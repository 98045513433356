import { FC } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { Box } from "@mui/material";
import CustomShimmer from "../CustomShimmer/CustomShimmer";
import { tabViewPoint } from "../../utils/constants";
import { Colors } from "../../styles/constant";

export const CustomAccordionSkeleton: FC = () => {
  const { width } = useWindowSize();
  return (
    <Box display="flex" flexDirection="column" rowGap="16px">
      <Box
        display="flex"
        alignItems="center"
        columnGap=".5rem"
        padding=".875rem 1.25rem"
        borderRadius="1.5rem"
        sx={{
          backgroundColor: width > tabViewPoint ? Colors.bgGray : Colors.white,
        }}
      >
        <CustomShimmer width="1.3125rem" borderRadius="1.3125rem" />
        <CustomShimmer width="60%" borderRadius=".4375rem" />
      </Box>
    </Box>
  );
};
