import { FC } from "react";
import {
  Box,
  Button,
  Divider,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { T_Option } from "../../../../globalTypes/GlobalTypes";
import { Colors } from "../../../../styles/constant";
import "../../ProposerDetails/ProposerDetails.scss";
import useWindowSize from "../../../../hooks/useWindowSize";
import { tabViewPoint } from "../../../../utils/constants";

interface Props {
  handleRadioBtnClick: Function;
  handleClick: Function;
  value: string;
}

interface RadioListProofData {
  label: string;
  value: string;
}

const proofOptions: T_Option[] = [
  {
    label: "Aadhaar card",
    value: "aadhar",
  },
  {
    label: "Passport",
    value: "passport",
  },
  {
    label: "Driving License",
    value: "drivingLicense",
  },
  {
    label: "Voter ID",
    value: "voterID",
  },
];

const IdentityProof: FC<Props> = ({
  handleClick,
  value,
  handleRadioBtnClick,
}) => {
  const { width } = useWindowSize();
  return (
    <Box>
      <Typography
        textAlign="center"
        variant="h5"
        sx={{
          marginBottom: width > tabViewPoint ? ".5rem" : "1.5rem !important",
        }}
      >
        SELECT IDENTITY PROOF
      </Typography>
      <Typography marginTop="0.5rem" textAlign="center" variant="subtitle2">
        Please select the identity proof that you are going to provide us
      </Typography>
      <Box margin={width > tabViewPoint ? "0rem 0 0rem" : "0rem 0 .625rem"}>
        <RadioGroup className="radio-group">
          {proofOptions.map((item: RadioListProofData, index: number) => (
            <>
              <Box
                key={item.value}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                paddingY={width > tabViewPoint ? "1rem" : "1.5rem"}
                sx={{ cursor: "pointer" }}
                onClick={() => handleRadioBtnClick(item)}
              >
                <Typography variant="body1">{item.label}</Typography>
                <Radio
                  checked={item.value === value}
                  sx={{
                    padding: 0,
                    color: Colors.red,
                    "&.Mui-checked": {
                      color: Colors.red,
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                    },
                  }}
                />
              </Box>
              {proofOptions.length - 1 > index && (
                <Divider sx={{ color: "#F7F7F7" }} />
              )}
            </>
          ))}
        </RadioGroup>
        <Button
          fullWidth
          variant="contained"
          sx={{
            marginTop: "1.5rem",
          }}
          disabled={value === "" ? true : false}
          className="continue-btn"
          onClick={() => handleClick()}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default IdentityProof;
