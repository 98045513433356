type T_LOCAL_KEYS = "CS_USER_ID" | "MOBILE" | "SHOW_INTRO" | "OTP_BLOCKED_TIME";

// Define a utility class for managing session storage
class CsLocalStorageUtils {
  // Function to save session data to localStorage
  static set<T = any>(key: T_LOCAL_KEYS, data: T): void {
    try {
      const serializedData = JSON.stringify(data);
      localStorage.setItem(key, serializedData);
    } catch (error) {
      console.error("Error saving session data:", error);
    }
  }

  // Function to retrieve session data from localStorage
  static get<T = any>(key: T_LOCAL_KEYS): T {
    try {
      const serializedData = localStorage.getItem(key);
      if (serializedData === null) {
        return null as T;
      }
      return JSON.parse(serializedData) as T;
    } catch (error) {
      console.error("Error retrieving session data:", error);
      return null as T;
    }
  }

  // Function to clear session data from localStorage
  static clear(key: T_LOCAL_KEYS): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Error clearing session data:", error);
    }
  }
}

export default CsLocalStorageUtils;
