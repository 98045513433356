import { FC, useCallback, useEffect, useRef, useState } from "react";
import "./Home.scss";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import ArrowRightIcon from "../../assets/icons/red-arrow-right.svg";
import CalculatorIcon from "../../assets/icons/calculator.png";
import CustomSimpleCard from "../../components/CustomSimpleCard/CustomSimpleCard";
import CustomLink from "../../components/CustomLink/CustomLink";
import { Colors } from "../../styles/constant";
import AddManuallyPopup from "../AddManuallyPopup/AddManuallyPopup";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import NavbarHome from "../../components/CustomNavbar/NavbarHome";
import RateUsPopUp from "../../features/Feature_GeneralAppElements/RateUsPopUp/RateUsPopUp";
import IntroWithBlurBackground from "../../features/Feature_GeneralAppElements/IntroWithBlurBackground/IntroWithBlurBackground";
import CsLocalStorageUtils from "../../utils/CsLocalStorageUtils";
import DashboardTabs from "./DashboardTabs";
import { E_ChannelOptions } from "../../globalTypes/GlobalTypes";
import PolicyAndConditionsComponent from "../../components/CustomPolicyAndConditionsComponent/PolicyAndConditionsComponent";
import { DashboardTabKeys } from "../../utils/constants";
import { useLazyFetchFaqParentModulesQuery } from "../../features/Feature_GeneralAppElements/GeneralAppElements_Api";
import { setParentModuleList } from "../../features/Feature_GeneralAppElements/GeneralAppElements_Slice";
import FAQCommon from "../../features/Feature_GeneralAppElements/FAQCommon/FAQCommon";
import usePromotionalCards from "../../hooks/usePromotionalCards";

const Home: FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("");
  const [token, setToken] = useState<string | null>(null);
  const [openRateUsPopUp, setOpenRateUsPopUp] = useState<boolean>(false);
  const [showIntro, setShowIntro] = useState(false);
  const [termsAndConditionsPopUp, setTermsAndConditionsPopUp] =
    useState<boolean>(false);
  const navbarRef = useRef<{ handleFooterFaqBtnClick: Function }>();
  const ssoSlice = useAppSelector((state) => state.SsoSlice.ssoData);
  const loginSlice = useAppSelector((state) => state.LoginSlice);
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const customerAndLobDetails = useAppSelector(
    (state) => state.CsAppSlice.userDetails?.customerAndLobDetails
  );
  const userName = customerAndLobDetails?.[0].NAME || "";
  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const promotionCard7 = cmsData.filter(
    (i) => i.attributes.cmsType === "calculator"
  );

  const promotionalCards = usePromotionalCards();
  const [fetchFaqParentModulesApi] = useLazyFetchFaqParentModulesQuery();
  const parentModuleList = useAppSelector(
    (state) => state.GeneralAppElementsSlice.parentModuleList
  );

  useEffect(() => {
    if (parentModuleList.length === 0) {
      fetchFaqParentModuleList();
      return () => {
        fetchFaqParentModuleList();
      };
    }
  }, [parentModuleList]);

  useEffect(() => {
    const showIntroValue =
      CsLocalStorageUtils.get("SHOW_INTRO") &&
      ssoSlice.channel === E_ChannelOptions.SUPER_APP &&
      ssoSlice.sourceLobId === "25";
    if (showIntroValue) {
      const timer = setTimeout(() => {
        setShowIntro(true);
      }, 5000);
      // Clean up the timer and Intro Keyword if the component is unmounted before the time has elapsed
      return () => {
        clearTimeout(timer);
        CsLocalStorageUtils.clear("SHOW_INTRO");
      };
    }
    const displayRateUs = loginSlice.displayRateUs;
    if (displayRateUs) {
      setOpenRateUsPopUp(true);
    }
  }, []);

  const handleClick = (link: string) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const handleTermsClick = () => {
    setTermsAndConditionsPopUp(!termsAndConditionsPopUp);
  };

  const fetchFaqParentModuleList = useCallback(() => {
    fetchFaqParentModulesApi("").then((res) => {
      if (res?.data?.code === 1) {
        dispatch(setParentModuleList(res?.data?.payload?.data));
      } else {
        dispatch(setParentModuleList([]));
      }
    });
  }, []);

  return (
    <>
      <NavbarHome navbarRef={navbarRef} />
      <Box className="home-page">
        <Box className="home-bg-container">
          <Container>
            <Box marginBottom={".75rem"}>
              <Typography variant="h3" color={Colors.darkCharcoal}>
                Hi {userDetails?.name},
              </Typography>
              <Typography variant="subtitle1" color={Colors.darkCharcoal}>
                Here are your existing holdings
              </Typography>
            </Box>
            <DashboardTabs setActiveTab={setActiveTab} />
            <Box
              display="flex"
              marginLeft=".625rem"
              marginTop="1rem"
              alignItems="center"
            >
              <Typography marginRight=".25rem" variant="h5">
                Didn't find your product here?
              </Typography>
              <CustomLink title="Add manually" onClick={() => setOpen(true)} />
            </Box>
            <Stack spacing="1rem" marginTop="1.625rem">
              {promotionalCards.map((item) => {
                return (
                  <CustomSimpleCard
                    imageUrl={item.attributes.image.data?.attributes.url ?? ""}
                    title={item.attributes.title ?? ""}
                    description={item.attributes.text}
                    link={
                      <CustomLink
                        onClick={() => handleClick(item.attributes.url ?? "")}
                        title={item.attributes.urlLable ?? ""}
                        titleSize={14}
                      />
                    }
                  ></CustomSimpleCard>
                );
              })}
            </Stack>

            {promotionCard7.map((i) => {
              return (
                <div onClick={() => handleClick(i?.attributes.url ?? "")}>
                  <Box className="custom-poc-card">
                    <Box display="flex" alignItems="center">
                      <img
                        src={CalculatorIcon}
                        alt="calculator"
                        className="calculator-icon"
                      />

                      <Box marginLeft=".375rem" marginRight=".9375rem">
                        {i.attributes.title && (
                          <Typography
                            color="white"
                            marginBottom=".3125rem"
                            fontSize={14}
                            fontWeight={600}
                          >
                            {i.attributes.title}
                          </Typography>
                        )}
                        <Typography color="white" fontWeight={400} variant="h6">
                          {i?.attributes.text}
                        </Typography>
                      </Box>
                    </Box>
                    <img
                      src={ArrowRightIcon}
                      alt="right"
                      className="arrow--right-icon"
                    />
                  </Box>
                </div>
              );
            })}
          </Container>
          {activeTab === DashboardTabKeys.Investment && (
            <Stack>
              <Typography
                sx={{
                  fontFamily: "Anek Latin",
                  fontSize: "12px",
                  fontWeight: 600,
                  lineHeight: "14.4px",
                  textAlign: "center",
                  color: "#979CAE",
                  padding: ".625rem 2.4375rem",
                }}
              >
                Disclaimer : Mutual Fund investments are subject to market
                risks, read all scheme related documents carefully before
                investing.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Anek Latin",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "13.2px",
                  letterSpacing: "0.1px",
                  textAlign: "center",
                  padding: ".625rem",
                }}
              >
                Aditya Birla Capital Digital Limited
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Anek Latin",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "13.2px",
                  letterSpacing: "0.1px",
                  textAlign: "center",
                  color: "#979CAE",
                  padding: ".625rem",
                }}
              >
                {/* AMFI-registered Mutual Fund Distributor ARN-123456
                <br /> */}
                By continuing, you agree to{" "}
                <Typography
                  component="span"
                  sx={{ color: "#C7222A", cursor: "pointer", fontSize: "12px" }}
                  onClick={handleTermsClick}
                >
                  Terms & Conditions
                </Typography>
              </Typography>
            </Stack>
          )}
        </Box>
        <FAQCommon activeTab={activeTab} />
      </Box>
      {open && <AddManuallyPopup open={open} handleOpen={setOpen} />}
      {showIntro && <IntroWithBlurBackground handleClose={setShowIntro} />}
      {openRateUsPopUp && (
        <RateUsPopUp open={openRateUsPopUp} handleOpen={setOpenRateUsPopUp} />
      )}
      {termsAndConditionsPopUp && (
        <PolicyAndConditionsComponent
          open={termsAndConditionsPopUp}
          handleOpen={setTermsAndConditionsPopUp}
          title={"mf-terms-and-conditions"}
        />
      )}
    </>
  );
};

export default Home;
