import React, { ErrorInfo } from "react";
import APIFailurePopup from "./components/CustomAPIFailurePopup/APIFailurePopup";
interface Props {
  children: React.ReactNode;
}

interface State {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}
class ErrorBoundary extends React.Component<Props, State> {
  // Constructor for initializing Variables etc in a state
  // Just similar to initial line of useState if you are familiar
  // with Functional Components
  constructor(props: Props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  clearError = () => {
    this.setState({ error: null, errorInfo: null });
  };

  // This method is called if any error is encountered
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and
    // re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    // You can also log error messages to an error
    // reporting service here
  }

  handleOpen = () => {
    this.clearError();
  };

  // This will render this component wherever called
  render() {
    return (
      <>
        {this.props.children}
        {this.state.error && (
          <APIFailurePopup open={true} handleOpen={this.handleOpen} />
        )}
      </>
    );
  }
}
export default ErrorBoundary;
