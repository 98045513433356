import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { enUS } from "@mui/x-date-pickers/locales";
import {
  CurrencyFormat,
  CurrencyFormatText,
} from "../../../utils/conversionUtils";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import ContentCopyIcon from "../../../assets/icons/content-copy.svg";
import { T_CaseCreateRequest, T_SummaryData } from "../Homeloan_Types";
import { useLazySubmitCaseRequestQuery } from "../Homeloan_Api";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import AbhflLogo from "../../../assets/images/ABHFL LOGO 2.svg";
import { Colors } from "../../../styles/constant";
import { R_HomeRoutes } from "../../../pages/Home/Home_Routes";
import TodayIcon from "@mui/icons-material/Today";
import "./DisbursementRequest.scss";
import useWindowSize from "../../../hooks/useWindowSize";
import { tabViewPoint } from "../../../utils/constants";
import { hlIndexFinder } from "../HomeloanDetails/HomeloanDetails";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import CustomFailure from "../CustomFailure/CustomFailure";
import NotFound from "../../../pages/NotFound/NotFound";
import { R_HLRoutes } from "../Homeloan_Routes";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";

const usLocale =
  enUS.components.MuiLocalizationProvider.defaultProps.localeText;
const radioOptions = ["NEFT/RTGS", "Demand draft/Cheque"];

type workflowInfo = {
  [key: string]: any;
};
const DisbursementRequest: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const minDate = dayjs().add(1, "day");
  const [reduxValues, setReduxValues] = useState<workflowInfo>({});
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const { state } = useLocation();
  const accountNumber = state?.accountNumber || "";
  const [selectVal, setSelectVal] = useState<string>("NEFT/RTGS");
  const [inputValues, setInputValues] = useState<workflowInfo>({
    disbursementDate: "",
    amount: "",
    beneficiaryName: "",
  });
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isExist, setIsExist] = useState<boolean>(false);
  const [showTick, setShowTick] = useState<boolean>(false);
  const [submitDisbursementRequestApi] = useLazySubmitCaseRequestQuery();
  const [amountErr, setAmountErr] = useState<boolean>(false);
  const [successData, setSuccessData] = useState({ reqNo: "", resTime: "" });
  const { width } = useWindowSize();
  const [isSubmitApiRunning, setIsSubmitApiRunning] = useState<boolean>(false);

  const updateFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    if (allSummaryData.length > 0 && accountNumber !== null) {
      const idx = hlIndexFinder(allSummaryData, accountNumber);
      const summaryData = allSummaryData[Number(idx)];
      setReduxValues({
        beneficiarySummary: summaryData?.Disbursement_History?.rows.map(
          (data) => {
            return {
              date: data?.DISBRUSMENT_DATE,
              name: data?.BENIFICIARY_NAME,
              amount: data?.AMOUNT,
            };
          }
        ),
        balanceAmount:
          summaryData?.Disbursement_sUMMARY?.rows?.[0]?.BALANCE_REMAINING,
        otherData: {
          customerId: summaryData?.account_detail?.rows[0]?.CUSTOMER_NO,
          accountNumber:
            summaryData?.Benificiary_Details?.rows[0]?.LOAN_ACCOUNT_NO,
          firstName: summaryData?.account_detail?.rows[0]?.FNAME,
          lastName: summaryData?.account_detail?.rows[0]?.LNAME,
          mobile: summaryData?.borrower_detail?.rows[0]?.MOBILE,
          emailId: summaryData?.borrower_detail?.rows[0]?.E_MAIL,
        },
      });
    }
  }, [allSummaryData, accountNumber]);

  useEffect(() => {
    validateAmount(inputValues?.amount);
  }, [inputValues?.amount]);

  const allFieldAreFilled = useCallback(() => {
    return (
      dayjs(inputValues.disbursementDate).isValid() &&
      inputValues.amount.trim() !== "" &&
      inputValues.beneficiaryName.trim() !== "" &&
      !amountErr
    );
  }, [inputValues, amountErr]);

  const updateBlur = useCallback(() => {
    if (inputValues.amount === "") setIsFocused((prevState) => !prevState);
  }, [inputValues.amount]);

  const copyText = useCallback(() => {
    let timeout: any;
    navigator.clipboard
      .writeText(successData?.reqNo)
      .then(() => {
        setShowTick(true);
        timeout = setTimeout(() => {
          setShowTick(false);
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });

    return () => clearTimeout(timeout);
  }, [successData]);

  const handlePopupOpen = useCallback(() => {
    setIsSuccess((prevState) => !prevState);
  }, []);

  const handleExistingPopupOpen = useCallback(() => {
    setIsExist((prevState) => !prevState);
  }, []);

  const validateAmount = useCallback(
    (val: string) => {
      if (
        parseFloat(val.replaceAll(",", "")) >
        parseFloat(reduxValues?.balanceAmount)
      ) {
        setAmountErr(true);
      } else setAmountErr(false);
    },
    [reduxValues]
  );

  const handleDOBchange = useCallback((value: dayjs.Dayjs | null) => {
    if (dayjs(value).isBefore(minDate) || value === null) {
      setInputValues((prevState) => {
        return {
          ...prevState,
          disbursementDate: null,
        };
      });
      return;
    }
    if (dayjs(value).isValid() && dayjs(value).isBefore(dayjs("2099-12-31"))) {
      setInputValues((prevState) => {
        return {
          ...prevState,
          disbursementDate: value,
        };
      });
    }
  }, []);

  const handleClose = useCallback(() => {
    setIsSuccess((prevState) => !prevState);
    navigate(R_HomeRoutes.home);
  }, []);

  const submitRequest = useCallback(() => {
    setIsSubmitApiRunning(true);
    let updatedValues: workflowInfo = {};
    updatedValues["amount"] = inputValues?.amount?.replaceAll(",", "");
    updatedValues["disbursementDate"] = dayjs(
      inputValues.disbursementDate
    ).format("DD/MM/YYYY");
    updatedValues["beneficiaryName"] = inputValues.beneficiaryName;
    updatedValues["modeOfPayment"] = selectVal;
    let val = Object.values(updatedValues).join(",");
    const reqPayload: T_CaseCreateRequest = Object.assign(
      reduxValues?.otherData,
      {
        subject: "Disbursement Request",
        comments: val,
        source: "ABC_Servicing_App",
        subtype: "hfl023",
        isClosed: false,
        url: "",
        category: "Request",
        lob: "HFC",
        priority: "Low",
        segment: "Finance",
        callbackDate: "",
        callbackTimeSlot: "",
        stpsRequestType: "",
        asOnDate: "",
        salaryCreditDate: "",
        dueDate: "",
        changeInEmiAmount: "",
        payableCharge: "",
        payPaymentAmount: "",
        impact: "",
        modeOfRepayment: "",
        soaFromDate: "",
        soaToDate: "",
        icFromYear: "",
        icToYear: "",
        attachments: null,
      }
    );
    submitDisbursementRequestApi(reqPayload).then((res) => {
      setIsSubmitApiRunning(false);
      if (res.data?.code === 1) {
        if (res.data?.payload?.existingCaseId === null) {
          setSuccessData({
            reqNo: res.data?.payload?.caseId,
            resTime: res.data?.payload?.expectedResolutionTime,
          });
          handlePopupOpen();
        } else {
          handleExistingPopupOpen();
        }
      }
      if (res.data?.code !== 1) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "Case create API failed",
          })
        );
      }
    });
  }, [inputValues, selectVal]);

  const goToServiceRequest = useCallback(() => {
    handleExistingPopupOpen();
    // View service request page not available
    navigate(R_HLRoutes.home_loan_service_request);
  }, []);

  return (
    <Box className="hl-disbursement-page">
      {isSuccess && (
        <CustomPopup
          open={isSuccess}
          handleOpen={handlePopupOpen}
          className={`hl-common-policy-popup-box`}
        >
          <CommonSuccessPopupBody
            title="Disbursement request has been submitted successfully"
            des={
              "Your service request will be resolved by " +
              dayjs(successData?.resTime, "DD/MM/YYYY h:mm:ss a").format(
                "DD/MM/YYYY"
              )
            }
            btnLabel="Go to homepage"
            additionalInfo={true}
            additionalContent={
              <Box className="hl-request-success-info">
                <Box className="hl-request-success-info-box">
                  <Typography
                    variant="subtitle2"
                    className="hl-request-success-info-title"
                  >
                    Service Request Number
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection="row"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {successData?.reqNo}
                    </Typography>

                    <img
                      src={ContentCopyIcon}
                      onClick={copyText}
                      alt="copy-icon"
                    />
                  </Box>
                  {showTick && <Typography variant="h4">Copied!</Typography>}
                </Box>
              </Box>
            }
            btnOnClick={handleClose}
          />
        </CustomPopup>
      )}

      <CustomFailure
        title="Service Request Already exists"
        btnLabel="View all service requests"
        handleClick={goToServiceRequest}
        open={isExist}
      />
      <Box>
        <Box className="hl-custom-breadcrumb">
          <CustomBreadcrumbs
            data={["Home Loan", accountNumber, "Disbursement request"]}
            className="hl-page-breadcrumb"
          />
          <CustomHelpComponent />
        </Box>
        {Object.keys(reduxValues).length > 0 ? (
          <Box>
            <Box className="hl-disbursement-header">
              <Typography className="hl-disbursement-header-label">
                Balance amount
              </Typography>
              <Typography className="hl-disbursement-header-value">
                {CurrencyFormat(reduxValues?.balanceAmount)}
              </Typography>
            </Box>
            <Box className="hl-disbursement-history-box ">
              <Typography
                variant="h5"
                className="hl-disbursement-history-title color-value"
              >
                Disbursement history
              </Typography>
              <Table className="hl-disbursement-table">
                <TableHead>
                  <TableRow>
                    <TableCell width={"33.33%"}>
                      <Typography variant="h6" className="color-value">
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell width={"33.33%"}>
                      <Typography variant="h6" className="color-value">
                        Beneficiary
                      </Typography>
                    </TableCell>
                    <TableCell width={"33.33%"}>
                      <Typography variant="h6" className="color-value">
                        Amount
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reduxValues?.beneficiarySummary.map(
                    (data: workflowInfo, index: number) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Typography variant="h6" className="color-value">
                              {data?.date}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              fontWeight={400}
                              className="color-value"
                            >
                              {data?.name}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              fontWeight={400}
                              className="color-value"
                            >
                              {CurrencyFormatText(data?.amount, true)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Box>
            <Box className="hl-disbursement-input-box">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{
                  ...usLocale,
                  fieldMonthPlaceholder: (params) =>
                    params.contentType === "letter" ? "MMM" : "MM",
                }}
              >
                <DatePicker
                  label="Disbursement Date"
                  format="DD/MM/YYYY"
                  className="date-picker"
                  minDate={minDate}
                  value={
                    inputValues.disbursementDate !== ""
                      ? inputValues.disbursementDate
                      : null
                  }
                  sx={{ width: "100%" }}
                  onChange={handleDOBchange}
                  slotProps={{
                    textField: {
                      InputProps:
                        width <= tabViewPoint
                          ? {
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  sx={{ marginRight: "0rem" }}
                                >
                                  <IconButton>
                                    <TodayIcon sx={{ color: Colors.black }} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }
                          : {},
                      error: false,
                    },
                  }}
                  disablePast
                  disableHighlightToday
                />
              </LocalizationProvider>
              <TextField
                label="Amount"
                value={inputValues?.amount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  let rv = e.target.value.replace(/[^\d]/g, "");
                  if (isNaN(parseFloat(rv)))
                    setInputValues((prevState) => {
                      return {
                        ...prevState,
                        amount: "",
                      };
                    });
                  else {
                    setInputValues((prevState) => {
                      return {
                        ...prevState,
                        amount: CurrencyFormatText(parseFloat(rv), false),
                      };
                    });
                  }
                }}
                inputProps={{
                  style: {
                    margin: isFocused ? "0rem" : "initial",
                  },
                }}
                InputProps={{
                  startAdornment: isFocused && (
                    <InputAdornment
                      position="start"
                      sx={{ marginRight: "0rem" }}
                    >
                      <IconButton
                        sx={{
                          paddingRight: "0rem",
                          paddingBottom: "0rem",
                          paddingTop: "0rem",
                        }}
                      >
                        {/* <CurrencyRupeeIcon
                      sx={{
                        height: ".75rem",
                        width: ".75rem",
                        color: amountErr ? Colors.red : "#000",
                      }}
                    /> */}
                        <Typography
                          variant="h5"
                          sx={{
                            color: amountErr ? Colors.red : Colors.darkBlack,
                            marginTop: "-.06rem",
                          }}
                        >
                          ₹
                        </Typography>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onFocus={updateFocus}
                onBlur={updateBlur}
                error={amountErr}
                helperText={
                  amountErr && (
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <InputAdornment position="start">
                        <img src={AlertRhombus} alt="alert" />
                      </InputAdornment>
                      Amount should not exceed balance amount
                    </span>
                  )
                }
              />
              <TextField
                label="Beneficiary name"
                value={inputValues?.beneficiaryName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValues((prevState) => {
                    return {
                      ...prevState,
                      beneficiaryName: e.target.value,
                    };
                  });
                }}
              />
            </Box>
            <Box className="hl-disbursement-radio-box">
              <Typography
                variant="h5"
                className="hl-disbursement-radio-box-header color-value"
              >
                Mode of payment
              </Typography>
              <RadioGroup className="hl-disbursement-radio-box-content">
                {radioOptions.map((item: string, index: number) => (
                  <Box
                    key={item}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingY="1rem"
                    sx={{ cursor: "pointer", marginRight: "1.5rem" }}
                    onClick={() => setSelectVal(item)}
                  >
                    <Radio
                      checked={item === selectVal}
                      sx={{
                        padding: 0,
                        color: Colors.radioGrey,
                        "&.Mui-checked": {
                          color: Colors.red,
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                        marginRight: "0.5rem",
                      }}
                    />
                    <Typography
                      variant="h5"
                      sx={{
                        color:
                          item === selectVal ? Colors.black : Colors.radioGrey,
                      }}
                    >
                      {item}
                    </Typography>
                  </Box>
                ))}
              </RadioGroup>
            </Box>
            {isSubmitApiRunning ? (
              <CustomThreeDotsLoader />
            ) : (
              <Button
                className="hl-disbursement-btn"
                disabled={!allFieldAreFilled()}
                onClick={allFieldAreFilled() ? submitRequest : () => {}}
                disableRipple
                disableFocusRipple
              >
                <Box display="flex" alignItems="center">
                  <Typography
                    marginLeft=".3125rem"
                    marginRight=".3125rem"
                    variant="h4"
                  >
                    Submit Request
                  </Typography>
                </Box>
              </Button>
            )}
            <Box className="powered-by">
              <Typography variant="h5" sx={{ color: Colors.textGray }}>
                Powered by
              </Typography>
              <img src={AbhflLogo} alt="abc-logo" />
            </Box>
          </Box>
        ) : (
          <NotFound />
          // <Box>
          //   <Typography
          //     variant="h5"
          //     sx={{
          //       margin: "2rem",
          //       textAlign: "center",
          //     }}
          //   >
          //     No data available
          //   </Typography>
          //   <Button
          //     className="hl-disbursement-btn"
          //     onClick={goToHome}
          //     disableRipple
          //     disableFocusRipple
          //   >
          //     <Box display="flex" alignItems="center">
          //       <Typography
          //         marginLeft=".3125rem"
          //         marginRight=".3125rem"
          //         variant="h4"
          //       >
          //         Go back to Home Page
          //       </Typography>
          //     </Box>
          //   </Button>
          // </Box>
        )}
      </Box>
    </Box>
  );
};

export default DisbursementRequest;
