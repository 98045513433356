import PremiumReceipt from "./PremiumReceipt/PremiumReceipt";
import LifeInsuranceGuard from "./LifeInsurance_Guard";
import UpdatePAN from "./UpdatePAN/UpdatePAN";
import SetAlerts from "./SetAlerts/SetAlerts";
import UnableToNavigate from "../../pages/UnableToNavigate/UnableToNavigate";
import { lazy, Suspense } from "react";
import LoadingInstant from "../../pages/Utilities/LoadingInstant";

export enum R_LiRoutes {
  li_baseUrl = "/details/li",
  details_PolicyNumber = "/details/li",
  details_PolicyNumber_TaxCertificate = "/details/li/tax-certificate",
  details_PolicyNumber_PolicyAccountStatement = "/details/li/policy-account-statement",
  details_PolicyNumber_PremiumReceipts = "/details/li/premium-receipts",
  details_PolicyNumber_UpdatePAN = "/details/li/update-PAN",
  details_set_alerts = "/details/li/set-alerts",
  li_UrlNotFound = "li/not-found",
}
const TaxCertificate = lazy(() => import("./TaxCertificate/TaxCertificate"));
const PolicyAccountStatement = lazy(
  () => import("./PolicyAccountStatement/PolicyAccountStatement")
);
const DetailsPage = lazy(
  () => import("../Feature_LifeInsurance/DetailsPage/DetailsPage")
);
const LifeInsuranceRoutesFn = () => {
  return [
    {
      path: R_LiRoutes.li_baseUrl,
      element: <LifeInsuranceGuard />,
      children: [
        {
          path: R_LiRoutes.details_PolicyNumber,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <DetailsPage />
            </Suspense>
          ),
        },
        {
          path: R_LiRoutes.details_PolicyNumber_TaxCertificate,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <TaxCertificate />
            </Suspense>
          ),
        },
        {
          path: R_LiRoutes.details_PolicyNumber_PolicyAccountStatement,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <PolicyAccountStatement />
            </Suspense>
          ),
        },
        {
          path: R_LiRoutes.details_PolicyNumber_PremiumReceipts,
          element: <PremiumReceipt />,
        },
        {
          path: R_LiRoutes.details_PolicyNumber_UpdatePAN,
          element: <UpdatePAN />,
        },
        {
          path: R_LiRoutes.details_set_alerts,
          element: <SetAlerts />,
        },
      ],
    },
    {
      path: R_LiRoutes.li_UrlNotFound,
      element: <UnableToNavigate />,
    },
  ];
};
export { LifeInsuranceRoutesFn };
