import { Colors } from "../styles/constant";
import activAssureDiamondIcon from "../assets/icons/ActivAssureDiamondIcon.svg";
import activAssureDiamondIconRed from "../assets/icons/ActivAssureDiamondIcon-red.svg";
import activAssureDiamondPlusIcon from "../assets/icons/ActivAssureDiamondPlusIcon.svg";
import activAssureDiamondPlusIconRed from "../assets/icons/ActivAssureDiamondPlusIcon-red.svg";
import activFitPlusIcon from "../assets/icons/ActivFitPlusIcon.svg";
import activFitPlusIconRed from "../assets/icons/ActivFitPlusIcon-red.svg";
import activFitPreferredIcon from "../assets/icons/ActivFitPreferredIcon.svg";
import activFitPreferredIconRed from "../assets/icons/ActivFitPreferredIcon-red.svg";
import activHealthPlatinumEnhancedIcon from "../assets/icons/ActivHealthPlatinumEnhancedIcon.svg";
import activHealthPlatinumEnhancedIconRed from "../assets/icons/ActivHealthPlatinumEnhancedIcon-red.svg";
import activHealthPlatinumEssentialIcon from "../assets/icons/ActivHealthPlatinumEssentialIcon.svg";
import activHealthPlatinumEssentialIconRed from "../assets/icons/ActivHealthPlatinumEssentialIcon-red.svg";
import activOneMaxIcon from "../assets/icons/activonemax-gray.svg";
import activOneMaxIconRed from "../assets/icons/ActivOneMaxIcon-red.svg";
import activOneNxtIcon from "../assets/icons/activonenxt-gray.svg";
import activOneNxtIconRed from "../assets/icons/ActivOneNxtIcon-red.svg";
import superTopUpPlanBIcon from "../assets/icons/SuperTopUpPlanBIcon.svg";
import superTopUpPlanBIconRed from "../assets/icons/SuperTopUpPlanBIcon-red.svg";
import topUpPlanGenericIcon from "../assets/icons/TopUpPlanGenericIcon.svg";
import topUpPlanGenericIconRed from "../assets/icons/TopUpPlanGenericIcon-red.svg";
import BasePlanGenericIcon from "../assets/icons/BasePlanGenericIcon.svg";
import BasePlanGenericIconRed from "../assets/icons/BasePlanGenericIcon-red.svg";
import { T_InsuredDetail } from "../features/Feature_HealthInsurance/HealthInsurance_Types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Extend Day.js with the necessary plugin
dayjs.extend(customParseFormat);

export const CurrencyFormat = (value: string = "") => {
  if (!value) return "";
  let tempValue = value.includes(".") ? parseFloat(value) : parseInt(value);
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: tempValue.toString().includes(".") ? 2 : 0,
  }).format(tempValue);
};

export const CurrencyFormatText = (value: number = 0, withSymbol = true) => {
  let formatedValue = new Intl.NumberFormat("en-IN").format(value);
  if (withSymbol) {
    return "₹" + formatedValue;
  }
  return formatedValue;
};

export const currencyConvertor = (amountInNumber: any) => {
  let lacs: any, crores: any, formattedAmount: any;
  if (amountInNumber && !isNaN(amountInNumber)) {
    if (amountInNumber >= 10000000) {
      crores = (amountInNumber / 10000000).toFixed(2);
      formattedAmount =
        "₹" + (crores % 1 === 0 ? crores.split(".")[0] : crores) + " Crores";
    } else if (amountInNumber >= 100000) {
      lacs = (amountInNumber / 100000).toFixed(1);
      formattedAmount =
        "₹" + (lacs % 1 === 0 ? lacs.split(".")[0] : lacs) + " Lacs";
    } else {
      formattedAmount = CurrencyFormat(amountInNumber.toString());
    }
  } else formattedAmount = amountInNumber;
  if (!formattedAmount) return "";
  return formattedAmount;
};

export const getPolicyStatus = (status: string | "") => {
  switch (status) {
    case "PREMIUM WAIVER OR PAYOR DEATH":
      return "Waiver";
    case "PAID-UP ANNUITY/EXTENDED TERM-ULIP":
    case "PAID-UP ANNUITY/EXTENDED TERM":
    case "RPU ULIP":
      return "Paid-Up";
    case "PREMIUM PAYING":
      return "In-Force";
    case "LAPSED":
      return "Lapsed";
    case "DISCONTINUED":
      return "Discontinued";
    case "TERMINATED":
      return "Terminated";
    case "SURRENDERED":
      return "Surrendered";
    default:
      return "";
  }
};

export const getStatusColor = (status: string | "") => {
  switch (status) {
    case "PREMIUM PAYING":
    case "PREMIUM WAIVER OR PAYOR DEATH":
      return Colors.cadmiumGreen;
    case "LAPSED":
    case "DISCONTINUED":
    case "PAID-UP ANNUITY/EXTENDED TERM-ULIP":
    case "PAID-UP ANNUITY/EXTENDED TERM":
    case "RPU ULIP":
      return Colors.iconYellow;
    case "SURRENDERED":
    case "TERMINATED":
      return Colors.red;
    default:
      return "";
  }
};

export const getPolicyType = (type: string | "") => {
  switch (type) {
    case "ULIP":
      return "Unit Linked Insurance Plan";
    case "PAR":
    case "NON-PAR":
    case "NON PAR":
      return "Savings Plan";
    case "ANNUITY":
      return "Retirement Plan";
    case "HEALTH":
      return "Critical Illness Plan";
    case "TERM":
      return "Term Plan";
    default:
      return "";
  }
};

export const getHiPolicyStatus = (status: string = "") => {
  let tempStatus = status.toLowerCase();
  if (tempStatus === "if") {
    return "Active";
  } else if (tempStatus === "expired") {
    return "Expired";
  } else if (tempStatus === "lapsed") {
    return "Lapsed";
  }
};

export const getHlPolicyStatus = (status: string = "") => {
  if (!status) {
    return "";
  }
  let tempStatus = status.toLowerCase();
  if (status === null || status === undefined) {
    return "";
  }
  if (tempStatus.toLowerCase() === "active") {
    return "Active";
  } else if (tempStatus.toLowerCase() === "closed") {
    return "Closed";
  } else {
    return "";
  }
};

export const getHiPolicyNameIcon = (
  name: string = "",
  pageName: string = ""
) => {
  switch (name.toLowerCase()) {
    case "platinum - essential":
      return pageName === "dashboard"
        ? activHealthPlatinumEssentialIcon
        : activHealthPlatinumEssentialIconRed;
    case "platinum - enhanced":
      return pageName === "dashboard"
        ? activHealthPlatinumEnhancedIcon
        : activHealthPlatinumEnhancedIconRed;
    case "preferred":
      return pageName === "dashboard"
        ? activFitPreferredIcon
        : activFitPreferredIconRed;
    case "plus":
      return pageName === "dashboard" ? activFitPlusIcon : activFitPlusIconRed;
    case "diamond":
      return pageName === "dashboard"
        ? activAssureDiamondIcon
        : activAssureDiamondIconRed;
    case "diamond plus":
      return pageName === "dashboard"
        ? activAssureDiamondPlusIcon
        : activAssureDiamondPlusIconRed;
    case "max":
      return pageName === "dashboard" ? activOneMaxIcon : activOneMaxIconRed;
    case "nxt":
      return pageName === "dashboard" ? activOneNxtIcon : activOneNxtIconRed;
    case "base plan (generic":
      return pageName === "dashboard"
        ? BasePlanGenericIcon
        : BasePlanGenericIconRed;
    case "top up plan (generic":
    case "plan a":
      return pageName === "dashboard"
        ? topUpPlanGenericIcon
        : topUpPlanGenericIconRed;
    case "plan b":
      return pageName === "dashboard"
        ? superTopUpPlanBIcon
        : superTopUpPlanBIconRed;
    default:
      return undefined;
  }
};

export const getHiMembersInsured = (insuredDetails: T_InsuredDetail[]) => {
  let policyType = insuredDetails?.[0]?.Policy_Type?.toLowerCase();
  let displayString = "";
  if (["individual", "family floater"].includes(policyType)) {
    let isSelf =
      insuredDetails.filter((item) => item.vchRelation.toLowerCase() === "self")
        .length > 0;
    let isSpouse =
      insuredDetails.filter(
        (item) => item.vchRelation.toLowerCase() === "spouse"
      ).length > 0;
    let kidsCount = insuredDetails.filter(
      (item) => !["self", "spouse"].includes(item.vchRelation.toLowerCase())
    ).length;
    if (isSelf) displayString += "Self";
    if (isSpouse) {
      if (displayString) displayString += ", ";
      displayString += "Spouse";
    }
    if (kidsCount > 0) {
      if (displayString) displayString += ", ";
      displayString += kidsCount === 1 ? "1 kid" : `${kidsCount} kids`;
    }
  } else if (policyType === "multi-individual") {
    const memberCount = insuredDetails.length;
    return `${memberCount} members`;
  }
  return displayString;
};

export const getOrdinalDateSuffix = (day: any) => {
  if (day > 3 && day < 21) return "th"; // exception for 11th, 12th, 13th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formatDateWithOrdinal = (dateStr: any) => {
  const date = dayjs(dateStr, "DD/MM/YYYY hh:mm:ss A");
  const day = date.date();
  const month = date.format("MMMM");
  const year = date.format("YYYY");
  const suffix = getOrdinalDateSuffix(day);

  return `${month} ${day}${suffix}, ${year}`;
};
