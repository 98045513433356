import React, { FC, useState, useEffect, useCallback, useMemo } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./CustomNavbarHome.scss";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SearchIcon from "@mui/icons-material/Search";
import ABCDLogo from "../../assets/images/abcd-logo.png";
import { useNavigate } from "react-router-dom";
import threeLinesIcon from "../../assets/icons/three-lines.svg";
import NotificationsPage from "../../features/Feature_GeneralAppElements/NotificationsPage/NotificationsPage";
import HamburgerMenuDialog from "../../features/Feature_GeneralAppElements/HamburgerMenuDialog/HamburgerMenuDialog";
import AppSwitchHeader from "../../features/Feature_GeneralAppElements/AppSwitchHeader/AppSwitchHeader";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../styles/constant";
import DownloadCSAppPopup from "../../features/Feature_GeneralAppElements/DownloadCSAppPopup/DownloadCSAppPopup";
import { getInitials } from "../../utils/helper";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import {
  ABCD_SUPER_APP_PACKAGE_NAME,
  DashboardTabKeys,
} from "../../utils/constants";
import { E_ChannelOptions } from "../../globalTypes/GlobalTypes";
import CustomNetworkImage from "../CustomNetworkImage/CustomNetworkImage";
import CsSessionStorageUtils from "../../utils/CsSessionStorageUtils";

interface Props {
  navbarRef: any;
}

const NavbarHome: FC<Props> = ({ navbarRef }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [defaultComponent, setDefaultComponent] = useState("main-menu");
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [openDownloadABCDAppPopup, setOpenDownloadABCDAppPopup] =
    useState<boolean>(false);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const navigate = useNavigate();
  const openDownloadAppHeader: boolean = false; //just added this for now, it can change in future based on condition, change to true to see header
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const ssoSlice = useAppSelector((state) => state.SsoSlice);
  const cmsSlice = useAppSelector((state) => state.CmsSlice);
  const activeTab = CsSessionStorageUtils.get("ACTIVE_DASHBOARD_TAB");

  const defaultMarqueeText = useMemo(() => {
    return (
      cmsSlice.CmsIntegrationVariableData.find(
        (f) => f.attributes.isEnable && f.attributes.key === "Default Marquee"
      )?.attributes.text || "Search for Insurance policy details"
    );
  }, []);
  const [marqueeLabels, setMarqueeLabels] = useState(defaultMarqueeText);

  const cmsConfigMarquee = useMemo(() => {
    const marqueeArr = cmsSlice.CmsIntegrationVariableData.filter(
      (f) => f.attributes.isEnable && f.attributes.key === "Marquee"
    );
  return marqueeArr.filter((f) => {
      const temp1 =
        activeTab === DashboardTabKeys.Insurance
          ? ["02", "06"].includes(f.attributes.lobId)
          : activeTab === DashboardTabKeys.Investment
          ? ["24", "01"].includes(f.attributes.lobId)
          : false;
      return f.attributes.isEnable && f.attributes.key === "Marquee" && temp1;
    });
  }, [activeTab]);

  useEffect(() => {
    if (!cmsConfigMarquee || cmsConfigMarquee.length <= 0) {
      setMarqueeLabels(defaultMarqueeText);
      return;
    }
    const toggleMarque = () => {
      setMarqueeLabels((prev) => {
        return cmsConfigMarquee[0]?.attributes.text === prev
          ? "" + cmsConfigMarquee?.[1]?.attributes.text
          : "" + cmsConfigMarquee?.[0]?.attributes.text;
      });
    };
    toggleMarque();
    const interval = setInterval(() => {
      toggleMarque();
    }, 10000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [cmsConfigMarquee]);

  const handleFooterFaqBtnClick = () => {
    setDefaultComponent("FAQs");
    setOpen(true);
  };

  React.useImperativeHandle(navbarRef, () => ({
    handleFooterFaqBtnClick,
  }));

  const handleNotificationClick = useCallback(() => {
    setNotificationOpen(true);
  }, []);

  const handleDownloadABCDAppPopup = useCallback(() => {
    setOpenDownloadABCDAppPopup(true);
  }, []);

  const navigateToSearch = useCallback(() => {
    navigate("/search");
  }, []);

  const handleHamburgerMenuClick = useCallback(() => {
    setOpen(true);
  }, []);

  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const cmsLogo =
    cmsData.find((i) => i.attributes.cmsType === "ABC Logo")?.attributes.image
      .data?.attributes.url ?? "";
  const notificationIcon =
    cmsData.find((i) => i.attributes.cmsType === "notification-icon")
      ?.attributes.image.data?.attributes.url ?? "";

  useEffect(() => {
    const checkInstalledApps = async () => {
      if ("getInstalledRelatedApps" in navigator) {
        try {
          const relatedApps = await (
            navigator as any
          ).getInstalledRelatedApps();
          const isInstalled = relatedApps.some(
            (app: any) =>
              app.platform === "play" && app.id === ABCD_SUPER_APP_PACKAGE_NAME
          );
          setIsAppInstalled(isInstalled);
        } catch (error) {
          console.error("Error checking installed apps:", error);
        }
      } else {
        console.warn(
          "getInstalledRelatedApps is not supported in this browser."
        );
      }
    };

    checkInstalledApps();
  }, []);

  const imageStyle: React.CSSProperties = {
    marginLeft: "-0.3125rem", // Use camelCase
    width: "7.1875rem",
    marginRight: "0.3125rem", // Use camelCase
  };
  return (
    <Box className="navbar-home">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding={".75rem 0"}
        >
          <Box
            display="flex"
            alignItems="center"
            position="relative"
            zIndex="100"
          >
            <CustomNetworkImage
              src={cmsLogo}
              fallBackImage= { <img src={ABCDLogo}  style={imageStyle} alt=""/>}
              alt="aditya-birla"
              style={imageStyle}
            />

            {/* {ssoSlice.ssoData?.channel === E_ChannelOptions.SUPER_APP &&
              ssoSlice.ssoData.sourceLobId === "25" && <AppSwitchHeader />} */}
              <AppSwitchHeader />
          </Box>
          <Box display="flex" alignItems="center" onClick={() => handleNotificationClick}>
            {/* <NotificationsNoneIcon
              sx={{
                color: "#4F4F4F",
                fontSize: "1.5rem",
                marginRight: "1.25rem",
              }}
              // onClick={handleNotificationClick}
            /> */}
            <CustomNetworkImage
              src={notificationIcon}
              alt=""
              style={{ marginRight: "1.25rem" }}
            ></CustomNetworkImage>
            <Box position="relative">
              <Avatar className="menu-icon" onClick={handleHamburgerMenuClick}>
                {getInitials(userDetails?.name)}
              </Avatar>

              <img
                src={threeLinesIcon}
                className="three-lines-icon"
                alt="three-lines-icon"
              />
            </Box>
          </Box>
        </Box>
      </Container>
      {openDownloadAppHeader && ( //this will enable based on condition in future, disabled for now
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="space-between"
          bgcolor={Colors.red}
          padding=".75rem 1rem .75rem 1rem"
          marginBottom={".75rem"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Typography variant="h6" fontWeight={500} color="#F7F7F7">
              Do more with your money
            </Typography>
            <Button
              sx={{
                bgcolor: "white",
                borderRadius: "6.25rem",
                textTransform: "none",
                width: "8.0625rem",
                height: "1.5rem",
                marginLeft: "0.5rem",
              }}
              onClick={handleDownloadABCDAppPopup}
            >
              <Typography variant="h6" lineHeight=".975rem" color="#CA1F34">
                Download ABCD App
              </Typography>
            </Button>
          </Box>
          <CloseIcon sx={{ fontSize: "1.125rem", color: "#FEFDFF" }} />
        </Box>
      )}
      <Container>
        <TextField
          fullWidth
          placeholder={marqueeLabels}
          InputProps={{
            startAdornment: (
              <InputAdornment sx={{ marginRight: 0 }} position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            className: "custom-placeholder",
          }}
          onClick={navigateToSearch}
        />
      </Container>
      {open && (
        <HamburgerMenuDialog
          open={open}
          setOpen={setOpen}
          defaultComponent={defaultComponent}
        />
      )}
      {notificationOpen && (
        <NotificationsPage
          open={notificationOpen}
          handleOpen={setNotificationOpen}
        />
      )}
      {openDownloadABCDAppPopup && (
        <DownloadCSAppPopup
          openPopup={openDownloadABCDAppPopup}
          handlePopup={setOpenDownloadABCDAppPopup}
        />
      )}
    </Box>
  );
};

export default NavbarHome;
