import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import "./DownloadAppPopup.scss";
import downloadAppQrCode from "../../../assets/images/download-app-qr-code.png";
import appStoreImage from "../../../assets/images/app-store-1x.png";
import googlePlayStoreImage from "../../../assets/images/google-play-store-1x.png";

interface Props {
  open: boolean;
  handleOpen: Function;
}

const DownloadAppPopup: FC<Props> = ({ open, handleOpen }) => {
  return (
    <CustomPopup
      open={open}
      handleOpen={handleOpen}
      btnLabel="Close"
      btnStartIcon={<CloseIcon fontSize="small" />}
      className="download-app-popup"
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography
          textTransform="uppercase"
          variant="h6"
          lineHeight="1.125rem"
          letterSpacing="0.0938rem"
          textAlign="center"
          paddingX=".75rem"
          marginBottom="2rem"
          fontFamily="Poppins, sans-serif"
        >
          SCAN THE QR to Download the app
        </Typography>
        <img src={downloadAppQrCode} />
        <Stack rowGap="1rem" marginTop="2rem">
          <img src={googlePlayStoreImage} />
          <img src={appStoreImage} />
        </Stack>
      </Box>
    </CustomPopup>
  );
};

export default DownloadAppPopup;
