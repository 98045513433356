import { Box, Stack, SwipeableDrawer, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import {
  DEVICE_TYPE,
  HLQuickList,
  HLServiceRequest,
  HLStatementsAndDocuments,
  tabViewPoint,
} from "../../../utils/constants";
import ServiceArrowIcon from "../../../assets/icons/up-gray-arrow.svg";
import servicesIcon from "../../../assets/icons/services.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../../styles/constant";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import useWindowSize from "../../../hooks/useWindowSize";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "../../../assets/icons/content-copy.svg";
import "./QuickActionsMenuComponent.scss";
import {
  useLazyCreateCaseQuery,
  useLazyReportingServiceQuery,
} from "../Homeloan_Api";
import {
  downloadPdfFromBase64,
  getCustomerRequest,
  getReportingServiceRequest,
  getCustomerRequestForRepayment,
} from "../../../utils/helper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { T_SummaryData } from "../Homeloan_Types";
import { T_ValidateSSOOldRes } from "../../Feature_SSO/Sso_Types";
import { hlIndexFinder } from "../HomeloanDetails/HomeloanDetails";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import CustomFailure from "../CustomFailure/CustomFailure";
import useDeviceDetection from "../../../hooks/useDeviceDetection";
import CustomCircularLoader from "../../../components/CustomCircularLoader/CustomCircularLoader";
import { R_HLRoutes } from "../Homeloan_Routes";

function getList(title: string) {
  switch (title) {
    case "Statement & documents":
      return HLStatementsAndDocuments;
    case "Service requests":
      return HLServiceRequest;
    default:
      return HLQuickList;
  }
}

interface ServiceList {
  key?: string;
  title: string;
  preIcon: any;
  postIcon: React.ElementType;
  pathname?: string;
  secondIcon?: string;
  isNavIcon?: boolean;
}

const ServiceObjData = {
  title: "Quick Actions",
  preIcon: servicesIcon,
};

interface ServiceObj {
  title: string;
  preIcon: any;
}

const HLQuickActionsMenuComponent: FC = () => {
  const { state: locationState } = useLocation();
  const accountNumber = locationState?.accountNumber || "";
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const secondaryChannelSlice = useAppSelector(
    (state) => state.SecondaryChannelSlice
  );
  const idx = hlIndexFinder(allSummaryData, accountNumber);
  const summaryData = allSummaryData[Number(idx)];
  const ssoSlice = useAppSelector(
    (state) => state.SsoSlice.ssoData as T_ValidateSSOOldRes
  );
  const [state, setState] = useState<{
    open: boolean;
    serviceMenuList: ServiceList[];
    serviceObj: ServiceObj;
    isServiceMainMenu: boolean;
    isPolicyBondApiSuccess: boolean;
    policyBondPopupOpen: boolean;
  }>({
    open: false,
    serviceMenuList: HLQuickList,
    serviceObj: ServiceObjData,
    isServiceMainMenu: true,
    isPolicyBondApiSuccess: false,
    policyBondPopupOpen: false,
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const [isApiSuccess, setIsApiSuccess] = useState<boolean>(false);
  const [isApiPopupOpen, setApiPopup] = useState<boolean>(false);
  const [generatedSrNumber, setSrNumber] = useState<string | null>(null);
  const [docType, setDocumentType] = useState("");
  const [documentName, setDocumentName] = useState<string | null>(null);
  const [createCase] = useLazyCreateCaseQuery();
  const [isExist, setIsExist] = useState<boolean>(false);
  const [showTick, setShowTick] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<string>("");
  const [reportingService] = useLazyReportingServiceQuery();

  const deviceType = useDeviceDetection();

  useEffect(() => {
    if (ssoSlice.functionality) {
      setState((prevState) => ({
        ...prevState,
        open: true,
      }));
    }
  }, [ssoSlice.functionality]);

  const copyText = useCallback(() => {
    let timeout: any;
    navigator.clipboard
      .writeText(`${generatedSrNumber}`)
      .then(() => {
        setShowTick(true);
        timeout = setTimeout(() => {
          setShowTick(false);
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });

    return () => clearTimeout(timeout);
  }, [generatedSrNumber]);

  const handlePopupOpen = useCallback(() => {
    setIsApiSuccess((prevState) => !prevState);
  }, []);

  const getDocument = async (type: string, title: string) => {
    const changeData = {
      subject: "Statements & Documents-WelcomeKit",
      subtype: "RSTAWEITHFC",
    };
    const request = getCustomerRequest(changeData, summaryData, type);
    setIsBtnLoading(title);
    createCase(request).then((res) => {
      if (res.data?.code === 1) {
        const response = res.data.payload;
        const srNumber = response.caseId;
        // const srNumber = response.existingCaseId;
        if (srNumber === undefined || srNumber === null) {
          setIsExist(true);
          setIsBtnLoading("");
        } else {
          setSrNumber(srNumber);
          if (type === "Download") {
            const data = {
              reportName: "WelcomeKit",
            };
            const reportingServiceRequest = getReportingServiceRequest(
              data,
              summaryData,
              srNumber
            );
            reportingService(reportingServiceRequest).then((res) => {
              if (res.isError || res.data?.code !== 1) {
                dispatch(
                  setError({
                    error: new Error("Error"),
                    message: "Exit Load API failed",
                  })
                );
                setIsBtnLoading("");
              }
              if (res.data) {
                try {
                  const response = res.data.payload;
                  const dataList = response.binaryDataList;
                  if (dataList === undefined || dataList === null) {
                    setIsApiSuccess(false);
                    setIsBtnLoading("");
                  } else {
                    downloadPdfFromBase64(
                      dataList[0],
                      `${summaryData.account_detail.rows[0].FNAME}_${summaryData.account_detail.rows[0].LNAME}.pdf`
                    );
                    setIsApiSuccess(true);
                    setIsBtnLoading("");
                  }
                } catch {
                  throw new Error("Unable to extract data");
                }
              }
            });
          } else {
            setIsApiSuccess(true);
            setIsBtnLoading("");
          }
        }
      }
      if (res.data?.code !== 1) {
        setIsBtnLoading("");
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
    });
    setApiPopup(true);
  };

  const getDocumentForRepayment = async (type: string, title: string) => {
    const changeData = {
      subject: "Amortization schedule",
      subtype: "hfl004",
    };
    const request = getCustomerRequestForRepayment(
      changeData,
      summaryData,
      type
    );
    setIsBtnLoading(title);
    createCase(request).then((res) => {
      setIsBtnLoading("");
      if (res.data?.code === 1) {
        const response = res.data.payload;
        const srNumber = response.caseId;
        if (srNumber === undefined || srNumber === null) {
          setIsApiSuccess(false);
          setIsBtnLoading("");
        } else {
          setSrNumber(srNumber);
          if (type === "Download") {
            const reportingServiceRequest = getReportingServiceRequest(
              { reportName: "RepaymentSchedule" },
              summaryData,
              srNumber
            );
            setIsBtnLoading(title);
            reportingService(reportingServiceRequest).then((res) => {
              if (res.isError || res.data?.code !== 1) {
                dispatch(
                  setError({
                    error: new Error("Error"),
                    message: "Exit Load API failed",
                  })
                );
                setIsBtnLoading("");
              }
              if (res.data) {
                try {
                  const response = res.data.payload;
                  const dataList = response.binaryDataList;
                  if (dataList === undefined || dataList === null) {
                    setIsApiSuccess(false);
                    setIsBtnLoading("");
                  } else {
                    downloadPdfFromBase64(
                      dataList[0],
                      `${summaryData.account_detail.rows[0].FNAME}_${summaryData.account_detail.rows[0].LNAME}.pdf`
                    );

                    setIsBtnLoading("");
                    setIsApiSuccess(true);
                  }
                } catch {
                  throw new Error("Unable to extract data");
                }
              }
            });
          } else {
            setIsApiSuccess(true);
            setIsBtnLoading("");
          }
        }
      }
      if (res.data?.code !== 1) {
        setIsBtnLoading("");
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
    });
    setApiPopup(true);
  };

  const handleDownloadListItem = (item: ServiceList) => {
    if (item.key === "welcome-kit") {
      setDocumentType("Download");
      getDocument("Download", item.title);
      setDocumentName("Welcome Kit");
    } else if (item.key === "repayment-schedule") {
      setDocumentType("Download");
      getDocumentForRepayment("Download", item.title);
      setDocumentName("Repayment Schedule");
      // getCustomerRequestForRepayment("download");
    }
  };

  const handleEmailListItem = (item: ServiceList) => {
    if (item.key === "welcome-kit") {
      setDocumentType("Email");
      getDocument("email", item.title);
      setDocumentName("Welcome Kit");
    } else if (item.key === "repayment-schedule") {
      setDocumentType("Email");
      getDocumentForRepayment("email", item.title);
      setDocumentName("Repayment Schedule");
      // getCustomerRequestForRepayment("download");
    }
  };

  const handleClickListItem = (item: ServiceList) => {
    if (item.title === "Disbursement request") {
      navigate(R_HLRoutes.disbursement_request, { state: { accountNumber } });
    } else {
      if (state.isServiceMainMenu) {
        let tempList: ServiceList[] = getList(item.title);

        setState((prevState) => ({
          ...prevState,
          serviceMenuList: tempList,
          serviceObj: { title: item.title, preIcon: item.preIcon },
          isServiceMainMenu: false,
        }));
      } else {
        if (item.key === "interest-certificate") {
          navigate(R_HLRoutes.interest_certificate, {
            state: { accountNumber },
          });
        } else if (item.key === "statements-documents") {
          navigate(R_HLRoutes.account_statement, { state: { accountNumber } });
        } else if (item.key === "welcome-kit") {
          setDocumentName(item.key);
          handleEmailListItem(item);
        } else if (item.key === "repayment-schedule") {
          setDocumentName(item.key);
          handleEmailListItem(item);
        } else if (item.key === "register-change-e-nach") {
          navigate(R_HLRoutes.change_enach, { state: { accountNumber } });
        }
      }
    }
  };

  useEffect(() => {
    if (!state.open) {
      setState((prevState) => ({
        ...prevState,
        serviceMenuList: HLQuickList,
        isServiceMainMenu: true,
        serviceObj: ServiceObjData,
      }));
    }
  }, [state.open]);

  useEffect(() => {
    if (
      (secondaryChannelSlice.functionalityCode ||
        locationState.functionalityCode) === "04LDQSD"
    ) {
      let item = HLQuickList[0];
      let tempList: ServiceList[] = getList(item.title);
      setState((prevState) => ({
        ...prevState,
        open: true,
        serviceMenuList: tempList,
        serviceObj: { title: item.title, preIcon: item.preIcon },
        isServiceMainMenu: false,
      }));
    }
  }, []);

  const handleServiceBackBtn = () => {
    setState((prevState) => ({
      ...prevState,
      serviceObj: ServiceObjData,
      isServiceMainMenu: true,
      serviceMenuList: HLQuickList,
    }));
  };

  // Quick Action Sub menu list Start

  const serviceItem = (
    <Stack
      display="flex"
      rowGap={1}
      justifyContent="center"
      alignItems="center"
      width={"100%"}
    >
      <img
        src={ServiceArrowIcon}
        style={{
          transform: state.open ? "rotate(180deg)" : "none",
          cursor: "pointer",
        }}
        alt="service-icon"
        className="service-drawer-arrow"
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            open: !state.open,
          }))
        }
      />
      <Box display="flex" alignItems="center" width="100%" marginTop="1rem">
        {state.serviceObj.title !== "Quick Actions" ? (
          <KeyboardArrowLeftIcon
            sx={{ color: Colors.red, cursor: "pointer" }}
            onClick={handleServiceBackBtn}
          />
        ) : null}
        <Stack
          direction="row"
          columnGap={1}
          display="flex"
          alignItems="center"
          flexGrow={1}
          justifyContent="center"
          marginY={state.open ? ".625rem" : 0}
          marginRight={
            state.serviceObj.title !== "Quick Actions" ? "1.5rem" : "0"
          }
        >
          <img
            src={state.serviceObj.preIcon}
            className="service-header-icon"
            alt="service-header-icon"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                open: !state.open,
              }))
            }
            style={{ cursor: "pointer" }}
          />
          <Typography
            textTransform="uppercase"
            variant="h5"
            onClick={() =>
              setState((prevState) => ({
                ...prevState,
                open: !state.open,
              }))
            }
            sx={{ cursor: "pointer" }}
          >
            {state.serviceObj.title}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );

  // Quick Action list Start

  const quickActionMenuBody = (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        {serviceItem}
      </Box>
      <Box className="list-wrapper">
        {state.serviceMenuList &&
          state.serviceMenuList.map((item) => (
            <Box
              className="list-item"
              key={item.title}
              onClick={() =>
                (deviceType === DEVICE_TYPE.WEB ||
                  deviceType === DEVICE_TYPE.PWA_WEB) &&
                item.isNavIcon &&
                handleClickListItem(item)
              }
            >
              <Box display="flex" alignItems="center">
                <img
                  src={item.preIcon}
                  className="list-icon"
                  alt={`${item.title}-icon`}
                />
                <Typography variant="body1">{item.title}</Typography>
              </Box>

              <Box display="flex" alignItems="center">
                {item.secondIcon && (
                  <>
                    {isBtnLoading === item.title && docType === "Download" ? (
                      <CustomCircularLoader size={20} marginRight=".625rem" />
                    ) : (
                      <img
                        src={item.secondIcon}
                        className="list-icon"
                        alt="second-icon"
                        onClick={() => handleDownloadListItem(item)}
                      />
                    )}
                  </>
                )}

                {(deviceType === DEVICE_TYPE.MOBILE ||
                  deviceType === DEVICE_TYPE.PWA_MOBILE ||
                  !item.isNavIcon) && (
                  <>
                    {isBtnLoading === item.title && docType === "Email" ? (
                      <CustomCircularLoader size={20} />
                    ) : (
                      <item.postIcon
                        onClick={() => handleClickListItem(item)}
                        fontSize="small"
                        sx={{ color: Colors.red }}
                      />
                    )}
                  </>
                )}
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );

  // Quick Action list End

  const goToServiceRequest = useCallback(() => {
    setIsExist(false);
    // View service request page not available
    // navigate(R_HLRoutes.view_service_requests);
  }, []);

  return (
    <>
      <Stack display="flex" justifyContent="center" rowGap={1}>
        <Box className="service-drawer-btn">{serviceItem}</Box>
        {width >= tabViewPoint ? (
          <CustomPopup
            open={state.open}
            handleOpen={() => {
              setState((prevState) => ({
                ...prevState,
                open: !state.open,
              }));
            }}
            className="service-menu-popup"
            btnLabel="Close"
            btnStartIcon={<CloseIcon sx={{ fontSize: "1.0625rem" }} />}
          >
            {quickActionMenuBody}
          </CustomPopup>
        ) : (
          <SwipeableDrawer
            anchor="bottom"
            open={state.open}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                open: false,
              }))
            }
            onOpen={() =>
              setState((prevState) => ({
                ...prevState,
                open: false,
              }))
            }
            className="service-menu-drawer"
            sx={{
              ".MuiPaper-root": {
                left: { xs: "1rem", sm: "1rem", md: "25%" },
                right: { xs: "1rem", sm: "1rem", md: "25%" },
              },
            }}
          >
            {quickActionMenuBody}
          </SwipeableDrawer>
        )}
      </Stack>
      <Stack>
        <CustomFailure
          title="Service Request Already exists"
          btnLabel="View all service requests"
          handleClick={goToServiceRequest}
          open={isExist}
        />
        {isApiSuccess && (
          <CustomPopup
            open={isApiSuccess}
            handleOpen={handlePopupOpen}
            className="download-statement-popup"
          >
            <CommonSuccessPopupBody
              title={
                docType === "Email"
                  ? `${documentName} will be emailed to your registered email ID shortly`
                  : `${documentName} has been successfully downloaded `
              }
              des={""}
              subDescription={`The password to the document is PAN of loan's main applicant`}
              btnLabel="Go back to homepage"
              btnOnClick={() => navigate("/home")}
              btnDisabled={false}
              extraInfo={
                <Box className="hl-request-success-info">
                  <Box className="hl-request-success-info-box">
                    <Typography
                      variant="subtitle2"
                      className="hl-request-success-info-title"
                    >
                      Service Request Number
                    </Typography>
                    <Box
                      display={"flex"}
                      flexDirection="row"
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {generatedSrNumber}
                      </Typography>

                      <img src={ContentCopyIcon} onClick={copyText} />
                    </Box>
                    {showTick && <Typography variant="h6">Copied!</Typography>}
                  </Box>
                </Box>
              }
              isChangesForHomeLoan={true}
            />
          </CustomPopup>
        )}
      </Stack>
    </>
  );
};

export default HLQuickActionsMenuComponent;
