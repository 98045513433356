import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import { useLazyFetchPolicyInsuredQuery } from "./HealthInsurance_Api";
import { useEffect } from "react";
import { setHiAllPolicyInsuredDetails } from "./HealthInsurance_Slice";
import {
  E_ChannelOptions,
  T_HiRouteState,
} from "../../globalTypes/GlobalTypes";
import NotFound from "../../pages/NotFound/NotFound";
import { updateLoadingState } from "../Feature_Loading/Loading_Slice";
import { isEmpty } from "lodash";

const HiDocumentGuard = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const csAppSlice = useAppSelector((st) => st.CsAppSlice);
  const policyNumber = (state as T_HiRouteState)?.policyNumber || "";
  const [fetchPolicyInsuredApi, { isError, error }] =
    useLazyFetchPolicyInsuredQuery();
  const policyInsuredDetails = useAppSelector(
    (st) => st.HealthInsuranceSlice.allPolicyInsuredDetails
  );

  useEffect(() => {
    if (isEmpty(policyInsuredDetails)) {
      dispatch(updateLoadingState(true));
      fetchPolicyInsuredApi(policyNumber, true).then((res) => {
        dispatch(updateLoadingState(false));
        dispatch(
          setHiAllPolicyInsuredDetails(
            res?.data?.payload?.Response ? [res?.data?.payload?.Response] : []
          )
        );
      });
    }
  }, [policyInsuredDetails]);

  return isError || error ? (
    <NotFound hideButton={csAppSlice.channel != E_ChannelOptions.CSAPP} />
  ) : isEmpty(policyInsuredDetails) ? (
    <></>
  ) : (
    <Outlet />
  );
};

export default HiDocumentGuard;
