import { FC } from "react";
import { Box, Typography } from "@mui/material";
import reloadIcon from "../../assets/icons/reload.svg";
import { Colors } from "../../styles/constant";
import { defaultEmptyFun } from "../../utils/helper";

interface Props {
  title: string;
  titleSize?: number | string;
  onClick?: any;
  className?:string;
}

const CustomRefresh: FC<Props> = ({
  title = "",
  titleSize = ".875rem",
  onClick = defaultEmptyFun,
  className="",
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={onClick}
      gap={"0.25rem"}
      className={className}
    >
      <Typography
        color={Colors.red}
        fontSize={titleSize}
        fontWeight="500"
        variant="h5"
      >
        {title}
      </Typography>
      <img
        src={reloadIcon}
        alt="reload-icon"
        style={{ color: Colors.red }}
      />
    </Box>
  );
};

export default CustomRefresh;
