import { Box, Typography } from "@mui/material";
import { Colors } from "../../../styles/constant";
import { FC, useCallback } from "react";
import { hlTermsandConditions as descriptionByParagraph } from "../../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import "../../Feature_GeneralAppElements/PolicyAndConditionsPage/PolicyAndConditionsPage.scss";
import "./TermsAndConditions.scss";

const TermsAndConditions: FC = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    return navigate(-1);
  }, []);

  return (
    <Box className="terms-conditions-page terms-conditions-emi-page">
      <Box className="terms-conditions-body">
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" className="body-title">
            Terms and conditions
          </Typography>
          <CloseIcon
            sx={{
              fontSize: "1rem",
              color: Colors.descriptionGray,
              cursor: "pointer",
              marginBottom: "1.5rem",
            }}
            onClick={handleClick}
          />
        </Box>
        <Box>
          {descriptionByParagraph &&
            descriptionByParagraph.map((paragraph) => (
              <Typography
                key={paragraph}
                className="body-description"
                variant="body1"
                sx={{
                  marginBottom:"0.4rem"
                }}
              >
                {paragraph}
              </Typography>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
