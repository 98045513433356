export enum API_STATUS_CODE {
  SUCCESS = 1,
  ERROR = 0,
  BUSINESS_ERROR = 2,
}

export const LI_API_BASE_URL = process.env.REACT_APP_LI_API_BASE_URL;
export const MF_API_BASE_URL = process.env.REACT_APP_MF_API_BASE_URL;
export const ABFSSL_API_BASE_URL = process.env.REACT_APP_ABFSSL_API_BASE_URL;
export const LOGIN_SERVICE_API_BASE_URL =
  process.env.REACT_APP_LOGIN_SERVICE_API_BASE_URL;
export const HI_API_BASE_URL = process.env.REACT_APP_HI_API_BASE_URL;
export const STOCKS_API_BASE_URL = process.env.REACT_APP_STOCK_API_BASE_URL;

export const HL_API_BASE_URL = process.env.REACT_APP_HL_API_BASE_URL;
export const BASE_URL = process.env.REACT_APP_ABCD_SUPERAPP_URL;

export const Api_GenerateToken = ABFSSL_API_BASE_URL + `/auth/generate-token`;
export const Api_GenerateInternalToken =
  ABFSSL_API_BASE_URL + `/auth/generate-internal-token`;
export const Api_GenerateOtp =
  LOGIN_SERVICE_API_BASE_URL + `/onboard/generate-otp`;
export const Api_ValidateOtp =
  LOGIN_SERVICE_API_BASE_URL + `/onboard/validate-otp`;
export const Api_GetCustomerLobDetails =
  ABFSSL_API_BASE_URL + `/abfssl/fetch-customer-and-lob-details`;
export const Api_FetchConsent = ABFSSL_API_BASE_URL + `/abfssl/fetch-consent`;
export const Api_SaveConsent = ABFSSL_API_BASE_URL + `/abfssl/save-consent`;
export const Api_CreateMpin =
  ABFSSL_API_BASE_URL + `/mpin-management/create-mpin`;
export const Api_ValidateMpin =
  ABFSSL_API_BASE_URL + `/mpin-management/validate-mpin`;
export const Api_UpdateMpin =
  ABFSSL_API_BASE_URL + `/mpin-management/update-mpin`;
export const Api_TeraDataUserRegistration =
  ABFSSL_API_BASE_URL + `/abfssl/teradata-user-registration`;
export const Api_FetchConfigData =
  ABFSSL_API_BASE_URL + `/utility/fetch-config-data`;
export const Api_FileUpload = ABFSSL_API_BASE_URL + `/utility/sr/submit`;
export const Api_FetchPopularService =
  ABFSSL_API_BASE_URL + `/abfssl/fetch-popular-services`;
export const Api_FetchSearchHistory =
  ABFSSL_API_BASE_URL + `/abfssl/fetch-search-history`;
export const Api_FetchSearchBarContent =
  ABFSSL_API_BASE_URL + `/abfssl/fetch-search-bar-content`;
export const Api_SaveSearchHistory =
  ABFSSL_API_BASE_URL + `/abfssl/save-search-history`;
export const Api_ClearSearchHistory =
  ABFSSL_API_BASE_URL + `/abfssl/clear-search-history`;
export const Api_FetchMasterData =
  ABFSSL_API_BASE_URL + "/abfssl/fetch-master-data";
export const Api_SaveHoldings = ABFSSL_API_BASE_URL + "/abfssl/save-holdings";
export const Api_CheckCiiExistsForMobileEmail =
  ABFSSL_API_BASE_URL + "/abfssl/check-login-id";
export const Api_MapLobProduct =
  ABFSSL_API_BASE_URL + "/abfssl/map-lob-product";
export const Api_CheckLoginId = ABFSSL_API_BASE_URL + `/abfssl/check-login-id`;
export const Api_FetchProfile_Details =
  ABFSSL_API_BASE_URL + `/abfssl/fetch-profile-details`;

export const Api_ValidateSso_New =
  LOGIN_SERVICE_API_BASE_URL + "/access-management/validate-internal-sso-token";
export const Api_GenerateSso_New =
  LOGIN_SERVICE_API_BASE_URL + "/access-management/generate-internal-sso-token";
export const Api_GenerateSso_Old =
  LOGIN_SERVICE_API_BASE_URL + "/access-management/generate-sso-token";
export const Api_ValidateSso_Old =
  LOGIN_SERVICE_API_BASE_URL + "/access-management/validate-sso-token";

// life Insurance APIs
export const Api_FetchDashboardDetails =
  LI_API_BASE_URL + `/li/fetch-dashboard-details`;
export const Api_FetchPolicyAllDetails =
  LI_API_BASE_URL + `/li/fetch-policy-all-details`;
export const Api_DownloadTaxCertificate =
  LI_API_BASE_URL + `/li/post-certificate`;
export const APi_DownloadPolicyAccountStatement =
  LI_API_BASE_URL + `/li/policy-account-statement`;
export const Api_FetchPolicyDetails =
  LI_API_BASE_URL + `/li/fetch-policy-details`;
export const Api_FetchFundDetails = LI_API_BASE_URL + `/li/fund-details`;
export const Api_FetchAnnuityDetails =
  LI_API_BASE_URL + `/li/fetch-annuity-details`;
export const Api_FetchPolBankDetails = LI_API_BASE_URL + `/li/pol-bank-details`;
export const Api_FetchBondPolicy = LI_API_BASE_URL + `/li/fetch-bond-policy`;
export const Api_FetchPastPaymentDetails =
  LI_API_BASE_URL + `/li/past-payment-details`;
export const Api_DownloadPremium_Receipt =
  LI_API_BASE_URL + `/li/fetch-premium-receipt`;
export const Api_FetchPremium_Receipt_WithDate =
  LI_API_BASE_URL + `/li/fetch-premium-receipt-with-date`;
export const Api_LogOut = ABFSSL_API_BASE_URL + `/auth/logout`;
export const Api_RateUs = ABFSSL_API_BASE_URL + `/abfssl/save-rating`;
export const Api_SendEmail = LI_API_BASE_URL + `/li/send-document-email`;
export const Api_SendWhatsApp = LI_API_BASE_URL + `/li/send-document-whatsapp`;
export const Api_WhatsappOpt = LI_API_BASE_URL + `/li/whatsapp-opt-inout`;
export const Api_FetchRecentUpdates =
  LI_API_BASE_URL + `/li/fetch-recent-updates`;
export const Api_FetchNSDLPANDetails =
  LI_API_BASE_URL + `/li/fetch-nsdl-pan-details?recordsCount=1`;
export const Api_CrmCaseCreate = LI_API_BASE_URL + `/li/crm-case-create`;
export const Api_LiUploadDoc = LI_API_BASE_URL + `/li/upload-doc`;
export const Api_LiGetOtp = LI_API_BASE_URL + `/li/get-otp`;
export const Api_LiValidateOtp = LI_API_BASE_URL + `/li/validate-otp`;
export const Api_LiFundDetails = LI_API_BASE_URL + `/li/fund-details`;
export const Api_LiSetAlertExisting =
  LI_API_BASE_URL + `/li/set-alert-existing`;
export const Api_LiWhatsappOptIn = LI_API_BASE_URL + `/li/whatsapp-opt-inout`;

//Mutual Fund APIs
export const Api_ExitLoad = MF_API_BASE_URL + `/mf/exit-load-amount`;
export const Api_DownloadCapitalStatement =
  MF_API_BASE_URL + `/mf/fetch-capital-gain-statement`;
export const Api_ValidatePan = MF_API_BASE_URL + `/mf/validate-pan`;
export const Api_FoliosSummary =
  MF_API_BASE_URL + `/mf/fetch-portfolio-by-folio`;
export const APi_FolioSchemeSummary =
  MF_API_BASE_URL + `/mf/fetch-folio-scheme-summary`;
export const Api_AccountStatement =
  MF_API_BASE_URL + `/mf/fetch-account-statement`;
export const Api_LastTransactionBroker =
  MF_API_BASE_URL + `/mf/last-transaction-broker`;
export const Api_FolioLinkingDelinking =
  MF_API_BASE_URL + `/mf/folio-linking-delinking`;
export const Api_DelinkFolioList = MF_API_BASE_URL + `/mf/delink-folio-list`;

//health Insurance Apis
export const Api_FetchPolicyDetailsByMobileNumber =
  HI_API_BASE_URL + `/hi/policy-details-by-mobile-number`;
export const Api_PolicyInsured = HI_API_BASE_URL + `/hi/insured-policy-api`;
export const Api_DownloadECard = HI_API_BASE_URL + `/hi/e-card-download`;
export const Api_DownloadPolicyDocs =
  HI_API_BASE_URL + `/hi/omni-docs-search-and-download`;
export const Api_DownloadPolicyWording = HI_API_BASE_URL + `/hi/policy-wording`;
export const Api_UpdateHIDetails = HI_API_BASE_URL + `/hi/case-create`;

///stock api
export const API_FetchPortfolio =
  STOCKS_API_BASE_URL + `/stocks/fetch-portfolio-from-pan-or-mobile`;
export const API_DownloadStatementOfTransaction =
  STOCKS_API_BASE_URL + `/stocks/fetch-aditya-financial-statement-pdf`;
export const API_CapitalGainStatementDownload =
  STOCKS_API_BASE_URL + `/stocks/fetch-capital-gain`;
export const API_DownloadStatementOfAccount =
  STOCKS_API_BASE_URL + `/stocks/fetch-cash-info-statement`;
export const API_DownloadContractNote =
  STOCKS_API_BASE_URL + `/stocks/fetch-contract-file-pdf`;

// CMS
export const APi_CMS_GetHamburgerMenu =
  BASE_URL + `/specs/cms/profile-hamburger-menus`;
export const APi_CMS_PageVariables = BASE_URL + `/specs/cms/page-variables`;
export const APi_CMS_IntegrationVariables =
  BASE_URL + `/specs/cms/integration-variables`;
export const APi_CMS_ErrorDetails = BASE_URL + `/specs/cms/fetch-error-details`;
export const APi_CMS_RateUsContent = BASE_URL + `/specs/cms/rate-us`;
export const API_CMS_FAQ_Parent_ModuleList =
  BASE_URL + `/specs/cms/parent-modules`;
export const API_CMS_FAQ_ModuleList = BASE_URL + `/specs/cms/modules`;
export const API_CMS_FAQ_CategoryList = BASE_URL + `/specs/cms/faq-categories`;
export const API_CMS_FAQ_List = BASE_URL + `/specs/cms/faqs`;
export const APi_CMS_PageMessages = BASE_URL + `/specs/cms/page-messages`;

// home loan API
export const Api_Dataservice = HL_API_BASE_URL + `/data-services`;
export const Api_Fetchlan = HL_API_BASE_URL + `/fetch-lan`;
export const Api_Createcase = HL_API_BASE_URL + `/create-case`;
export const Api_ReportingService = HL_API_BASE_URL + `/reporting-service`;
export const Api_Case_Create = HL_API_BASE_URL + `/create-case`;
export const Api_Create_Order = HL_API_BASE_URL + `/create-order`;
export const Api_Order_Status = HL_API_BASE_URL + `/order-status`;
export const Api_Create_Case_Query =
  HL_API_BASE_URL + `/create-case-query-multiple`;
