import { FC, useEffect } from "react";
import {
  T_GenericSSORedirectPayload,
  T_HISSORedirectPayload,
  TriggerGenerateSSO_OLD,
} from "../Sso_Types";
import {
  useLazyGenerateSso_OldQuery,
  useLazyRedirectUrlApiQuery,
} from "../Sso_Api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import { API_STATUS_CODE } from "../../../utils/ApiEndpoints";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import UnableToNavigate from "../../../pages/UnableToNavigate/UnableToNavigate";
import { useNavigate } from "react-router-dom";
import { clearSsoSlice, disableShowLoading } from "../Sso_Slice";
import {
  HiDestinationLobId,
  LiDestinationLobId,
  LiLobCode,
  MfDestinationLobId,
  MfLobCode,
  OneLoginLobCode,
} from "../../../utils/constants";
import { nanoid } from "@reduxjs/toolkit";
import { T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import { R_LiRoutes } from "../../Feature_LifeInsurance/LifeInsurance_Routes";

interface RequestDataBase {
  TokenNo: string;
  TokenRefNo: string;
}

interface ExtendedRequestData extends RequestDataBase {
  UserId?: string; // Optional property
  ApiPassword?: string; // Optional property
}

const GenerateSsoOld: FC = () => {
  const ssoSlice = useAppSelector(
    (state) => state.SsoSlice.ssoData as TriggerGenerateSSO_OLD
  );
  const csAppSlice = useAppSelector((state) => state.CsAppSlice);

  const [redirectionApiCall] = useLazyRedirectUrlApiQuery();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [
    callGenerateSSOOld,
    { data: generateSsoOldData, isFetching, isUninitialized },
  ] = useLazyGenerateSso_OldQuery();
  useEffect(() => {
    if (ssoSlice.appVersion) {
      const reqPayload = { ...ssoSlice };
      reqPayload.udp1 = csAppSlice.traceId;
      reqPayload.udp2 =
        reqPayload?.destinationLobId === "03" ? reqPayload.panNumber : "";
      reqPayload.udp3 = reqPayload.mobileNo;
      reqPayload.udp4 = "REQ" + nanoid(9);
      callGenerateSSOOld(reqPayload);
    }
  }, [ssoSlice.triggerGenerateSSO_Old]);
  useEffect(() => {
    if (isFetching || isUninitialized) {
      return;
    }
    if (
      generateSsoOldData?.code === API_STATUS_CODE.SUCCESS &&
      generateSsoOldData.payload?.ReturnCode === "1"
    ) {
      const redirectionUrl = ssoSlice.redirectionUrl;
      const genericRequestData: T_GenericSSORedirectPayload = {
        TokenNo: generateSsoOldData.payload.TokenNo,
        TokenRefNo: generateSsoOldData.payload.TokenRefNo,
      };
      let headers = { "Content-Type": "application/x-www-form-urlencoded" };
      let requestData;
      if (ssoSlice.destinationLobId === HiDestinationLobId) {
        // Add additional parameters if the condition is met
        genericRequestData.UserId = ssoSlice?.userId; // Replace ssoSlice.userId with the actual source of userId
        genericRequestData.ApiPassword = ssoSlice.apiPassword; // Replace ssoSlice.apiPassword
        headers = { "Content-Type": "application/json" };
        const hiRequestData: T_HISSORedirectPayload = {
          ValidateTokenRequest: {
            ...genericRequestData,
          },
        };
        requestData = { ...hiRequestData };
      } else {
        requestData = { ...genericRequestData };
      }

      redirectionApiCall({
        url: redirectionUrl,
        type: "POST",
        headers: headers,
        data: requestData,
      }).then((r: any) => {
        dispatch(updateLoadingState(false));
        console.log("response: ", r);
        if (
          [LiDestinationLobId, MfDestinationLobId].includes(
            "" + ssoSlice.destinationLobId
          )
        ) {
          console.log("li headers: ", r.data?.headers.responseUrl);
          window.open(r.data?.headers?.responseUrl, "_self");
        }
        if (ssoSlice.destinationLobId === HiDestinationLobId) {
          window.open(r?.data?.payload, "_self");
        } else if (ssoSlice.redirectionUrl == OneLoginLobCode) {
          window.open(ssoSlice.redirectionUrl);
          dispatch({ type: "USER_LOGOUT" });
        }
        dispatch(clearSsoSlice());
        if (r.error) {
          if (
            ["CI", "SC", "RST", "CT", "ST", "EI"].includes(
              ssoSlice.functionality
            )
          ) {
            navigate(R_LiRoutes.li_UrlNotFound);
          } else {
            const err: Error = new Error("Generate SSO failed");
            dispatch(
              setError({
                error: r.error as T_ApiResponse<any>,
                message: err.message,
              })
            );
          }
        }
      });
    } else {
      dispatch(disableShowLoading());
      dispatch(updateLoadingState(false));
      if (
        ["CI", "SC", "RST", "CT", "ST", "EI"].includes(ssoSlice.functionality)
      ) {
        navigate(R_LiRoutes.li_UrlNotFound);
      } else {
        const err: Error = new Error("Generate SSO failed");
        dispatch(
          setError({
            error: err,
            message: `${"" + generateSsoOldData?.rootCause}`,
          })
        );
      }
    }
  }, [isFetching]);
  return <></>;
};
export default GenerateSsoOld;
