import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useLazyFetchFolioSchemeSummaryQuery,
  useLazyValidatePanQuery,
} from "./MutualFund_API";
import { API_STATUS_CODE } from "../../utils/ApiEndpoints";
import { setFolioDetails, setFolioSchemeDetails } from "./MutualFund_Slice";
import { T_Folio, T_ValidatePan_Request } from "./MutualFund_Types";
import NotFound from "../../pages/NotFound/NotFound";
import { E_ChannelOptions } from "../../globalTypes/GlobalTypes";
import { updateLoadingState } from "../Feature_Loading/Loading_Slice";
import { isEmpty } from "lodash";
import { T_ValidateSSOOldRes } from "../Feature_SSO/Sso_Types";

const MutualFundGuard = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const csAppSlice = useAppSelector((st) => st.CsAppSlice);
  const userDetails = csAppSlice.userDetails;
  const ssoSlice = useAppSelector((st) => st.SsoSlice.ssoData);
  const [isEmptyFolio, setIsEmptyFolio] = useState(false);
  const folioDetails = useAppSelector((st) => st.MutualFundSlice.folioDetails);
  const [folioListData, setFolioListData] = useState<T_Folio[]>(
    folioDetails || []
  );

  const folioSchemeDetails = useAppSelector(
    (st) => st.MutualFundSlice.folioSchemeDetails
  );

  const [
    fetchValidatePanApi,
    { isError: isErrorValidatePan, isSuccess: isSuccessValidatePan },
  ] = useLazyValidatePanQuery();
  const [
    fetchFolioSchemeSummaryDetails,
    { isError: isErrorFolioScheme, isSuccess: isSuccessFolioScheme },
  ] = useLazyFetchFolioSchemeSummaryQuery();

  let panNumber = state?.panNumber || userDetails?.pan || "";
  if (isEmpty(panNumber) && csAppSlice.channel !== E_ChannelOptions.CSAPP) {
    panNumber =
      csAppSlice.userDetails?.customerAndLobDetails?.find((f) =>
        (ssoSlice as T_ValidateSSOOldRes)?.lobCode?.includes(f.LOB_CODE)
      )?.PAN || "";
  }

  const fetchData = async () => {
    let apiBody: T_ValidatePan_Request = {
      PANNo: panNumber,
      AadharNo: null,
      DOB: "",
      ClientIpAddress: null,
      IMEI: null,
      OS: null,
      IsNew: null,
      isMinor: null,
      Channel: null,
      PageURL: null,
      Campaign: null,
      Trans_No: null,
      TransactionType: null,
      ServerIpAddress: null,
      ModuleName: null,
      MethodName: null,
      AuthCode: null,
      GoogleAdID: null,
      DeviceId: null,
      UserName: null,
      isFolioRequired: true,
    };
    await fetchValidatePanApi(apiBody, false).then((res) => {
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        let folioList = res?.data?.payload.Folios || [];
        dispatch(setFolioDetails(folioList));
        setFolioListData(folioList);
        if (folioList.length > 0) {
          let folioString = folioList.map((item) => item.Folio_No).join(",");
          const apiBody_schemeSummary = {
            P_FOLIO_NO: folioString,
            P_PAN_NO: panNumber || "",
            P_IS_MINOR: "",
          };
          fetchFolioSchemeSummaryDetails(apiBody_schemeSummary).then(
            (resData) => {
              if (resData.data?.code === API_STATUS_CODE.SUCCESS) {
                const schemeDetails = resData.data?.payload.REF_CUR;
                dispatch(setFolioSchemeDetails(schemeDetails));
              }
            }
          );
        } else {
          setIsEmptyFolio(true);
        }
      }
    });
  };

  useEffect(() => {
    if (isEmpty(folioSchemeDetails) && panNumber) {
      dispatch(updateLoadingState(true));
      fetchData();
    } else {
      dispatch(updateLoadingState(false));
    }
  }, []);

  useEffect(() => {
    if (isSuccessValidatePan && isSuccessFolioScheme) {
      dispatch(updateLoadingState(false));
    }
  }, [isSuccessValidatePan, isSuccessFolioScheme]);

  return isErrorValidatePan || isErrorFolioScheme || isEmptyFolio ? (
    <NotFound hideButton={csAppSlice.channel != E_ChannelOptions.CSAPP} />
  ) : isEmpty(folioSchemeDetails) ? (
    <></>
  ) : (
    <Outlet />
  );
};

export default MutualFundGuard;
