import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../redux-store/CustomReduxHooks";
import { updateSecondaryChannelState } from "./SecondaryChannel_Slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getDecryptedSearchParamsValue } from "../../utils/helper";
import { updateChannelDetails } from "../../redux-store/reducers/CsAppSlice";
import {
  E_CampaignIdentifierKeys,
  E_ChannelOptions,
  J_FunctionalityCode,
  T_CampaignHeaders,
} from "../../globalTypes/GlobalTypes";
import CsSessionStorageUtils from "../../utils/CsSessionStorageUtils";
import { updateLoadingState } from "../Feature_Loading/Loading_Slice";
import NotFound from "../../pages/NotFound/NotFound";

const Feature_SecondaryChannel: FC = () => {
  const [urlSearchParam] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    try {
      dispatch(updateLoadingState(true));
      const request = urlSearchParam.get("request") || "";
      if (request.length === 0 || !request) {
        // this condition will prevent the old url to execute
        dispatch(updateLoadingState(false));
        setIsError(true);
        return;
      }
      let requestData: any = getDecryptedSearchParamsValue(request);
      // if (request.length === 0 || !request) {  // This condition will use for old url to work
      //   requestData.functionalityCode =
      //     urlSearchParam.get("functionalityCode") || "";
      //   requestData.policyNumber = urlSearchParam.get("policyNumber") || "";
      //   requestData.source = urlSearchParam.get("source") || "";
      //   requestData.panNumber = urlSearchParam.get("panNumber") || "";
      //   requestData.mobileNo = urlSearchParam.get("mobileNo") || "";
      // }
      const campaignRelatedParams: T_CampaignHeaders[] = [];
      const campaignIdentifierKeys = [
        E_CampaignIdentifierKeys.utm_source,
        E_CampaignIdentifierKeys.utm_medium,
        E_CampaignIdentifierKeys.utm_campaign,
        E_CampaignIdentifierKeys.utm_term,
        E_CampaignIdentifierKeys.utm_content,
        E_CampaignIdentifierKeys.utm_id,
        E_CampaignIdentifierKeys.utm_adid,
        E_CampaignIdentifierKeys.utm_adgroup,
      ];
      campaignIdentifierKeys.forEach((identifierKey: string) => {
        campaignRelatedParams.push({
          key: identifierKey,
          value: requestData[identifierKey] || "", // urlSearchParam.get(identifierKey) add this as or condition for old url to work
        });
      });
      CsSessionStorageUtils.set(
        "CAMPAIGN_IDENTIFIERS",
        JSON.stringify(campaignRelatedParams)
      );
      const routeDetails = J_FunctionalityCode.find(
        (f) => f.funcCode === requestData.functionalityCode
      );

      let urlConstructed = routeDetails?.route || "";
      const dynamicState: any = {};
      let dynamicKeys = routeDetails?.dynamicKeys || [];
      if (dynamicKeys?.length > 0) {
        routeDetails?.dynamicKeys.forEach((d) => {
          dynamicState[d] = requestData[d] || ""; // urlSearchParam.get(d) add this as or condition for old url to work
        });
      }
      dispatch(updateChannelDetails(E_ChannelOptions.SECONDARY_CHANNEL));
      dispatch(
        updateSecondaryChannelState({
          functionalityCode: requestData.functionalityCode,
          policyNo: requestData.policyNumber || "",
          source: requestData.source,
          constructedAppUrl: urlConstructed,
          panNumber: requestData.panNumber || "",
          dynamicState: dynamicState,
          mobileNo: requestData.mobileNo,
        })
      );
      navigate(urlConstructed, { state: { ...dynamicState }, replace: true });
    } catch (e) {
      dispatch(updateLoadingState(false));
      setIsError(true);
      return;
    }
  }, []);

  return isError ? <NotFound hideButton={true} /> : <></>;
};
export default Feature_SecondaryChannel;
