import React, { useState, useEffect } from "react";
import CustomShimmer from "../CustomShimmer/CustomShimmer";

interface ImageComponentProps {
  src: string;
  alt: string;
  style?: React.CSSProperties;
  fallBackImage?: JSX.Element; 
}

const CustomNetworkImage: React.FC<ImageComponentProps> = ({
  src,
  alt,
  style,
  fallBackImage
}) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const image = new Image();
    image.src = src;

    image.onload = () => {
      setImageSrc(src);
      setIsLoading(false);
    };

    image.onerror = () => {
      setHasError(true);
    };

    return () => {
      setImageSrc(null);
      setIsLoading(true);
      setHasError(false);
    };
  }, [src]);

  // Render fallback immediately if there’s an error
  if (hasError) {
    return <div style={{ display: "flex", justifyContent: "center" }}>{fallBackImage}</div>;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {isLoading && fallBackImage}
      <img
        src={imageSrc || src}
        alt={alt}
        loading="lazy"
        onError={() => setHasError(true)}
        onLoad={() => setIsLoading(false)}
        style={{
          ...style,
          display: isLoading ? "none" : "block",
          transition: "opacity 0.3s ease",
          opacity: isLoading ? 0 : 1,
        }}
      />
    </div>
  );
};

export default CustomNetworkImage;
