import { Box, Button, Container, Grid, Typography, styled } from "@mui/material";
import { FC, useEffect, useState } from "react";
import "./IntroWithBlurBackground.scss";
import coinIcon from "../../../assets/images/coinIcon.svg";
import rightArrowThinIcon from "../../../assets/icons/arrow-right-thin.svg"
import arrowDottedLines from "../../../assets/icons/arrow-dotted-lines.svg";
import { Colors } from "../../../styles/constant";
import CsLocalStorageUtils from "../../../utils/CsLocalStorageUtils";


const BackgroundColor = styled(Box)({  
    position: 'fixed',  
    top: 0,  
    left: 0,  
    width: '100%',  
    height: '100%',  
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background color  
    backdropFilter: 'blur(10px)', // Blur effect  
    zIndex: -1,  
  }); 

  interface Props {
    handleClose: Function;
  }


const IntroWithBlurBackground: FC<Props>=({handleClose})=>{

  const [contentVisible, setContentVisible] = useState(false); 

  useEffect(() => {  
    const timer = setTimeout(() => {  
      setContentVisible(true);  
    }, 1000); // 2 seconds delay for content  
  
    return () => clearTimeout(timer);  
  }, []);

  const handleBtnClick = ()=>{
    CsLocalStorageUtils.clear('SHOW_INTRO');
    handleClose(false);
  }


    return <Box sx={{ position: 'absolute', width: '100%', top: 0, height: '100%', overflow: 'hidden', zIndex:99 }} className="intro-with-background-box">
        <BackgroundColor />
        <Box className="intro-content-box">
          <Box className="intro-content-red-background-box" />
          <img src={arrowDottedLines} alt="arrow-dotted-lines" className={`intro-content-arrow-dotted-lines ${contentVisible ? 'visible':''}`} />
          <Container className="intro-content-section" sx={{paddingX: '1.58rem'}}>
            <Box height="8.125rem" display="flex" alignItems="center" justifyContent="center" flexDirection="row" gap="0.25rem" marginBottom={'.9375rem'}>
              <Box height="100%"><img src={coinIcon} alt="coin-icon" height="100%"></img></Box>
              <Box height="100%">
                <Box display="flex" alignItems="flex-start" flexDirection="column" height="100%" paddingY={".4375rem"} rowGap="1rem">
                  <Typography color={Colors.white} variant="h2">
                  Switch between existing holdings and purchase
                  </Typography>
                  <Button
          className="intro-content-close-btn"
          variant="contained"
          onClick={handleBtnClick}
        >
          Got it
          <img src={rightArrowThinIcon} alt="right=arrow-icon" className="intro-content-close-btn-icon" />
        </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
         </Box>
}


export default IntroWithBlurBackground;