import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  Api_CreateMpin,
  Api_FetchConsent,
  Api_GenerateInternalToken,
  Api_GenerateOtp,
  Api_GenerateToken,
  Api_GetCustomerLobDetails,
  Api_ValidateMpin,
  Api_ValidateOtp,
} from "../../utils/ApiEndpoints";
import {
  T_CheckConsent,
  T_CreateMpinPay,
  T_CreateMpinRes,
  T_GenerateOtpPay,
  T_GenerateOtpRes,
  T_GenerateTokenRes,
  T_GetCustomerLobDetailsPay,
  T_GetCustomerLobDetailsRes,
  T_InternalTokenRes,
  T_ValidateMpinPay,
  T_ValidateOtpPay,
  T_ValidateOtpRes,
} from "./Login_Types";
export const loginRtk = CSApiService.injectEndpoints({
  overrideExisting: "throw",
  endpoints: (builder) => ({
    generateToken: builder.query<T_ApiResponse<T_GenerateTokenRes>, void>({
      query: () => ({ url: Api_GenerateToken }),
    }),
    getInternalToken: builder.query<T_ApiResponse<T_InternalTokenRes>, string>({
      query: (csUserId) => ({
        url: Api_GenerateInternalToken,
        headers: {
          csUserId: csUserId,
        },
      }),
    }),
    generateOtp: builder.query<
      T_ApiResponse<T_GenerateOtpRes>,
      T_GenerateOtpPay
    >({
      query: (payload) => ({
        url: Api_GenerateOtp,
        method: "POST",
        body: {
          mobileNo: payload.mobileNo,
          otp: "",
        },
      }),
    }),
    validateOtp: builder.query<
      T_ApiResponse<T_ValidateOtpRes>,
      T_ValidateOtpPay
    >({
      query: (payload) => ({
        url: Api_ValidateOtp,
        method: "POST",
        body: payload,
      }),
    }),
    getCustomerLobDetails: builder.query<
      T_ApiResponse<T_GetCustomerLobDetailsRes>,
      T_GetCustomerLobDetailsPay
    >({
      providesTags: ["CUSTOMER_LOB_DETAILS"],
      query: (payload) => ({
        url: Api_GetCustomerLobDetails,
        method: "POST",
        body: payload,
      }),
    }),
    createMpin: builder.query<T_ApiResponse<T_CreateMpinRes>, T_CreateMpinPay>({
      query: (payload) => ({
        url: Api_CreateMpin,
        method: "POST",
        body: payload,
      }),
    }),
    validateMpin: builder.query<
      T_ApiResponse<T_ValidateOtpRes>,
      T_ValidateMpinPay
    >({
      query: (payload) => ({
        url: Api_ValidateMpin,
        method: "POST",
        body: payload,
      }),
    }),
    checkIfUserConsentRequired: builder.query<
      T_ApiResponse<T_CheckConsent>,
      string
    >({
      query: (mobileNumber) => ({
        url: Api_FetchConsent,
        method: "POST",
        body: { mobileNumber },
      }),
    }),
  }),
});

export const {
  useLazyGenerateOtpQuery,
  useLazyValidateOtpQuery,
  useLazyGenerateTokenQuery,
  useGenerateTokenQuery,
  useLazyGetCustomerLobDetailsQuery,
  useLazyCreateMpinQuery,
  useLazyValidateMpinQuery,
  useLazyGetInternalTokenQuery,
  useLazyCheckIfUserConsentRequiredQuery,
} = loginRtk;
