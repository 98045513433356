import { useCallback, useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { Box, Button, Container, Typography } from "@mui/material";
import CallUsPopUp from "../CallUsPopUp/CallUsPopUp";
import EmailUsPopUp from "../EmailUsPopUp/EmailUsPopUp";
import FAQsMobile from "../FAQsMobile/FAQsMobile";
import CustomChatWithUs from "../../../components/CustomChatWithUs/CustomChatWithUs";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import HelpChatIcon from "../../../assets/icons/help-chat.svg";
import { Colors } from "../../../styles/constant";
import { setCategoryList } from "../GeneralAppElements_Slice";

export const FAQCommon = ({ activeTab = "" }) => {
  const selectedModule = useAppSelector(
    (state) => state.GeneralAppElementsSlice.selectedModule
  );

  const [isCallUsOpen, setIsCallUsOpen] = useState(false);
  const [isEmailUsOpen, setIsEmailUsOpen] = useState(false);
  const [showChatWithUs, setShowChatWithUs] = useState(false);
  const [isShowFaq, setIsShowFaq] = useState<boolean>(false);
  const [isFaqOpen, setIsFaqOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  // Handle button click to generate JWT and load ChatBot
  const handleChatClick = () => {
    setShowChatWithUs(true);
  };

  const handleCloseChat = () => {
    setShowChatWithUs(false);
  };

  const handleFaqClick = useCallback(() => {
    setIsFaqOpen((prevState) => !prevState);
  }, []);

  useEffect(() => {
    dispatch(setCategoryList([]));
  }, [activeTab]);

  return (
    <Box className="bottom-section">
      <Box display="flex" flexDirection="column" alignItems="center">
        <img
          src={HelpChatIcon}
          alt="help-chat"
          style={{
            backgroundColor: "#FEF6F7",
            borderRadius: "50%",
            padding: ".625rem",
          }}
        />
        <Typography marginTop="1rem" variant="h5">
          We are just a tap away. Always
        </Typography>
      </Box>
      <Container sx={{ marginTop: "1rem" }}>
        <Box
          gap="1rem"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            fullWidth
            variant="outlined"
            startIcon={
              <ContactSupportIcon
                sx={{ color: Colors.red, fontSize: "1.75rem" }}
              />
            }
            onClick={handleFaqClick}
          >
            FAQs
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={
              <HeadsetMicIcon sx={{ color: Colors.red, fontSize: "1.75rem" }} />
            }
            onClick={handleChatClick}
          >
            Chat with Us
          </Button>
        </Box>
        <Box
          gap="1rem"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          marginTop={"1rem"}
        >
          <Button
            fullWidth
            variant="outlined"
            startIcon={
              <CallIcon sx={{ color: Colors.red, fontSize: "1.75rem" }} />
            }
            onClick={() => setIsCallUsOpen(true)}
          >
            Call Us
          </Button>
          <Button
            fullWidth
            variant="outlined"
            startIcon={
              <EmailIcon sx={{ color: Colors.red, fontSize: "1.75rem" }} />
            }
            onClick={() => setIsEmailUsOpen(true)}
          >
            Email Us
          </Button>
        </Box>
      </Container>
      {isCallUsOpen && (
        <CallUsPopUp open={isCallUsOpen} handleOpen={setIsCallUsOpen} />
      )}
      {isEmailUsOpen && (
        <EmailUsPopUp open={isEmailUsOpen} handleOpen={setIsEmailUsOpen} />
      )}
      {isFaqOpen && (
        <FAQsMobile
          open={isFaqOpen}
          handleOpen={handleFaqClick}
          openChatWithUs={handleChatClick}
          activeTab={activeTab}
        />
      )}
      {showChatWithUs && (
        <CustomChatWithUs
          showIframe={showChatWithUs}
          onClose={handleCloseChat}
          functionalityCode="D"
          LobId="25"
        />
      )}
    </Box>
  );
};

export default FAQCommon;
