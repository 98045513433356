import { FC, useEffect, useState } from "react";
import "./HiUploadIdentityDocument.scss";
import { Box, Typography, Button, IconButton } from "@mui/material";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import useWindowSize from "../../../hooks/useWindowSize";
import { Colors } from "../../../styles/constant";
import { tabViewPoint } from "../../../utils/constants";
import { getBase64, getFileSize } from "../../../utils/helper";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "../../../assets/icons/upload-doc-icon.svg";
import screenShotIcon from "../../../assets/icons/screenshot-icon.svg";
import UploadedIcon from "../../../assets/icons/UploadIcon.svg";
import CameraUploadIcon from "../../../assets/icons/camera-upload-icon.svg";
import CameraUploadedIcon from "../../../assets/icons/camera-uploaded-icon.svg";
import PdfFileExtensionTypeIcon from "../../../assets/icons/file-pdf-box.svg";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";

interface UploadIdentityDocProps {
  showUploadIdentityDoc: boolean;
  handleClick: Function;
  handleComponent: (show: boolean) => void;
  title?: string;
  subTitle?: string;
  uploadMessage?: string;
  stepVal?: number;
  proposerValues?: object;
  flow?: string;
  isBtnLoading?: boolean;
}

const HiUploadIdentityDocument: FC<UploadIdentityDocProps> = ({
  showUploadIdentityDoc,
  handleComponent,
  title = "",
  subTitle = "",
  uploadMessage = "",
  handleClick,
  stepVal,
  proposerValues = {},
  flow = "",
  isBtnLoading,
}) => {
  {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [byteArray, setByteArray] = useState<any>("");
    const [fileType, setFileType] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const { width } = useWindowSize();
    const MAX_FILE_SIZE_MB = 10;
    const getFileExtension = (filename: string): string => {
      const parts = filename.split(".");
      if (parts.length > 1) {
        return parts[parts.length - 1].toUpperCase();
      }
      return "";
    };

    const handleFileUpload = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const file = event.target.files?.[0];
      if (file?.name) {
        // setFileName(file.name.split(".")[0]); as we need the entire filename with extension
        setFileName(file.name);
        setFileType(getFileExtension(file?.name));
        if (
          !["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(
            file?.type
          )
        ) {
          return alert(`File type ${file?.type} not supported`);
        }
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
          alert(`File size exceeds ${MAX_FILE_SIZE_MB} MB limit.`);
          event.target.value = "";
          setSelectedFile(null);
        } else {
          setSelectedFile(file);
        }
        const bae64StringFromFile = await getBase64(file);
        setByteArray(bae64StringFromFile || "");
      }
    };

    const handleDeleteFile = () => {
      setSelectedFile(null);
    };

    const handleContinue = async () => {
      if (!selectedFile?.name) {
        alert("file is required");
        return;
      }
      const formData = new FormData();
      formData.append("file", selectedFile || "");
      if (Object.keys(proposerValues).length > 0)
        handleClick(
          {
            attachmentContent: byteArray,
            attachmentType: fileType,
            attachmentFileName: fileName,
          },
          stepVal,
          proposerValues,
          flow
        );
      else
        handleClick(
          {
            attachmentContent: byteArray,
            attachmentType: fileType,
            attachmentFileName: fileName,
          },
          stepVal
        );
    };

    useEffect(() => {
      if (!showUploadIdentityDoc) {
        setSelectedFile(null);
      }
    }, [showUploadIdentityDoc]);

    return (
      <>
        <Box
          className={`upload-identity-doc-popup ${
            width < tabViewPoint ? "mobile-view" : "web-view"
          } `}
        >
          <Box className="upload-identity-doc-box">
            <Typography
              variant="h5"
              letterSpacing=".0938rem"
              textAlign="center"
              marginBottom={title === "UPLOAD YOUR PAN" ? "" : ".5rem"}
              color={Colors.darkCharcoal}
            >
              {title || "UPLOAD IDENTITY PROOF"}
            </Typography>

            <Typography
              variant="h5"
              fontWeight={400}
              textAlign={"center"}
              color={Colors.darkGray}
              marginBottom={"1.5rem"}
            >
              {title === "UPLOAD YOUR PAN"
                ? ""
                : subTitle || "Please upload the selected identity proof"}
            </Typography>

            <Box
              sx={{
                borderRadius: "1.5rem",
                marginBottom: "1.5rem",
                backgroundColor: selectedFile?.name ? "#F1F3F6" : "white",
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23D5D7DE' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e")`,
              }}
              width="100%"
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={"1rem 1rem"}
              >
                <Box>
                  <Typography variant="h5" color={Colors.darkCharcoal}>
                    {uploadMessage || `Upload your current identity proof`}
                  </Typography>
                  <Typography
                    variant="h6"
                    fontWeight={400}
                    marginTop="0.5rem"
                    color={Colors.quartz}
                  >
                    JPG/JPEG/PNG/PDF Files <br /> (up to 10MB)
                  </Typography>
                </Box>
                {/* <Box>
                  <Button
                    component="label"
                    variant="text"
                    startIcon={
                      <img
                        src={selectedFile?.name ? CameraUploadedIcon : CameraUploadIcon}
                      />
                    }
                    htmlFor="upload-image"
                    className="upload-button"
                  >
                    <input
                      style={{ display: "none" }}
                      id="upload-image"
                      hidden
                      accept="image/jpeg, image/png, application/pdf"
                      type="file"
                      multiple={false}
                      onChange={handleFileUpload}
                    />
                    <Typography
                      variant="h5"
                      color={Colors.descriptionGray}
                      marginTop=".5rem"
                    >
                      Camera
                    </Typography>
                  </Button>
                </Box> */}
                <Box>
                  <Button
                    component="label"
                    variant="text"
                    startIcon={
                      <img
                        src={selectedFile?.name ? UploadedIcon : UploadIcon}
                      />
                    }
                    htmlFor="upload-image"
                    className="upload-button"
                  >
                    <input
                      style={{ display: "none" }}
                      id="upload-image"
                      hidden
                      accept="image/jpeg, image/png, application/pdf"
                      type="file"
                      multiple={false}
                      onChange={handleFileUpload}
                      disabled={!!selectedFile?.name}
                    />
                    <Typography variant="h5" color={Colors.descriptionGray}>
                      Upload
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
            {selectedFile && (
              <Box width="100%">
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    variant="h5"
                    marginBottom=".5rem"
                    marginLeft=".3125rem"
                    color={Colors.darkCharcoal}
                  >
                    Uploads:
                  </Typography>
                  <Typography
                    variant="h6"
                    lineHeight=".9rem"
                    marginBottom=".5rem"
                    marginRight=".5rem"
                    color={"#AFB3C0"}
                  >
                    Added 1 of 1
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  marginBottom="1.5rem"
                  sx={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "1.5rem",
                    padding: ".75rem 1rem",
                  }}
                >
                  <Box display="flex" alignItems="center" gap=".5rem">
                    <Box className="upload-file-icon-wrapper">
                      <img
                        src={
                          getFileExtension(selectedFile.name).toLowerCase() ===
                          "pdf"
                            ? PdfFileExtensionTypeIcon
                            : screenShotIcon
                        }
                        alt="screenshot"
                      />
                    </Box>
                    <Box>
                      <Typography variant="h5" sx={{ wordBreak: "break-all" }}>
                        {selectedFile.name.split(".")[0]}
                      </Typography>
                      <Typography
                        variant="h6"
                        lineHeight="1.125rem"
                        color="#797979"
                      >
                        {`${getFileExtension(selectedFile.name)} document`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" gap=".625rem">
                    <Typography variant="h6">
                      {getFileSize(selectedFile)}
                    </Typography>
                    <IconButton
                      onClick={handleDeleteFile}
                      color="inherit"
                      size="small"
                      sx={{ color: "#D04A02", padding: 0, cursor: "pointer" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            )}
            {isBtnLoading ? (
              <CustomThreeDotsLoader />
            ) : (
              <Button
                disabled={!selectedFile}
                variant="contained"
                fullWidth
                onClick={handleContinue}
                sx={{
                  borderRadius: "4.25rem",
                  padding: "1rem 2.625rem",
                  margin: "0 auto", // Center the button horizontally
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Continue
              </Button>
            )}
          </Box>
        </Box>
      </>
    );
  }
};

export default HiUploadIdentityDocument;
