import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptString } from "../../../utils/helper";
import { clearSsoSlice, updateSSOSlice } from "../Sso_Slice";
import { useAppDispatch } from "../../../redux-store/CustomReduxHooks";
import {
  saveCsUserId,
  updateChannelDetails,
  updateTraceId,
} from "../../../redux-store/reducers/CsAppSlice";
import {
  E_ChannelOptions,
  J_FunctionalityCode,
} from "../../../globalTypes/GlobalTypes";
import { T_ValidateSSOOldRes } from "../Sso_Types";
import { updateLoginData } from "../../Feature_Login/Login_Slice";
import { isEmpty } from "lodash";
import NotFound from "../../../pages/NotFound/NotFound";

const ValidateSsoOld: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const appDispatch = useAppDispatch();
  const [isError, setIsError] = useState<number>(1);
  useEffect(() => {
    try {
      const searchData = location.search;
      const responseParam = searchData.split("response=");
      const response = responseParam[1];
      console.log(response);
      const decryptedValue2 = decryptString(
        response,
        "1234567891234567",
        "2619AD481DFBB08C"
      );
      console.log("********", decryptedValue2);
      const jsonValue = JSON.parse(decryptedValue2);
      console.log(jsonValue);
      const validateSsoOldData = jsonValue as T_ValidateSSOOldRes;
      if (validateSsoOldData?.sourceLobId === "25") {
        appDispatch(updateChannelDetails(E_ChannelOptions.SUPER_APP));
      } else {
        appDispatch(updateChannelDetails(E_ChannelOptions.ONE_ABC));
      }
      console.log(validateSsoOldData);
      if (validateSsoOldData?.traceId && !isEmpty(validateSsoOldData.traceId)) {
        appDispatch(updateTraceId(validateSsoOldData.traceId));
      }
      if (
        validateSsoOldData?.csUserId &&
        !isEmpty(validateSsoOldData.csUserId)
      ) {
        appDispatch(saveCsUserId(validateSsoOldData.csUserId));
      }
      appDispatch(
        updateLoginData({
          key: "isUserLoggedIn",
          value: true,
        })
      );
      const routeDetails = J_FunctionalityCode.find(
        (f) => f.funcCode === validateSsoOldData.functionality
      );
      const navStateData: any = {};
      routeDetails?.dynamicKeys.forEach((d) => {
        navStateData[d] = jsonValue[d] || jsonValue["policyNo"];
      });
      console.info(navStateData, routeDetails);
      validateSsoOldData.urlConstructured = "" + routeDetails?.route;
      validateSsoOldData.navigationParams = JSON.stringify(navStateData);
      appDispatch(updateSSOSlice(validateSsoOldData));
      console.log(validateSsoOldData.urlConstructured);
      navigate("" + validateSsoOldData.urlConstructured, {
        state: { ...navStateData },
        replace: true,
      });
    } catch (e) {
      appDispatch(clearSsoSlice());
      setIsError((prev) => prev + 1);
      console.log(e);
    }
  }, []);
  return isError ? <NotFound hideButton={true} /> : <></>;
};
export default ValidateSsoOld;
