import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { API_STATUS_CODE } from "../../utils/ApiEndpoints";
import NotFound from "../../pages/NotFound/NotFound";
import { E_ChannelOptions } from "../../globalTypes/GlobalTypes";
import { updateLoadingState } from "../Feature_Loading/Loading_Slice";
import { isEmpty } from "lodash";
import {
  useLazyFetchDataServiceDetailsQuery,
  useLazyFetchLanDetailsQuery,
} from "./Homeloan_Api";
import { T_HlDataServiceApiRequest, T_SummaryData } from "./Homeloan_Types";
import { setHomeLoanInfo, setSummaryData } from "./Homeloan_Slice";

const HomeLoanGuard = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const csAppSlice = useAppSelector((st) => st.CsAppSlice);
  const secondaryChannelSlice = useAppSelector(
    (state) => state.SecondaryChannelSlice
  );
  const userDetails = csAppSlice.userDetails;
  const panNumber = secondaryChannelSlice?.panNumber || userDetails?.pan || "";
  const accountNumber = state?.accountNumber || "";
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const loanInfo = useAppSelector((state) => state.HomeLoanSlice.loanInfo);
  const [
    fetchLanDetailsApi,
    { isSuccess: isSuccessLanDetails, isError: isErrorLanDetails },
  ] = useLazyFetchLanDetailsQuery();
  const [fetchDataServiceDetailsApi, { isSuccess, isError }] =
    useLazyFetchDataServiceDetailsQuery();

  useEffect(() => {
    if (
      (isEmpty(allSummaryData) || isEmpty(loanInfo.rows)) &&
      panNumber &&
      accountNumber
    ) {
      dispatch(updateLoadingState(true));
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    await fetchLanDetailsApi({ pan: panNumber }).then((res) => {
      if (
        res.data?.code === API_STATUS_CODE.SUCCESS &&
        !isEmpty(res.data.payload.LOAN_ACCOUNT_NUMBER) &&
        res.data.payload.LOAN_ACCOUNT_NUMBER.includes(accountNumber)
      ) {
        const apiRequest: T_HlDataServiceApiRequest = {
          CCC: "",
          LAN: [
            {
              ID: accountNumber,
            },
          ],
        };
        fetchDataServiceDetailsApi(apiRequest).then((res) => {
          if (
            res.data?.code === API_STATUS_CODE.SUCCESS &&
            !isEmpty(res.data?.payload.data.Summary_Data)
          ) {
            dispatch(setSummaryData(res.data?.payload.data.Summary_Data));
            let rows = res.data?.payload.data.Summary_Data?.[0].loan_info.rows;
            dispatch(
              setHomeLoanInfo({
                totalRows: `${rows.length}`,
                moreRows: "false",
                rows,
              })
            );
          }
        });
      }
    });
  };

  useEffect(() => {
    if (isSuccess && isSuccessLanDetails) {
      dispatch(updateLoadingState(false));
    }
  }, [isSuccess, isSuccessLanDetails]);

  return isError || isErrorLanDetails ? (
    <NotFound hideButton={csAppSlice.channel != E_ChannelOptions.CSAPP} />
  ) : isEmpty(allSummaryData) || isEmpty(loanInfo.rows) ? (
    <></>
  ) : (
    <Outlet />
  );
};

export default HomeLoanGuard;
