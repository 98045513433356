import React, { FC, useCallback, useState } from "react";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import { useNavigate } from "react-router-dom";
import { R_HomeRoutes } from "../../../pages/Home/Home_Routes";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import LoadingDots from "../../../assets/gif/Loader_dots.json";

interface Props {
  handleClick: Function;
}

const Paymentsuccess_page: FC<Props> = ({ handleClick }) => {
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setShowLoader((prevState) => !prevState);
    setIsSuccess((prevState) => !prevState);
    navigate(R_HomeRoutes.home, { replace: true });
  }, []);

  return (
    <CustomPopup
      open={isSuccess}
      handleOpen={handleClose}
      className={`hl-common-policy-popup-box`}
    >
      {showLoader ? (
        <CommonGifAnimation animationData={LoadingDots} heightCustom="1rem" />
      ) : (
        <CommonSuccessPopupBody
          title={"Your payment is successful"}
          des={""}
          btnLabel={"Go back to homepage"}
          btnOnClick={handleClose}
        />
      )}
    </CustomPopup>
  );
};
export default React.memo(Paymentsuccess_page);
