import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type T_SecondaryChannelSliceState = {
  source: string;
  functionalityCode: string;
  policyNo: string;
  constructedAppUrl: string;
  panNumber?: string;
  mobileNo?: string;
  dynamicState?: { panNumber?: string; policyNumber?: string };
};

const initialState: T_SecondaryChannelSliceState = {
  source: "",
  functionalityCode: "",
  policyNo: "",
  constructedAppUrl: "",
  panNumber: "",
  mobileNo: "",
  dynamicState: {},
};

export const SecondaryChannelSlice = createSlice({
  name: "SecondaryChannelSlice",
  initialState: initialState,
  reducers: {
    updateSecondaryChannelState: (
      state: T_SecondaryChannelSliceState,
      action: PayloadAction<T_SecondaryChannelSliceState>
    ) => {
      return { ...action.payload };
    },
  },
});

export const { reducer: SecondaryChannelSliceReducer } = SecondaryChannelSlice;
export const SecondaryChannelSliceKey = SecondaryChannelSlice.reducerPath;
export const { updateSecondaryChannelState } = SecondaryChannelSlice.actions;
