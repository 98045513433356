import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { T_ProfileHamburgerMenu } from "./ProfileHamburgerMenu.Types";
import { T_CmsIntegrationVariables } from "./CmsIntegrationVariable.Types";
import { T_CmsPageVariables } from "./CmsPageVariable.Types";
import { T_CmsErrorVariable } from "./CmsErrorVariables.Types";
import pageVariablesDefault from "../../assets/testData/CMS_Defaults/pageVariables.json";
import integrationVariablesDefault from "../../assets/testData/CMS_Defaults/integrationVariables.json";
import hamburgerMenusDefault from "../../assets/testData/CMS_Defaults/hamburgerMenu.json";
import errorDefault from "../../assets/testData/CMS_Defaults/errorDetails.json";
import { T_CmsPageMessage } from "./CmsPageMessages.Types";

const pageVariablesDefaultMap = pageVariablesDefault.map((r) => {
  return {
    ...r,
    attributes: { ...r.attributes, slNo: Number(r.attributes.slNo) },
  };
});
const initialState: {
  ProfileHamburgerMenuData: T_ProfileHamburgerMenu[];
  CmsIntegrationVariableData: T_CmsIntegrationVariables[];
  CmsPageVariableData: T_CmsPageVariables[];
  CmsErrorVariablesData: T_CmsErrorVariable[];
  CmsPageMessagesData: T_CmsPageMessage[];
} = {
  ProfileHamburgerMenuData: hamburgerMenusDefault as T_ProfileHamburgerMenu[],
  CmsIntegrationVariableData:
    integrationVariablesDefault as T_CmsIntegrationVariables[],
  CmsPageVariableData: pageVariablesDefaultMap as T_CmsPageVariables[],
  CmsErrorVariablesData: errorDefault as T_CmsErrorVariable[],
  CmsPageMessagesData: []
};

const CmsSlice = createSlice({
  name: "CmsSlice",
  initialState: initialState,
  reducers: {
    setProfileHamburgerMenu(
      state,
      action: PayloadAction<T_ProfileHamburgerMenu[]>
    ) {
      state.ProfileHamburgerMenuData = action.payload;
    },
    setPageVariables(state, action: PayloadAction<T_CmsPageVariables[]>) {
      state.CmsPageVariableData = action.payload;
    },
    setIntegrationVariables(
      state,
      action: PayloadAction<T_CmsIntegrationVariables[]>
    ) {
      state.CmsIntegrationVariableData = action.payload;
    },
    setErrorVariables(state, action: PayloadAction<T_CmsErrorVariable[]>) {
      state.CmsErrorVariablesData = action.payload;
    },
    setPageMessage(state, action: PayloadAction<T_CmsPageMessage[]>) {
      state.CmsPageMessagesData = action.payload;
    },
  },
});

export const {
  setProfileHamburgerMenu,
  setPageVariables,
  setIntegrationVariables,
  setErrorVariables,
  setPageMessage
} = CmsSlice.actions;

export const CmsReducer = CmsSlice.reducer;
export const CmsKey = CmsSlice.reducerPath;
