import { FC, Fragment, useState } from "react";
import CustomPopup from "../CustomPopup/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Colors } from "../../styles/constant";
import CustomThreeDotsLoader from "../CustomThreeDotsLoader/CustomThreeDotsLoader";
import { PolicyOrAccountSelectionData } from "../../globalTypes/GlobalTypes";
import { HlLobCode } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

interface Props {
  data: PolicyOrAccountSelectionData;
  policyOrAccountDataHandler: Function;
}

const CustomPolicyOrAccountSelect: FC<Props> = ({
  data,
  policyOrAccountDataHandler,
}) => {
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>("");
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleCloseClick = () => {
    let tempData = { ...data };
    tempData.isOpen = false;
    tempData.lobDetails = null;
    tempData.redirectionUrl = "";
    tempData.options = [];
    tempData.functionalityCode = "";
    policyOrAccountDataHandler(tempData);
  };

  const handleContinueClick = () => {
    let dynamicState: any = {};
    if (data.lobDetails?.lob === HlLobCode) {
      dynamicState.accountNumber = selectedRadioValue;
      dynamicState.functionalityCode = data.functionalityCode;
    } else {
      dynamicState.policyNumber = selectedRadioValue;
    }
    navigate(data.redirectionUrl, { state: dynamicState });
  };

  return (
    <CustomPopup
      open={data.isOpen}
      handleOpen={handleCloseClick}
      btnLabel={"Close"}
      className="common-custom-popup-style custom-policy-or-account-select "
      btnStartIcon={<CloseIcon sx={{ fontSize: "1.0625rem" }} />}
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Typography
          textTransform="uppercase"
          variant="h5"
          letterSpacing="0.0938rem"
          textAlign="center"
          marginY="1.5rem"
        >
          {data.lobDetails?.lob === HlLobCode
            ? "Select Account Number"
            : "Select Policy Number"}
        </Typography>
        <RadioGroup sx={{ gap: "1rem", width: "100%" }} className="radio-group">
          {data.options.map((item: string, index) => (
            <Fragment>
              <Stack
                key={item}
                sx={{
                  borderRadius: ".75rem",
                  padding: ".75rem 0rem",
                }}
                flexDirection="row"
                display="flex"
                justifyContent="space-between"
                onClick={() => setSelectedRadioValue(item)}
              >
                <Box display="flex" alignItems="center">
                  <Typography
                    marginLeft=".625rem"
                    variant="h4"
                    fontWeight={500}
                  >
                    {item}
                  </Typography>
                </Box>
                <Radio
                  checked={item === selectedRadioValue}
                  sx={{
                    padding: "0 1rem",
                    color: Colors.red,
                    "&.Mui-checked": {
                      color: Colors.red,
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                    },
                  }}
                />
              </Stack>
              {index < data.options.length - 1 && <Divider />}
            </Fragment>
          ))}
        </RadioGroup>
      </Box>
      <Box marginTop="2.25rem" paddingBottom="0.5rem">
        {isBtnLoading ? (
          <CustomThreeDotsLoader />
        ) : (
          <Button
            fullWidth
            variant="contained"
            disabled={!selectedRadioValue}
            className="continue-btn"
            onClick={handleContinueClick}
          >
            Continue
          </Button>
        )}
      </Box>
    </CustomPopup>
  );
};

export default CustomPolicyOrAccountSelect;
