import { Box, Button, Typography } from "@mui/material";
import { FC, useCallback, useEffect } from "react";
import NavigationNotFound from "../../assets/images/navigation_not_found_1x.png";
import { Colors } from "../../styles/constant";
import { useNavigate } from "react-router-dom";
import { R_HomeRoutes } from "../Home/Home_Routes";
import { useAppDispatch } from "../../redux-store/CustomReduxHooks";
import { updateLoadingState } from "../../features/Feature_Loading/Loading_Slice";

type NotFoundProps = {
  hideButton?: boolean;
};
const NotFound: FC<NotFoundProps> = ({ hideButton = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateLoadingState(false));
  }, []);

  const gotoHomepage = useCallback(() => {
    navigate(R_HomeRoutes.home, { replace: true });
  }, []);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height="calc(100vh - 200px)"
    >
      <img src={NavigationNotFound} height={"196px"} width={"196px"} />
      <Box marginBlock="2rem" textAlign="center">
        <Typography variant="h3" marginBottom="0.5rem">
          Unable to navigate
        </Typography>
        <Typography variant="h5" color={Colors.textGray}>
          We are facing some technical issue. Please try again after some time 
        </Typography>
      </Box>
      {!hideButton && (
        <Button
          variant="contained"
          sx={{
            padding: "1rem 2rem",
          }}
          onClick={gotoHomepage}
        >
          Go back to homepage
        </Button>
      )}
    </Box>
  );
};
export default NotFound;
