import { RouteObject } from "react-router-dom";
import HiEditPolicy from "./HiEditPolicy/HiEditPolicy";
import HiDocumentGuard from "./HiDocumentGuard";
import { lazy, Suspense } from "react";
import LoadingInstant from "../../pages/Utilities/LoadingInstant";

export enum R_HiRoutes {
  hi_baseUrl = "/details/hi",
  edit_hi_policy_details = "/details/hi/edit",
  details_PolicyNumber_EditPolicy = "/details/hi/edit-policy",
  details_PolicyNumber = "/details/hi",
  details_PolicyNumber_MedicalReports = "/details/hi/medical-reports",
}

const HiDetailsPage = lazy(() => import("./HiDetailsPage/HiDetailsPage"));
const MedicalReports = lazy(() => import("./MedicalReports/MedicalReports"));
const HealthInsuranceRoutesFn = () => {
  return [
    {
      path: R_HiRoutes.hi_baseUrl,
      element: <HiDocumentGuard />,
      children: [
        {
          path: R_HiRoutes.details_PolicyNumber,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <HiDetailsPage />
            </Suspense>
          ),
        },
        {
          path: `${R_HiRoutes.details_PolicyNumber_MedicalReports}`,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <MedicalReports />
            </Suspense>
          ),
        },
        {
          path: `${R_HiRoutes.details_PolicyNumber_EditPolicy}`,
          element: <HiEditPolicy />,
        },
      ],
    },
    {
      path: R_HiRoutes.edit_hi_policy_details,
      element: <HiEditPolicy />,
    },
  ];
};
export { HealthInsuranceRoutesFn };
