import { Box, Typography, Divider } from "@mui/material";
import { FC, useState } from "react";
import PencilIcon from "../../../assets/icons/pencil.svg";
import { useLocation } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import "./ContactDetails.scss";
import EditContactDetailsPopups from "../EditContactDetailsPopups/EditContactDetailsPopups";
import CustomEditField from "../../../components/CustomEditField/CustomEditField";
import { hiEditPolicyContactForm } from "../../../utils/constants";
import { T_LiRouteState } from "../../../globalTypes/GlobalTypes";
import { maskEmail } from "../../../utils/helper";

interface ContactDetails {
  mobileNo: string;
  alternateMobile: string;
  email: string;
}
interface EditContactFormData {
  key: string;
  title: string;
  icon: any;
  type: string;
}

const EditPolicy: FC = () => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [popupFlow, setPopupFlow] = useState<string>("");
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState)?.policyNumber || "";

  const handleClick = (value: any) => {
    if (value == "Mobile") {
      setPopupOpen(true);
      setPopupFlow("Mobile");
    } else if (value == "NomineeContactNumber") {
      setPopupOpen(true);
      setPopupFlow("AlternateMobile");
    } else if (value == "Email") {
      setPopupOpen(true);
      setPopupFlow("Email");
    } else if (value == "Address") {
      setPopupOpen(true);
      setPopupFlow("Address");
    }
  };
  const allPolicyInsuredDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyInsuredDetails
  );
  const selectedPolicy = allPolicyInsuredDetails.find(
    (item) => item.PolicyDetail.PolicyNumber === policyNumber
  );

  const PolicyDetails = selectedPolicy ? selectedPolicy.PolicyDetail : null;

  return (
    <>
      <Box>
        {hiEditPolicyContactForm.map((data: EditContactFormData) => {
          let keyValue = data.key as keyof typeof PolicyDetails;
          return data.key !== "Address" ? (
            <CustomEditField
              key={data.key}
              keyVal={data.key}
              labelValue={data.title}
              value={
                PolicyDetails
                  ? typeof PolicyDetails[keyValue] == "string"
                    ? data.key === "Email"
                      ? maskEmail(PolicyDetails[keyValue])
                      : PolicyDetails[keyValue]
                    : ""
                  : ""
              }
              handleClick={() => handleClick(data.key)}
              srcIcon={data.icon}
              isEdit={false}
              type={data.type}
            />
          ) : (
            <Box className="edit-address-field">
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h5">Address</Typography>
                <img src={PencilIcon} onClick={() => handleClick(data.key)} />
              </Box>
              <Divider sx={{ marginY: ".625rem" }} />
              <Box display="flex" flexDirection="column" marginTop="0.5rem">
                <Typography className="address-title" variant="subtitle2">
                  Address line 1
                </Typography>
                <Typography className="address-details" variant="h5">
                  {PolicyDetails?.HomeAddress?.Home_Address_1}
                </Typography>
                <Typography className="address-title" variant="subtitle2">
                  Address line 2
                </Typography>
                <Typography className="address-details" variant="h5">
                  {PolicyDetails?.HomeAddress?.Home_Address_2}
                </Typography>
                <Typography className="address-title" variant="subtitle2">
                  Pincode, City, State
                </Typography>
                <Typography className="address-details" variant="h5">
                  {PolicyDetails?.HomeAddress?.Home_Pincode},{" "}
                  {PolicyDetails?.HomeAddress?.Home_City},{" "}
                  {PolicyDetails?.HomeAddress?.Home_State}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      <EditContactDetailsPopups
        popupFlow={popupFlow}
        open={popupOpen}
        handleOpen={setPopupOpen}
        details={selectedPolicy}
      />
    </>
  );
};

export default EditPolicy;
