import StocksAndSecuritiesGuard from "./StocksAndSecuritiesGuard";
import { lazy, Suspense } from "react";
import LoadingInstant from "../../pages/Utilities/LoadingInstant";

export enum R_SSRoutes {
  details_baseUrl = "/my-portfolio",
  details_Portfolio = "/my-portfolio",
  details_Portfolio_MyDocuments = "/my-portfolio/my-documents",
  account_statement = "/my-portfolio/my-documents/account_statement",
  contact_notes = "/my-portfolio/my-documents/contacts-notes",
}
const MyPortfolioPage = lazy(
  () => import("../Feature_StocksAndSecurities/MyPortfolioPage/MyPortfolioPage")
);
const MyDocuments = lazy(
  () => import("./MyPortfolioPage/MyDocumentsPage/MyDocuments")
);
const AccountStatement = lazy(
  () =>
    import("../Feature_StocksAndSecurities/AccountStatement/AccountStatement")
);

const StocksSecuritiesRoutesFn = () => {
  return [
    {
      path: R_SSRoutes.details_baseUrl,
      element: <StocksAndSecuritiesGuard />,
      children: [
        {
          path: R_SSRoutes.details_Portfolio,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <MyPortfolioPage />
            </Suspense>
          ),
        },
        {
          path: R_SSRoutes.details_Portfolio_MyDocuments,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <MyDocuments />
            </Suspense>
          ),
        },
        {
          path: R_SSRoutes.account_statement,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <AccountStatement />
            </Suspense>
          ),
        },
        {
          path: R_SSRoutes.contact_notes,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <AccountStatement />
            </Suspense>
          ),
        },
      ],
    },
  ];
};
export { StocksSecuritiesRoutesFn };
