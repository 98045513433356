import { FC } from "react";
import { Box, Typography } from "@mui/material";
import "./CustomSimpleCarouselCard.scss";
import CustomNetworkImage from "../../CustomNetworkImage/CustomNetworkImage";

interface Props {
  title: string;
  description: string;
  imgSrc: string;
}

const CustomSimpleCarouselCard: FC<Props> = ({
  title = "",
  description = "",
  imgSrc,
}) => {
  return (
    <Box className="custom-simple-carousel-card">
      <Box className="custom-simple-carousel-card-content">
        <Box>
          <Typography variant="h3">{title}</Typography>
          <Typography variant="subtitle2" marginTop=".25rem">
            {description}
          </Typography>
        </Box>
        <CustomNetworkImage  alt='' src={imgSrc} />
      </Box>
    </Box>
  );
};

export default CustomSimpleCarouselCard;
