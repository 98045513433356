import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Colors } from "../../styles/constant";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import "./CustomOfferSection.scss";

interface Props {
  title: string;
}

const OfferSection: FC<Props> = ({ title }) => {
  return (
    <Box className="offer-section">
      <Box display="flex" alignItems="center">
        <ArrowUpwardRoundedIcon
          sx={{
            color: "white",
            borderRadius: "50%",
            backgroundColor: Colors.iconYellow,
            fontSize: ".875rem",
            padding: ".125rem",
            marginRight: ".625rem",
          }}
        />
        <Typography variant="h6" color={Colors.yellow}>
          {title}
        </Typography>
      </Box>
      <KeyboardArrowRightIcon sx={{ color: Colors.red }} />
    </Box>
  );
};

export default OfferSection;
