import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./HiEditPolicyTabs.scss";
import ProposerDetails from "../ProposerDetails/ProposerDetails";
import ContactDetails from "../ContactDetails/ContactDetails";
import { hiEditPolicyTabItems } from "../../../utils/constants";
import { Container } from "@mui/material";
import NomineeDetails from "../../Feature_HealthInsurance/NomineeDetails/NomineeDetails";

const HiEditPolicyTabs: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: Object, newValue: number) => {
    setValue(newValue);
  };

  const renderForm = (val: number) => {
    switch (val) {
      case 0:
        return <ProposerDetails />;
      case 1:
        return <ContactDetails />;
      case 2:
        return <NomineeDetails />;
      default:
        break;
    }
  };

  return (
    <Box className="edit-policy-tabs">
      <Container sx={{ paddingRight: "0px !important" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          {hiEditPolicyTabItems.map((data: string) => {
            return <Tab label={data} key={data} />;
          })}
        </Tabs>
      </Container>

      <Container>{renderForm(value)}</Container>
    </Box>
  );
};
export default HiEditPolicyTabs;
