type T_SESSION_KEYS =
  | "LOGGED_IN_TIME"
  | "DASHBOARD_DETAIL_API_FAILED"
  | "DASHBOARD_SECONDARY_API_FAILED"
  | "MOBILE"
  | "ACTIVE_DASHBOARD_TAB"
  | "CAMPAIGN_IDENTIFIERS";

// Define a utility class for managing session storage
class CsSessionStorageUtils {
  // Function to save session data to sessionStorage
  static set<T = any>(key: T_SESSION_KEYS, data: T): void {
    try {
      const serializedData = JSON.stringify(data);
      sessionStorage.setItem(key, serializedData);
    } catch (error) {
      console.error("Error saving session data:", error);
    }
  }

  // Function to retrieve session data from sessionStorage
  static get<T = any>(key: T_SESSION_KEYS): T {
    try {
      const serializedData = sessionStorage.getItem(key);
      if (serializedData === null) {
        return null as T;
      }
      return JSON.parse(serializedData) as T;
    } catch (error) {
      console.error("Error retrieving session data:", error);
      return null as T;
    }
  }

  // Function to clear session data from sessionStorage
  static clear(key: T_SESSION_KEYS): void {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error("Error clearing session data:", error);
    }
  }
}

export default CsSessionStorageUtils;
