import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { Colors } from "../../../../styles/constant";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import dayjs from "dayjs";

type FundValueType = {
  FND_ID: string;
  FUND_NAME: string;
  FUND_VALUE: string;
  NAV: string;
  POL_CSTAT_CD: string;
  POL_ID: string;
  TOT_UNITS: string;
};

interface fundValueLimitProps {
  type: string;
  data: FundValueType[];
  selectedData: number;
  handleFundValueLimitChange: Function;
  index: string;
  withAlert?: boolean;
  validateInputs: Function;
}
interface FundValueLimitAccProps {
  data: FundValueType;
  handleFVLChange: Function;
  withAlert?: boolean;
  validateInputs: Function;
  index?: number;
  handleChange: Function;
  expanded: boolean;
}

const CustomSelectFundValueLimit: FC<fundValueLimitProps> = ({
  type,
  data,
  selectedData,
  index,
  handleFundValueLimitChange,
  withAlert = true,
  validateInputs,
}) => {
  const [validateItems, setValidateItems] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const handleChange = () => {
    handleFundValueLimitChange();
  };

  const validateValues = useCallback(
    (items: string[], value: any) => {
      let emptyVal = [...items].filter((d) => d === "");
      let val = [...items].filter((d) => d === "false");
      if (emptyVal.length !== items.length && val.length === 0) {
        validateInputs(type, true, value.values);
      } else validateInputs(type, false, value.values);
    },
    [type]
  );

  const handleValidation = useCallback(
    (index: number, value: any) => {
      validateItems[index] = value.status;
      setValidateItems(validateItems);
      validateValues(validateItems, value);
    },
    [validateItems]
  );

  const handleCheckBox = (val: number) => {
    setSelectedIndex((prevState) => {
      if (prevState === val) return null;
      return val;
    });
  };

  return (
    <Box className="li-sa-fvl-box">
      <Typography variant="h5">Select Fund</Typography>
      <Box className="li-sa-fvl-box-content" sx={{ width: "100%" }} key={index}>
        {data.length > 0 &&
          data.map((content: FundValueType, index: number) => {
            return (
              <CustomFVLAccordion
                data={content}
                handleFVLChange={handleChange}
                withAlert={withAlert}
                validateInputs={handleValidation}
                index={index}
                handleChange={handleCheckBox}
                expanded={selectedIndex === index}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default CustomSelectFundValueLimit;

const CustomFVLAccordion: FC<FundValueLimitAccProps> = ({
  handleFVLChange,
  data,
  index,
  validateInputs,
  handleChange,
  expanded,
}) => {
  // const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedToggle, setIsSelectedToggle] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [err, setErr] = useState<string>("");

  const handleToggleButtonClick = useCallback(() => {
    setIsSelectedToggle((prevState) => !prevState);
  }, []);

  const handleAccordionChange = useCallback(() => {
    handleChange(index);
  }, []);

  const handlePriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue((e.target as HTMLInputElement).value);
    },
    []
  );

  let validate = "";

  useEffect(() => {
    validate = validateValue();
    validateInputs(index, {
      status: validate,
      values: {
        FundChange: !selectedToggle
          ? "Fund value goes above"
          : "Fund value goes below",
        ChangeAmount: inputValue,
        NAVfundType: data.FUND_NAME,
      },
    });
  }, [expanded, selectedToggle, inputValue, data]);

  const validateValue = useCallback(() => {
    if (expanded) {
      if (inputValue !== "") {
        if (!selectedToggle) {
          if (parseInt(inputValue) > parseInt(data.FUND_VALUE)) {
            setErr("");
            return "true";
          } else {
            setErr("Please enter a value greater than current Fund value");
            return "false";
          }
        } else {
          if (parseInt(inputValue) < parseInt(data.FUND_VALUE)) {
            setErr("");
            return "true";
          } else {
            setErr("Please enter a value Lower than current Fund value");
            return "false";
          }
        }
      } else {
        setErr("Please fill the details");
        return "false";
      }
    } else {
      return "";
    }
  }, [expanded, selectedToggle, inputValue]);

  return (
    <Accordion
      className="custom-fvl-accordion"
      disableGutters={true}
      expanded={expanded}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon sx={{ color: Colors.black }} />}
        aria-controls="panel1-content"
        id="panel1-header"
        className="accordion-summary"
      >
        <Box className="li-sa-fvl-box-content-items-left">
          <Box
            className="li-sa-fvl-box-content-items-left-title"
            // onClick={handleChange}
          >
            <Typography variant="h5">{data.FUND_VALUE}</Typography>
          </Box>
          <Typography
            variant="subtitle2"
            className="li-sa-fvl-box-content-items-left-sub-desc"
          >
            Current Fund Value (As on {dayjs().format("DD-MM-YYYY")})
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails className="accordion-details">
        <Box className="li-sa-fvl-box-content-alert">
          <Typography variant="h5">If Fund Value Moves</Typography>
          <Box className="li-sa-fvl-radio-box">
            <Box className="li-sa-fvl-radio-box-items">
              <Box className="li-sa-fvl-input-box">
                <Box className="li-sa-fvl-radio-box-item">
                  <Typography variant="h5" className="fvl-higherthan">
                    Higher Than
                  </Typography>
                </Box>
                <Box
                  className={`fvl-toggle-line-box ${
                    selectedToggle ? "fvl-toggle-line-box-active" : ""
                  }`}
                  onClick={handleToggleButtonClick}
                >
                  <span
                    className={`fvl-toggle-line  ${
                      selectedToggle ? "fvl-toggle-line-active" : ""
                    }`}
                  >
                    <span
                      className={`fvl-toggle-round ${
                        selectedToggle ? "fvl-toggle-round-active" : ""
                      }`}
                    ></span>
                  </span>
                </Box>
                <Box className="li-sa-fvl-radio-box-item">
                  <Typography variant="h5" className="fvl-lowerthan">
                    Lower Than
                  </Typography>
                </Box>
                <Box>
                  <TextField
                    className="fvl-text-box"
                    value={inputValue}
                    onChange={handlePriceChange}
                  />
                </Box>
              </Box>
              <Typography
                variant="h6"
                sx={{ marginTop: "0.313rem", color: Colors.red }}
              >
                {err}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
