import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import UploadIcon from "../../../assets/icons/UploadIcon.svg";
import { Colors } from "../../../styles/constant";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import "./RaiseABug.scss";
import SuccessGif from "../../../assets/gif/Success.json";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import { T_AnimationData, T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import screenShotIcon from "../../../assets/icons/screenshot-icon.svg";
import { getFileSize } from "../../../utils/helper";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import useWindowSize from "../../../hooks/useWindowSize";
import { tabViewPoint } from "../../../utils/constants";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { useLazyRaiseABugDocUploadQuery } from "../GeneralAppElements_Api";
import useDeviceDetection from "../../../hooks/useDeviceDetection";
import { useDispatch } from "react-redux";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import LoadingDots from "../../../assets/gif/Loader_dots.json";

interface RaiseABugProps {
  showRaiseABug: boolean;
  handleComponent: (show: boolean) => void;
}

interface RaiseABugReqObject {
  csUserId: string;
  mobile: string;
  lob: string;
  category: string;
  description: string;
  pendingWith: string;
  reportedFrom: string;
}

const RaiseABug: React.FC<RaiseABugProps> = ({
  showRaiseABug,
  handleComponent,
}) => {
  const dispatch = useDispatch();
  const [popupOpen, setPopupOpen] = useState(false);
  const [problemText, setProblemText] = useState<string>("");
  const [helperTextProblemText, setHelperTextProblemText] =
    useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const deviceTypeValue = useDeviceDetection();
  const { width } = useWindowSize();
  const MAX_FILE_SIZE_MB = 5;
  const [raiseABugDocUploadApi] = useLazyRaiseABugDocUploadQuery();

  const customerAndLobDetails = useAppSelector(
    (state) => state.CsAppSlice.userDetails
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const problemTextValue = event.target.value;
    if (problemTextValue.length <= 500) {
      setProblemText(problemTextValue);
    }
    setHelperTextProblemText("");
  };

  const getFileExtension = (filename: string): string => {
    const parts = filename.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1].toUpperCase();
    }
    return "";
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file?.name) {
      if (
        !["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(
          file?.type
        )
      ) {
        return alert(`File type ${file?.type} not supported`);
      }
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        alert(`File size exceeds ${MAX_FILE_SIZE_MB} MB limit.`);
        event.target.value = "";
        setSelectedFile(null);
      } else {
        setSelectedFile(file);
      }
    }
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  let raiseABugReq: RaiseABugReqObject = {
    csUserId: customerAndLobDetails?.CS_USER_ID
      ? customerAndLobDetails?.CS_USER_ID
      : "user123",
    mobile: customerAndLobDetails?.customerAndLobDetails
      ? customerAndLobDetails?.customerAndLobDetails[0].MOBILE
      : "",
    lob: customerAndLobDetails?.customerAndLobDetails
      ? customerAndLobDetails?.customerAndLobDetails[0].LOBID
      : "LI",
    category: "Bug",
    description: problemText || "",
    pendingWith: "",
    reportedFrom: deviceTypeValue || "ABFSLLI",
  };

  const handleProceed = async () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("file", selectedFile || "");
    }
    formData.append(
      "serviceRequest",
      new Blob([JSON.stringify(raiseABugReq)], {
        type: "application/json",
      })
    );

    try {
      setShowLoading(true);
      const response = await raiseABugDocUploadApi(formData);
      if (response.data?.payload) {
        setShowLoading(false);
        setPopupOpen(true);
        setProblemText("");
        setSelectedFile(null);
        handleComponent(false);
      } else {
        dispatch(
          setError({
            error: response.data,
            message: "Raise a Bug api failed!!",
          })
        );
        setPopupOpen(false);
      }
    } catch (error) {}
  };
  const successCustomPopupChildren = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          marginLeft: "1rem",
          marginRight: "1rem",
          marginBottom: "2rem",
        }}
      >
        <CommonGifAnimation
          animationData={SuccessGif as T_AnimationData}
          width="15.625rem"
        />
        <Typography variant="h3" marginBottom=".625rem">
          Thanks!
        </Typography>
        <Typography variant="subtitle2">
          Your concern has been submitted successfully
        </Typography>
      </Box>
    );
  };

  useEffect(() => {
    if (!showRaiseABug) {
      setProblemText("");
      setSelectedFile(null);
    }
  }, [showRaiseABug]);

  const handleClose = () => {
    handleComponent(false);
    setHelperTextProblemText("");
  };

  // const isButtonDisabled = !selectedFile === null || problemText.length < 50;

  const isButtonDisabled = !(selectedFile?.name || problemText.length >= 50);

  return (
    <>
      <CustomPopup
        open={showRaiseABug}
        handleOpen={handleClose}
        btnLabel="Go back"
        btnStartIcon={
          <ChevronLeftIcon fontSize="small" sx={{ color: Colors.red }} />
        }
        className={`raise-a-bug-popup ${
          width < tabViewPoint ? "mobile-view" : "web-view"
        } `}
      >
        <Box className="raise-a-bug-box">
          <Typography
            variant="h5"
            letterSpacing=".0938rem"
            textAlign="center"
            marginBottom="1.5rem"
          >
            RAISE A BUG
          </Typography>

          <TextField
            multiline
            fullWidth
            rows={4}
            placeholder="Specify your problem"
            value={problemText}
            className="raise-bug-text-area"
            onChange={handleChange}
            inputProps={{
              maxLength: 500,
              inputMode: "text",
            }}
            error={helperTextProblemText.length > 0}
            helperText={
              <span style={{ display: "flex", alignItems: "center" }}>
                <InputAdornment position="start">
                  {helperTextProblemText.length > 0 && (
                    <img src={AlertRhombus} />
                  )}
                </InputAdornment>
                {helperTextProblemText}
              </span>
            }
          />
          <Box
            sx={{
              border: ".0625rem dashed #D5D7DE",
              background: Colors.bgGray,
              borderRadius: "1.5rem",
            }}
            width="100%"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              margin="1rem"
            >
              <Box>
                <Typography variant="h5" fontWeight={700}>
                  Upload screenshot
                </Typography>
                <Typography variant="h6" marginTop="0.5rem" color={"#000000B2"}>
                  JPG/PNG/PDF/JPEG Files (up to 5MB)
                </Typography>
              </Box>
              <Box>
                <Button
                  component="label"
                  variant="text"
                  startIcon={<img src={UploadIcon} />}
                  htmlFor="upload-image"
                  className="upload-button"
                >
                  <input
                    style={{ display: "none" }}
                    id="upload-image"
                    hidden
                    accept="image/jpeg, image/png, application/pdf"
                    type="file"
                    multiple={false}
                    onChange={handleFileUpload}
                  />
                  <Typography
                    fontFamily="Poppins"
                    fontWeight={600}
                    fontSize=".625rem"
                    lineHeight=".9375rem"
                    textAlign="center"
                    color="#797979"
                    marginTop=".5rem"
                  >
                    Upload
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Typography
            color="#9E9E9E"
            variant="h6"
            fontWeight={500}
            marginTop=".5rem"
            marginBottom="1.5rem"
            marginRight="auto"
          >
            Attaching screenshot will help us assist you better
          </Typography>
          {selectedFile && (
            <Box width="100%">
              <Typography
                variant="h5"
                lineHeight="1.3125rem"
                fontFamily="Poppins"
                marginBottom=".5rem"
              >
                Uploads:
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="1.5rem"
                sx={{
                  backgroundColor: "#F7F7F7",
                  borderRadius: "1.5rem",
                  padding: ".75rem 1rem",
                }}
              >
                <Box display="flex" alignItems="center" gap=".5rem">
                  <Box className="upload-file-icon-wrapper">
                    <img src={screenShotIcon} alt="screenshot" />
                  </Box>
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight={700}
                      fontFamily="Poppins"
                      sx={{ wordBreak: "break-all" }}
                    >
                      {selectedFile.name.split(".")[0]}
                    </Typography>
                    <Typography
                      variant="h6"
                      lineHeight="1.125rem"
                      color="#797979"
                    >
                      {getFileExtension(selectedFile.name)}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" gap=".625rem">
                  <Typography variant="h6">
                    {getFileSize(selectedFile)}
                  </Typography>
                  <IconButton
                    onClick={handleDeleteFile}
                    color="inherit"
                    size="small"
                    sx={{ color: "#D04A02", padding: 0, cursor: "pointer" }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}
          {showLoading ? (
            <CommonGifAnimation
              animationData={LoadingDots}
              heightCustom="1rem"
            />
          ) : (
            <Button
              variant="contained"
              fullWidth
              onClick={handleProceed}
              sx={{
                borderRadius: "4.25rem",
                padding: "1rem 2.625rem",
                margin: "0 auto", // Center the button horizontally
                display: "flex",
                justifyContent: "center",
              }}
              // disabled={problemText.length < 50}
              disabled={isButtonDisabled}
            >
              Proceed Now
            </Button>
          )}
        </Box>
      </CustomPopup>
      <CustomPopup
        open={popupOpen}
        handleOpen={setPopupOpen}
        btnLabel="Go back"
        btnStartIcon={
          <ChevronLeftIcon fontSize="small" sx={{ color: Colors.red }} />
        }
        className={`success-reportBug-popup ${
          width < tabViewPoint ? "mobile-view" : "web-view"
        }`}
      >
        {successCustomPopupChildren()}
      </CustomPopup>
    </>
  );
};

export default RaiseABug;
