import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import "./CustomEditField.scss";

interface CDEreturnValue {
  getValue: () => string;
  focusErr: (errMsg: string) => void;
}

interface ChildComponentProps {
  keyVal: string;
  type: string;
  labelValue: string;
  value: string;
  handleClick: Function;
  srcIcon: any;
  isEdit: boolean;
  validations?: any;
  onblur?: Function;
}

export const CustomEditField = forwardRef<CDEreturnValue, ChildComponentProps>(
  (
    {
      keyVal,
      labelValue,
      type,
      value,
      handleClick,
      srcIcon,
      isEdit = false,
      validations = null,
      onblur = () => {},
    },
    ref
  ) => {
    const [val, setVal] = useState<string>("");
    const [err, setErr] = useState<string>("");

    useEffect(() => {
      setVal(value);
      if (value !== "") setErr("");
    }, [value]);

    useImperativeHandle(ref, () => ({
      getValue: () => val,
      focusErr: (errMsg: string) => {
        setErr(errMsg);
      },
    }));

    return (
      <Box className={`${(keyVal === "Mobile" || keyVal === "NomineeContactNumber") ? "edit-start-icon-only" : " edit-policy-read-only"}`}>
        {!isEdit ? (
          <TextField
            fullWidth
            label={labelValue}
            variant="outlined"
            value={val}
            type={type}
            inputProps={{
              "aria-readonly": true,
              readOnly: !isEdit,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleClick(keyVal, val)}
                >
                  {srcIcon !== null && <img src={srcIcon} />}
                </InputAdornment>
              ),
              startAdornment:keyVal === "Mobile" || keyVal === "NomineeContactNumber" ? <InputAdornment position="start">
                        <Typography variant="h5" color="black">+91</Typography></InputAdornment>
                        : ""
            }}
          />
        ) : (
          <TextField
            fullWidth
            label={labelValue}
            variant="outlined"
            value={val}
            type={type}
            inputProps={{
              "aria-readonly": true,
              readOnly: !isEdit,
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (new RegExp(validations.validation).test(e.target.value)) {
                setVal(e.target.value);
              }
            }}
            onFocus={() => setErr("")}
            helperText={err !== "" ? err : ""}
          />
        )}
      </Box>
    );
  }
);

export default CustomEditField;
