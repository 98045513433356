import { FC, useCallback, useState } from "react";
import "./HomeLoanDetails.scss";
import {
  Box,
  Container,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Colors } from "../../../styles/constant";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { useLocation, useNavigate } from "react-router-dom";
import ABHFLIcon from "../../../assets/icons/ABHFL LOGO 2.svg";
import CalendarIcon from "../../../assets/icons/calendar-today1.svg";
import AlarmIcon from "../../../assets/icons/alarm.svg";
import AmountIcon from "../../../assets/icons/cash-amount.svg";
import InterestIcon from "../../../assets/icons/percent.svg";
import HomeCityIcon from "../../../assets/icons/home-city.svg";
import PhoneIcon from "../../../assets/icons/phone.svg";
import EmailIcon from "../../../assets/icons/email.svg";
import PanIcon from "../../../assets/icons/id-card.svg";
import HandIcon from "../../../assets/icons/hand-coin-grey.svg";
import AccountIcon from "../../../assets/icons/account.svg";
import AccountCashIcon from "../../../assets/icons/account-cash (1).svg";
import MapMarkerCheckIcon from "../../../assets/icons/map-marker-check.svg";
import MapMarkerPlusIcon from "../../../assets/icons/map-marker-plus.svg";
import AccountGroup from "../../../assets/icons/account-group.svg";
import { LoanData, T_SummaryData } from "../Homeloan_Types";
import LoanDetailsSection from "../hlDashboardCard/HomeloanDetailSection";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import HiDetailsPageAccordion from "../../Feature_HealthInsurance/HiDetailsPage/HiDetailsPageAccordion";
import CashRefundIcon from "../../../assets/icons/cash-refund.svg";
import DisplayText from "./DisplayText";
import { getRupeeFormat } from "../hlDashboardCard/HlInformationCard";
import { HlDisclaimer } from "../hlDashboardCard/HlDashboardCard";
import CustomLink from "../../../components/CustomLink/CustomLink";
import { Fragment } from "react/jsx-runtime";
import CustomSimpleCard from "../../../components/CustomSimpleCard/CustomSimpleCard";
import HLQuickActionsMenuComponent from "../HLQuikeActionsComponent/HLQuickActionsComponent";
import TryAgain from "./TryAgain";
import { setEmiPaymentOrderInfo } from "../Homeloan_Slice";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import repaymentDetailsAmountIcon from "../../../assets/icons/disbursed-amt-icon.svg";
import { R_HLRoutes } from "../Homeloan_Routes";

export type HomeloanProfile = Record<string, string | undefined>;

export const HomeLoanPoweredBy = () => {
  return (
    <Stack
      direction="row"
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={"0.8rem"}
      columnGap={1}
      className="home-loan-powered-by"
    >
      <Typography
        variant="h5"
        color={Colors.textGray}
        textAlign="right"
        className="content-text"
      >
        Powered by
      </Typography>
      <img
        src={ABHFLIcon}
        alt="help-icon"
        style={{ width: "1.5rem", height: "1.5rem" }}
      />
    </Stack>
  );
};

export const hlIndexFinder = (
  allSummaryData: T_SummaryData[],
  accountNumber: string | undefined
) => {
  if (!accountNumber) return -1;
  const lanNumber = accountNumber.trim();
  return allSummaryData.findIndex(
    (item) => item.loan_info.rows[0].LOAN_ACCOUNT_NUMBER === lanNumber
  );
};

const HomeloanDetails: FC<any> = () => {
  const [expanded, setExpanded] = useState<string>("");
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  const accountNumber = state?.accountNumber || "";
  const idx = hlIndexFinder(allSummaryData, accountNumber);
  const summaryData = allSummaryData[Number(idx)];

  const isDataAvailable = summaryData !== null && summaryData !== undefined;

  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const promotionalCards = cmsData.filter(
    (i) => i.attributes.pageReference === "hl-page"
  );
  const handleClick = (link: string) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };

  const repaymentDetailsContextArray = isDataAvailable
    ? [
        {
          title: "Disbursal date",
          value: summaryData.Disbursement_History.rows[0].DISBRUSMENT_DATE,
          icon: CalendarIcon,
        },
        {
          title: "Disbursal amount",
          value: getRupeeFormat(
            summaryData.account_detail.rows[0].TOTAL_AMOUNT_DISBURSED
          ),
          icon: repaymentDetailsAmountIcon,
        },
        {
          title: "EMI start date",
          value: summaryData.loan_info.rows[0].EMI_START_DATE,
          icon: CalendarIcon,
        },
        {
          title: "EMI end date",
          value: summaryData.loan_info.rows[0].EMI_END_DATE,
          icon: CalendarIcon,
        },
        {
          title: "Next EMI due date",
          value: summaryData.loan_info.rows[0].NEXT_DUE_DATE ?? "",
          icon: CalendarIcon,
        },
        {
          title: "Repaid amount",
          value: getRupeeFormat(
            summaryData.repayment_detail.rows[0].REPAID_AMOUNT
          ),
          icon: repaymentDetailsAmountIcon,
        },
        {
          title: "EMIs pending",
          value: summaryData.loan_info.rows[0].REMAINING_TENURE,
          icon: AlarmIcon,
        },
        {
          title: "Days past due",
          value: summaryData.account_detail.rows[0].DAYS_PAST_DUE,
          icon: AlarmIcon,
        },
        {
          title: "",
          value: "",
          icon: "",
          divider: true,
        },
        {
          title: "EMI amount",
          value: getRupeeFormat(
            summaryData.account_detail.rows[0].NEXT_DUE_AMOUNT ?? ""
          ),
          icon: repaymentDetailsAmountIcon,
        },
        {
          title: "Overdue with charges",
          value: getRupeeFormat(
            summaryData.loan_info.rows[0].AMOUNT_OVERDUE ?? ""
          ),
          icon: repaymentDetailsAmountIcon,
        },
        {
          title: "Total payable",
          value: getRupeeFormat(
            summaryData.loan_info.rows[0].AMOUNT_OVERDUE ?? ""
          ),
          icon: repaymentDetailsAmountIcon,
        },
      ]
    : [];

  const amountDetailsContextArray = isDataAvailable
    ? [
        // {
        //   title: "Sanctioned date",
        //   value: "",//summaryData.loan_info.rows[0].LOAN_START_DATE,// As discussed with shammad commenting this
        //   icon: CalendarIcon,
        // },
        {
          title: "Sanctioned amount",
          value: getRupeeFormat(
            summaryData.account_detail.rows[0].SANCTIONED_AMOUNT
          ),
          icon: AmountIcon,
        },
        {
          title: "Disbursed amount",
          value: getRupeeFormat(
            summaryData.account_detail.rows[0].TOTAL_AMOUNT_DISBURSED
          ),
          icon: AmountIcon,
        },
        {
          title: "Principal outstanding",
          value: getRupeeFormat(summaryData.loan_info.rows[0].LOAN_OUTSTANDING),
          icon: AmountIcon,
        },
        {
          title: "Tenure",
          value: `${summaryData.loan_info.rows[0].INSTALLMENT_NO} Months`,
          icon: CalendarIcon,
        },
        {
          title: "Remaining tenure",
          value: `${summaryData.loan_info.rows[0].REMAINING_TENURE} Months`,
          icon: CalendarIcon,
        },
        {
          title: "EMI",
          value: getRupeeFormat(
            summaryData.account_detail.rows[0].NEXT_DUE_AMOUNT
          ),
          icon: AmountIcon,
        },
        {
          title: "Interest rate",
          value: `${summaryData.loan_info.rows[0].INTEREST_RATE}`,
          icon: InterestIcon,
        },
        {
          title: "Interest frequency",
          value: summaryData.loan_info.rows[0].REPAYMENT_FREQUENCY,
          icon: InterestIcon,
        },
      ]
    : [];

  const borrowerDetailsContextArray = isDataAvailable
    ? [
        {
          title: "Product type",
          value: summaryData.loan_info.rows[0].PRODUCT,
          icon: HandIcon,
        },
        {
          title: "Customer ID",
          value: summaryData.account_detail.rows[0].CUSTOMER_NO,
          icon: AccountIcon,
        },
        {
          title: "Account no.",
          value: summaryData.loan_info.rows[0].LOAN_ACCOUNT_NUMBER,
          icon: PanIcon,
        },
        {
          title: "Primary borrower",
          value: summaryData.borrower_detail.rows[0].BORROWER_NAME,
          icon: AccountCashIcon,
        },
        {
          title: "Property type",
          value: summaryData.property_detail.rows[0].PROPERTY_TYPE,
          icon: HomeCityIcon,
        },
        {
          title: "Property address",
          value: summaryData.property_detail.rows[0].PROPERTYDETAILS,
          icon: MapMarkerCheckIcon,
        },
        {
          title: "Corresponding address",
          value: summaryData.borrower_detail.rows[0].ADDRESS,
          icon: MapMarkerPlusIcon,
        },
        {
          title: "Contact number",
          value: summaryData.borrower_detail.rows[0].MOBILE,
          icon: PhoneIcon,
        },
        {
          title: "Email",
          value: summaryData.borrower_detail.rows[0].E_MAIL,
          icon: EmailIcon,
        },

        {
          title: "PAN",
          value: userDetails?.pan ? userDetails?.pan : "",
          icon: PanIcon,
        },
      ]
    : [];

  const loanData: LoanData | undefined = isDataAvailable
    ? {
        SANCTIONED_AMOUNT: summaryData.account_detail.rows[0].SANCTIONED_AMOUNT,
        TOTAL_AMOUNT_DISBURSED:
          summaryData.account_detail.rows[0].TOTAL_AMOUNT_DISBURSED,
        EMI_END_DATE: summaryData.loan_info.rows[0].NEXT_DUE_DATE ?? "",
        INTEREST_RATE: summaryData.loan_info.rows[0].INTEREST_RATE,
        EMI_AMOUNT: summaryData.account_detail.rows[0].NEXT_DUE_AMOUNT,
        DATE_TITLE:
          summaryData.loan_info.rows[0].ACCOUNT_STATUS?.toLowerCase() ===
          "active"
            ? "Next EMI date"
            : "Closed date",
      }
    : undefined;

  const handleAccordionClick = useCallback(
    (title: string) => {
      if (title === expanded) {
        setExpanded("");
      } else {
        setExpanded(title);
      }
    },
    [expanded]
  );

  const handleEmiPayment = useCallback(() => {
    setEmiPaymentOrderInfo({
      payment_data: {
        pl_order_id: "",
        merc_order_id: "",
        merc_order_date: "",
        merc_id: "",
        amount: "",
        return_url: "",
        product_type: "",
        product_id: "",
        customer_id: "",
        customer_name: "",
        customer_phone: "",
        order_desc: "",
        udf1: "",
        udf2: "",
        account_no: "",
        account_ifsc: "",
      },
      order_data: {
        pl_order_id: "",
        created_date: "",
        status: "",
        status_code: "",
        links: {
          payment_link_web: "",
        },
      },
    });
    navigate(R_HLRoutes.emi_payment, { state: { accountNumber } });
  }, [accountNumber]);

  return (
    <Stack rowGap={1} className="hl-details-body">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 10,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <CustomBreadcrumbs
            data={[
              "Home loan",
              isDataAvailable
                ? summaryData.loan_info.rows[0].LOAN_ACCOUNT_NUMBER
                : "---",
            ]}
            className="hl-page-breadcrumb"
          />
          <CustomHelpComponent />
        </Box>
      </Container>

      <Box className="hl-details-accordion-card" borderRadius={"1rem"}>
        {summaryData.loan_info.rows[0].AMOUNT_OVERDUE !== "0" && (
          <Box className="accordion-note">
            <Stack
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              columnGap={2}
            >
              <Stack
                direction="row"
                columnGap={0.5}
                display="flex"
                justifyContent="flex-start"
              >
                <Typography variant="subtitle2" color={Colors.darkYellow}>
                  Due amount
                </Typography>
                <Typography variant="h6" marginTop={"0.14rem"}>
                  {getRupeeFormat(
                    summaryData.account_detail.rows[0].NEXT_DUE_AMOUNT
                  )}
                </Typography>
              </Stack>
              <Box>
                <CustomLink
                  title="Pay now"
                  titleSize={".75rem"}
                  onClick={handleEmiPayment}
                />
              </Box>
            </Stack>
          </Box>
        )}
        {isDataAvailable && (
          <Box className="sub-header">
            <Typography
              color={
                summaryData.loan_info.rows[0].AMOUNT_OVERDUE
                  ? Colors.red
                  : Colors.green
              }
              variant="h5"
            >
              Home loan
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop={"0.4rem"}
            >
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Account no.</Typography>
                <Typography
                  color={Colors.black}
                  variant="h5"
                  fontWeight={600}
                  fontSize={"0.75rem"}
                >
                  <strong>
                    {summaryData.loan_info.rows[0].LOAN_ACCOUNT_NUMBER}
                  </strong>
                </Typography>
              </Stack>
              <Box
                display="flex"
                alignItems="center"
                marginRight="0.9375rem"
                gap=".25rem"
              >
                <span
                  className="circle-icon"
                  style={{
                    backgroundColor:
                      summaryData.loan_info.rows[0].ACCOUNT_STATUS.toLowerCase() ===
                      "active"
                        ? summaryData.loan_info.rows[0].AMOUNT_OVERDUE
                          ? Colors.red
                          : Colors.green
                        : Colors.bgGray,
                  }}
                ></span>
                <Typography
                  color={
                    summaryData.loan_info.rows[0].ACCOUNT_STATUS.toLowerCase() ===
                    "active"
                      ? summaryData.loan_info.rows[0].AMOUNT_OVERDUE
                        ? Colors.red
                        : Colors.green
                      : Colors.textGray
                  }
                  variant="h6"
                >
                  Active
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        <Box className="body">
          {isDataAvailable && loanData ? (
            <LoanDetailsSection
              isDetailPage={true}
              data={loanData}
              overdue={
                summaryData.loan_info.rows[0].AMOUNT_OVERDUE &&
                summaryData.loan_info.rows[0].AMOUNT_OVERDUE.trim() !== ""
                  ? {
                      principalOutstanding:
                        summaryData.account_detail.rows[0].LOAN_OUTSTANDING,
                      dueAmount:
                        summaryData.account_detail.rows[0].NEXT_DUE_AMOUNT,
                    }
                  : undefined
              }
              progressBar={
                <Stack rowGap={1} marginTop={"0.5rem"}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography
                      textAlign="left"
                      color={Colors.descriptionGray}
                      variant="h5"
                    >
                      0
                    </Typography>
                    <Typography
                      textAlign="right"
                      color={Colors.descriptionGray}
                      marginRight={"0.3rem"}
                      variant="h5"
                    >
                      {Number(summaryData.loan_info.rows[0].TOTAL_TENOR)}
                    </Typography>
                  </Box>
                  <LinearProgress
                    color="success"
                    variant="determinate"
                    value={
                      ((Number(summaryData.loan_info.rows[0].TOTAL_TENOR) -
                        Number(
                          summaryData.loan_info.rows[0].REMAINING_TENURE
                        )) *
                        100) /
                      Number(summaryData.loan_info.rows[0].TOTAL_TENOR)
                    }
                  />
                  <Box display="flex" justifyContent="flex-end">
                    <Typography textAlign="right" variant="subtitle2">
                      Remaining tenure:
                      <strong style={{ marginLeft: "0.2rem" }}>
                        {Number(summaryData.loan_info.rows[0].REMAINING_TENURE)}{" "}
                        months
                      </strong>
                    </Typography>
                  </Box>
                </Stack>
              }
            />
          ) : (
            <TryAgain />
          )}
        </Box>
      </Box>
      <Box className="accordion-group-hl">
        <Box className="accordion-sub-group">
          <HiDetailsPageAccordion
            title="Borrower details"
            expanded={expanded}
            handleAccordionClick={handleAccordionClick}
          >
            {isDataAvailable ? (
              <>
                {borrowerDetailsContextArray.map((details) => {
                  return (
                    <DisplayText
                      title={details.title}
                      value={details.value}
                      icon={details.icon}
                      key={details.title}
                    />
                  );
                })}
                <DisplayText
                  title="Joint holders"
                  value={
                    summaryData.borrower_detail.rows[0].CO_BORROWER_NAME?.split(
                      ","
                    ).length === 1
                      ? summaryData.borrower_detail.rows[0].CO_BORROWER_NAME?.split(
                          ","
                        ).toString()
                      : ""
                  }
                  icon={AccountGroup}
                />
                <Box
                  // direction="row"
                  // spacing={2}
                  justifyContent="center"
                  className="hl-slider-container"
                  width="100%"
                >
                  {summaryData.borrower_detail.rows[0].CO_BORROWER_NAME?.split(
                    ","
                  ).length !== 1 &&
                    summaryData.borrower_detail.rows[0].CO_BORROWER_NAME?.split(
                      ","
                    ).map((item, index) => {
                      return (
                        <Box
                          key={`${item}_${index}`}
                          // columnGap={2}
                          // direction="row"
                          width={"auto"}
                          overflow={"hidden"}
                          alignItems="center"
                          height={"100%"}
                          className="borrower-badge-container"
                          marginRight={"0.7rem"}
                        >
                          {item && (
                            <Stack
                              display="flex"
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-start"
                              columnGap={1}
                            >
                              <Typography
                                variant="h5"
                                textAlign="center"
                                className="borrower-badge"
                              >
                                {index + 1}
                              </Typography>
                              <Box className="borrower-panel">
                                <Typography
                                  variant="h5"
                                  textAlign="center"
                                  whiteSpace={"break-spaces"}
                                >
                                  {item}
                                </Typography>
                              </Box>
                            </Stack>
                          )}
                        </Box>
                      );
                    })}
                </Box>
              </>
            ) : (
              <TryAgain />
            )}
          </HiDetailsPageAccordion>

          <HiDetailsPageAccordion
            title="Amount and payment details"
            expanded={expanded}
            handleAccordionClick={handleAccordionClick}
          >
            {isDataAvailable ? (
              <>
                {amountDetailsContextArray.map((details) => {
                  return (
                    <DisplayText
                      title={details.title}
                      value={details.value}
                      icon={details.icon}
                      key={details.title}
                    />
                  );
                })}
              </>
            ) : (
              <TryAgain />
            )}
          </HiDetailsPageAccordion>

          <HiDetailsPageAccordion
            title="Repayment details"
            expanded={expanded}
            icon={CashRefundIcon}
            handleAccordionClick={handleAccordionClick}
          >
            {isDataAvailable ? (
              <>
                {repaymentDetailsContextArray.map((details, idx) => {
                  return (
                    <Fragment key={idx}>
                      {details.divider ? (
                        <Divider sx={{ marginY: ".625rem" }} />
                      ) : (
                        <DisplayText
                          title={details.title}
                          value={details.value}
                          icon={details.icon}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </>
            ) : (
              <TryAgain />
            )}
          </HiDetailsPageAccordion>
          <Box marginTop={"0.8rem"}>
            <HlDisclaimer />
          </Box>
        </Box>
      </Box>
      <Container sx={{ marginTop: "-0.8rem" }}>
        {promotionalCards.map((item) => {
          return (
            <CustomSimpleCard
              imageUrl={item.attributes.image.data?.attributes.url ?? ""}
              title={item.attributes.title ?? ""}
              description={item.attributes.text}
              link={
                <CustomLink
                  onClick={() => handleClick(item.attributes.url ?? "")}
                  title={item.attributes.urlLable ?? ""}
                  titleSize={14}
                />
              }
            ></CustomSimpleCard>
          );
        })}
      </Container>
      <HomeLoanPoweredBy />
      {isDataAvailable && (
        <Box className="quick-actions-panel">
          <HLQuickActionsMenuComponent />
        </Box>
      )}
    </Stack>
  );
};

export default HomeloanDetails;
