import { FC, useCallback, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import AlertGif from "../../../assets/gif/Exclamation_Mark.json";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import { T_AnimationData } from "../../../globalTypes/GlobalTypes";
import AbhflLogo from "../../../assets/images/ABHFL LOGO 2.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./PaymentPending.scss";
import { Colors } from "../../../styles/constant";
import CallUs from "../../Feature_GeneralAppElements/CallUs/CallUs";

interface Props {
  handleClick: Function;
}

const PaymentPending: FC<Props> = ({ handleClick }) => {
  const [open, setOpen] = useState<boolean>(false);
  const retryApi = useCallback(() => {
    handleClick("PENDING");
  }, [handleClick]);

  const handleOpen = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return (
    <Box
      className="hl-payment-pending"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      <Box className="hl-payment-pending-content">
        <CommonGifAnimation
          width="2.5rem"
          heightCustom="initial"
          animationData={AlertGif as T_AnimationData}
        />
        <Typography
          variant="h3"
          sx={{ marginTop: "1.5rem", marginBottom: "0.5rem" }}
        >
          Your payment is pending
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ marginBottom: "1.5rem", color: Colors.quartz }}
        >
          Please refresh the page or try again later. It could take a few
          minutes to 3-4 days. If you have any problems, reach us to raise a
          dispute
        </Typography>
      </Box>
      <Box className="hl-payment-pending-bottom-content">
        <Button
          fullWidth
          variant="contained"
          className="continue-btn"
          onClick={retryApi}
        >
          Refresh this page
        </Button>
        <Box className="bottom-info">
          <InfoOutlinedIcon
            sx={{
              color: Colors.red,
              height: "1rem",
              width: "1rem",
              marginRight: ".25rem",
            }}
          />
          <Typography
            variant="h6"
            sx={{ color: Colors.red, cursor: "pointer", lineHeight: "initial" }}
            onClick={handleOpen}
          >
            Connect with our executive
          </Typography>
        </Box>
        <Box className="powered-by">
          <Typography variant="h5" sx={{ color: Colors.textGray }}>
            Powered by
          </Typography>
          <img src={AbhflLogo} alt="powered-by" />
        </Box>
        <CallUs open={open} handleOpen={handleOpen} showChatWithUsBtn={false} />
      </Box>
    </Box>
  );
};

export default PaymentPending;
