import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
import PaymentPending from "../PaymentPending/PaymentPending";
import PaymentFailed from "../PaymentFailed/PaymentFailed";
import Paymentsuccess_page from "./Paymentsuccess_page";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import LoadingDots from "../../../assets/gif/Loader_dots.json";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import "./EMI_payment.scss";

interface Props {
  status: string;
  handleClick: Function;
}

const PaymentResult: React.FC<Props> = ({ status, handleClick }) => {
  const [pStatus, setPStatus] = useState<string>("");

  useEffect(() => {
    // console.log(status, "status");
    setPStatus(status);
  }, [status]);

  const handleCallback = useCallback(
    (val: string) => {
      handleClick(val);
    },
    [handleClick]
  );

  const renderPaymentStatus = useCallback((status: string) => {
    switch (status) {
      case "SUCCESS":
        return <Paymentsuccess_page handleClick={handleCallback} />;
      case "PENDING":
        return <PaymentPending handleClick={handleCallback} />;
      case "FAILURE":
        return <PaymentFailed handleClick={handleCallback} />;
      default:
        return (
          <CustomPopup
            open={true}
            handleOpen={() => {}}
            className={`hl-common-policy-popup-box emi-payment-pending-popup`}
          >
            <CommonGifAnimation
              animationData={LoadingDots}
              heightCustom="1rem"
            />
          </CustomPopup>
        );
    }
  }, []);

  return <Box>{renderPaymentStatus(pStatus)}</Box>;
};
export default PaymentResult;
