import { FC, useState, useEffect, useCallback } from "react";
import CommonMenuListPage from "../../../components/CustomMenuListPage/CommonMenuListPage";
import { Box, Button, Stack, Typography, Snackbar, Alert } from "@mui/material";
import { Colors } from "../../../styles/constant";
import { MuiOtpInput } from "mui-one-time-password-input";
import "./ChangemPIN.scss";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { useLazyUpdatempinQuery } from "../GeneralAppElements_Api";
interface Props {
  handleBackBtnClick: Function;
  pathname?: string;
}

const OtpInputSection: FC<{
  label: string;
  value: string;
  setValue: (val: string) => void;
}> = ({ label, value, setValue }) => (
  <Box
    textAlign="center"
    sx={{ "& > :not(:first-child)": { marginTop: "1rem" } }}
  >
    <Typography variant="h6" lineHeight=".975rem" color={Colors.arsenic}>
      Enter {label} <span style={{ fontWeight: 700 }}>mPIN</span>
    </Typography>
    <MuiOtpInput
      value={value}
      onChange={setValue}
      display="flex"
      justifyContent="center"
      className="otp-input"
      sx={{ gap: "1.0625rem" }}
      length={4}
    />
  </Box>
);

const isErrorWithMessage = (
  error: unknown
): error is { data: { message: string } } => {
  return (
    typeof error === "object" &&
    error !== null &&
    "data" in error &&
    "message" in (error as any).data
  );
};

const ChangemPIN: FC<Props> = ({ handleBackBtnClick, pathname }) => {
  const [oldMpin, setOldMpin] = useState("");
  const [newMpin, setNewMpin] = useState("");
  const [confirmMpin, setConfirmMpin] = useState("");

  const [updateMpin, { data: Response, isError, isSuccess, error }] =
    useLazyUpdatempinQuery();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  useEffect(() => {
    if (isError && isErrorWithMessage(error)) {
      setSnackbarMessage(`${error.data.message}`);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } else if (isSuccess) {
      setSnackbarMessage("mPIN updated successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    }
  }, [Response, error]);

  const handleSubmit = useCallback(async () => {
    if (newMpin !== confirmMpin) {
      alert("New mPIN and Confirm mPIN do not match");
      return;
    }
    const params = {
      //mobileNo will come from redux
      mobileNo: "9437473531",
      oldMpin,
      newMpin,
    };
    try {
      await updateMpin(params);
    } catch (err) {
      console.error("Failed to update mPIN:", err);
    }
  }, []);

  const handleCloseSnackbar = useCallback(() => {
    setOpenSnackbar(false);
    if (isSuccess) {
      handleBackBtnClick(pathname);
    }
  }, []);

  return (
    <CommonMenuListPage
      title="Change mPIN"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
      <Box
        textAlign={"center"}
        sx={{ "& > :not(:first-child)": { marginTop: ".5rem" } }}
      >
        <Typography variant="h2" color={Colors.arsenic}>
          Update your mPIN
        </Typography>
        <Typography variant="h5" fontWeight={400} color={"#77787B"}>
          This helps keeping your data secure
        </Typography>
      </Box>
      <Stack spacing={"2rem"} marginTop={"2.75rem"}>
        <OtpInputSection label="old" value={oldMpin} setValue={setOldMpin} />
        <OtpInputSection label="new" value={newMpin} setValue={setNewMpin} />
        <OtpInputSection
          label="confirm new"
          value={confirmMpin}
          setValue={setConfirmMpin}
        />
        <Box
          textAlign={"center"}
          sx={{
            "& > :not(:first-child)": { marginTop: ".5rem" },
            boxShadow: "0rem .5rem 1rem 0rem #0000000A",
            borderRadius: "1rem",
          }}
          padding="1rem"
          marginX="1rem !important"
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            gap={".625rem"}
            alignItems={"center"}
            bgcolor={Colors.lightYellow}
            borderRadius={".5rem"}
            height={"1.5rem"}
          >
            <InfoRoundedIcon sx={{ fontSize: "1rem", color: "#F7A500" }} />
            <Typography
              fontSize={".625rem"}
              fontWeight={600}
              lineHeight={".9375rem"}
              letterSpacing={".0063rem"}
              fontFamily="Poppins, sans-serif"
              color="#D99202"
            >
              Disclaimer
            </Typography>
          </Box>
          <Typography
            variant="h6"
            fontWeight={400}
            color={Colors.darkCharcoal}
            lineHeight="1.125rem"
            fontFamily="Poppins, sans-serif"
            textAlign="center"
          >
            The new mPIN should be different from <br /> last three mPINs
          </Typography>
        </Box>
      </Stack>

      <Box marginX="1rem" marginTop="auto" marginBottom=".75rem">
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          // disabled={isError}
        >
          Setup mPin
        </Button>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ marginTop: "1.5rem" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{
              backgroundColor: "white",
              width: "90%",
              marginLeft: "1.25rem",
              marginRight: "1.25rem",
              borderEndStartRadius: "1.5rem",
              borderEndEndRadius: "1.5rem",
              padding: "0rem",
              display: "flex",
              justifyContent: "space-around",
              position: "absolute",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </CommonMenuListPage>
  );
};

export default ChangemPIN;
