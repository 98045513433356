import { RouteObject } from "react-router-dom";
import OtpMaximumAttempt from "./OtpMaximumAttempt/OtpMaximumAttempt";
import FeatureLogin from "./Feature_Login";
import PolicyAndConditionsPage from "../Feature_GeneralAppElements/PolicyAndConditionsPage/PolicyAndConditionsPage";

export enum R_LoginRoutes {
  login = "/login",
  maximumOtpAttempt = "/maximumOtpAttempt",
  policyAndConditions = "/policy-&-conditions",
}
const LoginRoutes: RouteObject[] = [
  {
    path: R_LoginRoutes.login,
    element: <FeatureLogin />,
  },
  {
    path: R_LoginRoutes.policyAndConditions,
    element: <PolicyAndConditionsPage />,
  },
  // {
  //   path: "maximumOtpAttempt",
  //   element: <OtpMaximumAttempt />,
  // },
];
export default LoginRoutes;
