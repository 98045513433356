import { Box, Button, Grid, Typography } from "@mui/material";
import LoginBGImg from "../../assets/images/coinIcon.svg";
import ABCLogo from "../../assets/images/abc-logo.jpg";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import MobileNumberEntry from "./MobileNumberEntry/MobileNumberEntry";
// import LoginWithMPIN from "./LoginWithMPIN/LoginWithMPIN";
import SetupMpn from "./SetupMpn/SetupMpn";
import EnterOtp from "../../features/Feature_Login/OtpEntry/OtpEntry";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import CustomPopup from "../../components/CustomPopup/CustomPopup";
import CommonGifAnimation from "../../components/CustomGifAnimation/CommonGifAnimation";
import { Colors } from "../../styles/constant";
import {
  E_ChannelOptions,
  T_AnimationData,
} from "../../globalTypes/GlobalTypes";
import ExclamationMarkGif from "../../assets/gif/Exclamation_Mark.json";
import CsLocalStorageUtils from "../../utils/CsLocalStorageUtils";
import { useNavigate } from "react-router-dom";
import { ABCD_SUPERAPP_URL } from "../../utils/constants";
import { useEffect } from "react";
import { updateLoadingState } from "../Feature_Loading/Loading_Slice";
import { Footer } from "../../components/CustomFooter/CustomFooter";
import CsSessionStorageUtils from "../../utils/CsSessionStorageUtils";
import "./Feature_Login.scss";
import CustomNetworkImage from "../../components/CustomNetworkImage/CustomNetworkImage";
import { defaultEmptyFun } from "../../utils/helper";
import {
  resetLoginData,
  updateLoginData,
  updateScreenTag,
} from "./Login_Slice";
import { clearCustomerAndLobDetailsArr } from "../../redux-store/reducers/CsAppSlice";
import { useLazyGenerateOtpQuery } from "./Login_Api";
import { setError } from "../../redux-store/reducers/CsErrorSlice";

const FeatureLogin: React.FC = () => {
  const loginSlice = useAppSelector((state) => state.LoginSlice);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const csAppSlice = useAppSelector((state) => state.CsAppSlice);
  const secondaryChannelSlice = useAppSelector(
    (state) => state.SecondaryChannelSlice
  );
  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const cmsLogo =
    cmsData.find((i) => i.attributes.cmsType === "ABC Logo")?.attributes.image
      .data?.attributes.url ?? "";

  const [generateOtpApi] = useLazyGenerateOtpQuery();

  useEffect(() => {
    if (
      csAppSlice.channel === E_ChannelOptions.SECONDARY_CHANNEL &&
      secondaryChannelSlice.mobileNo
    ) {
      dispatch(updateLoadingState(true));
      generateOtpFun();
    }
  }, []);

  useEffect(() => {
    /**Reset the master details */
    CsSessionStorageUtils.clear("MOBILE");
    if (csAppSlice.channel !== E_ChannelOptions.SECONDARY_CHANNEL) {
      dispatch(updateLoadingState(false));
    }
    dispatch(resetLoginData());
    dispatch(clearCustomerAndLobDetailsArr());
  }, []);

  const generateOtpFun = async () => {
    await generateOtpApi({
      mobileNo: "" + secondaryChannelSlice.mobileNo,
      otp: "",
    }).then((res) => {
      if (res.data?.code === 1) {
        dispatch(
          updateLoginData({
            key: "mobilenumber",
            value: secondaryChannelSlice.mobileNo,
          })
        );
        dispatch(updateScreenTag(LoginScreenValues.enterOtpScreen));
      } else {
        dispatch(
          setError({
            error: res.data,
            message: "API failed",
          })
        );
      }
      dispatch(updateLoadingState(false));
    });
  };

  function callComponentByStage(stage: LoginScreenValues) {
    switch (stage) {
      //   case "LoginWithMPIN":
      //     return <LoginWithMPIN />;
      case "enterMobileScreen":
        return (
          <MobileNumberEntry
            headerText="Welcome to ABC"
            subheaderText=" Enter details to begin"
          />
        );
      case "enterOtpScreen":
        return (
          <EnterOtp
            headerText={`Enter OTP sent to ${loginSlice.mobilenumber}`}
            onMaximumAttempt={defaultEmptyFun}
          />
        );
      //   case "enterMobileScreenForgotPin":
      //     return (
      //       <MobileNumberEntry
      //         headerText="Forgot mPIN"
      //         subheaderText=" Enter mobile number linked to ABC"
      //       />
      //     );
      //   case "enterOtpScreenForgotmPIN":
      //     return (
      //       <EnterOtp headerText="OTP Verification" onMaximumAttempt={() => {}} />
      //     );
      //   case "setupMPN":
      //     return <SetupMpn length={4} />;
      default:
        console.error(`Function ${stage} not found.`);
    }
  }

  const isUserBlocked = CsLocalStorageUtils.get("OTP_BLOCKED_TIME")?.length > 0;

  return (
    <Box className="login-page">
      <Box className="header-section">
        <CustomNetworkImage fallBackImage= { <img src={ABCLogo} alt=""/>} src={cmsLogo} alt="abc-logo-img" />
      </Box>
      <Box className="background-image">
        <img src={LoginBGImg} alt="background-logo-img" />
      </Box>
      <Box className="cardlayout">
        <Box display="flex" justifyContent="center">
          <HorizontalRuleIcon className="header-logo" />
        </Box>
        <Box display="flex" justifyContent="center">
          {callComponentByStage(loginSlice.screenTag)}
          <CustomPopup open={isUserBlocked} handleOpen={defaultEmptyFun}>
            <Box padding="1.5rem 1rem">
              <Box
                display="flex"
                justifyContent="center"
                flexDirection={"column"}
                textAlign={"center"}
                alignItems={"center"}
              >
                <CommonGifAnimation
                  animationData={ExclamationMarkGif as T_AnimationData}
                />
                <Typography
                  marginTop={"2rem"}
                  variant="h3"
                  color={Colors.textblack}
                >
                  You have reached the maximum attempt of tries
                </Typography>
                <Button
                  sx={{ width: "100%", marginTop: "2rem" }}
                  type="button"
                  variant="contained"
                  onClick={() => {
                    navigate("" + ABCD_SUPERAPP_URL);
                  }}
                >
                  Go to ABCD homepage
                </Button>
              </Box>
            </Box>
          </CustomPopup>
        </Box>
      </Box>
      {loginSlice.screenTag === "enterMobileScreen" && (
        <Box
          sx={{ backgroundColor: "#363636" }}
          display="flex"
          justifyContent="center"
        >
          <Footer />
        </Box>
      )}
    </Box>
  );
};
export default FeatureLogin;
enum LoginScreenValues {
  enterMobileScreen = "enterMobileScreen",
  LoginWithMPIN = "LoginWithMPIN",
  enterOtpScreen = "enterOtpScreen",
  enterMobileScreenForgotPin = "enterMobileScreenForgotPin",
  enterOtpScreenForgotmPIN = "enterOtpScreenForgotmPIN",
  enterUserDetailScreen = "enterUserDetailScreen",
  setupMPN = "setupMPN",
  fetchCustomerLobAndDo2FAAuth = "fetchCustomerLobAndDo2FAAuth",
  loading = "loading",
}
export { LoginScreenValues };
