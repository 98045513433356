import { Box, Typography } from "@mui/material";
import { FC, useCallback } from "react";

interface TypeProps {
  data: string;
  handleChange: Function;
  selectedValue: string;
}

const RenderTypeButton: FC<TypeProps> = ({
  data = "",
  handleChange,
  selectedValue,
}) => {
  const handleTypeChange = useCallback(() => {
    handleChange(data);
  }, [data, handleChange]);
  return (
    <Box
      className={`li-sa-type-title ${
        selectedValue === data ? "selected-type-title" : ""
      }`}
      onClick={handleTypeChange}
    >
      <Typography variant="h5">{data}</Typography>
    </Box>
  );
};
export default RenderTypeButton;
