import { Box, Button, Dialog, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import "./CustomPopup.scss";
import { Colors } from "../../styles/constant";
import useWindowSize from "../../hooks/useWindowSize";
import { tabViewPoint } from "../../utils/constants";

interface Props {
  open: boolean;
  handleOpen: Function;
  children?: React.ReactNode | null;
  btnStartIcon?: any;
  btnEndIcon?: any;
  btnLabel?: string;
  className?: string;
  anchor?: string;
  isDisabled?: boolean;
  flowType?: string;
  customeGetHelpHandler?: Function;
}

const CustomPopup: FC<Props> = ({
  open,
  handleOpen,
  children,
  btnLabel = "",
  btnStartIcon = null,
  btnEndIcon = null,
  className = "",
  anchor = "",
  isDisabled = false,
  flowType = "LI",
  customeGetHelpHandler = () => {},
}) => {
  const { width } = useWindowSize();
  const handleOpenWrapper = useCallback(() => {
    handleOpen();
  }, [handleOpen]);

  const buttonComponent = (
    <Box
      display="flex"
      justifyContent="center"
      marginTop={anchor === "bottom" ? "1rem" : ""}
    >
      <Button
        className={`custom-drawer-btn ${
          flowType === "HI" || flowType === "HL" ? "custom-drawer-btn-red" : ""
        }`}
        onClick={() =>
          anchor && anchor === "top" && flowType === "HL"
            ? customeGetHelpHandler()
            : handleOpenWrapper()
        }
        disabled={isDisabled}
      >
        <Box display="flex" alignItems="center">
          {btnStartIcon ? btnStartIcon : null}
          <Typography
            marginLeft=".3125rem"
            marginRight=".3125rem"
            variant="h5"
            color={flowType === "HI" ? Colors.white : Colors.red}
          >
            {btnLabel}
          </Typography>
          {btnEndIcon ? btnEndIcon : null}
        </Box>
      </Button>
    </Box>
  );

  return (
    <Dialog
      fullWidth
      className={`custom-popup ${
        width > tabViewPoint ? "customPopCentered" : ""
      }  ${className}`}
      open={open}
    >
      {btnLabel && buttonComponent}
      <Box className="popup-content-wrapper">{children}</Box>
    </Dialog>
  );
};

export default CustomPopup;
