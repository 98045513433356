import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import CheckCircle from "@mui/icons-material/CheckCircle"; // Adjust import based on your setup
import { RadioButtonChecked } from "@mui/icons-material";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { formatDateWithOrdinal } from "../../../../utils/conversionUtils";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

// Define the types for the props
interface StatusBoxProps {
  title: string;
  date: string;
  status: "success" | "pending"; // Define acceptable status values
  onProgress?: boolean;
  expectedResolution?: string;
}

const StatusBox: React.FC<StatusBoxProps> = ({
  title,
  date,
  status,
  onProgress,
  expectedResolution,
}) => {
  // Define colors based on status
  const colors: Record<string, string> = {
    success: "#1F874C", // Existing color for success
    pending: "#F7A500", // Color for pending status
  };

  const icons: Record<string, any> = {
    success: <CheckCircle fontSize="small" />, // Existing color for success
    pending: <RadioButtonChecked fontSize="small" />, // Color for pending status
  };

  // Determine the color based on status
  const color = colors[status] || colors.success;
  const icon = icons[status] || icons.success;

  return (
    <Stack direction="row" spacing={1} style={{ color: color }}>
      <Stack direction="column" alignItems="center">
        {icon}
        {status === "success" && title === "Service request submitted" && (
          <Box
            sx={{
              height: "25px",
              background: `linear-gradient(to bottom, ${color}, ${color}00)`,
              width: "1px",
            }}
          ></Box>
        )}
      </Stack>
      <Stack direction="column" sx={{ textAlign: "left" }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "12px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "12px",
          }}
        >
          {date}
        </Typography>
        {status !== "success" && (
          <Typography
            variant="h6"
            color="#767D93"
            lineHeight=".825rem"
            fontWeight="400"
            marginTop=".5rem"
          >
            Expected Resolution by {formatDateWithOrdinal(expectedResolution)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default StatusBox;
