import { CircularProgress } from "@mui/material";
import { FC } from "react";
import "./CustomCircularLoader.scss";
import { defaultEmptyFun } from "../../utils/helper";

interface Props {
  size?: number;
  marginRight?: string;
}

const CustomCircularLoader: FC<Props> = ({ size = 32, marginRight = "" }) => {
  return (
    <>
      <CircularProgress
        size={size}
        className="custom-circular-loader"
        style={{ marginRight: marginRight }}
      />
      <div
        className="circular-loader-overlay"
        onClick={(e) => {
          e.preventDefault();
        }}
      ></div>
    </>
  );
};

export default CustomCircularLoader;
