import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { T_MFDashboardDetails, T_MutualFundSliceState } from "./MutualFund_Types";


const initialState: T_MutualFundSliceState = {
    mfDashboardDetails: {
        INVESTED_AMOUNT: null,
        CURRENT_VALUE: null,
        XIRR_VALUE: null
    },
    folioDetails: [],
    secondaryFolioDetails: [],
    folioSchemeDetails: [],
}

export const MutualFundSlice = createSlice({
    name: "MutualFundSlice",
    initialState,
    reducers: {
        setMfDashboardDetails: (state, action) => {
            return {
                ...state,
                mfDashboardDetails: { ...state.mfDashboardDetails, ...action.payload }
            };
        },
        setFolioDetails: (state, action) => {
            return {
                ...state,
                folioDetails: action.payload
            }
        },
        setSecondaryFolioDetails: (state, action) => {
            return {
                ...state,
                secondaryFolioDetails: action.payload
            }
        },
        updateSecondaryFolioValues: (state, action: PayloadAction<{ pan: string; values: T_MFDashboardDetails }>) => {
            const { pan, values } = action.payload;
            const folio = state.secondaryFolioDetails.find(f => f.pan === pan);
            if (folio) {
                folio.values = values;
            }
        },
        setFolioSchemeDetails: (state, action) => {
            return {
                ...state,
                folioSchemeDetails: action.payload
            }
        }
    }
})

export const MutualFundReducer = MutualFundSlice.reducer;
export const MutualFundSliceKey = MutualFundSlice.reducerPath;

export const { setMfDashboardDetails, setFolioDetails, setFolioSchemeDetails, setSecondaryFolioDetails, updateSecondaryFolioValues } = MutualFundSlice.actions;
