import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  T_SsoSliceState,
  T_ValidateSSONewRes,
  T_ValidateSSOOldRes,
  TriggerGenerateSSO_NEW,
  TriggerGenerateSSO_OLD,
} from "./Sso_Types";

const initialState: T_SsoSliceState<
  | T_ValidateSSONewRes
  | T_ValidateSSOOldRes
  | TriggerGenerateSSO_OLD
  | TriggerGenerateSSO_NEW
> = {
  ssoData: {},
};

export const SsoSlice = createSlice({
  name: "SsoSlice",
  initialState: initialState,
  reducers: {
    clearSsoSlice: (state) => {
      return { ssoData: {} };
    },
    updateSSOSlice: (
      state,
      action: PayloadAction<T_ValidateSSOOldRes | T_ValidateSSONewRes>
    ) => {
      return { ...state, ssoData: action.payload };
    },
    triggerGenerateSSOOld: (
      state,
      action: PayloadAction<TriggerGenerateSSO_OLD>
    ) => {
      return {
        ssoData: action.payload,
      };
    },
    triggerGenerateSSONew: (
      state,
      action: PayloadAction<TriggerGenerateSSO_NEW>
    ) => {
      return {
        ssoData: action.payload,
      };
    },
    disableShowLoading: (state) => {
      return { ssoData: { ...state.ssoData, showLoading: false } };
    },
  },
});

export const SsoSliceReducer = SsoSlice.reducer;
export const SsoSliceKey = SsoSlice.reducerPath;
export const {
  updateSSOSlice,
  triggerGenerateSSOOld,
  triggerGenerateSSONew,
  clearSsoSlice,
  disableShowLoading,
} = SsoSlice.actions;
