import { RouteObject } from "react-router-dom";
import BasicSearch from "./BasicSearch/BasicSearch";
import NotificationsPage from "./NotificationsPage/NotificationsPage";
import { defaultEmptyFun } from "../../utils/helper";
export enum R_GeneralElementsRoutes {
  search = "/search",
  notifications = "/notifications",
}

const GeneralAppElementsRoutes: RouteObject[] = [
  {
    path: R_GeneralElementsRoutes.search,
    element: <BasicSearch />,
  },
  {
    path: R_GeneralElementsRoutes.notifications,
    element: <NotificationsPage open={true} handleOpen={defaultEmptyFun} />,
  },
];
export default GeneralAppElementsRoutes;
