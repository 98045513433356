import { FC, useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import {
  T_FaqCategoryItemList,
  T_FaqCategoryItems,
  T_FaqModuleItem,
} from "../GeneralAppElements_Types";
import { Colors } from "../../../styles/constant";
import FaqItemList from "../FaqItemList/FaqItemList";
import { useLazyFetchFaqCategoriesQuery } from "../GeneralAppElements_Api";
import CustomShimmer from "../../../components/CustomShimmer/CustomShimmer";
import {
  FAQDisclaimerContent,
  FAQDisclaimerTitle,
  tabViewPoint,
} from "../../../utils/constants";
import "./FAQsMobile.scss";
import useWindowSize from "../../../hooks/useWindowSize";
import { setCategoryList } from "../GeneralAppElements_Slice";

interface Props {
  open: boolean;
  handleOpen: Function;
  openChatWithUs: Function;
  activeTab: string;
}

interface ChildProps {
  data: T_FaqCategoryItems;
  handleCategory: Function;
}

const FAQsMobile: FC<Props> = ({
  open,
  handleOpen,
  openChatWithUs,
  activeTab = "",
}) => {
  // const appDispatch = useAppDispatch();
  const [faqCategories, setFaqCategories] = useState<T_FaqCategoryItems[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<T_FaqCategoryItems>({
    attributes: {
      createdAt: "",
      key: "",
      categoryName: "",
      publishedAt: "",
      updatedAt: "",
    },
    id: 0,
    moduleName: "",
  });
  const [fetchFaqCategoriesApi] = useLazyFetchFaqCategoriesQuery();
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(
    (state) => state.GeneralAppElementsSlice.selectedTab
  );
  const selectedModule = useAppSelector(
    (state) => state.GeneralAppElementsSlice.selectedModule
  );
  const parentModuleList = useAppSelector(
    (state) => state.GeneralAppElementsSlice.parentModuleList
  );

  const categoryList = useAppSelector(
    (state) => state.GeneralAppElementsSlice.categoryList
  );
  const [showShimmer, setShowCategoryShimmer] = useState<boolean>(false);

  useEffect(() => {
    if (parentModuleList.length > 0) {
      fetchFaqCategoryList();
    }
  }, [parentModuleList, activeTab]);

  useEffect(() => {
    setFaqCategories(categoryList);
  }, [categoryList]);

  const handleClosePopup = useCallback(() => {
    handleOpen();
  }, [handleOpen]);

  const handleCategory = useCallback(
    (data: T_FaqCategoryItems, isShow: boolean) => {
      if (isShow) {
        setSelectedCategory(data);
      } else {
        setSelectedCategory({
          attributes: {
            createdAt: "",
            key: "",
            categoryName: "",
            publishedAt: "",
            updatedAt: "",
          },
          id: 0,
          moduleName: "",
        });
      }
    },
    []
  );

  const fetchFaqCategoryList = useCallback(async () => {
    try {
      setShowCategoryShimmer(true);
      let data = [...parentModuleList].filter(
        (d) => d.attributes.parentModuleName === activeTab
      );
      let apis: any = [];
      if (data.length > 0) {
        let promises = data[0].attributes.modules.data.map(
          (data: T_FaqModuleItem) => {
            return fetchFaqCategoriesApi(data.attributes.key);
          }
        );
        let results = await Promise.all(promises);
        let categories: any[] = [];
        results.forEach((res) => {
          if (res.data?.code === 1) {
            res?.data?.payload?.data?.map((data) => {
              data = JSON.parse(JSON.stringify(data));
              data["moduleName"] = res.originalArgs;
              categories.push(data);
              return data;
            });
          }
        });
        dispatch(setCategoryList(categories));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowCategoryShimmer(false);
    }
  }, []);

  const renderSteps = useCallback(() => {
    return (
      <Grid container className="faq-mbl-items" spacing={"0.564rem"}>
        {!showShimmer ? (
          faqCategories.length > 0 ? (
            faqCategories.map((data) => {
              return (
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  className="faq-mbl-items-grid"
                  key={data.attributes.key}
                >
                  <RenderTitle data={data} handleCategory={handleCategory} />
                </Grid>
              );
            })
          ) : (
            <Grid item md={12} sm={12} xs={12} className="faq-mbl-items-grid">
              <Typography
                variant="h5"
                sx={{ textAlign: "center", width: "100%" }}
              >
                No categories found
              </Typography>
            </Grid>
          )
        ) : (
          <>
            {[1, 2, 3, 4].map((data) => {
              return (
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={6}
                  className="faq-mbl-items-grid"
                  key={data}
                >
                  <Typography className="faq-mbl-item" variant="subtitle2">
                    <CustomShimmer width="100%" height="1.063rem" />
                  </Typography>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>
    );
  }, [showShimmer, faqCategories]);

  const handleChatOpen = useCallback(() => {
    openChatWithUs();
  }, []);

  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      btnLabel={"Go back"}
      className={`faq-mbl-popup ${
        selectedCategory?.attributes.categoryName === "" ? "" : "faq-que-ans"
      } ${width > tabViewPoint ? "centered" : ""}`}
      btnStartIcon={<KeyboardArrowLeftIcon fontSize="small" />}
    >
      {selectedCategory?.attributes.categoryName === "" ? (
        <Box className="faq-mbl-popup-content" sx={{ textAlign: "center" }}>
          <Box>
            <Typography variant="h5" sx={{ color: Colors.textBlackV1 }}>
              FAQS
            </Typography>
            <Typography className="faq-category-title" variant="h5">
              Categories
            </Typography>
          </Box>
          {renderSteps()}
          <Button className="faq-get-in-touch" onClick={handleChatOpen}>
            <Typography variant="h4">
              Have any other question? Get in touch
            </Typography>
          </Button>
          <Box className="faq-disclaimer">
            <Typography className="faq-disclaimer-title" variant="h6">
              {FAQDisclaimerTitle}
            </Typography>
            <Typography className="faq-disclaimer-content" variant="h6">
              {FAQDisclaimerContent}
            </Typography>
          </Box>
        </Box>
      ) : (
        <FaqItemList
          category={selectedCategory}
          handleCategory={handleCategory}
          selectedModule={selectedModule}
        />
      )}
    </CustomPopup>
  );
};
export default FAQsMobile;

export const RenderTitle: FC<ChildProps> = ({ data, handleCategory }) => {
  const handleClick = useCallback(() => {
    handleCategory(data, true);
  }, [handleCategory, data]);
  return (
    <Typography
      className="faq-mbl-item"
      variant="subtitle2"
      onClick={handleClick}
    >
      {data.attributes.categoryName}
    </Typography>
  );
};
