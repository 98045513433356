import { FC, useCallback, useEffect, useState } from "react";
import "./HlDashboardCard.scss";
import { Box, ListItemText, Stack, Typography } from "@mui/material";
import { Colors } from "../../../styles/constant";
import { getHlPolicyStatus } from "../../../utils/conversionUtils";
import { HomeLoanDashboardInfo, LoanDetails } from "../Homeloan_Types";
import HiInfoCard, { getRupeeFormat } from "./HlInformationCard";
import { useNavigate } from "react-router-dom";
import { R_HLRoutes } from "../Homeloan_Routes";

export const HlDisclaimer = () => {
  return (
    <Box className="hl-diclaimer">
      <Typography color={Colors.textGray} variant="subtitle2">
        <strong>Disclaimer</strong> : Figures shown here may get updated within
        two working days from the date of making any payments.
      </Typography>
    </Box>
  );
};

interface Props {
  info?: HomeLoanDashboardInfo;
}

interface totalAmounts {
  totalSanctionedAmount: string;
  totalDisbursedAmount: string;
}

const HlDashboardCard: FC<Props> = ({ info }) => {
  const navigate = useNavigate();
  const [totalAmounts, setTotalAmounts] = useState<totalAmounts>({
    totalSanctionedAmount: "",
    totalDisbursedAmount: "",
  });

  const calculateTotalAmount = () => {
    let totalSanctioned = 0;
    let totalDisbursed = 0;

    info?.rows.forEach((item) => {
      if (item.SANCTIONED_AMOUNT !== null && item.SANCTIONED_AMOUNT !== "") {
        totalSanctioned = totalSanctioned + Number(item.SANCTIONED_AMOUNT);
      }
      if (
        item.TOTAL_AMOUNT_DISBURSED !== null &&
        item.TOTAL_AMOUNT_DISBURSED !== ""
      ) {
        totalDisbursed = totalDisbursed + Number(item.TOTAL_AMOUNT_DISBURSED);
      }
    });

    setTotalAmounts((pre: totalAmounts) => ({
      ...pre,
      totalSanctionedAmount: `${totalSanctioned}`,
      totalDisbursedAmount: `${totalDisbursed}`,
    }));
  };

  useEffect(() => {
    calculateTotalAmount();
  }, []);

  const handleEmiPayment = useCallback((loanAccountNumber: string) => {
    navigate(R_HLRoutes.emi_payment, {
      state: { accountNumber: loanAccountNumber },
    });
  }, []);

  return (
    <Stack rowGap={2}>
      {info?.rows && info?.rows?.length > 0 && (
        <>
          <Box className="hl-dashboard-accordion-card">
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
            >
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    marginRight="0.3125rem"
                    minWidth={"4.6875rem"}
                    sx={{
                      borderRight: "1px solid #d2d3d4",
                    }}
                    fontSize={"0.875rem"}
                    color={Colors.darkBlack}
                  >
                    Total Sanctioned amount
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="h4"
                    marginRight="0.3125rem"
                    minWidth={"4.6875rem"}
                    textAlign="left"
                    marginTop={"0.2rem"}
                  >
                    {getRupeeFormat(totalAmounts.totalSanctionedAmount)}
                  </Typography>
                }
              />
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    marginRight="0.3125rem"
                    minWidth={"4.6875rem"}
                    textAlign="right"
                    fontSize={"0.875rem"}
                    color={Colors.darkBlack}
                  >
                    Total disbursed amount
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="h4"
                    marginRight="0.3125rem"
                    minWidth={"4.6875rem"}
                    textAlign="right"
                    marginTop={"0.2rem"}
                  >
                    {getRupeeFormat(totalAmounts.totalDisbursedAmount)}
                  </Typography>
                }
              />
            </Stack>
          </Box>

          {info?.rows.map((item: LoanDetails, idx: number) => {
            const status = getHlPolicyStatus(item.ACCOUNT_STATUS);
            return (
              <Stack
                rowGap={1}
                direction="column"
                key={item.LOAN_ACCOUNT_NUMBER}
              >
                <HiInfoCard
                  data={item}
                  status={status}
                  title="Home loan"
                  index={idx}
                />
                {status.toLowerCase() === "active" &&
                  item.AMOUNT_OVERDUE &&
                  item.AMOUNT_OVERDUE.trim() !== "0" && (
                    <Box
                      className="hl-paynow-btn"
                      onClick={() => handleEmiPayment(item.LOAN_ACCOUNT_NUMBER)}
                    >
                      <Typography
                        variant="h5"
                        marginRight="0.3125rem"
                        minWidth={"4.6875rem"}
                      >
                        Pay now
                      </Typography>
                    </Box>
                  )}
              </Stack>
            );
          })}
          <HlDisclaimer />
        </>
      )}
    </Stack>
  );
};

export default HlDashboardCard;
