import {
  Box,
  Button,
  Container,
  Typography,
  Tab,
  TextField,
  Divider,
  IconButton,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import HelpIcon from "../../../assets/icons/help.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import PencilIcon from "../../../assets/icons/pencil.svg";
import { useLocation } from "react-router-dom";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
// import "./ContactDetails.scss";
import dayjs from "dayjs";
import PolicyDocumentPopups from "../PolicyDocumentPopups/PolicyDocumentPopups";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import downloadRightIcon from "../../../assets/icons/download-right-icon.svg";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import EditContactDetailsPopups from "../EditContactDetailsPopups/EditContactDetailsPopups";
import CustomEditField from "../../../components/CustomEditField/CustomEditField";
import {
  hiEditPolicyContactForm,
  HiEditPolicyNomineeForm,
} from "../../../utils/constants";
import { T_PolicyInsured } from "../HealthInsurance_Types";
import "./NomineeDetails.scss";
import HiEditNomineeDetails from "../HiEditNomineeDetails/HiEditNomineeDetails";
import { T_LiRouteState } from "../../../globalTypes/GlobalTypes";

interface ContactDetails {
  mobileNo: string;
  alternateMobile: string;
  email: string;
}
interface EditContactFormData {
  key: string;
  title: string;
  icon: any;
  type: string;
}

const EditPolicy: FC = () => {
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState)?.policyNumber || "";

  const handleNomineeDetailsClick = () => {
    setPopupOpen(true);
  };
  const allPolicyInsuredDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyInsuredDetails
  );
  const selectedPolicy = allPolicyInsuredDetails.find(
    (item) => item.PolicyDetail.PolicyNumber === policyNumber
  );

  const PolicyDetails = selectedPolicy ? selectedPolicy.PolicyDetail : null;

  return (
    <>
      <Box className="edit-nominee-field">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Nominee Details</Typography>
          <img src={PencilIcon} onClick={handleNomineeDetailsClick} />
        </Box>
        <Divider sx={{ marginY: ".625rem" }} />
        <Box display="flex" flexDirection="column" marginTop="0.5rem">
          <Typography className="address-title" variant="subtitle2">
            Nominee name
          </Typography>
          <Typography className="address-details" variant="h5">
            {PolicyDetails?.vchNominee}
          </Typography>
          <Typography className="address-title" variant="subtitle2">
            Relation with proposer
          </Typography>
          <Typography className="address-details" variant="h5">
            {PolicyDetails?.Relationship}
          </Typography>
          <Typography className="address-title" variant="subtitle2">
            Mobile number
          </Typography>
          <Typography className="address-details" variant="h5">
            {PolicyDetails?.NomineeContactNumber
              ? `+91 ${PolicyDetails?.NomineeContactNumber}`
              : ""}
          </Typography>
        </Box>
      </Box>
      {popupOpen && (
        <HiEditNomineeDetails
          open={popupOpen}
          handleOpen={setPopupOpen}
          details={selectedPolicy}
        />
      )}
    </>
  );
};

export default EditPolicy;
