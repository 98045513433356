import { FC } from "react";
import { InputAdornment, Stack, TextField } from "@mui/material";
import CommonMenuListPage from "../../../components/CustomMenuListPage/CommonMenuListPage";
import { DatePicker } from "@mui/x-date-pickers";
import panImage from "../../../assets/images/pan-image.png";
import EventIcon from "@mui/icons-material/Event";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enUS } from "@mui/x-date-pickers/locales";

dayjs.extend(customParseFormat);

interface Props {
  handleBackBtnClick: Function;
  pathname?: string;
}

const MyProfile: FC<Props> = ({ handleBackBtnClick, pathname }) => {
  const usLocale =
    enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);

  const renderDatePickerTextField = (params: any) => {
    return (
      <TextField
        {...params}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EventIcon style={{ color: "#2E2E2E" }} />
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <CommonMenuListPage
      title="My Profile"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
      <Stack padding="0 1rem" rowGap="1rem">
        <TextField
          fullWidth
          label="PAN"
          defaultValue={userDetails?.pan}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <img src={panImage} alt="pan" style={{ width: "1.5rem" }} />
              </InputAdornment>
            ),
          }}
        />
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          localeText={{
            ...usLocale,
            fieldMonthPlaceholder: (params) =>
              params.contentType === "letter" ? "MMM" : "MM",
          }}
        >
          <DatePicker
            label="DOB"
            value={userDetails?.dob ? dayjs(userDetails?.dob) : null}
            format="DD MMM YYYY"
            className="date-picker"
            sx={{ color: "blue" }}
            readOnly
            slots={{ textField: renderDatePickerTextField }}
          />
        </LocalizationProvider>
        <TextField
          InputProps={{ readOnly: true }}
          fullWidth
          label="Mobile Number"
          defaultValue={userDetails?.mobileNumber}
        />
        <TextField
          fullWidth
          label="Email ID"
          defaultValue={userDetails?.emailId}
          InputProps={{ readOnly: true }}
        />
      </Stack>
    </CommonMenuListPage>
  );
};

export default MyProfile;
