import React, { FC } from "react";
import "./CustomSimpleCard.scss";
import { Box,Typography } from "@mui/material";
import CustomNetworkImage from "../CustomNetworkImage/CustomNetworkImage";

interface Props {
  title: string;
  description: string;
  link: JSX.Element;
  imageUrl : string | undefined;
}

const CustomSimpleCard: FC<Props> = ({
  title = "",
  description = "",
  link,
  imageUrl
}) => {
  return (
    <Box className="custom-simple-card" padding="1rem" display="flex" flexDirection="row" alignItems="flex-start" justifyContent="space-between">
    {/* Container for all text */}
    <Box marginRight="1rem" flex="1">
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle2" marginTop=".25rem">
        {description}
      </Typography>
      <Box marginTop="0.5rem">{link}</Box>
    </Box>

    {/* Container for image */}
    {imageUrl && (
      <CustomNetworkImage
        src={imageUrl}
        style={{ width: '6.5625rem', height: '6.5625rem', objectFit: 'cover' }}
        alt=""
      />
    )}
  </Box>
  );
};

export default CustomSimpleCard;
