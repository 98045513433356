import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  Api_MapLobProduct,
  Api_TeraDataUserRegistration,
} from "../../utils/ApiEndpoints";
import {
  T_MapLobProduct_ReqPayload,
  T_MapLobProduct_Response,
  T_TeraDataUserRegistrationPay,
  T_TeraDataUserRegistrationRes,
} from "../Feature_Login/Login_Types";

export const onBoardingRtk = CSApiService.injectEndpoints({
  overrideExisting: "throw",
  endpoints: (builder) => ({
    teraDataUserRegistration: builder.query<
      T_ApiResponse<T_TeraDataUserRegistrationRes>,
      T_TeraDataUserRegistrationPay
    >({
      query: (payload) => ({
        url: Api_TeraDataUserRegistration,
        method: "POST",
        body: payload,
      }),
    }),
    mapLobProduct: builder.query<
      T_ApiResponse<T_MapLobProduct_Response>,
      T_MapLobProduct_ReqPayload
    >({
      query: (payload) => ({
        url: Api_MapLobProduct,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});
export const {
  useLazyTeraDataUserRegistrationQuery,
  useLazyMapLobProductQuery,
} = onBoardingRtk;
