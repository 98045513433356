import { FC, useState } from "react";
import { defaultEmptyFun } from "../../utils/helper";
import { Box, Button, Typography } from "@mui/material";
import OfferSection from "../CustomOfferSection/OfferSection";
import CommonGifAnimation from "../CustomGifAnimation/CommonGifAnimation";
import AlertGif from "../../assets/gif/Exclamation_Mark.json";
import { T_AnimationData } from "../../globalTypes/GlobalTypes";
import CallUsPopUp from "../../features/Feature_GeneralAppElements/CallUsPopUp/CallUsPopUp";

interface Props {
  title: string;
  des?: string;
  btnLabel: string;
  btnOnClick?: any;
}

const CustomErrorPopupBody: FC<Props> = ({
  title,
  des,
  btnLabel,
  btnOnClick = defaultEmptyFun,
}) => {
  const [callUsOpen, setCallUsOpen] = useState(false);
  return (
    <Box className="common-error-popup-body">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={"13.5625rem"}
      >
        <CommonGifAnimation
          width="25%"
          animationData={AlertGif as T_AnimationData}
        />
      </Box>
      <Typography
        marginTop="2rem"
        textAlign="center"
        variant="h3"
        marginBottom={"1rem"}
      >
        {title}
      </Typography>
      {des && (
        <Typography
          marginTop=".625rem"
          textAlign="center"
          variant="subtitle2"
          marginBottom={"1rem"}
        >
          {des}
        </Typography>
      )}
      <Button
        fullWidth
        variant="contained"
        className="popup-btn"
        onClick={() => setCallUsOpen(true)}
      >
        {btnLabel}
      </Button>
      {callUsOpen && (
        <CallUsPopUp open={callUsOpen} handleOpen={setCallUsOpen} />
      )}
    </Box>
  );
};

export default CustomErrorPopupBody;
