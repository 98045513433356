import React, { useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import HlServiceRequestCard from "./HlServiceRequestCard/HlServiceRequestCard";
import { useLazyFetchCreateCaseQueryQuery } from "../Homeloan_Api";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { T_HlServiceRequestResponse, T_SummaryData } from "../Homeloan_Types";
import { useNavigate } from "react-router-dom";
import { R_HLRoutes } from "../Homeloan_Routes";
import { useDispatch } from "react-redux";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import dayjs from "dayjs";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
interface HlServiceRequestHomeProps {}

const HlServiceRequestHome: React.FC<HlServiceRequestHomeProps> = () => {
  const [fetchCreateCaseQuery] = useLazyFetchCreateCaseQueryQuery();
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const [serviceRequestList, setServiceRequestList] = useState<
    T_HlServiceRequestResponse[]
  >([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );

  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  useEffect(() => {
    if(allSummaryData[0]?.account_detail.rows[0]?.CUSTOMER_NO !== "" && allSummaryData[0]?.account_detail.rows[0]?.CUSTOMER_NO){
      getActiveServiceRequestList();
    }
  }, [allSummaryData[0]?.account_detail.rows[0]?.CUSTOMER_NO]);

  const getActiveServiceRequestList = async () => {
    try {
      setIsBtnLoading(true);
      const response = await fetchCreateCaseQuery({
        caseId: "",
        customerId: allSummaryData[0]?.account_detail.rows[0]?.CUSTOMER_NO,
        fromDate: dayjs().subtract(14, "days").format("YYYY-MM-DD"),
        toDate: dayjs().format("YYYY-MM-DD"),
        "active": "true"
      });
      if (response.isSuccess) {
        setServiceRequestList(response.data.payload);
      }
      setIsBtnLoading(false);
    } catch (e) {
      setIsBtnLoading(false);
    }
  };

  return (
    <Box
      sx={
        {
          // backgroundColor :'#F1F3F6',
          // width:'100%'
        }
      }
    >
      <Box sx={{ padding: "16px" }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "18px",
              color: "#2E2E2E",
            }}
          >
            Service Requests
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                color: "#C7222A",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(R_HLRoutes.home_loan_service_request, {});
              }}
            >
              View All
            </Typography>
            <ArrowRightAlt
              sx={{
                fontWeight: 600,
                color: "#C7222A",
              }}
            />
          </Box>
        </Stack>

        {serviceRequestList.length > 0 ? (
          serviceRequestList.map((item, index) => {
            return (
              <Box
                sx={{
                  backgroundColor: "#FFFF",
                  borderRadius: "1.5rem",
                  paddingTop: "1px",
                  paddingLeft: "1px",
                  paddingRight: "1px",
                  marginTop: "0.75rem",
                }}
              >
                <HlServiceRequestCard
                  key={index}
                  HlServiceRequestDetails={item}
                />
              </Box>
            );
          })
        ) : isBtnLoading ? <CustomThreeDotsLoader /> :  (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignContent: "flex-start",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Stack />
            <ReportProblemIcon
              sx={{
                color: "#979CAE",
                fontSize: "20px",
                marginBottom: "16px",
              }}
            />
            <Stack />

            <Typography
              sx={{
                fontFamily: "Anek Latin",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.8px",
                textAlign: "left",
                color: "#979CAE",
              }}
            >
                No Service Request Available
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HlServiceRequestHome;
