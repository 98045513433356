import { FC, useState } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./DownloadStatementPopups.scss";
import ArrowRightIcon from "../../../assets/icons/red-arrow-right.svg";
import { Colors } from "../../../styles/constant";
import CloseIcon from "@mui/icons-material/Close";
import otpVerificationImage from "../../../assets/icons/otp-verification.svg";
import exclamationMarkIcon from "../../../assets/icons/Exclamation mark.svg";
import LockIcon from "@mui/icons-material/Lock";
import { MuiOtpInput } from "mui-one-time-password-input";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import CommonFeedbackPopupBody from "../../../components/CustomFeedbackPopupBody/CommonFeedbackPopupBody";
import emptyDocumentImage from "../../../assets/images/empty-document.svg";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { T_PolicyAllDetails } from "../LifeInsurance_Types";
import CustomErrorPopupBody from "../../../components/CustomErrorPopupBody/CustomErrorPopupBody";
import {
  E_ChannelOptions,
  T_LiRouteState,
} from "../../../globalTypes/GlobalTypes";

interface Props {
  open: boolean;
  handleOpen: Function;
  popupFlow: string;
  isSuccess?: boolean;
}

const DownloadStatementPopups: FC<Props> = ({
  open,
  handleOpen,
  popupFlow,
  isSuccess,
}) => {
  const [step, setStep] = useState<number>(0);
  const navigate = useNavigate();

  const handleClosePopup = () => {
    handleOpen(false);
    setStep(0);
  };
  const csAppSlice = useAppSelector((st) => st.CsAppSlice);
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState).policyNumber || "";

  const policyAllDetails = useAppSelector(
    (st) => st.LifeInsuranceSlice.policyAllDetails
  );
  const selectedPolicyAllDetails = policyAllDetails.find(
    (item: T_PolicyAllDetails) => {
      return item.Policy_Level_Details.POLICY_NUMBER === policyNumber;
    }
  );

  const renderSteps = () => {
    if (!isSuccess) {
      return (
        <CustomErrorPopupBody
          title="Document is unavailable"
          btnLabel="Connect to customer support"
        />
      );
    }
    switch (popupFlow) {
      case "policyBond":
        switch (step) {
          case 0:
            return (
              <CommonSuccessPopupBody
                title="Congratulations!"
                des="Policy Bond downloaded successfully"
                // btnLabel="Rate Your Experience"
                btnLabel="Continue to policy details"
                // btnOnClick={() => setStep(2)}
                btnOnClick={handleClosePopup}
              />
            );
          case 2:
            return <CommonFeedbackPopupBody linkOnClick={handleClosePopup} />;
          case 3:
            return (
              <Box>
                <Box display="flex" justifyContent="center">
                  <img src={emptyDocumentImage} alt="empty document" />
                </Box>
                <Typography marginTop="2rem" textAlign="center" variant="h3">
                  Policy Bond Unavailable
                </Typography>
                <Typography
                  marginTop=".625rem"
                  textAlign="center"
                  variant="subtitle2"
                >
                  Policy bond is not available on app for policies where policy
                  start date is prior to 1st Jan 2016.
                </Typography>
                <Typography
                  paddingX="1.25rem"
                  marginTop="2rem"
                  textAlign="center"
                  variant="subtitle2"
                >
                  You can write to{" "}
                  <span style={{ color: Colors.red }}>
                    care.lifeinsurance@adityabirlacapital.com
                  </span>{" "}
                  from your registered Email-Id and get the policy bond.
                </Typography>
                <Button
                  sx={{ marginTop: "1.25rem" }}
                  fullWidth
                  variant="contained"
                  className="popup-btn"
                >
                  Email Now
                </Button>
              </Box>
            );
          case 4:
            return (
              <Box>
                <Box display="flex" justifyContent="center">
                  <img src={emptyDocumentImage} alt="empty document" />
                </Box>
                <Typography marginTop="2rem" textAlign="center" variant="h3">
                  Policy Bond Unavailable
                </Typography>
                <Typography
                  marginTop=".625rem"
                  textAlign="center"
                  variant="subtitle2"
                >
                  Policy bond is not available currently. You can try again
                  after sometime
                </Typography>
                <Typography marginY=".625rem" textAlign="center" variant="h5">
                  OR
                </Typography>
                <Typography
                  paddingX="1.25rem"
                  textAlign="center"
                  variant="subtitle2"
                >
                  You can write to{" "}
                  <span style={{ color: Colors.red }}>
                    care.lifeinsurance@adityabirlacapital.com
                  </span>{" "}
                  from your registered Email-Id and get the policy bond.
                </Typography>
                <Button
                  sx={{ marginTop: "1.25rem" }}
                  fullWidth
                  variant="contained"
                  className="popup-btn"
                >
                  Email Now
                </Button>
              </Box>
            );
          default:
            break;
        }
        break;

      case "download":
        switch (step) {
          case 0:
            return (
              <CommonSuccessPopupBody
                title="Congratulations!"
                des="Downloaded successfully"
                // btnLabel="Rate Your Experience"
                btnLabel="Continue to policy details"
                // btnOnClick={() => setStep(2)}
                btnOnClick={() => navigate(-1)}
              />
            );
          case 1:
            return (
              <CommonSuccessPopupBody
                title="Congratulations!"
                des="Downloaded successfully"
                btnLabel="Continue to policy details"
              />
            );
          case 2:
            return <CommonFeedbackPopupBody linkOnClick={handleClosePopup} />;
          default:
            break;
        }
        break;

      case "email":
        switch (step) {
          case 0:
            return (
              <CommonSuccessPopupBody
                title="Email sent successfully!"
                des={`You will receive the document on your registered Email-Id:- ${
                  selectedPolicyAllDetails?.Owner.EMAIL_ID || ""
                }  shortly`}
                // btnLabel="Rate Your Experience"
                btnLabel="Continue to policy details"
                // btnOnClick={() => setStep(2)}
                btnOnClick={() => navigate(-1)}
              />
            );
          case 1:
            return (
              <CommonSuccessPopupBody
                title="Email sent successfully!"
                des={`You will receive the document on your registered Email-Id:- ${
                  selectedPolicyAllDetails?.Owner.EMAIL_ID || ""
                }  shortly`}
                btnLabel="Continue to policy details"
              />
            );
          case 2:
            return <CommonFeedbackPopupBody linkOnClick={handleClosePopup} />;
          case 3:
            return (
              <Box>
                <Typography textAlign="center" variant="h3">
                  Enter your Email-Id
                </Typography>
                <Typography
                  marginTop=".625rem"
                  textAlign="center"
                  variant="subtitle2"
                >
                  Your email address is not updated with us. Please enter it
                  here
                </Typography>
                <TextField
                  sx={{ marginTop: ".625rem" }}
                  fullWidth
                  label="Enter Email-Id"
                />
                <Button
                  sx={{ marginTop: "1.875rem" }}
                  fullWidth
                  variant="contained"
                  className="popup-btn"
                >
                  Update
                </Button>
              </Box>
            );
          case 4:
            return (
              <Box className="otp-verification">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={otpVerificationImage} alt="otp" />
                  <Typography
                    textAlign="center"
                    marginTop="1.5rem"
                    variant="h2"
                  >
                    OTP Verification
                  </Typography>
                  <Typography
                    textAlign="center"
                    marginTop=".25rem"
                    variant="subtitle2"
                    color={Colors.textGray}
                  >
                    We have sent an OTP to the Email-Id
                  </Typography>
                  <Typography fontSize={14} fontWeight={700} color={Colors.red}>
                    ritxxxx.shah@axxxx.com
                  </Typography>
                </Box>
                <MuiOtpInput
                  className="otp-input"
                  sx={{ gap: ".5rem" }}
                  length={6}
                />
                <Typography
                  marginTop=".75rem"
                  fontSize={".75rem"}
                  fontWeight={500}
                  color="#9C9C9C"
                >
                  Resend OTP in 00:60s
                </Typography>
                <Button
                  fullWidth
                  sx={{ marginTop: "4.4375rem" }}
                  variant="contained"
                  className="popup-btn"
                >
                  Verify
                </Button>
              </Box>
            );
          case 5:
            return (
              <CommonSuccessPopupBody
                title="Congratulations!"
                des="You have successfully registered your Email-Id as ritika.shah@abc.com"
                // btnLabel="Rate Your Experience"
                btnLabel="Continue to policy details"
                btnOnClick={() => navigate(-1)}
              />
            );
          case 6:
            return (
              <CommonSuccessPopupBody
                title="Congratulations!"
                des="You have successfully submitted your request to update your Email-Id as ritika.shah@abc.com"
                // btnLabel="Rate Your Experience"
                btnLabel="Continue to policy details"
                btnOnClick={() => navigate(-1)}
              />
            );
          default:
            break;
        }
        break;

      case "whatsapp":
        switch (step) {
          case 0:
            return (
              <CommonSuccessPopupBody
                title="WhatsApp sent successfully!"
                des="You will receive the document on your registered mobile number via WhatsApp shortly"
                // btnLabel="Rate Your Experience"
                btnLabel="Continue to policy details"
                // btnOnClick={() => setStep(2)}
                btnOnClick={() => navigate(-1)}
              />
            );
          case 1:
            return (
              <CommonSuccessPopupBody
                title="WhatsApp sent successfully!"
                des="You will receive the document on your registered mobile number via WhatsApp shortly"
                btnLabel="Continue to policy details"
              />
            );
          case 2:
            return <CommonFeedbackPopupBody linkOnClick={handleClosePopup} />;
          case 3:
            return (
              <Box>
                <Typography textAlign="center" fontSize={18} fontWeight={600}>
                  WhatsApp Opt-In
                </Typography>
                <Typography
                  marginTop=".625rem"
                  textAlign="center"
                  fontSize={14}
                  fontWeight={400}
                >
                  Please confirm your mobile number registered for WhatsApp
                </Typography>
                <TextField
                  sx={{ marginTop: ".625rem" }}
                  fullWidth
                  label="Registered mobile number"
                  defaultValue="9876543219"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon fontSize="small" sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  sx={{ marginTop: "1.875rem" }}
                  fullWidth
                  variant="contained"
                  className="popup-btn"
                >
                  Update
                </Button>
              </Box>
            );
          default:
            break;
        }
        break;

      default:
        break;
    }
  };

  const handlePopupTopBtnClick = () => {
    if (
      popupFlow === "policyBond" ||
      csAppSlice.channel === E_ChannelOptions.ONE_ABC
    ) {
      handleOpen(false);
    } else {
      handleOpen(false);
      navigate(-1);
    }
  };

  let buttonLabel = "";
  if (csAppSlice.channel === E_ChannelOptions.ONE_ABC) {
    buttonLabel = "Close";
  } else if ([0, 5, 6].includes(step) || isSuccess) {
    buttonLabel = "Skip to policy details";
  } else if ([3, 4].includes(step)) {
    buttonLabel = "Close";
  }

  return (
    <CustomPopup
      open={open}
      handleOpen={handlePopupTopBtnClick}
      btnLabel={buttonLabel}
      className="download-statement-popup"
      btnEndIcon={
        [0, 5, 6].includes(step) || isSuccess ? (
          <img
            src={ArrowRightIcon}
            alt="right-arrow"
            style={{ color: Colors.red }}
          />
        ) : null
      }
      btnStartIcon={
        [3, 4].includes(step) ? <CloseIcon fontSize="small" /> : null
      }
    >
      <Box className="download-statement-popup-content">{renderSteps()}</Box>
    </CustomPopup>
  );
};

export default DownloadStatementPopups;
