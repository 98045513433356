import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  Api_FetchConfigData,
  Api_SaveConsent,
  Api_FileUpload,
  Api_FetchPopularService,
  Api_FetchSearchHistory,
  Api_FetchSearchBarContent,
  Api_SaveSearchHistory,
  Api_UpdateMpin,
  Api_FetchProfile_Details,
  Api_ClearSearchHistory,
  Api_LogOut,
  Api_RateUs,
  Api_FetchMasterData,
  Api_SaveHoldings,
  Api_CheckCiiExistsForMobileEmail,
  APi_CMS_RateUsContent,
  API_CMS_FAQ_ModuleList,
  API_CMS_FAQ_CategoryList,
  API_CMS_FAQ_List,
  API_CMS_FAQ_Parent_ModuleList,
} from "../../utils/ApiEndpoints";
import {
  T_FetchConfigData_ReqPayload,
  T_FetchConfigData_Response,
  T_UsersConsent_ReqPayload,
  T_UsersConsent_Response,
  T_raiseABugDocUpload_Response,
  T_fetchPopularService_Request,
  T_PopularService_Response,
  T_fetchSearchHistory_Response,
  T_fetchSearchHistory_Request,
  T_fetchSearchBarContent_Response,
  T_fetchSearchBarContent_Request,
  T_saveSearchHistory_Request,
  T_saveSearchHistory_Response,
  T_updateMpin_Request,
  T_updateMpin_Response,
  T_FetchProfileDetails_Response,
  T_FetchProfileDetails_ReqPayload,
  T_clearSearchHistory_Response,
  T_clearSearchHistory_Request,
  T_Logout_Response,
  T_RateUs_Response,
  T_RateUs_ReqPayload,
  T_Logout_ReqPayload,
  T_FetchMasterData_Response,
  T_SaveHolding_ReqPayload,
  T_SaveHoldings_Response,
  T_CheckCiiExistsForMobileEmail_Response,
  T_CheckCiiExistsForMobileEmail_Payload,
  T_Fetch_FaqModules_Response,
  T_FetchCategory_Request,
  T_Fetch_FaqCategories_Response,
  T_FaqList_Request,
  T_FaqList_Response,
  T_Fetch_FaqParentModules_Response,
} from "./GeneralAppElements_Types";
import { T_RateUsContent_Response } from "../Features_Cms/CmsRateContentVariables.Types";
export const GeneralAppElementsApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    storeUserConsent: builder.query<
      T_ApiResponse<T_UsersConsent_Response>,
      T_UsersConsent_ReqPayload
    >({
      query: (payload) => ({
        url: `${Api_SaveConsent}`,
        method: "POST",
        body: payload,
      }),
    }),
    fetchConfigDataDetails: builder.query<
      T_ApiResponse<T_FetchConfigData_Response>,
      T_FetchConfigData_ReqPayload
    >({
      providesTags: ["CONFIG_DATA"],
      query: ({ optionTypeReq }) => ({
        url: `${Api_FetchConfigData}`,
        method: "POST",
        body: { optionType: optionTypeReq },
      }),
    }),
    fetchProfileDetails: builder.query<
      T_ApiResponse<T_FetchProfileDetails_Response>,
      T_FetchProfileDetails_ReqPayload
    >({
      providesTags: ["PROFILE_DATA"],
      query: (payload) => ({
        url: Api_FetchProfile_Details,
        method: "POST",
        body: payload,
      }),
    }),
    fetchPopularService: builder.query<
      T_ApiResponse<T_PopularService_Response>,
      T_fetchPopularService_Request
    >({
      providesTags: ["SEARCH_POPULAR_SERVICES"],
      query: (formData) => ({
        url: `${Api_FetchPopularService}`,
        method: "POST",
        body: formData,
      }),
    }),
    fetchSearchHistory: builder.query<
      T_ApiResponse<T_fetchSearchHistory_Response>,
      T_fetchSearchHistory_Request
    >({
      providesTags: ["SEARCH_HISTORY"],
      query: (requestData) => ({
        url: `${Api_FetchSearchHistory}`,
        method: "POST",
        body: requestData,
      }),
    }),
    fetchSearchBarContent: builder.query<
      T_ApiResponse<T_fetchSearchBarContent_Response>,
      T_fetchSearchBarContent_Request
    >({
      providesTags: ["SEARCH_RESULT"],
      query: (requestData) => ({
        url: `${Api_FetchSearchBarContent}`,
        method: "POST",
        body: requestData,
      }),
    }),
    logoutApi: builder.query<
      T_ApiResponse<T_Logout_Response>,
      T_Logout_ReqPayload
    >({
      query: (requestData) => ({
        url: `${Api_LogOut}`,
        method: "POST",
        headers: {
          csUserId: `${requestData.csUserId}`,
        },
        body: requestData,
      }),
    }),
    rateUsApi: builder.query<
      T_ApiResponse<T_RateUs_Response>,
      T_RateUs_ReqPayload
    >({
      query: (requestData) => ({
        url: `${Api_RateUs}`,
        method: "POST",
        body: requestData,
      }),
    }),
    fetchRateUsContent: builder.query<T_RateUsContent_Response, void>({
      query: () => ({
        url: APi_CMS_RateUsContent,
        method: "POST",
      }),
      //providesTags: ['CMS_PAGE_VARIABLES_DATA'],
    }),
    saveSearchHistory: builder.query<
      T_ApiResponse<T_saveSearchHistory_Response>,
      T_saveSearchHistory_Request
    >({
      query: (formData) => ({
        url: `${Api_SaveSearchHistory}`,
        method: "POST",
        body: formData,
      }),
    }),
    clearSearchHistory: builder.mutation<
      T_ApiResponse<T_clearSearchHistory_Response>,
      T_clearSearchHistory_Request
    >({
      invalidatesTags: ["SEARCH_HISTORY"],
      query: (formData) => ({
        url: `${Api_ClearSearchHistory}`,
        method: "POST",
        body: formData,
      }),
    }),
    raiseABugDocUpload: builder.query<
      T_ApiResponse<T_raiseABugDocUpload_Response>,
      FormData
    >({
      query: (formData) => ({
        url: `${Api_FileUpload}`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    }),
    updatempin: builder.query<
      T_ApiResponse<T_updateMpin_Response>,
      T_updateMpin_Request
    >({
      query: (formData) => ({
        url: `${Api_UpdateMpin}`,
        method: "POST",
        body: formData,
      }),
    }),
    fetchMasterData: builder.query<
      T_ApiResponse<T_FetchMasterData_Response>,
      string
    >({
      providesTags: ["MASTER_DATA"],
      query: (optionType) => ({
        method: "POST",
        url: Api_FetchMasterData,
        body: { optionType },
      }),
    }),
    saveHoldingData: builder.mutation<
      T_ApiResponse<T_SaveHoldings_Response>,
      T_SaveHolding_ReqPayload[]
    >({
      // invalidatesTags: ["CUSTOMER_LOB_DETAILS", "LI_DASHBOARD"],
      query: (payload) => ({
        method: "POST",
        url: Api_SaveHoldings,
        data: payload,
      }),
    }),
    checkIfCIIExistsForSecondaryUser: builder.query<
      T_ApiResponse<T_CheckCiiExistsForMobileEmail_Response>,
      T_CheckCiiExistsForMobileEmail_Payload
    >({
      query: (payload) => ({
        method: "POST",
        url: Api_CheckCiiExistsForMobileEmail,
        data: payload,
      }),
    }),
    fetchFaqParentModules: builder.query<
      T_ApiResponse<T_Fetch_FaqParentModules_Response>,
      string
    >({
      query: () => ({
        method: "GET",
        url: API_CMS_FAQ_Parent_ModuleList,
      }),
    }),
    fetchFaqModules: builder.query<
      T_ApiResponse<T_Fetch_FaqModules_Response>,
      string
    >({
      query: (payload) => ({
        method: "GET",
        url: API_CMS_FAQ_ModuleList,
        params: {
          parentModules: payload,
        },
      }),
    }),
    fetchFaqCategories: builder.query<
      T_ApiResponse<T_Fetch_FaqCategories_Response>,
      string
    >({
      query: (value) => ({
        method: "GET",
        url: API_CMS_FAQ_CategoryList,
        params: {
          modules: value,
        },
      }),
    }),
    fetchFaqList: builder.query<
      T_ApiResponse<T_FaqList_Response>,
      T_FaqList_Request
    >({
      query: (reqPayload) => ({
        method: "GET",
        url: API_CMS_FAQ_List,
        params: {
          categories: reqPayload.categories,
          modules: reqPayload.modules,
        },
      }),
    }),
  }),
});

export const {
  useLazyStoreUserConsentQuery,
  useLazyFetchConfigDataDetailsQuery,
  useLazyRaiseABugDocUploadQuery,
  useLazyFetchPopularServiceQuery,
  useLazyFetchSearchHistoryQuery,
  useLazyFetchSearchBarContentQuery,
  useLazySaveSearchHistoryQuery,
  useClearSearchHistoryMutation,
  useLazyUpdatempinQuery,
  useLazyFetchProfileDetailsQuery,
  useLazyLogoutApiQuery,
  useLazyRateUsApiQuery,
  useLazyFetchMasterDataQuery,
  useSaveHoldingDataMutation,
  useLazyCheckIfCIIExistsForSecondaryUserQuery,
  useLazyFetchRateUsContentQuery,
  useLazyFetchFaqParentModulesQuery,
  useLazyFetchFaqModulesQuery,
  useLazyFetchFaqCategoriesQuery,
  useLazyFetchFaqListQuery,
} = GeneralAppElementsApi;
