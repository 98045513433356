import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  Api_FetchPolicyDetailsByMobileNumber,
  Api_PolicyInsured,
  Api_DownloadECard,
  Api_DownloadPolicyDocs,
  Api_DownloadPolicyWording,
  Api_UpdateHIDetails
} from "../../utils/ApiEndpoints";
import {
  T_AppMobileNumber_Response,
  T_PolicyInsured_Response,
  T_ECard_ReqPayload,
  T_ECard_Response,
  T_DownloadPolicyWording_Response,
  T_DownloadPolicyWording_ReqPayload,
  T_downloadHiPolicyDocuments_ReqPayload,
  T_DownloadHiPolicyDocuments_Response,
  T_UpdateHIDetails_Response,
  T_UpdateHIDetails_ReqPayload
} from "./HealthInsurance_Types";

export const HealthInsuranceApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchPolicyDetailsByMobileNumber: builder.query<
      T_ApiResponse<T_AppMobileNumber_Response>,
      string
    >({
      providesTags: ["HI_POLICY_DETAILS_BY_MOBILE"],
      query: (mobileNumber) => ({
        url: Api_FetchPolicyDetailsByMobileNumber,
        method: "POST",
        body: { mobileNumber },
      }),
    }),
    fetchPolicyInsured: builder.query<
      T_ApiResponse<T_PolicyInsured_Response>,
      string
    >({
      providesTags: ["HI_POLICY_INSURED"],
      query: (policyId) => ({
        url: Api_PolicyInsured,
        method: "POST",
        body: { policyId },
      }),
    }),
    downloadECard: builder.query<
      T_ApiResponse<T_ECard_Response>,
      T_ECard_ReqPayload
    >({
      query: ({ policyNumber }) => ({
        url: `${Api_DownloadECard}`,
        method: "POST",
        body: {
          policyNumber: policyNumber,
        },
      }),
    }),
    downloadHiPolicyDocuments: builder.query<
      T_ApiResponse<T_DownloadHiPolicyDocuments_Response>,
      T_downloadHiPolicyDocuments_ReqPayload
    >({
      query: ({ sender, searchRequestList }) => ({
        url: `${Api_DownloadPolicyDocs}`,
        method: "POST",
        body: {
          sender: sender,
          searchRequestList: searchRequestList,
        },
      }),
    }),
    downloadPolicyWording: builder.query<
      T_ApiResponse<T_DownloadPolicyWording_Response>,
      T_DownloadPolicyWording_ReqPayload
    >({
      query: ({ productName, planName }) => ({
        url: Api_DownloadPolicyWording,
        method: "POST",
        body: {
          productName: productName,
          planName: planName,
        },
      }),
    }),
    updateHIDetails: builder.query<
    T_ApiResponse<T_UpdateHIDetails_Response>,
    T_UpdateHIDetails_ReqPayload>({
      query: (request) => ({
        url: `${Api_UpdateHIDetails}`,
        method: "POST",
        body: request,
      }),
    })  
  }),
});

export const {
  useLazyFetchPolicyDetailsByMobileNumberQuery,
  useLazyFetchPolicyInsuredQuery,
  useLazyDownloadECardQuery,
  useLazyDownloadPolicyWordingQuery,
  useLazyDownloadHiPolicyDocumentsQuery,
  useLazyUpdateHIDetailsQuery
} = HealthInsuranceApi;
