import React, { FC, useCallback, useEffect, useState } from "react";
import {
  useLazyFetchDataServiceDetailsQuery,
  useLazyFetchLanDetailsQuery,
} from "../Homeloan_Api";
import {
  LoanDetails,
  T_HlDataServiceApiRequest,
  T_HlFetchLanApiRequest,
  T_SummaryData,
} from "../Homeloan_Types";
import { useDispatch } from "react-redux";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { HlLobCode } from "../../../utils/constants";
import { setHomeLoanInfo, setSummaryData } from "../Homeloan_Slice";
import { Box, Stack, Typography } from "@mui/material";
import { CustomAccordionSkeleton } from "../../../components/CustomAccordianSkeletonShimmer/CustomAccordianSkeletonShimmer";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion";
import HlDashboardCard from "../hlDashboardCard/HlDashboardCard";
import homeIcon from "../../../assets/icons/home.svg";
import { Colors } from "../../../styles/constant";
import "./HomeloanCard.scss";
import { setSelectedModule } from "../../Feature_GeneralAppElements/GeneralAppElements_Slice";
import clockIcon from "../../../assets/icons/clock-time-three-icon.svg";
import { uniq } from "lodash";

interface Props {
  moduleName?: string;
}

const HomeloanCard: FC<Props> = ({ moduleName = "" }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOverDuePresent, setOverDuePresent] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [fetchDataServiceDetails] = useLazyFetchDataServiceDetailsQuery();
  const [fetchLanDetails] = useLazyFetchLanDetailsQuery();

  const hlLoanInfo = useAppSelector((state) => state.HomeLoanSlice.loanInfo);
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);

  const customerAndLobDetails = userDetails?.customerAndLobDetails;
  const allHomeLoanPans = customerAndLobDetails
    ?.filter((item) => item.LOB === HlLobCode)
    .filter((ln) => ln?.PAN && ln?.PAN !== "")
    .map((val) => val.PAN);

  useEffect(() => {
    ApiCall();
  }, [customerAndLobDetails]);

  const ApiCall = async () => {
    setIsLoading(true);
    await fetchAllHlDetails();
    setIsLoading(false);
  };

  const fetchLanDetailsFromPan = async (panArray: string[]) => {
    let promises = uniq([...panArray]).map((item: string) => {
      const apiRequest: T_HlFetchLanApiRequest = {
        pan: item,
      };
      return fetchLanDetails(apiRequest);
    });
    let results = await Promise.all(promises);

    let transformedResult: string[] = results
      .flatMap((item: any) => {
        if (item.data?.payload?.LOAN_ACCOUNT_NUMBER) {
          return item.data?.payload?.LOAN_ACCOUNT_NUMBER?.map(
            (val: string) => val
          );
        }
      })
      .filter((val: string) => val !== undefined && val !== null && val !== "");
    const set: Set<string> = new Set(transformedResult);
    return Array.from(set);
  };

  const fetchAllHlDetails = async () => {
    if (!allHomeLoanPans) {
      return;
    }
    const allLanNumbers = await fetchLanDetailsFromPan(allHomeLoanPans);
    if (allLanNumbers.length <= 0) {
      return;
    }

    let promises = [...allLanNumbers].map((item: string) => {
      const apiRequest: T_HlDataServiceApiRequest = {
        CCC: "",
        LAN: [
          {
            ID: item,
          },
        ],
      };
      return fetchDataServiceDetails(apiRequest);
    });

    let results = await Promise.all(promises);
    // Transform results
    let transformedResult = results
      .flatMap((item: any) => item.data?.payload?.data?.Summary_Data || [])
      .filter((val: any) => val !== undefined && val !== null);
    // Filter out `undefined` and `null`
    // Dispatch the transformed result
    dispatch(setSummaryData(transformedResult));

    // Process rows if there are transformed results
    const rows =
      transformedResult.length > 0
        ? transformedResult
            .flatMap((row: T_SummaryData) => row.loan_info?.rows || [])
            .filter(
              (val: LoanDetails | undefined) =>
                val !== undefined && val !== null
            ) // Filter out `undefined` and `null`
        : [];

    if (rows.length > 0) {
      rows.forEach((row) => {
        if (row.AMOUNT_OVERDUE && row.AMOUNT_OVERDUE.trim() !== "") {
          setOverDuePresent(true);
        }
      });
    }
    // Dispatch the rows
    dispatch(
      setHomeLoanInfo({
        totalRows: `${rows.length}`,
        moreRows: "false",
        rows,
      })
    );
  };

  const handleOpen = useCallback(() => {
    dispatch(setSelectedModule(moduleName));
  }, [moduleName]);

  return (
    <>
      {isLoading ? (
        <CustomAccordionSkeleton />
      ) : (
        <>
          {hlLoanInfo?.rows?.length > 0 && (
            <Stack className="hl-card">
              <CustomAccordion
                title=""
                nodeTitle={
                  <Stack
                    direction="row"
                    gap={2}
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h3">{`Home loan (${hlLoanInfo?.rows?.length})`}</Typography>
                    {isOverDuePresent ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        marginRight="0.9375rem"
                        gap=".25rem"
                        padding="0.25rem 0.5rem 0.25rem 0.5rem"
                        borderRadius="0.5rem"
                        sx={{ backgroundColor: "#FFE7E5" }}
                      >
                        <img src={clockIcon} />
                        <Typography color={Colors.red} variant="h6">
                          Active overdue
                        </Typography>
                      </Box>
                    ) : null}
                  </Stack>
                }
                preIcon={
                  <img
                    src={homeIcon}
                    alt="home"
                    className="accordion-icon"
                    loading="lazy"
                  />
                }
                handleAccordionClick={handleOpen}
              >
                <React.Fragment>
                  <Stack rowGap="1rem">
                    <HlDashboardCard
                      // info={homeLoanMockData.data.Summary_Data[0].loan_info}
                      info={hlLoanInfo}
                    />
                  </Stack>
                </React.Fragment>
              </CustomAccordion>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export default HomeloanCard;
