import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {T_StockSliceState,T_StockDashboardDetails,T_CustomerData} from "./Stockc&Securities_Types";


const initialState:T_StockSliceState = {
    stockdashboardDetails: [],
    CustomerData:{}
}

export const StocksSlice = createSlice({
    name:"StocksSlice",
    initialState,
    reducers:{
        setStocksDashboardDetails: (state, action:PayloadAction<T_StockDashboardDetails[]>) => {
            return {
              ...state,
              stockdashboardDetails: action.payload,
            };
          },
          setUserDetails: (state, action:PayloadAction<T_CustomerData>) => {
            return {
              ...state,
              CustomerData: action.payload,
            };
          },
    }
})

export const StocksSliceReducer = StocksSlice.reducer;
export const StocksSliceKey = StocksSlice.reducerPath;


export const {
    setStocksDashboardDetails,
    setUserDetails
  } = StocksSlice.actions;

