import { FC } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { Colors } from "../../styles/constant";
// import HomeIcon from "../../assets/icons/home-muted-grey.svg";
import HomeIcon from "../../assets/icons/house-fill.svg";
import "./CustomBreadcrumbs.scss";
import { getMobileOperatingSystem } from "../../utils/helper";

interface Props {
  data: string[];
  className?: string;
}

const CustomBreadcrumbs: FC<Props> = ({ data = [], className = "" }) => {
  return (
    <Breadcrumbs
      separator="\"
      aria-label="breadcrumb"
      className= {`custom-breadcrumbs${getMobileOperatingSystem() === 'iOS' ? ' add-xtr-bb' : ''}`}
      itemsAfterCollapse={1}
      itemsBeforeCollapse={2}
      maxItems={3}
    >
      <img src={HomeIcon} alt="home-icon" className="home-icon" />
      {data.length > 0 &&
        data.map((item, index) => (
          <Typography
            key={item}
            variant="h6"
            color={data.length - 1 === index ? Colors.red : Colors.textGray}
          >
            {item}
          </Typography>
        ))}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
