import React, { useEffect, useState, useCallback, useMemo, FC } from "react";
import { Typography, Box, Button, Stack, Grid } from "@mui/material";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion";
import { Colors } from "../../../styles/constant";
import RefreshIcon from "../../../assets/icons/refresh-icon.svg";
import stockIcon from "../../../assets/icons/stocks-Icon.svg";
import ArrowRightIcon from "../../../assets/icons/red-arrow-right.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { useNavigate } from "react-router-dom";
import {
  setStocksDashboardDetails,
  setUserDetails,
} from "../Stocks&Securities_Slice";
import { useLazyFetchPortfolioDataQuery } from "../Stockc&Securities_Api";
import CustomShimmer from "../../../components/CustomShimmer/CustomShimmer";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import { T_AnimationData } from "../../../globalTypes/GlobalTypes";
import NoMFSchemeSummaryGif from "../../../assets/gif/No_MF_Scheme_Summary.json";
import "./Stocks&SecuritiesCard.scss";
import { CustomAccordionSkeleton } from "../../../components/CustomAccordianSkeletonShimmer/CustomAccordianSkeletonShimmer";

const StocksAndSecuritiesCard: React.FC = () => {
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const userMobileNumber = userDetails?.mobileNumber;
  const dispatch = useAppDispatch();

  const [portfolioData, setPortfolioData] = useState({
    CurrentPortfolioValue: 0,
    lastUpdatedDate: "",
    InvestedAmount: 0,
    GainLoss: 0,
    change: 0,
  });

  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();
  const [fetchPortfolioData, { data: PortfolioData, isLoading, isError }] =
    useLazyFetchPortfolioDataQuery();

  const processPortfolioData = useCallback(() => {
    if (PortfolioData) {
      if (PortfolioData.payload[0].holdingDetailsList.result !== null) {
        dispatch(
          setStocksDashboardDetails(
            PortfolioData.payload[0].holdingDetailsList.result.NewDataset
              .Table1 || []
          )
        );
        dispatch(setUserDetails(PortfolioData.payload[0].customerData));
        const portfolioValue =
          PortfolioData.payload[0].holdingDetailsList.result.NewDataset.Table1.reduce(
            (sum, stock) => sum + parseFloat(stock.MarketValue),
            0
          );

        const investedAmount =
          PortfolioData.payload[0].holdingDetailsList.result.NewDataset.Table1.reduce(
            (sum, stock) => sum + parseFloat(stock.InvestedCost),
            0
          );
        const LastUpdatedDate =
          PortfolioData.payload[0].holdingDetailsList.result.NewDataset
            .Table1[0].tradedate;
        const gainLoss =
          PortfolioData.payload[0].holdingDetailsList.result.NewDataset.Table1.reduce(
            (sum, stock) => sum + parseFloat(stock.UnrealisedGainLoss),
            0
          );

        const changeValue =
          investedAmount !== 0
            ? ((portfolioValue - investedAmount) / investedAmount) * 100
            : 0;
        setPortfolioData({
          CurrentPortfolioValue: portfolioValue,
          lastUpdatedDate: LastUpdatedDate,
          InvestedAmount: investedAmount,
          GainLoss: gainLoss,
          change: changeValue,
        });
      } else {
        dispatch(setStocksDashboardDetails([]));
        dispatch(setUserDetails(PortfolioData.payload[0].customerData));
        setPortfolioData({
          CurrentPortfolioValue: 0,
          lastUpdatedDate: "",
          InvestedAmount: 0,
          GainLoss: 0,
          change: 0,
        });
      }
    }
  }, [PortfolioData, dispatch]);

  const updatePortfolioData = useCallback(() => {
    if (userMobileNumber) {
      setLocalLoading(true);
      const requestData = {
        MobileNo: userMobileNumber,
        PanNo: "",
        RequestDate: new Date().toString(),
      };
      fetchPortfolioData(requestData).then(async (res) => {
        if (res.isSuccess === true) {
          processPortfolioData();
          setLocalLoading(false);
        }
      });
    }
  }, [userMobileNumber, fetchPortfolioData]);

  useEffect(() => {
    if (userMobileNumber) {
      const requestData = {
        MobileNo: userMobileNumber,
        PanNo: "",
        RequestDate: new Date().toString(),
      };
      fetchPortfolioData(requestData);
    }
  }, [userMobileNumber, fetchPortfolioData]);

  useEffect(() => {
    if (PortfolioData) {
      processPortfolioData();
    }
  }, [PortfolioData, processPortfolioData]);
  const gainLossColor = useMemo(
    () =>
      portfolioData.GainLoss > 0
        ? Colors.lightgreen
        : portfolioData.GainLoss < 0
        ? Colors.red
        : Colors.black,
    [portfolioData.GainLoss]
  );

  const changeColor = useMemo(
    () =>
      portfolioData.change > 0
        ? Colors.lightgreen
        : portfolioData.change < 0
        ? Colors.red
        : Colors.black,
    [portfolioData.change]
  );

  const isDataLoading = isLoading || localLoading;
  return (
    <Stack spacing="1rem" marginTop={"1rem"}>
      {!isLoading ? (
        <CustomAccordion
          title="Stocks and securities"
          preIcon={
            <img src={stockIcon} alt="heart" className="accordion-icon" />
          }
        >
          {isError && (
            // <Typography color="error">Failed to fetch portfolio data</Typography>
            <Box className="details-not-fetched-error">
              <CommonGifAnimation
                width={"6.25rem"}
                animationData={NoMFSchemeSummaryGif as T_AnimationData}
              />
              <Typography className="details-not-fetched-error-text">
                Unable to load your data
              </Typography>
              <Box
                className="details-not-fetched-error-link"
                onClick={updatePortfolioData}
              >
                Try Again
              </Box>
            </Box>
          )}
          {!isError && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: ".75rem",
                      lineHeight: ".825rem",
                      color: "#797979",
                      paddingRight: ".125rem",
                    }}
                  >
                    Last updated :
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: ".75rem",
                      lineHeight: ".9rem",
                    }}
                  >
                    {isDataLoading ? (
                      <CustomShimmer />
                    ) : (
                      portfolioData.lastUpdatedDate
                    )}
                    {/* {isDataLoading ? <CustomShimmer /> : lastUpdatedDate} */}
                    {/* {lastUpdatedDate} */}
                  </Typography>
                </Box>

                <Button
                  variant="text"
                  sx={{
                    padding: "0rem",
                    minWidth: "12px",
                    height: "12px",
                    fontSize: "12px",
                    color: Colors.red,
                    marginRight: "10px",
                  }}
                  onClick={updatePortfolioData}
                  endIcon={<img src={RefreshIcon}></img>}
                >
                  Update
                </Button>
              </Box>
              <Box sx={{ marginTop: "16px", marginBottom: "8px" }}>
                <Typography
                  variant="h5"
                  marginRight="5px"
                  minWidth={"75px"}
                  color={Colors.descriptionGray}
                >
                  Current portfolio value
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "32px",
                    lineHeight: "38.4px",
                  }}
                >
                  {isDataLoading ? (
                    <CustomShimmer />
                  ) : (
                    `₹${portfolioData.CurrentPortfolioValue.toFixed(
                      2
                    ).toLocaleString()}`
                  )}
                  {/* {isDataLoading ? (
                <CustomShimmer />
              ) : (
                `₹${CurrentPortfolioValue.toLocaleString()}`
              )} */}
                  {/* ₹{CurrentPortfolioValue.toLocaleString()} */}
                </Typography>
              </Box>
              <Grid container columnSpacing="1rem">
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="column" rowGap=".25rem">
                    <Typography variant="h6" fontWeight={400} color="#979CAE">
                      Invested amount
                    </Typography>
                    <Typography variant="h5" color={Colors.darkCharcoal}>
                      {isDataLoading ? (
                        <CustomShimmer />
                      ) : (
                        `₹${portfolioData.InvestedAmount.toFixed(
                          2
                        ).toLocaleString()}`
                      )}
                      {/* {isDataLoading ? (
                  <CustomShimmer />
                ) : (
                  `₹${InvestedAmount.toLocaleString()}`
                )} */}
                      {/* ₹{InvestedAmount.toLocaleString()} */}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="column" rowGap=".25rem">
                    <Typography variant="h6" fontWeight={400} color="#979CAE">
                      Gain/Loss
                    </Typography>
                    <Typography variant="h5" color={gainLossColor}>
                      {isDataLoading ? (
                        <CustomShimmer />
                      ) : (
                        `${
                          portfolioData.GainLoss >= 0 ? "+" : ""
                        }₹${portfolioData.GainLoss.toFixed(2).toLocaleString()}`
                      )}
                      {/* +₹{GainLoss.toLocaleString()} */}
                      {/* {isDataLoading ? (
                  <CustomShimmer />
                ) : (
                  `${GainLoss >= 0 ? "+" : ""}₹${GainLoss.toLocaleString()}`
                )} */}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex" flexDirection="column" rowGap=".25rem">
                    <Typography variant="h6" fontWeight={400} color="#979CAE">
                      Change
                    </Typography>
                    <Typography variant="h5" color={changeColor}>
                      {isDataLoading ? (
                        <CustomShimmer />
                      ) : (
                        `${
                          portfolioData.change >= 0 ? "+" : ""
                        }${portfolioData.change.toFixed(2)}%`
                      )}
                      {/* {change > 0 ? "+" : ""}
                {change.toFixed(2)}% */}
                      {/* {isDataLoading ? (
                  <CustomShimmer />
                ) : (
                  `${change >= 0 ? "+" : ""}${change.toFixed(2)}%`
                )} */}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box display="flex">
                <Button
                  variant="text"
                  sx={{
                    minWidth: "20px",
                    height: "20px",
                    color: Colors.red,
                    fontSize: "12px",
                    // marginTop: "20px",
                  }}
                  onClick={() => navigate(`/my-portfolio`)}
                  endIcon={<img src={ArrowRightIcon}></img>}
                >
                  View my portfolio
                </Button>
              </Box>
            </>
          )}
        </CustomAccordion>
      ) : (
        <CustomAccordionSkeleton />
      )}
    </Stack>
  );
};

export default StocksAndSecuritiesCard;
