import { Box, Grid, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import CustomItemSelect from "../CustomItemSelect/CustomItemSelect";

type radioItems = {
  title: string;
  value: string;
};

interface FrequencyProps {
  title?: string;
  type: string;
  values: radioItems[];
  handleChange: Function;
  selectedValue: string;
  validateInputs: Function;
}

const RenderDaysInAdvance: FC<FrequencyProps> = ({
  type = "",
  values,
  handleChange,
  selectedValue,
  validateInputs,
}) => {
  const [selectedData, setSelectedData] = useState<any>({
    title: "",
    value: "",
  });

  useEffect(() => {
    if (selectedValue) setSelectedData(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (selectedData.value !== "")
      validateInputs(type, true, {
        Frequency: selectedData.value,
      });
    else
      validateInputs(type, false, {
        Frequency: selectedData.value,
      });
  }, [selectedData]);

  const handleRadioChange = useCallback(
    (selectedIndex: number) => {
      setSelectedData(values[selectedIndex]);
    },
    [values]
  );

  return (
    <Box className="li-sa-frequency-mode">
      <Typography variant="h5">Days in Advance</Typography>
      <Box className="li-sa-frequency-mode-box-container">
        {values.length > 0 &&
          values.map((data, index) => {
            return (
              <CustomItemSelect
                key={data.value}
                title={data.title}
                value={data.value}
                handleRadioChange={handleRadioChange}
                selectedValue={selectedData}
                index={index}
              />
            );
          })}
      </Box>
    </Box>
  );
};
export default RenderDaysInAdvance;
