import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import "./HlServiceRequestCard.scss";
import CustomAccordion from "../../../../components/CustomAccordion/CustomAccordion";
import StatusBox from "./StatusBox";
import HeadsetMic from "@mui/icons-material/HeadsetMic";
import { T_HlServiceRequestResponse } from "../../Homeloan_Types";
import CallUs from "../../../Feature_GeneralAppElements/CallUs/CallUs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import CallUsPopUp from "../../../Feature_GeneralAppElements/CallUsPopUp/CallUsPopUp";
import { Colors } from "../../../../styles/constant";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { formatDateWithOrdinal } from "../../../../utils/conversionUtils";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

interface Props {
  HlServiceRequestDetails: T_HlServiceRequestResponse;
}

const HlServiceRequestCard: FC<Props> = ({ HlServiceRequestDetails }) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Box className="hl-sr-details-card">
      <Stack
        className="hl-first-container"
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack sx={{ paddingLeft: "1.5rem" }} direction="row" spacing={1}>
          <Typography sx={{ color: "#4C4C4C", fontSize: "12px" }}>
            Service request no
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#414042",
            }}
          >
            {HlServiceRequestDetails.caseId}
          </Typography>
        </Stack>
        <Typography
          sx={{
            color:
              HlServiceRequestDetails.stage === "Active"
                ? Colors.cadmiumGreen
                : "#767D93",
            fontWeight: 600,
            fontSize: "14px",
            paddingRight: "1.5rem",
          }}
        >
          {HlServiceRequestDetails.stage}
        </Typography>
      </Stack>
      <Box sx={{ padding: "1.5rem" }}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" spacing={1}>
            <Typography
              sx={{ color: "#4C4C4C", fontSize: "12px", textWrap: "nowrap" }}
            >
              Acc. no.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#414042",
                textWrap: "nowrap",
              }}
            >
              {HlServiceRequestDetails.accountNumber}
            </Typography>
          </Stack>
          <div className="capsule">
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "14px",
                textAlign: "center",
                textWrap: "nowrap",
              }}
            >
              Home loan
            </Typography>
          </div>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography sx={{ color: "#4C4C4C", fontSize: "12px" }}>
            Type
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#414042",
            }}
          >
            {HlServiceRequestDetails.caseType}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography sx={{ color: "#4C4C4C", fontSize: "12px" }}>
            Subtype
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#414042",
            }}
          >
            {HlServiceRequestDetails.caseSubType}
          </Typography>
        </Stack>
        <Divider sx={{ marginY: ".625rem" }} />
        <Accordion className="hl-custom-accordion" disableGutters={true}>
          <AccordionSummary
            expandIcon={<KeyboardArrowDownIcon sx={{ color: Colors.red }} />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="accordion-summary"
          >
            <Typography variant="h5" fontWeight="400" color={Colors.quartz}>
              Service request status
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {HlServiceRequestDetails.createdOn && (
              <StatusBox
                date={`(${formatDateWithOrdinal(
                  HlServiceRequestDetails.createdOn
                )})`}
                title={"Service request submitted"}
                status={
                  HlServiceRequestDetails.stage === "Resolved"
                    ? "success"
                    : "pending"
                }
                expectedResolution={
                  HlServiceRequestDetails.expectedResDate || ""
                }
              />
            )}
            {HlServiceRequestDetails.stage === "Resolved" && (
              <StatusBox
                date={`(${formatDateWithOrdinal(
                  HlServiceRequestDetails.expectedResDate
                )})`}
                title={"Service request resolved"}
                onProgress={false}
                status={
                  HlServiceRequestDetails.stage === "Resolved"
                    ? "success"
                    : "pending"
                }
                expectedResolution={HlServiceRequestDetails.modifiedOn || ""}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Button
          fullWidth
          variant="contained"
          sx={{
            borderRadius: "1.25rem",
            padding: ".875rem .875rem",
            backgroundColor: "#FFE7E5",
            color: "#C7222A",
            boxShadow: "none",
            height: "2rem",
            "&:hover": {
              color: "#FFFFFF",
            },
            marginTop: ".75rem",
          }}
          endIcon={<HeadsetMic />}
          onClick={() => {
            setOpen(true);
          }}
        >
          Connect to executive
        </Button>
      </Box>
      <CallUsPopUp open={open} handleOpen={setOpen} />
    </Box>
  );
};

export default HlServiceRequestCard;
