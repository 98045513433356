import { FC, useCallback, useState } from "react";
import CustomFailure from "../CustomFailure/CustomFailure";

interface Props {
  handleClick: Function;
}

const PaymentFailed: FC<Props> = ({ handleClick }) => {
  const [isSuccess, setIsSuccess] = useState<boolean>(true);

  const tryAgain = useCallback(() => {
    handleClick("FAILURE");
  }, []);

  return (
    <CustomFailure
      title="Payment failed"
      btnLabel="Try Again"
      handleClick={tryAgain}
      open={isSuccess}
    />
  );
};

export default PaymentFailed;
