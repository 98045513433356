import React, { FC } from "react";
import LoadingDots from "../../assets/gif/Loader_dots.json";
import CommonGifAnimation from "../CustomGifAnimation/CommonGifAnimation";
import { Box, Typography } from "@mui/material";
import "./CustomThreeDotsLoader.scss";

interface Props {
  withText?: boolean;
  textContent?: any;
}

const CustomThreeDotsLoader: FC<Props> = ({
  withText = false,
  textContent = null,
}) => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        overflow={"hidden"}
      >
        <CommonGifAnimation animationData={LoadingDots} heightCustom="3rem" />
        {withText ? (
          <Box textAlign="center" marginTop="0.5rem">
            {textContent ? (
              textContent
            ) : (
              <>
                <Typography>Just a few moments...</Typography>
                <Typography>Ensuring everything is upto date</Typography>{" "}
              </>
            )}
          </Box>
        ) : null}
      </Box>
      <div className="loader-overlay"></div>
    </>
  );
};
export default CustomThreeDotsLoader;
