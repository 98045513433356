import { createTheme } from "@mui/material";
import anekLatinRegular from "../assets/fonts/aneklatinregular.ttf";
import anekLatinMedium from "../assets/fonts/aneklatinmedium.ttf";
import anekLatinSemiBold from "../assets/fonts/aneklatinsemibold.ttf";
import anekLatinBold from "../assets/fonts/aneklatinbold.ttf";
import poppinsRegular from "../assets/fonts/poppinsregular.ttf";
import poppinsMedium from "../assets/fonts/poppinsmedium.ttf";
import poppinsSemiBold from "../assets/fonts/poppinssemibold.ttf";
import poppinsBold from "../assets/fonts/poppinsbold.ttf";

export const Colors = {
  red: "#C7222A",
  lightYellow: "#FFF4CE",
  yellow: "#D99202",
  iconYellow: "#F7A500",
  darkYellow: "#845800",
  bgGray: "#F1F3F6",
  green: "#1F874C",
  textGray: "#767D93",
  textblack: "#252525",
  descriptionGray: "#797979",
  arsenic: "#414042",
  darkCharcoal: "#2E2E2E",
  quartz: "#4C4C4C",
  cadmiumGreen: "#0A6A34",
  white: "#FFFFFF",
  lightgreen: "#0A6A34",
  black: "#2E2E2E",
  darkGray: "#979797",
  radioGrey: "#979cae",
  darkBlack: "#000000",
  textBlackV1: "#202428",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#CA1F34",
    },
    secondary: {
      main: "#70B865",
    },
  },
  typography: {
    fontFamily: "Anek Latin",
    h2: {
      fontWeight: 600,
      fontSize: "1.25rem", // 20px
      lineHeight: "1.5rem", // 24px
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.125rem", // 18px
      lineHeight: "1.35rem", // 21.6px
    },
    h4: {
      fontWeight: 600,
      fontSize: "1rem", // 16px
      lineHeight: "1.2rem", // 19.2px
    },
    h5: {
      fontWeight: 600,
      fontSize: ".875rem", // 14px
      lineHeight: "1.05rem", // 16.8px
    },
    h6: {
      fontWeight: 600,
      fontSize: ".75rem", // 12px
      lineHeight: ".9rem", // 14.4px
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "1.125rem", // 18px
      lineHeight: "1.35rem", // 21.6px
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: ".875rem", // 14px
      lineHeight: "1.05rem", // 16.8px
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem", // 16px
      lineHeight: "1.2rem", // 19.2px
    },
    body2: {
      fontWeight: 700,
      fontSize: "18px", // 18px
      lineHeight: "21.6px", // 21.6px
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face [{
          font-family: Anek Latin;
          font-weight: 400;
          src: url(${anekLatinRegular}) format('truetype');
        },
        {
          font-family: Anek Latin;
          font-weight: 500;
          src: url(${anekLatinMedium}) format('truetype');
        },
        {
          font-family: Anek Latin;
          font-weight: 600;
          src: url(${anekLatinSemiBold}) format('truetype');
        },
        {
          font-family: Anek Latin;
          font-weight: 700;
          src: url(${anekLatinBold}) format('truetype');
        },
          {
          font-family: Poppins;
          font-weight: 400;
          src: url(${poppinsRegular}) format('truetype');
        },
          {
          font-family: Poppins;
          font-weight: 500;
          src: url(${poppinsMedium}) format('truetype');
        },
        {
          font-family: Poppins;
          font-weight: 600;
          src: url(${poppinsSemiBold}) format('truetype');
        },
        {
          font-family: Poppins;
          font-weight: 700;
          src: url(${poppinsBold}) format('truetype');
        },
      ]
      `,
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: "none",
          borderRadius: "1.875rem !important",
          backgroundColor: Colors.bgGray,
          "&.Mui-error .MuiInputBase-input": {
            color: "#D34528",
          },
          "&.Mui-disabled": {
            border: "none",
          },
        },
        input: {
          color: "#2e2e2e",
          fontSize: ".875rem",
          fontWeight: 600,
          marginLeft: ".625rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: `${Colors.textGray} !important`,
          marginLeft: ".625rem",
          "&.Mui-error": {
            color: "#D34528 !important",
            marginLeft: ".125rem",
          },
        },
        shrink: {
          marginLeft: ".75rem",
          top: ".0625rem",
          fontWeight: 600,
          ":focus": {
            color: `${Colors.textGray} !important`,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: Colors.red,
          fontWeight: 600,
          "&.Mui-error": {
            color: "#D34528",
            fontSize: ".75rem",
            fontWeight: "600",
            lineHeight: "1.125rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        // notchedOutline: {
        //   border: "none",
        // },
        root: {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.bgGray,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.bgGray,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: Colors.bgGray,
          },
          "&.Mui-error .MuiOutlinedInput-notchedOutline": {
            borderColor: "#D34528",
            borderWidth: ".0625rem",
          },
          "&.Mui-error": {
            backgroundColor: "#FFE7E5",
            borderRadius: "1.25rem",
            borderColor: "#D34528",
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginRight: ".625rem",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "black",
          marginRight: ".625rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "3.75rem",
          padding: "1rem 0",
          fontSize: "1rem",
          textTransform: "none",
          lineHeight: "1.2rem",
          fontWeight: 600,
          "&.Mui-disabled": {
            backgroundColor: "#AFB3C0",
            color: "white",
            "&:hover": {
              backgroundColor: "none",
            },
          },
        },
      },
    },
  },
});
