import { T_ProfileHamburgerMenuResponse } from './ProfileHamburgerMenu.Types';
import { CSApiService } from '../../redux-store/CSApiService';
import { APi_CMS_ErrorDetails, APi_CMS_GetHamburgerMenu, APi_CMS_IntegrationVariables, APi_CMS_PageMessages, APi_CMS_PageVariables } from '../../utils/ApiEndpoints';
import { T_CmsIntegrationVariablesApiResponse } from './CmsIntegrationVariable.Types';
import { T_CmsPageVariablesResponse } from './CmsPageVariable.Types';
import { T_CmsErrorResponse } from './CmsErrorVariables.Types';
import { T_CmsPageMessageApiResponse } from './CmsPageMessages.Types';



export const cmsApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchHamburgerMenu: builder.query<T_ProfileHamburgerMenuResponse, void>({
      query: () => ({
        url: APi_CMS_GetHamburgerMenu,
        method: 'POST',
      }),
      providesTags: ['PROFILE_HAMBURGER_MENU_DATA'], 
    }),
    FetchIntegrationVariable: builder.query<T_CmsIntegrationVariablesApiResponse, void>({
      query: () => ({
        url: APi_CMS_IntegrationVariables,
        method: 'POST',
      }),
      //providesTags: ['CMS_Integration_VARIABLES_DATA'], 
    }),

    FetchPageVariable: builder.query<T_CmsPageVariablesResponse, void>({
      query: () => ({
        url: APi_CMS_PageVariables ,
        method: 'POST',
      }),
      //providesTags: ['CMS_PAGE_VARIABLES_DATA'], 
    }),
    FetchErrorVariable: builder.query<T_CmsErrorResponse, void>({
      query: () => ({
        url: APi_CMS_ErrorDetails ,
        method: 'POST',
      }),
      //providesTags: ['CMS_PAGE_VARIABLES_DATA'], 
    }),
    FetchPageMessage: builder.query<T_CmsPageMessageApiResponse, void>({
      query: () => ({
        url: APi_CMS_PageMessages ,
        method: 'POST',
      }),
      //providesTags: ['CMS_PAGE_VARIABLES_DATA'], 
    }),

  }),
});

export const {
  useLazyFetchHamburgerMenuQuery,
  useLazyFetchIntegrationVariableQuery,
  useLazyFetchPageVariableQuery,
  useLazyFetchErrorVariableQuery,
  useLazyFetchPageMessageQuery
} = cmsApi;




