import { FC, useState } from "react";
import "./CustomHelpComponent.scss";
import HelpIcon from "../../assets/icons/web-help-icon.svg";
import CallUs from "../../features/Feature_GeneralAppElements/CallUs/CallUs";

interface Props {
  functionalityCode?: string;
  LobId?: string
}

const CustomHelpComponent: FC<Props> = ({
  functionalityCode="",
  LobId = ""
}) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <img
        src={HelpIcon}
        onClick={() => setOpen(true)}
        alt="help-icon"
        className="help-icon"
      />
      {open && 
      <CallUs 
       open={open}
       handleOpen={setOpen}
       functionalityCode={functionalityCode}
       LobId={LobId}
       showChatWithUsBtn={true} />}
    </>
  );
};

export default CustomHelpComponent;
