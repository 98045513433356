import React, { FC, useEffect, useState } from "react";
import {
  FAQsMenuList,
  autoDebitMenuList,
  businessLoanMenuList,
} from "../../../utils/constants";
import CommonMenuListPage from "../../../components/CustomMenuListPage/CommonMenuListPage";
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Typography,
  Collapse,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  useLazyFetchFaqCategoriesQuery,
  useLazyFetchFaqListQuery,
  useLazyFetchFaqParentModulesQuery,
} from "../GeneralAppElements_Api";
import {
  T_FaqCategoryItems,
  T_FaqList_Request,
  T_FaqListItems,
  T_FaqParentModuleItem,
} from "../GeneralAppElements_Types";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { useAppDispatch } from "../../../redux-store/CustomReduxHooks";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
interface MarkedOptions {
  gfm?: boolean;
  breaks?: boolean;
  headerIds?: boolean;
  mangle?: boolean;
}
const markedOptions: MarkedOptions = {
  gfm: true, // Use GitHub Flavored Markdown
  breaks: true, // Enable line breaks
  headerIds: true, // Disable automatic IDs for headers
  mangle: false, // Disable name mangling for links and images
};

interface Props {
  handleBackBtnClick: Function;
  pathname?: string;
}

const FAQs: FC<Props> = ({ handleBackBtnClick, pathname }) => {
  const [state, setState] = useState<{
    parentModule: string;
    type: string;
    module: string;
    question: string;
    parentModuleName: string;
    moduleName: string;
    categoryName: string;
  }>({
    parentModule: "",
    type: "parent-module",
    module: "",
    question: "",
    parentModuleName: '',
    moduleName: '',
    categoryName: ''
  });
  const [fetchFaqParentModules] = useLazyFetchFaqParentModulesQuery();
  const [fetchFaqCategories] = useLazyFetchFaqCategoriesQuery();
  const [fetchFaqList] = useLazyFetchFaqListQuery();

  const [FAQsMenus, setFAQsMenus] = useState<T_FaqParentModuleItem[]>([]);
  const [FAQCategory, setFAQCategory] = useState<T_FaqCategoryItems[]>([]);
  const [answers, setAnswers] = useState<T_FaqListItems[]>([]);
  const [FAQsHeaderText, setFAQsHeaderText] = useState<string[]>([
    "BROWSE CATEGORIES",
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const appDispatch = useAppDispatch();
  useEffect(() => {
    fetchParentModule();
  }, []);

  useEffect(() => {
    let headers = state.parentModuleName === ''? [
      "BROWSE CATEGORIES"
    ] :[state.parentModuleName,state.moduleName,state.categoryName];
      
    setFAQsHeaderText(headers.filter(name => name !== "")); 
  }, [state.parentModuleName,state.categoryName,state.moduleName]);
  
  const updateTitle = (type:string,name:string)=>{
    if(type === 'parent-module'){
      setState((prevState) => ({
        ...prevState,
        parentModuleName : name
      }))
    }if(type=== 'module'){
      setState((prevState) => ({
        ...prevState,
        moduleName : name
      }))
    }if(type=== 'category'){
      setState((prevState) => ({
        ...prevState,
        categoryName : name,
      }))
    }
  }

  const fetchParentModule = async () => {
    setLoading(true);
    appDispatch(updateLoadingState(true));
    try {
      const response = await fetchFaqParentModules("");
      if (response.data?.code === 1) {
        setFAQsMenus(response.data.payload.data);
      }else{
        appDispatch(
          setError({
            error: response.data,
            message: "FAQ Api error",
          })
        );
      }
    } finally {
      setLoading(false);
      appDispatch(updateLoadingState(false));
    }
  };

  const fetchFaqCategory = async (module: string) => {
    setLoading(true);
    appDispatch(updateLoadingState(true));
    try {
      const response = await fetchFaqCategories(module);
      if (response.data?.code === 1) {
        setFAQCategory(response.data.payload.data);
      }else{
        appDispatch(
          setError({
            error: response.data,
            message: "FAQ Api error",
          })
        );
      }
    } finally {
      setLoading(false);
      appDispatch(updateLoadingState(false));
    }
  };

  const fetchAnswers = async (request: T_FaqList_Request) => {
    setLoading(true);
    appDispatch(updateLoadingState(true));
    try {
      const response = await fetchFaqList(request);
      if (response.data?.code === 1) {
        let markedContent;
        let sanitizedContent;
        let sanitized: any = [];
        response.data?.payload.data.map((data: T_FaqListItems) => {
          async function getMarkedVal(val: string, data: T_FaqListItems) {
            let sanityValue = {
              attributes: {
                question: "",
                answer: "",
              },
              id: 0,
            };
            markedContent = await marked(val, markedOptions);
            sanitizedContent = DOMPurify.sanitize(markedContent);
            sanityValue.attributes["question"] = data.attributes.question;
            sanityValue.attributes["answer"] = sanitizedContent;
            sanityValue.id = data.id;
            sanitized.push(sanityValue);
          }
          getMarkedVal(data.attributes.answer, data);
          return data;
        });

        setAnswers(sanitized);
      }else{
        appDispatch(
          setError({
            error:  response.data,
            message:"FAQ Api error",
          })
        );
      }

    } finally {
      setLoading(false);
      appDispatch(updateLoadingState(false));
    }
  };

  

  const handleFAQsClick = (key: string, type: string) => {
    setState((prevState) => ({
      ...prevState,
      parentModule:
        type === "parent-module" && key === prevState.parentModule
          ? ""
          : key,
      type: type,
      module: type === "module" ? key : prevState.module,
    }));

    if (type === "module") {
      fetchFaqCategory(key);
    } else if (type === "category") {
      fetchAnswers({
        categories: key,
        modules: state.module,
      });
    }

 

    

    const header = [state.parentModuleName,state.moduleName,state.categoryName];
    setFAQsHeaderText(header);
  };

  const showAnswer = (question: string) => {
    setState((prevState) => ({
      ...prevState,
      question:state.question === question ? '' : question,
    }));
  };

  const renderParentModuleView = () => (
    <CommonMenuListPage
      title="FAQs"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingX="1.25rem"
      >
        <Divider className="header-text-line" />
        <Typography
          color="#77787B"
          fontSize=".75rem"
          fontWeight={500}
          marginX=".625rem"
        >
          {FAQsHeaderText.join(" / ")}
        </Typography>
        <Divider className="header-text-line" />
      </Box>
      {FAQsMenus.map((item) => (
        <React.Fragment key={item.attributes.parentModuleName}>
          <ListItem
            onClick={() =>{
              handleFAQsClick(item.attributes.key, "parent-module");
              updateTitle("parent-module",item.attributes.parentModuleName);
            }
            }
            secondaryAction={
              <IconButton
                sx={{ marginRight: 0 }}
                edge="end"
                aria-label="expand"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            }
            sx={{ marginY: ".625rem", paddingLeft: "1.5rem" }}
          >
            <ListItemText
              primary={
                <Typography variant="h5" marginBottom=".3125rem">
                  {item.attributes.parentModuleName}
                </Typography>
              }
            />
          </ListItem>
          <Collapse in={item.attributes.key === state.parentModule}>
            {item?.attributes.modules?.data.map((listItem) => (
              <ListItem
                key={listItem.attributes.key}
                onClick={() =>{
                  handleFAQsClick(listItem.attributes.key, "module");
                  updateTitle( "module",listItem.attributes.moduleName);
                }}
                secondaryAction={
                  <IconButton
                    sx={{ marginRight: 0 }}
                    edge="end"
                    aria-label="expand"
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                }
                sx={{ marginY: ".625rem", paddingLeft: "1.5rem" }}
              >
                <ListItemText
                  primary={
                    <Typography variant="h6" marginBottom=".3125rem">
                      {listItem.attributes.moduleName}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </Collapse>
          <Divider />
        </React.Fragment>
      ))}
    </CommonMenuListPage>
  );

  const renderModuleView = () => (
    <CommonMenuListPage
      title="FAQs"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingX="1.25rem"
      >
        <Divider className="header-text-line" />
        <Typography
          color="#77787B"
          fontSize=".75rem"
          fontWeight={500}
          marginX=".625rem"
        >
          {FAQsHeaderText.join(" / ")}
        </Typography>
        <Divider className="header-text-line" />
      </Box>
      {FAQCategory.map((item) => (
        <React.Fragment key={item.attributes.categoryName}>
          <ListItem
            onClick={() => {
              handleFAQsClick(item.attributes.key, "category");
              updateTitle("category",item.attributes.categoryName);
            }}
            secondaryAction={
              <IconButton
                sx={{ marginRight: 0 }}
                edge="end"
                aria-label="expand"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            }
            sx={{ marginY: ".625rem", paddingLeft: "1.5rem" }}
          >
            <ListItemText
              primary={
                <Typography variant="h5" marginBottom=".3125rem">
                  {item.attributes.categoryName}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </CommonMenuListPage>
  );

  const renderCategoryView = () => (
    <CommonMenuListPage
      title="FAQs"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        paddingX="1.25rem"
      >
        <Divider className="header-text-line" />
        <Typography
          color="#77787B"
          fontSize=".75rem"
          fontWeight={500}
          marginX=".625rem"
        >
          {FAQsHeaderText.join(" / ")}
        </Typography>
        <Divider className="header-text-line" />
      </Box>
      {answers.map((item) => (
        <React.Fragment key={item.attributes.question}>
          <ListItem
            onClick={() => showAnswer(item.attributes.question)}
            secondaryAction={
              <IconButton
                sx={{ marginRight: 0 }}
                edge="end"
                aria-label="expand"
              >
                <KeyboardArrowRightIcon />
              </IconButton>
            }
            sx={{ marginY: ".625rem", paddingLeft: "1.5rem" }}
          >
            <ListItemText
              primary={
                <Typography variant="h5" marginBottom=".3125rem">
                  {item.attributes.question}
                </Typography>
              }
            />
          </ListItem>
          <Collapse in={item.attributes.question === state.question}>
            {item.attributes.answer && (
              <Box padding="0 1.5rem .9375rem" width="80%">
                <Box marginBottom=".625rem">
                  <Typography fontSize=".75rem" fontWeight={400}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.attributes.answer,
                      }}
                    />
                  </Typography>
                  {/* <Typography fontSize=".75rem" fontWeight={400}>
                    {item.attributes.answer}
                  </Typography> */}
                </Box>
              </Box>
            )}
          </Collapse>
          <Divider />
        </React.Fragment>
      ))}
    </CommonMenuListPage>
  );

  const loader = () => (
    <CommonMenuListPage
      title="FAQs"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
    </CommonMenuListPage>
  );

  if (loading) return loader();

  switch (state.type) {
    case "parent-module":
      return renderParentModuleView();
    case "module":
      return renderModuleView();
    case "category":
      return renderCategoryView();
    default:
      return loader();
  }
};

export default FAQs;
