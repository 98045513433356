import React from "react";
import { Stack, ListItemText, Typography, Divider } from "@mui/material";
import { LoanData, T_HLOverdue } from "../Homeloan_Types";
import { getRupeeFormat } from "./HlInformationCard";
import { Colors } from "../../../styles/constant";

interface LoanDetailsProps {
  data: LoanData;
  progressBar?: React.ReactNode | null;
  overdue?: T_HLOverdue;
  isDetailPage?: boolean;
}

const LoanDetails: React.FC<LoanDetailsProps> = ({
  data,
  progressBar,
  overdue,
  isDetailPage = false,
}) => {
  return (
    <>
      <Stack
        direction="row"
        display="flex"
        justifyContent="space-between"
        columnGap={isDetailPage ? 2 : undefined}
      >
        <ListItemText
          primary={
            <Typography
              variant={isDetailPage ? "subtitle2" : "h5"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              color={isDetailPage ? Colors.descriptionGray : undefined}
            >
              Sanctioned amount
            </Typography>
          }
          secondary={
            <Typography
              variant={isDetailPage ? "h5" : "subtitle2"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              marginTop={"0.2rem"}
              fontSize={isDetailPage ? "1.125rem" : undefined}
            >
              {getRupeeFormat(data.SANCTIONED_AMOUNT)}
            </Typography>
          }
        />
        {isDetailPage && (
          <Divider orientation="vertical" variant="middle" flexItem />
        )}
        <ListItemText
          primary={
            <Typography
              variant={isDetailPage ? "subtitle2" : "h5"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              textAlign="right"
              color={isDetailPage ? Colors.descriptionGray : undefined}
            >
              Disbursed amount
            </Typography>
          }
          secondary={
            <Typography
              variant={isDetailPage ? "h5" : "subtitle2"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              textAlign="right"
              marginTop={"0.2rem"}
              fontSize={isDetailPage ? "1.125rem" : undefined}
            >
              {getRupeeFormat(data.TOTAL_AMOUNT_DISBURSED)}
            </Typography>
          }
        />
      </Stack>

      {overdue && (
        <Stack direction="row" display="flex" justifyContent="space-between">
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                marginRight="0.3125rem"
                minWidth={"4.6875rem"}
                textAlign="left"
                color={isDetailPage ? Colors.descriptionGray : undefined}
              >
                Principal outstanding
              </Typography>
            }
            secondary={
              <Typography
                variant="h5"
                marginRight="0.3125rem"
                minWidth={"4.6875rem"}
                marginTop={"0.2rem"}
                textAlign="left"
              >
                {getRupeeFormat(overdue.principalOutstanding)}
              </Typography>
            }
          />

          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                marginRight="0.3125rem"
                minWidth={"4.6875rem"}
                textAlign="right"
                color={isDetailPage ? Colors.descriptionGray : undefined}
              >
                Due amount
              </Typography>
            }
            secondary={
              <Typography
                variant="h5"
                marginRight="0.3125rem"
                minWidth={"4.6875rem"}
                marginTop={"0.2rem"}
                textAlign="right"
                color={"#D34528"}
              >
                {getRupeeFormat(overdue.dueAmount)}
              </Typography>
            }
          />
        </Stack>
      )}

      <Stack direction="row" display="flex" justifyContent="space-between">
        <ListItemText
          sx={{ marginRight: "10px" }}
          primary={
            <Typography
              variant={isDetailPage ? "subtitle2" : "h5"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              color={isDetailPage ? Colors.descriptionGray : undefined}
            >
              {data.DATE_TITLE}
            </Typography>
          }
          secondary={
            <Typography
              variant={isDetailPage ? "h5" : "subtitle2"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              marginTop={"0.2rem"}
              textAlign="left"
            >
              {data.EMI_END_DATE}
            </Typography>
          }
        />
        <ListItemText
          primary={
            <Typography
              variant={isDetailPage ? "subtitle2" : "h5"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              textAlign="center"
              color={isDetailPage ? Colors.descriptionGray : undefined}
            >
              Interest rate
            </Typography>
          }
          secondary={
            <Typography
              variant={isDetailPage ? "h5" : "subtitle2"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              marginTop={"0.2rem"}
              textAlign="center"
            >
              {`${data.INTEREST_RATE} p.a.`}
            </Typography>
          }
        />
        <ListItemText
          primary={
            <Typography
              variant={isDetailPage ? "subtitle2" : "h5"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              textAlign="right"
              color={isDetailPage ? Colors.descriptionGray : undefined}
            >
              EMI
            </Typography>
          }
          secondary={
            <Typography
              variant={isDetailPage ? "h5" : "subtitle2"}
              marginRight="0.3125rem"
              minWidth={"4.6875rem"}
              textAlign="right"
              marginTop={"0.2rem"}
            >
              {getRupeeFormat(data.EMI_AMOUNT)}
            </Typography>
          }
        />
      </Stack>

      <Stack gap={1}>{progressBar}</Stack>
    </>
  );
};

export default LoanDetails;
