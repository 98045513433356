import { RouteObject } from "react-router-dom";
import Dashboard from "./Dashboard";
import NoHoldingsFound from "../NoHoldingsFoundPage/NoHoldingsFound";
export enum R_HomeRoutes {
  home = "/home",
  noHoldings = "/no-holdings",
}
const HomeRoutes: RouteObject[] = [
  {
    path: R_HomeRoutes.home,
    element: <Dashboard />,
  },
  {
    path: R_HomeRoutes.noHoldings,
    element: <NoHoldingsFound />,
  },
];

export default HomeRoutes;
