import React, { useState } from "react";
import "./HomeloanChangeEnach.scss";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { HomeLoanPoweredBy } from "../HomeloanDetails/HomeloanDetails";
import { Colors } from "../../../styles/constant";
import SelectOccupation from "./SelectOccupation/SelectOccupation";
import { useLocation, useNavigate } from "react-router-dom";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import mapsearch from "../../../assets/icons/map-search.svg";
import ArrowRightIcon from "../../../assets/icons/red-arrow-right.svg";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import { R_HLRoutes } from "../Homeloan_Routes";

type HomeloanProfile = Record<string, string | undefined>;

const HomeloanChangeEnach: React.FC = () => {
  const [selfEmployedPopupOpen, setSelfEmployedPopupOpen] =
    useState<boolean>(false);
  const { state } = useLocation();
  const accountNumber = state?.accountNumber || "";
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();
  const [occupationType, setOccupationType] = useState({
    salaried: false,
    selfEmployed: false,
  });

  const handleOccupationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOccupationType({
      salaried: e.target.name === "salaried",
      selfEmployed: e.target.name === "selfEmployed",
    });
  };

  const handleSubmit = () => {
    if (occupationType.selfEmployed) {
      setSelfEmployedPopupOpen(true);
    } else {
      setIsSubmitted(true);
      navigate(R_HLRoutes.document_upload, { state: { accountNumber } });
    }
  };

  const isButtonDisabled =
    !occupationType.salaried && !occupationType.selfEmployed;

  return (
    <Box className="hl-e-nach-body">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 10,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <CustomBreadcrumbs
            data={[
              "Home loan",
              accountNumber || "...",
              "Register/Change E-nach",
            ]}
          />
          <CustomHelpComponent />
        </Box>
        <Box className="content-wrapper">
          <SelectOccupation
            occupationType={occupationType}
            handleOccupationChange={handleOccupationChange}
          />
        </Box>
      </Container>

      <Box margin="auto 1rem 0">
        <Button
          fullWidth
          variant="contained"
          className="continue-btn"
          disabled={isButtonDisabled}
          onClick={handleSubmit}
        >
          Submit request
        </Button>
        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          rowGap={2}
          marginTop={"1rem"}
        >
          <Box marginTop={"-1rem"}>
            <HomeLoanPoweredBy />
          </Box>
        </Stack>
      </Box>
      {selfEmployedPopupOpen && (
        <CustomPopup
          open={selfEmployedPopupOpen}
          handleOpen={() => setSelfEmployedPopupOpen(false)}
          className="download-statement-popup"
          btnEndIcon={
            <img
              src={ArrowRightIcon}
              alt="right-arrow"
              style={{ color: Colors.red }}
              loading="lazy"
            />
          }
        >
          <CommonSuccessPopupBody
            des={
              "Please visit the nearest ABHFL branch to register/change e-nach"
            }
            withInfoText={`The password to the document is PAN of loan's main applicant`}
            btnLabel="Go back to homepage"
            btnOnClick={() => navigate("/home")}
            btnDisabled={false}
            extraInfo={
              <Stack
                columnGap={0.5}
                direction="row"
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingRight={"1rem"}
                paddingLeft={"1rem"}
                marginBottom={"2rem"}
                onClick={() => {
                  window.location.href =
                    "https://homefinance.adityabirlacapital.com/pages/branches.aspx";
                }}
              >
                <Typography
                  variant="subtitle2"
                  color={Colors.red}
                  sx={{
                    fontFamily: "Anek Latin",
                    fontSize: ".875rem",
                    fontWeight: 600,
                    lineHeight: "1.05rem",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  Branch locator
                </Typography>
                <img
                  src={mapsearch}
                  alt="copy"
                  style={{
                    color: Colors.bgGray,
                    height: "1.05rem",
                    marginLeft: ".25rem",
                    paddingTop: "0.25rem",
                    cursor: "pointer",
                  }}
                  loading="lazy"
                />
              </Stack>
            }
          />
        </CustomPopup>
      )}
    </Box>
  );
};

export default HomeloanChangeEnach;
