import { Box, Button, Typography } from "@mui/material";
import { Colors } from "../../../styles/constant";

import { useNavigate } from "react-router-dom";

import emptyPolicy_FolderIcon from "../../../assets/icons/empty_holding_foldericon.svg";

const TryAgain = () => {
  const navigate = useNavigate();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <img width={"100px"} height={"100px"} src={emptyPolicy_FolderIcon} />
      <Typography
        variant="subtitle2"
        lineHeight={"18.2px"}
        textAlign={"center"}
      >
        Unable to load your data
      </Typography>
      <Button onClick={() => navigate("/home")}>
        <Typography variant="h6" color={Colors.red}>
          Try Again
        </Typography>
      </Button>
    </Box>
  );
};

export default TryAgain;
