import { FC, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "./CustomMenuListPage.scss";
import { defaultEmptyFun } from "../../utils/helper";

interface CommonHeaderProps {
  title: string;
  children?: React.ReactNode;
  handleBackBtnClick?: Function;
  pathname?: string;
}

const CommonMenuListPage: FC<CommonHeaderProps> = ({
  title = "",
  children,
  pathname,
  handleBackBtnClick = defaultEmptyFun,
}) => {
  const handleBackBtnClickWrapper = useCallback(() => {
    handleBackBtnClick(pathname);
  }, [handleBackBtnClick]);

  return (
    <Box className="hamburger-menu-page">
      <Box className="header-section">
        <KeyboardArrowLeftIcon
          onClick={handleBackBtnClickWrapper}
          className="back-arrow-icon"
        />
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box className="content-wrapper">{children}</Box>
    </Box>
  );
};

export default CommonMenuListPage;
