import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import radioboxBlack from "../../assets/icons/radiobox-blank.svg";
import radioboxMarked from "../../assets/icons/radiobox-marked.svg";
interface CustomCheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  label: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  name,
  label,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<img src={radioboxBlack} alt="unchecked" />}
          checkedIcon={<img src={radioboxMarked} alt="checked" />}
          checked={checked}
          onChange={onChange}
          name={name}
          className="custom-checkbox"
        />
      }
      label={label}
    />
  );
};

export default CustomCheckbox;
