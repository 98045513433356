import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import { Colors } from "../../../../styles/constant";
import "./SelectOccupation.scss";

interface SelectOccupationProps {
  occupationType: { salaried: boolean; selfEmployed: boolean };
  handleOccupationChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectOccupation: React.FC<SelectOccupationProps> = ({
  occupationType,
  handleOccupationChange,
}) => {
  return (
    <>
      <Typography variant="h3">Register/Change E-Nach</Typography>
      <Stack rowGap={"-0.5rem"} marginTop={"0.8rem"}>
        <Stack direction="row" columnGap={1}>
          <Typography
            variant="subtitle2"
            color={Colors.textGray}
            fontSize={"0.875rem"}
          >
            Select occupation type
          </Typography>
        </Stack>
        <Box display="flex" flexDirection="column" marginTop="1.5rem">
          <Box
            className={`checkbox-wrapper ${
              occupationType.salaried ? "selected" : ""
            }`}
          >
            <CustomCheckbox
              checked={occupationType.salaried}
              onChange={handleOccupationChange}
              name="salaried"
              label="Salaried"
            />
          </Box>
          <Box
            className={`checkbox-wrapper ${
              occupationType.selfEmployed ? "selected" : ""
            }`}
          >
            <CustomCheckbox
              checked={occupationType.selfEmployed}
              onChange={handleOccupationChange}
              name="selfEmployed"
              label="Self Employed"
            />
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default SelectOccupation;
