import { Box, Stack, Typography } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Colors } from "../../../styles/constant";

interface Props {
  title: string;
  value: string;
  icon?: any;
}
const DisplayText: FC<Props> = ({ title, value, icon }) => {
  return (
    <Stack
      direction="row"
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      columnGap={3}
    >
      <Box display="flex" alignItems="center">
        {icon && (
          <img
            src={icon}
            alt="icon-text"
            style={{ width: "1rem", height: "1rem" }}
          />
        )}
        <Typography
          marginLeft=".5rem"
          variant="subtitle2"
          textAlign="left"
          fontWeight={title === "Total payable" ? 600 : 400}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        variant="h5"
        color={Colors.black}
        textAlign="right"
        className="content-text"
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default DisplayText;
