import { createSlice } from "@reduxjs/toolkit";
import {
  T_CsErrorSliceState,
  T_ErrorEventHandlerState,
} from "../../globalTypes/GlobalTypes";

const initialState: T_CsErrorSliceState = {
  error: undefined,
  message: undefined,
};

export const CsErrorSlice = createSlice({
  name: "CsErrorSlice",
  initialState: initialState,
  reducers: {
    setError: (state, action: { payload: T_CsErrorSliceState }) => {
      return {
        ...action.payload,
      };
    },
    clearError: () => {
      return initialState;
    },
  },
});

export const CsErrorSliceReducer = CsErrorSlice.reducer;
export const CsErrorSliceKey = CsErrorSlice.reducerPath;
export const { setError, clearError } = CsErrorSlice.actions;
export class ErrorEventHandler {
  static eventState: T_ErrorEventHandlerState = {
    onClick: undefined,
  };
  static setOnClickHandler = (customEvent: Function) => {
    this.eventState.onClick = customEvent;
  };
  static resetHandler = () => {
    this.eventState.onClick = undefined;
  };
}
