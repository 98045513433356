import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { Colors } from "../../../styles/constant";
import "./PremiumReceipt.scss";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import {
  agentCodeValue,
  dbDetailValue,
  documentTypeForPremiumReceipt,
  radioListData,
} from "../../../utils/constants";
import DownloadStatementPopups from "../DownloadStatementPopups/DownloadStatementPopups";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import {
  T_FetchPremiumReceiptWithDateResponse_Details,
  T_PolicyAllDetails,
} from "../LifeInsurance_Types";
import {
  downloadPdfFromBase64,
  encryptString,
  extractDownloadablePremiumReceipt,
  extractPremiumReceiptValues,
  extractReceiptAmountByDate,
  extractReceiptDateAmount,
  filterMonthsByYear,
  getLastTwoFinancialYears,
} from "../../../utils/helper";
import {
  useLazyDownloadPremiumReceiptQuery,
  useFetchPremiumReceiptWithDateQuery,
  useLazySendEmailQuery,
  useLazySendWhatsappQuery,
} from "../LifeInsurance_Api";
import { useLocation, useParams } from "react-router-dom";
import { isNull } from "lodash";
import { useDispatch } from "react-redux";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import {
  T_ApiResponse,
  T_LiRouteState,
} from "../../../globalTypes/GlobalTypes";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";

interface RadioListData {
  label: string;
  preIcon: any;
  key: string;
}

interface Option {
  label: string;
  value: string;
}

const PremiumReceipt: FC = () => {
  const dispatch = useDispatch();
  const [isApiSuccess, setIsApiSuccess] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [receiptDate, setReceiptDate] = useState<string>("");
  const [receiptAmount, setReceiptAmount] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedRadio, setSelectedRadio] = useState<string>("");
  const [yearOptions, setYearOptions] = useState<Option[]>([]);
  const [monthOptions, setMonthOptions] = useState<Option[]>([]);
  const [receiptDateOptions, setReceiptDateOptions] = useState<Option[]>([]);
  const [receiptAmountOptions, setReceiptAmountOptions] = useState<Option[]>(
    []
  );
  const [dates] = useState<{
    startDate: string;
    endDate: string;
  }>(getLastTwoFinancialYears());
  const [finalRadioListData, setFinalRadioListData] = useState<RadioListData[]>(
    []
  );
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState).policyNumber || "";

  const [extractedPremiumReceipt, setExtractedPremiumReceipt] =
    useState<T_FetchPremiumReceiptWithDateResponse_Details>({
      PROCESSDATE: "",
      DBDETAIL: "",
      RCPTAMOUNT: 0,
      ORNUM: "",
      POLICYNO: "",
      OWNERNAME: "",
      RECNUM: "",
    });

  const [downloadPremiumReceiptApi] = useLazyDownloadPremiumReceiptQuery();
  const [sendEmailApi] = useLazySendEmailQuery();
  const [sendWhatsAppApi] = useLazySendWhatsappQuery();

  const configuration = useAppSelector(
    (st) => st.CsAppSlice.tokenDetails?.configuration
  );
  const IV = configuration?.iv || "";
  const secretKey = configuration?.key || "";

  const encryptedPremiumWithDateApiRequest = encryptString(
    JSON.stringify({
      policyId: policyNumber, //as of now added policyId as static but after we can make it as dynamic if test data added.
      fromDate: dates.startDate, //as of now added fromDate as static but after we can make it as dynamic if test data added.
      toDate: dates.endDate, //as of now added toDate as static but after we can make it as dynamic if test data added.
    }),
    IV,
    secretKey
  );
  const {
    data: premiumWithDateApiResponse,
    isError,
    error: premiumWithDateApiError,
  } = useFetchPremiumReceiptWithDateQuery({
    request: encryptedPremiumWithDateApiRequest,
  });

  const policyAllDetails = useAppSelector(
    (st) => st.LifeInsuranceSlice.policyAllDetails
  );
  const selectedPolicyAllDetails = policyAllDetails.find(
    (item: T_PolicyAllDetails) => {
      return item.Policy_Level_Details.POLICY_NUMBER === policyNumber;
    }
  );

  const handlePopulatedDropdowns = (
    premiumReceiptsData: T_FetchPremiumReceiptWithDateResponse_Details[]
  ) => {
    const { yearOptionsList, monthOptionsList } =
      extractPremiumReceiptValues(premiumReceiptsData);
    setYearOptions(yearOptionsList);
    if (year) {
      const { monthNames } = filterMonthsByYear(premiumReceiptsData, year);
      setMonthOptions(monthNames);
    }
    if (year && month) {
      const { finalFilteredReceiptDate } = extractReceiptDateAmount(
        year,
        month,
        premiumReceiptsData
      );
      if (receiptDate) {
        const filteredReceiptAmount = extractReceiptAmountByDate(
          receiptDate,
          premiumReceiptsData
        );
        setReceiptAmountOptions(filteredReceiptAmount);
      }
      setReceiptDateOptions(finalFilteredReceiptDate);
    }
    if (isDataFilled) {
      const extractedPremiumReceiptValue = extractDownloadablePremiumReceipt(
        year,
        month,
        receiptDate,
        receiptAmount,
        premiumReceiptsData
      );
      setExtractedPremiumReceipt(extractedPremiumReceiptValue[0]);
    }
  };

  const downloadPremiumReceipt = () => {
    setIsBtnLoading(true);
    const encryptedDownloadPremiumReceiptApiRequest = encryptString(
      JSON.stringify({
        //as of now added req as static but after we can make it as dynamic if test data added.
        policyId: policyNumber, //as of now static,  it should pass dynamic in future
        orNum: extractedPremiumReceipt.ORNUM, //as of now static,  it should pass dynamic in future
        recNum: extractedPremiumReceipt.RECNUM, //as of now static,  it should pass dynamic in future
        dbDetail: dbDetailValue,
        agentCode: agentCodeValue,
        outputFormat: "PDF",
      }),
      IV,
      secretKey
    );
    downloadPremiumReceiptApi({
      request: encryptedDownloadPremiumReceiptApiRequest,
    })
      .then(async (res) => {
        const { data } = res;
        if (
          data?.code === 1 &&
          data?.payload &&
          !isNull(data?.payload.ResponsePDFBytes)
        ) {
          setIsBtnLoading(false);
          const pdfData = data.payload.ResponsePDFBytes;
          await downloadPdfFromBase64(
            pdfData,
            `PremiumReceipt-${policyNumber}`
          );
          setIsApiSuccess(true);
        } else {
          setIsBtnLoading(false);
        }
        if (res.isError && res.error) {
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "failed",
            })
          );
          return;
        }
        setOpen(true);
      })
      .catch((err) => {
        setIsBtnLoading(false);
        setOpen(true);
      });
  };

  const handleRadioBtnClick = (data: RadioListData) => {
    setSelectedRadio(data.key);
  };

  const sendEmail = async () => {
    setIsBtnLoading(true);
    const encryptedSendEmailApiRequest = encryptString(
      JSON.stringify({
        documentType: documentTypeForPremiumReceipt,
        policyNo: policyNumber, //as of now static,  it should pass dynamic in future
        customerName: `${selectedPolicyAllDetails?.Owner.FIRST_NAME || ""} ${
          selectedPolicyAllDetails?.Owner.MIDDLE_NAME || ""
        } ${selectedPolicyAllDetails?.Owner.LAST_NAME || ""}`,
        emailId: selectedPolicyAllDetails?.Owner.EMAIL_ID,
        orNum: extractedPremiumReceipt.ORNUM, //as of now static,  it should pass dynamic in future
        recnum: extractedPremiumReceipt.RECNUM, //as of now static,  it should pass dynamic in future
        agentCode: agentCodeValue,
        dbdetail: dbDetailValue,
      }),
      IV,
      secretKey
    );
    await sendEmailApi({ request: encryptedSendEmailApiRequest })
      .then((res) => {
        if (res.data?.code === 1 && !isNull(res.data?.payload)) {
          setIsBtnLoading(false);
          setIsApiSuccess(true);
        } else {
          setIsBtnLoading(false);
        }
        if (res.isError && res.error) {
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "failed",
            })
          );
          return;
        }
        setOpen(true);
      })
      .catch((err) => {
        setIsBtnLoading(false);
        setOpen(true);
      });
  };

  const sendWhatsApp = async () => {
    setIsBtnLoading(true);
    const encryptedSendWhatsAppApiRequest = encryptString(
      JSON.stringify({
        documentType: documentTypeForPremiumReceipt,
        policyNo: policyNumber, //as of now static,  it should pass dynamic in future
        customerName: `${selectedPolicyAllDetails?.Owner.FIRST_NAME || ""} ${
          selectedPolicyAllDetails?.Owner.MIDDLE_NAME || ""
        } ${selectedPolicyAllDetails?.Owner.LAST_NAME || ""}`,
        mobileNumber: selectedPolicyAllDetails?.Owner.MOBILE_CONTACT,
        orNum: extractedPremiumReceipt.ORNUM, //as of now static,  it should pass dynamic in future
        recnum: extractedPremiumReceipt.RECNUM, //as of now static,  it should pass dynamic in future
        agentCode: agentCodeValue,
        dbdetail: dbDetailValue,
        fromDate: extractedPremiumReceipt.PROCESSDATE.split(" ")[0] || "",
      }),
      IV,
      secretKey
    );
    await sendWhatsAppApi({ request: encryptedSendWhatsAppApiRequest })
      .then((res) => {
        if (
          res.data?.code === 1 &&
          res.data?.payload &&
          !isNull(res.data?.payload.response) &&
          res.data?.payload.response.status !== "error"
        ) {
          setIsBtnLoading(false);
          setIsApiSuccess(true);
        } else {
          setIsBtnLoading(false);
        }
        if (res.isError && res.error) {
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "failed",
            })
          );
          return;
        }
        setOpen(true);
      })
      .catch((err) => {
        setIsBtnLoading(false);
        setOpen(true);
      });
  };

  const handleContinueClick = () => {
    if (selectedRadio === "download") {
      downloadPremiumReceipt();
    } else if (selectedRadio === "email") {
      sendEmail();
    } else if (selectedRadio === "whatsapp") {
      sendWhatsApp();
    }
  };

  const isDataFilled = year && month && receiptDate && receiptAmount;

  useEffect(() => {
    // Default to showing all radio buttons
    let updatedRadioListData = [...radioListData];
    // Check if EMAIL_ID is falsy and remove "Get it on Email Id"
    if (!selectedPolicyAllDetails?.Owner.EMAIL_ID) {
      updatedRadioListData = updatedRadioListData.filter(
        (item) => item.label !== "Get it on Email Id"
      );
    }
    // Check if POLICY_OWNERS_WHATSAPP_INDICATOR is falsy or not "Y" and remove "Get it on Whatsapp"
    if (
      !selectedPolicyAllDetails?.Owner.POLICY_OWNERS_WHATSAPP_INDICATOR ||
      selectedPolicyAllDetails?.Owner.POLICY_OWNERS_WHATSAPP_INDICATOR !== "Y"
    ) {
      updatedRadioListData = updatedRadioListData.filter(
        (item) => item.label !== "Get it on Whatsapp"
      );
    }

    // Update the state with the filtered radio list data
    setFinalRadioListData(updatedRadioListData);
  }, [selectedPolicyAllDetails]);

  useEffect(() => {
    setIsBtnLoading(true);
    if (!premiumWithDateApiResponse?.payload) {
      setIsBtnLoading(false);
      setMonthOptions([]);
      setYearOptions([]);
      setReceiptAmountOptions([]);
      setReceiptDateOptions([]);
    } else {
      setIsBtnLoading(false);
      if (
        premiumWithDateApiResponse.code === 1 &&
        premiumWithDateApiResponse?.payload.ReceiptDetail
      ) {
        handlePopulatedDropdowns(
          premiumWithDateApiResponse?.payload.ReceiptDetail
        );
      } else {
        setIsApiSuccess(false);
        setOpen(true);
      }
    }
    if (isError) {
      setIsBtnLoading(false);
      dispatch(
        setError({
          error: premiumWithDateApiResponse,
          message: (premiumWithDateApiError as Error)?.message,
        })
      );
    }
  }, [
    isError,
    premiumWithDateApiResponse,
    year,
    month,
    receiptDate,
    isDataFilled,
  ]);

  const handleYearChange = (value: string) => {
    if (year !== value) {
      setYear(value);
      setMonth("");
      setReceiptDate("");
      setReceiptAmount("");
    }
  };
  const handleMonthChange = (value: string) => {
    if (month !== value) {
      setMonth(value);
      setReceiptDate("");
      setReceiptAmount("");
    }
  };
  const handleReceiptDateChange = (value: string) => {
    if (receiptDate !== value) {
      setReceiptDate(value);
      setReceiptAmount("");
    }
  };
  return (
    <Box className="premium-receipt">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 10,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <CustomBreadcrumbs
            data={["Life Insurance", `${policyNumber}`, "Premium Receipts"]}
          />
          <CustomHelpComponent />
        </Box>
        <Box className="content-wrapper">
          <Typography variant="h3">Premium Receipts</Typography>
          <Typography variant="subtitle2" color="#797979" marginTop="1rem">
            Please select the duration for which you want to receive Premium
            Receipts.
          </Typography>
          <Box display="flex" gap="1.875rem" marginTop="1rem">
            <Box flex={1}>
              <CustomSelect
                label="Year"
                value={year}
                onChange={handleYearChange}
                options={yearOptions}
                placeholder="Select Year"
              />
            </Box>
            <Box flex={1}>
              <CustomSelect
                label="Month"
                value={month}
                onChange={handleMonthChange}
                options={monthOptions}
                placeholder="Select Month"
              />
            </Box>
          </Box>
          {year && month && (
            <Box marginTop="1rem">
              <Typography variant="h5">
                Choose Receipt Date and Amount
              </Typography>
              <Box display="flex" gap="1.875rem" marginTop="1rem">
                <Box flex={1}>
                  <CustomSelect
                    label="Date"
                    value={receiptDate}
                    onChange={handleReceiptDateChange}
                    options={receiptDateOptions}
                    placeholder="Select Date"
                  />
                </Box>
                <Box flex={1}>
                  <CustomSelect
                    label="Amount"
                    value={receiptAmount}
                    onChange={setReceiptAmount}
                    options={receiptAmountOptions}
                    placeholder="Select Amount"
                  />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Container>
      {isDataFilled && (
        <Box margin="auto 1rem .625rem">
          <RadioGroup
            sx={{ gap: "1.25rem", marginTop: "3.125rem" }}
            className="radio-group"
          >
            {finalRadioListData.map((item: RadioListData) => (
              <Stack
                key={item.key}
                sx={{
                  backgroundColor: Colors.bgGray,
                  borderRadius: ".75rem",
                  padding: ".75rem 1rem",
                }}
                flexDirection="row"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" alignItems="center">
                  <img src={item.preIcon} />
                  <Typography marginLeft=".625rem" variant="h3">
                    {item.label}
                  </Typography>
                </Box>
                <Radio
                  checked={item.key === selectedRadio}
                  onClick={() => handleRadioBtnClick(item)}
                  sx={{
                    padding: 0,
                    color: Colors.red,
                    "&.Mui-checked": {
                      color: Colors.red,
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 20,
                    },
                  }}
                />
              </Stack>
            ))}
          </RadioGroup>
        </Box>
      )}
      <Box
        sx={{
          margin: isDataFilled ? ".5625rem 1rem 0" : "auto 1rem 0",
          paddingBottom: { md: "1.875rem" },
        }}
      >
        {isBtnLoading ? (
          <CustomThreeDotsLoader />
        ) : (
          <Button
            fullWidth
            variant="contained"
            disabled={!selectedRadio}
            className="continue-btn"
            onClick={() => handleContinueClick()}
          >
            Continue
          </Button>
        )}
      </Box>
      <DownloadStatementPopups
        popupFlow={selectedRadio}
        open={open}
        handleOpen={setOpen}
        isSuccess={isApiSuccess}
      />
    </Box>
  );
};

export default PremiumReceipt;
