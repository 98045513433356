import { RouteObject } from "react-router-dom";
import ValidateSsoNew from "./NewSSO/ValidateSsoOld";
import ValidateSsoOld from "./OldSSO/ValidateSsoOld";
export enum R_SsoRoutes {
  SsoNew_TokenNo_TokenRefNo = "/sso/new",
  SsoOld_TokenNo_TokenRefNo = "/sso/old",
}
const SsoRoutes: RouteObject[] = [
  {
    path: R_SsoRoutes.SsoNew_TokenNo_TokenRefNo,
    element: <ValidateSsoNew />,
  },
  {
    path: R_SsoRoutes.SsoOld_TokenNo_TokenRefNo,
    element: <ValidateSsoOld />,
  },
];
export default SsoRoutes;
