import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import "./HiDashboardCard.scss";
import { Colors } from "../../../styles/constant";
import {
  currencyConvertor,
  getHiMembersInsured,
  getHiPolicyNameIcon,
  getHiPolicyStatus,
} from "../../../utils/conversionUtils";
import CustomLink from "../../../components/CustomLink/CustomLink";
import {
  T_AppMobileNumberObj,
  T_PolicyInsured,
} from "../HealthInsurance_Types";
import CustomShimmer from "../../../components/CustomShimmer/CustomShimmer";
import dayjs from "dayjs";
import AlertGif from "../../../assets/gif/Exclamation_Mark.json";
import { T_AnimationData } from "../../../globalTypes/GlobalTypes";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import BasePlanGenericIcon from "../../../assets/icons/BasePlanGenericIcon.svg";

interface KeyPairValueProps {
  title: string;
  value?: string;
  isLoading?: boolean;
}

const KeyPairValue: FC<KeyPairValueProps> = ({ title, value, isLoading }) => {
  return (
    <>
      <Typography marginBottom=".3125rem" variant="h5">
        {title}
      </Typography>
      {isLoading ? (
        <CustomShimmer width="3rem"/>
      ) : (
        <Typography variant="subtitle2">{value ? value : "---"}</Typography>
      )}
    </>
  );
};
interface Props {
  data?: T_AppMobileNumberObj;
  viewDetailsClick?: Function;
  policyDetails?: T_PolicyInsured;
  isHiPolicyDetailsFetched?: boolean;
  isApiSuccessArray?: string[];
  tryAgainHandler: Function;
  isOtherHolding?: boolean;
}

const HiDashboardCard: FC<Props> = ({
  data,
  viewDetailsClick,
  policyDetails,
  isHiPolicyDetailsFetched,
  isApiSuccessArray = [],
  tryAgainHandler,
  isOtherHolding = false,
}) => {
  const [isApiRunning, setIsApiRunning] = useState<boolean>(false);
  let planName = `${policyDetails?.PolicyDetail.Product || ""} ${
    policyDetails?.PolicyDetail.Plan || ""
  }`;

  const handleTryAgainClick = async () => {
    setIsApiRunning(true);
    await tryAgainHandler(data?.PolicyNumber);
    setIsApiRunning(false);
  };

  if (
    !isApiRunning &&
    !isHiPolicyDetailsFetched &&
    !isApiSuccessArray.includes(data?.PolicyNumber || "")
  ) {
    return (
      <Box className="hi-dashboard-accordion-card failure">
        <CommonGifAnimation
          width="6.25rem"
          animationData={AlertGif as T_AnimationData}
        />
        <Typography variant="h5" fontWeight={400} marginY="0.5rem">
          Unable to load your data
        </Typography>
        <Typography
          variant="h5"
          color={Colors.red}
          onClick={handleTryAgainClick}
        >
          Try Again
        </Typography>
      </Box>
    );
  }

  let isLoading = isApiRunning || isHiPolicyDetailsFetched;

  return (
    <Box className="hi-dashboard-accordion-card">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {isLoading ? (
          <CustomShimmer width="3rem" />
        ) : (
          <Box display="flex" alignItems="flex-start">
            <img
              src={
                getHiPolicyNameIcon(
                  policyDetails?.PolicyDetail.Plan,
                  "dashboard"
                ) || BasePlanGenericIcon
              }
              style={{
                width: getHiPolicyNameIcon(policyDetails?.PolicyDetail.Plan)
                  ? ".875rem"
                  : ".625rem",
                marginTop: getHiPolicyNameIcon(policyDetails?.PolicyDetail.Plan)
                  ? ""
                  : ".1875rem",
              }}
            />

            <Box marginLeft="0.5rem">
              <Typography color={Colors.textGray} variant="h5">
                {planName}
              </Typography>
            </Box>
          </Box>
        )}
        {isLoading ? (
          <Box>
            <CustomShimmer width="2.5rem" />
          </Box>
        ) : (
          policyDetails?.PolicyDetail.PolicyStatus && (
            <Box
              display="flex"
              alignItems="center"
              marginRight="0.9375rem"
              gap=".25rem"
            >
              <span
                className="circle-icon"
                style={{
                  backgroundColor:
                    policyDetails?.PolicyDetail.PolicyStatus === "IF"
                      ? Colors.green
                      : Colors.textGray,
                }}
              ></span>
              <Typography
                color={
                  policyDetails?.PolicyDetail.PolicyStatus === "IF"
                    ? Colors.green
                    : Colors.textGray
                }
                variant="h6"
              >
                {getHiPolicyStatus(policyDetails?.PolicyDetail.PolicyStatus)}
              </Typography>
            </Box>
          )
        )}
      </Box>
      <Stack
        display="flex"
        marginTop=".625rem"
        justifyContent="space-between"
        rowGap={".625rem"}
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="h5"
            marginRight="0.3125rem"
            minWidth={"4.6875rem"}
          >
            Policy No.
          </Typography>
          <Typography variant="subtitle2">{data?.PolicyNumber}</Typography>
        </Box>
        <Box display="flex" alignItems="flex-start">
          <Typography
            variant="h5"
            marginRight="0.3125rem"
            minWidth={"4.6875rem"}
          >
            Plan Name
          </Typography>
          {isLoading ? (
            <CustomShimmer width="3rem" />
          ) : (
            <Typography variant="subtitle2">{planName}</Typography>
          )}
        </Box>
      </Stack>
      <Divider sx={{ marginY: ".625rem" }} />
      <Grid container display="flex" rowGap=".625rem" textAlign="start">
        <Grid item xs={6} sm={8}>
          <KeyPairValue
            title="Sum Assured"
            isLoading={isLoading}
            value={currencyConvertor(policyDetails?.PolicyDetail.SumInsured)}
          />
        </Grid>
        <Grid item xs={6} sm={4} textAlign={{ xs: "end", sm: "start" }}>
          <KeyPairValue
            title="Members Insured"
            isLoading={isLoading}
            value={getHiMembersInsured(policyDetails?.InsuredDetail || [])}
          />
        </Grid>
        <Grid item xs={6} sm={8}>
          <KeyPairValue
            title="Premium due date"
            isLoading={isLoading}
            value={
              policyDetails?.PolicyDetail.ValidTo
                ? dayjs(policyDetails?.PolicyDetail.ValidTo).format(
                    "DD/MM/YYYY"
                  )
                : ""
            }
          />
        </Grid>
        <Grid item xs={6} sm={4} textAlign={{ xs: "end", sm: "start" }}>
          <KeyPairValue
            title="Premium due amount"
            isLoading={isLoading}
            value={currencyConvertor(policyDetails?.PolicyDetail.AnnualPremium)}
          />
        </Grid>
      </Grid>
      {!isOtherHolding && (
        <Box display="flex" marginTop=".9375rem" justifyContent="flex-start">
          <CustomLink
            title="View details"
            titleSize={".75rem"}
            onClick={viewDetailsClick}
          />
        </Box>
      )}
    </Box>
  );
};

export default HiDashboardCard;
