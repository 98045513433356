import React, { FC, useCallback, useEffect, useState } from "react";
import "./CustomChatWithUs.scss";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import {DEVICE_TYPE} from "../../utils/constants";
import { Dialog, DialogContent, IconButton, Box } from "@mui/material";
import { UserPayload } from "../../globalTypes/GlobalTypes";
import { generateJWT } from "../../utils/helper";
import CloseIcon from "@mui/icons-material/Close";
import useDeviceDetection from "../../hooks/useDeviceDetection";


interface Props {
    showIframe: boolean;
    onClose: () => void;
    functionalityCode?:string;
    LobId:string
}

const CustomChatWithUs : FC<Props> = ({
    showIframe, 
    onClose,
    functionalityCode = "",
    LobId
  }) => {
  const customerAndLobDetails = useAppSelector(
        (state) => state.CsAppSlice.userDetails?.customerAndLobDetails
      );
  const deviceType = useDeviceDetection();
  const isMobile = deviceType === DEVICE_TYPE.MOBILE;
  const userName = customerAndLobDetails?.[0].NAME || "";
  const [iFrameURL, setIframeURL] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
    const userPayload: UserPayload = {
            uuid: customerAndLobDetails?.[0].CII || "",
            linkedlobs: "MF|HI",
            userName: userName,
            iat: Math.floor(Date.now() / 1000),
            exp: Math.floor(Date.now() / 1000) + (60 * 60),
            functionalitycode:functionalityCode,
            LOB :LobId
    }
    const jwt = await generateJWT(userPayload);
    setIframeURL(`https://c3.avaamo.com/web_channels/3d0d5b7d-38d5-45cf-8551-212af723d114/channel.html?user_info=${jwt}`)
    }
    fetchData();
  },[customerAndLobDetails, userName])
 

  return (
    <>
    <Dialog
    open={showIframe}
    className="custom-chatus-dialog"
    PaperProps={{
      style: {
        width: isMobile ? '100vw' : '22rem', // Full screen width for mobile, fixed width for web
        top: isMobile ? 0 : 'calc(100vh - 29rem)',
        height: isMobile ? '100vh' : '100vh', // Full viewport height for both
        borderRadius: 0,
        margin: 0,
        position: isMobile ? 'sticky' : 'absolute', // Fixed for mobile, absolute for web
        right: isMobile ? undefined : 0, // Align to the right only on web
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
    }}
  >
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          color: '#fff',
          zIndex: 1300,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{
          padding: 0,
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <iframe
          id="myIframe"
          src={iFrameURL}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
          title="Chat with Us"
        />
      </DialogContent>
    </Box>
  </Dialog></>
  );
};

export default CustomChatWithUs;
