import { BaseQueryApi, createApi, retry } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axios/axiosBaseQuery";
import {
  AxiosFetchQuery,
  T_ApiResponse,
  T_CustomerLobDetails,
  T_UserProfile,
} from "../globalTypes/GlobalTypes";
import { Mutex } from "async-mutex";
import {
  Api_GenerateInternalToken,
  Api_GenerateToken,
} from "../utils/ApiEndpoints";
import CsLocalStorageUtils from "../utils/CsLocalStorageUtils";
import { AxiosError } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  T_GenerateTokenRes,
  T_InternalTokenRes,
} from "../features/Feature_Login/Login_Types";
// import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { getLogoutInfo } from "../utils/helper";

let store: any = undefined;
export const injectStoreApiService = (_store: any) => {
  store = _store;
};

// initialize an empty api service that we'll inject endpoints into later as needed
const mutex = new Mutex();
const handleForbiddenScenario = async (
  result: any,
  args: AxiosFetchQuery,
  api: BaseQueryApi,
  extraOptions: any
) => {
  if (!mutex.isLocked()) {
    const release = await mutex.acquire();
    try {
      const isUserLoggedIn = store.getState()?.LoginSlice.isUserLoggedIn;
      const generateTokenArgs: AxiosFetchQuery = {
        url: isUserLoggedIn ? Api_GenerateInternalToken : Api_GenerateToken,
        headers: {
          csUserId: CsLocalStorageUtils.get("CS_USER_ID"),
        },
      };
      const refreshResult = await axiosBaseQuery()(
        generateTokenArgs,
        api,
        extraOptions
      );
      if (refreshResult.data) {
        if (isUserLoggedIn) {
          const actionObj: PayloadAction<string> = {
            type: "CsAppSlice/setLongSessionAuthToken",
            payload: (refreshResult.data as T_ApiResponse<T_InternalTokenRes>)
              .payload.authToken,
          };
          api.dispatch(actionObj);
        } else {
          const actionObj: PayloadAction<T_GenerateTokenRes> = {
            type: "CsAppSlice/setTokenDetails",
            payload: (refreshResult.data as T_ApiResponse<T_GenerateTokenRes>)
              .payload,
          };
          api.dispatch(actionObj);
        }
        // retry the initial query
        result = await axiosBaseQuery()(args, api, extraOptions);
      } else {
        const tokenDetails = store.getState()?.CsAppSlice.tokenDetails;
        const userDetails = store.getState()?.CsAppSlice.userDetails;
        const logoutExtraInfo = getLogoutInfo(
          tokenDetails as T_GenerateTokenRes,
          userDetails as T_UserProfile & T_CustomerLobDetails
        );
        api.dispatch({ type: "USER_LOGOUT", payload: logoutExtraInfo });
      }
      return result;
    } catch (e) {
      console.log(e);
      return result;
    } finally {
      // release must be called once the mutex should be released again.
      release();
    }
  }
};
export const CSApiService = createApi({
  reducerPath: "ApiService",
  keepUnusedDataFor: 1800,
  baseQuery: retry(
    async (args: AxiosFetchQuery, api, extraOptions) => {
      await mutex.waitForUnlock();
      let result = await axiosBaseQuery()(args, api, extraOptions);
      /**Incase of any 403:Forbidden, 401:unauthorized errror, retry for token once and continue
       * incase of error in getting token do logout
       */
      // if (result.error && (result.error as AxiosError)?.status === 403) {
      //   result = await handleForbiddenScenario(result, args, api, extraOptions);
      // } 
      
      if (result.error && ([401,409,406,403].includes((result.error as AxiosError)?.status || 200)) ) {
        api.dispatch({ type: "USER_LOGOUT" });
      }

      return result;
    },
    {
      maxRetries: 0,
    }
  ),
  tagTypes: [
    "TOKEN",
    "INTERNAL_TOKEN",
    "CUSTOMER_LOB_DETAILS",
    "USER_PROFILE",
    "CONFIG_DATA",
    "MASTER_DATA",
    "PROFILE_DATA",
    "LI_DASHBOARD",
    "LI_POLICY_ALL_DETAILS",
    "LI_POLICY_DETAILS",
    "LI_ANNUITY_DETAILS",
    "LI_FUND_DETAILS",
    "LI_POL_BANK_DETAILS",
    "LI_PAST_PAYMENT_DETAILS",
    "MF_EXIT_LOAD",
    "MF_VALIDATE_PAN",
    "MF_FOLIOS_SUMMARY",
    "MF_FOLIO_SCHEME_SUMMARY",
    "HI_POLICY_DETAILS_BY_MOBILE",
    "HI_POLICY_INSURED",
    "SEARCH_HISTORY",
    "SEARCH_RESULT",
    "SEARCH_POPULAR_SERVICES",
    "PROFILE_HAMBURGER_MENU_DATA",
  ],
  endpoints: () => ({}),
});

export const CSApiServiceStoreKey = CSApiService.reducerPath;
export const { reducer: CSApiServiceReducer } = CSApiService;
