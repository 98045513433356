import { FC } from "react";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import "./DownloadCSAppPopup.scss";
import SuperAppDownloadImage from "../../../assets/images/abcd-app-download-image.svg";
import { Colors } from "../../../styles/constant";
import downloadAppQrCode from "../../../assets/images/download-app-qr-code.png";
import appStoreImage from "../../../assets/images/app-store-1x.png";
import googlePlayStoreImage from "../../../assets/images/google-play-store-1x.png";

interface DownloadCSAppPopupProps {
  openPopup: boolean;
  handlePopup: Function;
}

const DownloadCSAppPopup: FC<DownloadCSAppPopupProps> = ({
  openPopup,
  handlePopup,
}) => {
  return (
    <CustomPopup
      open={openPopup}
      handleOpen={handlePopup}
      btnLabel="Close"
      btnStartIcon={<CloseIcon sx={{ fontSize: "1rem" }} />}
      className="download-cs-app-popup"
    >
      <Stack spacing={"2rem"} className="download-csApp-children-content">
        <img src={SuperAppDownloadImage} />
        <Typography variant="h5" textAlign={"center"}>
          To explore financial products download ABCD <br /> app and Pay,
          Invest, Insure & Borrow on the go.
        </Typography>
        <Button
          variant="contained"
          fullWidth
          sx={{
            bgcolor: Colors.red,
            borderRadius: "3.75rem",
            textTransform: "none",
          }}
        >
          <Typography variant="h6">Download Now</Typography>
        </Button>
        <Box display={"flex"}>
          <img src={downloadAppQrCode} style={{ marginRight: "2rem" }} />
          <Divider orientation="vertical" flexItem />
          <Stack
            rowGap={"1rem"}
            sx={{ marginLeft: "2rem" }}
            width={"7.9375rem"}
            height={"5.9375rem"}
          >
            <img src={googlePlayStoreImage} />
            <img src={appStoreImage} />
          </Stack>
        </Box>
      </Stack>
    </CustomPopup>
  );
};

export default DownloadCSAppPopup;
