import { FC, useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import { DEVICE_TYPE } from "../../../utils/constants";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { T_FetchConfigData_ResponseDetails } from "../GeneralAppElements_Types";
import useDeviceDetection from "../../../hooks/useDeviceDetection";
import "./EmailUsPopUp.scss";

interface Props {
  open: boolean;
  handleOpen: Function;
}

const EmailUsPopUp: FC<Props> = ({ open, handleOpen }) => {
  const [emailInfo, setEmailInfo] = useState<string>("");
  const deviceType = useDeviceDetection();
  const fetchConfigDataDetails = useAppSelector(
    (state) => state.GeneralAppElementsSlice?.masterData?.supportDataList || []
  );

  const handleClosePopup = useCallback(() => {
    handleOpen(false);
  }, [handleOpen]);

  useEffect(() => {
    const emailUsItem = fetchConfigDataDetails.find(
      (item) => item.optionType === "Email Us"
    );
    if (emailUsItem) {
      setEmailInfo(emailUsItem.contactInfo);
    }
  }, [fetchConfigDataDetails]);

  const renderSteps = () => {
    if (deviceType === DEVICE_TYPE.WEB) {
      return (
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
              marginBottom: "1.5rem",
            }}
          >
            {" "}
            EMAIL US{" "}
          </Typography>
          <Typography sx={{ marginBottom: ".3125rem" }}>
            For any queries, please email us at
          </Typography>
          <Typography sx={{ color: "#0000FF" }}>{emailInfo}</Typography>
        </Box>
      );
    } else {
      return (
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
              marginBottom: "1.5rem",
            }}
          >
            {" "}
            EMAIL US{" "}
          </Typography>
          <Typography sx={{ marginBottom: ".3125rem" }}>
            {" "}
            For any queries, please email us at
          </Typography>
          <Typography>
            <a href={`mailto:${emailInfo}?subject=Inquiry`}>{emailInfo}</a>
          </Typography>
        </Box>
      );
    }
  };
  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      btnLabel={"Close"}
      className="add-emailus-popup"
      btnStartIcon={<CloseIcon fontSize="small" />}
    >
      <Box
        className="add-emailus-popup-content"
        sx={{ margin: ".625rem", textAlign: "center", padding: ".625rem" }}
      >
        {renderSteps()}
      </Box>
    </CustomPopup>
  );
};
export default EmailUsPopUp;
