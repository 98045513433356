import { FC, useCallback, useState } from "react";
import InfoIcon from "../../../assets/icons/information-outline.svg";
import ArrowRightIcon from "../../../assets/icons/red-arrow-right.svg";
import CopyIcon from "../../../assets/icons/content-copy.svg";
import textBoxIcon from "../../../assets/icons/text-box.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "./HomeLoanStatement.scss";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import {
  Box,
  Button,
  Container,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import {
  hlIndexFinder,
  HomeLoanPoweredBy,
} from "../HomeloanDetails/HomeloanDetails";
import { useLocation } from "react-router-dom";
import {
  T_HlCreateCaseApiRequest,
  T_HlReportingServiceApiRequest,
  T_SummaryData,
} from "../Homeloan_Types";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux-store/CustomReduxHooks";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import { Colors } from "../../../styles/constant";
import CloseIcon from "@mui/icons-material/Close";
import {
  downloadPopOptions,
  StatementType,
  tabViewPoint,
} from "../../../utils/constants";
import { T_Option } from "../../../globalTypes/GlobalTypes";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { enUS } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import CustomErrorPopupBody from "../../../components/CustomErrorPopupBody/CustomErrorPopupBody";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetCreateCaseDetailsQuery,
  useLazyGetReportingServiceDetailsQuery,
} from "../Homeloan_Api";
import dayjs, { Dayjs } from "dayjs";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import useWindowSize from "../../../hooks/useWindowSize";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import customParseFormat from "dayjs/plugin/customParseFormat";
const getDateFromString = (date: string) => date?.split("/")[2].trim();

dayjs.extend(customParseFormat);

const generateFinancialYearsFromDate = (dateStr: string) => {
  const financialYears = [];
  // Extract the year from the provided date string
  const startYear = Number(getDateFromString(dateStr));
  const currentYear = new Date().getFullYear(); // Get the current year
  const endYear = currentYear; // End year is the current year
  // Generate financial years
  for (let year = startYear; year <= endYear; year++) {
    const nextYear = year + 1;
    const financialYear = `${year}`;
    financialYears.push({
      label: financialYear,
      value: financialYear,
    });
  }

  return financialYears;
};

const options: T_Option[] = StatementType;
const popOptions: any[] = downloadPopOptions;

const HomeLoanStatement: FC<any> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const [customStartDate, setCustomStartDate] = useState<string | null>(null);
  const [customEndDate, setCustomEndDate] = useState<string | null>(null);
  const [copiedText, showCopiedText] = useState<boolean>(false);
  const [maxEndDateForCustomSelection, setMaxEndDateForCustomSelection] =
    useState(dayjs());

  const [dates, setDates] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: "13-05-2019", endDate: "25-05-2019" });

  const [getCreateCaseDetailsApi] = useLazyGetCreateCaseDetailsQuery();
  const [reportingServiceDetailsApi] = useLazyGetReportingServiceDetailsQuery();
  const usLocale =
    enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  const [open, setOpen] = useState<boolean>(false);
  const [financialYear, setFinancialYear] = useState<string>("");
  const [isDownloadOptionOpen, setDownloadOptionOpen] =
    useState<boolean>(false);
  const [docType, setDocumentType] = useState("email");

  const [isApiSuccess, setIsApiSuccess] = useState<boolean>(false);
  const [isApiPopupOpen, setApiPopup] = useState<boolean>(false);
  const [generatedSrNumber, setSrNumber] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [selectedRadioValue, setSelectedRadioValue] =
    useState<string>("FinancialYear");

  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const { state } = useLocation();
  const accountNumber = state?.accountNumber || "";
  const idx = hlIndexFinder(allSummaryData, accountNumber);
  const summaryData = allSummaryData[Number(idx)];

  const financialYears = generateFinancialYearsFromDate(
    summaryData?.Disbursement_History?.rows[0]?.DISBRUSMENT_DATE
  );
  const handleRadioBtnClick = (data: T_Option) => {
    setSelectedRadioValue(data.value);
  };
  const getDocument = (type: string) => {
    setDocumentType(type);
    // api call
    setIsBtnLoading(true);

    getCreateCaseDetails(type);
  };

  const getCreateCaseDetails = async (type: string) => {
    try {
      const apiRequest: T_HlCreateCaseApiRequest = {
        customerId: summaryData.account_detail.rows[0].CUSTOMER_NO,
        accountNumber: summaryData?.account_detail.rows[0]?.LOAN_ACCOUNT_NUMBER,
        firstName: summaryData.account_detail.rows[0].FNAME,
        lastName: summaryData.account_detail.rows[0].LNAME,
        mobile: summaryData.borrower_detail.rows[0].MOBILE,
        emailId: summaryData.borrower_detail.rows[0].E_MAIL,
        subject: "Statement of Account",
        source: "ABC_Servicing_App",
        comments: "",
        subtype: "hfl073",
        isClosed: "true",
        url: "",
        category: "Request",
        lob: "HFC",
        priority: "LOW",
        segment: "Finance",
        callbackDate: "",
        callbackTimeSlot: "",
        stpsRequestType: type,
        asOnDate: "",
        salaryCreditDate: "",
        dueDate: "",
        changeInEmiAmount: "",
        payableCharge: "",
        partPaymentAmount: "",
        impact: "",
        modeOfRepayment: "",
        soaFromDate:
          selectedRadioValue != "FinancialYear"
            ? customStartDate
              ? customStartDate
              : dates.startDate
            : "",
        soaToDate:
          selectedRadioValue != "FinancialYear"
            ? customEndDate
              ? customEndDate
              : dates.endDate
            : "",
        icFromYear: "",
        icToYear: financialYear,
        attachments: [],
      };
      await getCreateCaseDetailsApi(apiRequest)
        .then(async (res) => {
          if (res.data?.message == "Success") {
            const caseId = res.data?.payload?.caseId
              ? res.data?.payload?.caseId
              : res.data?.payload?.existingCaseId;
            if (type == "Download" || type == "download") {
              await getReportingServiceDetails(caseId);
            } else {
              setIsApiSuccess(true);
              setApiPopup(true);
              setSrNumber(caseId);
              setIsBtnLoading(false);
            }
          }
          if (res.isError && res.error) {
            dispatch(setError({ error: new Error(), message: "failed" }));
            return;
          }
        })
        .catch((e) => {
          console.log(e);

          setIsBtnLoading(false);
        })
        .finally(() => {
          setIsBtnLoading(false);
        });
    } catch (e) {
      console.log(e);
      setIsBtnLoading(false);
    }
  };

  const getReportingServiceDetails = async (caseId: string) => {
    const apiRequest: T_HlReportingServiceApiRequest = {
      caseId: caseId,
      source: "CRM",
      requestType: "View",
      lan: summaryData.account_detail.rows[0].LOAN_ACCOUNT_NUMBER,
      reportName: "SOABinary",
      documentType: "BinaryData",
      asOnDate: dates.startDate,
      fromDate: customStartDate ? customStartDate : dates.startDate, //as of now static,  it should pass dynamic in future
      toDate: customEndDate ? customEndDate : dates.endDate,
    };
    await reportingServiceDetailsApi(apiRequest)
      .then(async (res) => {
        if (res.data?.message == "Success") {
          setIsApiSuccess(true);
          setApiPopup(true);
          setSrNumber(caseId || "");
          downloadAsPDF(res?.data?.payload?.binaryDataList[0]);
          setIsBtnLoading(false);
        }
        if (res.isError && res.error) {
          dispatch(setError({ error: new Error(), message: "failed" }));
          return;
        }
      })
      .catch((e) => {
        console.log(e);

        setIsBtnLoading(false);
      })
      .finally(() => {
        setIsBtnLoading(false);
      });
  };

  function downloadAsPDF(base64String: string) {
    if (base64String.startsWith("JVB")) {
      base64String = "data:application/pdf;base64," + base64String;
      downloadFileObject(base64String);
    } else if (base64String.startsWith("data:application/pdf;base64")) {
      downloadFileObject(base64String);
    } else {
      alert("Not a valid Base64 PDF string. Please check");
    }
  }

  function downloadFileObject(base64String: string) {
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = "Account-Statement.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const handleDateChange = (date?: Dayjs | null, isStartDate?: boolean) => {
    if (date && dayjs(date).isAfter("1900-01-01") && dayjs(date).isValid()) {
      if (isStartDate) {
        const customStartDate = dayjs(date);
        const customStartDateString = dayjs(date).format("DD-MM-YYYY");
        const customEndDate = new Date(customStartDateString).setFullYear(
          customStartDate.year() + 2,
          customStartDate.month(),
          customStartDate.date()
        );
        if (dayjs(customEndDate).year() > dayjs().year()) {
          const currentDate = dayjs();
          setMaxEndDateForCustomSelection(currentDate);
        } else {
          setMaxEndDateForCustomSelection(dayjs(customEndDate));
        }
        setCustomStartDate(customStartDateString);
      } else {
        setCustomEndDate(dayjs(date).format("DD-MM-YYYY"));
      }
    }
    if (!date) {
      if (isStartDate) {
        setCustomStartDate(null);
      } else {
        setCustomEndDate(null);
      }
    }
  };
  //   useEffect(() => {
  //     const financialYears = generateFinancialYearsFromDate(
  //       summaryData.Disbursement_History.rows[0].DISBRUSMENT_DATE
  //     );
  //     setFinancialYearList(financialYears);
  //   }, []);

  const copyText = useCallback(() => {
    let timeout: any;
    navigator.clipboard
      .writeText(`${generatedSrNumber}`)
      .then(() => {
        showCopiedText(true);
        timeout = setTimeout(() => {
          showCopiedText(false);
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });

    return () => clearTimeout(timeout);
  }, [generatedSrNumber]);

  return (
    <Box className="hl-ic-body">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 4,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          {" "}
          <CustomBreadcrumbs
            data={["Home loan", accountNumber || "...", "Statement of account"]}
            className="hl-page-breadcrumb"
          />
          <CustomHelpComponent />
        </Box>
        <Box className="content-wrapper">
          <Typography variant="h3">Statement of account</Typography>
          <Stack rowGap={2} margin="1.5rem 0 .625rem">
            <Typography
              variant="subtitle2"
              color={Colors.textGray}
              fontSize={"0.875rem"}
            >
              Select one
            </Typography>
            <RadioGroup className="radio-group">
              {options.map((item, index: number) => (
                <Box key={item.value} className="radio-box">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingY="1.5rem"
                    paddingX="1.5rem"
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                      }}
                    >
                      {item.label}
                    </Typography>
                    <Radio
                      checked={item.value === selectedRadioValue}
                      onClick={() => handleRadioBtnClick(item)}
                      sx={{
                        padding: 0,
                        color: Colors.red,
                        "&.Mui-checked": {
                          color: Colors.red,
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: 20,
                        },
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </RadioGroup>
            {selectedRadioValue == "FinancialYear" ? (
              <Box flex={1}>
                <CustomSelect
                  customPopupClass="hl-select-popup"
                  label="Select Year"
                  value={financialYear}
                  onChange={setFinancialYear}
                  options={financialYears}
                  placeholder="Select financial year"
                />
              </Box>
            ) : (
              <Box
                gap="1rem"
                marginTop="1rem"
                display="flex"
                justifyContent="space-between"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{
                    ...usLocale,
                    fieldMonthPlaceholder: (params) =>
                      params.contentType === "letter" ? "MMM" : "MM",
                  }}
                >
                  <DesktopDatePicker
                    label="From"
                    format="DD/MM/YYYY"
                    className="date-picker"
                    minDate={dayjs("01/01/2000", "DD/MM/YYYY")}
                    maxDate={dayjs()}
                    value={
                      customStartDate
                        ? dayjs(customStartDate, "DD-MM-YYYY")
                        : null
                    }
                    onChange={(date) => handleDateChange(date, true)}
                    sx={{ cursor: "pointer" }}
                  />
                </LocalizationProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={{
                    ...usLocale,
                    fieldMonthPlaceholder: (params) =>
                      params.contentType === "letter" ? "MMM" : "MM",
                  }}
                >
                  <DesktopDatePicker
                    label="To"
                    format="DD/MM/YYYY"
                    className="date-picker"
                    minDate={dayjs(customStartDate, "DD-MM-YYYY")}
                    maxDate={maxEndDateForCustomSelection}
                    value={
                      customEndDate ? dayjs(customEndDate, "DD-MM-YYYY") : null
                    }
                    onChange={(date) => handleDateChange(date, false)}
                    onError={(error) => {
                      if (error != null) {
                        setCustomEndDate("");
                      }
                    }}
                    sx={{ cursor: "pointer" }}
                  />
                </LocalizationProvider>
              </Box>
            )}
          </Stack>
        </Box>
      </Container>
      <Box margin="auto 1rem 0">
        <Button
          fullWidth
          variant="contained"
          className="continue-btn"
          disabled={
            selectedRadioValue === "FinancialYear"
              ? !financialYear
              : dayjs(
                  dayjs(customEndDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                ).isBefore(
                  dayjs(
                    dayjs(customStartDate, "DD-MM-YYYY").format("YYYY-MM-DD")
                  )
                ) ||
                !customEndDate ||
                !customStartDate
          }
          sx={{
            marginTop: "3.5rem",
          }}
          onClick={() => setDownloadOptionOpen(true)}
        >
          Continue
        </Button>
        <HomeLoanPoweredBy />
      </Box>
      {isDownloadOptionOpen && (
        <Box className="quick-actions-menu">
          <CustomPopup
            open={isDownloadOptionOpen}
            handleOpen={() => {
              setDownloadOptionOpen(!isDownloadOptionOpen);
            }}
            className={`service-menu-popup ${
              width < tabViewPoint ? "service-menu-popup-hl-sa" : ""
            }`}
            btnLabel="Close"
            btnStartIcon={
              <CloseIcon
                sx={{ height: "1rem", width: "1rem", marginTop: ".125rem" }}
              />
            }
            customeGetHelpHandler={() => {}}
            anchor="top"
            // flowType="HL"
          >
            <Stack rowGap={2}>
              <Grid container marginBottom={"1rem"}>
                <Grid
                  item
                  xs={3}
                  sm={1}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <KeyboardArrowLeftIcon
                    sx={{
                      color: Colors.red,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setDownloadOptionOpen(!isDownloadOptionOpen);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={11}
                  display="flex"
                  sx={{
                    marginLeft: {
                      xs: undefined,
                      sm: "-4rem",
                    },
                  }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    columnGap={1}
                    display="flex"
                    alignItems="center"
                    sx={{
                      paddingLeft: {
                        xs: "0.5rem",
                        sm: "4rem",
                      },
                    }}
                  >
                    <img
                      src={textBoxIcon}
                      alt="help-icon"
                      style={{ width: "1rem", height: "1rem" }}
                      onClick={() => {}}
                    />
                    <Typography
                      variant="subtitle2"
                      color={Colors.black}
                      sx={{ marginBottom: "0.125rem" }}
                    >
                      Statement of Account
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              {isBtnLoading ? (
                <CustomThreeDotsLoader />
              ) : (
                popOptions.map((optionData: any) => {
                  return (
                    <Stack
                      direction="row"
                      display="flex"
                      justifyContent="space-between"
                      className="download-options"
                      sx={{
                        padding: "0.65rem",
                        borderRadius: "0.31rem",
                        cursor: "pointer",
                      }}
                      onClick={() => getDocument(optionData.title)}
                    >
                      <Typography variant="subtitle2" color={Colors.black}>
                        {optionData.title}
                      </Typography>
                      <img
                        src={optionData.icon}
                        alt="help-icon"
                        style={{ width: "1.5rem", height: "1.5rem" }}
                      />
                    </Stack>
                  );
                })
              )}
            </Stack>
          </CustomPopup>
        </Box>
      )}
      {isApiPopupOpen && (
        <CustomPopup
          open={isApiPopupOpen}
          handleOpen={() => setApiPopup(false)}
          btnLabel={!isApiSuccess ? "Go to Account Statement page" : ""}
          className="download-statement-popup"
          btnEndIcon={
            !isApiSuccess ? (
              <img
                src={ArrowRightIcon}
                alt="right-arrow"
                style={{ color: Colors.red }}
                loading="lazy"
              />
            ) : null
          }
        >
          {isApiSuccess ? (
            <CommonSuccessPopupBody
              title=""
              des={
                docType.toLowerCase() === "email"
                  ? "Statement of Account will be emailed to your registered email ID shortly"
                  : "Statement of Account has been downloaded successfully"
              }
              // withInfoText={`The password to the document is PAN of loan's main applicant`}
              btnLabel="Go back to homepage"
              btnOnClick={() => navigate("/home")}
              btnDisabled={false}
              subDescription="The password to the document is PAN of loan's main applicant"
              isChangesForHomeLoan={true}
              extraInfo={
                <Stack
                  rowGap={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack className="hl-statement-generated-sr" rowGap={1}>
                    <Typography variant="h5" color={Colors.textGray}>
                      Service request number
                    </Typography>
                    <Stack direction="row" gap={".25rem"} alignItems="center">
                      <Typography
                        variant="h3"
                        color={Colors.darkCharcoal}
                        fontSize={"1.125rem"}
                      >
                        {generatedSrNumber}
                      </Typography>
                      <img
                        src={CopyIcon}
                        alt="copy"
                        loading="lazy"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigator.clipboard.writeText(`${generatedSrNumber}`)
                        }
                      />
                    </Stack>
                  </Stack>
                  {copiedText && (
                    <Typography variant="h6" color={Colors.black}>
                      Copied!
                    </Typography>
                  )}
                </Stack>
              }
            />
          ) : (
            <CustomErrorPopupBody
              title="Document is unavailable"
              btnLabel="Connect to customer support"
            />
          )}
        </CustomPopup>
      )}
    </Box>
  );
};

export default HomeLoanStatement;
