import { Box, Typography } from "@mui/material";
import { FC, useCallback } from "react";

interface AlertOptionProps {
  data: string;
  typeIcon: any;
  handleChange: Function;
  index: number;
  selectedValue: string;
}
const RenderAlertOption: FC<AlertOptionProps> = ({
  data = "",
  typeIcon,
  handleChange,
  index,
  selectedValue,
}) => {
  const handleTypeChange = useCallback(() => {
    handleChange(index);
  }, [index, handleChange]);
  return (
    <Box
      className={`li-sa-alert-title ${
        selectedValue === data ? "li-sa-alert-selected-title" : ""
      }`}
      onClick={handleTypeChange}
    >
      <img src={typeIcon} alt={"type-icon"} />
      <Typography variant="h5">{data}</Typography>
    </Box>
  );
};
export default RenderAlertOption;
