import { FC } from "react";
import CommonGifAnimation from "../../components/CustomGifAnimation/CommonGifAnimation";
import { T_AnimationData } from "../../globalTypes/GlobalTypes";
import LoaderGif from "../../assets/gif/Coffee_Loader.json";
import { Backdrop, Box, Typography } from "@mui/material";
import { Colors } from "../../styles/constant";

const LoadingInstant: FC = () => {
  return (
    <Backdrop
      open={true}
      style={{
        height: "100%",
        width: "100%",
        padding: "0rem",
        margin: "0rem",
        zIndex: "2999",
        backgroundColor: "white",
      }}
    >
      <Box>
        <CommonGifAnimation
          width={"9.375rem"}
          animationData={LoaderGif as T_AnimationData}
        />
        <Typography marginTop={"2rem"} variant="h3" color={Colors.textblack}>
          Crafting ABC experience for you
        </Typography>
        <Typography
          marginTop={"1rem"}
          variant="h5"
          color={Colors.descriptionGray}
        >
          This will be done before your coffee brews
        </Typography>
      </Box>
    </Backdrop>
  );
};
export default LoadingInstant;
