import { FC, useState } from "react";
import "../HiDetailsPage/HiDetailsPage.scss";
import { Box, Container, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { T_PolicyInsured } from "../HealthInsurance_Types";
import InfoIcon from "../../../assets/icons/information.svg";
import HiEditPolicyCard from "./HiEditPolicyCard";
import HiEditPolicyTabs from "./HiEditPolicyTabs";
import "./HiEditPolicy.scss";
import { T_LiRouteState } from "../../../globalTypes/GlobalTypes";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";

const HiEditPolicy: FC = () => {
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState)?.policyNumber || "";
  const policyInsuredDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyInsuredDetails
  );
  const selectedPolicyInsuredDetails = policyInsuredDetails.find(
    (item: T_PolicyInsured) => {
      return item.PolicyDetail.PolicyNumber === policyNumber;
    }
  );

  let genderCode = selectedPolicyInsuredDetails?.PolicyDetail.Gender1;
  let genderName;

  if (!genderCode) {
    genderName = "";
  } else if (genderCode === "F") {
    genderName = "Female";
  } else if (genderCode === "M") {
    genderName = "Male";
  } else {
    genderName = "Other";
  }

  let tenure = selectedPolicyInsuredDetails?.PolicyDetail.Tenure;
  let tenureString = "";

  if (tenure) {
    if (tenure === "1") {
      tenureString = "1 Year";
    } else {
      tenureString = `${tenure} Years`;
    }
  }

  return (
    <Box className="edit-policy-page">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 10,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <CustomBreadcrumbs
            data={["Health Insurance", policyNumber, "Edit Policy"]}
            className="hi-page-breadcrumb"
          />
          <CustomHelpComponent/>
        </Box>

        <Box className="content-wrapper">
          <Typography
            sx={{
              fontSize: "1.125rem",
              lineHeight: "1.35rem",
              fontWeight: 500,
              marginBottom: "0.5rem",
            }}
          >
            {selectedPolicyInsuredDetails?.PolicyDetail?.PolicyOwnerName} -{" "}
            {selectedPolicyInsuredDetails?.PolicyDetail?.CustomerCode}
          </Typography>
          {selectedPolicyInsuredDetails &&
          Object.keys(selectedPolicyInsuredDetails.PolicyDetail).length ? (
            <HiEditPolicyCard
              policyInsuredDetails={selectedPolicyInsuredDetails}
              showNoDocImage={false}
            />
          ) : (
            <HiEditPolicyCard
              policyInsuredDetails={undefined}
              showNoDocImage={true}
            />
          )}
        </Box>
      </Container>

      {selectedPolicyInsuredDetails?.PolicyDetail.editPolicyRecordPresent ===
        "true" && (
        <Container sx={{ background: "#fff" }}>
          <Box
            sx={{ marginBottom: "1rem !important" }}
            className="edit-policy-info"
          >
            <img
              src={InfoIcon}
              className="edit-policy-info-icon"
              alt="quick-menu-header-icon"
            />
            <Box>
              <Typography sx={{ fontWeight: 600 }}>
                Can't see your changes? Don't worry!
              </Typography>
              <Typography>Your changes will reflect in 3 days.</Typography>
            </Box>
          </Box>
        </Container>
      )}
      <HiEditPolicyTabs />
    </Box>
  );
};

export default HiEditPolicy;
