import { FC, useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import AlertGif from "../../../assets/gif/Exclamation_Mark.json";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import { T_AnimationData } from "../../../globalTypes/GlobalTypes";
import "./CustomFailure.scss";

interface Props {
  title: string;
  handleClick: Function;
  btnLabel: string;
  open: boolean;
}

const CustomFailure: FC<Props> = ({ title, handleClick, btnLabel, open }) => {
  const handleOpen = useCallback(() => {
    handleClick();
  }, [handleClick]);

  return (
    <CustomPopup
      open={open}
      handleOpen={handleOpen}
      className={`hl-common-policy-popup-box`}
    >
      <Box className="hl-custom-failure-box">
        <Box style={{ marginTop: "1.625rem" }}>
          <CommonGifAnimation
            width="2.5rem"
            animationData={AlertGif as T_AnimationData}
          />
          <Typography
            variant="h3"
            sx={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}
          >
            {title}
          </Typography>
        </Box>

        <Button
          fullWidth
          variant="contained"
          className="continue-btn"
          onClick={handleOpen}
        >
          {btnLabel}
        </Button>
      </Box>
    </CustomPopup>
  );
};

export default CustomFailure;
