import { Box, Typography } from "@mui/material";
import { FC } from "react";
import dayjs from "dayjs";
import {
  currencyConvertor,
  CurrencyFormat,
  getPolicyStatus,
  getPolicyType,
  getStatusColor,
} from "../../../../utils/conversionUtils";

interface FundDetailsProps {
  data: any[];
  type?: string;
  policyNo: string;
}

const CustomFundDetails: FC<FundDetailsProps> = ({
  data = [],
  type,
  policyNo = "",
}) => {
  return (
    <Box className="cea-parent-box" sx={{ width: "100%" }}>
      <Typography variant="h4">Alerts</Typography>
      {data.length > 0 &&
        data.map((content) => {
          return (
            <Box className="cea-box">
              <Box className="cea-box-header">
                <Box>
                  <Typography variant="h5">Policy Number</Typography>
                  <Typography variant="subtitle2" className="cea-box-value">
                    {policyNo}006741894
                  </Typography>
                </Box>
                <Box className="cea-inforce">
                  <Typography
                    variant="h5"
                    sx={{ color: getStatusColor("PREMIUM PAYING") }}
                  >
                    {getPolicyStatus("PREMIUM PAYING")}
                  </Typography>
                </Box>
              </Box>
              <Box className="cea-subcontent">
                <Typography variant="h5">Plan Name</Typography>
                <Typography variant="subtitle2" className="cea-box-value">
                  ABSL Wealth Secure Plan
                </Typography>
                <Typography variant="subtitle2" className="cea-box-value">
                  2013 FAV - Pay 10
                </Typography>
              </Box>
              <Box className="cea-subcontent">
                <Typography variant="h5">
                  Fund Value as on {dayjs().format("DD MM YYYY")}
                </Typography>
                <Typography variant="subtitle2" className="cea-box-value">
                  {CurrencyFormat("1827003.75")}
                </Typography>
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default CustomFundDetails;
