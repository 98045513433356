import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Container,
  InputAdornment,
  TextField,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Button,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import clockLogo from "../../../assets/icons/clock-time-two-outline.svg";
import SearchLogo from "../../../assets/icons/search-icon.svg";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import MutualFundIcon from "../../../assets/icons/mutualFund.svg";
import StockIcon from "../../../assets/icons/stock.svg";
import LifeInsuranceIcon from "../../../assets/icons/life-insurance.svg";
import HealthInsuranceIcon from "../../../assets/icons/healthInsurance.svg";
import NotFoundGif from "../../../assets/gif/Not_found.json";
import "./BasicSearch.scss";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import {
  PolicyOrAccountSelectionData,
  T_AnimationData,
  T_LiRouteState,
} from "../../../globalTypes/GlobalTypes";
import {
  useLazyFetchPopularServiceQuery,
  useLazyFetchSearchHistoryQuery,
  useLazyFetchSearchBarContentQuery,
  useLazySaveSearchHistoryQuery,
  useClearSearchHistoryMutation,
} from "../GeneralAppElements_Api";
import {
  T_fetchSearchHistory,
  T_P1orP2,
  T_SearchBarContent,
} from "../GeneralAppElements_Types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { Colors } from "../../../styles/constant";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../../../ErrorBoundary";
import { R_HomeRoutes } from "../../../pages/Home/Home_Routes";
import CsSessionStorageUtils from "../../../utils/CsSessionStorageUtils";
import {
  DashboardTabKeys,
  REACT_APP_URL,
  DEVICE_TYPE,
  LiLobCode,
  HiLobCode,
  MfLobCode,
  StockLobCode,
  HlLobCode,
} from "../../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import useDeviceDetection from "../../../hooks/useDeviceDetection";
import { TriggerGenerateSSO_OLD } from "../../Feature_SSO/Sso_Types";
import { triggerGenerateSSOOld } from "../../Feature_SSO/Sso_Slice";
import CustomCircularLoader from "../../../components/CustomCircularLoader/CustomCircularLoader";
import { isEmpty } from "lodash";
import CustomPolicyOrAccountSelect from "../../../components/CustomPolicyOrAccountSelect/CustomPolicyOrAccountSelect";

const titles = {
  SEARCH_HISTORY: "Search History",
  SEARCH_RESULT: "Search Result",
  NOT_FOUND: "Not found",
};
const serviceIcons: Record<string, string> = {
  "Life Insurance": LifeInsuranceIcon,
  "Health Insurance": HealthInsuranceIcon,
  "Mutual Funds": MutualFundIcon,
  "Stocks & Securities": StockIcon,
};

function BasicSearch() {
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const [searchState, setSearchState] = useState(titles.SEARCH_HISTORY);
  const [filteredResults, setFilteredResults] = useState<T_SearchBarContent[]>(
    []
  );
  const [listItemLoading, setListItemLoading] = useState<{
    key: string;
    loading: boolean;
  }>();
  const navigate = useNavigate();
  const LI_dashboardDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice
  );
  const MF_folioDetails = useAppSelector(
    (state) => state.MutualFundSlice.folioDetails
  );
  const HI_policyDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyDetails
  );
  const STOCKS_Details = useAppSelector(
    (state) => state.StocksSlice.stockdashboardDetails
  );
  const HL_LoanInfo = useAppSelector((state) => state.HomeLoanSlice.loanInfo);

  const [searchValue, setSearchValue] = useState("");
  const [showAllResults, setShowAllResults] = useState(false);
  const [policyOrAccountSelectionData, setPolicyOrAccountSelectionData] =
    useState<PolicyOrAccountSelectionData>({
      isOpen: false,
      options: [],
      redirectionUrl: "",
      lobDetails: null,
      functionalityCode: "",
    });

  const [fetchPopularService, { data: popularServiceData }] =
    useLazyFetchPopularServiceQuery();
  const [fetchSearchHistory, { data: searchHistoryData }] =
    useLazyFetchSearchHistoryQuery();
  const [fetchSearchBarContent, { data: searchBarContent }] =
    useLazyFetchSearchBarContentQuery();
  const [saveSearchHistory] = useLazySaveSearchHistoryQuery();
  const [clearSearchHistory] = useClearSearchHistoryMutation();
  const masterData = useAppSelector(
    (state) => state.GeneralAppElementsSlice.masterData
  );
  const CsAppSlice = useAppSelector((state) => state.CsAppSlice);
  const popularServicesLob = masterData?.masterDetail?.popularServicesLob;
  const itemsPerPage = 5;
  const dispatch = useAppDispatch();
  const deviceType = useDeviceDetection();
  const activeTab = CsSessionStorageUtils.get("ACTIVE_DASHBOARD_TAB");
  const cmsSlice = useAppSelector((state) => state.CmsSlice);
  const ssoSlice = useAppSelector(
    (state) => state.SsoSlice.ssoData as TriggerGenerateSSO_OLD
  );

  const cmsConfigPopularServices = useMemo(() => {
    return cmsSlice.CmsIntegrationVariableData.filter(
      (f) =>
        f.attributes.isEnable &&
        f.attributes.pageReference === "Search - Popular services"
    );
  }, []);

  const cmsExplorePlans = useMemo(() => {
    return cmsSlice.CmsIntegrationVariableData.filter(
      (f) =>
        f.attributes.isEnable &&
        f.attributes.pageReference === "Search Explore Plans - Popular services"
    );
  }, []);
  const cmsConfigMarquee = useMemo(() => {
    const marqueeArr = cmsSlice.CmsIntegrationVariableData.filter(
      (f) => f.attributes.isEnable && f.attributes.key === "Marquee"
    );
   return marqueeArr.filter((f) => {
      const tempData =
        activeTab === DashboardTabKeys.Insurance
          ? ["02", "06"].includes(f.attributes.lobId)
          : activeTab === DashboardTabKeys.Investment
          ? ["24", "01"].includes(f.attributes.lobId)
          : false;
      return (
        f.attributes.isEnable && f.attributes.key === "Marquee" && tempData
      );
    });
  }, []);

  const defaultMarqueeText = useMemo(() => {
    return (
      cmsSlice.CmsIntegrationVariableData.find(
        (f) => f.attributes.isEnable && f.attributes.key === "Default Marquee"
      )?.attributes.text || "Search for Insurance policy details"
    );
  }, []);
  const [marqueeLabels, setMarqueeLabels] = useState(defaultMarqueeText);

  useEffect(() => {
    if (!ssoSlice || isEmpty(ssoSlice) || !ssoSlice?.showLoading) {
      console.log("setting to false");
      setListItemLoading((prev) => {
        if (prev) {
          return { ...prev, loading: false };
        } else {
          return undefined;
        }
      });
    }
  }, [ssoSlice]);

  useEffect(() => {
    if (!cmsConfigMarquee || cmsConfigMarquee.length <= 0) {
      setMarqueeLabels(defaultMarqueeText);
      return;
    }
    const toggleMarque = () => {
      setMarqueeLabels((prev) => {
        return cmsConfigMarquee[0]?.attributes.text === prev
          ? "" + cmsConfigMarquee?.[1]?.attributes.text
          : "" + cmsConfigMarquee?.[0]?.attributes.text;
      });
    };
    toggleMarque();
    const interval = setInterval(() => {
      toggleMarque();
    }, 10000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [cmsConfigMarquee]);
  useEffect(() => {
    dispatch(updateLoadingState(false));
    const data = {
      csUserId: userDetails?.CS_USER_ID || "",
    };
    fetchSearchHistory(data);
    const params = {
      lobs: popularServicesLob?.map((p) => p.masterValue) || [
        "Mutual Funds",
        "Stocks & Securities",
        "Life Insurance",
        "Health Insurance",
      ],
    };
    fetchPopularService(params, true);
    const activeTab = CsSessionStorageUtils.get("ACTIVE_DASHBOARD_TAB");

    const lobsToSearch: string[] = [];
    const lobs = CsAppSlice.userDetails?.customerAndLobDetails
      ?.filter((f) => f.PRIMARY_SECONDARY_USER === "primary")
      ?.map((m) => m.LOB);
    if (lobs?.includes(LiLobCode)) {
      lobsToSearch.push("Life Insurance");
    }
    if (lobs?.includes(HiLobCode)) {
      lobsToSearch.push("Health Insurance");
    }
    if (lobs?.includes(MfLobCode)) {
      lobsToSearch.push("Mutual Funds");
    }
    if (lobs?.includes(StockLobCode)) {
      lobsToSearch.push("Stocks & Securities");
    }
    if (lobs?.includes(HlLobCode)) {
      lobsToSearch.push("Home Loans");
    }
    fetchSearchBarContent({ lob: lobsToSearch.join(",") });
  }, []);

  React.useEffect(() => {
    if (searchBarContent?.payload) {
      setFilteredResults(searchBarContent.payload);
    }
  }, [searchBarContent]);

  const redirectToLob = useCallback(
    (
      uniqeuId: string,
      functionalityCode: string,
      lobName: string,
      redirectionUrl: string
    ) => {
      const lobDetails = masterData?.productCategory.find(
        (f) => f.lob === lobName || f.lobName === lobName || f.lobId === lobName
      );
      const panNo = CsAppSlice.userDetails?.pan;
      const name = CsAppSlice.userDetails?.name;
      const dob = CsAppSlice.userDetails?.dob;
      const email = "" + CsAppSlice.userDetails?.emailId;
      const mobile = "" + CsAppSlice.userDetails?.mobileNumber;
      const ciiNo = CsAppSlice.userDetails?.customerAndLobDetails?.find(
        (f) =>
          f.PRIMARY_SECONDARY_USER === "primary" && f.LOB === lobDetails?.lob
      )?.CII;
      const oldTriggerValue = (ssoSlice as TriggerGenerateSSO_OLD)
        .triggerGenerateSSO_Old;
      /**Udp related params are being udpated in generateSSO component as it is constant for all lobs */
      const ssoObj = {
        triggerGenerateSSO_Old: oldTriggerValue ? !oldTriggerValue : true,
        redirectionUrl: redirectionUrl,
        ciiNo: ciiNo,
        functionality: functionalityCode,
        mobileNo: mobile,
        lobClientId: ssoSlice.lobClientId,
        channel: CsAppSlice.channel,
        sourceLobId: lobDetails?.lobId,
        destinationLobId: lobDetails?.lobId,
        callbackUrl: `${REACT_APP_URL}/login`,
        appVersion: "1",
        panNumber: panNo,
        customerFullName: name,
        customerDob: dob,
        emailId: email,
        osVersion: "Windows",
        imei: "1231312",
        deviceId: "131312",
        userAgent: navigator.userAgent,
        clientIpAddress: "",
        udp: "",
        udp2: "",
        udp3: "",
        udp4: "",
        udp5: "",
        udp6: "",
        udp7: "",
        udp8: "",
        udp9: "",
        udp10: "",
        showLoading: true,
      } as TriggerGenerateSSO_OLD;
      if (lobDetails?.lobId === "06") {
        ssoObj.userId = "OneABCTest"; //given by client
        ssoObj.apiPassword = "T25lQUJDVGVzdDEyMw=="; //given by client
      }
      dispatch(triggerGenerateSSOOld(ssoObj));
      console.log(uniqeuId);
      setListItemLoading(() => {
        return { key: uniqeuId, loading: true };
      });
    },
    [ssoSlice]
  );
  const handleRedirect = (url: string) => {
    window.location.href =
      url ||
      "https://apps.apple.com/in/app/abcd-aditya-birla-capital/id6477909524?mt=8";
  };

  const handleInAppRedirection = (
    lobName: string,
    redirectionUrl: string,
    functionalityCode?: string
  ) => {
    const lobDetails = masterData?.productCategory.find(
      (f) => f.lob === lobName || f.lobName === lobName || f.lobId === lobName
    );

    if (
      lobDetails?.lobName === "Life Insurance" &&
      !isEmpty(LI_dashboardDetails?.dashboardDetails)
    ) {
      if (LI_dashboardDetails?.dashboardDetails?.length === 1) {
        const policyNumber =
          LI_dashboardDetails.dashboardDetails[0].POLICY_NUMBER;
        const stateData: T_LiRouteState = {
          policyNumber: policyNumber,
        };
        navigate(redirectionUrl, { state: stateData });
      } else {
        let policyNumberArray = LI_dashboardDetails.dashboardDetails.map(
          (item) => item.POLICY_NUMBER
        );
        setPolicyOrAccountSelectionData({
          isOpen: true,
          options: policyNumberArray || [],
          redirectionUrl: redirectionUrl,
          lobDetails: lobDetails,
        });
      }
    } else if (
      lobDetails?.lobName === "Health Insurance" &&
      !isEmpty(HI_policyDetails)
    ) {
      if (HI_policyDetails?.length === 1) {
        const policyNumber = HI_policyDetails[0].PolicyNumber;
        const stateData: T_LiRouteState = {
          policyNumber: policyNumber,
        };
        navigate(redirectionUrl, { state: stateData });
      } else {
        let policyNumberArray = HI_policyDetails.map(
          (item) => item.PolicyNumber
        );
        setPolicyOrAccountSelectionData({
          isOpen: true,
          options: policyNumberArray || [],
          redirectionUrl: redirectionUrl,
          lobDetails: lobDetails,
        });
      }
    } else if (
      lobDetails?.lobName === "Mutual Funds" &&
      MF_folioDetails?.length > 0
    ) {
      navigate(redirectionUrl);
    } else if (
      lobDetails?.lobName === "Stocks & Securities" &&
      STOCKS_Details?.length > 0
    ) {
      navigate(redirectionUrl);
    } else if (
      lobDetails?.lobName === "Home Loans" &&
      !isEmpty(HL_LoanInfo.rows)
    ) {
      if (HL_LoanInfo?.rows?.length === 1) {
        const accountNumber = HL_LoanInfo?.rows[0].LOAN_ACCOUNT_NUMBER || "";
        const stateData = {
          accountNumber: accountNumber,
          functionalityCode: functionalityCode,
        };
        navigate(redirectionUrl, { state: stateData });
      } else {
        let accountNumberArray = HL_LoanInfo?.rows.map(
          (item) => item.LOAN_ACCOUNT_NUMBER
        );
        setPolicyOrAccountSelectionData({
          isOpen: true,
          options: accountNumberArray || [],
          redirectionUrl: redirectionUrl,
          lobDetails: lobDetails,
          functionalityCode: functionalityCode,
        });
      }
    } else {
      navigate(R_HomeRoutes.home);
    }
  };

  const policyOrAccountDataHandler = (data: PolicyOrAccountSelectionData) => {
    setPolicyOrAccountSelectionData(data);
  };

  const handleSearchHistoryArrowClick = (history: T_fetchSearchHistory) => {
    setFilteredResults([]);
    if (history.redirectionMode === "inApp") {
      handleInAppRedirection(
        history.lob,
        history.redirectionUrl,
        history.functionalityCode || ""
      );
    } else {
      console.log("lob from search history:", history.lob);
      redirectToLob(
        "" + history.uniqueId,
        "" + history.functionalityCode,
        history.lob,
        history.redirectionUrl
      );
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputValue = event.target.value.toLowerCase();

    if (inputValue === "") {
      setSearchState(titles.SEARCH_HISTORY);
    } else if (inputValue.length >= 2) {
      setSearchState(titles.SEARCH_RESULT);
      const filtered =
        searchBarContent?.payload?.filter((item) =>
          item.menuDisplayText.toLowerCase().includes(inputValue)
        ) || [];
      if (filtered.length <= 0) {
        setSearchState(titles.NOT_FOUND);
      } else {
        setFilteredResults(filtered);
      }
    } else {
      setSearchState(titles.SEARCH_HISTORY);
    }
    setSearchValue(event.target.value);
  };

  const handleArrowClick = useCallback((history: T_SearchBarContent) => {
    saveSearchHistory({
      csUserId: userDetails?.CS_USER_ID || "",
      searchHistory: [history.uniqueId.toString()],
    }).then((r) => {
      if (history.redirectionMode === "inApp") {
        handleInAppRedirection(
          history.lob,
          history.redirectionUrl,
          history.functionalityCode
        );
      } else {
        console.log("lob from search result:", history.lob);
        redirectToLob(
          `${history.uniqueId}-${history.menuId}`,
          "" + history.functionalityCode,
          history.lob,
          history.redirectionUrl
        );
      }
    });
  }, []);

  const clearSearchHistoryHandler = useCallback(() => {
    const data = { csUserId: userDetails?.CS_USER_ID || "" };
    clearSearchHistory(data);
  }, []);

  const handlePopularServicesClick = useCallback(
    (lobName: string, option: T_P1orP2) => {
      if (option.redirectionMode === "inApp") {
        handleInAppRedirection(lobName, option.redirectionUrl);
      } else {
        console.log("lob from search popular services:", lobName);
        redirectToLob(
          `${lobName}`,
          "" + option.functionalityCode,
          lobName,
          option.redirectionUrl
        );
      }
    },
    []
  );

  const handleSearchClick = () => {
    const searchItem = filteredResults.find(
      (item) => item.menuDisplayText.toLowerCase() === searchValue.toLowerCase()
    );
    if (searchItem) {
      handleArrowClick(searchItem);
    } else {
      setSearchState(titles.NOT_FOUND);
    }
  };

  const handleClearClick = () => {
    setSearchValue("");
  };

  const popularServicesUI = useMemo(() => {
    if (!popularServiceData?.payload) {
      return <></>;
    }

    const handleExplorePlans = (title: string, redirectionUrl: string) => {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // marginLeft: "16px",
            }}
          >
            <Typography
              sx={{
                color: "#414042",
                // marginLeft: "16px",
              }}
              style={{
                cursor: "pointer",
                wordBreak: "break-all",
                textDecoration: "none",
                fontSize: ".75rem",
                fontWeight: "500",
                lineHeight: ".975rem",
                color: "#414042",
              }}
              onClick={() => {
                if (!listItemLoading?.loading) {
                  handleRedirect(redirectionUrl);
                }
              }}
            >
              {title}
            </Typography>
          </Box>
        </>
      );
    };
    type T_ExplorePlans = {
      lob: string;
      text: string;
      redirectionUrl: string;
    };
    const explorePlansMapped: T_ExplorePlans[] = [];
    const mappedServices = popularServiceData.payload.map((p) => {
      // const mappedServices = popularServicesTestData.map((p) => {
      const cmsConfigP1 = cmsConfigPopularServices.find(
        (f) => f.id === Number(p.p1.cmsId)
      )?.attributes;
      const cmsConfigP2 = cmsConfigPopularServices.find(
        (f) => f.id === Number(p.p2.cmsId)
      )?.attributes;
      const explorePlans = cmsExplorePlans.find(
        (f) => f.attributes.lobId === cmsConfigP1?.lobId
      );
      const userLOBDetail =
        CsAppSlice?.userDetails?.customerAndLobDetails?.filter(
          (detail) =>
            detail.LOB === explorePlans?.attributes.lobId ||
            detail.LOB === explorePlans?.attributes.lobName
        ) || [];

      if (!userLOBDetail?.length) {
        explorePlansMapped.push({
          lob: p.lob,
          text: "" + explorePlans?.attributes.text,
          redirectionUrl: "" + explorePlans?.attributes.url,
        });
      }

      return {
        ...p,
        p1: {
          ...p.p1,
          functionalityCode: "" + p.p1.functionalityCode,
          functionalityDesc: "",
          menuDisplayValue: "" + cmsConfigP1?.key,
          redirectionMode: "" + cmsConfigP1?.reference,
          redirectionUrl: "" + cmsConfigP1?.url,
          useCaseId: "" + cmsConfigP1?.text,
        },
        p2: {
          ...p.p2,
          functionalityCode: "" + p.p2.functionalityCode,
          functionalityDesc: "",
          menuDisplayValue: "" + cmsConfigP2?.key,
          redirectionMode: "" + cmsConfigP2?.reference,
          redirectionUrl: "" + cmsConfigP2?.url,
          useCaseId: "" + cmsConfigP2?.text,
        },
      };
    });
    return !mappedServices || mappedServices?.length <= 0 ? (
      <></>
    ) : (
      <Box className="popular-service">
        <Typography
          sx={{
            display: "inline",
            fontWeight: "600",
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: "#000000",
            alignItems: "right",
            marginLeft: "1rem",
          }}
        >
          {"Popular Services"}
        </Typography>
        <Box className="popular-service-option">
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            {mappedServices?.map((service) => {
              const explorePlanItem = explorePlansMapped.find(
                (f) => f.lob === service.lob
              );
              return (
                <ListItem alignItems="flex-start" key={service.lob}>
                  <ListItemAvatar style={{ flex: "auto" }}>
                    <Avatar alt={service.lob} src={serviceIcons[service.lob]} />
                  </ListItemAvatar>
                  <ListItemText
                    style={{ flex: "80%" }}
                    primary={
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: ".75rem",
                          color: "#414042",
                        }}
                      >
                        {service.lob}
                      </Typography>
                    }
                    secondary={
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <React.Fragment
                          key={`${service.lob}-${service.p1.useCaseId}`}
                        >
                          {explorePlanItem ? (
                            handleExplorePlans(
                              explorePlanItem.text || "Explore Plans",
                              "" + explorePlanItem.redirectionUrl
                            )
                          ) : (
                            <>
                              <Typography
                                variant="h6"
                                sx={{
                                  color: "#414042",
                                }}
                                style={{
                                  cursor: "pointer",
                                  wordBreak: "break-all",
                                }}
                                onClick={() => {
                                  if (!listItemLoading?.loading) {
                                    handlePopularServicesClick(
                                      service.lob,
                                      service.p1
                                    );
                                  }
                                }}
                              >
                                {service.p1.useCaseId}
                              </Typography>
                              <Typography
                                fontSize={12}
                                fontWeight={600}
                                lineHeight=".975rem"
                                marginX=".25rem"
                                style={{ cursor: "pointer" }}
                              >
                                |
                              </Typography>
                              <Typography
                                variant="h6"
                                sx={{
                                  color: "#414042",
                                }}
                                style={{
                                  cursor: "pointer",
                                  wordBreak: "break-all",
                                }}
                                onClick={() => {
                                  if (!listItemLoading?.loading) {
                                    handlePopularServicesClick(
                                      service.lob,
                                      service.p2
                                    );
                                  }
                                }}
                              >
                                {service.p2.useCaseId}
                              </Typography>
                            </>
                          )}
                        </React.Fragment>
                      </div>
                    }
                  />
                  {listItemLoading &&
                    listItemLoading.key === `${service.lob}` &&
                    listItemLoading.loading && (
                      <div style={{ flex: "auto", alignSelf: "center" }}>
                        <CustomCircularLoader size={20} />
                      </div>
                    )}
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Box>
    );
  }, [popularServiceData?.payload, titles, listItemLoading]);

  const getSearchHistoryScreen = useMemo(() => {
    return (
      <Box>
        <Box className="search-history">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginTop={"1.25rem"}
            marginBottom={"1.25rem"}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1rem",
                lineHeight: "1.2rem",
                color: "#414042",
              }}
            >
              {titles.SEARCH_HISTORY}
            </Typography>

            {(searchHistoryData?.payload?.length || 0) > 0 && (
              <Typography
                variant="h6"
                color={Colors.red}
                onClick={clearSearchHistoryHandler}
              >
                Clear all
              </Typography>
            )}
          </Box>

          {(searchHistoryData?.payload?.length || 0) > 0 &&
            searchHistoryData?.payload.map((history) => (
              <Grid
                container
                spacing={1}
                key={history.uniqueId}
                sx={{
                  borderBottom: ".0625rem solid #E0E0E0",
                  paddingTop: ".875rem",
                  paddingBottom: ".875rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (!listItemLoading?.loading) {
                    handleSearchHistoryArrowClick(history);
                  }
                }}
              >
                <Grid item xs={1}>
                  <img src={clockLogo} alt="logo" />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: ".75rem",
                      lineHeight: ".825rem",
                      color: "#414042",
                      marginTop: ".375rem",
                      marginLeft: ".375rem",
                    }}
                  >
                    {history.menuDisplayText}
                  </Typography>
                </Grid>
                <Grid item xs={1} sx={{ marginTop: ".25rem" }}>
                  {listItemLoading &&
                  listItemLoading.key === "" + history.uniqueId &&
                  listItemLoading.loading ? (
                    <CustomCircularLoader size={20} />
                  ) : (
                    <img src={arrowRight} alt="arrow-right" />
                  )}
                </Grid>
              </Grid>
            ))}
        </Box>
        {/* {popularServicesUI} */}
      </Box>
    );
  }, [searchHistoryData?.payload, titles, listItemLoading]);

  const getSearchResultScreen = (
    title: string,
    searchBarContentData: T_SearchBarContent[] = []
  ) => {
    const displayedResults = showAllResults
      ? searchBarContentData
      : searchBarContentData.slice(0, itemsPerPage);
    const handleViewAll = () => {
      setShowAllResults(true);
    };
    return (
      <>
        <Box className="search-history">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: ".875rem",
                lineHeight: "1.1375rem",
                color: "#414042",
                marginTop: "1.25rem",
              }}
            >
              {title}
            </Typography>
          </Box>

          {displayedResults.map((history) => (
            <Grid
              container
              spacing={1}
              key={`${history.uniqueId}-${history.menuId}`}
              sx={{
                borderBottom: ".0625rem solid #E0E0E0",
                paddingTop: ".875rem",
                paddingBottom: ".875rem",
                cursor: "pointer",
              }}
              onClick={() => {
                if (!listItemLoading?.loading) {
                  handleArrowClick(history);
                }
              }}
            >
              <Grid item xs={1}>
                <img
                  src={title === titles.SEARCH_HISTORY ? clockLogo : SearchLogo}
                  alt="logo"
                />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: ".75rem",
                    lineHeight: ".825rem",
                    color: "#414042",
                    marginTop: ".375rem",
                    marginLeft: ".375rem",
                  }}
                >
                  {history.menuDisplayText}
                </Typography>
              </Grid>
              <Grid item xs={1} sx={{ marginTop: ".25rem" }}>
                {listItemLoading &&
                listItemLoading.key ===
                  `${history.uniqueId}-${history.menuId}` &&
                listItemLoading.loading ? (
                  <CustomCircularLoader size={20} />
                ) : (
                  <img src={arrowRight} alt="arrow-right" />
                )}
              </Grid>
            </Grid>
          ))}
          {!showAllResults && searchBarContentData.length > itemsPerPage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="text"
                sx={{
                  minWidth: "1.25rem",
                  height: "1.25rem",
                  color: Colors.red,
                  marginTop: "1.25rem",
                }}
                onClick={handleViewAll}
              >
                View All
              </Button>
            </Box>
          )}
        </Box>
      </>
    );
  };

  const NotFound = useMemo(() => {
    return (
      <Box className="Not-found">
        <CommonGifAnimation animationData={NotFoundGif as T_AnimationData} />
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "1.25rem",
            lineHeight: "1.5rem",
            color: "#2E2E2ECC",
            textAlign: "center",
          }}
        >
          {"Uhh-Ohh!"}
        </Typography>
        <Typography
          sx={{
            marginTop: "1rem",
            fontWeight: "400",
            fontSize: ".75rem",
            lineHeight: "1.05rem",
            color: "rgba(46, 46, 46, 0.5)",
            textAlign: "center",
          }}
        >
          {"We couldn't find what you were looking for"}
        </Typography>
      </Box>
    );
  }, [titles]);

  return (
    <ErrorBoundary>
      <Box
        className="search-page"
        sx={{
          ...((deviceType === DEVICE_TYPE.MOBILE ||
            deviceType === DEVICE_TYPE.PWA_MOBILE) && {
            height: window.innerHeight,
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none", // for Internet Explorer and Edge
            "scrollbar-width": "none", // for Firefox
          }),
        }}
      >
        <Container>
          <Box className="search-box">
            <TextField
              fullWidth
              placeholder={marqueeLabels}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ marginRight: 0 }} position="start">
                    <IconButton sx={{ padding: 0 }} onClick={handleSearchClick}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: searchValue.length > 0 && (
                  <InputAdornment sx={{ marginRight: 0 }} position="end">
                    <IconButton sx={{ padding: 0 }} onClick={handleClearClick}>
                      <CloseIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  "& input::placeholder": {
                    color: "#737373", // Change this to the desired color
                  },
                },
              }}
              value={searchValue}
              onChange={handleChange}
            />
          </Box>
          {searchState === titles.SEARCH_HISTORY && getSearchHistoryScreen}
          {searchState === titles.SEARCH_RESULT &&
            getSearchResultScreen(titles.SEARCH_RESULT, filteredResults)}
          {searchState === titles.NOT_FOUND && NotFound}
          {[titles.NOT_FOUND, titles.SEARCH_HISTORY].includes(searchState) &&
            popularServicesUI}
        </Container>
      </Box>
      {policyOrAccountSelectionData.isOpen &&
        !isEmpty(policyOrAccountSelectionData.options) && (
          <CustomPolicyOrAccountSelect
            data={policyOrAccountSelectionData}
            policyOrAccountDataHandler={policyOrAccountDataHandler}
          />
        )}
    </ErrorBoundary>
  );
}

export default BasicSearch;
