import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import { T_AnimationData, T_Option } from "../../../globalTypes/GlobalTypes";
import DropDownIcon from "../../../assets/icons/chevron-down.svg";
import { Colors } from "../../../styles/constant";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import HealthSuccessGif from "../../../assets/gif/Health_Success.json";
import { getCaseCreateReqPayload } from "../../../utils/helper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import {
  T_PolicyInsured,
  T_UpdateHIDetails_ReqPayload,
} from "../HealthInsurance_Types";
import {
  useLazyFetchPolicyInsuredQuery,
  useLazyUpdateHIDetailsQuery,
} from "../HealthInsurance_Api";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { HiNomineeRelationship } from "../../../utils/constants";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import { setHiAllPolicyInsuredDetails } from "../HealthInsurance_Slice";

interface EditNomineeDetailsComponentProps {
  open: boolean;
  handleOpen: Function;
  details?: T_PolicyInsured;
}

const HiEditNomineeDetails: FC<EditNomineeDetailsComponentProps> = ({
  open,
  handleOpen,
  details,
}) => {
  const options: T_Option[] = HiNomineeRelationship;
  const [updateHiDetailsApi] = useLazyUpdateHIDetailsQuery();
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [nomineeName, setNomineeName] = useState<string>("");
  const [nomineeNameHelperText, setNomineeNameHelperText] =
    useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [mobileNumberHelperText, setMobileNumberHelperText] =
    useState<string>("");
  const [selectedRelation, setSelectedRelation] = useState<string>("");
  const [isRelationshipDetailsOpen, setIsRelationshipDetailsOpen] =
    useState<boolean>(false);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>("");
  const [policyNum, setPolicyNum] = useState<string | undefined>("");
  const [currNomineeName, setCurrNomineeName] = useState<string>("");
  const [currNomineeRelation, setCurrNomineeRelation] = useState<string>("");
  const [currNomineePhoneNum, setCurrNomineePhoneNum] = useState<string>("");

  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
  const [isOnNomineeDetails, setIsOnNomineeDetails] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const [fetchPolicyInsuredApi] = useLazyFetchPolicyInsuredQuery();
  const hiAllPolicyInsuredDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyInsuredDetails
  );

  const handleDropdownClick = () => {
    setIsRelationshipDetailsOpen(true);
    setIsOnNomineeDetails(false);
  };

  const handleRadioBtnClick = (data: T_Option) => {
    setSelectedRadioValue(data.value);
  };

  const handleRelationshipContinueClick = () => {
    setSelectedRelation(selectedRadioValue);
    setIsRelationshipDetailsOpen(false);
    setIsOnNomineeDetails(true);
  };

  useEffect(() => {
    if (details?.PolicyDetail) {
      let item = details?.PolicyDetail;
      setCurrNomineeName(item.vchNominee);
      setCurrNomineeRelation(item.Relationship);
      setCurrNomineePhoneNum(item.NomineeContactNumber);

      setNomineeName(item.vchNominee);
      setSelectedRelation(item.Relationship);
      setSelectedRadioValue(item.Relationship);
      setPhoneNumber(item.NomineeContactNumber);
    }
    setPolicyNum(details?.PolicyDetail.PolicyNumber);
  }, []);

  const handleNomineeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^[A-Za-z\s]*$/; // Only allows letters, no special characters

    if (value.trim() === "") {
      setNomineeNameHelperText("Nominee name cannot be blank");
    } else if (!regex.test(value)) {
      setNomineeNameHelperText("Enter a valid Nominee name");
    } else if (value.length > 50) {
      setNomineeNameHelperText("Nominee name cannot exceed 50 characters");
    } else {
      setNomineeNameHelperText(""); // Clear error if valid
    }

    setNomineeName(value);
  };

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setPhoneNumber(inputValue);
    const cleanedValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters

    const phoneRegex = /^[6-9]\d{9}$/; // Starts with 6-9 followed by exactly 9 more digits

    if (!phoneRegex.test(cleanedValue)) {
      setMobileNumberHelperText("Enter a valid mobile number");
    } else {
      setMobileNumberHelperText("");
    }
  };

  const isContinueButtonEnabled = () => {
    return (
      nomineeName !== "" && // Ensure nomineeName is not empty
      nomineeNameHelperText === "" && // Ensure there are no validation errors for nomineeName
      mobileNumberHelperText === "" && // Ensure there are no validation errors for mobileNumber
      phoneNumber.length === 10 &&
      selectedRadioValue.trim() !== "" // Ensure there is a selected radio value
    );
  };

  const handleContinueBtnClick = () => {
    setIsOnNomineeDetails(false);
    setIsBtnLoading(true);
    const reqPayload: T_UpdateHIDetails_ReqPayload = getCaseCreateReqPayload(
      {
        CaseSubSubType: "Change of Nominee",
        CaseTitle: "EndorsementChange of Nominee",
        Comments: `Current Nominee Details - Nominee name:${currNomineeName} | NomineeRelationship: ${currNomineeRelation}|NomineeContact: ${currNomineePhoneNum}| , Updated Nominee Details - Nominee name:${nomineeName} |  NomineeRelationship:${selectedRelation} | NomineeContact:${phoneNumber} `,
        Policy: details?.PolicyDetail?.PolicyNumber, // Ensure `details` is available
      },
      {
        NomineeName: currNomineeName,
        NomineeContact: currNomineePhoneNum,
        NomineeRelationship: currNomineeRelation,
      }
    );
    updateHiDetailsApi(reqPayload).then((res) => {
      setIsBtnLoading(false);
      if (res.data?.code === 1) {
        // Handle success scenario
        fetchPolicyInsuredApi(policyNum || "").then((response) => {
          if (response.data?.code === 1 && response?.data?.payload?.Response) {
            const selectedPolicyIndex = hiAllPolicyInsuredDetails.findIndex(
              (item) => item.PolicyDetail.PolicyNumber === policyNum
            );
            if (selectedPolicyIndex >= 0) {
              let updatedPolicyInsuredDetails = [...hiAllPolicyInsuredDetails];
              updatedPolicyInsuredDetails[selectedPolicyIndex] =
                response?.data?.payload?.Response;
              dispatch(
                setHiAllPolicyInsuredDetails(updatedPolicyInsuredDetails)
              );
            }
          }
        });
        setIsOnNomineeDetails(false);
        setShowSuccessPopup(true);
      } else {
        // Handle error scenario
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
      if (res.isError && res.error) {
        setShowSuccessPopup(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
    });
  };

  const handleClick = () => {
    // if (showSuccessPopup) {
    //   se
    // setShowSuccessPopup(false);
    // }
    if (!isOnNomineeDetails) {
      handleOpen(false);
      setShowSuccessPopup(false);
    }
  };

  const renderCustomPopupChildren = () => {
    return (
      <Box>
        <Typography textAlign="center" variant="h5">
          EDIT NOMINEE DETAILS
        </Typography>
        <Stack rowGap="1.5rem" marginTop="1.5rem">
          <TextField
            fullWidth
            label="Nominee name"
            value={nomineeName}
            onChange={handleNomineeChange}
            error={nomineeNameHelperText.length > 0}
            helperText={nomineeNameHelperText}
          />
          <TextField
            fullWidth
            label="Relation with proposer"
            value={selectedRelation}
            onClick={handleDropdownClick}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={DropDownIcon}
                    alt="Drop Down Icon"
                    style={{ width: 24, height: 24 }} // Adjust size as needed
                  />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            label="Mobile number"
            value={phoneNumber} // Ensure phone number is displayed correctly
            onChange={handleMobileNumberChange}
            error={mobileNumberHelperText.length > 0}
            helperText={mobileNumberHelperText}
            inputProps={{
              maxLength: 10,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            type="tel"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ fontWeight: "bold" }}>
                  +91
                </InputAdornment>
              ),
            }}
          />
          {isBtnLoading ? (
            <CustomThreeDotsLoader />
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={handleContinueBtnClick}
              disabled={!isContinueButtonEnabled()}
              className="continue-btn"
            >
              Continue
            </Button>
          )}
        </Stack>
      </Box>
    );
  };

  return (
    <>
      {isOnNomineeDetails && (
        <CustomPopup
          open={open}
          handleOpen={() => {
            handleOpen(false);
          }}
          btnLabel={"Close"}
          btnStartIcon={<CloseIcon sx={{ width: "1rem", height: "1rem" }} />}
          className={`add-manual-popup ${isOnNomineeDetails ? "" : "centered"}`}
          children={renderCustomPopupChildren()}
        />
      )}
      {isRelationshipDetailsOpen && (
        <CustomPopup
          open={open}
          handleOpen={() => {
            setIsOnNomineeDetails(true);
            setIsRelationshipDetailsOpen(false);
            setSelectedRadioValue(selectedRadioValue);
          }}
          btnLabel={"Close"}
          btnStartIcon={<CloseIcon sx={{ width: "1rem", height: "1rem" }} />}
          className={`add-manual-popup ${
            isRelationshipDetailsOpen ? "" : "centered"
          }`}
          children={
            <Box>
              <Typography textAlign="center" variant="h5">
                SELECT RELATIONSHIP WITH PROPOSER
              </Typography>
              <Box>
                <RadioGroup className="radio-group">
                  {options.map((item, index: number) => (
                    <Box key={item.value}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        paddingY="1.5rem"
                      >
                        <Typography variant="body1">{item.label}</Typography>
                        <Radio
                          checked={item.value === selectedRadioValue}
                          onClick={() => handleRadioBtnClick(item)}
                          sx={{
                            padding: 0,
                            color: Colors.red,
                            "&.Mui-checked": {
                              color: Colors.red,
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: 20,
                            },
                          }}
                        />
                      </Box>
                      {options.length - 1 > index && (
                        <Divider sx={{ color: "#F7F7F7" }} />
                      )}
                    </Box>
                  ))}
                </RadioGroup>
                <Box>
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={!selectedRadioValue}
                    className="continue-btn"
                    onClick={handleRelationshipContinueClick}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          }
        />
      )}
      {showSuccessPopup && (
        <CustomPopup
          open={open}
          handleOpen={() => {
            setShowSuccessPopup(false);
            handleClick();
          }}
          children={
            <Box
              className="common-success-popup-body"
              marginBottom={"2rem"}
              marginLeft={"1rem"}
              marginRight={"1rem"}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={"13.5625rem"}
              >
                <CommonGifAnimation
                  width="100%"
                  animationData={HealthSuccessGif as T_AnimationData}
                />
              </Box>
              <Typography
                marginTop="1rem"
                marginBottom={"1rem"}
                textAlign="center"
                variant="h3"
              >
                {"Nominee details changed successfully!"}
              </Typography>
              <Button
                fullWidth
                variant="contained"
                className="popup-btn"
                onClick={handleClick}
              >
                {"Go back to Edit Policy"}
              </Button>
            </Box>
          }
        />
      )}
    </>
  );
};

export default HiEditNomineeDetails;
