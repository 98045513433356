import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useCallback, useState } from "react";
import { Colors } from "../../../../styles/constant";
import DeleteIcon from "../../../../assets/icons/mdi_bin.svg";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";
import { tabViewPoint } from "../../../../utils/constants";
import useWindowSize from "../../../../hooks/useWindowSize";
import CloseIcon from "@mui/icons-material/Close";
import CommonSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";

type EAO = {
  policyNumber: string;
  subContent?: any | null;
  contents: object[];
};
interface ExistingAlertProps {
  data: EAO[];
}

const CustomExistingAlert: FC<ExistingAlertProps> = ({ data = [] }) => {
  const handleDelete = useCallback(
    (value: EAO) => {
      console.log(value, "values");
      handleOpen();
    },
    [data]
  );
  const { width } = useWindowSize();
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const handleOpen = useCallback(() => {
    setShowPopup((prevState) => !prevState);
    setIsSuccess(false);
  }, []);
  const handleSubmit = useCallback(() => {
    setIsSuccess(true);
  }, []);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  return (
    <Box className="cea-parent-box" sx={{ width: "100%" }}>
      <Typography variant="h4">Alerts</Typography>
      {data.length > 0 &&
        data.map((content) => {
          console.log(content, "content");
          return (
            <Box className="cea-box">
              <Box className="cea-box-header">
                <Box>
                  <Typography variant="h5">Policy Number</Typography>
                  <Typography variant="h5" className="cea-box-value">
                    {content.policyNumber}
                  </Typography>
                </Box>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleDelete(content)}
                >
                  <img src={DeleteIcon} alt="icon" />
                </Box>
              </Box>
              {content.subContent !== null && (
                <Box className="cea-subcontent">
                  <Typography variant="h4">
                    {content.subContent?.label}
                  </Typography>
                  <Typography variant="h5" className="cea-box-value">
                    {content.subContent?.value}
                  </Typography>
                </Box>
              )}
              <Box className="cea-content">
                {content.contents.length > 0 &&
                  content.contents.map((data: any) => {
                    return (
                      <Box>
                        <Typography variant="h4">{data.label}</Typography>
                        <Typography variant="h5" className="cea-box-value">
                          {data.value}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
          );
        })}
      <CustomPopup
        open={showPopup}
        handleOpen={handleOpen}
        className={`set-alert-popup ${width > tabViewPoint ? "centered" : ""}`}
        btnLabel="Close"
        btnStartIcon={
          <CloseIcon sx={{ fontSize: "1rem", color: Colors.red }} />
        }
      >
        {isSuccess ? (
          <SetExistingAlertSuccess message="Selected Alert has been removed" />
        ) : (
          <Box className={`set-alert-popup-confirm`}>
            <Typography variant="h5" className="cea-popup-title">
              CONFIRM DELETE
            </Typography>
            <Typography variant="h5" className="cea-popup-subtitle">
              Are you sure you want to delete the selected alerts?
            </Typography>
            <Box className="li-existing-alert-delete-box">
              <Button
                fullWidth
                variant="contained"
                className={`li-existing-alert-submit-button li-existing-alert-disabled-button`}
                onClick={handleOpen}
                disableFocusRipple
                sx={{ boxShadow: "none !important" }}
              >
                <Typography variant="h5">Cancel</Typography>
              </Button>
              <Button
                fullWidth
                variant="contained"
                className={`li-existing-alert-submit-button`}
                onClick={handleSubmit}
                disableFocusRipple
                sx={{ boxShadow: "none !important" }}
              >
                <Typography variant="h5">Confirm</Typography>
              </Button>
            </Box>
          </Box>
        )}
      </CustomPopup>
    </Box>
  );
};

export default CustomExistingAlert;

export const SetExistingAlertSuccess = ({ message = "" }) => {
  const handleRateUs = useCallback(() => {
    alert("Rate Us");
  }, []);
  return (
    <CommonSuccessPopupBody
      title="Success"
      des={message}
      btnLabel="Rate Your Experience"
      btnOnClick={handleRateUs}
    />
  );
};
