import { Box, Stack, Tab, Tabs } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import StocksAndSecuritiesCard from "../../features/Feature_StocksAndSecurities/Stocks&SecuritiesCard/StocksAndSecuritiesCard";
import CsSessionStorageUtils from "../../utils/CsSessionStorageUtils";
import {
  DashboardTabKeys,
  HiLobCode,
  HlLobCode,
  LiLobCode,
  MfLobCode,
  StockLobCode,
  tabViewPoint,
} from "../../utils/constants";
import MfDashboard from "../../features/Feature_MutualFund/Dashboard/MfDashboard";
import { LiDashboard } from "../../features/Feature_LifeInsurance/Dashboard/LI_Dashboard";
import { HiDashboard } from "../../features/Feature_HealthInsurance/Dashboard/HI_Dashboard";
import useWindowSize from "../../hooks/useWindowSize";
import HomeloanCard from "../../features/Feature_Homeloan/HomeLoanCard/HomeloanCard";
import HlServiceRequestHome from "../../features/Feature_Homeloan/HomeloanServiceDetails/HlServiceRequestHome";
import {
  setModuleList,
  setParentModuleList,
  setSelectedModule,
  setSelectedTab,
} from "../../features/Feature_GeneralAppElements/GeneralAppElements_Slice";
import {
  T_FaqModuleItem,
  T_FaqModuleItemList,
  T_FaqParentModuleItem,
  T_Fetch_FaqModules_Response,
} from "../../features/Feature_GeneralAppElements/GeneralAppElements_Types";
import { isEmpty } from "lodash";

interface Props {
  setActiveTab: Function;
}

const DashboardTabs: FC<Props> = ({ setActiveTab }) => {
  const [activeDashboardTab, setActiveDashboardTab] =
    useState<DashboardTabKeys>(DashboardTabKeys.Investment);
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const parentModuleList = useAppSelector(
    (state) => state.GeneralAppElementsSlice.parentModuleList
  );
  const appDispatch = useAppDispatch();
  const customerAndLobDetails = userDetails?.customerAndLobDetails;
  const { width } = useWindowSize();
  // const [fetchFaqModuleApi] = useLazyFetchFaqModulesQuery();

  const handleDashboardChange = (newValue: string) => {
    setActiveDashboardTab(newValue as DashboardTabKeys);
    CsSessionStorageUtils.set("ACTIVE_DASHBOARD_TAB", newValue);
    setActiveTab(newValue);
  };

  useEffect(() => {
    setSelectedTab(activeDashboardTab);
    appDispatch(setSelectedModule(""));
    let moduleList: any = [];
    if (parentModuleList.length > 0) {
      parentModuleList.find((data: T_FaqParentModuleItem) => {
        if (activeDashboardTab === data.attributes.parentModuleName) {
          moduleList.push(data.attributes.modules);
        }
      });
    }
    setModuleList(moduleList);
  }, [activeDashboardTab]);

  const lobTabVisibility = useMemo(() => {
    const lobVisibility = new Map<string, boolean>();
    lobVisibility.set(LiLobCode, false);
    lobVisibility.set(HiLobCode, false);
    lobVisibility.set(StockLobCode, false);
    lobVisibility.set(MfLobCode, false);
    lobVisibility.set(HlLobCode, false);
    customerAndLobDetails?.forEach((item) => {
      if (
        (item.LOB === MfLobCode && item.PRIMARY_SECONDARY_USER === "primary") ||
        (item.LOB === MfLobCode && item.PRIMARY_SECONDARY_USER === "secondary")
      ) {
        lobVisibility.set(MfLobCode, true);
      } else if (item.LOB === StockLobCode) {
        lobVisibility.set(StockLobCode, true);
      } else if (
        item.LOB === LiLobCode &&
        (item.PRIMARY_SECONDARY_USER === "primary" ||
          item.PRIMARY_SECONDARY_USER === "secondary")
      ) {
        lobVisibility.set(LiLobCode, true);
      } else if (
        item.LOB === HiLobCode &&
        (item.PRIMARY_SECONDARY_USER === "primary" ||
          item.PRIMARY_SECONDARY_USER === "secondary")
      ) {
        lobVisibility.set(HiLobCode, true);
      } else if (item.LOB === HlLobCode) {
        lobVisibility.set(HlLobCode, true);
      }
    });
    const previouslySetActiveTab = CsSessionStorageUtils.get(
      "ACTIVE_DASHBOARD_TAB"
    );
    if (isEmpty(previouslySetActiveTab)) {
      // console.log("in here");
      if (lobVisibility?.get(MfLobCode) || lobVisibility?.get(StockLobCode)) {
        handleDashboardChange(DashboardTabKeys.Investment);
      } else if (
        lobVisibility?.get(LiLobCode) ||
        lobVisibility?.get(HiLobCode)
      ) {
        handleDashboardChange(DashboardTabKeys.Insurance);
      } else if (lobVisibility?.get(HlLobCode)) {
        handleDashboardChange(DashboardTabKeys.Loans);
      }
    } else {
      setActiveDashboardTab(previouslySetActiveTab);
      setActiveTab(previouslySetActiveTab || "");
    }
    return lobVisibility;
  }, [customerAndLobDetails, userDetails?.pan]);

  // const fetchFaqModuleList = useCallback((value: string) => {
  //   fetchFaqModuleApi(value).then((res) => {
  //     console.log(JSON.stringify(res), res, "response");
  //     if (res?.data?.code === 1) {
  //     } else {
  //     }
  //   });
  // }, []);

  return (
    <Box>
      <Tabs
        value={activeDashboardTab}
        onChange={(event, newValue) => handleDashboardChange(newValue)}
        centered={width >= tabViewPoint ? true : false}
      >
        {(lobTabVisibility.get(MfLobCode) ||
          lobTabVisibility.get(StockLobCode)) && (
          <Tab label="Investment" value={DashboardTabKeys.Investment} />
        )}
        {(lobTabVisibility.get(LiLobCode) ||
          lobTabVisibility.get(HiLobCode)) && (
          <Tab label="Insurance" value={DashboardTabKeys.Insurance} />
        )}
        {lobTabVisibility.get(HlLobCode) && (
          <Tab label="Loans" value={DashboardTabKeys.Loans} />
        )}
      </Tabs>
      <Box>
        {activeDashboardTab === DashboardTabKeys.Insurance && (
          <Stack spacing="1rem" marginTop={"1rem"}>
            {lobTabVisibility.get(LiLobCode) && (
              <LiDashboard moduleName={"life-insurance"} />
            )}
            {lobTabVisibility.get(HiLobCode) && (
              <HiDashboard moduleName={"health-insurance"} />
            )}
          </Stack>
        )}
        {activeDashboardTab === DashboardTabKeys.Investment && (
          <Stack spacing="1rem" marginTop={"1rem"}>
            {lobTabVisibility.get(MfLobCode) && <MfDashboard />}
            {lobTabVisibility.get(StockLobCode) && <StocksAndSecuritiesCard />}
          </Stack>
        )}
        {activeDashboardTab === DashboardTabKeys.Loans && (
          <Stack spacing="1rem" marginTop={"1rem"}>
            {lobTabVisibility.get(HlLobCode) && (
              <HomeloanCard moduleName={"home-loan"} />
            )}
            <HlServiceRequestHome />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default DashboardTabs;
