import { RouteObject } from "react-router-dom";
import Feature_SecondaryChannel from "./Feature_SecondaryChannel";

export enum R_SecondaryChannelRoutes {
  fromSecondaryChannel = "/channel",
}

const SecondaryChannelRoutes: RouteObject[] = [
  {
    path: R_SecondaryChannelRoutes.fromSecondaryChannel,
    element: <Feature_SecondaryChannel />,
  },
];

export default SecondaryChannelRoutes;
