import {
  T_FetchPremiumReceiptWithDateResponse_Details,
  T_RwsDetails,
} from "../features/Feature_LifeInsurance/LifeInsurance_Types";
import {
  CustomerRequest,
  CustomerRequestUplaod,
  T_SummaryData,
} from "../features/Feature_Homeloan/Homeloan_Types";
import {
  FooterDetailsList,
  dobRegex,
  monthsList,
  panRegex,
  phoneRegex,
} from "./constants";
import CryptoJS from "crypto-js";
import { SignJWT } from 'jose';
import { currencyConvertor } from "./conversionUtils";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { R_HomeRoutes } from "../pages/Home/Home_Routes";
import {
  J_FunctionalityCode, UserPayload,
  T_CustomerLobDetails,
  T_LogoutInfoToBePassed,
  T_UserProfile,
} from "../globalTypes/GlobalTypes";
import {
  APi_DownloadPolicyAccountStatement,
  Api_DownloadTaxCertificate,
  Api_FetchPolicyDetailsByMobileNumber,
  Api_GetCustomerLobDetails,
  Api_SendEmail,
  Api_SendWhatsApp,
} from "./ApiEndpoints";
import { InternalAxiosRequestConfig } from "axios";
import { generatePath } from "react-router-dom";
import { PDFDocument } from "pdf-lib";
import { T_GenerateTokenRes } from "../features/Feature_Login/Login_Types";
import _ from "lodash";

dayjs.extend(customParseFormat);
const secretKey = new TextEncoder().encode('711c13ae-b4bb-4409-945e-c6152e44ebaf');
interface Option {
  label: string;
  value: string;
}

interface UniqueValues {
  yearOptionsList: Option[];
  monthOptionsList: Option[];
}
interface FileObject {
  fileName: string;
  fileExtension: string;
  base64: string;
}
export const defaultEmptyFun = () => {
  //This is intentional
};

export const timeDifferenceInDays = (
  initialDate: Date,
  finalDate: Date
): number => {
  const oneDay = 1000 * 60 * 60 * 24; // Number of milliseconds in a day
  const timeDifference = Math.abs(initialDate.getTime() - finalDate.getTime()); // Difference in milliseconds
  return Math.floor(timeDifference / oneDay); // Convert difference to days
};

// Utility function to validate PAN
export const isValidPAN = (pan: string = ""): boolean => {
  return panRegex.test(pan);
};
export const isValidMobile = (mobile: string = ""): boolean => {
  return phoneRegex.test(mobile);
};
// Utility function to validate DOB (in "DD Mon YYYY" format)
export const isValidDOB = (dob: string): boolean => {
  return dobRegex.test(dob);
};

export const fetchPolicyTermsDescriptionByTitle = (
  inputTitle: string
): string => {
  switch (inputTitle) {
    case FooterDetailsList.termsAndConditions.title: {
      return FooterDetailsList.termsAndConditions.description;
    }
    case FooterDetailsList.privacyPolicy.title: {
      return FooterDetailsList.privacyPolicy.description;
    }
    case FooterDetailsList.aboutUs.title: {
      return FooterDetailsList.aboutUs.description;
    }
    default: {
      return "";
    }
  }
};

export const encryptString = (payload: any, IV: string, secretKey: string) => {
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const salt = CryptoJS.enc.Utf8.parse(IV);
  return CryptoJS.AES.encrypt(payload, key, {
    iv: salt,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

export const decryptString = (
  data: string | null,
  IV: string,
  secretKey: string
) => {
  if (!data) {
    return "";
  }
  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const salt = CryptoJS.enc.Utf8.parse(IV);
  const decrypted = CryptoJS.AES.decrypt(data, key, {
    iv: salt,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

interface Option {
  label: string;
  value: string;
  isCustom?: boolean;
}

export const getFinancialYearOptions = ({ isCustom = false }): Option[] => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentFYStartYear = currentMonth >= 4 ? currentYear : currentYear - 1;
  const currentFYEndYear = currentFYStartYear + 1;
  const previousFYStartYear = currentFYStartYear - 1;
  const previousFYEndYear = currentFYStartYear;
  if (isCustom) {
    return [
      {
        label: `FY ${currentFYStartYear}-${currentFYEndYear
          .toString()
          .slice(2)}`,
        value: `FY ${currentFYStartYear}-${currentFYEndYear
          .toString()
          .slice(2)}`,
      },
      {
        label: `FY ${previousFYStartYear}-${previousFYEndYear
          .toString()
          .slice(2)}`,
        value: `FY ${previousFYStartYear}-${previousFYEndYear
          .toString()
          .slice(2)}`,
      },
      {
        label: `Custom`,
        value: `Custom`,
      },
    ];
  } else {
    return [
      {
        label: `FY ${currentFYStartYear}-${currentFYEndYear
          .toString()
          .slice(2)}`,
        value: `FY ${currentFYStartYear}-${currentFYEndYear
          .toString()
          .slice(2)}`,
      },
      {
        label: `FY ${previousFYStartYear}-${previousFYEndYear
          .toString()
          .slice(2)}`,
        value: `FY ${previousFYStartYear}-${previousFYEndYear
          .toString()
          .slice(2)}`,
      },
    ];
  }
};

// Utility function to convert financial year to start and end dates
// export const getFinancialYearDates = (
//   financialYear: string,
//   isPolicyStatement: boolean
// ) => {
//   const [startYear, endYear] = financialYear
//     .split("FY ")[1]
//     .split("-")
//     .map(Number);
//   const startDate = isPolicyStatement
//     ? `01-04-${startYear}`
//     : `01/04/${startYear}`;
//   const endDate = isPolicyStatement
//     ? `31-03-${2000 + endYear}`
//     : `31/03/${2000 + endYear}`;
//   return { startDate, endDate };
// };

export const getFinancialYearDates = (
  financialYear: string,
  isPolicyStatement: boolean
) => {
  const today = dayjs();
  const currentFinancialYear = getCurrentFinancialYear();

  const [startYear, endYear] = financialYear
    .split("FY ")[1]
    .split("-")
    .map(Number);

  const isCurrentFinancialYear = financialYear === currentFinancialYear;
  let formatType = isPolicyStatement ? "DD-MM-YYYY" : "DD/MM/YYYY";
  const startDate = dayjs(`${startYear}-04-01`).format(formatType);
  const endDate = isCurrentFinancialYear
    ? today.format(formatType)
    : dayjs(`${endYear + 2000}-03-31`).format(formatType);
  return { startDate, endDate };
};

export const getFinancialYearDatesForFullYear = (
  financialYear: string,
  isPolicyStatement: boolean
) => {
  const currentYear = dayjs().year();
  const currentDate = new Date();
  const [startYear, endYear] = financialYear.split("-").map(Number);
  const startDate = `${startYear}-04-01`;
  const endDate =
    endYear > currentYear
      ? currentDate.toLocaleDateString("en-CA")
      : `${endYear}-03-31`;
  return { startDate, endDate };
};

export const getLastTwoFinancialYears = () => {
  const currentYear = dayjs().year();
  let startDate = `01/04/${currentYear - 2}`;
  let endDate = dayjs().format("DD/MM/YYYY");
  // Calculate last two financial years
  return {
    startDate,
    endDate,
  };
};

export const downloadPdfFromBase64 = async (
  pdfData: string = "",
  fileName: string = "file.pdf"
) => {
  const byteCharacters = atob(pdfData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // Clean up the object URL after the download
  URL.revokeObjectURL(blobUrl);
};

export const extractPremiumReceiptValues = (
  dataArray: T_FetchPremiumReceiptWithDateResponse_Details[]
): UniqueValues => {
  const yearsMap: { [key: string]: boolean } = {};
  const monthsMap: { [key: string]: boolean } = {};
  const monthNames: string[] = monthsList;
  const yearOptionsList: Option[] = [];
  const monthOptionsList: Option[] = [];

  if (dataArray && dataArray.length) {
    dataArray.forEach((item) => {
      const dateParts = item.PROCESSDATE.split("/");
      const monthIndex = parseInt(dateParts[1], 10) - 1;
      const monthValue = monthNames[monthIndex];
      const yearValue = dateParts[2].substring(0, 4);
      if (!yearsMap[yearValue]) {
        yearOptionsList.push({ label: yearValue, value: yearValue });
        yearsMap[yearValue] = true;
      }
      if (!monthsMap[monthValue]) {
        monthOptionsList.push({ label: monthValue, value: monthValue });
        monthsMap[monthValue] = true;
      }
    });
    return { yearOptionsList, monthOptionsList };
  } else {
    return { yearOptionsList, monthOptionsList };
  }
};

export function extractReceiptDateAmount(
  selectedYear: string,
  selectedMonth: string,
  dataArray: T_FetchPremiumReceiptWithDateResponse_Details[]
) {
  const year = parseInt(selectedYear.trim());
  const month = selectedMonth.trim();
  // Initialize arrays for day and amount
  let filteredReceiptDate: Option[] = [];
  // let filteredReceiptAmount: Option[] = [];
  let finalFilteredReceiptDate: Option[] = [];
  // Iterate through dataArray
  dataArray.forEach((item) => {
    // Split the date string into components
    const parts = item.PROCESSDATE.split(/[\/ :]/);
    // Parse components into integers
    const day = parseInt(parts[0], 10);
    const monthIndex = parseInt(parts[1], 10) - 1; // Month is zero-indexed
    const yearValue = parseInt(parts[2], 10);
    const hour = parseInt(parts[3], 10);
    const minute = parseInt(parts[4], 10);
    const second = parseInt(parts[5], 10);
    // Create a new Date object using parsed components
    const processDate = new Date(
      yearValue,
      monthIndex,
      day,
      hour,
      minute,
      second
    );
    // Check if item matches year and month
    if (
      processDate.getFullYear() === year &&
      getMonthName(processDate.getMonth() + 1) === month
    ) {
      // Format day as "Day Month"
      const formattedDay = `${processDate.getDate()} ${processDate.toLocaleString(
        "en-US",
        { month: "long" }
      )}`;

      // Push to dayArray and amountArray
      filteredReceiptDate.push({
        label: formattedDay,
        value: formattedDay,
      });
      const uniqueSet = new Set();

      // Filter out duplicates based on 'label' and 'value'
      finalFilteredReceiptDate = filteredReceiptDate.filter((obj) => {
        const key = `${obj.label}_${obj.value}`;
        if (!uniqueSet.has(key)) {
          uniqueSet.add(key);
          return true;
        }
        return false;
      });

      // filteredReceiptAmount.push({
      //   label: currencyConvertor(item.RCPTAMOUNT.toFixed(2).toString()),
      //   value: currencyConvertor(item.RCPTAMOUNT.toFixed(2).toString()),
      // });
    }
  });
  return { finalFilteredReceiptDate };
}

export function extractReceiptAmountByDate(
  receiptDate: string,
  dataArray: T_FetchPremiumReceiptWithDateResponse_Details[]
) {
  let filteredReceiptAmount: Option[] = [];
  // Iterate through dataArray
  dataArray.forEach((item) => {
    // Extract day and month from PROCESSDATE
    const parts = item.PROCESSDATE.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    // Format day as "Day Month"
    const formattedDay = `${day} ${getMonthName(month)}`;
    // Check if formattedDay matches dateString
    if (formattedDay === receiptDate) {
      filteredReceiptAmount.push({
        label: currencyConvertor(item.RCPTAMOUNT.toFixed(2).toString()),
        value: currencyConvertor(item.RCPTAMOUNT.toFixed(2).toString()),
      });
    }
  });
  return filteredReceiptAmount;
}

// Function to get month name from month number, used in extractReceiptAmountByDate,extractReceiptDateAmount,extractPremiumReceiptValues
function getMonthName(monthNumber: number) {
  const months = monthsList;
  return months[monthNumber - 1]; // Month array is 0-indexed
}

export function extractDownloadablePremiumReceipt(
  year: string,
  month: string,
  date: string,
  amount: string,
  dataArray: T_FetchPremiumReceiptWithDateResponse_Details[]
) {
  const monthLower = month.toLowerCase();
  const targetDate = new Date(`${year}-${monthLower}-${date}`);

  return dataArray.filter((item) => {
    const processDate = new Date(
      item.PROCESSDATE.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1")
    );

    return (
      processDate.getFullYear() === targetDate.getFullYear() &&
      processDate.getMonth() === targetDate.getMonth() &&
      processDate.getDate() === targetDate.getDate()
    );
  });
}

export const getFileSize = (file: File) => {
  const fileSizeInBytes = file.size;
  const fileSizeInKB = fileSizeInBytes / 1024; // Convert bytes to KB
  const fileSizeInMB = fileSizeInKB / 1024; // Convert KB to MB
  let sizeToShow;
  if (fileSizeInMB >= 1) {
    sizeToShow = `${fileSizeInMB.toFixed(2)}mb`;
  } else {
    sizeToShow = `${fileSizeInKB.toFixed(2)}kb`;
  }
  return sizeToShow;
};

const parseDate = (dateString: string) => dayjs(dateString, "DD/MM/YYYY");

export const getLatestDateData = (data: T_RwsDetails[]) => {
  if (data.length > 0) {
    return data.reduce(
      (maxObject: T_RwsDetails, currentObject: T_RwsDetails) => {
        const maxDate = maxObject ? parseDate(maxObject.process_date) : null;
        const currentDate = parseDate(currentObject.process_date);
        if (!maxDate || currentDate.isAfter(maxDate)) {
          return currentObject;
        } else {
          return maxObject;
        }
      }
    );
  } else {
    return undefined;
  }
};

export const getInitials = (name: string = "") => {
  if (name) {
    const parts = name.split(" ");
    let initials = [""];
    if (parts?.length > 1) {
      initials = [parts[0]?.charAt(0), parts[parts.length - 1]?.charAt(0)];
    } else {
      initials = [parts[0]?.charAt(0)];
    }
    return initials.join("").toUpperCase(); // Convert to uppercase if needed
  }
};

export const propagateEvent = (eventName: string, data: any) => {
  console.log("Event", eventName, "\nData", data);
  // @ts-ignore: Window should have flutter_inappwebview variable
  window?.flutter_inappwebview?.callHandler(eventName, data);
};

export const getMaskedMobileNumber = (
  mobileNumber: string = "",
  symbol: string = "X"
) => {
  if (mobileNumber.length === 13) {
    return mobileNumber.slice(0, 5) + symbol.repeat(6) + mobileNumber.slice(-2);
  }
  if (mobileNumber) {
    return mobileNumber.slice(0, 2) + symbol.repeat(6) + mobileNumber.slice(-2);
  }
  return "";
};

export const getMaskedEmail = (email: string) => {
  const [localPart, domain] = email.toLowerCase().split("@");

  if (!domain) return email; // In case of invalid email format

  const [domainName, domainExt] = domain.split(".");

  const maskedLocalPart =
    localPart.length > 4
      ? `${localPart.slice(0, 2)}${"X".repeat(localPart.length - 2)}`
      : localPart;

  const maskedDomainName =
    domainName.length > 2
      ? `${domainName.slice(0, 2)}${"x".repeat(domainName.length - 2)}`
      : domainName;

  return `${maskedLocalPart}@${maskedDomainName}.${domainExt.slice(
    0,
    1
  )}${"x".repeat(domainExt.length - 1)}`;
};

export const isValidateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getRouteFromFuncCode = (
  functionalityCode: string,
  fallbackIfNotFound = true,
  fallbackRoute: string = R_HomeRoutes.home
) => {
  return J_FunctionalityCode.find((f) => f.funcCode === functionalityCode)
    ?.route || fallbackIfNotFound
    ? fallbackRoute
    : undefined;
};

export const getStaticReqPayload = (config: InternalAxiosRequestConfig) => {
  switch (config?.url || "") {
    case Api_GetCustomerLobDetails:
      return { mobile: "9228417017" }; // 9437473531
    case Api_FetchPolicyDetailsByMobileNumber:
      return { mobileNumber: "9730896906" }; //"8903263193"
    case Api_DownloadTaxCertificate:
      return {
        ...config.data,
        policyId: "007255785",
        startDate: "01/04/2020",
        endDate: "17/02/2022",
      };
    case APi_DownloadPolicyAccountStatement:
      return {
        ...config.data,
        policyId: "001572067",
        startDate: "13-05-2019",
        endDate: "25-05-2019",
      };
    case Api_SendEmail:
    case Api_SendWhatsApp:
      return {
        ...config.data,
        policyNo: "007255785",
        fromDate: "01/04/2020",
        toDate: "17/02/2022",
      };
    default:
      return config.data;
  }
};

export const getValueOfRenewalPayment = (data: string = "") => {
  return data.substring(1, data.indexOf(","));
};

export const getCurrentDateTimeStamp = () => {
  // < dd/MM/yyyy HH:MM:SS PM>
  return new Date().toLocaleString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
};

export const isPositiveNumber = (str: any) => {
  // Check if the string is not empty and contains numeric data
  const num = parseFloat(str);
  if (!isNaN(num) && num > 0) {
    return true;
  }
  return false;
};

export const isZeroString = (value: string): boolean =>
  !isNaN(parseFloat(value.trim())) && parseFloat(value.trim()) === 0;
export const downloadExcelFromBase64 = async (
  excelData: string = "",
  fileName: string = "file.xlsx"
) => {
  // Decode base64 data to byte array
  const byteCharacters = atob(excelData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the byte array with MIME type for Excel (.xlsx)
  const blob = new Blob([byteArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create object URL for the Blob
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName;

  // Append link to the body, click it, and then remove it
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up the object URL after the download
  URL.revokeObjectURL(blobUrl);
};

export const injectDynamicValueInFuncnalityCodeUrls = (
  urlSearchParam: URLSearchParams | undefined,
  functionalityCode: string | undefined = "D"
) => {
  const routeDetails = J_FunctionalityCode.find(
    (f) => f.funcCode === functionalityCode
  );
  let injectDataInDynamicPath = routeDetails?.route;
  if (routeDetails?.isDynamicPath) {
    routeDetails.dynamicKeys.forEach((dynamicKey) => {
      const paramValue = urlSearchParam?.get(dynamicKey) || null;
      if (paramValue == null) {
        return R_HomeRoutes.home;
      }
      injectDataInDynamicPath = generatePath(routeDetails.route, {
        [dynamicKey]: paramValue,
      });
    });
  }
  return injectDataInDynamicPath;
};

export const injectDynamicValueInFuncnalityCodeUrls_ssoRedirect = (
  responseDataList: Map<string, string>,
  functionalityCode: string | undefined = "D"
) => {
  const routeDetails = J_FunctionalityCode.find(
    (f) => f.funcCode === functionalityCode
  );
  let injectDataInDynamicPath = routeDetails?.route;
  if (routeDetails?.isDynamicPath) {
    routeDetails.dynamicKeys.forEach((dynamicKey) => {
      const paramValue = responseDataList?.get(dynamicKey) || null;
      if (paramValue == null) {
        return R_HomeRoutes.home;
      }
      injectDataInDynamicPath = generatePath(routeDetails.route, {
        [dynamicKey]: paramValue,
      });
    });
  }
  return injectDataInDynamicPath;
};

export const filterMonthsByYear = (
  data: T_FetchPremiumReceiptWithDateResponse_Details[],
  year: string
) => {
  // Create a Set to store unique months
  const uniqueMonths = new Set();

  // Filter data based on the provided year and collect unique months
  data.forEach((obj) => {
    const processDate = obj.PROCESSDATE;
    const parts = processDate.split("/");
    const objYear = parts[2].substring(0, 4); // Extract year from PROCESSDATE as string
    const objMonth = parseInt(parts[1], 10); // Extract month from PROCESSDATE

    if (objYear === year) {
      uniqueMonths.add(objMonth);
    }
  });

  // Convert month numbers to month names and create label-value pairs
  const monthNames = Array.from(uniqueMonths).map((month) => {
    const monthName = new Date(
      Number(year),
      Number(month) - 1,
      1
    ).toLocaleString("en-US", {
      month: "long",
    });
    return { label: monthName, value: monthName };
  });

  return { monthNames };
};

export const getCaseCreateReqPayload = (
  data: Object,
  endorsementDetailData: Object
) => {
  let EndorsementDetails = {
    AadharCardNo: null,
    AlternateContactNumber: null,
    AlternateEmail: null,
    Country: null,
    Email: null,
    InternationalAddress: null,
    InternationalContactNumber: null,
    MemberAlternateContactNumber: null,
    MemberAlternateEmail: null,
    MemberEmail: null,
    MemberPrimaryContactNumber: null,
    NomineeContact: null,
    NomineeName: null,
    NomineeRelationship: null,
    PED: null,
    PanCardNo: null,
    PrimaryContactNumber: null,
  };
  let details = {
    ActivityDescription: null,
    ActivitySubject: null,
    AssignedTeam: "Endorsement- Non financial",
    AssignedUser: null,
    AttachmentContent: null,
    AttachmentType: null,
    AttachmentName: null,
    BankAccountNumber: null,
    BrokerCode: null,
    CaseId: null,
    CaseSubSubType: null,
    CaseSubType: "Endorsement",
    CaseSubTypeCode: null,
    CaseTitle: null,
    CaseType: "Endorsement",
    Category: "Request",
    Comments: null,
    CreatedOn: null,
    Customer: "PT87307294",
    CustomerID: "",
    CustomerType: "",
    EmailAddress: null,
    EndorsementDetails: EndorsementDetails,
    IsClosed: "No",
    Mobile: null,
    ModifiedOn: null,
    NotesDescription: null,
    NotesTitle: null,
    OneABCCustomerTAT: null,
    OneABCOwner: null,
    OneABCSubSource: null,
    Origin: "ABC_Servicing_App1",
    Partner: null,
    PartnerCaseId: null,
    PartnerStatus: null,
    PartnerSubStatus: null,
    Policy: null,
    PolicyStatus: null,
    Priority: null,
    Product: null,
    RedFlag: null,
    Status: null,
    URL: null,
  };
  let v1 = Object.assign(details, data);
  let v2 = Object.assign(EndorsementDetails, endorsementDetailData);
  v1["EndorsementDetails"] = v2;
  return v1;
};
export const getFinancialYearOptionForCapitalGain = () => {
  const today = dayjs();
  const currentMonth = today.month(); // January is 0, February is 1, etc.
  const currentYear = today.year();

  // Determine the start of the current financial year
  let startYear = currentYear;
  let endYear = currentYear + 1;

  // Adjust the start year if the current month is before April
  if (currentMonth < 3) {
    // Months are zero-indexed: 3 is April
    startYear -= 1;
    endYear -= 1;
  }
  const financialYears = [];
  // Generate the past T-3 financial years
  for (let i = 0; i < 4; i++) {
    const fyStart = startYear - i;
    const fyEnd = fyStart + 1;
    const fyLabel = `FY ${String(fyStart).slice(-2)}-${String(fyEnd).slice(
      -2
    )}`;
    financialYears.push({ label: fyLabel, value: fyLabel });
  }
  financialYears.push({
    label: "All Financial Years",
    value: "All Financial Years",
  });
  return financialYears;
};

const parseFinancialYear = (fy: string) => {
  const yearMatch = fy.match(/^FY (\d{2})-(\d{2})$/);
  if (yearMatch) {
    const startYear = `20${yearMatch[1]}`;
    const endYear = `20${yearMatch[2]}`;
    return { startYear, endYear };
  }
  return { startYear: "", endYear: "" };
};

// Calculate the start and end dates for the given financial year
export const getFinancialYearDatesForCapitalGain = (fy: string) => {
  const { startYear, endYear } = parseFinancialYear(fy);
  const today = dayjs();
  if (startYear && endYear) {
    const startDate = dayjs(`${startYear}-04-01`);
    let endDate = dayjs(`${endYear}-03-31`);
    // Adjust endDate if the financial year is the current one
    if (
      (today.isSame(startDate, "day") || today.isAfter(startDate, "day")) &&
      today.isBefore(endDate, "day")
    ) {
      endDate = today;
    }
    return {
      startDate: startDate.format("DD-MM-YYYY"),
      endDate: endDate.format("DD-MM-YYYY"),
    };
  }
  return { startDate: "", endDate: "" };
};
export const getPreviousFinancialYear = (yearsAgo: number) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const startYear = currentMonth >= 3 ? currentYear : currentYear - 1;
  const startPreviousYear = startYear - yearsAgo;
  const endPreviousYear = startPreviousYear + 1;
  return `FY ${startPreviousYear}-${String(endPreviousYear).slice(-2)}`;
};

export const getCurrentFinancialYear = (): string => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const startYear = currentMonth >= 3 ? currentYear : currentYear - 1;
  const endYear = startYear + 1;
  return `FY ${startYear}-${String(endYear).slice(-2)}`;
};

export const formatDate = (dateStr: string): string => {
  let dateObj = new Date(dateStr);

  const monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let day: number = dateObj.getDate();
  let month: string = monthNames[dateObj.getMonth()];
  let year: string = dateObj.getFullYear().toString().slice(-2);

  let daySuffix: string;
  if (day > 3 && day < 21) {
    daySuffix = "th";
  } else {
    switch (day % 10) {
      case 1:
        daySuffix = "st";
        break;
      case 2:
        daySuffix = "nd";
        break;
      case 3:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
    }
  }
  // Extract and format time components
  let hours: number = dateObj.getHours();
  let minutes: number = dateObj.getMinutes();
  let ampm: string = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // The hour '0' should be '12'
  let formattedMinutes: string =
    minutes < 10 ? "0" + minutes : minutes.toString();

  let time: string = `${hours}:${formattedMinutes} ${ampm}`;

  return `${day}${daySuffix} ${month}, ${year} | ${time}`;
};

export const getBase64 = async (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result?.toString().split(",")[1]);
    };
    reader.onerror = reject;
  });
};

export const getCustomerRequest = (
  changeData: Object,
  summaryData: T_SummaryData,
  option: string
) => {
  const customerRequest: CustomerRequest = {
    customerId: summaryData.account_detail.rows[0].CUSTOMER_NO,
    accountNumber: summaryData?.Benificiary_Details?.rows[0]?.LOAN_ACCOUNT_NO,
    firstName: summaryData.account_detail.rows[0].FNAME,
    lastName: summaryData.account_detail.rows[0].LNAME,
    mobile: summaryData.borrower_detail.rows[0].MOBILE,
    emailId: summaryData.borrower_detail.rows[0].E_MAIL,
    subject: "",
    source: "ABC_Servicing_App",
    comments: "",
    subtype: "RSTAWEITHFC",
    isClosed: true,
    url: "",
    category: "Request",
    lob: "HFC",
    priority: "Low",
    segment: "Finance",
    callbackDate: "",
    callbackTimeSlot: "",
    stpsRequestType: option,
    asOnDate: "",
    salaryCreditDate: "",
    dueDate: "",
    changeInEmiAmount: "",
    payableCharge: "",
    partPaymentAmount: "",
    impact: "",
    modeOfRepayment: "",
    soaFromDate: "",
    soaToDate: "",
    icFromYear: "",
    icToYear: "",
    attachments: [
      {
        attachmentName: "",
        attachmentType: "",
        attachmentContent: "",
        comments: "",
      },
    ],
  };
  let request = Object.assign(customerRequest, changeData);
  return request;
};

export const getCustomerRequestForRepayment = (
  changeData: Object,
  summaryData: T_SummaryData,
  option: string
) => {
  const attachments = [
    {
      attachmentName: "",
      attachmentType: "",
      attachmentContent: "",
      comments: "",
    },
  ];
  const customerRequest: CustomerRequest = {
    customerId: summaryData.account_detail.rows[0].CUSTOMER_NO,
    accountNumber: summaryData?.Benificiary_Details?.rows[0]?.LOAN_ACCOUNT_NO,
    firstName: summaryData.account_detail.rows[0].FNAME,
    lastName: summaryData.account_detail.rows[0].LNAME,
    mobile: summaryData.borrower_detail.rows[0].MOBILE,
    emailId: summaryData.borrower_detail.rows[0].E_MAIL,
    subject: "Amortization schedule",
    source: "ABC_Servicing_App",
    comments:
      "What is the difference between Semi-Fixed & Floating Rate of Interest?",
    subtype: "hfl004",
    isClosed: true,
    url: "",
    category: "Request",
    lob: "HFC",
    priority: "LOW",
    segment: "Finance",
    callbackDate: "",
    callbackTimeSlot: "",
    stpsRequestType: option,
    asOnDate: "",
    salaryCreditDate: "",
    dueDate: "",
    changeInEmiAmount: "",
    payableCharge: "",
    partPaymentAmount: "",
    impact: "",
    modeOfRepayment: "",
    soaFromDate: "",
    soaToDate: "",
    icFromYear: "",
    icToYear: "",
    attachments: attachments,
  };
  let request = Object.assign(customerRequest, changeData);
  return request;
};
export const getCustomerRequestForDocumentUpload = (
  changeData: Object,
  summaryData: T_SummaryData,
  fileObjects: Array<FileObject>
  // fileName: string,
  // fileExtension: string,
  // base64: string
) => {
  const attachments = fileObjects.map((fileObject) => ({
    attachmentName: fileObject.fileName,
    attachmentType: fileObject.fileExtension,
    attachmentContent: fileObject.base64,
    comments: "no comment",
  }));
  const customerRequest: CustomerRequestUplaod = {
    customerId: summaryData.account_detail.rows[0].CUSTOMER_NO,
    accountNumber: summaryData?.Benificiary_Details?.rows[0]?.LOAN_ACCOUNT_NO,
    firstName: summaryData.account_detail.rows[0].FNAME,
    lastName: summaryData.account_detail.rows[0].LNAME,
    mobile: summaryData.borrower_detail.rows[0].MOBILE,
    emailId: summaryData.borrower_detail.rows[0].E_MAIL,
    subject: "Account related-SwapMandate",
    source: "ABC_Servicing_App",
    comments: "",
    subtype: "RREPSWNTHFC",
    isClosed: false,
    url: "",
    category: "Request",
    lob: "HFC",
    priority: "LOW",
    segment: "Finance",
    callbackDate: "",
    callbackTimeSlot: "",
    stpsRequestType: "",
    asOnDate: "",
    salaryCreditDate: "",
    dueDate: "",
    changeInEmiAmount: "",
    payableCharge: "",
    partPaymentAmount: "",
    impact: "",
    modeOfRepayment: "",
    soaFromDate: "",
    soaToDate: "",
    icFromYear: "",
    icToYear: "",
    attachments: attachments,
  };
  let request = Object.assign(customerRequest, changeData);
  return request;
};

export const getReportingServiceRequest = (
  changeData: Object,
  summaryData: T_SummaryData,
  caseId: string
) => {
  const reportingRequest = {
    caseId: caseId,
    source: "CRM",
    requestType: "View",
    lan: summaryData.Benificiary_Details.rows[0].LOAN_ACCOUNT_NO,
    reportName: "BKL",
    documentType: "BinaryData",
    asOnDate: "",
    fromDate: "",
    toDate: "",
  };

  let request = Object.assign(reportingRequest, changeData);
  return request;
};

export function generatePlTraceId() {
  const min = 10000000;
  const max = 99999999;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  const bytes = new Uint8Array(buffer);
  let binaryString = "";
  for (let i = 0; i < bytes.byteLength; i++) {
    binaryString += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binaryString);
};

const base64ToUint8Array = (base64: string) => {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

const uint8ArrayToBase64 = (uint8Array: Uint8Array): string => {
  const binaryString = Array.from(uint8Array)
    .map((byte) => String.fromCharCode(byte))
    .join("");
  return window.btoa(binaryString);
};

const convertImageToPdf = async (
  imageBase64: string,
  isJpg: boolean = false
): Promise<Uint8Array> => {
  const pdfDoc = await PDFDocument.create();
  const imageBytes = base64ToUint8Array(imageBase64);
  let image;
  if (isJpg) {
    image = await pdfDoc.embedJpg(imageBytes); // Use embedJpg for JPEG images
  } else {
    image = await pdfDoc.embedPng(imageBytes); // Use embedPng for PNG images
  }
  const { width, height } = image.scale(1); // Scaling to keep original size
  const page = pdfDoc.addPage([width, height]);
  page.drawImage(image, {
    x: 0,
    y: 0,
    width: width,
    height: height,
  });

  return pdfDoc.save();
};

export const mergePdfs = async (
  pdfBase64: string,
  otherBase64: string,
  isJpg: boolean = false
): Promise<string> => {
  try {
    let pdfDoc1;
    if (pdfBase64) {
      pdfDoc1 = await PDFDocument.load(base64ToUint8Array(pdfBase64));
    }

    let pdfDoc2;
    // Check if the other file is a PDF or an image
    try {
      pdfDoc2 = await PDFDocument.load(base64ToUint8Array(otherBase64));
    } catch (e) {
      // If it's not a PDF, assume it's an image and convert it
      const imagePdfBytes = await convertImageToPdf(otherBase64, isJpg);
      pdfDoc2 = await PDFDocument.load(imagePdfBytes);
    }

    // Create a new PDF document
    const mergedPdf = await PDFDocument.create();

    // Copy pages from the first PDF
    let copiedPages1;
    if (pdfDoc1) {
      copiedPages1 = await mergedPdf.copyPages(
        pdfDoc1,
        pdfDoc1.getPageIndices()
      );
      copiedPages1.forEach((page) => mergedPdf.addPage(page));
    }

    // Copy pages from the second PDF
    const copiedPages2 = await mergedPdf.copyPages(
      pdfDoc2,
      pdfDoc2.getPageIndices()
    );
    copiedPages2.forEach((page) => mergedPdf.addPage(page));

    // Serialize the document to bytes
    const mergedPdfBytes = await mergedPdf.save();

    // Convert bytes to base64
    return uint8ArrayToBase64(new Uint8Array(mergedPdfBytes));
  } catch (error) {
    console.error("Error merging PDFs or converting images:", error);
    return "";
  }
};

export const maskEmail = (email: string) => {
  // Validate email format using regular expression
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return email;
  }

  // Split the email into local and domain parts
  const [localPart, domain] = email.split("@");

  // Mask the local part, keeping only the first and last characters visible
  const maskedLocalPart: string =
    localPart.length > 2
      ? `${localPart[0]}${"*".repeat(localPart.length - 2)}${
          localPart[localPart.length - 1]
        }`
      : localPart; // Handle short local parts without masking

  // Return the masked email
  return `${maskedLocalPart}@${domain}`;
};

export const base64ToTiffImageFormat = (
  base64: string
): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = `data:image/tiff;base64,${base64}`;
    img.onload = () => resolve(img);
  });
};


// Function to generate the JWT
export const generateJWT = async (payload : UserPayload) => {
  const jwt = await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .sign(secretKey);
  return jwt;
};

export const convertTiffToPng = (image: HTMLImageElement): Promise<string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) {
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      resolve(canvas.toDataURL("image/png"));
    } else {
      throw new Error("Failed to get canvas context");
    }
  });
};

export const getLogoutInfo = (
  tokenDetails: T_GenerateTokenRes,
  userDetails: T_UserProfile & T_CustomerLobDetails
) => {
  const ciiExists = userDetails?.customerAndLobDetails.some((r) => r.CII);
  return {
    token: "" + tokenDetails?.external.access_token,
    supportingData: {
      mobile: `${
        !_.isEmpty(userDetails?.mobileNumber) ? userDetails?.mobileNumber : ""
      }`,
      destinationLobId: "25",
      sourceLobId: "26",
      functionalityCode: "LO",
      isOnBoarding: ciiExists ? "Yes" : "No",
      isExtensionRequired: "No",
      pan: `${!_.isEmpty(userDetails?.pan) ? userDetails?.pan : ""}`,
      dob: `${
        !_.isEmpty(userDetails?.dob)
          ? userDetails?.dob?.replaceAll("/", "-")
          : ""
      }`,
      email: `${!_.isEmpty(userDetails?.emailId) ? userDetails?.emailId : ""}`,
    },
  };
};

export const goToSuperAppInfo = (
  tokenDetails: T_GenerateTokenRes,
  userDetails: T_UserProfile & T_CustomerLobDetails
) => {
  const ciiExists = userDetails?.customerAndLobDetails.some((r) => r.CII);
  return {
    token: "" + tokenDetails?.external.access_token,
    supportingData: {
      mobile: `${
        !_.isEmpty(userDetails?.mobileNumber) ? userDetails?.mobileNumber : ""
      }`,
      destinationLobId: "25",
      sourceLobId: "26",
      functionalityCode: "D",
      isOnBoarding: ciiExists ? "Yes" : "No",
      isExtensionRequired: "No",
      pan: `${!_.isEmpty(userDetails?.pan) ? userDetails?.pan : ""}`,
      dob: `${
        !_.isEmpty(userDetails?.dob)
          ? userDetails?.dob?.replaceAll("/", "-")
          : ""
      }`,
      email: `${!_.isEmpty(userDetails?.emailId) ? userDetails?.emailId : ""}`,
    },
  };
};

export const getDecryptedSearchParamsValue = (searchParamsString: string) => {
  let decodedUrl = decodeURIComponent(searchParamsString);
  let decryptedSearchParams = decryptString(
    decodedUrl,
    "1234567891234567",
    "2619AD481DFBB08C"
  );
  const pairs = decryptedSearchParams.split("&");
  let tempData: any = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    if (key) {
      tempData[key] = value;
    }
  });
  return tempData;
};

/**
 * Get mobile operating system
 * @returns
 */
export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || (window as any)?.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) || iOS()) {
    return "iOS";
  }
  return "unknown";
};

const iOS = () => {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);
};
