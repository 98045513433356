import { BaseQueryFn } from "@reduxjs/toolkit/query";
import axiosInstance from "./axiosInstance";
import { AxiosError, AxiosResponse } from "axios";
import { AxiosFetchQuery } from "../globalTypes/GlobalTypes";
// import {
//   BaseQueryMeta,
//   QueryReturnValue,
// } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

const axiosBaseQuery =
  (): BaseQueryFn<AxiosFetchQuery, unknown, unknown, unknown, any> =>
  async ({ url, method, data, body, params, headers }) => {
    try {
      const response: AxiosResponse<any, any> = await axiosInstance({
        url: url,
        method,
        data: data || body,
        params,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
          ...headers,
        },
        signal: new AbortController().signal,
      });
      // console.log(response);
      const responseUrl = response.request.responseURL;
      const config = { ...response.config };
      const meta = {
        ...response.headers,
        responseUrl: responseUrl,
        config: config,
      };

      return {
        ...response,
        meta: { ...meta },
      };
    } catch (axiosError: any) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export default axiosBaseQuery;
