import React, { useCallback, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Container from "@mui/material/Container";
import "./NoHoldingsFound.scss";
import Divider from "@mui/material/Divider";
import CommonGifAnimation from "../../components/CustomGifAnimation/CommonGifAnimation";
import NoHoldingsFoundGif from "../../assets/gif/No_Holdings_Found.json";
import {
  E_ChannelOptions,
  T_AnimationData,
  T_CustomerLobDetails,
  T_UserProfile,
} from "../../globalTypes/GlobalTypes";
import AddManuallyPopup from "../AddManuallyPopup/AddManuallyPopup";
import { Colors } from "../../styles/constant";
import { ABCD_PLAYSTORE_URL, EVENT_HANDLER } from "../../utils/constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import { goToSuperAppInfo } from "../../utils/helper";
import { T_GenerateTokenRes } from "../../features/Feature_Login/Login_Types";

function NoHoldingsFound() {
  const [isAddManualPopupOpen, setIsAddManualPopupOpen] = useState(false);
  const csAppSlice = useAppSelector((state) => state.CsAppSlice);
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const tokenDetails = useAppSelector((state) => state.CsAppSlice.tokenDetails);
  const dispatch = useAppDispatch();
  const handleGotoAbcdApp = useCallback(() => {
    if (csAppSlice.channel === E_ChannelOptions.SUPER_APP) {
      triggerEventToSuperApp();
    } else {
      window.open(ABCD_PLAYSTORE_URL);
    }
  }, []);

  /**
   * Trigger event to super app
   */
  const triggerEventToSuperApp = () => {
    const backToSuperAppExtraInfo = goToSuperAppInfo(
      tokenDetails as T_GenerateTokenRes,
      userDetails as T_UserProfile & T_CustomerLobDetails
    );
    dispatch({
      type: EVENT_HANDLER.GO_TO_SUPER_APP_EVENT,
      payload: backToSuperAppExtraInfo,
    });
  };

  return (
    <>
      <Box className="noholdings-page">
        <Box className="main-content" sx={{ padding: "1rem" }}>
          <Container
            sx={{
              textAlign: "center",
              backgroundColor: "white",

              borderRadius: "1.25rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              minHeight="13.5625rem"
              display="flex"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CommonGifAnimation
                animationData={NoHoldingsFoundGif as T_AnimationData}
                width="100%"
              />
            </Box>
            <Typography marginY={"1rem"} variant="h3" textAlign="center">
              No holdings found on your mobile number
            </Typography>
            <Typography
              variant="h5"
              textAlign="center"
              fontWeight={500}
              marginBottom="0.5rem"
            >
              if you have existing holding then
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: Colors.red,
                marginBottom: "0.75rem",
                cursor: "pointer",
              }}
              onClick={() => setIsAddManualPopupOpen(true)}
            >
              Add manually
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              paddingX="1.25rem"
              width={"80%"}
            >
              <Divider className="header-text-line" />
              <Typography
                color="#77787B"
                fontSize={12}
                fontWeight={500}
                marginX=".625rem"
              >
                {"OR ELSE"}
              </Typography>
              <Divider className="header-text-line" />
            </Box>
            <Typography
              variant="h5"
              fontWeight={500}
              sx={{
                marginTop: "1rem",
                marginBottom: "0.5rem",
                color: "#414042",
              }}
            >
              Explore ABCD app
            </Typography>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: ".875rem",
                lineHeight: "1.05rem",
                marginBottom: ".5rem",
                color: "#797979",
              }}
            >
              Pay, Invest, Borrow, Insure & More
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: ".875rem",
                lineHeight: "1.05rem",
                color: "#C7222A",
                marginBottom: "1.5rem",
                cursor: "pointer",
              }}
              onClick={handleGotoAbcdApp}
            >
              Go to app now
            </Typography>
          </Container>
        </Box>
        {isAddManualPopupOpen && (
          <AddManuallyPopup
            open={isAddManualPopupOpen}
            handleOpen={setIsAddManualPopupOpen}
            isNoHoldingFlow={true}
          />
        )}
      </Box>
    </>
  );
}

export default NoHoldingsFound;
