import {
  Api_GenerateSso_New,
  // Api_GenerateSso_Old,
  Api_ValidateSso_New,
  Api_ValidateSso_Old,
  LOGIN_SERVICE_API_BASE_URL,
} from "../../utils/ApiEndpoints";
import {
  T_GenerateSSONewPay,
  T_GenerateSSONewRes,
  T_GenerateSSOOldPay,
  T_GenerateSSOOldRes,
  T_RedirectApiPayload,
  T_ValidateSSONewPay,
  T_ValidateSSONewRes,
  T_ValidateSSOOldPay,
  T_ValidateSSOOldRes,
} from "./Sso_Types";
import { CSApiService } from "../../redux-store/CSApiService";
import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { url } from "inspector";
import { AxiosError, AxiosResponse } from "axios";
export const ssoRtk = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    generateSso_New: builder.query<
      T_ApiResponse<T_GenerateSSONewRes>,
      T_GenerateSSONewPay
    >({
      query: (payload) => ({
        url: Api_GenerateSso_New,
        method: "POST",
        body: payload,
      }),
    }),
    generateSso_Old: builder.query<
      T_ApiResponse<T_GenerateSSOOldRes>,
      T_GenerateSSOOldPay
    >({
      query: (payload) => ({
        url:
          LOGIN_SERVICE_API_BASE_URL + "/access-management/generate-sso-token", //Api_GenerateSso_Old,
        method: "POST",
        body: payload,
      }),
    }),
    validateSso_New: builder.query<
      T_ApiResponse<T_ValidateSSONewRes>,
      T_ValidateSSONewPay
    >({
      query: (payload) => ({
        url: Api_ValidateSso_New,
        method: "POST",
        body: payload,
      }),
    }),
    validateSso_Old: builder.query<
      T_ApiResponse<T_ValidateSSOOldRes>,
      T_ValidateSSOOldPay
    >({
      query: (payload) => ({
        url: Api_ValidateSso_Old,
        method: "POST",
        body: payload,
      }),
    }),
    redirectUrlApi: builder.query<any, T_RedirectApiPayload>({
      query: (payload) => ({
        url: payload.url,
        method: payload.type,
        body: payload.data,
        headers: payload.headers,
      }),
      transformResponse: async (response: any, meta: any, arg) => {
        console.log("redirect URL headers: ", meta);
        return {
          payload: response,
          headers: { ...meta },
          config: { ...meta.config },
        };
      },
    }),
  }),
});
export const {
  useGenerateSso_NewQuery,
  useGenerateSso_OldQuery,
  useLazyGenerateSso_NewQuery,
  useLazyGenerateSso_OldQuery,
  useValidateSso_NewQuery,
  useValidateSso_OldQuery,
  useLazyRedirectUrlApiQuery,
} = ssoRtk;
