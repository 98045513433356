import React, { FC, useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion";
import {
  T_FaqCategoryItems,
  T_FaqListItems,
} from "../GeneralAppElements_Types";
import { defaultEmptyFun } from "../../../utils/helper";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { useLazyFetchFaqListQuery } from "../GeneralAppElements_Api";
import CustomShimmer from "../../../components/CustomShimmer/CustomShimmer";
import "./FaqItemList.scss";

interface Props {
  category: T_FaqCategoryItems;
  handleCategory: Function;
  selectedModule: string;
}
interface MarkedOptions {
  gfm?: boolean;
  breaks?: boolean;
  headerIds?: boolean;
  mangle?: boolean;
}
const markedOptions: MarkedOptions = {
  gfm: true, // Use GitHub Flavored Markdown
  breaks: true, // Enable line breaks
  headerIds: true, // Disable automatic IDs for headers
  mangle: false, // Disable name mangling for links and images
};

const FaqItemList: React.FC<Props> = ({
  category,
  handleCategory = defaultEmptyFun,
  selectedModule,
}) => {
  const [menus, setMenus] = useState<T_FaqListItems[]>([]);
  const [fetchFaqListApi] = useLazyFetchFaqListQuery();
  const [showShimmer, setShowSimmer] = useState<boolean>(false);

  useEffect(() => {
    if (category.moduleName) {
      fetchFaqList(category.attributes.key, category.moduleName);
      return () => {
        if (category.moduleName)
          fetchFaqList(category.attributes.key, category.moduleName);
      };
    }
  }, [category]);

  const handleClick = useCallback(() => {
    handleCategory(category, false);
  }, [handleCategory, category]);

  const fetchFaqList = async (category: string, module: string) => {
    setShowSimmer(true);
    try {
      const response = await fetchFaqListApi({
        modules: module,
        categories: category,
      }).then((res) => {
        return res;
      });
      if (response.data?.code === 1) {
        let markedContent;
        let sanitizedContent;
        let sanitized: any = [];
        response.data?.payload.data.map((data: T_FaqListItems) => {
          async function getMarkedVal(val: string, data: T_FaqListItems) {
            let sanityValue = {
              attributes: {
                question: "",
                answer: "",
              },
              id: 0,
            };
            markedContent = await marked(val, markedOptions);
            sanitizedContent = DOMPurify.sanitize(markedContent);
            sanityValue.attributes["question"] = data.attributes.question;
            sanityValue.attributes["answer"] = sanitizedContent;
            sanityValue.id = data.id;
            sanitized.push(sanityValue);
          }
          getMarkedVal(data.attributes.answer, data);
          return data;
        });
        setMenus(sanitized);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setShowSimmer(false);
    }
  };

  return (
    <Box className="faq-list-popup-body">
      <Box className="Title">
        <Box className="Titleicon">
          <ChevronLeftIcon className="Titleicon-svg" onClick={handleClick} />
        </Box>

        <Box className="Titlename">
          <Typography variant="h3" className="faq-list-style">
            {category.attributes.categoryName}
          </Typography>
        </Box>
      </Box>
      {showShimmer ? (
        <>
          {[1, 2].map((data) => {
            return (
              <Box
                className="accordian-content"
                sx={{ padding: "1.125rem 1.5rem !important" }}
                key={data}
              >
                <CustomShimmer width="100%" height="1.063rem" />
              </Box>
            );
          })}
        </>
      ) : (
        <>
          {menus.length > 0 ? (
            menus.map((data: T_FaqListItems) => {
              return (
                <Box
                  className="accordian-content"
                  key={data.attributes.question}
                >
                  <CustomAccordion title={data.attributes.question}>
                    <Typography variant="h6">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.attributes.answer,
                        }}
                      />
                    </Typography>
                  </CustomAccordion>
                </Box>
              );
            })
          ) : (
            <Typography sx={{ textAlign: "center" }} variant="h5">
              No FAQ's found
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
export default FaqItemList;
