import { Box, Grid, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import CustomRadio from "../CustomRadio/CustomRadio";

type radioItems = {
  title: string;
  value: string;
};

interface FrequencyProps {
  title?: string;
  type: string;
  values: radioItems[];
  handleChange: Function;
  validateInputs: Function;
  selectedAlert?: string;
}

const RenderModeOfFrequency: FC<FrequencyProps> = ({
  title = "",
  type = "",
  values,
  handleChange,
  validateInputs,
  selectedAlert,
}) => {
  const [selectedData, setSelectedData] = useState<string>("");

  useEffect(() => {
    setSelectedData("");
  }, [selectedAlert]);

  useEffect(() => {
    if (selectedData && selectedData !== "")
      validateInputs(type, true, {
        [type]: selectedData,
      });
    else
      validateInputs(type, false, {
        [type]: selectedData,
      });
  }, [selectedData]);

  const handleRadioChange = useCallback((selectedVal: string) => {
    setSelectedData(selectedVal);
  }, []);

  return (
    <Box className="li-sa-frequency-mode">
      <Typography variant="h5">{title}</Typography>
      <Grid
        container
        spacing={"0.625rem"}
        className="li-sa-frequency-mode-grid-container"
      >
        {values.length > 0 &&
          values.map((data) => {
            return (
              <Grid item md={6} sm={6} xs={6} key={data.value}>
                <CustomRadio
                  title={data.title}
                  value={data.value}
                  handleRadioChange={handleRadioChange}
                  selectedValue={selectedData}
                />
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};

export default RenderModeOfFrequency;
