import "./CsApp.scss";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/CustomNavbar/Navbar";
import AppRoutes from "./routes/AppRoutes";
import { Grid } from "@mui/material";
import ErrorBoundary from "./ErrorBoundary";
import { useLazyGenerateTokenQuery } from "./features/Feature_Login/Login_Api";
import { useAppDispatch, useAppSelector } from "./redux-store/CustomReduxHooks";
import {
  setTokenDetails,
  updateTraceId,
  setDeviceType,
} from "./redux-store/reducers/CsAppSlice";
import { useLazyFetchMasterDataQuery } from "./features/Feature_GeneralAppElements/GeneralAppElements_Api";
import { setMasterData } from "./features/Feature_GeneralAppElements/GeneralAppElements_Slice";
import { setError } from "./redux-store/reducers/CsErrorSlice";
import ErrorComp from "./components/CustomError/ErrorComp";
import useDeviceDetection from "./hooks/useDeviceDetection";
import Loading from "./pages/Utilities/Loading";
import IdleTimeOut from "./pages/IdleTimeOut/IdleTimeOut";
import GenerateSsoOld from "./features/Feature_SSO/OldSSO/GenerateSsoOld";
import { R_LiRoutes } from "./features/Feature_LifeInsurance/LifeInsurance_Routes";
import { R_GeneralElementsRoutes } from "./features/Feature_GeneralAppElements/GeneralAppElements_Routes";
import { R_HomeRoutes } from "./pages/Home/Home_Routes";
import { R_HiRoutes } from "./features/Feature_HealthInsurance/HealthInsurance_Routes";
import { updateLoadingState } from "./features/Feature_Loading/Loading_Slice";
import { R_HLRoutes } from "./features/Feature_Homeloan/Homeloan_Routes";

import {
  useLazyFetchErrorVariableQuery,
  useLazyFetchHamburgerMenuQuery,
  useLazyFetchIntegrationVariableQuery,
  useLazyFetchPageMessageQuery,
  useLazyFetchPageVariableQuery,
} from "./features/Features_Cms/Cms_Api";
import {
  setErrorVariables,
  setIntegrationVariables,
  setPageMessage,
  setPageVariables,
  setProfileHamburgerMenu,
} from "./features/Features_Cms/Feature_Cms_Slice";
import { nanoid } from "@reduxjs/toolkit";
import { R_MfRoutes } from "./features/Feature_MutualFund/MutualFund_Routes";
import { R_SSRoutes } from "./features/Feature_StocksAndSecurities/Stocks&Securities_Routes";

const CsApp: FC = () => {
  const appDispatch = useAppDispatch();
  const { pathname, search } = useLocation();

  const [generateTokenApi, { data: tokenDetails, error: tokenDetailsError }] =
    useLazyGenerateTokenQuery();
  const [fetchMasterDataApi] = useLazyFetchMasterDataQuery();
  const isPwaMobileOrWeb = useDeviceDetection();
  const deviceTypeFromStore = useAppSelector(
    (state) => state.CsAppSlice.deviceType
  );

  const csAppSlice = useAppSelector((state) => state.CsAppSlice);
  const appElements = useAppSelector((state) => state.GeneralAppElementsSlice);
  const [fetchHamburgerMenu] = useLazyFetchHamburgerMenuQuery();
  const [fetchPageVariable] = useLazyFetchPageVariableQuery();
  const [fetchCmsIntegration] = useLazyFetchIntegrationVariableQuery();
  const [fetchErrorVariable] = useLazyFetchErrorVariableQuery();
  const [fetchPageMessage] = useLazyFetchPageMessageQuery();
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const traceIdFromUrl = queryParams.get("traceid");
    if (traceIdFromUrl && traceIdFromUrl.length > 0) {
      appDispatch(updateTraceId(traceIdFromUrl));
    } else {
      let randomTraceId = "CS" + nanoid(14);
      appDispatch(updateTraceId(randomTraceId));
    }
  }, []);

  useEffect(() => {
    if (isPwaMobileOrWeb && isPwaMobileOrWeb.length > 0) {
      appDispatch(setDeviceType(isPwaMobileOrWeb));
    }
  }, [isPwaMobileOrWeb]);

  useEffect(() => {
    if (deviceTypeFromStore && deviceTypeFromStore.length > 0) {
      if (!csAppSlice.tokenDetails?.internal.authToken && !tokenDetailsError) {
        generateTokenApi();
      }
    }
  }, [deviceTypeFromStore]);

  useEffect(() => {
    if (tokenDetails) {
      appDispatch(setTokenDetails(tokenDetails.payload));
    }
  }, [tokenDetails]);

  useEffect(() => {
    if (
      csAppSlice.tokenDetails?.internal.authToken &&
      !appElements.masterData?.masterDetail
    ) {
      fetchMasterDataApi("all").then((res) => {
        appDispatch(setMasterData(res.data?.payload));
      });
      fetchHamburgerMenu().then((res) => {
        if (res.isSuccess && res.data) {
          appDispatch(setProfileHamburgerMenu(res.data?.payload.data));
        }
      });

      fetchPageVariable().then((res) => {
        if (res.isSuccess && res.data) {
          appDispatch(setPageVariables(res.data?.payload.data));
        }
      });
      fetchCmsIntegration().then((res) => {
        if (res.isSuccess && res.data) {
          appDispatch(setIntegrationVariables(res.data?.payload.data));
        }
      });
      fetchErrorVariable().then((res) => {
        if (res.isSuccess && res.data) {
          appDispatch(setErrorVariables(res.data?.payload.data));
        }
      });
      fetchPageMessage().then((res) => {
        if (res.isSuccess && res.data) {
          appDispatch(setPageMessage(res.data?.payload.data));
        }
      });
    }
  }, [csAppSlice.tokenDetails?.internal]);

  useEffect(() => {
    appDispatch(setError({ error: tokenDetailsError as Error, message: "" }));
  }, [tokenDetailsError]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  let isNavbarShow = [
    R_GeneralElementsRoutes.search,
    R_HomeRoutes.noHoldings,
    R_HLRoutes.home_loan_details,
    R_HLRoutes.interest_certificate,
    R_HLRoutes.emi_payment,
    R_HLRoutes.disbursement_request,
    R_HLRoutes.terms_and_conditions,
    R_HLRoutes.account_statement,
    R_HLRoutes.change_enach,
    R_HLRoutes.document_upload,
    R_HLRoutes.home_loan_service_request,
    R_LiRoutes.details_PolicyNumber,
    R_LiRoutes.details_PolicyNumber_TaxCertificate,
    R_LiRoutes.details_PolicyNumber_PolicyAccountStatement,
    R_LiRoutes.details_PolicyNumber_PremiumReceipts,
    R_LiRoutes.details_PolicyNumber_UpdatePAN,
    R_LiRoutes.details_set_alerts,
    R_MfRoutes.mutualFund_Details,
    R_MfRoutes.mutualFund_CapitalGains,
    R_MfRoutes.mutualFund_AccountStatement,
    R_MfRoutes.mutualFund_ExitLoad,
    R_SSRoutes.details_Portfolio,
    R_SSRoutes.details_Portfolio_MyDocuments,
    R_SSRoutes.contact_notes,
    R_SSRoutes.account_statement,
    R_HiRoutes.details_PolicyNumber,
    R_HiRoutes.details_PolicyNumber_MedicalReports,
    R_HiRoutes.details_PolicyNumber_EditPolicy,
    R_HLRoutes.home_loan_service_request,
    ``,
  ].includes(pathname);

  let isHomeRoute = ["/home"].includes(pathname);

  const getNavbar = () => {
    if (pathname) {
      if (isNavbarShow) {
        return <Navbar />;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  return (
    <>
      <ErrorBoundary>
        {getNavbar()}
        <Grid
          container
          spacing={3}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid item xs={12} sm={12} md={isHomeRoute ? 12 : 6}>
            <AppRoutes />
          </Grid>
        </Grid>
        <ErrorComp />
      </ErrorBoundary>
      <GenerateSsoOld key={"generateSSOOLD_Comp"} />
      <Loading />
      <IdleTimeOut />
    </>
  );
};

export default CsApp;
