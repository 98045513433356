import React, { FC, useState } from "react";
import {
  Avatar,
  Box,
  Container,
  Dialog,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Slide,
  Typography,
} from "@mui/material";
import {
  hamburgerMenuList,
  privacyPolicyTitle,
  tabViewPoint,
  termsAndConditionsTitle,
} from "../../../utils/constants";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ABCLogo from "../../../assets/images/abcd-logo.png";
import { TransitionProps } from "@mui/material/transitions";
import { Colors } from "../../../styles/constant";
import "./HamburgerMenuDialog.scss";
import useWindowSize from "../../../hooks/useWindowSize";
import MyProfile from "../MyProfile/MyProfile";
import Utilities from "../Utilities/Utilities";
import HelpAndSupport from "../HelpAndSupport/HelpAndSupport";
import AppSettings from "../AppSettings/AppSettings";
import FAQs from "../FAQs/FAQs";
import DeviceAuthenticationSettings from "../DeviceAuthenticationSettings/DeviceAuthenticationSettings";
import ChangemPIN from "../ChangemPIN/ChangemPIN";
import PolicyAndConditionsComponent from "../../../components/CustomPolicyAndConditionsComponent/PolicyAndConditionsComponent";
import LogOutPopUp from "../LogOutPopUp/LogOutPopUP";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { getInitials } from "../../../utils/helper";
import { T_ProfileHamburgerMenu } from "../../Features_Cms/ProfileHamburgerMenu.Types";
import CustomNetworkImage from "../../../components/CustomNetworkImage/CustomNetworkImage";
import RateUsPopUp from "../RateUsPopUp/RateUsPopUp";
interface Props {
  open: boolean;
  setOpen: Function;
  className?: string;
  defaultComponent?: string;
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const HamburgerMenuDialog: FC<Props> = ({
  open,
  setOpen,
  className = "",
  defaultComponent = "",
}) => {
  const [visibleComponent, setVisibleComponent] =
    useState<string>(defaultComponent);
  const [isCustomPopupOpen, setIsCustomPopupOpen] = useState<boolean>(false);
  const [openAppSwitchHeader, setOpenAppSwitchHeader] =
    useState<boolean>(false);
  const [openRateUsPopUp , setOpenRateUsPopUp] =  useState<boolean>(false);
  const [finalPolicyAndTermsTitle, setFinalPolicyAndTermsTitle] =
    useState<string>("");
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const { width } = useWindowSize();
  const isWebScreen = width >= tabViewPoint;

  const handleMenuClick = (item: T_ProfileHamburgerMenu) => {
    if (item.attributes.menuTitle === "Log out") {
      setOpenAppSwitchHeader(true);
    } else {
      item.attributes.pathname && setVisibleComponent(item.attributes.pathname);
    }
  };

  const profileHamburgerMenuData: T_ProfileHamburgerMenu[] = useAppSelector(
    (state) => state.CmsSlice.ProfileHamburgerMenuData
  );

  const mainMenuList = profileHamburgerMenuData;
   profileHamburgerMenuData.filter(
    (item) => item.attributes.pathname !== "app-settings"
  );
  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const cmsLogo =
    cmsData.find((i) => i.attributes.cmsType === "ABC Logo")?.attributes.image
      .data?.attributes.url ?? "";

  const imageStyle: React.CSSProperties = {
    width: "7.1875rem", // Use camelCase for width
    marginBottom: "0.75rem", // Use camelCase for margin-bottom
  };

  const mainMenu = (
    <>
      <Box className="header-part">
        <Box display="flex" alignItems="center">
          <Typography variant="body2" className="header-avatar">
            {getInitials(userDetails?.name)}
          </Typography>
          <Typography variant="h2">{userDetails?.name}</Typography>
        </Box>
        <CloseIcon
          fontSize="medium"
          sx={{
            marginRight: { xs: ".4375rem", sm: 0 },
          }}
          className="close-icon"
          onClick={() => setOpen(false)}
        />
      </Box>
      <Box>
      <Box paddingTop={className ? "0.25rem" : "1.5rem"}>
          {mainMenuList
            .filter(item => item.attributes.isEnable) // Filter items with isEnable true
            .map((item, index) => (
              <React.Fragment key={item.id}>
                <ListItem
                  key={item.attributes.menuTitle}
                  onClick={() => handleMenuClick(item)}
                  secondaryAction={
                    <IconButton
                      sx={{ marginRight: 0 }}
                      edge="end"
                      aria-label="delete"
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                  sx={{
                    marginY: index === mainMenuList.length ? "8px" : "0", 
                    cursor: "pointer",
                  }}
                >
                  <ListItemAvatar>
                    <img
                      style={{
                        height: "2rem",
                        marginLeft:
                          index === mainMenuList.length ? ".3125rem" : "0",
                      }}
                      src={item.attributes.menuIcon.data.attributes.url}
                      alt={item.attributes.menuTitle} 
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h5"
                        marginBottom={".25rem"}
                        onClick={() => handleMenuClick(item)}
                      >
                        {item.attributes.menuTitle}
                      </Typography>
                    }
                    secondary={
                      item.attributes.menuDescription && (
                        <Typography
                          fontSize={".75rem"}
                          fontWeight={400}
                          style={{ color: "#77787B" }}
                        >
                          {item.attributes.menuDescription}
                        </Typography>
                      )
                    }
                  />
                </ListItem>
                <Divider color="#F1F3F6" />
              </React.Fragment>
            ))}
        </Box>

      </Box>
      <Container className="bottom-part">
        <Box display="flex" justifyContent="center">
          <CustomNetworkImage 
            fallBackImage= { <img src={ABCLogo} alt="" style={imageStyle}/>}
            src={cmsLogo}
            alt="aditya-birla"
            style={imageStyle}
          />
        </Box>
        <Box display="flex" marginY="1.375rem" justifyContent="space-between">
          <Typography
            variant="subtitle2"
            sx={{ cursor: "pointer" }}
            onClick={() => handlePolicyTermsPopup(termsAndConditionsTitle)}
          >
            Terms & Conditions
          </Typography>
          <Typography
            sx={{ cursor: "pointer" }}
            variant="subtitle2"
            onClick={() => handlePolicyTermsPopup(privacyPolicyTitle)}
          >
            Privacy Policy
          </Typography>
          <Typography
            sx={{ cursor: "pointer" }}
            variant="subtitle2"
            onClick={() => handleRateUsPopup()}
          >
            Rate Us
          </Typography>
          {/* {!isWebScreen && (
            <Typography variant="subtitle2">App version 1.0</Typography>
          )} */}
        </Box>
      </Container>
      <PolicyAndConditionsComponent
        open={isCustomPopupOpen}
        handleOpen={setIsCustomPopupOpen}
        title={finalPolicyAndTermsTitle}
      />

      <LogOutPopUp
        open={openAppSwitchHeader}
        handleOpen={setOpenAppSwitchHeader}
      />

     {openRateUsPopUp && <RateUsPopUp
        open={openRateUsPopUp}
        handleOpen={setOpenRateUsPopUp}
      />}
    </>
  );

  const handleBackBtnClick = (pathname: string) => {
    if (
      ["my-profile", "utilities", "help-&-support", "app-settings"].includes(
        pathname
      )
    ) {
      setVisibleComponent("main-menu");
    } else if (pathname === "FAQs") {
      setVisibleComponent("help-&-support");
    } else if (
      ["change-mPIN", "device-authentication-settings"].includes(pathname)
    ) {
      setVisibleComponent("app-settings");
    }
  };

  const renderComponent = () => {
    switch (visibleComponent) {
      case "main-menu":
        return mainMenu;
      case "my-profile":
        return (
          <MyProfile
            handleBackBtnClick={handleBackBtnClick}
            pathname={visibleComponent}
          />
        );
      case "utilities":
        return (
          <Utilities
            handleBackBtnClick={handleBackBtnClick}
            pathname={visibleComponent}
          />
        );
      case "help-&-support":
        return (
          <HelpAndSupport
            handleBackBtnClick={handleBackBtnClick}
            pathname={visibleComponent}
            setVisibleComponent={setVisibleComponent}
          />
        );
      case "app-settings":
        return (
          <AppSettings
            handleBackBtnClick={handleBackBtnClick}
            pathname={visibleComponent}
            setVisibleComponent={setVisibleComponent}
          />
        );
      case "FAQs":
        return (
          <FAQs
            handleBackBtnClick={handleBackBtnClick}
            pathname={visibleComponent}
          />
        );
      case "change-mPIN":
        return (
          <ChangemPIN
            handleBackBtnClick={handleBackBtnClick}
            pathname={visibleComponent}
          />
        );
      case "device-authentication-settings":
        return (
          <DeviceAuthenticationSettings
            handleBackBtnClick={handleBackBtnClick}
            pathname={visibleComponent}
          />
        );
      default:
        return mainMenu;
    }
  };

  const handlePolicyTermsPopup = (title: string) => {
    setIsCustomPopupOpen(true);

    title === termsAndConditionsTitle
      ? setFinalPolicyAndTermsTitle(termsAndConditionsTitle)
      : setFinalPolicyAndTermsTitle(privacyPolicyTitle);
  };

  const handleRateUsPopup = () => {
    setOpenRateUsPopUp(true);
  };

  return (
    <Dialog
      className={`hamburger-menu-dialog ${className}`}
      TransitionComponent={Transition}
      fullScreen
      open={open}
    >
      {renderComponent()}
    </Dialog>
  );
};

export default HamburgerMenuDialog;