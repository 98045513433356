import { RouteObject } from "react-router-dom";
import FeatureOnBoarding from "./Feature_OnBoarding";

export enum R_OnBoardingRoutes {
  onboarding = "/onboarding",
}

const OnBoardingRoutes: RouteObject[] = [
  {
    path: R_OnBoardingRoutes.onboarding,
    element: <FeatureOnBoarding />,
  },
];

export default OnBoardingRoutes;
