import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  Dialog,
  Grid,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import MutualFundIcon from "../../../assets/icons/mutualFund.svg";
import StockIcon from "../../../assets/icons/stock.svg";
import LifeInsuranceIcon from "../../../assets/icons/life-insurance.svg";
import HealthInsuranceIcon from "../../../assets/icons/healthInsurance.svg";
import arrowRight from "../../../assets/icons/adaptive-search-arrow-right.svg";
import clockIcon from "../../../assets/icons/adaptive-clock-icon.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/adaptive-search-icon.svg";
import { Colors } from "../../../styles/constant";
import NotFoundGif from "../../../assets/gif/Not_found.json";
import "./AdaptiveScreenSearch.scss";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import {
  PolicyOrAccountSelectionData,
  T_AnimationData,
  T_LiRouteState,
} from "../../../globalTypes/GlobalTypes";
import {
  useLazyFetchPopularServiceQuery,
  useLazyFetchSearchHistoryQuery,
  useLazyFetchSearchBarContentQuery,
  useLazySaveSearchHistoryQuery,
  useClearSearchHistoryMutation,
} from "../GeneralAppElements_Api";
import {
  T_fetchSearchHistory,
  T_P1orP2,
  T_SearchBarContent,
} from "../GeneralAppElements_Types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { useNavigate } from "react-router-dom";
import CsSessionStorageUtils from "../../../utils/CsSessionStorageUtils";
import {
  DashboardTabKeys,
  DashboardTabValues,
  HiLobCode,
  HlLobCode,
  LiLobCode,
  MfLobCode,
  REACT_APP_URL,
  StockLobCode,
} from "../../../utils/constants";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { TriggerGenerateSSO_OLD } from "../../Feature_SSO/Sso_Types";
import { triggerGenerateSSOOld } from "../../Feature_SSO/Sso_Slice";
import { isEmpty } from "lodash";
import CustomCircularLoader from "../../../components/CustomCircularLoader/CustomCircularLoader";
import CustomPolicyOrAccountSelect from "../../../components/CustomPolicyOrAccountSelect/CustomPolicyOrAccountSelect";

const titles = {
  SEARCH_HISTORY: "Search History",
  SEARCH_RESULT: "Search Result",
  NOT_FOUND: "Not found",
};

const serviceIcons: Record<string, string> = {
  "Mutual Funds": MutualFundIcon,
  "Stocks & Securities": StockIcon,
  "Life Insurance": LifeInsuranceIcon,
  "Health Insurance": HealthInsuranceIcon,
};

interface Props {
  open: boolean;
  handleOpen: Function;
}

const AdaptiveScreenSearch: FC<Props> = ({ open, handleOpen }) => {
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const LI_dashboardDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice
  );
  const MF_folioDetails = useAppSelector(
    (state) => state.MutualFundSlice.folioDetails
  );
  const HI_policyDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyDetails
  );
  const STOCKS_Details = useAppSelector(
    (state) => state.StocksSlice.stockdashboardDetails
  );
  const HL_LoanInfo = useAppSelector((state) => state.HomeLoanSlice.loanInfo);
  const [searchState, setSearchState] = useState(titles.SEARCH_HISTORY);
  const [searchValue, setSearchValue] = useState("");
  const [showAllResults, setShowAllResults] = useState(false);
  const [policyOrAccountSelectionData, setPolicyOrAccountSelectionData] =
    useState<PolicyOrAccountSelectionData>({
      isOpen: false,
      options: [],
      redirectionUrl: "",
      lobDetails: null,
      functionalityCode: "",
    });
  const itemsPerPage = 5;
  const [filteredResults, setFilteredResults] = useState<T_SearchBarContent[]>(
    []
  );
  const [listItemLoading, setListItemLoading] = useState<{
    key: string;
    loading: boolean;
  }>();
  const navigate = useNavigate();
  const [fetchPopularService, { data: popularServiceData }] =
    useLazyFetchPopularServiceQuery();
  const [fetchSearchHistory, { data: searchHistoryData }] =
    useLazyFetchSearchHistoryQuery();
  const [fetchSearchBarContent, { data: searchBarContent }] =
    useLazyFetchSearchBarContentQuery();
  const [saveSearchHistory] = useLazySaveSearchHistoryQuery();
  const [clearSearchHistory] = useClearSearchHistoryMutation();
  const mappedSearchHistoryData = searchHistoryData?.payload || [];
  const masterData = useAppSelector(
    (state) => state.GeneralAppElementsSlice.masterData
  );
  const activeTab = CsSessionStorageUtils.get("ACTIVE_DASHBOARD_TAB");
  const CsAppSlice = useAppSelector((state) => state.CsAppSlice);
  const ssoSlice = useAppSelector(
    (state) => state.SsoSlice.ssoData as TriggerGenerateSSO_OLD
  );
  const dispatch = useAppDispatch();
  const popularServicesLob = masterData?.masterDetail?.popularServicesLob;
  const cmsSlice = useAppSelector((state) => state.CmsSlice);

  useEffect(() => {
    if (!ssoSlice || isEmpty(ssoSlice) || !ssoSlice?.showLoading) {
      setListItemLoading((prev) => {
        if (prev) {
          return { ...prev, loading: false };
        } else {
          return undefined;
        }
      });
    }
  }, [ssoSlice]);

  const cmsConfigPopularServices = useMemo(() => {
    return cmsSlice.CmsIntegrationVariableData.filter(
      (f) =>
        f.attributes.isEnable &&
        f.attributes.pageReference === "Search - Popular services"
    );
  }, []);

  const cmsExplorePlans = useMemo(() => {
    return cmsSlice.CmsIntegrationVariableData.filter(
      (f) =>
        f.attributes.isEnable &&
        f.attributes.pageReference === "Search Explore Plans - Popular services"
    );
  }, []);

  const cmsConfigMarquee = useMemo(() => {
    const marqueeArr = cmsSlice.CmsIntegrationVariableData.filter(
      (f) => f.attributes.isEnable && f.attributes.key === "Marquee"
    );
   return marqueeArr.filter((f) => {
      const tempData =
        activeTab === DashboardTabKeys.Insurance
          ? ["02", "06"].includes(f.attributes.lobId)
          : activeTab === DashboardTabKeys.Investment
          ? ["24", "01"].includes(f.attributes.lobId)
          : false;
      return (
        f.attributes.isEnable && f.attributes.key === "Marquee" && tempData
      );
    });
  }, []);

  const defaultMarqueeText = useMemo(() => {
    return (
      cmsSlice.CmsIntegrationVariableData.find(
        (f) => f.attributes.isEnable && f.attributes.key === "Default Marquee"
      )?.attributes.text || "Search for Insurance policy details"
    );
  }, []);
  const [marqueeLabels, setMarqueeLabels] = useState(defaultMarqueeText);

  useEffect(() => {
    if (!cmsConfigMarquee || cmsConfigMarquee.length <= 0) {
      setMarqueeLabels(defaultMarqueeText);
      return;
    }
    const toggleMarque = () => {
      setMarqueeLabels((prev) => {
        return cmsConfigMarquee[0]?.attributes.text === prev
          ? "" + cmsConfigMarquee?.[1]?.attributes.text
          : "" + cmsConfigMarquee?.[0]?.attributes.text;
      });
    };
    toggleMarque();
    const interval = setInterval(() => {
      toggleMarque();
    }, 10000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [cmsConfigMarquee]);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const inputValue = event.target.value.toLowerCase();
    if (inputValue === "") {
      setSearchState(titles.SEARCH_HISTORY);
    } else if (inputValue.length >= 2) {
      setSearchState(titles.SEARCH_RESULT);
      const filtered =
        searchBarContent?.payload?.filter((item) =>
          item.menuDisplayText.toLowerCase().includes(inputValue)
        ) || [];
      if (filtered.length <= 0) {
        setSearchState(titles.NOT_FOUND);
      } else {
        setFilteredResults(filtered);
      }
    } else {
      setSearchState(titles.SEARCH_HISTORY);
    }
    setSearchValue(event.target.value);
  };

  React.useEffect(() => {
    const data = {
      csUserId: userDetails?.CS_USER_ID || "",
    };
    fetchSearchHistory(data);
    const params = {
      lobs: popularServicesLob?.map((p) => p.masterValue) || [
        "Mutual Funds",
        "Stocks & Securities",
        "Life Insurance",
        "Health Insurance",
      ],
    };
    fetchPopularService(params, true);
    const lobsToSearch = [];
    const lobs = CsAppSlice.userDetails?.customerAndLobDetails
      ?.filter((f) => f.PRIMARY_SECONDARY_USER === "primary")
      ?.map((m) => m.LOB);
    if (lobs?.includes(LiLobCode)) {
      lobsToSearch.push("Life Insurance");
    }
    if (lobs?.includes(HiLobCode)) {
      lobsToSearch.push("Health Insurance");
    }
    if (lobs?.includes(MfLobCode)) {
      lobsToSearch.push("Mutual Funds");
    }
    if (lobs?.includes(StockLobCode)) {
      lobsToSearch.push("Stocks & Securities");
    }
    if (lobs?.includes(HlLobCode)) {
      lobsToSearch.push("Home Loans");
    }
    fetchSearchBarContent({ lob: lobsToSearch.join(",") });
  }, []);

  React.useEffect(() => {
    if (searchBarContent?.payload) {
      setFilteredResults(searchBarContent.payload);
    }
  }, [searchBarContent]);

  const handleSearchHistoryArrowClick = (history: T_fetchSearchHistory) => {
    setFilteredResults([]);
    if (history.redirectionMode == "inApp") {
      handleInAppRedirection(
        history.lob,
        history.redirectionUrl,
        history.functionalityCode || ""
      );
    } else {
      console.log("lob from search history:", history.lob);
      redirectToLob(
        "" + history.uniqueId,
        "" + history.functionalityCode,
        history.lob,
        history.redirectionUrl
      );
    }
  };

  const handleArrowClick = useCallback((value: T_SearchBarContent) => {
    saveSearchHistory({
      csUserId: userDetails?.CS_USER_ID || "",
      searchHistory: [value.uniqueId.toString()],
    }).then((r) => {
      if (value.redirectionMode === "inApp") {
        handleInAppRedirection(
          value.lob,
          value.redirectionUrl,
          value.functionalityCode
        );
      } else {
        console.log("lob from search result:", value.lob);
        redirectToLob(
          `${value.uniqueId}-${value.menuId}`,
          "" + value.functionalityCode,
          value.lob,
          value.redirectionUrl
        );
      }
    });
  }, []);

  const onDialogCloseHandler = useCallback(() => {
    handleOpen(false);
    setSearchState(titles.SEARCH_HISTORY);
    setSearchValue("");
    setShowAllResults(false);
  }, []);

  const clearSearchHistoryHandler = useCallback(() => {
    const data = { csUserId: userDetails?.CS_USER_ID || "" };
    clearSearchHistory(data);
  }, []);

  const totalPages = Math.ceil(filteredResults.length / itemsPerPage);
  const displayedResults = showAllResults
    ? filteredResults
    : filteredResults.slice(0, itemsPerPage);

  const handleViewAll = () => {
    setShowAllResults(true);
  };

  const handlePopularServicesClick = useCallback(
    (lobName: string, option: T_P1orP2) => {
      if (option.redirectionMode === "inApp") {
        handleInAppRedirection(lobName, option.redirectionUrl);
      } else {
        console.log("lob from search popular services:", lobName);
        redirectToLob(
          `${lobName}`,
          "" + option.functionalityCode,
          lobName,
          option.redirectionUrl
        );
      }
    },
    []
  );

  const handleSearchClick = () => {
    const searchItem = filteredResults.find(
      (item) => item.menuDisplayText.toLowerCase() === searchValue.toLowerCase()
    );
    if (searchItem) {
      handleArrowClick(searchItem);
    } else {
      setSearchState(titles.NOT_FOUND);
    }
  };

  const handleClearClick = () => {
    setSearchValue("");
  };

  const redirectToLob = useCallback(
    (
      uniqeuId: string,
      functionalityCode: string,
      lobName: string,
      redirectionUrl: string
    ) => {
      const lobDetails = masterData?.productCategory.find(
        (f) => f.lob === lobName || f.lobName === lobName || f.lobId === lobName
      );
      const panNo = CsAppSlice.userDetails?.pan;
      const name = CsAppSlice.userDetails?.name;
      const dob = CsAppSlice.userDetails?.dob;
      const email = "" + CsAppSlice.userDetails?.emailId;
      const mobile = "" + CsAppSlice.userDetails?.mobileNumber;
      const ciiNo = CsAppSlice.userDetails?.customerAndLobDetails?.find(
        (f) =>
          f.PRIMARY_SECONDARY_USER === "primary" && f.LOB === lobDetails?.lob
      )?.CII;
      const oldTriggerValue = (ssoSlice as TriggerGenerateSSO_OLD)
        .triggerGenerateSSO_Old;
      /**Udp related params are being udpated in generateSSO component as it is constant for all lobs */
      const ssoObj = {
        triggerGenerateSSO_Old: oldTriggerValue ? !oldTriggerValue : true,
        redirectionUrl: redirectionUrl,
        ciiNo: ciiNo,
        functionality: functionalityCode,
        mobileNo: mobile,
        lobClientId: ssoSlice.lobClientId,
        channel: CsAppSlice.channel,
        sourceLobId: lobDetails?.lobId,
        destinationLobId: lobDetails?.lobId,
        callbackUrl: `${REACT_APP_URL}/login`,
        appVersion: "1",
        panNumber: panNo,
        customerFullName: name,
        customerDob: dob,
        emailId: email,
        osVersion: "Windows",
        imei: "1231312",
        deviceId: "131312",
        userAgent: navigator.userAgent,
        clientIpAddress: "",
        udp: "",
        udp2: "",
        udp3: "",
        udp4: "",
        udp5: "",
        udp6: "",
        udp7: "",
        udp8: "",
        udp9: "",
        udp10: "",
        showLoading: true,
      } as TriggerGenerateSSO_OLD;
      if (lobDetails?.lobId === "06") {
        ssoObj.userId = "OneABCTest"; //given by client
        ssoObj.apiPassword = "T25lQUJDVGVzdDEyMw=="; //given by client
      }
      dispatch(triggerGenerateSSOOld(ssoObj));
      setListItemLoading(() => {
        return { key: uniqeuId, loading: true };
      });
    },
    [ssoSlice]
  );

  const handleInAppRedirection = (
    lobName: string,
    redirectionUrl: string,
    functionalityCode?: string
  ) => {
    const lobDetails = masterData?.productCategory.find(
      (f) => f.lob === lobName || f.lobName === lobName || f.lobId === lobName
    );
    if (
      lobDetails?.lobName === "Life Insurance" &&
      !isEmpty(LI_dashboardDetails?.dashboardDetails)
    ) {
      if (LI_dashboardDetails?.dashboardDetails?.length === 1) {
        const policyNumber =
          LI_dashboardDetails.dashboardDetails[0].POLICY_NUMBER;
        const stateData: T_LiRouteState = {
          policyNumber: policyNumber,
        };
        navigate(redirectionUrl, { state: stateData });
      } else {
        let policyNumberArray = LI_dashboardDetails.dashboardDetails.map(
          (item) => item.POLICY_NUMBER
        );
        setPolicyOrAccountSelectionData({
          isOpen: true,
          options: policyNumberArray || [],
          redirectionUrl: redirectionUrl,
          lobDetails: lobDetails,
        });
      }
    } else if (
      lobDetails?.lobName === "Health Insurance" &&
      !isEmpty(HI_policyDetails)
    ) {
      if (HI_policyDetails?.length === 1) {
        const policyNumber = HI_policyDetails[0].PolicyNumber;
        const stateData: T_LiRouteState = {
          policyNumber: policyNumber,
        };
        navigate(redirectionUrl, { state: stateData });
      } else {
        let policyNumberArray = HI_policyDetails.map(
          (item) => item.PolicyNumber
        );
        setPolicyOrAccountSelectionData({
          isOpen: true,
          options: policyNumberArray || [],
          redirectionUrl: redirectionUrl,
          lobDetails: lobDetails,
        });
      }
    } else if (
      lobDetails?.lobName === "Mutual Funds" &&
      MF_folioDetails?.length > 0
    ) {
      navigate(redirectionUrl);
    } else if (
      lobDetails?.lobName === "Stocks & Securities" &&
      STOCKS_Details?.length > 0
    ) {
      navigate(redirectionUrl);
    } else if (
      lobDetails?.lobName === "Home Loans" &&
      !isEmpty(HL_LoanInfo.rows)
    ) {
      if (HL_LoanInfo?.rows?.length === 1) {
        const accountNumber = HL_LoanInfo?.rows[0].LOAN_ACCOUNT_NUMBER || "";
        const stateData = {
          accountNumber: accountNumber,
          functionalityCode: functionalityCode,
        };
        navigate(redirectionUrl, { state: stateData });
      } else {
        let accountNumberArray = HL_LoanInfo?.rows.map(
          (item) => item.LOAN_ACCOUNT_NUMBER
        );
        setPolicyOrAccountSelectionData({
          isOpen: true,
          options: accountNumberArray || [],
          redirectionUrl: redirectionUrl,
          lobDetails: lobDetails,
          functionalityCode: functionalityCode,
        });
      }
    } else {
      onDialogCloseHandler();
    }
  };

  const policyOrAccountDataHandler = (data: PolicyOrAccountSelectionData) => {
    setPolicyOrAccountSelectionData(data);
  };

  const handleRedirect = (url: string) => {
    window.location.href =
      url ||
      "https://apps.apple.com/in/app/abcd-aditya-birla-capital/id6477909524?mt=8";
  };

  const popularServicesUI = useMemo(() => {
    if (!popularServiceData?.payload) {
      return <></>;
    }
    const handleExplorePlans = (title: string, redirectionUrl: string) => {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#414042",
              }}
              style={{
                cursor: "pointer",
                wordBreak: "break-all",
                textDecoration: "none",
                fontSize: ".75rem",
                fontWeight: "500",
                lineHeight: ".975rem",
                color: "#414042",
              }}
              onClick={() => {
                if (!listItemLoading?.loading) {
                  handleRedirect(redirectionUrl);
                }
              }}
            >
              {title}
            </Typography>
          </Box>
        </>
      );
    };
    type T_ExplorePlans = {
      lob: string;
      text: string;
      redirectionUrl: string;
    };
    const explorePlansMapped: T_ExplorePlans[] = [];
    const mappedServices = popularServiceData.payload.map((p) => {
      const cmsConfigP1 = cmsConfigPopularServices.find(
        (f) => f.id === Number(p.p1.cmsId)
      )?.attributes;
      const cmsConfigP2 = cmsConfigPopularServices.find(
        (f) => f.id === Number(p.p2.cmsId)
      )?.attributes;
      const explorePlans = cmsExplorePlans.find(
        (f) => f.attributes.lobId === cmsConfigP1?.lobId
      );
      const userLOBDetail =
        CsAppSlice?.userDetails?.customerAndLobDetails?.filter(
          (detail) =>
            detail.LOB === explorePlans?.attributes.lobId ||
            detail.LOB === explorePlans?.attributes.lobName
        ) || [];
      if (userLOBDetail?.length <= 0) {
        explorePlansMapped.push({
          lob: p.lob,
          text: "" + explorePlans?.attributes.text,
          redirectionUrl: "" + explorePlans?.attributes.url,
        });
      }
      return {
        ...p,
        p1: {
          ...p.p1,
          functionalityDesc: "",
          menuDisplayValue: "" + cmsConfigP1?.key,
          redirectionMode: "" + cmsConfigP1?.reference,
          redirectionUrl: "" + cmsConfigP1?.url,
          useCaseId: "" + cmsConfigP1?.text,
        },
        p2: {
          ...p.p2,
          functionalityDesc: "",
          menuDisplayValue: "" + cmsConfigP2?.key,
          redirectionMode: "" + cmsConfigP2?.reference,
          redirectionUrl: "" + cmsConfigP2?.url,
          useCaseId: "" + cmsConfigP2?.text,
        },
      };
    });
    return !mappedServices || mappedServices?.length <= 0 ? (
      <></>
    ) : (
      <Box bgcolor="#F7F7F7" padding="1.25rem 1.5rem">
        <Box padding="1rem" bgcolor="white" borderRadius="1rem">
          <Typography fontWeight={500} lineHeight="1.5rem" fontSize="1rem">
            Popular Services
          </Typography>
          <Grid container marginTop="0.375rem" spacing="0.625rem">
            {mappedServices.map((item) => {
              const explorePlanItem = explorePlansMapped.find(
                (f) => f.lob === item.lob
              );
              return (
                <Grid item xs={6} key={item.lob}>
                  <ListItem alignItems="center">
                    <ListItemAvatar style={{ flex: "auto" }}>
                      <img alt="img" src={serviceIcons[item.lob]} />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ flex: "80%" }}
                      sx={{ marginLeft: "0.5rem" }}
                      primary={
                        <Typography
                          fontSize=".75rem"
                          fontWeight={400}
                          lineHeight=".975rem"
                        >
                          {item.lob}
                        </Typography>
                      }
                      secondary={
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <React.Fragment
                            key={`${item.lob}-${item.p1.useCaseId}`}
                          >
                            {explorePlanItem ? (
                              handleExplorePlans(
                                explorePlanItem.text || "Explore Plans",
                                "" + explorePlanItem.redirectionUrl
                              )
                            ) : (
                              <>
                                <Typography
                                  width={"auto"}
                                  marginTop=".25rem"
                                  fontSize=".75rem"
                                  fontWeight={500}
                                  lineHeight=".975rem"
                                  color="#414042"
                                  style={{
                                    cursor: "pointer",
                                    wordBreak: "break-all",
                                  }}
                                  onClick={() => {
                                    if (!listItemLoading?.loading) {
                                      handlePopularServicesClick(
                                        item.lob,
                                        item.p1
                                      );
                                    }
                                  }}
                                >
                                  {item.p1.useCaseId}
                                </Typography>
                                <Typography
                                  marginTop=".25rem"
                                  fontSize=".75rem"
                                  fontWeight={500}
                                  lineHeight=".975rem"
                                  color="#414042"
                                  style={{
                                    cursor: "pointer",
                                    wordBreak: "break-all",
                                  }}
                                  onClick={() => {
                                    if (!listItemLoading?.loading) {
                                      handlePopularServicesClick(
                                        item.lob,
                                        item.p2
                                      );
                                    }
                                  }}
                                >
                                  <span>&nbsp;{`|`}&nbsp;</span>
                                  {item.p2.useCaseId}
                                </Typography>
                              </>
                            )}
                          </React.Fragment>
                        </div>
                      }
                    />
                    {listItemLoading &&
                      listItemLoading.key === `${item.lob}` &&
                      listItemLoading.loading && (
                        <div style={{ flex: "auto", alignSelf: "center" }}>
                          <CustomCircularLoader size={20} />
                        </div>
                      )}
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    );
  }, [popularServiceData?.payload, listItemLoading]);

  return (
    <Fragment>
      <Dialog
        fullWidth
        className="search-dialog"
        onClose={onDialogCloseHandler}
        open={open}
      >
        <TextField
          fullWidth
          placeholder={marqueeLabels}
          value={searchValue}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={handleSearchClick}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: searchValue.length > 0 && (
              <InputAdornment position="end">
                <IconButton sx={{ padding: 0 }} onClick={handleClearClick}>
                  <CloseIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              "& input::placeholder": {
                color: "#737373", // Change this to the desired color
              },
            },
          }}
        />

        <Box className="search-dialog-body">
          {searchState === "Search History" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4">{searchState}</Typography>
                {mappedSearchHistoryData.length > 0 && (
                  <Button variant="text">
                    <Typography
                      variant="h5"
                      color={Colors.red}
                      onClick={clearSearchHistoryHandler}
                    >
                      Clear all
                    </Typography>
                  </Button>
                )}
              </Box>
              <Stack marginTop="1rem" marginBottom="3rem">
                {mappedSearchHistoryData.length > 0 &&
                  mappedSearchHistoryData.map((item, index) => (
                    <React.Fragment key={item.uniqueId}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginY="1rem"
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!listItemLoading?.loading) {
                            handleSearchHistoryArrowClick(item);
                          }
                        }}
                      >
                        <Box display="flex" alignItems="center" gap="0.5rem">
                          <img src={clockIcon} />
                          <Typography variant="h5" fontWeight={500}>
                            {item.menuDisplayText}
                          </Typography>
                        </Box>
                        {listItemLoading &&
                        listItemLoading.key === "" + item.uniqueId &&
                        listItemLoading.loading ? (
                          <CustomCircularLoader size={20} />
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            src={arrowRight}
                            alt="arrow-right"
                          />
                        )}
                      </Box>
                      {mappedSearchHistoryData.length - 1 > index && (
                        <Divider sx={{ width: "84%" }} />
                      )}
                    </React.Fragment>
                  ))}
              </Stack>
            </>
          )}
          {searchState === "Search Result" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4">{searchState}</Typography>
              </Box>
              <Stack marginTop="1rem">
                {(showAllResults
                  ? filteredResults
                  : filteredResults.slice(0, itemsPerPage)
                ).map((item, index) => (
                  <React.Fragment key={`${item.uniqueId}-${item.menuId}`}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      marginY="1rem"
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!listItemLoading?.loading) {
                          handleArrowClick(item);
                        }
                      }}
                    >
                      <Box display="flex" alignItems="center" gap="0.5rem">
                        <SearchIcon />
                        <Typography variant="h5" fontWeight={500}>
                          {item.menuDisplayText}
                        </Typography>
                      </Box>
                      {listItemLoading &&
                      listItemLoading.key ===
                        `${item.uniqueId}-${item.menuId}` &&
                      listItemLoading.loading ? (
                        <CustomCircularLoader size={20} />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          src={arrowRight}
                          alt="arrow-right"
                        />
                      )}
                    </Box>
                    {displayedResults.length - 1 > index && (
                      <Divider sx={{ width: "84%" }} />
                    )}
                  </React.Fragment>
                ))}
                {filteredResults.length > itemsPerPage && !showAllResults && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{
                        minWidth: "1.25rem",
                        height: "1.25rem",
                        color: Colors.red,
                        marginTop: "1.25rem",
                      }}
                      onClick={handleViewAll}
                    >
                      View All
                    </Button>
                  </Box>
                )}
              </Stack>
            </>
          )}
          {searchState === "Not found" && (
            <Box>
              <Box className="search-dialog-body">
                <CommonGifAnimation
                  animationData={NotFoundGif as T_AnimationData}
                  width="200px"
                />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.25rem",
                    lineHeight: "1.5rem",
                    color: "#2E2E2ECC",
                    textAlign: "center",
                  }}
                >
                  {"Uhh-Ohh!"}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "0.5rem",
                    fontWeight: "400",
                    fontSize: ".75rem",
                    lineHeight: "1.05rem",
                    color: "rgba(46, 46, 46, 0.5)",
                    textAlign: "center",
                  }}
                >
                  {"We couldn't find what you were looking for"}
                </Typography>
              </Box>
            </Box>
          )}
          {["Not found", "Search History"].includes(searchState) &&
            popularServicesUI}
        </Box>
      </Dialog>
      {policyOrAccountSelectionData.isOpen &&
        !isEmpty(policyOrAccountSelectionData.options) && (
          <CustomPolicyOrAccountSelect
            data={policyOrAccountSelectionData}
            policyOrAccountDataHandler={policyOrAccountDataHandler}
          />
        )}
    </Fragment>
  );
};

export default AdaptiveScreenSearch;
