import { FC, useState } from "react";
import {
  Box,
  Dialog,
  Drawer,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "./NotificationsPage.scss";
import { Colors } from "../../../styles/constant";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { NotificationData, tabViewPoint } from "../../../utils/constants";
import { defaultEmptyFun, timeDifferenceInDays } from "../../../utils/helper";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import { T_AnimationData } from "../../../globalTypes/GlobalTypes";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import { Transition } from "../HamburgerMenuDialog/HamburgerMenuDialog";
import NotificationPopupBody from "./NotificationPopupBody";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import useWindowSize from "../../../hooks/useWindowSize";
import NotFoundGif from "../../../assets/gif/Not_found.json";

interface NotificationDrawerProps {
  openData: boolean;
  handleOpenData?: Function;
}
interface NotificationsData {
  preIcon: string;
  title: string;
  description: string;
  date: Date;
  isNotificationRead: boolean;
}

interface CommonProps {
  title: string;
  notificationListData: NotificationsData[];
}

interface Props {
  className?: string;
  open: boolean;
  handleOpen: Function;
}

const NotificationsPage: FC<Props> = ({ open, handleOpen, className }) => {
  const [openNotificationDrawer, setOpenNotificationDrawer] =
    useState<boolean>(false);
  const [value, setValue] = useState<number>(0);
  const { width } = useWindowSize();

  const todayNotifications: NotificationsData[] = NotificationData.filter(
    (notification) =>
      notification.date.toDateString() === new Date().toDateString()
  );

  const oldNotifications: NotificationsData[] = NotificationData.filter(
    (notification) =>
      notification.date.toDateString() !== new Date().toDateString()
  );

  const handleNotificationDrawer = () => {
    setOpenNotificationDrawer(true);
  };

  const changeValue = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    let inputValue: string = event.target.value;
    if (inputValue.length > 5) {
      setValue(2);
    } else {
      setValue(1);
    }
  };

  const GetSearchableScreens: FC = () => {
    return (
      <>
        <Stack spacing={"1.5rem"} marginTop={"1.5rem"}>
          <CommonSearchableScreen
            title={"Today"}
            notificationListData={todayNotifications}
          />
          <CommonSearchableScreen
            title={"Older Notifications"}
            notificationListData={oldNotifications}
          />
        </Stack>
        <NotificationDetail
          openData={openNotificationDrawer}
          handleOpenData={setOpenNotificationDrawer}
        />
      </>
    );
  };

  const NotificationDetail: FC<NotificationDrawerProps> = ({
    openData = false,
    handleOpenData = defaultEmptyFun,
  }) => {
    return (
      <>
        {/* {width >= tabViewPoint ? (
          <CustomPopup
            open={openData}
            handleOpen={() => handleOpenData(false)}
            btnLabel="Close"
            btnStartIcon={<CloseIcon fontSize="small" />}
            className="notification-popup"
          >
            <NotificationPopupBody />
          </CustomPopup>
        ) : ( */}
        <Drawer
          open={openData}
          anchor="bottom"
          onClose={() => handleOpenData(false)}
          className="notification-drawer"
        >
          <NotificationPopupBody />
        </Drawer>
      </>
    );
  };

  const NotFoundComponent: FC = () => {
    return (
      <Box className="not-found-component">
        <CommonGifAnimation animationData={NotFoundGif as T_AnimationData} />
        <Typography
          variant="h4"
          fontWeight={700}
          lineHeight={"1.1rem"}
          textAlign={"center"}
        >
          {"Oops, nothing here."}
        </Typography>
        <Typography
          variant="h6"
          lineHeight=".825rem"
          marginTop=".625rem"
          color="#77787B"
          textAlign="center"
        >
          {
            "Looks like we don’t have any notifications for you. Please visit later."
          }
        </Typography>
      </Box>
    );
  };

  const CommonSearchableScreen: FC<CommonProps> = ({
    title,
    notificationListData,
  }) => {
    return (
      <Box className="notifications">
        <Typography
          variant="h5"
          fontWeight={500}
          color={Colors.arsenic}
          marginBottom={".75rem"}
        >
          {notificationListData.length ? title : ""}
        </Typography>
        {notificationListData &&
          notificationListData.map((notification, index) => (
            <Box className="notification-panel" key={notification.title}>
              <Box display={"flex"} alignItems={"flex-start"}>
                <img
                  src={notification.preIcon}
                  className="notification-icon"
                  alt="notification icon"
                />
                <Box marginLeft={"0.5rem"}>
                  <Box
                    sx={{
                      "& > :not(:first-child)": { marginTop: ".25rem" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight={500}
                      color={Colors.arsenic}
                    >
                      {notification.title}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight={400}
                      color="#8D8C8E"
                      width={"13.9375rem"}
                    >
                      {notification.description}
                    </Typography>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"0.5rem"}
                    paddingTop={"0.5rem"}
                  >
                    <Typography variant="h6" fontWeight={400} color="#8D8C8E">
                      {timeDifferenceInDays(notification.date, new Date())}d
                    </Typography>
                    {notification.isNotificationRead && (
                      <CircleRoundedIcon
                        sx={{
                          color: Colors.red,
                          width: ".3331rem",
                          height: ".3331rem",
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              {notification.isNotificationRead && (
                <>
                  <MoreVertRoundedIcon
                    sx={{
                      width: ".875rem",
                      height: ".875rem",
                      color: "#77787B",
                    }}
                    onClick={() => handleNotificationDrawer()}
                  />
                </>
              )}
            </Box>
          ))}
      </Box>
    );
  };

  return (
    <Dialog
      className={`notification-dialog ${className}`}
      TransitionComponent={Transition}
      fullScreen
      open={open}
    >
      {className === "adaptive-notification" ? (
        <Box
          display="flex"
          margin="1.5rem 1.5rem 0"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h2" lineHeight="1.625rem">
            Notifications
          </Typography>
          <CloseIcon
            sx={{ color: "#757575", cursor: "pointer" }}
            fontSize="small"
            onClick={() => handleOpen(false)}
          />
        </Box>
      ) : (
        <Box className="header-section">
          <KeyboardArrowLeftIcon
            onClick={() => handleOpen(false)}
            className="back-arrow-icon"
          />
          <Typography variant="h3">Notifications</Typography>
        </Box>
      )}
      <Box className="content-wrapper">
        <Box className="notifications-page">
          <TextField
            className="search-field "
            fullWidth
            placeholder="Search for insurance"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onClick={() => {
              setValue(1);
            }}
            onChange={(event) => {
              changeValue(event);
            }}
          />
          {value === 0 || value === 1
            ? GetSearchableScreens("")
            : NotFoundComponent("Not found")}
        </Box>
      </Box>
    </Dialog>
  );
};

export default NotificationsPage;
