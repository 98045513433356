import React, { useEffect, useState } from "react";
import { Grid, TextField, Button, Box } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import { decryptString } from "../../utils/helper";
import { updateLoadingState } from "../../features/Feature_Loading/Loading_Slice";

function Decryption() {
  const [inputValue, setInputValue] = useState("");
  const [resultValue, setResultValue] = useState("");
  const appDispatch = useAppDispatch();
  appDispatch(updateLoadingState(false));

  const configuration = useAppSelector(
    (state) => state.CsAppSlice.tokenDetails?.configuration
  );

  const IV = configuration?.iv || "";
  const secretKey = configuration?.key || "";

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleButtonClick = () => {
    let dcryptedString = decryptString(inputValue, IV, secretKey);
    setResultValue(dcryptedString);
  };

  return (
    <Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        sx={{
          marginTop: "20px",
        }}
      >
        <Grid item xs={8}>
          <TextField
            fullWidth
            variant="outlined"
            size="medium"
            label="Input Text"
            value={inputValue}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            onClick={handleButtonClick}
          >
            Decrypt
          </Button>
        </Grid>
      </Grid>
      <TextField
        sx={{
          width: "600px",
          margin: "40px",
          caretColor: "transparent", // Hides the cursor
        }}
        label="Result"
        variant="outlined"
        multiline
        maxRows={20}
        value={resultValue}
        contentEditable={false}
      />
    </Box>
  );
}

export default Decryption;
