import { FC, ReactElement } from "react";
import { defaultEmptyFun } from "../../utils/helper";
import { Box, Button, Typography } from "@mui/material";
import OfferSection from "../CustomOfferSection/OfferSection";
import CommonGifAnimation from "../CustomGifAnimation/CommonGifAnimation";
import HealthSuccessGif from "../../assets/gif/Success.json";
import { T_AnimationData } from "../../globalTypes/GlobalTypes";
import InfoIcon from "../../assets/icons/information.svg";
import InfoOutlineIcon from "../../assets/icons/information-outline.svg";
import { Colors } from "../../styles/constant";
import { unary } from "lodash";
import useWindowSize from "../../hooks/useWindowSize";
import { tabViewPoint } from "../../utils/constants";

interface Props {
  title?: string;
  titleDark?: boolean;
  des: string;
  btnLabel: string;
  btnOnClick?: any;
  stepValue?: number;
  btnDisabled?: any;
  withInfo?: boolean;
  withInfoText?: string;
  extraInfo?: any;
  subDescription?: string;
  additionalInfo?: boolean;
  additionalContent?: ReactElement;
  offerTitle?:string;
  isChangesForHomeLoan?: boolean;
}

const CommonSuccessPopupBody: FC<Props> = ({
  title,
  des,
  subDescription = null,
  btnLabel,
  btnOnClick = defaultEmptyFun,
  stepValue = null,
  btnDisabled = false,
  withInfo = false,
  titleDark = false,
  withInfoText = null,
  extraInfo = undefined,
  additionalInfo = false,
  additionalContent,
  offerTitle,
  isChangesForHomeLoan = false,
}) => {
  const { width } = useWindowSize();
  const handleClick = () => {
    if (stepValue === null) btnOnClick();
    else btnOnClick(stepValue);
  };
  return (
    <Box className="common-success-popup-body">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={
          isChangesForHomeLoan && width >= tabViewPoint ? "6rem" : "13.5625rem"
        }
      >
        <CommonGifAnimation
          width="16.875rem"
          animationData={HealthSuccessGif as T_AnimationData}
        />
      </Box>
      {title && (
        <Typography
          marginTop="2rem"
          textAlign="center"
          variant="h3"
          color={titleDark ? Colors.textblack : undefined}
          fontWeight={titleDark ? 600 : undefined}
        >
          {title}
        </Typography>
      )}
      {isChangesForHomeLoan ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography
            marginTop="1rem"
            textAlign="center"
            variant="h3"
            marginBottom={"1rem"}
            color={Colors.textblack}
            width="18.5rem"
            height="2.75rem"
          >
            {des}
          </Typography>
        </Box>
      ) : (
        <Typography
          marginTop=".625rem"
          textAlign="center"
          variant="subtitle2"
          marginBottom={"1rem"}
        >
          {des}
        </Typography>
      )}

      {subDescription && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            padding: "0.563rem",
          }}
        >
          <img src={InfoOutlineIcon} style={{ marginRight: ".5rem" }} />
          <Typography
            textAlign="center"
            variant="h5"
            color="#767D93"
            width="13rem"
            marginBottom={"1rem"}
          >
            {subDescription}
          </Typography>
        </Box>
      )}
      {/*the below offersection is removed from sprint-1 as of now, when it will be uncommented , need to remove marginBottom from above Typography  */}
      {offerTitle?<OfferSection title={offerTitle?offerTitle:""}/>:""}
      {withInfo && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            backgroundColor: "#FFF4CE",
            borderRadius: "1rem",
            padding: "0.563rem",
          }}
        >
          <img src={InfoIcon} style={{ marginRight: ".5rem" }} />
          <Typography
            textAlign="center"
            variant="h6"
            color={withInfoText ? Colors.textGray : undefined}
          >
            {withInfoText
              ? withInfoText
              : `Your changes will reflect in 3 days.`}
          </Typography>
        </Box>
      )}
      {extraInfo}
      {additionalInfo && additionalContent}
      <Button
        fullWidth
        variant="contained"
        className="popup-btn"
        onClick={handleClick}
        disabled={btnDisabled}
      >
        {btnLabel}
      </Button>
    </Box>
  );
};

export default CommonSuccessPopupBody;
