import { FC, useEffect, useState } from "react";
import CsSessionStorageUtils from "../../../utils/CsSessionStorageUtils";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { T_CustomerLobDetailObj } from "../../Feature_Login/Login_Types";
import { HiLobCode } from "../../../utils/constants";
import { isEmpty, uniqBy } from "lodash";
import {
  T_AppMobileNumberObj,
  T_PolicyInsured,
} from "../HealthInsurance_Types";
import {
  useLazyFetchPolicyDetailsByMobileNumberQuery,
  useLazyFetchPolicyInsuredQuery,
} from "../HealthInsurance_Api";
import {
  setHiAllPolicyDetails,
  setHiAllPolicyInsuredDetails,
  setHiSecondaryAllPolicyDetails,
} from "../HealthInsurance_Slice";
import { CustomAccordionSkeleton } from "../../../components/CustomAccordianSkeletonShimmer/CustomAccordianSkeletonShimmer";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion";
import { Box, Stack, Typography } from "@mui/material";
import HiDashboardCard from "../HiDashboardCard/HiDashboardCard";
import heartIcon from "../../../assets/icons/heart-icon.svg";
import { generatePath, useNavigate } from "react-router-dom";
import { R_HiRoutes } from "../HealthInsurance_Routes";
import { T_LiRouteState } from "../../../globalTypes/GlobalTypes";
import { setSelectedModule } from "../../Feature_GeneralAppElements/GeneralAppElements_Slice";

interface Props {
  moduleName?: string;
}

export const HiDashboard: FC<Props> = ({ moduleName = "" }) => {
  const [apiCallRunning, setApiCallRunning] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const customerAndLobDetails = userDetails?.customerAndLobDetails;
  const [isHiPolicyDetailsFetching, setIsHiPolicyDetailsFetching] =
    useState<boolean>(false);
  const [isApiSuccessArray, setIsApiSuccessArray] = useState<string[]>([]);
  const [fetchPolicyInsuredApi] = useLazyFetchPolicyInsuredQuery();
  const hiPrimaryAllPolicyDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyDetails
  );
  const hiSecondaryAllPolicyDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.secondaryAllPolicyDetails
  );
  const hiAllPolicyInsuredDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyInsuredDetails
  );
  const primaryUserHi = customerAndLobDetails?.filter(
    (item: T_CustomerLobDetailObj) =>
      item.LOB === HiLobCode && item.PRIMARY_SECONDARY_USER === "primary"
  );
  const secondaryUserHi = customerAndLobDetails?.filter(
    (item: T_CustomerLobDetailObj) =>
      item.LOB === HiLobCode && item.PRIMARY_SECONDARY_USER === "secondary"
  );
  let totalHiPolicyCount =
    hiPrimaryAllPolicyDetails?.length + hiSecondaryAllPolicyDetails?.length;

  const [fetchPolicyDetailsByMobileApi] =
    useLazyFetchPolicyDetailsByMobileNumberQuery();

  useEffect(() => {
    ApiCalls();
  }, []);

  useEffect(() => {
    ApiCalls();
  }, [customerAndLobDetails]);

  const ApiCalls = async () => {
    setApiCallRunning(true);
    await fetchAllHiPolicyDetailsByMobile();
    setApiCallRunning(false);
  };

  const fetchAllHiPolicyDetailsByMobile = async () => {
    if (primaryUserHi && primaryUserHi?.length > 0) {
      const primaryUserHiPromises = uniqBy(primaryUserHi, "MOBILE")?.map(
        (item: T_CustomerLobDetailObj) => {
          return fetchPolicyDetailsByMobileApi(item.MOBILE, true);
        }
      );
      let primaryUserResults = await Promise.all(primaryUserHiPromises);
      let transformedPrimaryUserResults: T_AppMobileNumberObj[] = [];
      primaryUserResults.forEach((result) => {
        if (
          result.data?.payload.Response &&
          !isEmpty(result?.data?.payload.Response)
        ) {
          transformedPrimaryUserResults.push(...result.data?.payload.Response);
        }
      });
      dispatch(setHiAllPolicyDetails(transformedPrimaryUserResults));
    }
    if (secondaryUserHi && secondaryUserHi?.length > 0) {
      const secondaryUserHiPromises = uniqBy(secondaryUserHi, "MOBILE")?.map(
        (item: T_CustomerLobDetailObj) => {
          return fetchPolicyDetailsByMobileApi(item.MOBILE, true);
        }
      );
      let secondaryUserResults = await Promise.all(secondaryUserHiPromises);
      let transformedSecondaryUserResults: T_AppMobileNumberObj[] = [];
      secondaryUserResults.forEach((result) => {
        if (
          result.data?.payload.Response &&
          !isEmpty(result?.data?.payload.Response)
        ) {
          let policiesArray = result.data?.payload.Response;
          let selectedPolicy = policiesArray.filter((item) => {
            let policyNumberArray = secondaryUserHi.map(
              (i) => i.HOLDING_IDENTIFIER_ONE
            );
            return policyNumberArray.includes(item.PolicyNumber);
          });
          if (selectedPolicy.length > 0) {
            transformedSecondaryUserResults.push(...selectedPolicy);
          }
        }
      });
      dispatch(setHiSecondaryAllPolicyDetails(transformedSecondaryUserResults));
    }
  };

  const hiAccordionClick = async () => {
    dispatch(setSelectedModule(moduleName));
    let promises = [
      ...hiPrimaryAllPolicyDetails,
      ...hiSecondaryAllPolicyDetails,
    ].map((item) => {
      return fetchPolicyInsuredApi(item.PolicyNumber, true);
    });
    setIsHiPolicyDetailsFetching(true);
    let results = await Promise.all(promises);
    let transformedResult: T_PolicyInsured[] = [];
    results.forEach((res) => {
      if (res.data?.code === 1 && res?.data?.payload?.Response) {
        setIsApiSuccessArray((prevState) => {
          return [
            ...prevState,
            res?.data?.payload?.Response.PolicyDetail.PolicyNumber || "",
          ];
        });
        transformedResult.push(res.data.payload.Response);
      }
    });
    dispatch(setHiAllPolicyInsuredDetails(transformedResult));
    setIsHiPolicyDetailsFetching(false);
  };

  const tryAgainHandler = async (policyNumber: string) => {
    await fetchPolicyInsuredApi(policyNumber, true).then((res) => {
      if (res.data?.code === 1 && res?.data?.payload?.Response) {
        setIsApiSuccessArray((prevState) => {
          return [
            ...prevState,
            res?.data?.payload?.Response.PolicyDetail.PolicyNumber || "",
          ];
        });
        dispatch(
          setHiAllPolicyInsuredDetails([
            ...hiAllPolicyInsuredDetails,
            res?.data?.payload?.Response,
          ])
        );
      }
    });
  };

  return (
    <>
      {!apiCallRunning ? (
        totalHiPolicyCount > 0 && (
          <CustomAccordion
            title={`Health Insurance (${totalHiPolicyCount})`}
            preIcon={
              <img src={heartIcon} alt="heart" className="accordion-icon" />
            }
            handleAccordionClick={hiAccordionClick}
          >
            {hiPrimaryAllPolicyDetails.length > 0 && (
              <>
                <Typography variant="h5" marginBottom="1rem">
                  My Holding ({hiPrimaryAllPolicyDetails.length})
                </Typography>
                <Stack rowGap="1rem">
                  {hiPrimaryAllPolicyDetails.map(
                    (item: T_AppMobileNumberObj) => {
                      let policyDetails = hiAllPolicyInsuredDetails?.find(
                        (el) =>
                          el?.PolicyDetail?.PolicyNumber === item.PolicyNumber
                      );
                      return (
                        <HiDashboardCard
                          data={item}
                          policyDetails={policyDetails}
                          isHiPolicyDetailsFetched={isHiPolicyDetailsFetching}
                          viewDetailsClick={() => {
                            const stateData: T_LiRouteState = {
                              policyNumber: item.PolicyNumber,
                            };
                            navigate(R_HiRoutes.details_PolicyNumber, {
                              state: stateData,
                            });
                          }}
                          isApiSuccessArray={isApiSuccessArray}
                          tryAgainHandler={tryAgainHandler}
                        />
                      );
                    }
                  )}
                </Stack>
              </>
            )}
            <Box
              marginTop={
                hiPrimaryAllPolicyDetails.length > 0 ? "1.5rem" : "0.5rem"
              }
            >
              {hiSecondaryAllPolicyDetails.length > 0 && (
                <>
                  <Typography variant="h5" marginBottom="0.5rem">
                    Other Holding ({hiSecondaryAllPolicyDetails.length})
                  </Typography>
                  <Stack rowGap="1rem">
                    {hiSecondaryAllPolicyDetails.map(
                      (item: T_AppMobileNumberObj) => {
                        let policyDetails = hiAllPolicyInsuredDetails?.find(
                          (el: T_PolicyInsured) =>
                            el?.PolicyDetail?.PolicyNumber === item.PolicyNumber
                        );
                        return (
                          <HiDashboardCard
                            data={item}
                            isOtherHolding={true}
                            policyDetails={policyDetails}
                            isHiPolicyDetailsFetched={isHiPolicyDetailsFetching}
                            isApiSuccessArray={isApiSuccessArray}
                            tryAgainHandler={tryAgainHandler}
                          />
                        );
                      }
                    )}
                  </Stack>
                </>
              )}
            </Box>
          </CustomAccordion>
        )
      ) : (
        <CustomAccordionSkeleton />
      )}
    </>
  );
};
