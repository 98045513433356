import { Box, Grid, Typography } from "@mui/material";
import { FC, useCallback } from "react";

interface RadioProps {
  title: string;
  value: string;
  handleRadioChange: Function;
  selectedValue: any;
  index: number;
}

const CustomItemSelect: FC<RadioProps> = ({
  title,
  value,
  handleRadioChange,
  selectedValue,
  index,
}) => {
  const handleChange = useCallback(() => {
    handleRadioChange(index);
  }, [index]);

  return (
    <Box
      className={`sl-custom-item-select ${
        selectedValue.value === value ? "sl-custom-item-select-active" : ""
      } `}
      onClick={handleChange}
    >
      <Typography variant="h5">{title}</Typography>
    </Box>
  );
};
export default CustomItemSelect;
