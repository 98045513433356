import { useState, useEffect } from "react";

import { DEVICE_TYPE } from "../utils/constants";

const useDeviceDetection = () => {
  const [environment, setEnvironment] = useState<DEVICE_TYPE>(DEVICE_TYPE.WEB);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|windows phone/g.test(userAgent);
    const isPWA = window.matchMedia("(display-mode: standalone)").matches;

    if (isPWA) {
      if (isMobile) {
        setEnvironment(DEVICE_TYPE.PWA_MOBILE);
      } else {
        setEnvironment(DEVICE_TYPE.PWA_WEB);
      }
    } else if (isMobile) {
      setEnvironment(DEVICE_TYPE.MOBILE);
    } else {
      setEnvironment(DEVICE_TYPE.WEB);
    }
  }, [navigator.userAgent]);

  return environment;
};

export default useDeviceDetection;
