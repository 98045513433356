import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ABCDLogo from "../../assets/images/abcd-logo.png";
import { AppBar, Box, Container } from "@mui/material";
import "./CustomNavbar.scss";
import { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import {
  T_ValidateSSOOldRes,
  TriggerGenerateSSO_OLD,
} from "../../features/Feature_SSO/Sso_Types";
import {
  E_ChannelOptions,
  T_CustomerLobDetails,
  T_LogoutInfoToBePassed,
  T_UserProfile,
} from "../../globalTypes/GlobalTypes";
import { triggerGenerateSSOOld } from "../../features/Feature_SSO/Sso_Slice";
import { updateMedicalReportInlineFunClicked } from "../../features/Feature_HealthInsurance/HealthInsurance_Slice";
import {
  EVENT_HANDLER,
  OneLoginLobCode,
  OneLoginRedirectUrl,
  REACT_APP_URL,
  tabViewPoint,
} from "../../utils/constants";
import { useLazyLogoutApiQuery } from "../../features/Feature_GeneralAppElements/GeneralAppElements_Api";
import { goToSuperAppInfo, propagateEvent } from "../../utils/helper";
import { updateLoadingState } from "../../features/Feature_Loading/Loading_Slice";
import useWindowSize from "../../hooks/useWindowSize";
import CustomNetworkImage from "../CustomNetworkImage/CustomNetworkImage";
import { T_GenerateTokenRes } from "../../features/Feature_Login/Login_Types";

interface Props {
  className?: string;
}

const Navbar: FC<Props> = ({ className = "" }) => {
  const navigate = useNavigate();
  const ssoSlice = useAppSelector((state) => state.SsoSlice.ssoData);
  const csAppSlice = useAppSelector((state) => state.CsAppSlice);
  const medicalReportDownloaded = useAppSelector(
    (state) => state.HealthInsuranceSlice.medicalReportDownloaded
  );
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const tokenDetails = useAppSelector((state) => state.CsAppSlice.tokenDetails);
  const { pathname } = useLocation();
  const { width } = useWindowSize();

  const [getLogOutApi] = useLazyLogoutApiQuery();

  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const cmsLogo =
    cmsData.find((i) => i.attributes.cmsType === "ABC Logo")?.attributes.image
      .data?.attributes.url ?? "";

  /**
   * Trigger event to super app
   */
  const triggerEventToSuperApp = () => {
    const backToSuperAppExtraInfo = goToSuperAppInfo(
      tokenDetails as T_GenerateTokenRes,
      userDetails as T_UserProfile & T_CustomerLobDetails
    );
    dispatch({
      type: EVENT_HANDLER.GO_TO_SUPER_APP_EVENT,
      payload: backToSuperAppExtraInfo,
    });
  };

  const backBtnFunc = () => {
    if ("/no-holdings" === pathname || "/onboarding" === pathname) {
      if (csAppSlice.channel === E_ChannelOptions.SUPER_APP) {
        triggerEventToSuperApp();
      } else {
        dispatch(updateLoadingState(true));
        getLogOutApi({
          csUserId: "" + userDetails?.CS_USER_ID,
        }).then(async (res) => {
          dispatch({
            type: "USER_LOGOUT",
          });
          dispatch(updateLoadingState(false));
        });
      }
      return;
    }
    if (medicalReportDownloaded) {
      dispatch(updateMedicalReportInlineFunClicked(true));
    }
    if (
      csAppSlice.channel === E_ChannelOptions.ONE_ABC &&
      pathname === (ssoSlice as T_ValidateSSOOldRes)?.urlConstructured
    ) {
      dispatch(updateLoadingState(true));
      dispatch(
        triggerGenerateSSOOld({
          triggerGenerateSSO_Old:
            (ssoSlice as TriggerGenerateSSO_OLD).triggerGenerateSSO_Old || true,
          ciiNo: ssoSlice.ciiNo,
          functionality: "D",
          mobileNo: ssoSlice.mobileNo || "",
          lobClientId: ssoSlice.lobClientId,
          channel: E_ChannelOptions.CSAPP,
          sourceLobId: "11",
          destinationLobId: OneLoginLobCode,
          callbackUrl: `${REACT_APP_URL}/login`,
          redirectionUrl: OneLoginRedirectUrl,
          appVersion: "1",
          panNumber: ssoSlice.panNumber || "",
          customerFullName: ssoSlice.customerFullName || "",
          customerDob: ssoSlice.customerDob || "",
          emailId: ssoSlice.emailId || "",
          osVersion: "Windows",
          imei: "1231312",
          deviceId: "131312",
          userAgent: navigator.userAgent,
          clientIpAddress: "",
          udp: "",
          udp2: "",
          udp3: "",
          udp4: "",
          udp5: "",
          udp6: "",
          udp7: "",
          udp8: "",
          udp9: "",
          udp10: "",
        } as TriggerGenerateSSO_OLD)
      );
    } else {
      navigate(-1);
    }
  };

  return (
    <AppBar
      className={`navbar ${className}`}
      position="static"
      sx={{
        backgroundColor: "white",
        boxShadow: "0rem .5rem 1rem 0rem #0000000A",
      }}
    >
      <Container
        sx={{ display: "flex", alignItems: "center", paddingY: ".625rem" }}
      >
        {className !== "from-loading" && (
          <Box className="back-arrow-wrapper" onClick={backBtnFunc}>
            <KeyboardArrowLeftIcon className="back-arrow-icon" />
          </Box>
        )}
        <Box
          display="flex"
          justifyContent={width >= tabViewPoint ? "" : "center"}
          flexGrow={1}
          marginRight={className !== "from-loading" ? "2.5rem" : "0"}
        >
          <CustomNetworkImage fallBackImage= { <img src={ABCDLogo} alt=""/>} src={cmsLogo} alt="aditya-birla" />
        </Box>
      </Container>
    </AppBar>
  );
};

export default Navbar;
