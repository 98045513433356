import { FC, useCallback, useEffect, useRef, useState } from "react";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import otpVerificationImage from "../../../assets/icons/otp-verification.svg";
import { Colors } from "../../../styles/constant";
import { MuiOtpInput } from "mui-one-time-password-input";
import { T_PolicyAllDetails } from "../LifeInsurance_Types";
import { getMaskedEmail, isValidateEmail } from "../../../utils/helper";
import ArrowRightIcon from "../../../assets/icons/red-arrow-right.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import {
  useLazyLiGetOtpQuery,
  useLazyLiValidateOtpQuery,
} from "../LifeInsurance_Api";
import { API_STATUS_CODE } from "../../../utils/ApiEndpoints";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";

interface Props {
  open: boolean;
  handleOpen: Function;
  data?: T_PolicyAllDetails;
  handleCrmCaseCreate: Function;
  handleDocUploadFun: Function;
}

const UpdatePANPopups: FC<Props> = ({
  open,
  handleOpen,
  data,
  handleCrmCaseCreate,
  handleDocUploadFun,
}) => {
  const masterData = useAppSelector(
    (state) => state.GeneralAppElementsSlice?.masterData
  );
  const masterDetails = masterData?.masterDetail;
  const wrongOtpCountAllowed = parseInt(
    masterDetails?.wrong_otp_count_allowed?.find(
      (item) => item.masterKey === "limit"
    )?.masterValue || "3"
  );
  const retryCount = parseInt(
    masterDetails?.retry_count?.find(
      (item) => item.masterKey === "otpBypassValue"
    )?.masterValue || "3"
  );
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [screenTag, setScreenTag] = useState<string>("verifyOtp");
  const [otpValue, setOtpValue] = useState<string>("");
  const [count, setCount] = useState<number>(60);
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");
  const [otpVerifyAttemptLeft, setOtpVerifyAttemptLeft] =
    useState<number>(wrongOtpCountAllowed);
  const [reSendOtpAttemptLeft, setReSendOtpAttemptLeft] =
    useState<number>(retryCount);

  const isTimerOn = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [liGetOtpApi] = useLazyLiGetOtpQuery();
  const [liValidateOtpApi] = useLazyLiValidateOtpQuery();

  const otpByPassValue =
    masterData?.masterDetail?.otpBypass?.find(
      (item) => item.masterKey === "otpBypassValue"
    )?.masterValue || "";

  const isOtpBypassEnable =
    masterData?.masterDetail?.otpBypass?.find(
      (item) => item.masterKey === "otpBypassFlag"
    )?.masterValue || "";

  let mobileNumber =
    data?.Owner.MOBILE_CONTACT?.replace(/\s/g, "")?.slice(-10) || "";
  let emailId = data?.Owner.EMAIL_ID || "";

  const handleClosePopup = useCallback(() => {
    if (screenTag === "verifyOtp") {
      handleOpen(false);
    } else {
      navigate(-1);
    }
  }, [handleOpen, screenTag]);

  const startTimer = useCallback(() => {
    isTimerOn.current = true;
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount <= 0) {
          clearInterval(intervalId);
          isTimerOn.current = false;
          return 60;
        }
        return newCount;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    startTimer();
  }, []);

  const handleVerifyOtpClick = () => {
    if (isOtpBypassEnable === "true") {
      handleBypassOtp();
    } else {
      handleActualOtpVerify();
    }
  };

  const handleBypassOtp = () => {
    if (otpValue === otpByPassValue) {
      setIsBtnLoading(true);
      handleDocUploadAndCrmCaseCreate();
    } else {
      setOtpErrorMessage("Incorrect OTP, please try again later");
      setOtpValue("");
      if (otpVerifyAttemptLeft > 0) {
        setOtpVerifyAttemptLeft((prevCount) => prevCount - 1);
      }
    }
  };

  const handleActualOtpVerify = () => {
    let reqBody = {
      Source: "SVCAPP",
      MobileNo: mobileNumber,
      Functionality: "SVC_UPDATE_PAN_OTP",
      OTP: otpValue,
    };
    setIsBtnLoading(true);
    liValidateOtpApi(reqBody).then((res) => {
      if (res.isError || res.data?.code !== API_STATUS_CODE.SUCCESS) {
        setOtpErrorMessage("Incorrect OTP, please try again later");
        setOtpValue("");
        if (otpVerifyAttemptLeft > 0) {
          setOtpVerifyAttemptLeft((prevCount) => prevCount - 1);
        }
        setIsBtnLoading(false);
        return;
      }
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        handleDocUploadAndCrmCaseCreate();
      }
    });
  };

  const handleDocUploadAndCrmCaseCreate = async () => {
    let isDocUploadSuccess = false;
    let isCrmCaseCreateSuccess = false;
    isDocUploadSuccess = await handleDocUploadFun();
    if (isDocUploadSuccess) {
      isCrmCaseCreateSuccess = await handleCrmCaseCreate();
    }
    setIsBtnLoading(false);
    if (isCrmCaseCreateSuccess) {
      setScreenTag("success");
    }
  };

  const handleResendOTP = () => {
    let requestBody = {
      Source: "SVCAPP",
      MobileNo: mobileNumber,
      Functionality: "SVC_UPDATE_PAN_OTP",
      EmailId: isValidateEmail(emailId) ? emailId : "",
    };
    setOtpValue("");
    liGetOtpApi(requestBody).then((res) => {
      if (
        res.data?.code === API_STATUS_CODE.SUCCESS &&
        res.data.payload.smsAPI.statusCode === "200"
      ) {
        setReSendOtpAttemptLeft((prev) => prev - 1);
        setOtpErrorMessage("");
        startTimer();
      }
      if (res.isError || res.data?.code !== API_STATUS_CODE.SUCCESS) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "generateOtp API failed",
          })
        );
      }
    });
  };

  const handleOnChangeOTP = (value: string) => {
    setOtpValue(value);
    setOtpErrorMessage("");
  };

  const renderSteps = () => {
    if (screenTag === "verifyOtp") {
      return (
        <Box className="otp-verification">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <img src={otpVerificationImage} alt="otp" />
            <Typography textAlign="center" marginTop="1.5rem" variant="h2">
              OTP Verification
            </Typography>
            <Typography
              textAlign="center"
              marginTop=".25rem"
              variant="subtitle2"
              color={Colors.textGray}
            >
              We’ve sent an OTP to your registered mobile number{" "}
              {mobileNumber.slice(0, 2) +
                `X`.repeat(6) +
                mobileNumber.slice(-2)}{" "}
              and email id {getMaskedEmail(emailId)}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="fit-content"
            marginX="auto"
          >
            <MuiOtpInput
              className={`otp-input ${
                otpErrorMessage.length > 0 ? "error" : ""
              }`}
              length={6}
              value={otpValue}
              onChange={handleOnChangeOTP}
              validateChar={(char) => /^[0-9]$/.test(char)}
            />
            {otpErrorMessage && (
              <Box display="flex" gap={".25rem"} alignItems="center">
                <img src={AlertRhombus} />
                <Typography marginY={"0.5rem"} color={Colors.red} variant="h6">
                  {otpErrorMessage}
                </Typography>
              </Box>
            )}
            {isTimerOn.current ? (
              <Typography
                marginTop=".75rem"
                fontSize={12}
                fontWeight={500}
                color="#9C9C9C"
              >
                {`Resend OTP in 00:${count.toString().padStart(2, "0")}s`}
              </Typography>
            ) : reSendOtpAttemptLeft > 0 ? (
              <Button
                variant="text"
                sx={{
                  fontSize: ".75rem",
                  paddingY: ".75rem",
                  width: "fit-content",
                }}
                onClick={handleResendOTP}
              >
                Resend OTP
              </Button>
            ) : null}
          </Box>
          <Box marginTop="2rem">
            {isBtnLoading ? (
              <CustomThreeDotsLoader />
            ) : (
              <Button
                fullWidth
                variant="contained"
                className="verify-otp-popup-btn"
                disabled={otpVerifyAttemptLeft <= 0 || otpValue.length < 6}
                onClick={handleVerifyOtpClick}
              >
                Verify
              </Button>
            )}
          </Box>
        </Box>
      );
    } else if (screenTag === "success") {
      return (
        <CommonSuccessPopupBody
          title="Congratulations!"
          des="You have successfully submitted your request to update PAN"
          btnLabel="Rate Your Experience"
          withInfo={true}
          btnDisabled={true}
        />
      );
    }
  };

  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      btnLabel={screenTag === "success" ? "Skip to policy details" : "Close"}
      className="update-PAN-popup"
      btnStartIcon={
        screenTag === "verifyOtp" && (
          <CloseIcon sx={{ fontSize: "1.0625rem" }} />
        )
      }
      btnEndIcon={
        screenTag === "success" && (
          <img
            src={ArrowRightIcon}
            alt="right-arrow"
            style={{ color: Colors.red }}
          />
        )
      }
    >
      <Box className="update-PAN-popup-body-wrapper">{renderSteps()}</Box>
    </CustomPopup>
  );
};

export default UpdatePANPopups;
