import { FC, useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import PSIcon from "../../../assets/icons/policyaccstatement.svg";
import "./SetAlerts.scss";
import { Colors } from "../../../styles/constant";
import { SET_ALERT_MESSAGE, tabViewPoint } from "../../../utils/constants";
import FundValueIcon from "../../../assets/icons/fundvalue.svg";
import NAVIcon from "../../../assets/icons/nav.svg";
import FundValueLimitIcon from "../../../assets/icons/fundvaluelimit.svg";
import TaxCertificateIcon from "../../../assets/icons/taxcertificate.svg";
import GracePeriodIcon from "../../../assets/icons/graceperiod.svg";
import { values } from "lodash";
import RenderModeOfFrequency from "./RenderModeOfFrequency/RenderModeOfFrequency";
import RenderDaysInAdvance from "./RenderDaysInAdvance/RenderDaysInAdvance";
import CustomSelectFundValue from "./CustomSelectFundValue/CustomSelectFundValue";
import CustomSelectFundValueLimit from "./CustomSelectFundValueLimit/CustomSelectFundValueLimit";
import CustomExistingAlert, {
  SetExistingAlertSuccess,
} from "./CustomExistingAlert/CustomExistingAlert";
import RenderAlertTypeButton from "./RenderAlertTypeButton/RenderAlertTypeButton";
import RenderTypeButton from "./RenderTypeButton/RenderTypeButton";
import RenderAlertOption from "./RenderAlertOption/RenderAlertOption";
import CustomLink from "../../../components/CustomLink/CustomLink";
import CustomLinkOptions from "./CustomLinkOptions/CustomLinkOptions";
import {
  useLazyLiExistingAlertsQuery,
  useLazyLiFundDetailsQuery,
} from "../LifeInsurance_Api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import {
  T_LiExistingAlert_Response,
  T_PolicyAllDetails,
} from "../LifeInsurance_Types";
import { T_LiRouteState } from "../../../globalTypes/GlobalTypes";
import { useLocation } from "react-router-dom";
import { API_STATUS_CODE } from "../../../utils/ApiEndpoints";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import dayjs from "dayjs";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import useWindowSize from "../../../hooks/useWindowSize";
import CustomFundDetails from "./CustomFundDetails/CustomFundDetails";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";

type workflowInfo = {
  [key: string]: any;
};

const setAlertOptions: workflowInfo = {
  "Service Related": [
    {
      title: "Tax Certificate",
      icon: TaxCertificateIcon,
      successMessage: "Alert has been set for Tax certificate",
      options: ["Frequency"],
      selectedValues: { Frequency: "", DeliveryMode: "Email" },
      validateInputs: { Frequency: false },
      Frequency: [
        {
          title: "Monthly",
          value: "Monthly",
        },
        {
          title: "Quaterly",
          value: "Quaterly",
        },
        {
          title: "Semi Annual",
          value: "Semi Annual",
        },
        {
          title: "Annual",
          value: "Annual",
        },
      ],
    },
    {
      title: "Policy Account Statement",
      icon: PSIcon,
      options: ["Frequency"],
      successMessage: "Alert has been set for policy account statement",
      selectedValues: { Frequency: "", DeliveryMode: "Email" },
      validateInputs: { Frequency: false },
      Frequency: [
        {
          title: "Monthly",
          value: "Monthly",
        },
        {
          title: "Quaterly",
          value: "Quaterly",
        },
        {
          title: "Semi Annual",
          value: "Semi Annual",
        },
        {
          title: "Annual",
          value: "Annual",
        },
      ],
    },
    {
      title: "Grace Period",
      icon: GracePeriodIcon,
      options: ["daysInAdvance", "DeliveryMode"],
      successMessage: "Alert has been set for grace period",
      selectedValues: { Frequency: "", DeliveryMode: "" },
      validateInputs: { daysInAdvance: false, DeliveryMode: false },
      daysInAdvance: [
        {
          title: "3 Days",
          value: "3",
        },
        {
          title: "5 Days",
          value: "5",
        },
        {
          title: "8 Days",
          value: "8",
        },
      ],
      DeliveryMode: [
        {
          title: "Email",
          value: "Email",
        },
        {
          title: "SMS",
          value: "SMS",
        },
      ],
    },
  ],
  "Fund Related": [
    {
      title: "Fund Value",
      icon: FundValueIcon,
      options: ["linkOptions", "Frequency", "DeliveryMode"],
      successMessage:
        "You have successfully set frequency for fund value alert on your policy.",
      selectedValues: { Frequency: "", DeliveryMode: "" },
      validateInputs: { Frequency: false, DeliveryMode: false },
      linkOptions: [
        {
          title: "Know Funds",
          url: "dummyurl",
        },
        {
          title: "Switch Funds",
          value: "dummyurl",
        },
        {
          title: "Premium Redirection",
          value: "dummyurl",
        },
      ],
      Frequency: [
        {
          title: "Monthly",
          value: "Monthly",
        },
        {
          title: "Quaterly",
          value: "Quaterly",
        },
        {
          title: "Semi Annual",
          value: "Semi Annual",
        },
        {
          title: "Annual",
          value: "Annual",
        },
      ],
      DeliveryMode: [
        {
          title: "Email",
          value: "Email",
        },
        {
          title: "SMS",
          value: "SMS",
        },
      ],
    },
    {
      title: "NAV",
      icon: NAVIcon,
      options: ["selectFundWithAlert", "DeliveryMode"],
      successMessage:
        "You have successfully set frequency for NAV value alert on your policy.",
      selectedValues: { selectFundWithAlert: "", DeliveryMode: "" },
      validateInputs: {
        selectFundWithAlert: false,
        DeliveryMode: false,
      },
      selectFundWithAlert: [
        {
          title: "Tsets",
          subDesc: "astsd",
          value: 59.55,
          direction: "up",
        },
        {
          title: "Tsets2",
          subDesc: "2",
          value: 59.55,
          direction: "down",
        },
      ],
      DeliveryMode: [
        {
          title: "Email",
          value: "Email",
        },
        {
          title: "SMS",
          value: "SMS",
        },
      ],
    },
    {
      title: "Fund Value Limit",
      icon: FundValueLimitIcon,
      options: ["NAVfundType", "DeliveryMode"],
      successMessage:
        "You have successfully set frequency for Fund value limit on your policy.",
      selectedValues: { NAVfundType: "", DeliveryMode: "" },
      validateInputs: { NAVfundType: false, DeliveryMode: false },
      NAVfundType: [
        {
          title: 1234567,
          subDesc: "astsd",
          value: 59.55,
          direction: "up",
        },
        {
          title: 1234567,
          subDesc: "2",
          value: 59.55,
          direction: "down",
        },
      ],
      DeliveryMode: [
        {
          title: "Email",
          value: "Email",
        },
        {
          title: "SMS",
          value: "SMS",
        },
      ],
    },
  ],
};

const existingAlertOptions: workflowInfo = {
  "Service Related": [
    {
      title: "Tax Certificate",
      icon: TaxCertificateIcon,
      options: ["existingAlert"],
      selectedValues: { existingAlert: "" },
      validateInputs: { existingAlert: false },
      existingAlert: [
        {
          policyNumber: "008976695",
          contents: [
            { label: "Frequency", value: "Monthly" },
            { label: "Mode", value: "Email" },
          ],
        },
      ],
    },
    {
      title: "Policy Account Statement",
      icon: PSIcon,
      options: ["existingAlert"],
      selectedValues: { existingAlert: "" },
      validateInputs: { existingAlert: false },
      existingAlert: [
        {
          policyNumber: "008976695",
          contents: [
            { label: "Frequency", value: "Monthly" },
            { label: "Mode", value: "Email" },
          ],
        },
      ],
    },
    {
      title: "Grace Period",
      icon: GracePeriodIcon,
      options: ["existingAlert"],
      selectedValues: { existingAlert: "" },
      validateInputs: { existingAlert: false },
      existingAlert: [
        {
          policyNumber: "008976695",

          contents: [
            { label: "Days in Advance", value: "3 Days" },
            { label: "Mode", value: "Email" },
          ],
        },
      ],
    },
  ],
  "Fund Related": [
    {
      title: "Fund Value",
      icon: FundValueIcon,
      options: ["existingAlert"],
      selectedValues: { existingAlert: "" },
      validateInputs: { existingAlert: false },
      existingAlert: [
        {
          policyNumber: "008976695",
          contents: [
            { label: "Frequency", value: "Monthly" },
            { label: "Mode", value: "Email" },
          ],
        },
      ],
    },
    {
      title: "NAV",
      icon: NAVIcon,
      options: ["existingAlert"],
      selectedValues: { existingAlert: "" },
      validateInputs: { existingAlert: false },
      existingAlert: [
        {
          policyNumber: "008976695",
          contents: [
            { label: "If NAV value ", value: "Goes beyond 60" },
            { label: "If NAV value ", value: "Goes beyond 50" },
          ],
          subContent: {
            label: "Mode",
            value: "SMS",
          },
        },
      ],
    },
    {
      title: "Fund Value Limit",
      icon: FundValueLimitIcon,
      options: ["existingAlert"],
      selectedValues: { existingAlert: "" },
      validateInputs: { existingAlert: false },
      existingAlert: [
        {
          policyNumber: "008976695",
          contents: [
            { label: "If Fund value moves", value: "Increases by 16,00,000" },
            { label: "Frequency", value: "Monthly" },
          ],
        },
      ],
    },
  ],
};

const defaultValues = {
  isActive: "",
  AlertType: "",
  StartDate: "",
  Frequency: "",
  FundChange: "",
  ChangeAmount: "",
  NAVfundType: "",
  DeliveryMode: "",
  Requestsource: "",
};

const SetAlerts: FC = () => {
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState)?.policyNumber || "";
  const policyAllDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice.policyAllDetails
  );
  const selectedPolicyAllDetails = policyAllDetails?.find(
    (item: T_PolicyAllDetails) => {
      return item?.Policy_Level_Details?.POLICY_NUMBER === policyNumber;
    }
  );
  const [alertTypes, setAlertTypes] = useState<string[]>([
    "Set Alerts",
    "Existing Alerts",
  ]);
  const [types, setTypes] = useState<string[]>([
    "Service Related",
    "Fund Related",
  ]);
  const [selectedAlertType, setSelectedAlertType] =
    useState<string>("Set Alerts");
  const [selectedType, setSelectedType] = useState<string>("Service Related");
  const [loadOptions, setLoadOptions] = useState<any>([]);
  const [selectedAlertOption, setSelectedAlertOption] = useState<workflowInfo>({
    title: "",
    icon: "",
    options: [],
  });
  const [selectedValues, setSelectedValues] = useState<any>({});
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
  const [existingAlerts, setExistingAlerts] =
    useState<T_LiExistingAlert_Response>();
  const [showExisting, setShowExisting] = useState<boolean>(true);
  const [LiFundDetailsApi] = useLazyLiFundDetailsQuery();
  const [LiExistingAlertApi] = useLazyLiExistingAlertsQuery();
  const [fundDetails, setFundDetails] = useState<any[]>([
    {
      FND_ID: "string",
      TOT_UNITS: "string",
      NAV: "58.65",
      POL_CSTAT_CD: "string",
      FUND_NAME: "string",
      POL_ID: "",
      FUND_VALUE: "5000",
    },
    {
      FND_ID: "string",
      TOT_UNITS: "string",
      NAV: "52.65",
      POL_CSTAT_CD: "string",
      FUND_NAME: "string",
      POL_ID: "",
      FUND_VALUE: "657777",
    },
  ]);
  const [validateValues, setValidateValues] = useState<any>({});
  const [reqPayload, setReqPayload] = useState<any>(defaultValues);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (selectedPolicyAllDetails) {
      let promises = [];
      promises.push(
        LiFundDetailsApi({
          policy_id:
            selectedPolicyAllDetails?.Policy_Level_Details?.POLICY_NUMBER,
          effective_date: dayjs().format("DD-MM-YYYY"),
        }).then(
          (res) => {
            if (res.data?.code === API_STATUS_CODE.SUCCESS) {
              setFundDetails([
                {
                  FND_ID: "string",
                  TOT_UNITS: "string",
                  NAV: "58.65",
                  POL_CSTAT_CD: "string",
                  FUND_NAME: "string",
                  POL_ID: "",
                  FUND_VALUE: "5000",
                },
                {
                  FND_ID: "string",
                  TOT_UNITS: "string",
                  NAV: "52.65",
                  POL_CSTAT_CD: "string",
                  FUND_NAME: "string",
                  POL_ID: "",
                  FUND_VALUE: "657777",
                },
              ]);
            }
            dispatch(updateLoadingState(false));
          },
          (error) => {
            dispatch(updateLoadingState(false));
          }
        )
      );
      promises.push(
        LiExistingAlertApi({
          client_id: selectedPolicyAllDetails?.Owner?.CLIENT_ID,
          source: "417070313538",
        }).then(
          (res) => {
            if (res.data?.code === API_STATUS_CODE.SUCCESS) {
              setExistingAlerts(res.data.payload);
            }
            dispatch(updateLoadingState(false));
          },
          (error) => {
            dispatch(updateLoadingState(false));
          }
        )
      );
      callApis(promises);
    }
  }, [selectedPolicyAllDetails]);

  useEffect(() => {
    if (!showExisting) {
      setAlertTypes(["Set Alerts"]);
    } else setAlertTypes(["Set Alerts", "Existing Alerts"]);
  }, [showExisting]);

  useEffect(() => {
    if (existingAlerts && Object.keys(existingAlerts).length > 0) {
      setShowExisting(!checkArrValues(existingAlerts));
    }
  }, [existingAlerts]);

  useEffect(() => {
    if (selectedAlertType === "Set Alerts")
      setLoadOptions(setAlertOptions[selectedType]);
    else if (selectedAlertType === "Existing Alerts")
      setLoadOptions(existingAlertOptions[selectedType]);

    setReqPayload(defaultValues);
  }, [selectedType, selectedAlertType]);

  useEffect(() => {
    if (loadOptions.length > 0 && selectedType === "Service Related")
      setSelectedAlertOption(loadOptions[0]);
    else
      setSelectedAlertOption({
        title: "",
        icon: "",
        options: [],
      });
    setReqPayload(defaultValues);
  }, [loadOptions]);

  useEffect(() => {
    setReqPayload(Object.assign(defaultValues, selectedValues));
    let val = Object.keys(selectedValues).filter((data) => {
      return selectedValues[data] === "";
    });
    if (val.length > 0) {
      setIsBtnEnabled(true);
    } else setIsBtnEnabled(false);
  }, [selectedValues]);

  useEffect(() => {
    // console.log(isBtnEnabled, "isBtnEnabled");
  }, [isBtnEnabled]);

  useEffect(() => {
    if (selectedAlertOption.title !== "") {
      setSelectedValues(selectedAlertOption.selectedValues);
      setValidateValues(selectedAlertOption.validateInputs);
    }
    setReqPayload(defaultValues);
  }, [selectedAlertOption]);

  useEffect(() => {
    let isValidated = Object.values(validateValues).filter(
      (data: any) => data === false
    );
    if (selectedAlertOption.title !== "" && isValidated.length === 0) {
      setIsBtnEnabled(true);
    } else setIsBtnEnabled(false);
  }, [selectedAlertOption, validateValues]);

  // useEffect(() => {
  //   console.log(reqPayload, "reqPayload");
  // }, [reqPayload]);

  const callApis = useCallback(async (promises: any) => {
    if (promises.length > 0) {
      await Promise.allSettled(promises);
    }
  }, []);

  const checkArrValues = useCallback((values: T_LiExistingAlert_Response) => {
    return Object.values(values).every(
      (value) => Array.isArray(value) && value.length === 0
    );
  }, []);

  const handleAlertTypeChange = useCallback((alertType: string) => {
    setSelectedAlertType(alertType);
  }, []);

  const handleTypeChange = useCallback((type: string) => {
    setSelectedType(type);
  }, []);

  const handleAlertOptionChange = useCallback(
    (option: string) => {
      setSelectedAlertOption(loadOptions[option]);
    },
    [loadOptions]
  );

  const handleSelectedValue = useCallback((type: string, value: string) => {
    setSelectedValues((prevState: any) => {
      return { ...prevState, [type]: value };
    });
  }, []);

  const handleFundRedirection = useCallback(
    (selectedVal: string) => {
      let val = {
        title: "Fund Value",
        icon: FundValueIcon,
        options: ["linkOptions", "fundDetails"],
        successMessage:
          "You have successfully set frequency for fund value alert on your policy.",
        selectedValues: { linkOptions: selectedVal, fundDetails: "" },
        validateInputs: { linkOptions: false, fundDetails: "" },
        linkOptions: [
          {
            title: "Know Funds",
            url: "dummyurl",
          },
          {
            title: "Switch Funds",
            value: "dummyurl",
          },
          {
            title: "Premium Redirection",
            value: "dummyurl",
          },
        ],
        fundDetails: [],
      };
      setSelectedAlertOption(val);
    },
    [fundDetails]
  );

  const renderTypes = useCallback(
    (selectedOption: string, type: string, values: any[]) => {
      switch (type) {
        case "Frequency":
          return (
            <RenderModeOfFrequency
              type={type}
              selectedAlert={selectedOption}
              values={values}
              handleChange={handleSelectedValue}
              title="Select Frequency"
              validateInputs={validateInputs}
            />
          );
        case "daysInAdvance":
          return (
            <RenderDaysInAdvance
              type={type}
              values={values}
              handleChange={handleSelectedValue}
              selectedValue={selectedValues.daysInAdvance}
              validateInputs={validateInputs}
            />
          );
        case "DeliveryMode":
          return (
            <RenderModeOfFrequency
              type={type}
              values={values}
              selectedAlert={selectedOption}
              handleChange={handleSelectedValue}
              title="Select Mode of communication"
              validateInputs={validateInputs}
            />
          );
        case "linkOptions":
          return (
            <CustomLinkOptions
              values={values}
              type={type}
              selectedValue={selectedAlertOption.selectedValues[type]}
              handleFundRedirection={handleFundRedirection}
            />
          );
        case "selectFund":
          return (
            <CustomSelectFundValue
              data={values}
              index="0"
              selectedData={0}
              selectedAlert={selectedOption}
              handleFundValueChange={() => {}}
              validateInputs={validateInputs}
              type={type}
            />
          );
        case "selectFundWithAlert":
          return (
            <CustomSelectFundValue
              data={fundDetails}
              index="0"
              selectedData={0}
              withAlert={true}
              selectedAlert={selectedOption}
              handleFundValueChange={() => {}}
              validateInputs={validateInputs}
              type={type}
            />
          );
        case "NAVfundType":
          return (
            <CustomSelectFundValueLimit
              data={fundDetails}
              index="0"
              selectedData={0}
              handleFundValueLimitChange={() => {}}
              validateInputs={validateInputs}
              type={type}
            />
          );
        case "existingAlert":
          return <CustomExistingAlert data={values} />;
        case "fundDetails":
          return (
            <CustomFundDetails
              data={fundDetails}
              type={type}
              policyNo={policyNumber}
            />
          );
        default:
          return;
      }
    },
    [fundDetails, selectedAlertOption]
  );

  const validateInputs = useCallback(
    (type: string, value: boolean, data?: any) => {
      setReqPayload((prevState: any) => {
        return Object.assign({ ...prevState }, data, {
          AlertType: selectedAlertOption.title,
        });
      });
      setValidateValues((prevState: any) => {
        return { ...prevState, [type]: value };
      });
    },
    [reqPayload, selectedValues, selectedAlertOption]
  );

  const handleSubmit = useCallback(() => {
    handleOpen();
  }, [selectedValues]);

  const handleOpen = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return (
    <Box className="li-set-alerts">
      <Container className="li-set-alerts-container">
        <Box sx={{ width: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            gap={{
              sm: 10,
            }}
            sx={{
              justifyContent: {
                xs: "space-between",
                sm: "center",
              },
              marginBottom: "1.25rem",
            }}
          >
            <CustomBreadcrumbs data={["Life Insurance", "Set Alerts"]} />
            <CustomHelpComponent />
          </Box>
          <Box
            className="li-sa-alert-type"
            sx={{ width: showExisting ? "100% !important" : "50% !important" }}
          >
            {alertTypes.map((data) => {
              return (
                <RenderAlertTypeButton
                  key={data}
                  data={data}
                  handleChange={handleAlertTypeChange}
                  selectedValue={selectedAlertType}
                />
              );
            })}
          </Box>
          {selectedPolicyAllDetails &&
            selectedPolicyAllDetails.Policy_Level_Details?.ULIP_TYPE ===
              "B" && (
              <Box className="li-sa-type">
                <Grid container columnSpacing={"0.375rem"} rowSpacing={"0rem"}>
                  {types.map((data) => {
                    return (
                      <Grid item md={6} sm={6} xs={6}>
                        <RenderTypeButton
                          key={data}
                          data={data}
                          handleChange={handleTypeChange}
                          selectedValue={selectedType}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            )}
          <Box className="li-sa-alert-option">
            <Grid container columnSpacing={"0.5rem"} rowSpacing={"0rem"}>
              {loadOptions.map((data: any, index: number) => {
                return (
                  <Grid item md={4} sm={4} xs={4} key={data.title}>
                    <RenderAlertOption
                      data={data.title}
                      handleChange={handleAlertOptionChange}
                      typeIcon={data.icon}
                      index={index}
                      selectedValue={selectedAlertOption.title}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {selectedAlertOption.options.length > 0 &&
            selectedAlertOption.options.map((data: any, index: number) => {
              return (
                <Box className="li-sa-alert-option li-sa-alert-select-items">
                  {renderTypes(
                    selectedAlertOption.title,
                    data,
                    selectedAlertOption[data]
                  )}
                </Box>
              );
            })}
          {selectedAlertType === "Set Alerts" &&
            selectedAlertOption?.title !== "" &&
            !selectedAlertOption.options.includes("DeliveryMode") &&
            !selectedAlertOption.options.includes("linkOptions") && (
              <Box className="li-sa-alert-message">
                <Typography variant="h5">{SET_ALERT_MESSAGE}</Typography>
              </Box>
            )}
        </Box>
        {selectedAlertType === "Set Alerts" && (
          <Box className="li-sa-alert-submit-box">
            <Button
              fullWidth
              variant="contained"
              className={`li-sa-alert-submit-button ${
                !isBtnEnabled ? "li-sa-alert-disabled-button" : ""
              }`}
              onClick={!isBtnEnabled ? () => {} : handleSubmit}
              disableFocusRipple
              sx={{ boxShadow: "none !important" }}
            >
              <Typography variant="h4">Set Alert</Typography>
            </Button>
          </Box>
        )}
      </Container>

      <CustomPopup
        open={open}
        handleOpen={handleOpen}
        className={`set-alert-popup ${width > tabViewPoint ? "centered" : ""}`}
        btnLabel="Close"
        btnStartIcon={
          <CloseIcon sx={{ fontSize: "1rem", color: Colors.red }} />
        }
      >
        <SetExistingAlertSuccess message={selectedAlertOption.successMessage} />
      </CustomPopup>
    </Box>
  );
};

export default SetAlerts;
