import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import "./UpdatePAN.scss";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import { useLocation } from "react-router-dom";
import { T_LiRouteState } from "../../../globalTypes/GlobalTypes";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Colors } from "../../../styles/constant";
import {
  LiUpdatePanCaseCreateSampleReqBody,
  panRegex,
} from "../../../utils/constants";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import {
  useLazyFetchNSDLPANDetailsQuery,
  useLazyLiCrmCaseCreateQuery,
  useLazyLiGetOtpQuery,
  useLazyLiUploadDocQuery,
} from "../LifeInsurance_Api";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import dayjs from "dayjs";
import { T_PolicyAllDetails } from "../LifeInsurance_Types";
import { isEmpty } from "lodash";
import screenShotIcon from "../../../assets/icons/screenshot-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  base64ToTiffImageFormat,
  convertTiffToPng,
  getBase64,
  getFileSize,
  isValidateEmail,
  mergePdfs,
} from "../../../utils/helper";
import UploadIcon from "../../../assets/icons/UploadIcon.svg";
import UpdatePANPopups from "./UpdatePANPopups";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { useDispatch } from "react-redux";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import { API_STATUS_CODE } from "../../../utils/ApiEndpoints";

interface T_NSDLPANResponse {
  pan: string;
  name: string;
  dob: string;
  pan_status: string;
  fathername: string;
  seeding_status: string;
  pdfBytes?: string;
}

const UpdatePAN: FC = () => {
  const [enteredPan, setEnteredPan] = useState<string>("");
  const [helperTextPan, setHelperTextPan] = useState<string>("");
  const [caseType, setCaseType] = useState<string>("");
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [NSDLPANResponse, setNSDLPANResponse] =
    useState<T_NSDLPANResponse | null>(null);
  const [isNSDLValidPAN, setIsNSDLValidPAN] = useState<boolean>(false);
  const [isUploadVisible, setIsUploadVisible] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileError, setUploadedFileError] = useState<string>("");
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState)?.policyNumber || "";
  const MAX_FILE_SIZE_MB = 10;

  const dispatch = useDispatch();
  const policyAllDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice.policyAllDetails
  );
  const selectedPolicyAllDetails = policyAllDetails?.find(
    (item: T_PolicyAllDetails) => {
      return item?.Policy_Level_Details?.POLICY_NUMBER === policyNumber;
    }
  );
  let existingPAN = panRegex.test(selectedPolicyAllDetails?.Owner.PAN || "")
    ? selectedPolicyAllDetails?.Owner.PAN
    : "";

  let ownerName =
    (selectedPolicyAllDetails?.Owner.FIRST_NAME
      ? selectedPolicyAllDetails?.Owner.FIRST_NAME + " "
      : "") +
    (selectedPolicyAllDetails?.Owner.MIDDLE_NAME
      ? selectedPolicyAllDetails?.Owner.MIDDLE_NAME + " "
      : "") +
    (selectedPolicyAllDetails?.Owner.LAST_NAME
      ? selectedPolicyAllDetails?.Owner.LAST_NAME
      : "");

  const [fetchNSDLPANDetailsApi] = useLazyFetchNSDLPANDetailsQuery();
  const [liCrmCaseCreateApi] = useLazyLiCrmCaseCreateQuery();
  const [liUploadDocApi] = useLazyLiUploadDocQuery();
  const [liGetOtpApi] = useLazyLiGetOtpQuery();

  const getFileByteArray = async () => {
    let finalBase64 = "";
    if (isNSDLValidPAN) {
      finalBase64 = NSDLPANResponse?.pdfBytes || "";
    } else {
      let isJpg = uploadedFile?.type === "image/jpeg" ? true : false;
      let uploadedFileBase64 = "";
      if (uploadedFile?.type === "image/tiff") {
        let fileBase64 = await convertTiffToPngBase64(uploadedFile);
        uploadedFileBase64 = fileBase64.split(",")[1];
      } else {
        uploadedFileBase64 = (await getBase64(uploadedFile)) as string;
      }
      finalBase64 = await mergePdfs(
        NSDLPANResponse?.pdfBytes || "",
        uploadedFileBase64,
        isJpg
      );
    }
    return finalBase64;
  };

  const handleDocUploadApiFun = async () => {
    let fileFormId = caseType === "STP" ? "121373" : "111041";
    let fileName = `${
      selectedPolicyAllDetails?.Policy_Level_Details.APPLICATION_NUMBER
    }_${policyNumber}_${fileFormId}_${dayjs().format("DDMMYYYY")}.pdf`;

    let reqBody = {
      Request: {
        POLICY_DOCS: {
          Application_Number:
            selectedPolicyAllDetails?.Policy_Level_Details.APPLICATION_NUMBER ||
            "",
          Policy_Number: policyNumber,
          Source_Uploaded_From: "ABC_Servicing_App",
          DOC_Details: {
            File_ByteArray: (await getFileByteArray()) as string,
            File_Name: fileName,
            File_Form_ID: fileFormId,
          },
        },
      },
    };
    return await liUploadDocApi(reqBody).then((res) => {
      if (res.isError || res.data?.code !== API_STATUS_CODE.SUCCESS) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "Doc upload API failed",
          })
        );
        return false;
      }
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        return true;
      }
    });
  };

  const handleCrmCaseCreate = async () => {
    let reqBody = { ...LiUpdatePanCaseCreateSampleReqBody };
    reqBody.CUSTOMERID = selectedPolicyAllDetails?.Owner.CLIENT_ID || "";
    reqBody.POLICYNO =
      selectedPolicyAllDetails?.Policy_Level_Details.POLICY_NUMBER || "";
    reqBody.PAN_number = enteredPan;
    reqBody.Pan_Valid_status = NSDLPANResponse?.pan_status === "E" ? "Y" : "N";
    reqBody.PolicyOwnerName = ownerName;
    reqBody.NameMatched = NSDLPANResponse?.name === "Y" ? "Y" : "N";
    reqBody.TransactionId = `${
      selectedPolicyAllDetails?.Owner.CLIENT_ID
    }${Math.floor(Date.now() / 1000)}`;
    return await liCrmCaseCreateApi({
      CreateCaseRequest: reqBody,
    }).then((res) => {
      if (res?.isError || res.data?.code !== API_STATUS_CODE.SUCCESS) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
        return false;
      }
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        return true;
      }
    });
  };

  const handleChangePAN = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.trim();
    value = value.toUpperCase();
    setEnteredPan(value);
    if (value.length === 10 && !panRegex.test(value)) {
      setHelperTextPan("Please enter a valid PAN");
    } else {
      setHelperTextPan("");
    }
    if (value === existingPAN) {
      setHelperTextPan("New PAN cannot be the same as existing ");
      return;
    }
    setIsUploadVisible(false);
    if (value.length === 10 && panRegex.test(value)) {
      let reqBody = {
        pan: value,
        name: ownerName,
        fathername: "",
        dob: dayjs(selectedPolicyAllDetails?.Owner?.DATE_OF_BIRTH).format(
          "DD/MM/YYYY"
        ),
      };
      fetchNSDLPANDetailsApi({ inputData: [reqBody] }).then((res) => {
        if (
          res.data?.code === API_STATUS_CODE.SUCCESS &&
          !isEmpty(res.data.payload.outputData)
        ) {
          let outputData = res.data.payload.outputData[0];
          setNSDLPANResponse({
            ...outputData,
            pdfBytes: res.data.payload.pdfBytes,
          });
          if (outputData.pan_status === "E" && outputData.name === "Y") {
            setCaseType("STP");
          } else if (outputData.pan_status === "E" && outputData.name === "N") {
            setCaseType("NSTP");
          } else if (outputData.pan_status !== "E") {
            setHelperTextPan("Please enter a valid PAN");
            return;
          }
          if (
            outputData.pan_status === "E" &&
            outputData.name === "Y" &&
            outputData.dob === "Y"
          ) {
            setIsNSDLValidPAN(true);
            setIsUploadVisible(false);
            return;
          }
        }
        setIsUploadVisible(true);
        setIsNSDLValidPAN(false);
      });
    }
  };

  const getFileExtension = (filename: string): string => {
    const parts = filename.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1].toUpperCase();
    }
    return "";
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    setUploadedFileError("");
    if (file?.name) {
      if (
        ![
          "image/jpg",
          "image/jpeg",
          "image/png",
          "image/tiff",
          "application/pdf",
        ].includes(file?.type)
      ) {
        setUploadedFileError(
          `File type ${getFileExtension(file.name).toLowerCase()} not supported`
        );
        return;
      }
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setUploadedFileError(`File size exceeds ${MAX_FILE_SIZE_MB} MB limit.`);
        event.target.value = "";
        setUploadedFile(null);
      } else {
        setUploadedFile(file);
      }
    }
  };

  const handleDeleteFile = () => {
    setUploadedFile(null);
  };

  const convertTiffToPngBase64 = async (file: File) => {
    const base64Image = (await getBase64(file)) as string;
    const imgElement = await base64ToTiffImageFormat(base64Image);
    const pngBase64 = await convertTiffToPng(imgElement);
    return pngBase64 || "";
  };

  const handleUpdateBtnClick = () => {
    let mobileNumber =
      selectedPolicyAllDetails?.Owner.MOBILE_CONTACT?.replace(/\s/g, "")?.slice(
        -10
      ) || "";
    let emailId = selectedPolicyAllDetails?.Owner.EMAIL_ID || "";
    let reqBody = {
      Source: "SVCAPP",
      MobileNo: mobileNumber,
      Functionality: "SVC_UPDATE_PAN_OTP",
      EmailId: isValidateEmail(emailId) ? emailId : "",
    };
    setIsBtnLoading(true);
    liGetOtpApi(reqBody).then((res) => {
      setIsBtnLoading(false);
      if (
        res.data?.code === API_STATUS_CODE.SUCCESS &&
        res.data?.payload?.smsAPI?.statusCode === "200"
      ) {
        setIsPopupOpen(true);
      } else {
        setIsPopupOpen(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "get OTP API failed",
          })
        );
      }
    });
  };

  const getBtnDisabled = () => {
    let btnDisabled = true;
    if (isUploadVisible) {
      btnDisabled = !uploadedFile || enteredPan.length < 10 || !!helperTextPan;
    } else if (isNSDLValidPAN) {
      btnDisabled = enteredPan.length < 10 || !!helperTextPan;
    }
    return btnDisabled;
  };

  return (
    <>
      <Box className="li-update-pan">
        <Container>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <CustomBreadcrumbs
              data={["Life Insurance", policyNumber, "Update PAN"]}
            />
            <CustomHelpComponent />
          </Box>
          <Box className="content-wrapper">
            <Typography fontSize={18} fontWeight={600}>
              Update PAN
            </Typography>
            {existingPAN && (
              <Typography
                fontSize={14}
                fontWeight={400}
                color="#797979"
                marginTop="1rem"
              >
                Your existing PAN is{" "}
                <span style={{ color: Colors.red, fontWeight: 700 }}>
                  {existingPAN}
                </span>
              </Typography>
            )}
            <Typography
              fontSize={14}
              fontWeight={400}
              color="#797979"
              marginTop="1rem"
            >
              {existingPAN
                ? "Please enter new PAN here"
                : "Add Permanent Account Number"}
            </Typography>
            <Box marginTop="1rem">
              <TextField
                fullWidth
                label={isFocused || enteredPan ? "PAN" : "Enter PAN"}
                placeholder={isFocused ? "" : "Enter PAN"}
                value={enteredPan}
                onFocus={() => setIsFocused(true)}
                onChange={handleChangePAN}
                onBlur={(e) => {
                  setIsFocused(false);
                  if (!panRegex.test(e.target.value)) {
                    setHelperTextPan("Please enter a valid PAN");
                  }
                }}
                helperText={
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <InputAdornment position="start">
                      {helperTextPan.length > 0 && <img src={AlertRhombus} />}
                    </InputAdornment>
                    {helperTextPan}
                  </span>
                }
                inputProps={{
                  maxLength: 10,
                  inputMode: "text",
                  pattern: panRegex,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isNSDLValidPAN && (
                        <CheckCircleRoundedIcon
                          sx={{ color: Colors.lightgreen }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {isUploadVisible && (
              <>
                <Box
                  sx={{
                    border: ".0625rem dashed #D5D7DE",
                    background: Colors.bgGray,
                    borderRadius: "1.5rem",
                  }}
                  width="100%"
                  marginTop="1.125rem"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    margin="1rem"
                    gap={"2rem"}
                  >
                    <Box>
                      <Typography variant="h5" fontWeight={700}>
                        Upload your PAN here
                      </Typography>
                      <Typography
                        variant="h6"
                        marginTop="0.5rem"
                        color={"#000000B2"}
                      >
                        JPG/JPEG/PNG/TIF/PDF Files
                      </Typography>
                      <Typography variant="h6" color={"#000000B2"}>
                        (up to {MAX_FILE_SIZE_MB}MB)
                      </Typography>
                    </Box>
                    <Box>
                      <Button
                        component="label"
                        variant="text"
                        startIcon={<img src={UploadIcon} />}
                        htmlFor="upload-image"
                        className="upload-button"
                      >
                        <input
                          style={{ display: "none" }}
                          id="upload-image"
                          hidden
                          accept="image/jpeg, image/png, application/pdf, image/tiff, image/jpg"
                          type="file"
                          multiple={false}
                          onChange={handleFileUpload}
                          disabled={!!uploadedFile}
                        />
                        <Typography
                          fontWeight={600}
                          fontSize=".625rem"
                          lineHeight=".9375rem"
                          textAlign="center"
                          color="#797979"
                          marginTop=".5rem"
                        >
                          Upload
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
                {uploadedFileError && (
                  <Typography
                    marginTop="0.5rem"
                    color={Colors.red}
                    variant="h6"
                    marginLeft="1rem"
                  >
                    {uploadedFileError}
                  </Typography>
                )}
                {uploadedFile && (
                  <Box width="100%" marginTop="1rem">
                    <Typography
                      variant="h6"
                      marginLeft="0.5rem"
                      marginBottom=".5rem"
                    >
                      Uploads:
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      marginBottom="1.5rem"
                      sx={{
                        backgroundColor: "#F7F7F7",
                        borderRadius: "1.5rem",
                        padding: ".75rem 1rem",
                      }}
                    >
                      <Box display="flex" alignItems="center" gap=".5rem">
                        <Box className="upload-file-icon-wrapper">
                          <img src={screenShotIcon} alt="screenshot" />
                        </Box>
                        <Box>
                          <Typography
                            variant="h6"
                            fontWeight={700}
                            sx={{ wordBreak: "break-all" }}
                          >
                            {uploadedFile.name}
                          </Typography>
                          <Typography
                            variant="h6"
                            fontSize={10}
                            color="#797979"
                          >
                            {getFileExtension(uploadedFile.name)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center" gap=".625rem">
                        <Typography variant="h6" fontSize={10}>
                          {getFileSize(uploadedFile)}
                        </Typography>
                        <IconButton
                          onClick={handleDeleteFile}
                          color="inherit"
                          size="small"
                          sx={{
                            color: "#D04A02",
                            padding: 0,
                            cursor: "pointer",
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Container>
        <Box
          sx={{
            margin: "auto 1rem 0",
            paddingBottom: { md: "1.875rem" },
          }}
        >
          {isBtnLoading ? (
            <CustomThreeDotsLoader />
          ) : (
            <Button
              fullWidth
              variant="contained"
              className="update-btn"
              disabled={getBtnDisabled()}
              onClick={handleUpdateBtnClick}
              sx={{
                padding: ".875rem 2rem",
              }}
            >
              Update
            </Button>
          )}
        </Box>
      </Box>
      {isPopupOpen && (
        <UpdatePANPopups
          open={isPopupOpen}
          handleOpen={setIsPopupOpen}
          data={selectedPolicyAllDetails}
          handleCrmCaseCreate={handleCrmCaseCreate}
          handleDocUploadFun={handleDocUploadApiFun}
        />
      )}
    </>
  );
};

export default UpdatePAN;
