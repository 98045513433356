import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import APIFailurePopup from "../CustomAPIFailurePopup/APIFailurePopup";
import {
  clearError,
  ErrorEventHandler,
} from "../../redux-store/reducers/CsErrorSlice";
import { useCallback } from "react";
import { T_ApiResponse } from "../../globalTypes/GlobalTypes";

const ErrorComp: React.FC = () => {
  const errorState = useAppSelector((state) => state.CsErrorSlice);
  const authDispatch = useAppDispatch();
  const cmsErrState = useAppSelector(
    (state) => state.CmsSlice.CmsErrorVariablesData
  );

  const handleOpen = useCallback(() => {
    if (ErrorEventHandler.eventState.onClick) {
      ErrorEventHandler.eventState.onClick({ ...errorState });
      ErrorEventHandler.resetHandler();
    }
    authDispatch(clearError());
  }, [errorState]);

  const ApiErrorObj = errorState.error as T_ApiResponse<any>;
  const title =
    cmsErrState.find((f) => f.attributes?.errorCode === ApiErrorObj?.errorCode)
      ?.attributes?.uiDisplayTitle || ApiErrorObj?.uiDisplayTitle;
  const message =
    cmsErrState.find((f) => f.attributes?.errorCode === ApiErrorObj?.rootCause)
      ?.attributes?.uiDisplayMessage || ApiErrorObj?.uiDisplayMessage;

  return errorState.error ? (
    <APIFailurePopup
      open={true}
      handleOpen={handleOpen}
      customTitle={title}
      customSubTitle={message}
    />
  ) : null;
};
export default ErrorComp;
