import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoginScreenValues } from "./Feature_Login";

type T_LoginSliceState = {
  mpin: string | undefined;
  mobilenumber: string | undefined;
  isOtpVerificationDone: boolean;
  displayRateUs: boolean;
  pan: string | undefined;
  dob: string | undefined;
  csUserId: string | undefined;
  cii: string | undefined;
  screenTag: LoginScreenValues;
  isUserLoggedIn: boolean;
};

const initialState: T_LoginSliceState = {
  mpin: undefined,
  mobilenumber: undefined,
  isOtpVerificationDone: false,
  displayRateUs: false,
  pan: undefined,
  dob: undefined,
  cii: undefined,
  csUserId: undefined,
  screenTag: "enterMobileScreen" as LoginScreenValues.enterMobileScreen,
  isUserLoggedIn: false,
};

export const LoginSlice = createSlice({
  name: "LoginSlice",
  initialState: initialState,
  reducers: {
    updateLoginData: (
      state: T_LoginSliceState,
      action: PayloadAction<{
        key: keyof T_LoginSliceState;
        value: string | boolean | undefined;
      }>
    ) => {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    },
    updateScreenTag: (state, action: PayloadAction<LoginScreenValues>) => {
      return { ...state, screenTag: action.payload };
    },
    resetLoginData: (state) => {
      return initialState;
    },
  },
});

export const LoginSliceReducer = LoginSlice.reducer;
export const LoginSliceKey = LoginSlice.reducerPath;
export const { updateLoginData, updateScreenTag, resetLoginData } =
  LoginSlice.actions;
