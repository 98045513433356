import React, { FC } from "react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

import CommonMenuListPage from "../../../components/CustomMenuListPage/CommonMenuListPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { T_ProfileHamburgerMenu } from "../../Features_Cms/ProfileHamburgerMenu.Types";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";

interface Props {
  handleBackBtnClick: Function;
  pathname?: string;
}

const Utilities: FC<Props> = ({ handleBackBtnClick, pathname }) => {
  
  const profileHamburgerMenuData: T_ProfileHamburgerMenu[] = useAppSelector(
    (state) => state.CmsSlice.ProfileHamburgerMenuData
  );

  const utilitiesMenuList = profileHamburgerMenuData.find(
    (i) => i.attributes.pathname === "utilities" 
  )?.attributes.subMenu;
  return (
    <CommonMenuListPage
      title="Utilities"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
      <Box>
        {utilitiesMenuList?.filter(item => item.isEnable).map((item, index) => (
          <React.Fragment key={item.menuTitle}>
            <Link underline="none" color="black" href={item.menuUrl ?? ''}>
              <ListItem
                key={item.id}
                secondaryAction={
                  <IconButton
                    sx={{ marginRight: 0 }}
                    edge="end"
                    aria-label="delete"
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                }
                sx={{
                  marginY: ".625rem",
                  paddingLeft: "1.5rem",
                }}
              >
                <ListItemAvatar>
                <Avatar src={item.menuIcon.data.attributes.url} />
                  {/* <item.preIcon fontSize="large" sx={{ color: Colors.red }} /> */}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h5" marginBottom={"0.3125rem"}>
                      {item.menuTitle}
                    </Typography>
                  }
                />
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
        ))}
      </Box>
    </CommonMenuListPage>
  );
};

export default Utilities;
