import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomPopup from "../../components/CustomPopup/CustomPopup";
import {
  Box,
  Divider,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../styles/constant";
import "./AddManuallyPopup.scss";
import { useLazyFetchPolicyAllDetailsQuery } from "../../features/Feature_LifeInsurance/LifeInsurance_Api";
import {
  encryptString,
  getMaskedMobileNumber,
  isValidateEmail,
} from "../../utils/helper";
import {
  DashboardTabKeys,
  folioApiReqBodyPSW,
  folioApiReqBodyUserId,
  HiLobCode,
  LiLobCode,
  MfLobCode,
  panRegex,
} from "../../utils/constants";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import {
  T_ProductCategoryOptions,
  T_MasterData_DetailObjOption,
} from "../../features/Feature_GeneralAppElements/GeneralAppElements_Types";
import { T_ApiResponse, T_Option } from "../../globalTypes/GlobalTypes";
import { isEmpty } from "lodash";
import {
  useLazyGenerateOtpQuery,
  useLazyValidateOtpQuery,
} from "../../features/Feature_Login/Login_Api";
import {
  T_Owner,
  T_PolicyAllDetails,
} from "../../features/Feature_LifeInsurance/LifeInsurance_Types";
import {
  useLazyCheckIfCIIExistsForSecondaryUserQuery,
  useSaveHoldingDataMutation,
} from "../../features/Feature_GeneralAppElements/GeneralAppElements_Api";
import {
  useLazyMapLobProductQuery,
  useLazyTeraDataUserRegistrationQuery,
} from "../../features/Feature_OnBoarding/OnBoarding_Api";
import AddManuallySuccessBody from "../Success/AddManuallySuccessBody";
import { setError } from "../../redux-store/reducers/CsErrorSlice";
import { useNavigate } from "react-router-dom";
import { R_HomeRoutes } from "../Home/Home_Routes";
import { useLazyFetchPolicyInsuredQuery } from "../../features/Feature_HealthInsurance/HealthInsurance_Api";
import { T_PolicyInsured } from "../../features/Feature_HealthInsurance/HealthInsurance_Types";
import CustomLink from "../../components/CustomLink/CustomLink";
import AlertRhombus from "../../assets/icons/alert-rhombus.svg";
import {
  T_Folio,
  T_LstAvailabletoLink,
  validatePanBody,
} from "../../features/Feature_MutualFund/MutualFund_Types";
import {
  useLazyFetchDelinkFolioListQuery,
  useLazyFolioLinkingDelinkingQuery,
  useLazyValidatePanQuery,
} from "../../features/Feature_MutualFund/MutualFund_API";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enUS } from "@mui/x-date-pickers/locales";
import memberImage from "../../assets/images/family-support.png";
import CommonGifAnimation from "../../components/CustomGifAnimation/CommonGifAnimation";
import NoHoldingsFoundGif from "../../assets/gif/Exclamation_Mark.json";
import CsSessionStorageUtils from "../../utils/CsSessionStorageUtils";
import { nanoid } from "@reduxjs/toolkit";
import panImage from "../../assets/icons/pan-add-manually.svg";
import CustomThreeDotsLoader from "../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import { setCustomerAndLobDetails } from "../../redux-store/reducers/CsAppSlice";
import TodayIcon from "@mui/icons-material/Today";

interface Props {
  open: boolean;
  handleOpen: Function;
  isNoHoldingFlow?: boolean;
}

interface RadioListRelationData {
  label: string;
  value: string;
}

const AddManuallyPopup: FC<Props> = ({
  open,
  handleOpen,
  isNoHoldingFlow = false,
}) => {
  const usLocale =
    enUS.components.MuiLocalizationProvider.defaultProps.localeText;
  const masterData = useAppSelector(
    (state) => state.GeneralAppElementsSlice?.masterData
  );
  const masterDetails = masterData?.masterDetail;
  const wrongOtpCountAllowed = parseInt(
    masterDetails?.wrong_otp_count_allowed?.find(
      (item) => item.masterKey === "limit"
    )?.masterValue || "3"
  );
  const retryCount = parseInt(
    masterDetails?.retry_count?.find(
      (item) => item.masterKey === "otpBypassValue"
    )?.masterValue || "3"
  );
  const [manualStep, setManualStep] = useState<number>(0);
  const [product, setProduct] = useState<string>("");
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>("");
  const [selectedFolioRadio, setSelectedFolioRadio] = useState<string>("");
  const [policyNumberValue, setPolicyNumberValue] = useState<string>("");
  const [enteredPan, setEnteredPan] = useState<string>("");
  const [helperTextPan, setHelperTextPan] = useState<string>("");
  const [folioList, setFolioList] = useState<T_Folio[]>([]);
  const [displayedFolioList, setDisplayedFolioList] = useState<T_Folio[]>([]);
  const [deLinkFolioList, setDeLinkFolioList] = useState<
    T_LstAvailabletoLink[]
  >([]);
  const [otpValue, setOtpValue] = useState<string>("");
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");
  const [otherRelation, setOtherRelation] = useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [enteredDob, setEnteredDob] = useState<string | null>(null);
  const [dobError, setDobError] = useState<string | null>(null);
  const [ownerInfo, setOwnerInfo] = useState<T_Owner | null>(null);
  const [count, setCount] = useState<number>(60);
  const [isPolicyExist, setIsPolicyExist] = useState<boolean>(false);
  const [otpVerifyAttemptLeft, setOtpVerifyAttemptLeft] =
    useState<number>(wrongOtpCountAllowed);
  const [reSendOtpAttemptLeft, setReSendOtpAttemptLeft] =
    useState<number>(retryCount);
  const [addedPolicyAllDetails, setAddedPolicyAllDetails] =
    useState<T_PolicyAllDetails | null>(null);
  const [policyInsuredDetails, setPolicyInsuredDetails] = useState<
    T_PolicyInsured | undefined
  >(undefined);
  const [helperTextPolicyNumber, setHelperTextPolicyNumber] =
    useState<string>("");
  const [othersValueError, setOthersValueError] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const isTimerOn = useRef(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const customerAndLobDetails =
    useAppSelector(
      (state) => state.CsAppSlice.userDetails?.customerAndLobDetails
    ) || [];
  const HiData = useAppSelector((state) => state.HealthInsuranceSlice);
  const LiData = useAppSelector((state) => state.LifeInsuranceSlice);
  const MfData = useAppSelector((state) => state.MutualFundSlice);

  const [fetchPolicyAllDetails] = useLazyFetchPolicyAllDetailsQuery();
  const [generateOtpApi] = useLazyGenerateOtpQuery();
  const [validateOtpApi] = useLazyValidateOtpQuery();
  const [saveHoldingsApi] = useSaveHoldingDataMutation();
  const [mapLobProductApi] = useLazyMapLobProductQuery();
  const [teraDataUserRegistration] = useLazyTeraDataUserRegistrationQuery();
  const [fetchPolicyInsuredApi] = useLazyFetchPolicyInsuredQuery();
  const [fetchValidatePanApi] = useLazyValidatePanQuery();
  const [fetchDelinkFolioListApi] = useLazyFetchDelinkFolioListQuery();
  const [Api_checkIfCiiExistsForMobileEmail] =
    useLazyCheckIfCIIExistsForSecondaryUserQuery();
  const [folioLinkingDeLinkingApi] = useLazyFolioLinkingDelinkingQuery();

  const configuration = useAppSelector(
    (state) => state.CsAppSlice.tokenDetails?.configuration
  );

  let selectedLobDetails = masterData?.productCategory?.find(
    (item) => item.lobName === product
  );
  let selectedLob = selectedLobDetails?.lob || "";
  let selectedLobId = selectedLobDetails?.lobId || "";

  let selectedFolioDetails = folioList?.find(
    (item) => item.Folio_No === selectedFolioRadio
  );
  const otpByPassValue =
    masterData?.masterDetail?.otpBypass?.find(
      (item) => item.masterKey === "otpBypassValue"
    )?.masterValue || "";

  const isOtpBypassEnable =
    masterData?.masterDetail?.otpBypass?.find(
      (item) => item.masterKey === "otpBypassFlag"
    )?.masterValue || "";

  const IV = configuration?.iv || "";
  const secretKey = configuration?.key || "";
  let productCategoryOptions: T_Option[] = [];
  masterData?.productCategory.forEach((item: T_ProductCategoryOptions) => {
    if (
      !["Stocks & Securities", "Home Loans", "Loans"].includes(item.lobName)
    ) {
      productCategoryOptions.push({
        label: item.lobName,
        value: item.lobName,
      });
    }
  });

  let relationshipOptions: T_Option[] = [];
  masterData?.masterDetail?.relationship?.forEach(
    (item: T_MasterData_DetailObjOption) => {
      relationshipOptions.push({
        label: item.masterValue,
        value: item.masterValue,
      });
    }
  );
  const activeDashboardTab = CsSessionStorageUtils.get("ACTIVE_DASHBOARD_TAB");

  useEffect(() => {
    if (activeDashboardTab === DashboardTabKeys.Investment) {
      let mfProductCategory =
        masterData?.productCategory.find((item) => item.lob === MfLobCode)
          ?.lobName || "";
      setProduct(mfProductCategory);
    } else {
      setProduct("");
    }
  }, [activeDashboardTab]);

  const startTimer = useCallback(() => {
    isTimerOn.current = true;
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount <= 0) {
          clearInterval(intervalId);
          isTimerOn.current = false;
          return 60;
        }
        return newCount;
      });
    }, 1000);
  }, []);

  const clearAllInputData = () => {
    setManualStep(0);
    setProduct("");
    setSelectedRadioValue("");
    setPolicyNumberValue("");
    setOtpValue("");
    setOtherRelation("");
    setEnteredDob(null);
    setNewEmail("");
    setOtpErrorMessage("");
  };

  const handleClosePopup = () => {
    clearAllInputData();
    handleOpen(false);
    if (isNoHoldingFlow) {
      navigate(R_HomeRoutes.home, { replace: true });
    }
  };

  useEffect(() => {
    if (folioList.length > 0 && deLinkFolioList.length > 0) {
      const deLinkFolioNumberArray = deLinkFolioList.map(
        (item) => item.FolioNo
      );
      const result = folioList.filter((item) =>
        deLinkFolioNumberArray.includes(item.Folio_No)
      );
      setDisplayedFolioList(result);
    } else if (folioList.length > 0) {
      setDisplayedFolioList(folioList);
    }
  }, [folioList, deLinkFolioList]);


  const handleGenerateOtpResponse = (res:any) => {
    setIsBtnLoading(false);
    if (res.data?.code === 1) {
      setManualStep(1);
      startTimer();
    }
    if (res.isError || res.data?.code !== 1) {
      dispatch(
        setError({
          error: res.error as T_ApiResponse<any>,
          message: "generateOtp API failed",
        })
      );
    }
  };
  
  const handleGenerateOtpClick = async () => {
    setIsBtnLoading(true);
    let mobileNumber: string = "";
    if (selectedLob === LiLobCode) {
      if (
        LiData.dashboardDetails.filter(
          (item) => item.POLICY_NUMBER === policyNumberValue
        ).length > 0 ||
        LiData.secondaryDashboardDetails.filter(
          (item) => item.POLICY_NUMBER === policyNumberValue
        ).length > 0
      ) {
        setIsPolicyExist(true);
        setManualStep(10);
        setIsBtnLoading(false);
        return;
      }
      const encryptedPolicyNumber = encryptString(
        policyNumberValue,
        IV,
        secretKey
      );
      await fetchPolicyAllDetails(encryptedPolicyNumber).then((res) => {
        if (
          res.data?.code === 1 &&
          !isEmpty(res.data?.payload.Response.Owner)
        ) {
          const ownerDetails = res.data?.payload.Response.Owner;
          setOwnerInfo(ownerDetails);
          setAddedPolicyAllDetails(res.data.payload.Response);
          let ownerMobileNumber = ownerDetails.MOBILE_CONTACT.replace(
            /\s/g,
            ""
          );
          if (ownerMobileNumber.length > 0) {
            mobileNumber = ownerMobileNumber.slice(-10) || "";
          }
        }
        if (res.isError || res.data?.code !== 1) {
          setIsBtnLoading(false);
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "getPolicyAllDetails API failed",
            })
          );
          return;
        }
      });
    } else if (selectedLob === HiLobCode) {
      if (
        HiData.allPolicyDetails.filter(
          (item) => item.PolicyNumber === policyNumberValue
        ).length > 0 ||
        HiData.secondaryAllPolicyDetails.filter(
          (item) => item.PolicyNumber === policyNumberValue
        ).length > 0
      ) {
        setIsPolicyExist(true);
        setManualStep(10);
        setIsBtnLoading(false);
        return;
      }
      await fetchPolicyInsuredApi(policyNumberValue).then((res) => {
        if (res.isError || res.data?.code !== 1) {
          setIsBtnLoading(false);
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "Policy Insured API failed",
            })
          );
          return;
        }
        if (
          res.data?.code === 1 &&
          !isEmpty(res.data.payload.Response?.PolicyDetail) &&
          !isEmpty(res.data.payload.Response?.InsuredDetail)
        ) {
          let data = res.data.payload.Response;
          setPolicyInsuredDetails(data);
          mobileNumber = data.PolicyDetail.Mobile.replace(/\s/g, "").slice(-10);
        } else {
          setIsBtnLoading(false);
          setManualStep(10);
          return;
        }
      });
    } else if (selectedLob === MfLobCode) {
      const requestBody = { ...validatePanBody };
      if (enteredPan === userDetails?.pan) {
        let isListAvailable = false;
        await fetchDelinkFolioListApi({
          PanNumber: enteredPan,
          CIINumber: customerAndLobDetails?.[0].CII || "",
          UserId: folioApiReqBodyUserId,
          Password: folioApiReqBodyPSW,
          Source: null,
        }).then((res) => {
          if (res.isError || res.error) {
            setIsBtnLoading(false);
            dispatch(
              setError({
                error: res.error as T_ApiResponse<any>,
                message: "fetch delink folio list API failed",
              })
            );
          }
          if (
            res.data?.code === 1 &&
            !isEmpty(res.data?.payload.lstAvailabletoLink)
          ) {
            isListAvailable = true;
            setDeLinkFolioList(res.data?.payload.lstAvailabletoLink || []);
          } else {
            setIsBtnLoading(false);
            setManualStep(10);
            return;
          }
        });
        if (!isListAvailable) {
          setIsBtnLoading(false);
          return;
        }
      }
      requestBody.PANNo = enteredPan;
      await fetchValidatePanApi(requestBody).then((res) => {
        if (res.isError || res.error || res.data?.code !== 1) {
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "validate PAN API failed",
            })
          );
          return;
        }
        if (res.data?.code === 1 && !isEmpty(res?.data?.payload.Folios)) {
          setFolioList(res?.data?.payload.Folios || []);
          setManualStep(7);
        } else {
          setManualStep(10);
        }
      });
      setIsBtnLoading(false);
      return;
    }
    if (mobileNumber.length === 10) {
      await generateOtpApi({ mobileNo: mobileNumber, otp: "" }).then((res) => {
        handleGenerateOtpResponse(res);

      });
    } else {
      setIsBtnLoading(false);
    }
  };

  const handleResendOTP = () => {
    if (reSendOtpAttemptLeft <= 0) {
      return;
    }
    let mobileNo = getMobileNumber();
    generateOtpApi({ mobileNo, otp: "" }).then((res) => {
      if (res.data?.code === 1) {
        setReSendOtpAttemptLeft((prev) => prev - 1);
        setOtpErrorMessage("");
        startTimer();
      }
      if (res.isError || res.data?.code !== 1) {
        dispatch(
          setError({
            error: res.error as T_ApiResponse<any>,
            message: "generateOtp API failed",
          })
        );
      }
    });
  };

  const getMobileNumber = () => {
    let ownerMobileNumber = "";
    if (selectedLob === LiLobCode) {
      ownerMobileNumber = ownerInfo?.MOBILE_CONTACT || "";
    } else if (selectedLob === HiLobCode) {
      ownerMobileNumber = policyInsuredDetails?.PolicyDetail.Mobile || "";
    } else if (selectedLob === MfLobCode) {
      const selectedFolio = folioList.find(
        (item: T_Folio) => item?.Folio_No === selectedFolioRadio
      );
      ownerMobileNumber = selectedFolio?.MobileNumber || "";
    }
    return ownerMobileNumber?.replace(/\s/g, "").slice(-10) || "";
  };

  const getPanNumber = () => {
    let panNumber = "";
    if (selectedLob === LiLobCode) {
      panNumber = ownerInfo?.PAN || "";
    } else if (selectedLob === HiLobCode) {
      panNumber =
        policyInsuredDetails?.InsuredDetail.find((item) => item.PanNo)?.PanNo ||
        "";
    } else if (selectedLob === MfLobCode) {
      panNumber = selectedFolioDetails?.PANNo || "";
    }
    return panNumber;
  };

  const getDobNumber = () => {
    let dob = "";
    if (selectedLob === LiLobCode) {
      dob = ownerInfo?.DATE_OF_BIRTH || "";
    } else if (selectedLob === HiLobCode) {
      dob = policyInsuredDetails?.PolicyDetail.DateOfBirth || "";
    } else if (selectedLob === MfLobCode) {
      dob = selectedFolioDetails?.BirthDate || "";
    }
    return dob;
  };

  const handleBypassOtp = () => {
    if (otpValue === otpByPassValue) {
      checkIfPrimaryOrSecondaryPolicy();
    } else {
      setOtpErrorMessage("Incorrect OTP, please try again later");
      setOtpValue("");
      if (otpVerifyAttemptLeft > 0) {
        setOtpVerifyAttemptLeft((prevCount) => prevCount - 1);
      }
    }
  };

  const handleActualOtpVerify = () => {
    setIsBtnLoading(true);
    const mobileNo = getMobileNumber();
    validateOtpApi({ mobileNo, otp: otpValue }).then((res) => {
      if (res.data?.code === 1) {
        checkIfPrimaryOrSecondaryPolicy();
      } else {
        setIsBtnLoading(false);
        setOtpErrorMessage("Incorrect OTP, please try again later");
        setOtpValue("");
        if (otpVerifyAttemptLeft > 0) {
          setOtpVerifyAttemptLeft((prevCount) => prevCount - 1);
        }
      }
    });
  };

  const getHolderName = () => {
    let fullName = "";
    if (selectedLob === LiLobCode) {
      fullName = `${ownerInfo?.FIRST_NAME} ${ownerInfo?.MIDDLE_NAME} ${ownerInfo?.LAST_NAME}`;
    } else if (selectedLob === HiLobCode) {
      fullName = `${policyInsuredDetails?.PolicyDetail.PolicyOwnerName}`;
    } else if (selectedLob === MfLobCode) {
      fullName = `${selectedFolioDetails?.InvestorName}`;
    }
    return fullName;
  };

  const getIdentifiers = () => {
    let identifiers = {
      holdingIdentifierOne: "",
      holdingIdentifierTwo: "",
    };
    if (selectedLob === LiLobCode) {
      identifiers = {
        holdingIdentifierOne:
          addedPolicyAllDetails?.Policy_Level_Details.POLICY_NUMBER || "",
        holdingIdentifierTwo:
          addedPolicyAllDetails?.Policy_Level_Details.APPLICATION_NUMBER || "",
      };
    } else if (selectedLob === HiLobCode) {
      identifiers = identifiers = {
        holdingIdentifierOne:
          policyInsuredDetails?.PolicyDetail.PolicyNumber || "",
        holdingIdentifierTwo:
          policyInsuredDetails?.PolicyDetail.ApplicationNo || "",
      };
    } else if (selectedLob === MfLobCode) {
      identifiers = identifiers = {
        holdingIdentifierOne: selectedFolioDetails?.Folio_No || "",
        holdingIdentifierTwo: nanoid(),
      };
    }
    return identifiers;
  };

  const handleVerifyOtpClick = () => {
    if (isOtpBypassEnable === "true") {
      handleBypassOtp();
    } else {
      handleActualOtpVerify();
    }
  };

  const mapPolicyWithPrimaryUser = () => {
    mapLobProductApi({
      LOBId: selectedLobId,
      CIINo: customerAndLobDetails?.[0].CII || "",
    }).then((res) => {
      if (res.data?.code === 1) {
        saveHoldingForPrimary();
      }
      if (res.isError || res.data?.code !== 1) {
        setIsBtnLoading(false);
        dispatch(
          setError({
            error: res.error as T_ApiResponse<any>,
            message: "mapLobProduct API failed",
          })
        );
      }
    });
  };

  const setNewHoldingDataInCustomerLobDetails = ({
    cii = "",
    user = "",
    relationship = "",
    emailId = "",
  }: {
    cii?: string;
    user: string;
    relationship?: string;
    emailId?: string;
  }) => {
    let newHoldingData = {
      MOBILE: getMobileNumber(),
      EMAIL: emailId,
      PAN: getPanNumber(),
      DOB: enteredDob
        ? dayjs(enteredDob, "DD-MM-YYYY").format("YYYY-MM-DD")
        : dayjs(getDobNumber()).format("YYYY-MM-DD"),
      LOBID: getLobRefId(),
      STATUS: "ACTIVE",
      LOB: selectedLob || "",
      CII: cii,
      CREATED_DATE: dayjs().format("YYYY-MM-DD"),
      MODIFIED_DATE: dayjs().format("YYYY-MM-DD"),
      PRIMARY_SECONDARY_USER: user,
      NAME: getHolderName(),
      RELATIONSHIP: relationship,
      CUSTOMER_HOLDER_UNIQUE_ID: "",
      HOLDING_IDENTIFIER_ONE: getIdentifiers().holdingIdentifierOne,
      HOLDING_IDENTIFIER_TWO: getIdentifiers().holdingIdentifierTwo,
    };
    let tempData = {
      ...userDetails,
      CS_USER_ID: userDetails?.CS_USER_ID,
      IS_FIRST_TIME_USER: !!userDetails?.IS_FIRST_TIME_USER,
      IS_USER_REGISTERED: !!userDetails?.IS_USER_REGISTERED,
      customerAndLobDetails: [...customerAndLobDetails, newHoldingData],
    };
    dispatch(setCustomerAndLobDetails(tempData));
  };

  const saveHoldingForPrimary = () => {
    let payload = {
      uniqueId: "",
      csUserId: userDetails?.CS_USER_ID || "",
      secondaryCii: "",
      holderName: getHolderName(),
      lob: selectedLob || "",
      dob: enteredDob
        ? dayjs(enteredDob, "DD-MM-YYYY").format("YYYY-MM-DD")
        : dayjs(getDobNumber()).format("YYYY-MM-DD"),
      mobile: getMobileNumber(),
      emailId: userDetails?.emailId || "",
      pan: getPanNumber(),
      clientId: getLobRefId(),
      relationship: "self",
      primarySecondaryUser: "primary",
      status: "Active",
      authentication: "Y",
      holdingIdentifierOne: getIdentifiers().holdingIdentifierOne,
      holdingIdentifierTwo: getIdentifiers().holdingIdentifierTwo,
    };
    saveHoldingsApi([payload]).then((res) => {
      setIsBtnLoading(false);
      if (res.data?.code === 1) {
        setNewHoldingDataInCustomerLobDetails({
          cii: customerAndLobDetails?.[0].CII || "",
          user: "primary",
          relationship: "self",
          emailId: userDetails?.emailId || "",
        });
        setManualStep(9);
      }
      if (res.data?.code !== 1) {
        dispatch(
          setError({
            error: res.error as T_ApiResponse<any>,
            message: "saveHoldings API failed",
          })
        );
      }
    });
  };

  const mapSecondaryPolicyWithPrimaryUser = (
    secondaryUserCii: string,
    relation: string
  ) => {
    if (selectedLob === MfLobCode) {
      folioLinkingDeLinkingFun(secondaryUserCii);
    } else {
      mapLobProductApi({
        LOBId: selectedLobId,
        CIINo: secondaryUserCii,
      }).then((res) => {
        if (res.data?.code === 1) {
          saveHoldingForSecondary(secondaryUserCii, relation);
        }
        if (res.isError || res.data?.code !== 1) {
          setIsBtnLoading(false);
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "mapLobProduct API failed",
            })
          );
        }
      });
    }
  };

  const saveHoldingForSecondary = (
    secondaryUserCii: string = "",
    relation: string = ""
  ) => {
    let relationValue =
      selectedRadioValue === "Other"
        ? otherRelation
        : relation || selectedRadioValue;
    let payload = {
      uniqueId: "",
      csUserId: userDetails?.CS_USER_ID || "",
      secondaryCii: secondaryUserCii,
      holderName: getHolderName(),
      lob: selectedLob || "",
      dob: enteredDob
        ? dayjs(enteredDob, "DD-MM-YYYY").format("YYYY-MM-DD")
        : dayjs(getDobNumber()).format("YYYY-MM-DD"),
      mobile: getMobileNumber(),
      emailId: newEmail || "",
      pan: getPanNumber(),
      clientId: getLobRefId(),
      relationship: relationValue,
      primarySecondaryUser: "secondary",
      status: "Active",
      authentication: "Y",
      holdingIdentifierOne: getIdentifiers().holdingIdentifierOne,
      holdingIdentifierTwo: getIdentifiers().holdingIdentifierTwo,
    };
    saveHoldingsApi([payload]).then((res) => {
      setIsBtnLoading(false);
      if (res.data?.code === 1) {
        setNewHoldingDataInCustomerLobDetails({
          cii: secondaryUserCii,
          user: "secondary",
          relationship: relationValue,
          emailId: newEmail,
        });
        setManualStep(9);
      }
      if (res.data?.code !== 1) {
        dispatch(
          setError({
            error: res.error as T_ApiResponse<any>,
            message: "saveHoldings API failed",
          })
        );
      }
    });
  };

  const folioLinkingDeLinkingFun = (CII: string = "") => {
    const requestBody = {
      PrimaryFolio: folioList[0].Folio_No || "",
      PrimaryPan: folioList[0].PANNo || "",
      CIINumber: CII,
      LinkFolio: selectedFolioDetails?.Folio_No || "",
      LinkPan: selectedFolioDetails?.PANNo || "",
      IsLink: "Y",
      UserId: folioApiReqBodyUserId,
      Password: folioApiReqBodyPSW,
    };
    folioLinkingDeLinkingApi(requestBody).then((res) => {
      if (res.isError || res.data?.code !== 1) {
        setIsBtnLoading(false);
        dispatch(
          setError({
            error: res.error as T_ApiResponse<any>,
            message: "folioLinkingDeLinking API failed",
          })
        );
      }
      if (res.data?.code === 1) {
        if (enteredPan === userDetails?.pan) {
          saveHoldingForPrimary();
        } else {
          saveHoldingForSecondary(CII);
        }
      }
    });
  };

  const checkIfPrimaryOrSecondaryPolicy = () => {
    let P2 = getPanNumber();
    let D2 = getDobNumber();
    let P1 = userDetails?.pan;
    let D1orD3 = enteredDob
      ? dayjs(enteredDob, "DD-MM-YYYY").format("DD-MM-YYYY")
      : dayjs(userDetails?.dob).format("DD-MM-YYYY");
    if (P1 === P2) {
      mapForPrimaryUser();
    } else if (P2 === null && D1orD3 === dayjs(D2).format("DD-MM-YYYY")) {
      mapForPrimaryUser();
    } else {
      setIsBtnLoading(false);
      setManualStep(2);
    }
  };

  const mapForPrimaryUser = () => {
    if (selectedLob === MfLobCode) {
      folioLinkingDeLinkingFun(customerAndLobDetails?.[0].CII);
    } else {
      mapPolicyWithPrimaryUser();
    }
  };

  const handleEmailSubmit = () => {
    if (!isValidateEmail(newEmail)) {
      return setEmailError("Enter a valid email");
    } else {
      setEmailError("");
      if (selectedLob === LiLobCode) setManualStep(3);
      else if (selectedLob === HiLobCode) setManualStep(8);
      else if (selectedLob === MfLobCode) setManualStep(3);
    }
  };

  const getLobRefId = () => {
    let lobRefId = "";
    if (selectedLob === LiLobCode) {
      lobRefId = ownerInfo?.CLIENT_ID || "";
    } else if (selectedLob === HiLobCode) {
      lobRefId = getMobileNumber();
    } else if (selectedLob === MfLobCode) {
      lobRefId = selectedFolioDetails?.PANNo || "";
    }
    return lobRefId;
  };

  const handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailError("");
    setNewEmail(event?.target.value);
  };

  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!isValidateEmail(value)) {
      return setEmailError("Enter a valid email");
    }
  };

  const handleRelationshipContinueClick = async (relation: string = "") => {
    setIsBtnLoading(true);
    let ciiForSecondaryUser = "";
    const ciiIfExists = await Api_checkIfCiiExistsForMobileEmail({
      EmailId: newEmail || "",
      MobileNo: getMobileNumber(),
    });
    if (ciiIfExists.isError || ciiIfExists.error) {
      setIsBtnLoading(false);
      dispatch(
        setError({
          error: ciiIfExists.error as T_ApiResponse<any>,
          message: "check login Id API failed",
        })
      );
      return;
    }
    if (!ciiIfExists.isError && ciiIfExists.data?.payload.CII) {
      ciiForSecondaryUser = ciiIfExists.data.payload.CII;
    }
    if (ciiForSecondaryUser) {
      mapSecondaryPolicyWithPrimaryUser(ciiForSecondaryUser, relation);
      return;
    }
    if (!ciiForSecondaryUser) {
      await teraDataUserRegistration({
        abcUserId: newEmail,
        mobileNo: getMobileNumber(),
        abcPassword: enteredDob
          ? dayjs(enteredDob, "DD-MM-YYYY").format("YYYY-MM-DD")
          : dayjs(getDobNumber()).format("YYYY-MM-DD"),
        passwordType: "DOB in YYYY/MM/DD",
        userLobsDetails: [
          {
            lobRefId: getLobRefId(),
            lobCode: selectedLobId || "",
          },
        ],
        dob: enteredDob
          ? dayjs(enteredDob, "DD-MM-YYYY").format("YYYY-MM-DD")
          : dayjs(getDobNumber()).format("YYYY-MM-DD"),
        emailId: newEmail,
        panNo: getPanNumber(),
        customerName: getHolderName(),
        primarySecondaryUser: "secondary",
        presentCiiNo: "",
        ciiNoPresent: false,
      }).then((res) => {
        if (res.data?.code === 1 && res.data?.payload?.CII) {
          saveHoldingForSecondary(res.data?.payload?.CII);
        }
        if (res.isError || res.data?.code !== 1) {
          setIsBtnLoading(false);
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "teraDataUserRegistration API failed",
            })
          );
          return;
        }
      });
    }
  };

  const handleFolioSelectionContinue = async () => {
    const primaryMfFolioDetails = MfData.folioDetails.filter(
      (item) => item.Folio_No === selectedFolioRadio
    );
    const secondaryUsers =
      customerAndLobDetails?.filter(
        (user) =>
          user.LOB === MfLobCode && user.PRIMARY_SECONDARY_USER === "secondary"
      ) || [];
    const secondaryMfFolioDetails = secondaryUsers.filter(
      (item) => item.HOLDING_IDENTIFIER_ONE === selectedFolioRadio
    );
    if (primaryMfFolioDetails.length || secondaryMfFolioDetails.length) {
      setIsPolicyExist(true);
      setManualStep(10);
      setIsBtnLoading(false);
      return;
    }
    const mobileNumber = getMobileNumber();
    setIsBtnLoading(true);
    await generateOtpApi({ mobileNo: mobileNumber, otp: "" }).then((res) => {
       handleGenerateOtpResponse(res);

    });
  };

  const handleOtpOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      setOtpValue(value);
      setOtpErrorMessage("");
    }
  };

  const handleRadioBtnClick = (data: RadioListRelationData) => {
    setSelectedRadioValue(data.value);
  };
  const handleFolioRadioClick = (data: string) => {
    setSelectedFolioRadio(data);
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date?.isAfter(dayjs()) || date === null) {
      setEnteredDob(null);
      return;
    }
    if (dayjs(date).isAfter("1900-01-01") && dayjs(date).isValid()) {
      setEnteredDob(dayjs(date).format("DD-MM-YYYY"));
    }
  };

  const handleThisIsMineBtn = () => {
    handleRelationshipContinueClick("Self");
  };

  const handleThisBelongToMyFamily = () => {
    setManualStep(3);
  };

  const getBtnDisabled = () => {
    let isButtonDisabled = true;
    if (selectedLob === LiLobCode) {
      isButtonDisabled =
        !product ||
        policyNumberValue.length < 9 ||
        !enteredDob ||
        !!helperTextPolicyNumber ||
        !!dobError;
    } else if (selectedLob === HiLobCode) {
      isButtonDisabled =
        !product || policyNumberValue.length < 16 || !!helperTextPolicyNumber;
    } else if (selectedLob === MfLobCode) {
      isButtonDisabled = !product || enteredPan.length < 10 || !!helperTextPan;
    }
    return isButtonDisabled;
  };

  const handleChangePolicyNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.trim();
    setPolicyNumberValue(value);
    // Clear error message on input change
    setHelperTextPolicyNumber("");
    if (selectedLob === LiLobCode) {
      if (value.length === 9 && !/^\d{0,9}$/.test(value)) {
        setHelperTextPolicyNumber("Please enter a valid 9 digit policy number");
      }
    } else if (selectedLob === HiLobCode) {
      if (value.length === 16 && !/^\d{2}-\d{2}-\d{7}-\d{2}$/.test(value)) {
        setHelperTextPolicyNumber(
          "Please enter a valid 16 digit policy number"
        );
      }
    }
  };

  const handleBlurPolicyNumber = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const value = event.target.value.trim();
    if (selectedLob === LiLobCode) {
      if (value.length < 9 || !/^\d{0,9}$/.test(value)) {
        setHelperTextPolicyNumber("Please enter a valid 9 digit policy number");
      }
    } else if (selectedLob === HiLobCode) {
      if (value.length < 16 || !/^\d{2}-\d{2}-\d{7}-\d{2}$/.test(value)) {
        setHelperTextPolicyNumber(
          "Please enter a valid 16 digit policy number"
        );
      }
    }
  };

  const handleChangePan = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.trim();
    value = value.toUpperCase();
    setEnteredPan(value);
    if (value.length === 10 && !panRegex.test(value)) {
      setHelperTextPan("Please enter a valid PAN.");
    } else {
      setHelperTextPan("");
    }
  };

  const getPolicyMaxLength = () => {
    if (selectedLob === LiLobCode) return 9;
    else if (selectedLob === HiLobCode) return 16;
  };

  const getSuccessData = () => {
    if (selectedLob === HiLobCode) {
      return policyInsuredDetails;
    }
    return undefined;
  };

  const getDisplayInfoContent = () => {
    let title = "";
    let desc = "";
    if (selectedLob === LiLobCode) {
      title = "Policy already added on dashboard";
      desc = "The policy you are trying to add already exists on the dashboard";
    } else if (selectedLob === HiLobCode) {
      title = isPolicyExist
        ? "Policy already added on dashboard"
        : "No holding found";
      desc = isPolicyExist
        ? "The policy you are trying to add already exists on the dashboard"
        : "We are unable to find any holdings with the details entered";
    } else if (selectedLob === MfLobCode) {
      title = isPolicyExist
        ? "Folio already added on dashboard"
        : "No holding found";
      desc = isPolicyExist
        ? "The folio you are trying to add already exists on the dashboard"
        : "We are unable to find any new holding for the entered PAN";
    }
    return { title, desc };
  };

  const isButtonDisable = () => {
    if (selectedRadioValue === "Other" && !otherRelation) {
      return true;
    }
    if (!selectedRadioValue) {
      return true;
    }
    return false;
  };

  const handleProductSelect = (value: string) => {
    setProduct(value);
    setPolicyNumberValue("");
    setEnteredDob("");
    setEnteredPan("");
    setHelperTextPan("");
    setDobError("");
    setHelperTextPolicyNumber("");
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    // Regex to allow only alphabetical characters, numericals and spaces
    const sanitizedValue = input.replace(/[^A-Za-z0-9\s]/g, "");

    // Update state and error flag
    if (input !== sanitizedValue) {
      setOthersValueError(true); // Show error if any non-alphabetic character is detected
    } else {
      setOthersValueError(false);
    }

    setOtherRelation(sanitizedValue);
  };

  const renderSteps = () => {
    if (manualStep === 0) {
      return (
        <Box>
          <Typography textAlign="center" variant="h5">
            ADD PRODUCT MANUALLY
          </Typography>
          <Stack rowGap="1.5rem" marginTop="1.5rem">
            <CustomSelect
              label="Select product category"
              value={product}
              onChange={handleProductSelect}
              options={productCategoryOptions}
              placeholder="Select product category"
            />
            {product && [HiLobCode, LiLobCode].includes(selectedLob) && (
              <>
                <TextField
                  fullWidth
                  label="Policy Number"
                  onBlur={handleBlurPolicyNumber}
                  onChange={handleChangePolicyNumber}
                  value={policyNumberValue}
                  error={helperTextPolicyNumber.length > 0}
                  inputProps={{
                    maxLength: getPolicyMaxLength(),
                    inputMode: selectedLob === LiLobCode ? "numeric" : "text",
                  }}
                  helperText={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <InputAdornment position="start">
                        {helperTextPolicyNumber.length > 0 && (
                          <img src={AlertRhombus} />
                        )}
                      </InputAdornment>
                      {helperTextPolicyNumber}
                    </span>
                  }
                />
              </>
            )}
            {product && [MfLobCode].includes(selectedLob) && (
              <>
                <TextField
                  fullWidth
                  label="PAN"
                  onChange={handleChangePan}
                  value={enteredPan}
                  placeholder="Enter PAN"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <img
                          src={panImage}
                          alt="pan"
                          style={{ width: "1.25rem" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onBlur={(e) => {
                    if (!panRegex.test(e.target.value)) {
                      setHelperTextPan("Please enter a valid PAN.");
                    }
                  }}
                  helperText={
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <InputAdornment position="start">
                        {helperTextPan.length > 0 && <img src={AlertRhombus} />}
                      </InputAdornment>
                      {helperTextPan}
                    </span>
                  }
                  inputProps={{
                    maxLength: 10,
                    inputMode: "text",
                    pattern: panRegex,
                    style: {
                      textTransform: "uppercase",
                    },
                  }}
                />
              </>
            )}
            {product && selectedLob === LiLobCode && (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{
                  ...usLocale,
                  fieldMonthPlaceholder: (params) =>
                    params.contentType === "letter" ? "MMM" : "MM",
                }}
              >
                <DesktopDatePicker
                  label="Policy Owner DOB"
                  format="DD/MM/YYYY"
                  className="date-picker"
                  value={enteredDob ? dayjs(enteredDob, "DD-MM-YYYY") : null}
                  onChange={handleDateChange}
                  disableFuture
                  onError={(error) =>
                    error
                      ? setDobError("Please enter valid DOB")
                      : setDobError(null)
                  }
                  slotProps={{
                    textField: {
                      autoComplete: "new-password",
                      helperText: (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <InputAdornment position="start">
                            {dobError && <img src={AlertRhombus} />}
                          </InputAdornment>
                          {dobError}
                        </span>
                      ),
                    },
                  }}
                />
              </LocalizationProvider>
            )}
            {isBtnLoading ? (
              <CustomThreeDotsLoader />
            ) : (
              <Button
                fullWidth
                variant="contained"
                disabled={getBtnDisabled()}
                className="continue-btn"
                onClick={handleGenerateOtpClick}
              >
                Continue
              </Button>
            )}
          </Stack>
        </Box>
      );
    } else if (manualStep === 1) {
      return (
        <Box marginTop="">
          <Typography textAlign="center" variant="h5">
            VERIFY YOUR HOLDING
          </Typography>
          <Typography
            marginTop="0.5rem"
            textAlign="center"
            variant="subtitle2"
            marginBottom="1.5rem"
          >
            We've sent an OTP to {getMaskedMobileNumber(getMobileNumber(), "X")}
          </Typography>
          <TextField
            sx={{ marginBottom: "1.5rem" }}
            fullWidth
            label="Enter OTP"
            placeholder="Enter OTP"
            onChange={handleOtpOnChange}
            value={otpValue}
            helperText={
              <span style={{ display: "flex", alignItems: "center" }}>
                <InputAdornment position="start">
                  {otpErrorMessage.length > 0 && <img src={AlertRhombus} />}
                </InputAdornment>
                {otpErrorMessage}
              </span>
            }
            error={otpErrorMessage.length > 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!(otpValue.length === 6) &&
                    (isTimerOn.current ? (
                      <Typography variant="h4">
                        {`Resend OTP in 00:${count
                          .toString()
                          .padStart(2, "0")}s`}
                      </Typography>
                    ) : !isTimerOn.current && reSendOtpAttemptLeft > 0 ? (
                      <Button variant="text" onClick={handleResendOTP}>
                        Resend OTP
                      </Button>
                    ) : null)}
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 6,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
          {isBtnLoading ? (
            <CustomThreeDotsLoader />
          ) : (
            <Button
              fullWidth
              variant="contained"
              disabled={otpVerifyAttemptLeft <= 0 || otpValue.length < 6}
              className="continue-btn"
              onClick={handleVerifyOtpClick}
            >
              Continue
            </Button>
          )}
        </Box>
      );
    } else if (manualStep === 2) {
      return (
        <Box>
          <Typography textAlign="center" variant="h5">
            ENTER YOU EMAIL ID
          </Typography>
          <Typography marginTop="1.5rem" variant="h5" fontWeight={400}>
            Enter your email ID
          </Typography>
          <Box marginBottom="1.5rem" marginTop="1rem">
            <TextField
              fullWidth
              onBlur={handleEmailBlur}
              label="Enter new email id"
              onChange={handleOnChangeEmail}
              value={newEmail}
              error={emailError.length > 0}
              helperText={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <InputAdornment position="start">
                    {emailError.length > 0 && <img src={AlertRhombus} />}
                  </InputAdornment>
                  {emailError}
                </span>
              }
            />
          </Box>
          {isBtnLoading ? (
            <CustomThreeDotsLoader />
          ) : (
            <Button
              fullWidth
              variant="contained"
              disabled={!newEmail || !isValidateEmail(newEmail)}
              className="continue-btn"
              onClick={handleEmailSubmit}
            >
              Submit
            </Button>
          )}
        </Box>
      );
    } else if (manualStep === 3) {
      return (
        <Box>
          <Typography textAlign="center" variant="h5">
            RELATIONSHIP
          </Typography>
          <Typography
            marginTop="0.5rem"
            textAlign="center"
            variant="subtitle2"
            marginBottom="1.5rem"
          >
            Select your relationship with product holder
          </Typography>
          <Box margin="1.5rem 0 .625rem">
            <RadioGroup className="radio-group">
              {relationshipOptions.map(
                (item: RadioListRelationData, index: number) => (
                  <>
                    <Box
                      key={item.value}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY="1.5rem"
                    >
                      <Typography variant="body1">{item.label}</Typography>
                      <Radio
                        checked={item.value === selectedRadioValue}
                        onClick={() => handleRadioBtnClick(item)}
                        sx={{
                          padding: 0,
                          color: Colors.red,
                          "&.Mui-checked": {
                            color: Colors.red,
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                    </Box>
                    {relationshipOptions.length - 1 > index && (
                      <Divider sx={{ color: "#F7F7F7" }} />
                    )}
                  </>
                )
              )}
            </RadioGroup>
            {selectedRadioValue.toLowerCase() === "other" && (
              <TextField
                fullWidth
                label="Other"
                onChange={handleChange}
                value={otherRelation}
                placeholder="Specify your relationship"
                inputProps={{
                  maxLength: 20,
                }}
                helperText={
                  othersValueError
                    ? "Only alphabetic characters, numbers and spaces are allowed."
                    : ""
                }
              />
            )}
            {isBtnLoading ? (
              <CustomThreeDotsLoader />
            ) : (
              <Button
                fullWidth
                variant="contained"
                sx={{
                  marginTop: "1.5rem",
                }}
                disabled={isButtonDisable()}
                className="continue-btn"
                onClick={() => handleRelationshipContinueClick()}
              >
                Continue
              </Button>
            )}
          </Box>
        </Box>
      );
    } else if (manualStep === 7) {
      return (
        <Box>
          <Typography textAlign="center" variant="h5">
            SELECT FOLIO NUMBER
          </Typography>
          <Typography
            textAlign="center"
            marginTop="0.5rem"
            marginBottom="1.5rem"
            variant="h5"
            fontWeight={500}
          >
            Select Folio number to send OTP to the linked mobile number
            <Box margin="1.5rem 0 .625rem">
              <RadioGroup className="radio-group">
                {displayedFolioList.map((item: T_Folio, index: number) => (
                  <>
                    <Box
                      key={item.Folio_No}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY="1.5rem"
                    >
                      <Box>
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                          <Typography>Folio No.</Typography>
                          <Typography>
                            {item.Folio_No.slice(0, 2) +
                              `*`.repeat(6) +
                              item.Folio_No.slice(-2)}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", gap: "1rem" }}>
                          <Typography>Mobile No.</Typography>
                          <Typography>
                            {getMaskedMobileNumber(
                              item.MobileNumber.replace(/\s/g, ""),
                              "*"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                      <Radio
                        checked={item.Folio_No === selectedFolioRadio}
                        onClick={() => handleFolioRadioClick(item.Folio_No)}
                        sx={{
                          padding: 0,
                          color: Colors.red,
                          "&.Mui-checked": {
                            color: Colors.red,
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                    </Box>
                    {displayedFolioList.length - 1 > index && (
                      <Divider sx={{ color: "#F7F7F7" }} />
                    )}
                  </>
                ))}
              </RadioGroup>
              {isBtnLoading ? (
                <CustomThreeDotsLoader />
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: "1.5rem",
                  }}
                  disabled={!selectedFolioRadio}
                  className="continue-btn"
                  onClick={handleFolioSelectionContinue}
                >
                  Continue
                </Button>
              )}
            </Box>
          </Typography>
        </Box>
      );
    } else if (manualStep === 8) {
      return (
        <Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img src={memberImage} style={{ width: "100%" }} />
          </Box>
          <Typography textAlign="center" variant="h5" marginTop="1.5rem">
            ADD HOLDER DETAILS
          </Typography>
          <Typography
            textAlign="center"
            marginTop="0.5rem"
            marginBottom="1.5rem"
            variant="h5"
            fontWeight={500}
          >
            Help us serve you better by declaring your relationship with holder
          </Typography>
          {isBtnLoading ? (
            <CustomThreeDotsLoader />
          ) : (
            <Button
              fullWidth
              variant="contained"
              className="continue-btn"
              onClick={handleThisIsMineBtn}
            >
              This is mine
            </Button>
          )}
          <Box display="flex" marginTop="0.75rem" justifyContent="center">
            <CustomLink
              title="This belongs to my family member"
              titleSize=".75rem"
              onClick={handleThisBelongToMyFamily}
            />
          </Box>
        </Box>
      );
    } else if (manualStep === 9) {
      return (
        <AddManuallySuccessBody
          data={getSuccessData()}
          title={selectedLobDetails?.lobName || ""}
          goHomePageHandler={handleClosePopup}
          addAnotherHoldingHandler={clearAllInputData}
        />
      );
    } else if (manualStep === 10) {
      return (
        <Box>
          <Box
            display="flex"
            height="13.5625rem"
            justifyContent="center"
            alignItems="center"
          >
            <CommonGifAnimation
              animationData={NoHoldingsFoundGif}
              heightCustom="13.5625rem"
              maxWidth="16.875rem"
            />
          </Box>
          <Typography variant="h4" marginTop="1rem" textAlign="center">
            {getDisplayInfoContent().title}
          </Typography>
          <Typography
            variant="subtitle2"
            marginTop="1rem"
            marginBottom="2rem"
            textAlign="center"
          >
            {getDisplayInfoContent().desc}
          </Typography>
          {!isPolicyExist && selectedLob === HiLobCode && (
            <Button
              fullWidth
              variant="contained"
              className="continue-btn"
              onClick={clearAllInputData}
            >
              Retry
            </Button>
          )}
          <Box display="flex" marginTop="0.75rem" justifyContent="center">
            <Typography
              color={Colors.red}
              variant="h5"
              sx={{ cursor: "pointer" }}
              onClick={handleClosePopup}
              textAlign="center"
            >
              Go back to dashboard
            </Typography>
          </Box>
        </Box>
      );
    }
  };

  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      btnLabel={[9, 10].includes(manualStep) ? "" : "Close"}
      className={`add-manual-popup ${manualStep === 9 ? "centered" : ""}`}
      btnStartIcon={<CloseIcon sx={{ fontSize: "1.0625rem" }} />}
    >
      <Box>{renderSteps()}</Box>
    </CustomPopup>
  );
};

export default AddManuallyPopup;
