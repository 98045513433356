import { createSlice } from "@reduxjs/toolkit";
import { T_LifeInsuranceSliceState } from "./LifeInsurance_Types";

const initialState: T_LifeInsuranceSliceState = {
  dashboardDetails: [],
  secondaryDashboardDetails: [],
  dashboardFundDetails: [],
  policyAllDetails: [],
  policyDetails: {},
  fundDetails: {},
  pastPaymentDetails: {},
  polBankDetails: [],
  premiumReceiptDetails: [],
  annuityDetails: [],
  retryDashboardDetails: 0,
  retrySecondaryDashboardDetails: 0,
};

export const LifeInsuranceSlice = createSlice({
  name: "LifeInsuranceSlice",
  initialState,
  reducers: {
    setLiDashboardDetails: (state, action) => {
      return {
        ...state,
        dashboardDetails: action.payload,
      };
    },
    setSecondaryLiDashboardDetails: (state, action) => {
      return {
        ...state,
        secondaryDashboardDetails: action.payload,
      };
    },
    setLiDashboardFundDetails: (state, action) => {
      return {
        ...state,
        dashboardFundDetails: action.payload,
      };
    },
    setLiSecondaryDashboardFundDetails: (state, action) => {
      return {
        ...state,
        dashboardFundDetails: [
          ...state.dashboardFundDetails,
          ...action.payload,
        ],
      };
    },
    setLiPolicyAllDetails: (state, action) => {
      return {
        ...state,
        policyAllDetails: action.payload,
      };
    },
    setLiPolicyDetails: (state, action) => {
      return {
        ...state,
        policyDetails: action.payload,
      };
    },
    setLiFundDetails: (state, action) => {
      return {
        ...state,
        fundDetails: action.payload,
      };
    },
    setLiPastPaymentDetails: (state, action) => {
      return {
        ...state,
        pastPaymentDetails: action.payload,
      };
    },
    setLiPolBankDetails: (state, action) => {
      return {
        ...state,
        polBankDetails: action.payload,
      };
    },
    setLiPremiumReceiptDetails: (state, action) => {
      return {
        ...state,
        premiumReceiptDetails: action.payload,
      };
    },
    setLiAnnuityDetails: (state, action) => {
      return {
        ...state,
        annuityDetails: action.payload,
      };
    },
    retryDashboardDetails: (state) => {
      const incrementCount =
        (state?.retryDashboardDetails ? state?.retryDashboardDetails : 0) + 1;
      return {
        ...state,
        retryDashboardDetails: incrementCount,
      };
    },
    retrySecondaryDashboardDetails: (state) => {
      const incrementCount =
        (state?.retrySecondaryDashboardDetails
          ? state?.retrySecondaryDashboardDetails
          : 0) + 1;
      return {
        ...state,
        retrySecondaryDashboardDetails: incrementCount,
      };
    },
  },
});

export const LifeInsuranceReducer = LifeInsuranceSlice.reducer;
export const LifeInsuranceSliceKey = LifeInsuranceSlice.reducerPath;
export const {
  setLiDashboardDetails,
  setLiDashboardFundDetails,
  setLiPolicyAllDetails,
  setLiPolicyDetails,
  setLiFundDetails,
  setLiPolBankDetails,
  setLiPastPaymentDetails,
  setLiPremiumReceiptDetails,
  setLiAnnuityDetails,
  setSecondaryLiDashboardDetails,
  retryDashboardDetails,
  retrySecondaryDashboardDetails,
  setLiSecondaryDashboardFundDetails,
} = LifeInsuranceSlice.actions;
