import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CustomEditField from "../../../components/CustomEditField/CustomEditField";
import { hiEditPolicyProposalFrom } from "../../../utils/constants";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../../styles/constant";
import "../HiDetailsPage/HiDetailsPage.scss";
import "../HiDetailsPage/HiDetailsPage.scss";
import "./ProposerDetails.scss";
import PremiumChangeWarning from "../HiEditPolicy/PremiumChangeWarning/PremiumChangeWarning";
import IdentityProof from "../HiEditPolicy/IdentityProof/IdentityProof";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import { getCaseCreateReqPayload } from "../../../utils/helper";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { T_UpdateHIDetails_ReqPayload } from "../HealthInsurance_Types";
import {
  useLazyFetchPolicyInsuredQuery,
  useLazyUpdateHIDetailsQuery,
} from "../HealthInsurance_Api";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import HiUploadIdentityDocument from "../HiUploadIdentityDocument/HiUploadIdentityDocument";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { enUS } from "@mui/x-date-pickers/locales";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { T_LiRouteState } from "../../../globalTypes/GlobalTypes";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import { setHiAllPolicyInsuredDetails } from "../HealthInsurance_Slice";
import CustomShimmer from "../../../components/CustomShimmer/CustomShimmer";

interface RadioListProofData {
  label: string;
  value: string;
}

interface fileData {
  attachmentContent: string;
  attachmentType: string;
  attachmentFileName: string;
}

interface CDEreturnValue {
  getValue: () => string;
  focusErr: (errMsg: string) => void;
}

interface fileData {
  attachmentContent: string;
  attachmentType: string;
  attachmentFileName: string;
}

interface CDEreturnValue {
  getValue: () => string;
  focusErr: (errMsg: string) => void;
}

type workflowInfo = {
  [key: string]: any;
};

const usLocale =
  enUS.components.MuiLocalizationProvider.defaultProps.localeText;

const commonEditFlow = [
  "premiumWarning",
  "editField",
  "identityProof",
  "uploadDocs",
  "success",
];

const EditPanFlow = ["editField", "uploadDocs", "success"];

const workflow: workflowInfo = {
  PolicyOwnerName: commonEditFlow,
  Gender1: commonEditFlow,
  DateOfBirth: commonEditFlow,
  pan: EditPanFlow,
};

const dataFlow: workflowInfo = {
  PolicyOwnerName: hiEditPolicyProposalFrom[0],
  DateOfBirth: hiEditPolicyProposalFrom[1],
  pan: hiEditPolicyProposalFrom[2],
  Gender1: hiEditPolicyProposalFrom[4],
};

const validations: workflowInfo = {
  PolicyOwnerName: {
    validation: "^.{0,50}$",
    fieldValidation: "^[a-zA-Z ]{1,50}$",
  },
  pan: {
    validation: "^[A-Z0-9]{0,10}$",
    fieldValidation: "^[A-Z]{5}[0-9]{4}[A-Z]{1}$",
  },
};

const Gender1List = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Other",
    value: "Other",
  },
];

const genderData: workflowInfo = {
  M: "Male",
  F: "Female",
  O: "Other",
};

const ProposerDetails: FC = () => {
  const allPolicyInsuredDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyInsuredDetails
  );
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState)?.policyNumber || "";
  const dispatch = useDispatch();
  const [updateHiDetailsApi] = useLazyUpdateHIDetailsQuery();
  const [fetchPolicyInsuredApi] = useLazyFetchPolicyInsuredQuery();
  const [open, setOpen] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [activeFlow, setActiveFlow] = useState<string>("");
  const [selectedProofData, setSelectedProofData] = useState<string>("");
  const [proposerData, setProposerDetails] = useState<workflowInfo>({});
  const [updatedData, setUpdatedData] = useState<workflowInfo>({
    PolicyOwnerName: "",
    DateOfBirth: "",
    Gender1: "",
    pan: "",
  });
  const [dobErr, setDobErr] = useState<string>("");
  const [proposerNameErr, setProposerNameErr] = useState<string>("");
  const [panErr, setPanErr] = useState<string>("");
  const editFieldRef = useRef<CDEreturnValue>(null);
  const [withClose, setWithClose] = useState<boolean>(true);
  const [isFetchApiLoading, setIsFetchApiLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      activeFlow !== "" &&
      step > 0 &&
      workflow[activeFlow].length - 1 === step
    ) {
      setWithClose(false);
    } else setWithClose(true);
  }, [step, workflow, activeFlow]);

  useEffect(() => {
    const selectedPolicy = allPolicyInsuredDetails.find(
      (item) => item.PolicyDetail.PolicyNumber === policyNumber
    );

    const PolicyDetails = selectedPolicy ? selectedPolicy.PolicyDetail : null;
    const PolicyDetailsforPanandAadhar = selectedPolicy
      ? selectedPolicy.InsuredDetail
      : [];

    // Filter entries to get non-empty PanNo and AadharCradNo values
    const validEntries = PolicyDetailsforPanandAadhar.filter(
      (entry) => entry.PanNo
    );
    const aadharValue =
      validEntries.length > 0
        ? validEntries[0].AadharCradNo
        : PolicyDetailsforPanandAadhar.length > 0
        ? PolicyDetailsforPanandAadhar[0].AadharCradNo
        : null;

    const panValue =
      validEntries.length > 0
        ? validEntries[0].PanNo
        : PolicyDetailsforPanandAadhar.length > 0
        ? PolicyDetailsforPanandAadhar[0].PanNo
        : null;
    setProposerDetails((prevState) => {
      return {
        ...prevState,
        PolicyOwnerName: PolicyDetails?.PolicyOwnerName,
        DateOfBirth: dayjs(PolicyDetails?.DateOfBirth).format("DD/MM/YYYY"),
        Gender1: PolicyDetails?.Gender1
          ? genderData[PolicyDetails?.Gender1]
          : "",
        aadhar: aadharValue,
        pan: panValue,
      };
    });
  }, [allPolicyInsuredDetails]);

  useEffect(() => {
    if (open) {
      setUpdatedData(proposerData);
    } else {
      setUpdatedData({
        PolicyOwnerName: "",
        DateOfBirth: "",
        Gender1: "",
        pan: "",
      });
      setSelectedProofData("");
      setDobErr("");
      setProposerNameErr("");
      setPanErr("");
    }
  }, [open]);

  const fetchLatestData = useCallback(async (policyNumber: string) => {
    try {
      setIsFetchApiLoading(true);
      await fetchPolicyInsuredApi(policyNumber).then((res) => {
        if (res.data?.code === 1 && res?.data?.payload?.Response) {
          const selectedPolicyIndex = allPolicyInsuredDetails.findIndex(
            (item) => item.PolicyDetail.PolicyNumber === policyNumber
          );
          if (selectedPolicyIndex >= 0) {
            let updatedPolicyInsuredDetails = [...allPolicyInsuredDetails];
            updatedPolicyInsuredDetails[selectedPolicyIndex] =
              res?.data?.payload?.Response;
            dispatch(setHiAllPolicyInsuredDetails(updatedPolicyInsuredDetails));
          }
        } else {
          dispatch(
            setError({
              error: new Error("Error"),
              message: "Fetch Policy Insured API failed",
            })
          );
        }
      });
    } catch (e) {
      console.log(e);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "Fetch Policy Insured API failed",
        })
      );
    } finally {
      setIsFetchApiLoading(false);
    }
  }, []);

  const handleClick = (type: string, value: any) => {
    setActiveFlow(type);
    setStep(0);
    setOpen(!open);
  };

  const handleProofSelection = (data: RadioListProofData) => {
    setSelectedProofData(data.value);
  };

  const handleDOBchange = useCallback((value: Dayjs | null) => {
    if (
      dayjs(value).isAfter("1900-01-01") &&
      dayjs(value).isBefore(dayjs()) &&
      dayjs(value).isValid()
    ) {
      setDobErr("");
    } else {
      setDobErr("Enter a valid DOB");
    }
    setUpdatedData((prevState) => {
      return { ...prevState, DateOfBirth: value };
    });
  }, []);

  const handleProposerNamechange = useCallback(
    (value: string) => {
      if (
        new RegExp(validations["PolicyOwnerName"]?.fieldValidation).test(value)
      ) {
        setProposerNameErr("");
      } else {
        setProposerNameErr("Enter a valid Proposer name");
      }
      setUpdatedData((prevState) => {
        return { ...prevState, PolicyOwnerName: value };
      });
    },
    [editFieldRef]
  );
  const handlePanChange = useCallback(
    (value: string) => {
      if (new RegExp(validations["pan"]?.fieldValidation).test(value)) {
        setPanErr("");
      } else {
        setPanErr("Enter a valid Pan");
      }
      setUpdatedData((prevState) => {
        return { ...prevState, pan: value };
      });
    },
    [editFieldRef]
  );

  const handleBtnClick = (
    flow: string,
    stepVal: number,
    proposerValues: workflowInfo,
    dobErrVal: string,
    proposerNameErr: string,
    panErr: string
  ) => {
    switch (flow) {
      case "PolicyOwnerName":
        if (proposerNameErr === "") {
          setUpdatedData((prevState) => {
            return {
              ...prevState,
              PolicyOwnerName: proposerValues.PolicyOwnerName,
            };
          });
          setStep(stepVal + 1);
        }
        break;
      case "Gender1":
        if (proposerValues.Gender1 !== "") {
          setStep(stepVal + 1);
        }
        break;
      case "DateOfBirth":
        if (
          dayjs(proposerValues.DateOfBirth).isAfter("1900-01-01") &&
          dayjs(proposerValues.DateOfBirth).isBefore(dayjs()) &&
          dayjs(proposerValues.DateOfBirth).isValid()
        ) {
          setStep(stepVal + 1);
        } else {
          setDobErr("Enter a valid DOB");
        }
        break;
      case "pan":
        if (panErr === "") {
          setUpdatedData((prevState) => {
            return {
              ...prevState,
              pan: proposerValues.pan,
            };
          });
          setStep(stepVal + 1);
        }
        break;
    }
  };

  const renderEditComponent = (
    flow: string,
    proposerValues: workflowInfo,
    dobErrVal: string,
    proposerNameErr: string,
    panErr: string
  ) => {
    switch (flow) {
      case "PolicyOwnerName":
        return (
          <TextField
            fullWidth
            label={dataFlow[flow]?.editLabel}
            variant="outlined"
            value={proposerValues["PolicyOwnerName"]}
            type={"text"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (
                new RegExp(validations["PolicyOwnerName"]?.validation).test(
                  e.target.value
                )
              ) {
                handleProposerNamechange(e.target.value);
              }
            }}
            helperText={
              proposerNameErr !== "" ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <InputAdornment position="start">
                    {proposerNameErr && <img src={AlertRhombus} />}
                  </InputAdornment>
                  {proposerNameErr}
                </span>
              ) : (
                ""
              )
            }
          />
        );
      case "Gender1":
        return (
          <Grid
            container
            columns={{ md: 12, sm: 12, xs: 12 }}
            columnSpacing={"0.5rem"}
          >
            {Gender1List.map((data) => {
              return (
                <Grid item md={4} sm={4} xs={4} key={data.label}>
                  <Box
                    className={
                      proposerValues.Gender1 === data.value
                        ? "edit-policy-gender-option selected-gender"
                        : "edit-policy-gender-option"
                    }
                    onClick={() =>
                      setUpdatedData((prevState) => {
                        return { ...prevState, Gender1: data.value };
                      })
                    }
                  >
                    <Typography variant="subtitle2" fontWeight={500}>
                      {data.label}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        );
      case "DateOfBirth":
        return (
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              ...usLocale,
              fieldMonthPlaceholder: (params) =>
                params.contentType === "letter" ? "MMM" : "MM",
            }}
          >
            <DatePicker
              label="DOB"
              format="DD/MM/YYYY"
              // views={["year", "month", "day"]}
              className="date-picker"
              value={
                proposerValues.DateOfBirth
                  ? dayjs(proposerValues.DateOfBirth, "DD/MM/YYYY")
                  : null
              }
              onError={(error) => {
                error ? setDobErr("Enter a valid DOB") : setDobErr("");
              }}
              maxDate={dayjs()}
              sx={{ width: "100%" }}
              onChange={handleDOBchange}
              slotProps={{
                textField: {
                  helperText:
                    (dobErrVal?.length || 0) > 0 ? (
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {dobErrVal && <img src={AlertRhombus} />}
                        </InputAdornment>
                        {dobErrVal}
                      </span>
                    ) : null,
                },
              }}
              disableFuture
            />
          </LocalizationProvider>
        );
      case "pan":
        return (
          <TextField
            fullWidth
            label={dataFlow[flow]?.editLabel}
            variant="outlined"
            value={proposerValues["pan"]}
            type={"text"}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (
                new RegExp(validations["pan"]?.validation).test(e.target.value)
              ) {
                handlePanChange(e.target.value);
              }
            }}
            helperText={
              panErr !== "" ? (
                <span style={{ display: "flex", alignItems: "center" }}>
                  <InputAdornment position="start">
                    {panErr && <img src={AlertRhombus} />}
                  </InputAdornment>
                  {panErr}
                </span>
              ) : (
                ""
              )
            }
          />
        );
    }
  };

  const handlePolicyOwnerNameSubmit = (
    data: fileData,
    value: string,
    stepVal: number
  ) => {
    setIsBtnLoading(true);
    const reqPayload: T_UpdateHIDetails_ReqPayload = getCaseCreateReqPayload(
      {
        AttachmentContent: data.attachmentContent,
        AttachmentType: data.attachmentType,
        AttachmentName: data.attachmentFileName,
        CaseSubSubType: "Change my Name",
        CaseTitle: "Endorsement Change my Name",
        Comments: `Current Proposer Name:${proposerData?.PolicyOwnerName},Updated Proposer Name:${value}`,
        Policy: policyNumber,
      },
      {}
    );
    updateHiDetailsApi(reqPayload).then((res) => {
      setIsBtnLoading(false);
      if (res.data?.code === 1) {
        setStep(stepVal + 1);
      }
      if (res.data?.code !== 1) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
    });
  };

  const handleProposerPanSubmit = (
    data: fileData,
    value: string,
    stepVal: number
  ) => {
    setIsBtnLoading(true);
    const reqPayload: T_UpdateHIDetails_ReqPayload = getCaseCreateReqPayload(
      {
        AttachmentContent: data.attachmentContent,
        AttachmentType: data.attachmentType,
        AttachmentName: data.attachmentFileName,
        CaseSubSubType: "Pancard Update",
        CaseTitle: "EndorsementPancard Update",
        Comments: `Current Proposer PanCardNo:${proposerData?.pan},Updated Proposer PanCardNo:${value}`,
        Policy: policyNumber,
      },
      { PanCardNo: proposerData?.pan }
    );
    updateHiDetailsApi(reqPayload).then((res) => {
      setIsBtnLoading(false);
      if (res.data?.code === 1) {
        setStep(stepVal + 1);
      }
      if (res.data?.code !== 1) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
    });
  };

  const handleProposerDobSubmit = (
    data: fileData,
    value: string,
    stepVal: number
  ) => {
    setIsBtnLoading(true);
    const reqPayload: T_UpdateHIDetails_ReqPayload = getCaseCreateReqPayload(
      {
        AttachmentContent: data.attachmentContent,
        AttachmentType: data.attachmentType,
        AttachmentName: data.attachmentFileName,
        CaseSubSubType: "Correction in DOB",
        CaseTitle: "EndorsementCorrection in DOB",
        Comments: `Current DOB - ${dayjs(proposerData?.DateOfBirth).format(
          "YYYY-MM-DD"
        )}, Updated DOB - ${value}`,
        Policy: policyNumber,
      },
      {}
    );
    updateHiDetailsApi(reqPayload).then((res) => {
      setIsBtnLoading(false);
      if (res.data?.code === 1) {
        setStep(stepVal + 1);
      }
      if (res.data?.code !== 1) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
    });
  };

  const handleProposerGenderSubmit = (
    data: fileData,
    value: string,
    stepVal: number
  ) => {
    setIsBtnLoading(true);
    let val = Object.keys(genderData).find(
      (key) => genderData[key] === proposerData?.Gender1
    );
    const reqPayload: T_UpdateHIDetails_ReqPayload = getCaseCreateReqPayload(
      {
        AttachmentContent: data.attachmentContent,
        AttachmentType: data.attachmentType,
        AttachmentName: data.attachmentFileName,
        CaseSubSubType: "Correction in Gender",
        CaseTitle: "Endorsement Correction in Gender",
        Comments: `Current Proposer Gender:${val},Updated Proposer Gender:${value}`,
        Policy: policyNumber,
      },
      {}
    );
    updateHiDetailsApi(reqPayload).then((res) => {
      setIsBtnLoading(false);
      if (res.data?.code === 1) {
        setStep(stepVal + 1);
      }
      if (res.data?.code !== 1) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
    });
  };

  const handleSubmit = (
    data: fileData,
    stepVal: number,
    updatedValues: workflowInfo,
    flow: string
  ) => {
    switch (flow) {
      case "PolicyOwnerName":
        handlePolicyOwnerNameSubmit(
          data,
          updatedValues?.PolicyOwnerName,
          stepVal
        );
        break;
      case "Gender1":
        let value = Object.keys(genderData).find(
          (key) => genderData[key] === updatedValues?.Gender1
        );
        if (value === undefined) value = "";
        handleProposerGenderSubmit(data, value, stepVal);
        break;
      case "DateOfBirth":
        handleProposerDobSubmit(
          data,
          dayjs(updatedValues?.DateOfBirth).format("YYYY-MM-DD"),
          stepVal
        );
        break;
      case "pan":
        handleProposerPanSubmit(data, updatedValues?.pan, stepVal);
        break;
    }
  };

  const handleWorkflow = useCallback(
    (
      flow: string,
      open: boolean,
      stepVal: number,
      proposerValues: workflowInfo,
      selectedProofData: string,
      dobErrVal: string
    ) => {
      switch (workflow[flow][stepVal]) {
        case "premiumWarning":
          return (
            <PremiumChangeWarning handleClick={() => setStep(stepVal + 1)} />
          );
        case "editField":
          return (
            <Box className="premium-change-warning">
              <Typography
                fontWeight={600}
                sx={{
                  textTransform: "uppercase",
                  marginBottom: "1.5rem !important",
                }}
              >
                Edit {dataFlow[flow]?.title}
              </Typography>
              {renderEditComponent(
                flow,
                proposerValues,
                dobErrVal,
                proposerNameErr,
                panErr
              )}
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: "1.5rem" }}
                className="continue-btn"
                disabled={
                  dobErrVal === "" && proposerNameErr === "" && panErr === ""
                    ? false
                    : true
                }
                onClick={() => {
                  if (
                    dobErrVal === "" &&
                    proposerNameErr === "" &&
                    panErr === ""
                  )
                    handleBtnClick(
                      flow,
                      stepVal,
                      proposerValues,
                      dobErrVal,
                      proposerNameErr,
                      panErr
                    );
                }}
              >
                Continue
              </Button>
            </Box>
          );
        case "identityProof":
          return (
            <IdentityProof
              handleClick={() => setStep(stepVal + 1)}
              value={selectedProofData}
              handleRadioBtnClick={handleProofSelection}
            />
          );
        case "uploadDocs":
          return (
            <Box>
              <HiUploadIdentityDocument
                showUploadIdentityDoc={open}
                handleComponent={() => {
                  setOpen(!open);
                }}
                proposerValues={proposerValues}
                stepVal={stepVal}
                handleClick={handleSubmit}
                flow={flow}
                title={flow === "pan" ? "UPLOAD YOUR PAN" : ""}
                uploadMessage={flow === "pan" ? "Upload your Pan" : ""}
                isBtnLoading={isBtnLoading}
              />
            </Box>
          );
        case "success":
          switch (flow) {
            case "PolicyOwnerName":
              return (
                <CommonSuccessPopupBody
                  title="Proposer name change request is submitted successfully!"
                  des="Your proposer name change request will be processed soon."
                  btnLabel="Go back to Edit Policy"
                  withInfo={true}
                  btnOnClick={() => {
                    fetchLatestData(policyNumber);
                    setOpen(!open);
                  }}
                />
              );
            case "DateOfBirth":
              return (
                <CommonSuccessPopupBody
                  title="DOB change request is submitted successfully!"
                  des="Your DOB change request will be processed soon."
                  btnLabel="Go back to Edit Policy"
                  withInfo={true}
                  btnOnClick={() => {
                    fetchLatestData(policyNumber);
                    setOpen(!open);
                  }}
                />
              );
            case "Gender1":
              return (
                <CommonSuccessPopupBody
                  title="Gender change request is submitted successfully!"
                  des="Your gender change request will be processed soon."
                  btnLabel="Go back to Edit Policy"
                  withInfo={true}
                  btnOnClick={() => {
                    fetchLatestData(policyNumber);
                    setOpen(!open);
                  }}
                />
              );
            case "pan":
              return (
                <CommonSuccessPopupBody
                  title="PAN update request is submitted successfully!"
                  des="Your pan update request will be processed soon."
                  btnLabel="Go back to Edit Policy"
                  withInfo={true}
                  btnOnClick={() => {
                    fetchLatestData(policyNumber);
                    setOpen(!open);
                  }}
                />
              );
          }
      }
    },
    [proposerData, proposerNameErr, panErr, isBtnLoading, policyNumber]
  );

  return (
    <Box className="edit-proposer-details">
      {!isFetchApiLoading ? (
        <>
          {hiEditPolicyProposalFrom.map((data: any) => {
            return (
              <CustomEditField
                key={data.key}
                keyVal={data.key}
                labelValue={data.title}
                value={proposerData[data.key]}
                handleClick={handleClick}
                srcIcon={data.icon}
                isEdit={false}
                type={data.type}
              />
            );
          })}
          {open && (
            <CustomPopup
              open={open}
              handleOpen={() => {
                setOpen(!open);
                setStep(0);
              }}
              className={`hi-common-policy-popup-box edit-policy-popup-box`}
              btnLabel={withClose ? (step === 0 ? "Go Back" : "Close") : ""}
              btnStartIcon={
                withClose ? (
                  step === 0 ? (
                    <ChevronLeftIcon
                      fontSize="small"
                      sx={{ color: Colors.red }}
                    />
                  ) : (
                    <CloseIcon fontSize="small" sx={{ color: Colors.red }} />
                  )
                ) : null
              }
            >
              {handleWorkflow(
                activeFlow,
                open,
                step,
                updatedData,
                selectedProofData,
                dobErr
              )}
            </CustomPopup>
          )}
        </>
      ) : (
        <>
          {[1, 2, 3, 4, 5].map((data) => {
            return (
              <Box className="edit-proposer-loader">
                <CustomShimmer height="1.5rem" width="100%" />
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default ProposerDetails;
