import { createSlice } from "@reduxjs/toolkit";
import { T_GeneralAppElementsSlice } from "./GeneralAppElements_Types";

const initialState: T_GeneralAppElementsSlice = {
  masterData: {
    masterDetail: undefined,
    productCategory: [],
    supportDataList: [],
  },
  selectedTab: "",
  moduleList: [],
  parentModuleList: [],
  selectedModule: "",
  selectedCategory: "",
  categoryList: [],
  faqList: [],
};

export const GeneralAppElementsSlice = createSlice({
  name: "GeneralAppElementsSlice",
  initialState,
  reducers: {
    setMasterData: (state, action) => {
      return {
        ...state,
        masterData: action.payload,
      };
    },
    setSelectedTab: (state, action) => {
      return {
        ...state,
        selectedTab: action.payload,
      };
    },
    setParentModuleList: (state, action) => {
      return {
        ...state,
        parentModuleList: action.payload,
      };
    },
    setModuleList: (state, action) => {
      return {
        ...state,
        moduleList: action.payload,
      };
    },
    setSelectedModule: (state, action) => {
      return {
        ...state,
        selectedModule: action.payload,
      };
    },
    setCategoryList: (state, action) => {
      return {
        ...state,
        categoryList: action.payload,
      };
    },
    setSelectedCategory: (state, action) => {
      return {
        ...state,
        selectedCategory: action.payload,
      };
    },
    setFaqList: (state, action) => {
      return {
        ...state,
        faqList: action.payload,
      };
    },
  },
});

export const GeneralAppElementsReducer = GeneralAppElementsSlice.reducer;
export const GeneralAppElementsSliceKey = GeneralAppElementsSlice.reducerPath;
export const {
  setMasterData,
  setSelectedTab,
  setParentModuleList,
  setModuleList,
  setSelectedModule,
  setCategoryList,
  setSelectedCategory,
  setFaqList,
} = GeneralAppElementsSlice.actions;
