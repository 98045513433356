import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import panImage from "../../../assets/images/pan-image.png";
import { Colors } from "../../../styles/constant";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "./UserDetailEntry.scss";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import errorOutline from "../../../assets/images/error-outline-2x.png";
import { useNavigate } from "react-router-dom";
import {
  T_CustomerLobDetailObj,
  T_LobDetailsForUserRegPay,
} from "../../Feature_Login/Login_Types";
import { useLazyTeraDataUserRegistrationQuery } from "../OnBoarding_Api";
import { R_HomeRoutes } from "../../../pages/Home/Home_Routes";
import {
  ABCD_PLAYSTORE_URL,
  HiLobCode,
  LiLobCode,
  MfLobCode,
  panRegex,
  phoneRegex,
  privacyPolicyTitle,
  termsAndConditionsTitle,
} from "../../../utils/constants";
import {
  defaultEmptyFun,
  getRouteFromFuncCode,
  isValidateEmail,
} from "../../../utils/helper";
import { E_ChannelOptions } from "../../../globalTypes/GlobalTypes";
import _, { isEmpty } from "lodash";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enUS } from "@mui/x-date-pickers/locales";
import {
  clearCustomerAndLobDetailsArr,
  saveCsUserId,
} from "../../../redux-store/reducers/CsAppSlice";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import { useLazyCheckIfUserConsentRequiredQuery } from "../../Feature_Login/Login_Api";
import { useLazyStoreUserConsentQuery } from "../../Feature_GeneralAppElements/GeneralAppElements_Api";
import PolicyAndConditionsComponent from "../../../components/CustomPolicyAndConditionsComponent/PolicyAndConditionsComponent";

dayjs.extend(customParseFormat);
const Emailregex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const usLocale =
  enUS.components.MuiLocalizationProvider.defaultProps.localeText;
const EnterUserDetail: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const customerAndLobDetails = useAppSelector(
    (state) => state.CsAppSlice.userDetails?.customerAndLobDetails
  );
  const ssoSlice = useAppSelector((state) => state.SsoSlice.ssoData);
  const channel = useAppSelector((state) => state.CsAppSlice.channel);
  const secondaryChannel_Slice = useAppSelector(
    (state) => state.SecondaryChannelSlice
  );
  const mobilenumber = useAppSelector((state) =>
    E_ChannelOptions.SUPER_APP === channel
      ? state.SsoSlice?.ssoData?.mobileNo
      : state.LoginSlice?.mobilenumber
  );
  const [checked, setChecked] = useState<boolean>(false);
  const [askForConsent, setAskForConsent] = useState(false);
  const [fetchUserConsent] = useLazyCheckIfUserConsentRequiredQuery();
  const [storeUserConsentApi] = useLazyStoreUserConsentQuery();
  const navigate = useNavigate();
  /**
   * Prefilling PAN and DOB details from the customer lob details API response
   * if CII number received in any of the data during onboarding
   */
  const [pan, setPan] = useState<string>(ssoSlice?.panNumber || "");
  const [dob, setDob] = useState<Dayjs | null>(
    ssoSlice?.customerDob ? dayjs(ssoSlice?.customerDob, "YYYY-MM-DD") : null
  );
  const [email, setEmail] = useState<string>("");
  const [authenticationObj, updateAuthenticationObj] = useState<{
    status: undefined | boolean;
    showRetry: boolean;
    attemptLeft: number;
  }>({ status: undefined, showRetry: false, attemptLeft: 3 });
  const appDispatch = useAppDispatch();
  const [helperTextPan, setHelperTextPan] = useState<string>("");
  const [helperTextDob, setHelperTextDob] = useState<string | null>("");
  const [helperTextEmail, setHelperTextEmail] = useState<string>("");
  const [isCustomPopupOpen, setIsCustomPopupOpen] = useState<boolean>(false);
  const [finalPolicyAndTermsTitle, setFinalPolicyAndTermsTitle] =
    useState<string>("");
  const masterData = useAppSelector(
    (state) => state.GeneralAppElementsSlice?.masterData
  );
  const [teraDataUserRegistration] = useLazyTeraDataUserRegistrationQuery();

  useEffect(() => {
    if (
      [E_ChannelOptions.SUPER_APP, E_ChannelOptions.SECONDARY_CHANNEL].includes(
        channel
      )
    ) {
      const handleMobileNumberChange = (inputValue: string) => {
        if (inputValue.length === 10 && phoneRegex.test(inputValue)) {
          setShowLoading(true);
          fetchUserConsent(inputValue).then((r) => {
            if (r.data?.code === 1 && r.data?.payload?.CS_USER_ID) {
              appDispatch(saveCsUserId(r.data?.payload.CS_USER_ID));
            }
            if (r.data?.code === 1 && !r.data?.payload?.TNC_CONSENT) {
              setAskForConsent(true);
            } else {
              setAskForConsent(false);
            }
            setShowLoading(false);
          });
        }
      };
      handleMobileNumberChange(mobilenumber || "");
    }
  }, []);

  const handleGotoAbcdApp = useCallback(() => {
    window.open(ABCD_PLAYSTORE_URL);
  }, []);

  const handlePanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.toUpperCase();
    setPan(value);
    if (value.length === 10 && !panRegex.test(value)) {
      setHelperTextPan("Please enter a valid PAN.");
    } else {
      setHelperTextPan("");
    }
  };

  const handleDOBchange = (value: Dayjs | null) => {
    if (value?.isAfter(dayjs()) || value === null) {
      setDob(null);
      return;
    }
    if (dayjs(value).isAfter("1900-01-01") && dayjs(value).isValid()) {
      setDob(value);
    }
    if (helperTextDob) {
      setHelperTextDob("");
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (!Emailregex.test(event.target.value)) {
      setHelperTextEmail("Email is not valid. Please enter a valid email");
    } else {
      setHelperTextEmail("");
    }
  };
  const getLobRefId = (selectedLob: string, c: T_CustomerLobDetailObj) => {
    let lobRefId = "";
    if (selectedLob === LiLobCode) {
      lobRefId = c?.LOBID || "";
    } else if (selectedLob === HiLobCode) {
      lobRefId = c.MOBILE;
    } else if (selectedLob === MfLobCode) {
      lobRefId = c.PAN || "";
    }
    return lobRefId;
  };

  const handlePolicyTermsPopup = (title: string) => {
    setIsCustomPopupOpen(true);
    title === termsAndConditionsTitle
      ? setFinalPolicyAndTermsTitle(termsAndConditionsTitle)
      : setFinalPolicyAndTermsTitle(privacyPolicyTitle);
  };

  const saveConsent = async () => {
   return await storeUserConsentApi({
      mobile: mobilenumber || "",
      termsAndConditionConsent: "true",
      privacyConsent: "true",
    }).then((res) => {
      if (res.data?.code === 0 || res.data?.code == 2) {
        appDispatch(
          setError({
            error: res.data,
            message: "API failed",
          })
        );
        return Promise.resolve(false);
      } else if (
        res.data?.code === 1 &&
        res.data?.payload?.csUserId
      ) {
        appDispatch(saveCsUserId(res.data?.payload.csUserId));
      }
      return Promise.resolve(true);
    });
  };

  const handleOnContinue = async (event: React.FormEvent) => {
    // Regular expression for basic email validation
    // const validation: Map<"pan" | "dob" | "email", boolean> = new Map();
    let regexCheckFailed = false;
    if (!panRegex.test(pan)) {
      regexCheckFailed = true;
      setHelperTextPan("Please enter a valid PAN.");
    } else {
      setHelperTextPan("");
    }
    if (!Emailregex.test(email)) {
      regexCheckFailed = true;
      setHelperTextEmail("Email is not valid. Please enter a valid email");
    } else {
      setHelperTextEmail("");
    }
    if (!dob || !dob?.toISOString()) {
      regexCheckFailed = true;
      setHelperTextDob("Please enter valid DOB");
    } else {
      setHelperTextDob("");
    }

    if (regexCheckFailed) {
      return;
    }

    let customerName = "";
    let abcPwd = "",
      pwdType = "";
    const lobIds: T_LobDetailsForUserRegPay[] = [];
    customerAndLobDetails?.forEach((lobDetails) => {
      let isPanOrDobMatched: "pan" | "dob" | "" = "";
      if (!isEmpty(lobDetails.PAN) && panRegex.test(lobDetails.PAN || "")) {
        if (lobDetails.PAN === pan) {
          isPanOrDobMatched = "pan";
        }
      } else if (
        /** Goto DOB validation only if PAN for current LOB is empty or lob provided PAN is not matched with regex */
        dayjs(lobDetails.DOB).format("YYYY-MM-DD") === dob?.format("YYYY-MM-DD")
      ) {
        isPanOrDobMatched = "dob";
      }

      if (isPanOrDobMatched == "pan" || isPanOrDobMatched == "dob") {
        const selectedLob = masterData?.productCategory?.find(
          (item) => item.lob === lobDetails.LOB
        );
        lobIds.push({
          lobRefId: getLobRefId(lobDetails.LOB, lobDetails),
          lobCode: selectedLob?.lobId || "",
        });
        if (customerName?.length <= 0 && lobDetails?.NAME) {
          customerName = lobDetails.NAME;
        }
        if (abcPwd?.length <= 0) {
          abcPwd =
            isPanOrDobMatched === "pan"
              ? pan
              : isPanOrDobMatched === "dob" && dob
              ? dob.format("YYYY/MM/DD")
              : "";
          pwdType =
            isPanOrDobMatched === "pan" ? "Your PAN" : "DOB in YYYY/MM/DD";
        }
      }
    });
    const isSuccess = abcPwd?.length > 0 && lobIds.length > 0;
    if (isSuccess) {
      let consentSaved = false;
      if (askForConsent) {
        setShowLoading(true);
        consentSaved = await saveConsent();
      } else {
        consentSaved = true;
      }
      if (consentSaved) {
        const abcUserId = email;
        doNewUserOnboarding(
          abcUserId,
          abcPwd,
          pwdType,
          lobIds,
          pan,
          "" + dob?.format("YYYY/MM/DD"),
          email,
          customerName
        );
      } else {
        setShowLoading(false);
      }
    } else {
      if (authenticationObj.attemptLeft > 0) {
        updateAuthenticationObj((prev) => {
          return {
            ...prev,
            attemptLeft: prev.attemptLeft - 1,
            showRetry: true,
          };
        });
      } else {
        // validation failed against CustomerLob Details
        updateAuthenticationObj((prev) => {
          return { ...prev, status: false };
        });
      }
    }
  };

  const doNewUserOnboarding = (
    abcUserId: string,
    abcPwd: string,
    pwdType: string,
    userLobsDetails: T_LobDetailsForUserRegPay[],
    panNo: string,
    dobData: string,
    emailId: string,
    customerName: string
  ) => {
    if (!askForConsent) {
      setShowLoading(true);
    }
    const existingCiiNo =
      customerAndLobDetails?.find(
        (c) => c.PRIMARY_SECONDARY_USER === "primary" && !_.isEmpty(c.CII)
      )?.CII || "";
    teraDataUserRegistration(
      {
        abcUserId: abcUserId,
        abcPassword: abcPwd,
        passwordType: pwdType,
        mobileNo: "" + mobilenumber,
        userLobsDetails: userLobsDetails,
        dob: dobData,
        emailId: emailId,
        panNo: panNo,
        customerName: customerName,
        primarySecondaryUser: "primary",
        presentCiiNo: `${existingCiiNo}`,
        ciiNoPresent: existingCiiNo?.length > 0,
      },
      false
    ).then((r) => {
      setShowLoading(false);
      if (r.isSuccess) {
        appDispatch(clearCustomerAndLobDetailsArr());
        if (channel === E_ChannelOptions.SUPER_APP) {
          const routeForFuncCode = getRouteFromFuncCode(
            "" + ssoSlice.functionality,
            false
          );
          navigate(routeForFuncCode || R_HomeRoutes.home, { replace: true });
        } else if (channel === E_ChannelOptions.SECONDARY_CHANNEL) {
          navigate(secondaryChannel_Slice.constructedAppUrl, {
            state: secondaryChannel_Slice.dynamicState,
            replace: true,
          });
        } else {
          navigate(R_HomeRoutes.home, { replace: true });
        }
      } else if (r.error) {
        const e = r.error as Error;
        appDispatch(setError({ error: e, message: e.message }));
      }
    });
  };

  const isBtnDisabled =
    !pan || !dob || !email || askForConsent
      ? !checked
      : false || !!helperTextPan || !!helperTextDob || !!helperTextEmail;

  const renderDatePickerTextField = (params: any) => {
    return (
      <TextField
        fullWidth
        {...params}
        error={helperTextDob}
        helperText={
          <span style={{ display: "flex", alignItems: "center" }}>
            <InputAdornment position="start">
              {helperTextDob && <img src={AlertRhombus} />}
            </InputAdornment>
            {helperTextDob}
          </span>
        }
      />
    );
  };

  return (
    <Box className="enter-user-detail-wrapper">
      <CustomPopup
        open={authenticationObj.status === false}
        handleOpen={defaultEmptyFun}
      >
        <Box padding="1.5rem 1rem">
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={"column"}
            textAlign={"center"}
            alignItems={"center"}
          >
            <img
              src={errorOutline}
              style={{ width: "11.25rem", height: "11.25rem" }}
            />
            <Typography
              marginTop={"2rem"}
              variant="h3"
              color={Colors.textblack}
            >
              Unable to take you to holdings
            </Typography>
            <Typography
              marginTop={"2rem"}
              variant="h5"
              color={Colors.descriptionGray}
            >
              Please try again after sometime.
            </Typography>
            <Button
              sx={{ width: "100%", marginTop: "2rem" }}
              type="button"
              variant="contained"
              onClick={handleGotoAbcdApp}
            >
              Go to ABCD homepage
            </Button>
          </Box>
        </Box>
      </CustomPopup>
      <CustomPopup
        open={
          authenticationObj.showRetry === true &&
          authenticationObj.attemptLeft > 0
        }
        handleOpen={defaultEmptyFun}
      >
        <Box padding="1.5rem 1rem">
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={"column"}
            textAlign={"center"}
            alignItems={"center"}
          >
            <img
              src={errorOutline}
              style={{ width: "11.25rem", height: "11.25rem" }}
            />
            <Typography
              marginTop={"2rem"}
              variant="h3"
              color={Colors.textblack}
            >
              No holding found
            </Typography>
            <Typography
              marginTop={"1.5rem"}
              variant="h5"
              color={Colors.descriptionGray}
            >
              We are unable to find any holdings for entered PAN and DOB
              combination
            </Typography>
            <Button
              sx={{ width: "100%", marginTop: "2rem" }}
              type="button"
              variant="contained"
              onClick={() => {
                updateAuthenticationObj((prev) => {
                  return { ...prev, showRetry: false };
                });
              }}
            >
              Retry
            </Button>
            <Button
              sx={{ width: "100%", marginTop: "1rem" }}
              type="button"
              variant="text"
              onClick={handleGotoAbcdApp}
            >
              Go to ABCD App
            </Button>
          </Box>
        </Box>
      </CustomPopup>
      <Typography
        fontSize={24}
        marginTop="1.5rem"
        fontWeight={700}
        lineHeight={"1.8rem"}
      >
        {"Before we continue"}
      </Typography>
      <Typography
        fontSize={14}
        marginTop=".625rem"
        fontWeight={400}
        lineHeight={"1.2rem"}
        color={"rgba(121, 121, 121, 1)"}
      >
        {"We need some details. Be assured, we won’t be asking this again."}
      </Typography>

      <TextField
        fullWidth
        label="PAN"
        value={pan}
        onBlur={(e) => {
          if (!panRegex.test(e.target.value)) {
            setHelperTextPan("Please enter a valid PAN.");
          }
        }}
        onChange={handlePanChange}
        error={helperTextPan.length > 0}
        helperText={
          <span style={{ display: "flex", alignItems: "center" }}>
            <InputAdornment position="start">
              {helperTextPan.length > 0 && <img src={AlertRhombus} />}
            </InputAdornment>
            {helperTextPan}
          </span>
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={{ marginRight: ".625rem" }}>
              <img src={panImage} alt="pan" style={{ width: "1.5rem" }} />
            </InputAdornment>
          ),
        }}
        inputProps={{
          maxLength: 10,
          inputMode: "text",
          pattern: panRegex,
          style: {
            textTransform: "uppercase",
          },
        }}
        sx={{ marginTop: ".75rem", marginBottom: ".75rem" }}
      />

      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        localeText={{
          ...usLocale,
          fieldMonthPlaceholder: (params) =>
            params.contentType === "letter" ? "MMM" : "MM",
        }}
      >
        <DesktopDatePicker
          label="DOB"
          format="DD MMM YYYY"
          className="date-picker"
          value={dob ? dayjs(dob, "DD-MM-YYYY") : null}
          onError={(error) =>
            error
              ? setHelperTextDob("Please enter valid DOB")
              : setHelperTextDob(null)
          }
          onChange={handleDOBchange}
          disableFuture
          slotProps={{
            textField: {
              autoComplete: "new-password",
              helperText:
                (helperTextDob?.length || 0) > 0 ? (
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <InputAdornment position="start">
                      {helperTextDob && <img src={AlertRhombus} />}
                    </InputAdornment>
                    {helperTextDob}
                  </span>
                ) : null,
            },
          }}
        />
      </LocalizationProvider>
      <TextField
        fullWidth
        label="Email ID"
        value={email}
        onBlur={(e) => {
          if (!isValidateEmail(e.target.value)) {
            setHelperTextEmail(
              "Email is not valid. Please enter a valid email"
            );
          }
        }}
        onChange={handleEmailChange}
        error={helperTextEmail.length > 0}
        helperText={
          <span style={{ display: "flex", alignItems: "center" }}>
            <InputAdornment position="start">
              {helperTextEmail.length > 0 && <img src={AlertRhombus} />}
            </InputAdornment>
            {helperTextEmail}
          </span>
        }
        sx={{ marginTop: ".75rem", marginBottom: ".75rem" }}
      />
      {askForConsent && (
        <Box marginTop={"1rem"} display="flex" alignItems="center">
          <Checkbox
            checked={checked}
            inputProps={{ "aria-label": "custom checkbox" }}
            onChange={(event: any) => setChecked(event.target.checked)}
            sx={{
              padding: 0,
              color: Colors.red,
              "&.Mui-checked": {
                color: Colors.red,
              },
            }}
          />

          <Typography fontSize={12} fontWeight={500} marginLeft="1rem">
            I agree with
            <span
              style={{ color: Colors.red, cursor: "pointer" }}
              onClick={() => handlePolicyTermsPopup(termsAndConditionsTitle)}
            >
              {" "}
              Terms & Conditions{" "}
            </span>
            and
            <span
              style={{ color: Colors.red, cursor: "pointer" }}
              onClick={() => handlePolicyTermsPopup(privacyPolicyTitle)}
            >
              {" "}
              Privacy Policy{" "}
            </span>
          </Typography>
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="center"
        flexGrow={1}
        marginTop="1.25rem"
      >
        {showLoading ? (
          <>
            <CustomThreeDotsLoader />
          </>
        ) : (
          <Button
            fullWidth
            variant="contained"
            disabled={isBtnDisabled}
            sx={{
              backgroundColor: Colors.red,
              borderRadius: "1.25rem",
              padding: ".875rem 0",
              "&:hover": {
                backgroundColor: Colors.red,
              },
            }}
            onClick={handleOnContinue}
          >
            {"Continue"}
          </Button>
        )}
      </Box>
      {isCustomPopupOpen && (
        <PolicyAndConditionsComponent
          open={isCustomPopupOpen}
          handleOpen={setIsCustomPopupOpen}
          title={finalPolicyAndTermsTitle}
        />
      )}
    </Box>
  );
};

export default EnterUserDetail;
