import { useEffect, useRef } from "react";
import { useAppSelector } from "../redux-store/CustomReduxHooks";



const usePromotionalCards = () => {
  const promotionalCardsRef = useRef<any[]>([]);
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const platform = useAppSelector((state) => state.CsAppSlice.platform);

  useEffect(() => {
    const activeLOBDetails = userDetails?.customerAndLobDetails?.filter(item => item.STATUS === "ACTIVE") || [];
    const lobCodes = Array.from(new Set(activeLOBDetails.map(item => item.LOB_CODE).filter(Boolean)));
    const channels = Array.from(new Set(activeLOBDetails.map(item => item.CHANNEL).filter(Boolean)));

    const promoCards = cmsData.filter(
      (i) => i.attributes.cmsType === "promotional-card"
    );

    let filteredCards: any[] = [];

    filteredCards = promoCards.filter(i => {
      const lobIds = i.attributes?.lobId?.split(',').map(id => id.trim());
      const cardChannels = i.attributes?.channel?.split(',').map(channel => channel.trim());

      const isLobIdValid = lobIds?.some(lobId => lobCodes.includes(lobId));
      const isChannelValid = cardChannels?.some(channel => channels.includes(channel));

      const isPageReferenceValid = i.attributes.pageReference === window.location.pathname;
      const isPlatformValid = i.attributes.platform === platform;

      return isPageReferenceValid && isLobIdValid && isPlatformValid && isChannelValid;
    });

    promotionalCardsRef.current = filteredCards;
  }, [platform, cmsData, userDetails]);

  return promotionalCardsRef.current;
};

export default usePromotionalCards;
