import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import CustomPopup from "../CustomPopup/CustomPopup";
import { ChevronLeft } from "@mui/icons-material";
import { Colors } from "../../styles/constant";
import poppinsSemiBold from "../../assets/fonts/poppinssemibold.ttf";
import poppinsRegular from "../../assets/fonts/poppinsregular.ttf";
import { T_FetchConfigData_ResponseDetails } from "../../features/Feature_GeneralAppElements/GeneralAppElements_Types";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import {
  termsAndConditionsTitle,
  privacyPolicyTitle,
} from "../../utils/constants";

interface PolicyAndConditionsComponentProps {
  title: string;
  open: boolean;
  handleOpen: Function;
}

const PolicyAndConditionsComponent: FC<PolicyAndConditionsComponentProps> = ({
  title,
  open,
  handleOpen,
}) => {
  const fetchConfigDataDetails = useAppSelector(
    (state) => state.GeneralAppElementsSlice.masterData?.supportDataList || []
  );
  let finalPolicyAndTermsDescription = "";

  const cmsData= useAppSelector(
    (state) => state.CmsSlice.CmsPageVariableData || []
  );

  var titleFromCms : string = '';

  const handlePolicyTermsPopup = (titleData: string) => {

    if(cmsData && cmsData.length>0){
      finalPolicyAndTermsDescription = cmsData.find(i=>i.attributes.cmsType === titleData)?.attributes.text ?? '';
      titleFromCms = cmsData.find(i=>i.attributes.cmsType === titleData)?.attributes.title ?? titleData;
    }

    // fetchConfigDataDetails.forEach(
    //   (configData: T_FetchConfigData_ResponseDetails) => {
    //     let finalOptionType =
    //       title === termsAndConditionsTitle
    //         ? termsAndConditionsTitle
    //         : privacyPolicyTitle;
    //     if (configData.optionType === finalOptionType) {
    //       finalPolicyAndTermsDescription =
    //         configData.content || configData.description;
    //     }
    //   }
    // );
  };
  handlePolicyTermsPopup(title);
  const descriptionByParagraph = finalPolicyAndTermsDescription?.split("\n");
  const renderCustomPopupChildren = () => {
    return (
      <Box alignItems={"center"} padding={"1rem"}>
        <Typography
          textAlign={"center"}
          marginBottom={"1.5rem"}
          variant="h6"
          lineHeight={".75rem"}
          letterSpacing={".0938rem"}
          fontFamily={`${poppinsSemiBold}`}
          color={Colors.darkCharcoal}
        >
          {titleFromCms}
        </Typography>
        {descriptionByParagraph &&
          descriptionByParagraph.map((paragraph, index) => (
            <Typography
              lineHeight={"1.25rem"}
              key={paragraph}
              variant="subtitle2"
              color="#202428"
              textAlign={"left"}
              fontFamily={`${poppinsRegular}`}
            >
              {paragraph}
            </Typography>
          ))}
      </Box>
    );
  };

  return (
    <CustomPopup
      open={open}
      handleOpen={handleOpen}
      btnLabel={"Go back"}
      btnStartIcon={<ChevronLeft sx={{ width: "1rem", height: "1rem" }} />}
      children={renderCustomPopupChildren()}
    />
  );
};

export default PolicyAndConditionsComponent;
