import { FC } from "react";
import { Box, Typography } from "@mui/material";
import ArrowRightIcon from "../../assets/icons/red-arrow-right.svg";
import { Colors } from "../../styles/constant";
import { defaultEmptyFun } from "../../utils/helper";

interface Props {
  title: string;
  titleSize?: number | string;
  onClick?: any;
}

const CustomLink: FC<Props> = ({
  title = "",
  titleSize = ".875rem",
  onClick = defaultEmptyFun,
}) => {
  return (
    <Box
      display="flex"
      alignItems="baseline"
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Typography
        marginRight=".5rem"
        color={Colors.red}
        fontSize={titleSize}
        variant="h5"
      >
        {title}
      </Typography>
      <img
        src={ArrowRightIcon}
        alt="right-arrow"
        style={{ color: Colors.red }}
      />
    </Box>
  );
};

export default CustomLink;
