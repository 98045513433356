import { CSApiService } from "../../redux-store/CSApiService";
import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import {
  T_CaseDetails,
  CustomerRequest,
  T_ReportingServiceResponse,
  T_ReportingService_RequestData,
  T_PaymentRequest,
  T_CaseCreateRequest,
  T_CaseCreateResponse,
  T_OrderStatusRequest,
  T_OrderStatusResponse,
  T_CreateOrderRequest,
  T_CreateOrderResponse,
  T_HlCreateCaseApiRequest,
  T_HlReportingServiceApiRequest,
  T_HlCreateCaseApiPayload,
  T_HlReportingServiceApiPayload,
  T_HlDataServiceApiResponse,
  T_HlDataServiceApiRequest,
  T_HlFetchLanApiRequest,
  T_HlServiceRequestResponse,
  T_HlCreateCaseQueryRequest,
  T_FetchLanDetails_Response,
} from "./Homeloan_Types";
import {
  Api_Dataservice,
  Api_Fetchlan,
  Api_ReportingService,
  Api_Create_Order,
  Api_Order_Status,
  Api_Createcase,
  Api_Case_Create,
  Api_Create_Case_Query,
} from "../../utils/ApiEndpoints";
import dayjs from "dayjs";
import { generatePlTraceId } from "../../utils/helper";

export const HomeloanApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchDataServiceDetails: builder.query<
      T_ApiResponse<T_HlDataServiceApiResponse>,
      T_HlDataServiceApiRequest
    >({
      query: (dataServiceReq) => ({
        url: Api_Dataservice,
        method: "POST",
        body: dataServiceReq,
      }),
    }),
    fetchLanDetails: builder.query<
      T_ApiResponse<T_FetchLanDetails_Response>,
      T_HlFetchLanApiRequest
    >({
      query: (dataServiceReq) => ({
        url: Api_Fetchlan,
        method: "POST",
        body: dataServiceReq,
      }),
    }),

    getCreateCaseDetails: builder.query<
      T_ApiResponse<T_HlCreateCaseApiPayload>,
      T_HlCreateCaseApiRequest
    >({
      query: (dataServiceReq) => ({
        url: Api_Createcase,
        method: "POST",
        body: dataServiceReq,
      }),
    }),
    getReportingServiceDetails: builder.query<
      T_ApiResponse<T_HlReportingServiceApiPayload>,
      T_HlReportingServiceApiRequest
    >({
      query: (dataServiceReq) => ({
        url: Api_ReportingService,
        method: "POST",
        body: dataServiceReq,
      }),
    }),
    createCase: builder.query<T_ApiResponse<T_CaseDetails>, CustomerRequest>({
      query: (caseCreateReq) => ({
        url: Api_Case_Create,
        method: "POST",
        body: caseCreateReq,
      }),
    }),
    reportingService: builder.query<
      T_ApiResponse<T_ReportingServiceResponse>,
      T_ReportingService_RequestData
    >({
      query: (reportingServiceReq) => ({
        url: Api_ReportingService,
        method: "POST",
        body: reportingServiceReq,
      }),
    }),
    submitCaseRequest: builder.query<
      T_ApiResponse<T_CaseCreateResponse>,
      T_CaseCreateRequest
    >({
      query: (reqPayload) => ({
        url: Api_Case_Create,
        method: "POST",
        body: reqPayload,
      }),
    }),
    createPaymentOrder: builder.query<
      T_ApiResponse<T_CreateOrderResponse>,
      T_CreateOrderRequest
    >({
      query: (reqPayload) => ({
        url: Api_Create_Order,
        method: "POST",
        body: reqPayload,
      }),
    }),
    fetchOrderStatus: builder.query<
      T_ApiResponse<T_OrderStatusResponse>,
      T_OrderStatusRequest
    >({
      query: (reqPayload) => ({
        url: Api_Order_Status,
        method: "POST",
        body: reqPayload,
        headers: {
          "pl-timestamp": dayjs().format("YYYYMMDDHHMMss"),
          "pl-traceid": generatePlTraceId(),
          "pl-merc-id": reqPayload.merc_id,
        },
      }),
    }),
    fetchCreateCaseQuery: builder.query<
      T_ApiResponse<T_HlServiceRequestResponse[]>,
      T_HlCreateCaseQueryRequest
    >({
      query: (reqPayload) => ({
        url: Api_Create_Case_Query,
        method: "POST",
        body: reqPayload,
      }),
    }),
  }),
});

export const {
  useLazyCreateCaseQuery,
  useLazyReportingServiceQuery,
  useLazyCreatePaymentOrderQuery,
  useLazySubmitCaseRequestQuery,
  useLazyFetchOrderStatusQuery,
  useLazyGetCreateCaseDetailsQuery,
  useLazyGetReportingServiceDetailsQuery,
  useLazyFetchDataServiceDetailsQuery,
  useLazyFetchLanDetailsQuery,
  useLazyFetchCreateCaseQueryQuery,
} = HomeloanApi;
