import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import CustomSimpleCarouselCard from "./Card/CustomSimpleCarouselCard";
import "./CustomCarousel.scss";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";




export const CustomCarousel = () => {
  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const onboardingCards = cmsData.filter(
    (i) => i.attributes.pageReference === "onboard"
  );

  useEffect(() => {
    let slide = document.getElementById("content-loader") as HTMLElement;
    const arr = Array.prototype.slice.call(slide.children);
    arr.forEach((imgObj, index) => {
      imgObj.style.left = (slide.offsetWidth + 150) * index + "px";
    });

    let currentSlide = 1;
    const slideLength = slide.children.length;
    let prevSlide = 0;

    let sl1 = document.getElementsByClassName("top-loader-items");
    const defVal = Array.prototype.slice.call(sl1[0].children);
    defVal[0].classList.add("progress-active");
    let timer = setInterval(() => {
      if (currentSlide >= slideLength) currentSlide = 0;
      prevSlide = currentSlide;
      arr.forEach((imgObj, index) => {
        const arrVal = Array.prototype.slice.call(sl1[index]?.children) || [];
        if (prevSlide === index) {
          imgObj.style.left = "0px";
          setTimeout(() => {
            arrVal[0].classList.add("progress-active");
          }, 1000);
          arrVal[0].classList.remove("progress-completed");
        } else if (prevSlide > index) {
          arrVal[0].classList.remove("progress-active");
          arrVal[0].classList.add("progress-completed");
          imgObj.style.left =
            -(slide.offsetWidth + 150) * (prevSlide - index) + "px";
        } else {
          imgObj.style.left =
            (slide.offsetWidth + 150) * (index - prevSlide) + "px";
          arrVal[0].classList.remove("progress-active");
          arrVal[0].classList.remove("progress-completed");
        }
      });
      currentSlide++;
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Grid container className="custom-carousel-box">
      <Grid item sm={12} md={12} sx={{ width: "100%" }}>
        <Box className="custom-carousel-box-head">
          <Box id="top-loader">
            {[0, 1, 2, 3].map((data) => {
              return (
                <div className="top-loader-items" key={data}>
                  <span className="top-loader-items-completed"></span>
                </div>
              );
            })}
          </Box>
          <Grid container>
            <Grid
              item
              md={12}
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box id="content-loader">
                {
                  onboardingCards.map(i=>{
                    return <Grid container className="content-loader-items">
                    <CustomSimpleCarouselCard
                      title={i.attributes.title??''}
                      description={i.attributes.text}
                      imgSrc={i.attributes.image.data?.attributes.url ?? ''}
                    />
                  </Grid>
                  })
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
