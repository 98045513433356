import { FC, useCallback, useEffect, useState } from "react";
import "./AppSwitchHeader.scss";
import {
  useLazyGenerateSso_NewQuery,
  useLazyGenerateSso_OldQuery,
} from "../../Feature_SSO/Sso_Api";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import {
  T_GenerateSSONewPay,
  T_GenerateSSOOldPay,
} from "../../Feature_SSO/Sso_Types";
import { ABCD_SUPERAPP_URL, EVENT_HANDLER } from "../../../utils/constants";
import { goToSuperAppInfo } from "../../../utils/helper";
import {
  T_UserProfile,
  T_CustomerLobDetails,
} from "../../../globalTypes/GlobalTypes";
import { T_GenerateTokenRes } from "../../Feature_Login/Login_Types";

const AppSwitchHeader: FC = () => {
  const [selectedToggle, setSelectedToggle] = useState<boolean>(false);
  const userSlice = useAppSelector((state) => state.CsAppSlice.userDetails);
  const tokenDetails = useAppSelector((state) => state.CsAppSlice.tokenDetails);
  const primaryUserDetails = userSlice?.customerAndLobDetails.filter(
    (f) => f.PRIMARY_SECONDARY_USER === "primary"
  );
  const CII = primaryUserDetails?.at(0)?.CII || "";
  const lobClientId = primaryUserDetails?.at(0)?.LOBID || "";
  const mobile = primaryUserDetails?.at(0)?.MOBILE || "";
  const sourceLobId = "02";
  const destinationLobId = "03";

  const [callGenerateSSOApi_New] = useLazyGenerateSso_NewQuery();
  const [callGenerateSSOApi_Old] = useLazyGenerateSso_OldQuery();
  const dispatch = useAppDispatch();

  const triggerAppToggleEvent = () => {
    const toggleAppExtraInfo = goToSuperAppInfo(
      tokenDetails as T_GenerateTokenRes,
      userSlice as T_UserProfile & T_CustomerLobDetails
    );
    dispatch({
      type: EVENT_HANDLER.GO_TO_SUPER_APP_EVENT,
      payload: toggleAppExtraInfo,
    });
  };

  const handleToggleButtonClick = useCallback(
    (data: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedToggle(data.target.checked);
      if (data.target.checked) {
        const SSOPayload: T_GenerateSSONewPay = {
          callbackUrl: "https://adityabirlanh.findmystore.io/locator/stores",
          ciiNo: CII,
          functionality: "D",
          lobClientId: lobClientId,
          mobileNo: mobile,
          sourceLobId: sourceLobId,
          destinationLobId: destinationLobId,
          udp: "007250727",
          udp1: "007250727",
        };
        // callGenerateSSOApi_New(SSOPayload).then((r) => {
        //   if (r.data?.payload?.ReturnCode === "1") {
        //     const tokenNo = r.data.payload.TokenNo;
        //     const tokenRefNo = r.data.payload.TokenRefNo;
        //     window.open(
        //       `${ABCD_SUPERAPP_URL}/sso/new/${tokenNo}/${tokenRefNo}?traceid=ABCD25SmRyGPmABF`
        //     );
        //   }
        // });
        triggerAppToggleEvent();
      } else {
        // const SSOPayload: T_GenerateSSOOldPay = {
        //   callbackUrl: "https://adityabirlanh.findmystore.io/locator/stores",
        //   ciiNo: CII,
        //   functionality: "D",
        //   lobClientId: lobClientId,
        //   mobileNo: mobile,
        //   sourceLobId: sourceLobId,
        //   destinationLobId: destinationLobId,
        //   udp: "007250727",
        // };
        // callGenerateSSOApi_Old(SSOPayload).then((r) => {
        //   if (r.data?.payload.ReturnCode === "1") {
        //     const tokenNo = r.data.payload.TokenNo;
        //     const tokenRefNo = r.data.payload.TokenRefNo;
        //     window.open(
        //       `${ABCD_SUPERAPP_URL}/sso/old/${tokenNo}/${tokenRefNo}?traceid=ABCD25SmRyGPmABF`
        //     );
        //   }
        // });
      }
    },
    [userSlice]
  );

  return (
    <CustomSwitch
      className="app-switch-header"
      checked={selectedToggle}
      onChange={handleToggleButtonClick}
    />
  );
};

export default AppSwitchHeader;
