import { FC, useEffect, useRef, useState } from "react";
import { Avatar, Box, Button, Grid, Stack, Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import threeLinesIcon from "../../assets/icons/three-lines.svg";
import notificationBellIcon from "../../assets/icons/notification-bell.svg";
import "./Home.scss";
import { Colors } from "../../styles/constant";
import CustomSimpleCard from "../../components/CustomSimpleCard/CustomSimpleCard";
import CustomLink from "../../components/CustomLink/CustomLink";
import calculatorIcon from "../../assets/icons/calculator.svg";
import phoneCheckedIcon from "../../assets/icons/phone-checked.svg";
import SearchIcon from "@mui/icons-material/Search";
import AdaptiveScreenSearch from "../../features/Feature_GeneralAppElements/BasicSearch/AdaptiveScreenSearch";
import NotificationsPage from "../../features/Feature_GeneralAppElements/NotificationsPage/NotificationsPage";
import HamburgerMenuDialog from "../../features/Feature_GeneralAppElements/HamburgerMenuDialog/HamburgerMenuDialog";
import AddManuallyPopup from "../AddManuallyPopup/AddManuallyPopup";
import DownloadAppPopup from "../../features/Feature_GeneralAppElements/DownloadAppPopup/DownloadAppPopup";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import { getInitials } from "../../utils/helper";
import DashboardTabs from "./DashboardTabs";
import { DashboardTabKeys } from "../../utils/constants";
import PolicyAndConditionsComponent from "../../components/CustomPolicyAndConditionsComponent/PolicyAndConditionsComponent";
import CustomNetworkImage from "../../components/CustomNetworkImage/CustomNetworkImage";
import ABCDLogo from "../../assets/images/abcd-logo.png";
import FAQCommon from "../../features/Feature_GeneralAppElements/FAQCommon/FAQCommon";
import usePromotionalCards from "../../hooks/usePromotionalCards";
import RateUsPopUp from "../../features/Feature_GeneralAppElements/RateUsPopUp/RateUsPopUp";

const AdaptiveHome: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("");
  const [downloadAppPopupOpen, setDownloadAppPopupOpen] =
    useState<boolean>(false);
  const [addManuallyPopupOpen, setAddManuallyPopupOpen] =
    useState<boolean>(false);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [termsAndConditionsPopUp, setTermsAndConditionsPopUp] =
    useState<boolean>(false);
  const loginSlice = useAppSelector((state) => state.LoginSlice);
  const [openRateUsPopUp, setOpenRateUsPopUp] = useState<boolean>(false);
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const cmsLogo =
    cmsData.find((i) => i.attributes.cmsType === "ABC Logo")?.attributes.image
      .data?.attributes.url ?? "";

  const promotionalCards = usePromotionalCards();
  const handleTermsClick = () => {
    setTermsAndConditionsPopUp(!termsAndConditionsPopUp);
  };
  const promotionCard7 = cmsData.find(
    (i) => i.attributes.cmsType === "calculator"
  );
  const handleClick = (link: string) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };
  const imageStyle: React.CSSProperties = {
    marginLeft: "-0.3125rem",
    width: "7.1875rem",
    marginRight: "0.3125rem",
  };

  const notificationIcon =
    cmsData.find((i) => i.attributes.cmsType === "notification-icon")
      ?.attributes.image.data?.attributes.url ?? notificationBellIcon;
  const downloadApp = cmsData.find(
    (i) => i.attributes.cmsType === "download-app-button"
  )?.attributes;

  useEffect(() => {
    const displayRateUs = loginSlice.displayRateUs;
    if (displayRateUs) {
      setOpenRateUsPopUp(true);
    }
  }, []);
  return (
    <>
      <Box className="adaptive-home">
        <Box className="header-section">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginX="1.5rem"
          >
            <Box display="flex" alignItems="center">
              <CustomNetworkImage
                src={cmsLogo}
                fallBackImage={<img src={ABCDLogo} alt="" style={imageStyle} />}
                alt="aditya-birla"
                style={imageStyle}
              />
            </Box>
            <Box display="flex" alignItems="center" gap="1rem">
              <Button
                variant="contained"
                className="download-app"
                endIcon={
                  // <img src={phoneCheckedIcon} alt="phonec-checked-icon" />
                  <CustomNetworkImage
                    src={
                      downloadApp?.image.data?.attributes.url ??
                      phoneCheckedIcon
                    }
                    alt="phone-checked-icon"
                  />
                }
                onClick={() => setDownloadAppPopupOpen(true)}
              >
                {downloadApp?.title ?? ""}
              </Button>
              <Box className="search-header-icon-wrapper">
                <SearchIcon
                  className="search-header-icon"
                  onClick={() => setIsSearching(true)}
                />
              </Box>
              {/* <NotificationsNoneIcon
                className="header-icon"
                // onClick={() => setNotificationOpen(true)}
              /> */}
              <Box
                className="search-header-icon-wrapper"
                onClick={() => setNotificationOpen(true)}
              >
                <CustomNetworkImage src={notificationIcon} alt="" />
              </Box>
              <Box position="relative">
                <Avatar className="menu-icon" onClick={() => setOpen(true)}>
                  {getInitials(userDetails?.name)}
                </Avatar>
                <img
                  src={threeLinesIcon}
                  className="three-lines-icon"
                  alt="three-lines-icon"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="content-wrapper">
          <Grid container className="main-grid">
            <Grid item sm={4} md={4} sx={{ backgroundColor: Colors.bgGray }}>
              <Stack
                padding={{
                  sm: "3.75rem 0.6875rem 2rem 0.75rem",
                  md: "4rem 2.1875rem 2rem 2.5rem",
                }}
                spacing="1rem"
              >
                <Box className="power-of-compounding">
                  <Typography variant="h3">
                    {" "}
                    {promotionCard7?.attributes.title}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    marginTop=".5rem"
                    width={{ sm: "100%", md: "65%" }}
                  >
                    {promotionCard7?.attributes.text}
                  </Typography>
                  <Button
                    fullWidth
                    onClick={() =>
                      handleClick(promotionCard7?.attributes.url ?? "")
                    }
                    variant="contained"
                    className="calculator-icon"
                    endIcon={<img src={calculatorIcon} alt="calculator-icon" />}
                  >
                    {" "}
                    {promotionCard7?.attributes.urlLable}
                  </Button>
                </Box>
                {promotionalCards.map((item) => {
                  return (
                    <CustomSimpleCard
                      key={item.attributes.title}
                      imageUrl={
                        item.attributes.image.data?.attributes.url ?? ""
                      }
                      title={item.attributes.title ?? ""}
                      description={item.attributes.text}
                      link={
                        <CustomLink
                          onClick={() => handleClick(item.attributes.url ?? "")}
                          title={item.attributes.urlLable ?? ""}
                          titleSize={14}
                        />
                      }
                    ></CustomSimpleCard>
                  );
                })}
                <FAQCommon activeTab={activeTab} />
              </Stack>
            </Grid>
            <Grid item sm={8} md={8}>
              <Box padding="4rem 2.5rem 2rem 2.5rem">
                <Typography variant="h3" textAlign="center">
                  Hi {userDetails?.name},
                </Typography>
                <Typography variant="subtitle1" textAlign="center">
                  here are your existing holdings
                </Typography>
                <DashboardTabs setActiveTab={setActiveTab} />
                <Box
                  display="flex"
                  justifyContent="center"
                  marginTop="1.5rem"
                  alignItems="center"
                >
                  <Typography marginRight=".625rem" variant="h5">
                    Didn't find your product here?
                  </Typography>
                  <CustomLink
                    title="Add manually"
                    onClick={() => setAddManuallyPopupOpen(true)}
                  />
                </Box>
              </Box>
              {activeTab === DashboardTabKeys.Investment && (
                <Stack>
                  <Typography
                    sx={{
                      fontFamily: "Anek Latin",
                      fontSize: "12px",
                      fontWeight: 600,
                      lineHeight: "14.4px",
                      textAlign: "center",
                      color: "#979CAE",
                      padding: ".625rem",
                    }}
                  >
                    Disclaimer : Mutual Fund investments are subject to market
                    risks, read all scheme related documents carefully before
                    investing.
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Anek Latin",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "13.2px",
                      letterSpacing: "0.1px",
                      textAlign: "center",
                      padding: ".625rem",
                    }}
                  >
                    Aditya Birla Capital Digital Limited
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Anek Latin",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "13.2px",
                      letterSpacing: "0.1px",
                      textAlign: "center",
                      color: "#979CAE",
                      padding: ".625rem",
                    }}
                  >
                    {/* AMFI-registered Mutual Fund Distributor ARN-123456
                    <br /> */}
                    By continuing, you agree to{" "}
                    <Typography
                      component="span"
                      sx={{
                        color: "#C7222A",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                      onClick={handleTermsClick}
                    >
                      Terms & Conditions
                    </Typography>
                  </Typography>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      {downloadAppPopupOpen && (
        <DownloadAppPopup
          open={downloadAppPopupOpen}
          handleOpen={setDownloadAppPopupOpen}
        />
      )}
      {addManuallyPopupOpen && (
        <AddManuallyPopup
          open={addManuallyPopupOpen}
          handleOpen={setAddManuallyPopupOpen}
        />
      )}
      {open && (
        <HamburgerMenuDialog
          open={open}
          setOpen={setOpen}
          className="adaptive-screen-menu"
        />
      )}
      {isSearching && (
        <AdaptiveScreenSearch open={isSearching} handleOpen={setIsSearching} />
      )}
      {notificationOpen && (
        <NotificationsPage
          open={notificationOpen}
          handleOpen={setNotificationOpen}
          className="adaptive-notification"
        />
      )}
      {openRateUsPopUp && (
        <RateUsPopUp open={openRateUsPopUp} handleOpen={setOpenRateUsPopUp} />
      )}
      {termsAndConditionsPopUp && (
        <PolicyAndConditionsComponent
          open={termsAndConditionsPopUp}
          handleOpen={setTermsAndConditionsPopUp}
          title={"mf-terms-and-conditions"}
        />
      )}
    </>
  );
};

export default AdaptiveHome;
