import { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Colors } from "../../../styles/constant";

interface HiDetailsPageAccordionsProps {
  expanded: string;
  title: string;
  handleAccordionClick: Function;
  children?: React.ReactNode | null;
  icon?: any;
  isLastAccordion?: boolean;
}

const HiDetailsPageAccordion: FC<HiDetailsPageAccordionsProps> = ({
  expanded,
  title,
  handleAccordionClick,
  children,
  icon,
  isLastAccordion = false,
}) => {
  return (
    <Accordion
      expanded={expanded === title}
      onChange={() => handleAccordionClick(title)}
      key={title}
      className={`${title === "Cover Details" ? "first-accordion" : ""} ${
        isLastAccordion ? "last-accordion" : ""
      }`}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ color: Colors.red, fontSize: "1rem" }} />
        }
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box display="flex" alignItems="center">
          {icon && <img src={icon} alt="icon" />}
          <Typography marginLeft=".5rem" variant="h5">
            {title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className="accordion-content-wrapper">{children}</Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default HiDetailsPageAccordion;
