import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import "./OtpEntry.scss";
import InputAdornment from "@mui/material/InputAdornment";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import { useNavigate } from "react-router-dom";
import { useLazyGenerateOtpQuery, useLazyValidateOtpQuery } from "../Login_Api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { updateLoginData, updateScreenTag } from "../Login_Slice";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import CsLocalStorageUtils from "../../../utils/CsLocalStorageUtils";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import { Colors } from "../../../styles/constant";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import { T_AnimationData } from "../../../globalTypes/GlobalTypes";
import ExclamationMarkGif from "../../../assets/gif/Exclamation_Mark.json";
import { ABCD_PLAYSTORE_URL } from "../../../utils/constants";
import LoadingDots from "../../../assets/gif/Loader_dots.json";
import CsSessionStorageUtils from "../../../utils/CsSessionStorageUtils";
import { LoginScreenValues } from "../Feature_Login";
import { T_ValidateOtpRes } from "../Login_Types";
import { defaultEmptyFun } from "../../../utils/helper";

type ChildComponentProps = {
  onMaximumAttempt: () => void;
  headerText: string;
};

const OtpEntry: React.FC<ChildComponentProps> = ({
  headerText,
  onMaximumAttempt,
}) => {
  const masterDetails = useAppSelector(
    (state) => state.GeneralAppElementsSlice.masterData?.masterDetail
  );
  const wrongOtpCountAllowed = parseInt(
    masterDetails?.wrong_otp_count_allowed?.find(
      (item) => item.masterKey === "limit"
    )?.masterValue || "3"
  );
  const retryCount = parseInt(
    masterDetails?.retry_count?.find(
      (item) => item.masterKey === "otpBypassValue"
    )?.masterValue || "3"
  );

  const [otpVerifyAttemptLeft, setOtpVerifyAttemptLeft] =
    useState<number>(wrongOtpCountAllowed);
  const [reSendOtpAttemptLeft, setReSendOtpAttemptLeft] =
    useState<number>(retryCount);
  const [count, setCount] = useState<number>(60);
  const [validateOtpApi] = useLazyValidateOtpQuery();
  const [generateOtpApi] = useLazyGenerateOtpQuery();
  const isTimerOn = useRef(true);

  const navigate = useNavigate();
  const [otp, setOtp] = useState<string>("");
  const [helperTextOtp, setHelperTextOtp] = useState<string>("");
  const appDispatch = useAppDispatch();
  const mobileNumber = useAppSelector(
    (state) => state.LoginSlice.mobilenumber || ""
  );

  const otpByPassValue =
    masterDetails?.otpBypass?.find(
      (item) => item.masterKey === "otpBypassValue"
    )?.masterValue || "";
  const isOtpBypassEnable =
    masterDetails?.otpBypass?.find((item) => item.masterKey === "otpBypassFlag")
      ?.masterValue || "";

  function getMinutesBetweenDates(startDate: Date, endDate: Date) {
    const diff = endDate.getTime() - startDate.getTime();

    return diff / 60000;
  }

  useEffect(() => {
    if (otpVerifyAttemptLeft > 0) {
      return;
    }
    if (
      CsLocalStorageUtils.get("OTP_BLOCKED_TIME")?.length > 0 &&
      getMinutesBetweenDates(
        new Date(CsLocalStorageUtils.get("OTP_BLOCKED_TIME")),
        new Date()
      ) > 30
    ) {
      setReSendOtpAttemptLeft(0);
      setOtpVerifyAttemptLeft(0);
    } else {
      CsLocalStorageUtils.clear("OTP_BLOCKED_TIME");
    }
  }, [otpVerifyAttemptLeft]);

  useEffect(() => {
    startTimer();
  }, []);

  const startTimer = useCallback(() => {
    isTimerOn.current = true;
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount <= 0) {
          clearInterval(intervalId);
          isTimerOn.current = false;
          return 60;
        }
        return newCount;
      });
    }, 1000);
  }, []);

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setHelperTextOtp("");
    if (/^\d{0,6}$/.test(value)) {
      setOtp(event.target.value);
    }
  };

  const gotoNextStep = () => {
    if (headerText === "OTP Verification") {
      appDispatch(updateScreenTag(LoginScreenValues.setupMPN));
    } else {
      /** will be going throught AuthGuard, if new user
       * then would be redirected to onboarding screen
       */
      navigate("/", { replace: true });
    }
  };

  const handleGotoAbcdApp = useCallback(() => {
    window.open(ABCD_PLAYSTORE_URL);
  }, []);

  const handleByPassOtp = () => {
    if (otp === otpByPassValue) {
      onOtpMatched("");
    } else {
      onOtpMatchFailed();
    }
  };

  const onOtpMatched = (res: any) => {
    CsSessionStorageUtils.set("MOBILE", mobileNumber);
    CsSessionStorageUtils.set("LOGGED_IN_TIME", new Date().toISOString());
    appDispatch(
      updateLoginData({
        key: "isOtpVerificationDone",
        value: true,
      })
    );
    appDispatch(updateLoginData({ key: "isUserLoggedIn", value: true }));
    if (res) {
      appDispatch(
        updateLoginData({ key: "displayRateUs", value: res.displayRateUs })
      );
    }
    gotoNextStep();
  };

  const onOtpMatchFailed = () => {
    appDispatch(
      updateLoginData({
        key: "isOtpVerificationDone",
        value: false,
      })
    );
    if (otpVerifyAttemptLeft > 0) {
      setHelperTextOtp(`Incorrect OTP entered. Please retry`);
      setOtp("");
      setOtpVerifyAttemptLeft((prevCount) => prevCount - 1);
    } else {
      onMaximumAttempt();
    }
  };

  const handleActualOtpSubmit = () => {
    appDispatch(updateLoadingState(true));
    validateOtpApi({
      mobileNo: mobileNumber,
      otp: otp,
    })
      .then((res) => {
        if (res.data?.code === 1) {
          onOtpMatched(res.data?.payload);
        } else {
          appDispatch(updateLoadingState(false));
          onOtpMatchFailed();
        }
      })
      .catch((err: Error) => {
        appDispatch(updateLoadingState(false));
        appDispatch(
          setError({
            error: err,
            message: err.message,
          })
        );
      });
  };

  const handleOtpSubmitButton = () => {
    if (isOtpBypassEnable === "false") {
      handleByPassOtp();
    } else {
      handleActualOtpSubmit();
    }
  };

  const sendOtp = () => {
    appDispatch(updateLoadingState(true));
    generateOtpApi({ mobileNo: mobileNumber, otp: "" })
      .then((r) => {
        appDispatch(updateLoadingState(false));
        if (r.data?.code === 1) {
          setReSendOtpAttemptLeft((prev) => prev - 1);
          startTimer();
        } else {
          appDispatch(
            setError({
              error: r.data,
              message: r.data?.message,
            })
          );
        }
      })
      .catch((err: Error) => {
        appDispatch(setError({ error: err, message: err.message }));
      });
  };

  return (
    <Box
      className="enter-otp-wrapper"
      minWidth={"12.5rem"}
      width={"100%"}
      maxWidth={"23.9375rem"}
    >
      <CustomPopup
        open={otpVerifyAttemptLeft <= 0}
        handleOpen={defaultEmptyFun}
      >
        <Box padding="1.5rem 1rem">
          <Box
            display="flex"
            justifyContent="center"
            flexDirection={"column"}
            textAlign={"center"}
            alignItems={"center"}
          >
            <CommonGifAnimation
              animationData={ExclamationMarkGif as T_AnimationData}
            />
            <Typography
              marginTop={"2rem"}
              variant="h3"
              color={Colors.textblack}
            >
              You have reached the maximum attempt of tries
            </Typography>
            <Button
              sx={{ width: "100%", marginTop: "2rem" }}
              type="button"
              variant="contained"
              onClick={handleGotoAbcdApp}
            >
              Go to ABCD homepage
            </Button>
          </Box>
        </Box>
      </CustomPopup>
      <Typography
        fontWeight={700}
        fontSize={24}
        marginTop="1.5rem"
        marginBottom=".75rem"
        lineHeight={"1.8rem"}
        sx={{
          textDecoration: "none !important",
          '& a[href^="tel"], & a[href^="mailto"]': {
            pointerEvents: "none",
            cursor: "default",
            textDecoration: "none",
            color: "inherit",
          },
        }}
      >
        {headerText}
      </Typography>
      {headerText === "OTP Verification" && (
        <Typography
          fontWeight={400}
          fontSize={16}
          lineHeight="1.2rem"
          color="#797979"
        >
          Enter the 6 digit code send to
          <span style={{ color: "red" }}> {mobileNumber} </span>
        </Typography>
      )}

      <TextField
        fullWidth
        label="Enter OTP"
        variant="outlined"
        value={otp}
        onChange={handleOtpChange}
        error={helperTextOtp.length > 0}
        helperText={
          <span style={{ display: "flex", alignItems: "center" }}>
            <InputAdornment position="start">
              {helperTextOtp.length > 0 && <img src={AlertRhombus} />}
            </InputAdornment>
            {helperTextOtp}
          </span>
        }
        sx={{ marginTop: ".9rem" }}
        inputProps={{
          maxLength: 6,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />

      <Box marginTop={".9375rem"}>
        {isTimerOn.current ? (
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: ".875rem",
              lineHeight: "1.05rem",
              color: "#9C9C9C",
              height: ".875rem",
            }}
          >
            {`Resend OTP in 00:${count.toString().padStart(2, "0")}s`}
          </Typography>
        ) : !isTimerOn.current && reSendOtpAttemptLeft > 0 ? (
          <Button
            id="resendOtp"
            variant="text"
            color="primary"
            onClick={() => {
              if (otpVerifyAttemptLeft > 0) {
                sendOtp();
              }
            }}
          >
            {"Resend OTP"}
          </Button>
        ) : null}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginTop="1.25rem"
        marginBottom={{ xs: "1.875rem", sm: 0 }}
      >
        {/* <CommonGifAnimation animationData={LoadingDots} /> */}
        <Button
          variant="contained"
          onClick={handleOtpSubmitButton}
          disabled={otpVerifyAttemptLeft <= 0 || otp.length < 6}
          sx={{
            // backgroundColor: otp.length == 5 ? "#C7222A" : " #AFB3C0",
            width: "100%",
            height: "3rem",
            borderRadius: "1.25rem",
            // "&:hover": {
            //   backgroundColor: "rgba(175, 179, 192, 1)",
            // },
          }}
        >
          {"Submit"}
        </Button>
      </Box>
    </Box>
  );
};

export default OtpEntry;
