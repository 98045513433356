import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import { Colors } from "../../../../styles/constant";
import ArrowUpIcon from "../../../../assets/icons/ArrowUp.svg";
import dayjs from "dayjs";

type FundValueType = {
  FND_ID: string;
  FUND_NAME: string;
  FUND_VALUE: string;
  NAV: string;
  POL_CSTAT_CD: string;
  POL_ID: string;
  TOT_UNITS: string;
};

interface fundValueProps {
  data: FundValueType[];
  selectedData: number;
  handleFundValueChange: Function;
  index: string;
  withAlert?: boolean;
  validateInputs: Function;
  type: string;
  selectedAlert: string;
}

const CustomSelectFundValue: FC<fundValueProps> = ({
  data = [],
  handleFundValueChange,
  withAlert = false,
  validateInputs,
  type,
  selectedAlert,
}) => {
  const [validateItems, setValidateItems] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    setValidateItems(data.map(() => ""));
  }, [data, selectedAlert]);

  const validateValues = useCallback(
    (items: string[], value: any) => {
      let emptyVal = [...items].filter((d) => d === "");
      let val = [...items].filter((d) => d === "false");
      if (emptyVal.length !== items.length && val.length === 0) {
        validateInputs(type, true, value.values);
      } else validateInputs(type, false, value.values);
    },
    [type]
  );

  const handleValidation = useCallback(
    (index: number, value: any) => {
      validateItems[index] = value.status;
      setValidateItems(validateItems);
      validateValues(validateItems, value);
    },
    [validateItems]
  );

  const handleCheckBox = (val: number) => {
    setSelectedIndex((prevState) => {
      if (prevState === val) return null;
      return val;
    });
  };

  return (
    <Box className="li-sa-alert-fund-value-box">
      <Typography variant="h5">Select Fund</Typography>
      {data.length > 0 &&
        data.map((content: FundValueType, index: number) => {
          return (
            <CustomFundValueItem
              data={content}
              validateInputs={handleValidation}
              withAlert={withAlert}
              index={index}
              handleChange={handleCheckBox}
              isChecked={selectedIndex === index}
            />
          );
        })}
    </Box>
  );
};

export default CustomSelectFundValue;

interface FundValueItemProps {
  data: FundValueType;
  withAlert?: boolean;
  validateInputs: Function;
  index: number;
  handleChange: Function;
  isChecked: boolean;
}

const CustomFundValueItem: FC<FundValueItemProps> = ({
  data,
  validateInputs,
  withAlert = false,
  index,
  handleChange,
  isChecked,
}) => {
  const [radioValue, setRadioValue] = useState<string>("Up");
  // const [isChecked, setIsChecked] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [err, setErr] = useState<string>("");

  const handleCheckBoxChange = useCallback(() => {
    handleChange(index);
  }, []);

  const handleRadioChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRadioValue((event.target as HTMLInputElement).value);
    },
    []
  );

  const handlePriceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue((e.target as HTMLInputElement).value);
    },
    []
  );

  let validate = "";

  useEffect(() => {
    validate = validateValue();
    validateInputs(index, {
      status: validate,
      values: {
        FundChange:
          radioValue === "Up"
            ? "NAV value goes above"
            : "NAV value falls below",
        ChangeAmount: inputValue,
        NAVfundType: data.FUND_NAME,
      },
    });
  }, [isChecked, radioValue, inputValue, data]);

  const validateValue = useCallback(() => {
    if (withAlert) {
      if (isChecked) {
        if (inputValue !== "") {
          if (radioValue === "Up") {
            if (parseFloat(inputValue) > parseFloat(data.NAV)) {
              setErr("");
              return "true";
            } else {
              setErr("Please enter a value greater than current NAV");
              return "false";
            }
          } else if (radioValue === "Down") {
            if (parseFloat(inputValue) < parseFloat(data.NAV)) {
              setErr("");
              return "true";
            } else {
              setErr("Please enter a value Lower than current NAV");
              return "false";
            }
          }
        } else {
          setErr("Please fill the details");
          return "false";
        }
      } else {
        return "";
      }
    }
    return "";
  }, [isChecked, radioValue, inputValue]);

  return (
    <Box className="li-sa-alert-fund-value-box-content" sx={{ width: "100%" }}>
      <Box className="li-sa-alert-fund-value-box-content-items">
        <Box className="li-sa-alert-fund-value-box-content-items-left">
          <Box
            className="li-sa-alert-fund-value-box-content-items-left-title"
            onClick={handleCheckBoxChange}
          >
            <Checkbox
              checked={isChecked}
              sx={{
                padding: 0,
                color: Colors.black,
                "&.Mui-checked": {
                  color: Colors.red,
                },
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                },
              }}
            />
            <Typography variant="h5" sx={{ marginLeft: "0.625rem" }}>
              {data.FUND_NAME}
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            className="li-sa-alert-fund-value-box-content-items-left-sub-desc"
          >
            Current Fund Value (As on {dayjs().format("DD-MM-YYYY")})
          </Typography>
        </Box>
        <Box className="li-sa-alert-fund-value-box-content-items-right">
          <Typography variant="h5">{data.NAV}</Typography>
          <img src={ArrowUpIcon} className="up-icon" alt={"icon"} />
        </Box>
      </Box>
      {withAlert && isChecked && (
        <Box className="li-sa-alert-fund-value-box-content-alert">
          <Typography variant="h5">Set Alerts</Typography>
          <Box className="li-sa-fv-radio-box">
            <Box className="li-sa-fv-radio-box-items">
              <RadioGroup
                className="li-sa-fv-radio-box-item"
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel value="Up" control={<Radio />} label="Up" />
                <FormControlLabel
                  value="Down"
                  control={<Radio />}
                  label="Down"
                />
              </RadioGroup>
            </Box>
            <TextField
              placeholder="Enter value"
              value={inputValue}
              onChange={handlePriceChange}
            />
          </Box>
          <Typography
            variant="h6"
            sx={{ marginTop: "0.313rem", color: Colors.red }}
          >
            {err}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
