import { FC, useCallback, useRef, useState, useEffect } from "react";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./EditContactDetailsPopups.scss";
import {
  getMaskedMobileNumber,
  isValidMobile,
  getCaseCreateReqPayload,
  isValidateEmail,
} from "../../../utils/helper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import {
  useLazyGenerateOtpQuery,
  useLazyValidateOtpQuery,
} from "../../../features/Feature_Login/Login_Api";
import {
  useLazyUpdateHIDetailsQuery,
  useLazyFetchPolicyInsuredQuery,
} from "../HealthInsurance_Api";
import { updateLoadingState } from "../../../features/Feature_Loading/Loading_Slice";
import { setHiAllPolicyInsuredDetails } from "../../Feature_HealthInsurance/HealthInsurance_Slice";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import {
  T_PolicyInsured,
  T_UpdateHIDetails_ReqPayload,
} from "../HealthInsurance_Types";
import "../ProposerDetails/ProposerDetails.scss";
import IdentityProof from "../HiEditPolicy/IdentityProof/IdentityProof";
import HiUploadIdentityDocument from "../HiUploadIdentityDocument/HiUploadIdentityDocument";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import PremiumChangeWarning from "../HiEditPolicy/PremiumChangeWarning/PremiumChangeWarning";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";

interface Props {
  open: boolean;
  handleOpen: Function;
  popupFlow: string;
  details?: T_PolicyInsured;
}

interface RadioListProofData {
  label: string;
  value: string;
}

interface ProofDocuments {
  attachmentContent: string;
  attachmentType: string;
  attachmentFileName: string;
}

const EditContactDetailsPopups: FC<Props> = ({
  open,
  handleOpen,
  popupFlow,
  details,
}) => {
  const [manualStep, setManualStep] = useState<number>(0);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [otpValue, setOtpValue] = useState<string>("");
  const [otpErrorMessage, setOtpErrorMessage] = useState<string>("");
  const [mobileNum, setMobileNum] = useState<string>("");
  const [policyNum, setPolicyNum] = useState<string>("");
  const [primaryContactNumber, setPrimaryContactNumber] = useState<string>("");
  const [mobileNoError, setMobileNoError] = useState<string>("");
  const [alternateMobileNo, setAlternateMobileNo] = useState<string>("");
  const [currentAlternateContactNumber, setCurrentAlternateContactNumber] =
    useState<string>("");
  const [newEmail, setNewEmail] = useState<string>("");
  const [currentEmail, setCurrentEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [count, setCount] = useState<number>(60);
  const [otpVerifyAttemptLeft, setOtpVerifyAttemptLeft] = useState<number>(4);
  const [reSendOtpAttemptLeft, setReSendOtpAttemptLeft] = useState<number>(3);
  const [addressLine1, setAddressLine1] = useState<string>(
    details?.PolicyDetail.HomeAddress.Home_Address_1 || ""
  );
  const [addressLine2, setAddressLine2] = useState<string>(
    details?.PolicyDetail.HomeAddress.Home_Address_2 || ""
  );
  const [pinCode, setPinCode] = useState<string>(
    details?.PolicyDetail.HomeAddress.Home_Pincode || ""
  );
  const [city, setCity] = useState<string>(
    details?.PolicyDetail.HomeAddress.Home_City || ""
  );
  const [state, setState] = useState<string>(
    details?.PolicyDetail.HomeAddress.Home_State || ""
  );
  const [pinCodeErrorText, setPinCodeErrorText] = useState<string>("");
  const [addressLine1ErrorText, setAddressLine1ErrorText] =
    useState<string>("");
  const [addressLine2ErrorText, setAddressLine2ErrorText] =
    useState<string>("");
  const [cityErrorText, setCityErrorText] = useState<string>("");
  const [stateErrorText, setStateErrorText] = useState<string>("");
  const [selectedProofData, setSelectedProofData] = useState<string>("");
  const isTimerOn = useRef(false);
  const dispatch = useAppDispatch();

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      setMobileNum(inputValue);
    }
  };

  const handleAlternateMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    if (/^\d{0,10}$/.test(inputValue)) {
      setAlternateMobileNo(inputValue);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setNewEmail(inputValue);
  };
  const handleAddressLine1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setAddressLine1(inputValue);
  };
  const handleAddressLine2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setAddressLine2(inputValue);
  };
  const handlePinCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setPinCode(inputValue);
  };
  const handleBlurPinCode = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const isValid = /^\d{6}$/.test(value);
    if (!isValid) {
      return setPinCodeErrorText("Enter a valid pincode");
    } else {
      return setPinCodeErrorText("");
    }
  };
  const handleCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setCity(inputValue);
  };
  const handleState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setState(inputValue);
  };
  const handleBlurAddressLine1 = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const isValid = /^[a-zA-Z0-9 _-]*$/.test(value);
    if (!value) {
      setAddressLine1ErrorText("Please enter valid address");
    } else if (!isValid) {
      setAddressLine1ErrorText("Address must be alpha-numeric!");
    } else {
      setAddressLine1ErrorText("");
    }
  };
  const handleBlurAddressLine2 = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const isValid = /^[a-zA-Z0-9 _-]*$/.test(value);
    if (!value) {
      setAddressLine2ErrorText("Please enter valid address");
    } else if (!isValid) {
      setAddressLine2ErrorText("Address must be alpha-numeric!");
    } else {
      setAddressLine2ErrorText("");
    }
  };
  const handleBlurCity = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const isValid = /^[a-zA-Z\s]+$/.test(value);
    if (!value) {
      setCityErrorText("Please enter a valid city");
    } else if (!isValid) {
      setCityErrorText(
        "Please enter a valid city containing only letters and spaces!"
      );
    } else {
      setCityErrorText("");
    }
  };
  const handleBlurState = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const isValid = /^[a-zA-Z\s]+$/.test(value);
    if (!value) {
      setStateErrorText("Please enter a valid state");
    } else if (!isValid) {
      setStateErrorText(
        "Please enter a valid state containing only letters and spaces!"
      );
    } else {
      setStateErrorText("");
    }
  };
  const handleEmailBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (!isValidateEmail(value)) {
      return setEmailError("Enter a valid email");
    } else {
      return setEmailError("");
    }
  };
  const handleProofSelection = (data: RadioListProofData) => {
    setSelectedProofData(data.value);
  };
  const [generateOtpApi] = useLazyGenerateOtpQuery();
  const [validateOtpApi] = useLazyValidateOtpQuery();
  const [fetchPolicyInsuredApi] = useLazyFetchPolicyInsuredQuery();
  const [updateHiDetailsApi] = useLazyUpdateHIDetailsQuery();
  const hiAllPolicyInsuredDetails = useAppSelector(
    (state) => state.HealthInsuranceSlice.allPolicyInsuredDetails
  );

  const configuration = useAppSelector(
    (state) => state.CsAppSlice.tokenDetails?.configuration
  );
  const masterData = useAppSelector(
    (state) => state.GeneralAppElementsSlice?.masterData
  );

  const IV = configuration?.iv || "";
  const secretKey = configuration?.key || "";

  useEffect(() => {
    if (details?.PolicyDetail) {
      let item = details?.PolicyDetail;
      setPrimaryContactNumber(item.Mobile);
      setMobileNum(item.Mobile);
      setCurrentAlternateContactNumber(item.NomineeContactNumber);
      setAlternateMobileNo(item.NomineeContactNumber);
      setCurrentEmail(item.Email);
      setNewEmail(item.Email);
    }
    if (details) {
      setPolicyNum(details.PolicyDetail.PolicyNumber);
    }
  }, []);

  const startTimer = useCallback(() => {
    isTimerOn.current = true;
    const intervalId = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount - 1;
        if (newCount <= 0) {
          clearInterval(intervalId);
          isTimerOn.current = false;
          return 60;
        }
        return newCount;
      });
    }, 1000);
  }, []);

  const clearAllInputData = () => {
    setManualStep(0);
    setOtpValue("");
    setCurrentEmail("");
    setMobileNoError("");
    setEmailError("");
    setOtpErrorMessage("");
    setPinCodeErrorText("");
    setAddressLine1ErrorText("");
    setAddressLine2ErrorText("");
    setCityErrorText("");
    setStateErrorText("");
    setSelectedProofData("");
  };

  const handleClosePopup = () => {
    clearAllInputData();
    handleOpen(false);
  };

  const handleGenerateOtpClick = async () => {
    if (!isValidMobile(mobileNum)) {
      return setMobileNoError("Enter a valid mobile number");
    } else if (mobileNum !== primaryContactNumber) {
      setMobileNoError("");
      setIsBtnLoading(true);
      let mobileNumber: string = mobileNum;
      await generateOtpApi({ mobileNo: mobileNumber, otp: "" }).then((res) => {
        setIsBtnLoading(false);
        if (res.data?.code === 1) {
          setManualStep(1);
          startTimer();
        }
        if (res.isError || res.data?.code !== 1) {
          dispatch(
            setError({
              error: new Error("Error"),
              message: "generateOtp API failed",
            })
          );
        }
      });
    } else {
      handleOpen(false);
    }
  };

  const handleResendOTP = () => {
    let mobileNo: string = mobileNum;
    generateOtpApi({ mobileNo, otp: "" }).then((res) => {
      if (res.data?.code === 1) {
        setReSendOtpAttemptLeft((prev) => prev - 1);
        setOtpErrorMessage("");
        startTimer();
      }
      if (res.isError || res.data?.code !== 1) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "generateOtp API failed",
          })
        );
      }
    });
  };

  const handleBlurMobileNumber = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    validateInput(event.target.value);
  };

  const validateInput = (mobileInput: string) => {
    // Regular expression for phone number validation
    const phoneRegex = /^[6-9]\d{9}$/;
    if (!phoneRegex.test(mobileInput)) {
      setMobileNoError("Enter a valid mobile number");
    } else {
      setMobileNoError("");
    }
  };

  const handleEmailSubmit = () => {
    if (!isValidateEmail(newEmail)) {
      return setEmailError("Enter a valid email");
    } else {
      setEmailError("");
      setIsBtnLoading(true);
      const reqPayload: T_UpdateHIDetails_ReqPayload = getCaseCreateReqPayload(
        {
          CaseSubSubType: "Change in my Email ID",
          CaseTitle: "EndorsementChange in my Email ID",
          Comments: `Current EmailID:${currentEmail},Updated EmailID:${newEmail}`,
          EmailAddress: newEmail,
          Policy: details?.PolicyDetail.PolicyNumber,
        },
        { Email: newEmail }
      );
      updateHiDetailsApi(reqPayload).then((res) => {
        setIsBtnLoading(false);
        if (res.data?.code === 1) {
          setManualStep(4);
        }
        if (res.data?.code !== 1) {
          dispatch(
            setError({
              error: new Error("Error"),
              message: "case create API failed",
            })
          );
        }
      });
    }
  };

  const handleAlternateNumberChange = () => {
    if (!isValidMobile(mobileNum)) {
      return setMobileNoError("Enter a valid mobile number");
    } else if (currentAlternateContactNumber !== alternateMobileNo) {
      setMobileNoError("");
      setIsBtnLoading(true);
      const reqPayload: T_UpdateHIDetails_ReqPayload = getCaseCreateReqPayload(
        {
          CaseSubSubType: "Change my Alternate number",
          CaseTitle: "EndorsementChange my Alternate number",
          Comments: `Current AlternateContactNumber:${currentAlternateContactNumber},Updated AlternateContactNumber:${alternateMobileNo}`,
          Policy: details?.PolicyDetail.PolicyNumber,
        },
        { AlternateContactNumber: alternateMobileNo }
      );
      updateHiDetailsApi(reqPayload).then(async (res) => {
        setIsBtnLoading(false);
        if (res.data?.code === 1) {
          fetchPolicyInsuredApi(policyNum).then((response) => {
            if (
              response.data?.code === 1 &&
              response?.data?.payload?.Response
            ) {
              const selectedPolicyIndex = hiAllPolicyInsuredDetails.findIndex(
                (item) => item.PolicyDetail.PolicyNumber === policyNum
              );
              if (selectedPolicyIndex >= 0) {
                let updatedPolicyInsuredDetails = [
                  ...hiAllPolicyInsuredDetails,
                ];
                updatedPolicyInsuredDetails[selectedPolicyIndex] =
                  response?.data?.payload?.Response;
                dispatch(
                  setHiAllPolicyInsuredDetails(updatedPolicyInsuredDetails)
                );
              }
            }
          });
          setManualStep(4);
        }
        if (res.data?.code !== 1) {
          dispatch(
            setError({
              error: new Error("Error"),
              message: "case create API failed",
            })
          );
        }
      });
    } else {
      handleOpen(false);
    }
  };

  const handleVerifyOtpClick = () => {
    setIsBtnLoading(true);
    const mobileNo = mobileNum;
    validateOtpApi({ mobileNo, otp: otpValue }).then((res) => {
      if (res.data?.code === 1) {
        const reqPayload: T_UpdateHIDetails_ReqPayload =
          getCaseCreateReqPayload(
            {
              CaseSubSubType: "Change my Primary Registered Number",
              CaseTitle: "EndorsementChange in my  Primary Registered Number",
              Comments: `Current PrimaryContactNumber:${primaryContactNumber},Updated PrimaryContactNumber:${mobileNo}`,
              Policy: details?.PolicyDetail.PolicyNumber,
            },
            { PrimaryContactNumber: mobileNo }
          );
        updateHiDetailsApi(reqPayload).then(async (res) => {
          setIsBtnLoading(false);
          if (res.data?.code === 1) {
            fetchPolicyInsuredApi(policyNum).then((response) => {
              if (
                response.data?.code === 1 &&
                response?.data?.payload?.Response
              ) {
                const selectedPolicyIndex = hiAllPolicyInsuredDetails.findIndex(
                  (item) => item.PolicyDetail.PolicyNumber === policyNum
                );
                if (selectedPolicyIndex >= 0) {
                  let updatedPolicyInsuredDetails = [
                    ...hiAllPolicyInsuredDetails,
                  ];
                  updatedPolicyInsuredDetails[selectedPolicyIndex] =
                    response?.data?.payload?.Response;
                  dispatch(
                    setHiAllPolicyInsuredDetails(updatedPolicyInsuredDetails)
                  );
                }
              }
            });
            setManualStep(4);
          }
          if (res.data?.code !== 1) {
            dispatch(
              setError({
                error: new Error("Error"),
                message: "case create API failed",
              })
            );
          }
        });
      } else {
        setIsBtnLoading(false);
        setOtpErrorMessage("Incorrect OTP, Please try again");
        setOtpValue("");
        if (otpVerifyAttemptLeft > 0) {
          setOtpVerifyAttemptLeft((prevCount) => prevCount - 1);
        }
      }
    });
  };

  const handleOtpOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (/^\d{0,6}$/.test(value)) {
      setOtpValue(value);
    }
  };

  const handleSubmit = (data: ProofDocuments, stepVal: number) => {
    setIsBtnLoading(true);
    const reqPayload: T_UpdateHIDetails_ReqPayload = getCaseCreateReqPayload(
      {
        AssignedTeam: "Endorsement- Financial",
        CaseSubSubType: "Change in Communication Address",
        CaseTitle: "Endorsement Change in Communication Address",
        Comments: `Current Address:${details?.PolicyDetail.HomeAddress.Home_Address_1}, ${details?.PolicyDetail.HomeAddress.Home_Address_2}, ${details?.PolicyDetail.HomeAddress.Home_Pincode}, ${details?.PolicyDetail.HomeAddress.Home_City}, ${details?.PolicyDetail.HomeAddress.Home_State},Updated Address:${addressLine1}, ${addressLine2}, ${pinCode}, ${city}, ${state}`,
        Policy: details?.PolicyDetail.PolicyNumber,
        AttachmentContent: data.attachmentContent || "",
        AttachmentType: data.attachmentType || "",
        AttachmentName: `${data.attachmentFileName || ""}.${
          data.attachmentType.toLowerCase() || ""
        }`,
      },
      {}
    );
    updateHiDetailsApi(reqPayload).then(async (res) => {
      setIsBtnLoading(false);
      if (res.data?.code === 1) {
        fetchPolicyInsuredApi(policyNum).then((response) => {
          if (response.data?.code === 1 && response?.data?.payload?.Response) {
            const selectedPolicyIndex = hiAllPolicyInsuredDetails.findIndex(
              (item) => item.PolicyDetail.PolicyNumber === policyNum
            );
            if (selectedPolicyIndex >= 0) {
              let updatedPolicyInsuredDetails = [...hiAllPolicyInsuredDetails];
              updatedPolicyInsuredDetails[selectedPolicyIndex] =
                response?.data?.payload?.Response;
              dispatch(
                setHiAllPolicyInsuredDetails(updatedPolicyInsuredDetails)
              );
            }
          }
        });
        setManualStep(4);
      }
      if (res.data?.code !== 1) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "case create API failed",
          })
        );
      }
    });
  };

  const getButtonDisabled = () => {
    return (
      !addressLine1 ||
      !addressLine2 ||
      !pinCode ||
      !city ||
      !state ||
      !!addressLine1ErrorText ||
      !!addressLine2ErrorText ||
      !!pinCodeErrorText ||
      !!cityErrorText ||
      !!stateErrorText
    );
  };

  const renderSteps = () => {
    switch (popupFlow) {
      case "Mobile":
        switch (manualStep) {
          case 0:
            return (
              <Box>
                <Typography textAlign="center" variant="h5">
                  EDIT MOBILE NUMBER
                </Typography>
                <Stack rowGap="1.5rem" marginTop="1.5rem">
                  <TextField
                    type="tel"
                    label="Mobile number"
                    fullWidth
                    value={mobileNum}
                    onBlur={handleBlurMobileNumber}
                    onChange={handleMobileNumberChange}
                    error={mobileNoError.length > 0}
                    inputProps={{
                      maxLength: 10,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ margin: "0rem" }}
                        >
                          <Typography variant="h5" color="black">
                            +91
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {mobileNoError.length > 0 && (
                            <img src={AlertRhombus} />
                          )}
                        </InputAdornment>
                        {mobileNoError}
                      </span>
                    }
                  />
                </Stack>
                <Stack rowGap="1.5rem" marginTop="1.5rem">
                  {isBtnLoading ? (
                    <CustomThreeDotsLoader />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={!mobileNum || mobileNum.length < 10}
                      className="continue-btn"
                      onClick={handleGenerateOtpClick}
                    >
                      Continue
                    </Button>
                  )}
                </Stack>
              </Box>
            );
          case 1:
            return (
              <Box marginTop="">
                <Typography textAlign="center" variant="h5">
                  ENTER OTP
                </Typography>
                <Typography
                  marginTop="0.5rem"
                  textAlign="center"
                  variant="subtitle2"
                  marginBottom="1.5rem"
                >
                  We've sent an OTP to {getMaskedMobileNumber(mobileNum)}
                </Typography>
                <TextField
                  sx={{ marginBottom: "1.5rem" }}
                  fullWidth
                  label="Enter OTP"
                  onChange={handleOtpOnChange}
                  value={otpValue}
                  helperText={otpErrorMessage}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {reSendOtpAttemptLeft > 0 ? (
                          <Button variant="text" onClick={handleResendOTP}>
                            Resend OTP
                          </Button>
                        ) : null}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 6,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
                {isBtnLoading ? (
                  <CustomThreeDotsLoader />
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={otpValue.length !== 6}
                    className="continue-btn"
                    onClick={handleVerifyOtpClick}
                  >
                    Continue
                  </Button>
                )}
              </Box>
            );
          case 4:
            return (
              <CommonSuccessPopupBody
                title="Mobile number is changed successfully!"
                des=""
                btnLabel="Go back to Edit Policy"
                btnOnClick={() => {
                  handleOpen(!open);
                  setManualStep(0);
                }}
              />
            );
          default:
            break;
        }
        break;
      case "AlternateMobile":
        switch (manualStep) {
          case 0:
            return (
              <Box>
                <Typography textAlign="center" variant="h5">
                  EDIT ALTERNATE MOBILE NUMBER
                </Typography>
                <Stack rowGap="1.5rem" marginTop="1.5rem">
                  <TextField
                    label="Alternate mobile number"
                    fullWidth
                    value={alternateMobileNo}
                    onChange={handleAlternateMobileNumberChange}
                    onBlur={handleBlurMobileNumber}
                    error={mobileNoError.length > 0}
                    inputProps={{
                      maxLength: 10,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ margin: "0rem" }}
                        >
                          <Typography variant="h5" color="black">
                            +91
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {mobileNoError.length > 0 && (
                            <img src={AlertRhombus} />
                          )}
                        </InputAdornment>
                        {mobileNoError}
                      </span>
                    }
                  />
                </Stack>
                <Stack rowGap="1.5rem" marginTop="1.5rem">
                  {isBtnLoading ? (
                    <CustomThreeDotsLoader />
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      className="continue-btn"
                      disabled={
                        !alternateMobileNo || alternateMobileNo.length < 10
                      }
                      onClick={handleAlternateNumberChange}
                    >
                      Continue
                    </Button>
                  )}
                </Stack>
              </Box>
            );
          case 4:
            return (
              <CommonSuccessPopupBody
                title="Alternate mobile number is changed successfully!"
                des=""
                btnLabel="Go back to Edit Policy"
                btnOnClick={() => {
                  handleOpen(!open);
                  setManualStep(0);
                }}
              />
            );
          default:
            break;
        }
        break;
      case "Email":
        switch (manualStep) {
          case 0:
            return (
              <Box>
                <Typography textAlign="center" variant="h5">
                  CHANGE EMAIL ID
                </Typography>
                {/* <Typography marginTop="1.5rem" variant="h5" fontWeight={400}>
                  Email ID
                </Typography> */}
                <Box marginBottom="1.5rem" marginTop="1rem">
                  <TextField
                    fullWidth
                    label="Email"
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                    value={newEmail}
                    error={emailError.length > 0}
                    helperText={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {emailError.length > 0 && <img src={AlertRhombus} />}
                        </InputAdornment>
                        {emailError}
                      </span>
                    }
                  />
                </Box>
                {isBtnLoading ? (
                  <CustomThreeDotsLoader />
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    disabled={!newEmail}
                    className="continue-btn"
                    onClick={handleEmailSubmit}
                  >
                    Continue
                  </Button>
                )}
              </Box>
            );
          case 4:
            return (
              <CommonSuccessPopupBody
                title="Email id is changed successfully!"
                des=""
                btnLabel="Go back to Edit Policy"
                btnOnClick={() => {
                  handleOpen(!open);
                  setManualStep(0);
                }}
              />
            );
          default:
            break;
        }
        break;
      case "Address":
        switch (manualStep) {
          case 0:
            return (
              <PremiumChangeWarning handleClick={() => setManualStep(1)} />
            );
          case 1:
            return (
              <Box>
                <Typography textAlign="center" variant="h5">
                  EDIT ADDRESS
                </Typography>
                <Stack spacing={"1rem"} marginBottom="1.5rem" marginTop="1rem">
                  <TextField
                    fullWidth
                    label="Address line 1"
                    onChange={handleAddressLine1}
                    onBlur={handleBlurAddressLine1}
                    value={addressLine1}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontWeight: "600",
                        fontSize: ".875rem",
                        lineHeight: "16.8px",
                        color: "#000000",
                      },
                    }}
                    error={addressLine1ErrorText.length > 0}
                    helperText={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {addressLine1ErrorText.length > 0 && (
                            <img src={AlertRhombus} />
                          )}
                        </InputAdornment>
                        {addressLine1ErrorText}
                      </span>
                    }
                  />
                  <TextField
                    fullWidth
                    label="Address line 2"
                    onChange={handleAddressLine2}
                    onBlur={handleBlurAddressLine2}
                    value={addressLine2}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontWeight: "600",
                        fontSize: ".875rem",
                        lineHeight: "16.8px",
                        color: "#000000",
                      },
                    }}
                    error={addressLine2ErrorText.length > 0}
                    helperText={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {addressLine2ErrorText.length > 0 && (
                            <img src={AlertRhombus} />
                          )}
                        </InputAdornment>
                        {addressLine2ErrorText}
                      </span>
                    }
                  />
                  <TextField
                    fullWidth
                    label="Pincode"
                    onChange={handlePinCode}
                    onBlur={handleBlurPinCode}
                    value={pinCode}
                    inputProps={{
                      maxLength: 6,
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontWeight: "600",
                        fontSize: ".875rem",
                        lineHeight: "16.8px",
                        color: "#000000",
                      },
                    }}
                    error={pinCodeErrorText.length > 0}
                    helperText={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {pinCodeErrorText.length > 0 && (
                            <img src={AlertRhombus} />
                          )}
                        </InputAdornment>
                        {pinCodeErrorText}
                      </span>
                    }
                  />
                  <TextField
                    fullWidth
                    label="City"
                    onChange={handleCity}
                    onBlur={handleBlurCity}
                    value={city}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontWeight: "600",
                        fontSize: ".875rem",
                        lineHeight: "16.8px",
                        color: "#000000",
                      },
                    }}
                    inputProps={{ maxLength: 25, inputMode: "text" }}
                    error={cityErrorText.length > 0}
                    helperText={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {cityErrorText.length > 0 && (
                            <img src={AlertRhombus} />
                          )}
                        </InputAdornment>
                        {cityErrorText}
                      </span>
                    }
                  />
                  <TextField
                    fullWidth
                    label="State"
                    onChange={handleState}
                    onBlur={handleBlurState}
                    value={state}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontWeight: "600",
                        fontSize: ".875rem",
                        lineHeight: "16.8px",
                        color: "#000000",
                      },
                    }}
                    inputProps={{ maxLength: 25, inputMode: "text" }}
                    error={stateErrorText.length > 0}
                    helperText={
                      <span style={{ display: "flex", alignItems: "center" }}>
                        <InputAdornment position="start">
                          {stateErrorText.length > 0 && (
                            <img src={AlertRhombus} />
                          )}
                        </InputAdornment>
                        {stateErrorText}
                      </span>
                    }
                  />
                </Stack>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={getButtonDisabled()}
                  className="continue-btn"
                  onClick={() => setManualStep(2)}
                >
                  Continue
                </Button>
              </Box>
            );
          case 2:
            return (
              <IdentityProof
                handleClick={() => setManualStep(3)}
                value={selectedProofData}
                handleRadioBtnClick={handleProofSelection}
              />
            );
          case 3:
            return (
              <HiUploadIdentityDocument
                showUploadIdentityDoc={open}
                handleComponent={() => {
                  handleOpen(!open);
                }}
                stepVal={manualStep}
                handleClick={handleSubmit}
                title="UPLOAD ADDRESS PROOF"
                subTitle="Please upload the selected address proof"
                uploadMessage="Upload your current address proof"
                isBtnLoading={isBtnLoading}
              />
            );
          case 4:
            return (
              <CommonSuccessPopupBody
                title="Address update request is submitted successfully!"
                des="Your address update change request will be processed soon."
                btnLabel="Go back to Edit Policy"
                withInfo={true}
                btnOnClick={() => {
                  handleOpen(!open);
                }}
              />
            );
          default:
            break;
        }
        break;
    }
  };

  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      btnLabel={manualStep !== 4 ? "Close" : ""}
      btnStartIcon={<CloseIcon fontSize="small" />}
      className={`add-manual-popup ${manualStep === 4 ? "centered" : ""}`}
    >
      <Box>{renderSteps()}</Box>
    </CustomPopup>
  );
};

export default EditContactDetailsPopups;
