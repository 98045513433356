import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import "./HomeloanServiceDetails.scss";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import HelpIcon from "../../../assets/icons/help.svg";
import SwitchButton from "../../../components/CustomSwitchButton/CustomSwitchButton";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import HlServiceRequestCard from "./HlServiceRequestCard/HlServiceRequestCard";
import { useLazyFetchCreateCaseQueryQuery } from "../Homeloan_Api";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { T_HlServiceRequestResponse, T_SummaryData } from "../Homeloan_Types";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useDispatch } from "react-redux";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import SearchIcon from "@mui/icons-material/Search";

const HomeloanServiceDetails: FC<any> = () => {
  const [state, setState] = useState<{
    filter: string;
    searchText: string;
    fromDate: Dayjs | null;
    toDate: Dayjs | null;
    minEndDate: Dayjs;
    maxEndDate: Dayjs;
    serviceRequestList: T_HlServiceRequestResponse[];
  }>({
    filter: "Active",
    searchText: "",
    fromDate: dayjs().subtract(14, "days"),
    toDate: dayjs(),
    minEndDate: dayjs().subtract(14, "days"),
    maxEndDate: dayjs(),
    serviceRequestList: [],
  });

  const [fetchCreateCaseQuery] = useLazyFetchCreateCaseQueryQuery();
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const dispatch = useDispatch();
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const customerNumber = allSummaryData[0]?.account_detail.rows[0]?.CUSTOMER_NO;
  useEffect(() => {
    searchServiceRequest(false);
  }, [state.filter, state.fromDate, state.toDate, customerNumber]);

  const searchServiceRequest = async (isSearchById: boolean) => {
    try {
      setIsBtnLoading(true);
      const response = await fetchCreateCaseQuery({
        caseId: !isSearchById ? "" : state.searchText,
        customerId: customerNumber ?? "",
        toDate: isSearchById ? "" : state.toDate?.format("YYYY-MM-DD") || "",
        fromDate: isSearchById
          ? ""
          : state.fromDate?.format("YYYY-MM-DD") || "",
        active: state.filter === "Active" ? "true" : "false",
      });
      setIsBtnLoading(false);
      if (response.isSuccess && response.data.code === 1) {
        setState((prevState) => ({
          ...prevState,
          serviceRequestList: response.data.payload,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          serviceRequestList: [],
        }));
      }
    } catch (e) {
      setIsBtnLoading(false);
      // Handle error
    }
  };

  const onClickFilter = (key: string) => {
    setState((prevState) => ({
      ...prevState,
      filter: key,
    }));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      searchText: event.target.value,
    }));
  };

  useEffect(() => {
    if (state.searchText.length > 6) {
      searchServiceRequest(true);
    }
  }, [state.searchText]);

  const handleDateChange = (date: Dayjs | null, isStartDate: boolean) => {
    if (date && date.isValid()) {
      if (isStartDate) {
        setState((prevState) => ({
          ...prevState,
          fromDate: date,
          toDate: null,
          minEndDate: date,
          maxEndDate: date.add(14, "days"),
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          toDate: date,
        }));
      }
    }
  };

  return (
    <Box className="service-req-details-body">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 10,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <CustomBreadcrumbs data={["Home loan", "Track service requests"]} />
          <CustomHelpComponent />
        </Box>
        <Box sx={{ marginTop: "1.5rem" }}>
          <SwitchButton
            options={["Active", "Resolved"]}
            selected={state.filter}
            onClickFilter={onClickFilter}
          />
        </Box>
        {isBtnLoading ? <CustomThreeDotsLoader /> : <></>}
        <Box
          sx={{
            backgroundColor: "#FFFF",
            borderRadius: "1.5rem",
            padding: "16px",
          }}
        >
          <TextField
            fullWidth
            placeholder="Search for service requests"
            variant="outlined"
            value={state.searchText}
            name="searchText"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ marginRight: 0 }} position="start">
                  <IconButton sx={{ padding: 0 }}>
                    <SearchIcon
                      sx={{
                        width: "1.5rem",
                        height: "1.5rem",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            gap="1rem"
            marginTop="1rem"
            display="flex"
            justifyContent="space-between"
          >
            <DatePicker
              label="From"
              format="DD/MM/YYYY"
              className="date-picker"
              minDate={dayjs("01/01/2000")}
              maxDate={dayjs()}
              value={state.fromDate}
              onChange={(date) => handleDateChange(date, true)}
              sx={{ width: "100%" }}
            />
            <DatePicker
              label="To"
              format="DD/MM/YYYY"
              className="date-picker"
              minDate={state.fromDate || dayjs("01/01/2000")}
              maxDate={state.maxEndDate}
              value={state.toDate}
              onChange={(date) => handleDateChange(date, false)}
              sx={{ width: "100%" }}
            />
          </Box>
        </Box>
        {state.serviceRequestList.length > 0 ? (
          state.serviceRequestList.map((item, index) => {
            return (
              <Box>
                <Box
                  sx={{
                    backgroundColor: "#FFFF",
                    borderRadius: "1.5rem",
                    paddingTop: "1px",
                    paddingLeft: "1px",
                    paddingRight: "1px",
                    marginTop: "0.75rem",
                  }}
                >
                  <HlServiceRequestCard
                    key={index}
                    HlServiceRequestDetails={item}
                  />
                </Box>
              </Box>
            );
          })
        ) : (
          <Box
            sx={{
              marginTop: "64px",
              display: "flex",
              alignContent: "flex-start",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // backgroundColor: "#f1f3f6"
            }}
          >
            <Stack />
            <ReportProblemIcon
              sx={{
                color: "#979CAE",
                fontSize: "20px",
                marginBottom: "16px",
              }}
            />
            <Stack />

            <Typography
              sx={{
                fontFamily: "Anek Latin",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16.8px",
                textAlign: "left",
                color: "#979CAE",
              }}
            >
              No Service Request Available
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default HomeloanServiceDetails;
