import { FC } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { Colors } from "../../../styles/constant";
import {
  currencyConvertor,
  getHiMembersInsured,
  getHiPolicyNameIcon,
  getHiPolicyStatus,
} from "../../../utils/conversionUtils";
import { T_PolicyInsured } from "../HealthInsurance_Types";
import emptyPolicy_FolderIcon from "../../../assets/icons/empty_holding_foldericon.svg";
import "./HiEditPolicyCard.scss";
import BasePlanGenericIcon from "../../../assets/icons/BasePlanGenericIcon-red.svg";
import dayjs from "dayjs";

interface Props {
  policyInsuredDetails: T_PolicyInsured | undefined;
  showNoDocImage?: boolean;
}

interface KeyPairValueProps {
  title: string;
  value?: string;
  isLoading?: boolean;
}
const KeyPairValue: FC<KeyPairValueProps> = ({ title, value }) => {
  return (
    <>
      <Typography
        marginBottom=".3125rem"
        variant="h5"
        fontWeight={400}
        color={Colors.descriptionGray}
      >
        {title}
      </Typography>
      <Typography
        variant={title === "Sum Assured" ? "subtitle1" : "h5"}
        fontWeight={600}
        color={Colors.darkCharcoal}
      >
        {value ? value : "---"}
      </Typography>
    </>
  );
};
const HiDetailsPagePlanCard: FC<Props> = ({
  policyInsuredDetails,
  showNoDocImage = false,
}) => {
  return (
    <Box className="hi-edit-policy-card">
      {showNoDocImage ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img width={"100px"} height={"100px"} src={emptyPolicy_FolderIcon} />
          <Typography
            variant="subtitle2"
            lineHeight={"18.2px"}
            textAlign={"center"}
          >
            Unable to load your data
          </Typography>
          <Button>
            <Typography variant="h6" color={Colors.red}>
              Try Again
            </Typography>
          </Button>
        </div>
      ) : (
        <>
          <Box className="hi-edit-policy-card-header">
            <Box display="flex" alignItems="center" marginBottom={"0.5rem"}>
              {getHiPolicyNameIcon(policyInsuredDetails?.PolicyDetail.Plan) && (
                <img
                  src={
                    getHiPolicyNameIcon(
                      policyInsuredDetails?.PolicyDetail.Plan,
                      "detailsPage"
                    ) || BasePlanGenericIcon
                  }
                  style={{
                    width: ".875rem",
                    marginRight: ".5rem",
                  }}
                ></img>
              )}
              <Typography color={Colors.red} variant="h5">
                {`${policyInsuredDetails?.PolicyDetail.Product} ${policyInsuredDetails?.PolicyDetail.Plan}`}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex">
                <Typography
                  variant="h6"
                  fontWeight={400}
                  lineHeight={".825rem"}
                  letterSpacing={".0063rem"}
                  color={Colors.quartz}
                  marginRight=".3125rem"
                >
                  Policy no.
                </Typography>
                <Typography variant="h6" color={Colors.darkCharcoal}>
                  {policyInsuredDetails?.PolicyDetail.PolicyNumber || ""}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap=".25rem">
                <span
                  className="circle-icon"
                  style={{
                    backgroundColor:
                      policyInsuredDetails?.PolicyDetail.PolicyStatus === "IF"
                        ? Colors.green
                        : Colors.textGray,
                  }}
                ></span>
                <Typography
                  color={
                    policyInsuredDetails?.PolicyDetail.PolicyStatus === "IF"
                      ? Colors.green
                      : Colors.textGray
                  }
                  variant="h6"
                >
                  {getHiPolicyStatus(
                    policyInsuredDetails?.PolicyDetail.PolicyStatus
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            display="flex"
            rowGap=".625rem"
            textAlign="start"
            className="hi-edit-policy-card-content"
          >
            <Grid
              item
              xs={6}
              sm={6}
              sx={{ borderRight: ".0625rem solid #E6E6E6" }}
            >
              <KeyPairValue
                title="Sum Assured"
                value={
                  policyInsuredDetails?.PolicyDetail.SumInsured
                    ? currencyConvertor(
                        policyInsuredDetails?.PolicyDetail.SumInsured
                      )
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} textAlign={{ xs: "end", sm: "end" }}>
              <KeyPairValue
                title="Members Insured"
                value={
                  getHiMembersInsured(
                    policyInsuredDetails?.InsuredDetail || []
                  ) || ""
                }
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <KeyPairValue
                title="Premium due date"
                value={
                  policyInsuredDetails?.PolicyDetail.ValidTo
                    ? dayjs(policyInsuredDetails?.PolicyDetail.ValidTo).format(
                        "DD/MM/YYYY"
                      )
                    : ""
                }
              />
            </Grid>
            <Grid item xs={6} sm={6} textAlign={{ xs: "end", sm: "end" }}>
              <KeyPairValue
                title="Premium due amount"
                value={
                  policyInsuredDetails?.PolicyDetail.AnnualPremium
                    ? currencyConvertor(
                        policyInsuredDetails?.PolicyDetail.AnnualPremium
                      )
                    : ""
                }
              />
            </Grid>
            {policyInsuredDetails?.PolicyDetail.Product?.toLowerCase() ===
              "super top up" && (
              <Grid item xs={6} sm={8}>
                <KeyPairValue title="Deductible" value={"₹10 Lacs"} />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default HiDetailsPagePlanCard;
