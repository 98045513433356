import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Stack,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
  IconButton,
  Container,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { Colors } from "../../../styles/constant";
import {
  getBase64,
  getCustomerRequestForDocumentUpload,
  getFileSize,
} from "../../../utils/helper";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "../../../assets/icons/upload-doc-icon.svg";
import screenShotIcon from "../../../assets/icons/screenshot-icon.svg";
import UploadedIcon from "../../../assets/icons/UploadIcon.svg";
import PdfFileExtensionTypeIcon from "../../../assets/icons/file-pdf-box.svg";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import {
  hlIndexFinder,
  HomeLoanPoweredBy,
} from "../HomeloanDetails/HomeloanDetails";
import CopyIcon from "../../../assets/icons/content-copy.svg";
import dropDownIcon from "../../../assets/icons/dropdown-arrow.svg";
import { useLazyCreateCaseQuery } from "../Homeloan_Api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { T_SummaryData } from "../Homeloan_Types";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import ArrowRightIcon from "../../../assets/icons/red-arrow-right.svg";
import { useLocation, useNavigate } from "react-router-dom";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import CustomErrorPopupBody from "../../../components/CustomErrorPopupBody/CustomErrorPopupBody";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import "./Homeloanuploaddocument.scss";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import CustomFailure from "../CustomFailure/CustomFailure";
import { R_HLRoutes } from "../Homeloan_Routes";

interface Option {
  label: string;
  value: string;
  maxFiles: number;
}

const documentOptions: Option[] = [
  { label: "Latest 3 months’ salary slips", value: "salarySlip", maxFiles: 3 },
  {
    label: "Last 6 months bank statements reflecting salary credit",
    value: "bankStatement",
    maxFiles: 6,
  },
];

interface ClickableIconProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  open: boolean;
}

const ClickableIcon: React.FC<ClickableIconProps> = ({ onClick, open }) => {
  return (
    <IconButton onClick={onClick} size="small">
      <img
        src={dropDownIcon}
        alt="Dropdown Icon"
        style={{
          marginRight: "24px",
          transform: open ? "rotate(180deg)" : "rotate(0deg)",
        }}
      />
    </IconButton>
  );
};

const Homeloanuploaddocument: React.FC = () => {
  const { state } = useLocation();
  const accountNumber = state?.accountNumber || "";
  const [documentOption, setDocumentOption] = useState<string>("salarySlip");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [maxFiles, setMaxFiles] = useState<number>(3);
  const [isExist, setIsExist] = useState<boolean>(false);
  const MAX_FILE_SIZE_MB = 10;
  const [createCase] = useLazyCreateCaseQuery();
  const [isApiSuccess, setIsApiSuccess] = useState<boolean>(false);
  const [isApiPopupOpen, setApiPopup] = useState<boolean>(false);
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const [copiedText, showCopiedText] = useState(false);
  const idx = hlIndexFinder(allSummaryData, accountNumber);
  const summaryData = allSummaryData[Number(idx)];

  const dispatch = useAppDispatch();
  const [generatedSrNumber, setSrNumber] = useState<string | null>(null);
  const [resolutionDate, setDate] = useState<string | null>(null);
  const [fileObjects, setFileObjects] = useState<
    { fileName: string; fileExtension: string; base64: string; index: number }[]
  >([]);

  // const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Set the maxFiles state based on the default documentOption
    const defaultOption = documentOptions.find(
      (option) => option.value === documentOption
    );
    if (defaultOption) {
      setMaxFiles(defaultOption.maxFiles);
    }
  }, [documentOption]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setDocumentOption(selectedValue);
    const selectedOption = documentOptions.find(
      (option) => option.value === selectedValue
    );
    setMaxFiles(selectedOption ? selectedOption.maxFiles : 3);
    setSelectedFiles([]);
    setOpen(false);
  };

  const handleIconClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const getFileExtension = (filename: string): string => {
    const parts = filename.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1].toUpperCase();
    }
    return "";
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file?.name) {
      if (!["image/jpg", "image/png", "application/pdf"].includes(file?.type)) {
        return alert(`File type ${file?.type} not supported`);
      }
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        alert(`File size exceeds ${MAX_FILE_SIZE_MB} MB limit.`);
        event.target.value = "";
      } else if (selectedFiles.length < maxFiles) {
        setSelectedFiles((prevFiles) => [...prevFiles, file]);

        const base64String = (await getBase64(file)) as string;
        const newFileObject = {
          fileName: file.name,
          fileExtension: getFileExtension(file.name),
          base64: base64String,
          index: selectedFiles.length,
        };

        setFileObjects((prevObjects) => [...prevObjects, newFileObject]);
      }
    }
  };

  const handleDeleteFile = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFileObjects((prevObjects) => prevObjects.filter((_, i) => i !== index));
  };

  const handleExistingPopupOpen = useCallback(() => {
    if (isExist) {
      setIsExist(false);
    } else {
      setIsExist(true);
    }
  }, [isExist]);

  const goToServiceRequest = useCallback(() => {
    handleExistingPopupOpen();
    // View service request page not available
    navigate(R_HLRoutes.home_loan_service_request);
  }, []);

  const handleSubmit = async () => {
    try {
      setIsBtnLoading(true);
      try {
        const changeData = {
          subject: "Account related-SwapMandate",
          subtype: "RREPSWNTHFC",
          document: fileObjects,
        };
        const request = getCustomerRequestForDocumentUpload(
          changeData,
          summaryData,
          fileObjects
        );
        const res = await createCase(request);
        if (res.data?.code === 1) {
          const response = res.data.payload;
          const srNumber = response.caseId;
          const ResolutionDate = response.expectedResolutionTime.split(" ")[0];
          if (res.data?.payload?.existingCaseId === null) {
            if (srNumber === undefined || srNumber === null) {
              setIsApiSuccess(false);
            } else {
              setSrNumber(srNumber);
              setDate(ResolutionDate);
              setIsBtnLoading(false);
              setIsApiSuccess(true);
              setApiPopup(true);
            }
          } else {
            handleExistingPopupOpen();
            setIsBtnLoading(false);
          }
        } else {
          setIsBtnLoading(false);
          dispatch(
            setError({
              error: new Error("Error"),
              message: "case create API failed",
            })
          );
        }
      } catch (error) {
        setIsBtnLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "An error occurred during the API call",
          })
        );
      }
    } catch (error) {
      setIsBtnLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "One or more file uploads failed",
        })
      );
    }
  };

  const isButtonDisabled = selectedFiles.length <= 0;

  return (
    <Box className="hl-e-nach-body">
      <Container>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <CustomBreadcrumbs
            data={["Home loan", "...", "Register/Change E-nach"]}
          />
          <CustomHelpComponent />
        </Box>
        <Box className="content-wrapper">
          <>
            <Typography variant="h3">Since you selected salaried</Typography>
            <Stack rowGap={"-0.5rem"} marginTop={"0.8rem"}>
              <Stack direction="row" columnGap={1}>
                <Typography
                  variant="subtitle2"
                  color={Colors.textGray}
                  fontSize={"0.875rem"}
                >
                  You need to provide the latest document out of the following
                  for your income verification
                </Typography>
              </Stack>
              <Box display="flex" flexDirection="column" marginTop="1.5rem">
                <Box flex={1}>
                  <FormControl fullWidth>
                    <Select
                      value={documentOption}
                      onChange={handleChange}
                      displayEmpty
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      open={open}
                      IconComponent={(props) => (
                        <ClickableIcon
                          {...props}
                          open={open}
                          onClick={handleIconClick}
                        />
                      )}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <em>Select document option</em>;
                        }
                        const selectedOption = documentOptions.find(
                          (option) => option.value === selected
                        );
                        return selectedOption ? selectedOption.label : "";
                      }}
                    >
                      {documentOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box flex={1} marginTop="1.5rem">
                  <Box className="upload-document-doc-box">
                    <Box
                      sx={{
                        borderRadius: "1.5rem",
                        marginBottom: "1.5rem",
                        backgroundColor: "#F1F3F6",
                        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%23D5D7DE' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='20' stroke-linecap='square'/%3e%3c/svg%3e")`,
                      }}
                      width="100%"
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        padding={"1.5rem 1rem"}
                      >
                        <Box>
                          <Typography variant="h5" color={Colors.darkCharcoal}>
                            {`Upload documents`}
                          </Typography>
                          <Typography
                            variant="h6"
                            fontWeight={400}
                            marginTop="0.5rem"
                            color={Colors.quartz}
                          >
                            JPG/PNG/PDF Files <br /> (up to 5MB)
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            component="label"
                            variant="text"
                            startIcon={
                              <img
                                src={
                                  selectedFiles.length >= maxFiles
                                    ? UploadedIcon
                                    : UploadIcon
                                }
                              />
                            }
                            htmlFor="upload-image"
                            className="upload-button"
                          >
                            <input
                              style={{ display: "none" }}
                              id="upload-image"
                              hidden
                              accept="image/jpeg, image/png, application/pdf"
                              type="file"
                              multiple={false}
                              onChange={handleFileUpload}
                              disabled={selectedFiles.length >= maxFiles}
                            />
                            <Typography
                              variant="h5"
                              color={Colors.descriptionGray}
                            >
                              Upload
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                    {selectedFiles.length > 0 && (
                      <Box width="100%">
                        <Box display={"flex"} justifyContent={"space-between"}>
                          <Typography
                            variant="h5"
                            marginBottom=".5rem"
                            marginLeft=".3125rem"
                            color={Colors.darkCharcoal}
                          >
                            Uploads:
                          </Typography>
                          <Typography
                            variant="h6"
                            lineHeight=".9rem"
                            marginBottom=".5rem"
                            marginRight=".5rem"
                            color={"#AFB3C0"}
                          >
                            Added {selectedFiles.length} of {maxFiles}
                          </Typography>
                        </Box>
                        {selectedFiles.map((file, index) => (
                          <Box
                            key={index}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            marginBottom="1.5rem"
                            sx={{
                              backgroundColor: "#F7F7F7",
                              borderRadius: "1.5rem",
                              padding: ".75rem 1rem",
                            }}
                          >
                            <Box display="flex" alignItems="center" gap=".5rem">
                              <Box className="upload-file-icon-wrapper">
                                <img
                                  src={
                                    getFileExtension(
                                      file.name
                                    ).toLowerCase() === "pdf"
                                      ? PdfFileExtensionTypeIcon
                                      : screenShotIcon
                                  }
                                  alt="screenshot"
                                />
                              </Box>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ wordBreak: "break-all" }}
                                >
                                  {file.name.split(".")[0]}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  lineHeight="1.125rem"
                                  color="#797979"
                                >
                                  {`${getFileExtension(file.name)} document`}
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              alignItems="center"
                              gap=".625rem"
                            >
                              <Typography variant="h6">
                                {getFileSize(file)}
                              </Typography>
                              <IconButton
                                onClick={() => handleDeleteFile(index)}
                                color="inherit"
                                size="small"
                                sx={{
                                  color: "#D04A02",
                                  padding: 0,
                                  cursor: "pointer",
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Stack>
          </>
        </Box>
      </Container>

      <Box sx={{ margin: "auto 1rem 0", paddingBottom: { md: "1.875rem" } }}>
        {isBtnLoading ? (
          <CustomThreeDotsLoader />
        ) : (
          <>
            <Button
              fullWidth
              variant="contained"
              className="continue-btn"
              disabled={isButtonDisabled}
              onClick={handleSubmit}
            >
              Submit request
            </Button>
          </>
        )}

        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          rowGap={2}
          marginTop={"1rem"}
        >
          <Box marginTop={"0.6rem"}>
            <HomeLoanPoweredBy />
          </Box>
        </Stack>
      </Box>
      <CustomPopup
        open={isApiPopupOpen}
        handleOpen={() => setApiPopup(false)}
        btnLabel={!isApiSuccess ? "Go to Interest certificate page" : ""}
        className="download-statement-popup"
        btnEndIcon={
          !isApiSuccess ? (
            <img
              src={ArrowRightIcon}
              alt="right-arrow"
              style={{ color: Colors.red }}
              loading="lazy"
            />
          ) : null
        }
      >
        {isApiSuccess ? (
          <CommonSuccessPopupBody
            des={
              "Register/change e-nach request has been submitted successfully"
            }
            withInfoText={`Your service request will be resolved by 17/02/2023`}
            btnLabel="Go back to homepage"
            btnOnClick={() => navigate("/home")}
            btnDisabled={false}
            extraInfo={
              <Stack rowGap={1}>
                <Stack
                  columnGap={0.5}
                  direction="row"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  paddingRight={"1rem"}
                  paddingLeft={"1rem"}
                >
                  <Typography
                    variant="subtitle2"
                    color={Colors.textGray}
                    fontSize={"0.85rem"}
                    textAlign="center"
                  >
                    {`Your service request will be resolved by ${resolutionDate} `}
                  </Typography>
                </Stack>
                <Stack className="hl-generated-sr" rowGap={1}>
                  <Typography
                    variant="subtitle2"
                    color={Colors.textGray}
                    fontSize={"1.125rem"}
                  >
                    Service request number
                  </Typography>
                  <Stack direction="row" columnGap={2}>
                    <Typography
                      variant="h5"
                      color={Colors.black}
                      fontSize={"1.125rem"}
                    >
                      {generatedSrNumber}
                    </Typography>
                    <img
                      src={CopyIcon}
                      alt="copy"
                      loading="lazy"
                      onClick={() => {
                        navigator.clipboard.writeText(`${generatedSrNumber}`);
                        showCopiedText(true);
                      }}
                    />
                  </Stack>
                  {copiedText && (
                    <Typography
                      variant="h6"
                      color={Colors.black}
                      fontSize={"1.125rem"}
                    >
                      Copied!
                    </Typography>
                  )}
                </Stack>
              </Stack>
            }
          />
        ) : (
          <CustomErrorPopupBody
            title="Document is not uploaded"
            btnLabel="Connect to customer support"
          />
        )}
      </CustomPopup>

      <CustomFailure
        title="Service Request Already exists"
        btnLabel="View all service requests"
        handleClick={goToServiceRequest}
        open={isExist}
      />
    </Box>
  );
};

export default Homeloanuploaddocument;
