import { FC } from "react";
import CustomPopup from "../CustomPopup/CustomPopup";
import { Box, Button, Stack, Typography } from "@mui/material";
import noConnectionImage from "../../assets/images/no-connection.svg";
import { Colors } from "../../styles/constant";

interface Props {
  open: boolean;
  gotoLogin: ()=> void;
  logoutPreviousSession : ()=> void;
}

const CustomMultipleSessionPopUp: FC<Props> = ({ open,gotoLogin,logoutPreviousSession }) => {
  return (
    <CustomPopup
      handleOpen={()=>{}}
      open={open}
    >
      <Box padding="1.5rem 1rem">
        <Box display="flex" justifyContent="center">
          <img src={noConnectionImage} alt="no-connection" />
        </Box>
        <Box marginTop="1.5rem">
          <Typography textAlign="center" variant="h3">
            Multiple sessions !
          </Typography>
          <Typography
            marginTop="1rem"
            textAlign="center"
            variant="h5"
            paddingX="1.25rem"
            color={Colors.textGray}
          >
            You are already logged in from another device,Do you wish to
            continue?
          </Typography>
        </Box>
        <Stack direction={"row"}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ margin: " 1rem .25rem .25rem  .25rem" }}
            onClick={() => gotoLogin()}
          >
            No, thank you
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ margin: " 1rem .25rem .25rem  .25rem" }}
            onClick={() => logoutPreviousSession()}
          >
            Yes, continue
          </Button>
        </Stack>
      </Box>
    </CustomPopup>
  );
};

export default CustomMultipleSessionPopUp;
