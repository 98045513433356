import React, { FC, useCallback } from "react";
import CommonMenuListPage from "../../../components/CustomMenuListPage/CommonMenuListPage";
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Avatar,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { T_ProfileHamburgerMenu } from "../../Features_Cms/ProfileHamburgerMenu.Types";

interface Props {
  handleBackBtnClick: Function;
  setVisibleComponent: Function;
  pathname?: string;
}

const AppSettings: FC<Props> = ({
  handleBackBtnClick,
  pathname,
  setVisibleComponent,
}) => {
  const handleListItemClick = useCallback((pathnameData: string) => {
    if (pathnameData) {
      setVisibleComponent(pathnameData);
    }
  }, []);

  const profileHamburgerMenuData: T_ProfileHamburgerMenu[] = useAppSelector(
    (state) => state.CmsSlice.ProfileHamburgerMenuData
  );
  const settingSubMenu = profileHamburgerMenuData.find(
    (i) => i.attributes.pathname === "app-settings" && i.attributes.isEnable
  )?.attributes.subMenu;
  return (
    <CommonMenuListPage
      title="App Settings"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
      <Box>
        {settingSubMenu ? (
          settingSubMenu.filter(item => item.isEnable).map((item) => (
            <React.Fragment key={item.menuTitle}>
              <ListItem
                key={item.id}
                onClick={() => handleListItemClick(item.pathname)}
                secondaryAction={
                  <IconButton
                    sx={{ marginRight: 0 }}
                    edge="end"
                    aria-label="delete"
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                }
                sx={{
                  marginY: ".625rem",
                  paddingLeft: "1.5rem",
                }}
              >
                <ListItemAvatar>
                  <Avatar src={item.menuIcon.data.attributes.url} />
                  {/* <item.preIcon fontSize="large" sx={{ color: Colors.red }} /> */}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h5" marginBottom={".3125rem"}>
                      {item.menuTitle}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </Box>
    </CommonMenuListPage>
  );
};

export default AppSettings;
