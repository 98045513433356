import {
  Box,
  Button,
  Typography,
  Stack,
  Checkbox,
  FormControlLabel,
  Link,
  TextField,
} from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import CustomShimmer from "../../../components/CustomShimmer/CustomShimmer";
import "./RateUsPopUp.scss";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowRightAlt from "@mui/icons-material/ArrowRightAlt";
import { FeedbackOptions } from "../../../utils/constants";
import RateUsSuccessIcon from "../../../assets/icons/RateUsSucces.svg";
import { useLazyRateUsApiQuery ,useLazyFetchRateUsContentQuery} from "../../../features/Feature_GeneralAppElements/GeneralAppElements_Api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { getMobileOperatingSystem } from "../../../utils/helper";
import useDeviceDetection from "../../../hooks/useDeviceDetection";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import Slider from "@mui/material/Slider";
import {
  ABCD_PLAYSTORE_URL,
  ABCD_APPSTORE_URL,
  rateUsCmsContentData
} from "../../../utils/constants";
import { Colors } from "../../../styles/constant";
import { DEVICE_TYPE } from "../../../utils/constants";
import { styled } from "@mui/system";
import internal from "stream";
import { T_CmsRateContentVariables } from "../../Features_Cms/CmsRateContentVariables.Types";
interface Props {
  open: boolean;
  handleOpen: Function;
}

const RateUsPopUp: FC<Props> = ({ open, handleOpen }) => {
  const deviceType = useDeviceDetection();
  const [manualStep, setManualStep] = useState<number>(0);
  const [selectedValue, setSelectedValue] = useState(3);
  const [feedbackTextValue, setFeedbackTextValue] = useState<string>("");
  const [agreed, setAgreed] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [rateUsContent, setRateUsContent] = useState<T_CmsRateContentVariables>();
  const [localLoading, setLocalLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [rateUsApi] = useLazyRateUsApiQuery();
  const [fetchRateUsContent] = useLazyFetchRateUsContentQuery();


  
  useEffect(() => {
    const getContent = async () => {
      setLocalLoading(true);
      await fetchRateUsContent().then((res) => {
        if(res.isSuccess){
          if (res.data) {
            setRateUsContent(res.data.payload.data);
            setLocalLoading(false);
          }
        }else{
          setRateUsContent(rateUsCmsContentData);
          setLocalLoading(false);
        }
      });
    }; 
    getContent();
    setManualStep(0);
    setSelectedValue(3);
    setAgreed(false);
    setFeedbackTextValue("");
  }, [])

  const handleClosePopup = () => {
    if(manualStep === 1){
      const requestData = {
        rating: selectedValue,
        feedback: feedbackTextValue ? feedbackTextValue : "",
        consent: agreed
        }
        rateUsApi(requestData).then(async (res) => {
        })
        setManualStep(0);
    }else{
    handleOpen(false);
    setManualStep(0);
    }
  }; 

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const handleSubmitClick = () => {
    if (deviceType === DEVICE_TYPE.WEB) {
      setManualStep(1);
    }else {
      if (selectedValue <= 3) {
      setManualStep(1);
    } else {
      setManualStep(3);
    }
  }
  };

  const handleFeedbackSubmitClick = useCallback(() => {
    const requestData = {
    rating: selectedValue,
    feedback: feedbackTextValue ? feedbackTextValue : "",
    consent: agreed
    }
    setIsBtnLoading(true);
    rateUsApi(requestData).then(async (res) => {
      setIsBtnLoading(false);
      if (res.isSuccess){
        setManualStep(2);
      } else if (res.isError || res.data?.code !== 1) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "Rate Us API failed",
          })
        );
      }
      });
  },[]);

  const handleEmojiClick = (index: number) => {
    setSelectedValue(index + 1);
  };

  // Handle slider change
  const handleSliderChange = (event: any, newValue: any) => {
    setSelectedValue(newValue);
  };

  const handleYesRateClick = () => {
   if(getMobileOperatingSystem() === 'iOS'){
    window.open(ABCD_APPSTORE_URL);
   }else{
    window.open(ABCD_PLAYSTORE_URL);
   }
  }

  const handleNoRateClick = () => {
        setManualStep(1);
  }

  const renderSteps = () => {
    switch (manualStep) {
      case 0:
        return (
          <Box>
            <Typography textAlign="center" variant="h6">
            {localLoading ? (
                       <CustomShimmer width="80%"/>
                  ) : (
                    rateUsContent?.attributes.title
                  )}
            </Typography>
            <Typography
              textAlign="center"
              variant="subtitle2"
              marginTop={"0.5rem"}
              marginBottom={"2.5rem"}
            >
               {localLoading ? (
                       <CustomShimmer width="80%"/>
                  ) : (
                    rateUsContent?.attributes.subTitle
                )}   
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop={"0.125rem"}
            >
              {FeedbackOptions.map((option, index) => (
                <Box
                display="flex"
                flexDirection="column"> 
                <img
                 src={`${
                  index + 1 === selectedValue ?  option.postIcon : option.preIcon}`}
                 onClick={() => handleEmojiClick(index)}
                 alt="emoji"
                 className="emoji-icon"
               />
                <Box
                  key={index}
                  fontSize={"0.688rem"}
                  fontWeight={"700"}
                  textAlign={"center"}
                  color={`${
                    index + 1 === selectedValue ? "#4C4C4C" : "#DADADA"
                  }`}
                  lineHeight={"1rem"}
                >
                  {option.label}
                </Box></Box>
              ))}
            </Box>
            <Slider
              value={selectedValue}
              min={1}
              max={5}
              className={`${
                selectedValue >= 3
                  ? "slider-color-green"
                  : "slider-color-yellow"
              }`}
              step={1}
              marks
              onChange={handleSliderChange}
              sx={{ width: "100%" }}
            />
            <Box marginTop={"2.5rem"}>
              <FormControlLabel
                control={
                  <Checkbox checked={agreed} onChange={handleAgreeChange} />
                }
                label={
                  <Typography variant="subtitle2">
                     {localLoading ? (
                       <CustomShimmer width="15rem"/>
                  ) : (
                    rateUsContent?.attributes.checkBox
                )}
                  </Typography>
                }
                className="terms-condition-check"
              />
            </Box>
            <Button
              fullWidth
              variant="contained"
              disabled={selectedValue === 0}
              className="continue-btn"
              onClick={handleSubmitClick}
            >
              Submit
            </Button>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Typography textAlign="center" variant="h6">
               {localLoading ? (
                       <CustomShimmer width="80%"/>
                  ) : (
                    selectedValue <= 3
                    ? rateUsContent?.attributes.lowRatingDescription
                    : rateUsContent?.attributes.highRatingDescription
                )}
            </Typography>
            <Stack margin={"2.5rem 0"}>
              <TextField
                multiline
                fullWidth
                minRows={6}
                value={feedbackTextValue}
                onChange={(e) => setFeedbackTextValue(e.target.value)}
              />
            </Stack>
            {isBtnLoading ? (
          <CustomThreeDotsLoader />
        ) : (
            <Button
              fullWidth
              variant="contained"
              disabled={!feedbackTextValue}
              className="continue-btn"
              onClick={handleFeedbackSubmitClick}
            >
              Submit
            </Button>
        )}
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography textAlign="center" variant="h6">
            {localLoading ? (
                       <CustomShimmer width="80%"/>
                  ) : (
                    rateUsContent?.attributes.successTitle
                )}
            </Typography>
            <Stack alignItems={"center"}>
              <img
                src={RateUsSuccessIcon}
                alt="rateussuccess"
                className="success-image"
              />
            </Stack>
            <Typography variant="subtitle2" className="thankyou-content">
               {localLoading ? (
                       <CustomShimmer width="80%"/>
                  ) : (
                    rateUsContent?.attributes.successMessage
                )}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              className="continue-btn"
              onClick={handleClosePopup}
            >
              Continue
            </Button>
          </Box>
        );
      case 3:
        return (
          <Box>
            <Typography textAlign="center" className="rateus-title">
              Rate Us
            </Typography>
            <Typography variant="subtitle2" className="thankyou-content" margin={"2rem 0.125rem"}>
            Would you mind rating us in {getMobileOperatingSystem() === 'iOS' ? 
              `AppStore`: `PlayStore`}?
            </Typography>
            <Button
              fullWidth
              variant="contained"
              className="continue-btn"
              onClick={handleYesRateClick}
            >
              Yes, Continue
            </Button>
            <Box 
             display="flex" 
             alignItems="center" 
             justifyContent="center"
             marginTop={"0.625rem"}
             marginBottom={"2rem"}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "12px",
                color: "#C7222A",
                cursor: "pointer",
              }}
              onClick={handleNoRateClick}
            >
              No, Thanks
            </Typography>
            <ArrowRightAlt
              sx={{
                fontWeight: 600,
                color: "#C7222A",
              }}
            />
          </Box>
          </Box>
        );
    }
  };
  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      className="rateUs-popup"
      btnLabel="Go back"
      btnStartIcon={
        <ChevronLeftIcon fontSize="small" sx={{ color: Colors.red }} />
      }
    >
      <Box>{renderSteps()}</Box>
    </CustomPopup>
  );
};

export default RateUsPopUp;

