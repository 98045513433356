import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  Api_DelinkFolioList,
  Api_DownloadCapitalStatement,
  Api_ExitLoad,
  Api_FolioLinkingDelinking,
  APi_FolioSchemeSummary,
  Api_FoliosSummary,
  Api_LastTransactionBroker,
  Api_ValidatePan,
  Api_AccountStatement,
} from "../../utils/ApiEndpoints";
import { getCurrentDateTimeStamp } from "../../utils/helper";

import {
  T_FetchAccountStatement_ReqPayload,
  T_FetchAccountStatement_Response,
  T_FetchExitLoad_ReqPayload,
  T_FetchExitLoad_Response,
  DownloadCapitalGainStatement_ReqPayload,
  DownloadCapitalGainStatement_Response,
  T_FolioSchemeSummary_Request,
  T_FolioSchemeSummary_Response,
  T_FoliosSummary_Request,
  T_FoliosSummary_Response,
  T_ValidatePan_Request,
  T_ValidatePan_Response,
  T_LastTransactionBroker_Request,
  T_LastTransactionBroker_Response,
  T_DelinkFolioList_Response,
  T_DelinkFolioList_Request,
  T_FolioLinkingDeLinking_Response,
  T_FolioLinkingDeLinking_Request,
} from "./MutualFund_Types";

export const MutualFundApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    exitLoad: builder.query<
      T_ApiResponse<T_FetchExitLoad_Response>,
      T_FetchExitLoad_ReqPayload
    >({
      providesTags: ["MF_EXIT_LOAD"],
      query: (apiBody) => ({
        url: Api_ExitLoad,
        method: "POST",
        body: apiBody,
      }),
    }),

    downloadCapitalGainStatement: builder.query<
      T_ApiResponse<DownloadCapitalGainStatement_Response>,
      DownloadCapitalGainStatement_ReqPayload
    >({
      query: ({
        request
      }) => ({
        url: Api_DownloadCapitalStatement,
        method: "POST",
        body: {
          request
        },
      }),
    }),
    validatePan: builder.query<
      T_ApiResponse<T_ValidatePan_Response>,
      T_ValidatePan_Request
    >({
      providesTags: ["MF_VALIDATE_PAN"],
      query: (apiBody) => ({
        url: Api_ValidatePan,
        method: "POST",
        body: apiBody,
        headers: {
          csUserId: "123",
          ciiNumber: "1234",
        },
      }),
    }),
    fetchFoliosSummary: builder.query<
      T_ApiResponse<T_FoliosSummary_Response>,
      T_FoliosSummary_Request
    >({
      providesTags: ["MF_FOLIOS_SUMMARY"],
      query: (apiBody) => ({
        url: Api_FoliosSummary,
        method: "POST",
        body: apiBody,
      }),
    }),
    fetchFolioSchemeSummary: builder.query<
      T_ApiResponse<T_FolioSchemeSummary_Response>,
      T_FolioSchemeSummary_Request
    >({
      providesTags: ["MF_FOLIO_SCHEME_SUMMARY"],
      query: (apiBody) => ({
        url: APi_FolioSchemeSummary,
        method: "POST",
        body: apiBody,
      }),
    }),
    accountStatement: builder.query<
      T_ApiResponse<T_FetchAccountStatement_Response>,
      T_FetchAccountStatement_ReqPayload
    >({
      query: ({ request }) => ({
        url: Api_AccountStatement,
        method: "POST",
        body: {
          request,
        },
      }),
    }),
    lastTransactionBroker: builder.query<
      T_ApiResponse<T_LastTransactionBroker_Response>,
      T_LastTransactionBroker_Request
    >({
      query: ({ request }) => ({
        url: Api_LastTransactionBroker,
        method: "POST",
        body: {
          request,
        },
      }),
    }),
    fetchDelinkFolioList: builder.query<
      T_ApiResponse<T_DelinkFolioList_Response>,
      T_DelinkFolioList_Request
    >({
      query: (payload) => ({
        url: Api_DelinkFolioList,
        method: "POST",
        body: {
          getDelinkFolioListRequest: payload,
        },
      }),
    }),
    folioLinkingDelinking: builder.query<
      T_ApiResponse<T_FolioLinkingDeLinking_Response>,
      T_FolioLinkingDeLinking_Request
    >({
      query: (payload) => ({
        url: Api_FolioLinkingDelinking,
        method: "POST",
        body: {
          folioLinkDelinkRequest: payload,
        },
      }),
    }),
  }),
});

export const {
  useLazyExitLoadQuery,
  useLazyValidatePanQuery,
  useLazyFetchFoliosSummaryQuery,
  useLazyFetchFolioSchemeSummaryQuery,
  useLazyDownloadCapitalGainStatementQuery,
  useLazyAccountStatementQuery,
  useLazyLastTransactionBrokerQuery,
  useLazyFetchDelinkFolioListQuery,
  useLazyFolioLinkingDelinkingQuery,
} = MutualFundApi;
