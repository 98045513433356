import { Box } from "@mui/material";
import React, { FC } from "react";
import "./CustomShimmer.scss";

interface Props {
  width?: string;
  height?: string;
  sx?: React.CSSProperties;
  borderRadius?: string;
}

const CustomShimmer: FC<Props> = ({
  width = "1.25rem",
  height = ".875rem",
  sx = {},
  borderRadius,
}) => {
  return (
    <span
      style={{
        width: width,
        height: height,
        borderRadius: borderRadius,
        ...sx,
      }}
      className="custom-shimmer"
    ></span>
  );
};

export default CustomShimmer;
