import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { API_STATUS_CODE } from "../../utils/ApiEndpoints";
import NotFound from "../../pages/NotFound/NotFound";
import { E_ChannelOptions } from "../../globalTypes/GlobalTypes";
import { updateLoadingState } from "../Feature_Loading/Loading_Slice";
import { useLazyFetchPortfolioDataQuery } from "./Stockc&Securities_Api";
import { isEmpty } from "lodash";
import {
  setStocksDashboardDetails,
  setUserDetails,
} from "./Stocks&Securities_Slice";

const StocksAndSecuritiesGuard = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const csAppSlice = useAppSelector((st) => st.CsAppSlice);
  const userDetails = csAppSlice.userDetails;
  const mobileNumber = state?.mobileNo || userDetails?.mobileNumber || "";

  const [fetchPortfolioData, { isSuccess, isError, error }] =
    useLazyFetchPortfolioDataQuery();

  const stocksSlice = useAppSelector((st) => st.StocksSlice);

  useEffect(() => {
    if (
      (isEmpty(stocksSlice.stockdashboardDetails) ||
        isEmpty(stocksSlice.CustomerData)) &&
      mobileNumber
    ) {
      dispatch(updateLoadingState(true));
      const requestData = {
        MobileNo: mobileNumber,
        PanNo: "",
        RequestDate: new Date().toString(),
      };
      fetchPortfolioData(requestData).then(async (res) => {
        if (res.data?.code === API_STATUS_CODE.SUCCESS) {
          const portfolioData = res.data.payload;
          dispatch(
            setStocksDashboardDetails(
              portfolioData[0]?.holdingDetailsList.result.NewDataset.Table1 ||
                []
            )
          );
          dispatch(setUserDetails(portfolioData?.[0]?.customerData));
        }
        dispatch(updateLoadingState(false));
      });
    }
  }, [stocksSlice.stockdashboardDetails, stocksSlice.CustomerData]);

  return isError || error ? (
    <NotFound hideButton={csAppSlice.channel != E_ChannelOptions.CSAPP} />
  ) : isEmpty(stocksSlice.stockdashboardDetails) ||
    isEmpty(stocksSlice.CustomerData) ? (
    <></>
  ) : (
    <Outlet />
  );
};

export default StocksAndSecuritiesGuard;
