import { FC } from "react";
import { Box, Divider, Typography } from "@mui/material";
import NotificationsOffSharpIcon from "../../../assets/icons/notification-off-icon.svg";
import MarkChatUnreadSharpIcon from "../../../assets/icons/notification-mark-as-read-icon.svg";
import drawerLine from "../../../assets/icons/drawer-line.svg";
import useWindowSize from "../../../hooks/useWindowSize";
import { tabViewPoint } from "../../../utils/constants";

const NotificationPopupBody: FC = () => {
  const { width } = useWindowSize();
  return (
    <Box>
      {width < tabViewPoint && (
        <Box display="flex" justifyContent="center" marginBottom="1rem">
          <img src={drawerLine} style={{ width: "fit-content" }} />
        </Box>
      )}

      <Typography
        textAlign="center"
        fontSize=".75rem"
        lineHeight=".75rem"
        letterSpacing="0.0938rem"
        fontWeight={700}
        marginBottom="1rem"
      >
        LIFE INSURANCE
      </Typography>
      <Box className="notification-drawer-body">
        <Box className="notification-content">
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"1rem"}
          >
            <img
              src={NotificationsOffSharpIcon}
              className="drawer-icon"
              alt="NotificationsOffSharpIcon"
            />
            <Typography className="drawer-text">
              Turn off notifications
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box className="notification-content">
          <Box display="flex" padding={"1rem"}>
            <img
              src={MarkChatUnreadSharpIcon}
              className="drawer-icon"
              alt="MarkChatUnreadSharpIcon"
            />
            <Typography className="drawer-text">Mark all as read</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationPopupBody;
