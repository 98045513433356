import { Box, Stack, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion";
import {
  T_FetchFolioFunctionProps,
  T_Folio,
  T_FolioSecondaryDetails,
  T_FoliosSummary_Request,
  T_MFDashboardDetails,
  T_ValidatePan_Request,
} from "../MutualFund_Types";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import {
  useLazyFetchFolioSchemeSummaryQuery,
  useLazyFetchFoliosSummaryQuery,
  useLazyValidatePanQuery,
} from "../MutualFund_API";
import {
  setFolioDetails,
  setFolioSchemeDetails,
  setMfDashboardDetails,
  setSecondaryFolioDetails,
  updateSecondaryFolioValues,
} from "../MutualFund_Slice";
import { useDispatch } from "react-redux";
import cashPlusIcon from "../../../assets/icons/cash-plus-icon.svg";
import CustomRefresh from "../../../components/CustomRefresh/CustomRefresh";
import { MfLobCode } from "../../../utils/constants";
import InvestmentCard from "../InvestmentCard/InvestmentCard";
import { useNavigate } from "react-router-dom";
import { CustomAccordionSkeleton } from "../../../components/CustomAccordianSkeletonShimmer/CustomAccordianSkeletonShimmer";

interface LoadingState {
  pan: string;
  isFolioDetailsLoading: boolean;
  isXIRRDetailsLoading: boolean;
}

type LoadingStateKeys = Exclude<keyof LoadingState, "pan">;

interface FetchFolioResult {
  isFetched: boolean;
  dashboardDetails?: T_MFDashboardDetails;
}

const MfDashboard: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFolioListLoading, setIsFolioListLoading] = useState<boolean>(false);
  const [isFolioDetailsFetched, setIsFolioDetailsFetched] =
    useState<boolean>(false);
  const [isFolioDetailsLoading, setFolioDetailsLoading] =
    useState<boolean>(false);
  const [isXIRRDetailsFetched, setIsXIRRDetailsFetched] =
    useState<boolean>(false);
  const [isXIRRDetailsLoading, setIsXIRRDetailsLoading] =
    useState<boolean>(false);
  const [mfRefreshBtnVisible, setMfRefreshBtnVisible] =
    useState<boolean>(false);
  const [loadingStates, setLoadingStates] = useState<LoadingState[]>([]);

  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const {
    mfDashboardDetails,
    secondaryFolioDetails,
    folioDetails,
    folioSchemeDetails,
  } = useAppSelector((state: any) => state.MutualFundSlice);
  const customerAndLobDetails = userDetails?.customerAndLobDetails;
  const primaryUserMFLobId = customerAndLobDetails?.filter(
    (item) => item.LOB === MfLobCode && userDetails?.pan === item.PAN
  );
  const secondaryUsers =
    customerAndLobDetails?.filter(
      (user) =>
        user.LOB === MfLobCode && user.PRIMARY_SECONDARY_USER === "secondary"
    ) || [];

  const [fetchFolioSummaryDetails] = useLazyFetchFoliosSummaryQuery();
  const [fetchFolioSchemeSummaryDetails] =
    useLazyFetchFolioSchemeSummaryQuery();
  const [fetchValidatePanApi] = useLazyValidatePanQuery();

  useEffect(() => {
    if (
      folioDetails.length === 0 &&
      primaryUserMFLobId &&
      primaryUserMFLobId.length > 0
    ) {
      setIsLoading(true);
      fetchFolioList({
        PANNo: primaryUserMFLobId[0]?.PAN,
        DOB: primaryUserMFLobId[0]?.DOB,
        isCachingRequired: true,
      });
    }
    if (secondaryUsers.length > 0) fetchSecondaryMFDetails();
  }, [customerAndLobDetails, userDetails?.pan]);

  const fetchSecondaryMFDetails = () => {
    // Group by PAN
    const groupedByPan = secondaryUsers.reduce(
      (
        acc: {
          [key: string]: {
            name: string;
            pan: string;
            holdingIdentifiers: string[];
          };
        },
        user
      ) => {
        if (!acc[user.PAN]) {
          acc[user.PAN] = {
            name: user.NAME,
            pan: user.PAN,
            holdingIdentifiers: [],
          };
        }
        if (user.HOLDING_IDENTIFIER_ONE) {
          acc[user.PAN].holdingIdentifiers.push(user.HOLDING_IDENTIFIER_ONE);
        }
        return acc;
      },
      {}
    );

    // Transform the grouped data into the desired structure
    const result: T_FolioSecondaryDetails[] = Object.values(groupedByPan).map(
      (user) => ({
        folioList: user.holdingIdentifiers.join(","),
        name: user.name,
        pan: user.pan,
        values: {
          INVESTED_AMOUNT: "",
          CURRENT_VALUE: "",
          XIRR_VALUE: "",
        },
      })
    );

    dispatch(setSecondaryFolioDetails(result));
  };

  useEffect(() => {
    if (
      mfDashboardDetails.CURRENT_VALUE &&
      mfDashboardDetails.INVESTED_AMOUNT
    ) {
      setIsFolioDetailsFetched(true);
    }
    if (mfDashboardDetails.XIRR_VALUE) {
      setIsXIRRDetailsFetched(true);
    }
  }, []);

  useEffect(() => {
    setLoadingStates(
      secondaryFolioDetails.map((user: T_FolioSecondaryDetails) => ({
        pan: user.pan,
        isFolioDetailsLoading: false,
        isXIRRDetailsLoading: false,
      }))
    );
  }, [secondaryFolioDetails]);

  const fetchDataForFolio = async ({
    folioString,
    userDetails,
    isPrimary = false,
  }: T_FetchFolioFunctionProps): Promise<FetchFolioResult> => {
    if (!folioString) {
      return { isFetched: false };
    }

    const apiBody = {
      FolioNumbers: folioString,
      DeviceInfo: "Portal",
      XIRRFlag: "Y",
    };

    const apiBody_schemeSummary = {
      P_FOLIO_NO: folioString,
      P_PAN_NO: userDetails?.pan || "",
      P_IS_MINOR: "",
    };

    let isSchemeSummarySuccessful = false;
    let dashboardDetails: T_MFDashboardDetails = {
      INVESTED_AMOUNT: "",
      CURRENT_VALUE: "",
      XIRR_VALUE: "",
    };

    try {
      const res = await fetchFolioSchemeSummaryDetails(apiBody_schemeSummary);
      if (
        res?.data?.code === 1 &&
        res.data?.payload &&
        res.data?.payload?.ReturnMsg === "No Records Found" &&
        !Object.keys(mfDashboardDetails).length
      ) {
        dashboardDetails.INVESTED_AMOUNT = "0.00";
        dashboardDetails.CURRENT_VALUE = "0.00";
        dashboardDetails.XIRR_VALUE = "0.00";
        dispatch(setMfDashboardDetails(dashboardDetails));
        return {
          isFetched: true,
          dashboardDetails: dashboardDetails,
        };
      }
      if (res?.data?.code !== 1) {
        throw new Error("Failed to fetch Quotes");
      }

      const schemeDetails = res.data?.payload.REF_CUR || [];

      if (isPrimary) {
        dispatch(setFolioSchemeDetails(schemeDetails));
      }

      let totalInvestedAmount = 0;
      let totalCurrentValue = 0;

      if (Array.isArray(schemeDetails)) {
        schemeDetails.forEach((item: any) => {
          // Adjust the type according to your actual data structure
          totalInvestedAmount += parseFloat(item.INVESTED_AMOUNT);
          totalCurrentValue += parseFloat(item.MARKET_VALUE);
        });
      }

      if (totalInvestedAmount >= 0 && Array.isArray(schemeDetails)) {
        dashboardDetails.INVESTED_AMOUNT = String(totalInvestedAmount);
        dashboardDetails.CURRENT_VALUE = String(totalCurrentValue);
        if (isPrimary) dispatch(setMfDashboardDetails(dashboardDetails));
        // Set flag to indicate success
        isSchemeSummarySuccessful = true;
      }
    } catch (err) {
      console.log(err);
      isSchemeSummarySuccessful = false;
    }

    // Only execute summary response handler if scheme summary was successful
    if (isSchemeSummarySuccessful) {
      const xirrValue = await fetchSchemeSummaryDetailsFunction(apiBody);
      if (xirrValue) {
        dashboardDetails.XIRR_VALUE = String(xirrValue);
        if (isPrimary) dispatch(setMfDashboardDetails(dashboardDetails));
      }
    } else {
      // Handle case where scheme summary fetch failed
      console.log(
        "Fetching scheme summary failed, so summary details not fetched."
      );
    }

    return {
      isFetched: isSchemeSummarySuccessful,
      dashboardDetails: dashboardDetails,
    };
  };

  const updateLoadingState = (
    pan: string,
    key: keyof LoadingState,
    value: boolean
  ) => {
    setLoadingStates((prevState) =>
      prevState.map((state) =>
        state.pan === pan ? { ...state, [key]: value } : state
      )
    );
  };

  const getStateValueByKey = (
    pan: string,
    key: LoadingStateKeys
  ): boolean | undefined => {
    const loadingState = loadingStates.find((state) => state.pan === pan);
    return loadingState ? loadingState[key] : undefined;
  };

  const isXIRRValueMissing = secondaryFolioDetails.some(
    (item: T_FolioSecondaryDetails) => {
      const isLoadingData =
        getStateValueByKey(item.pan, "isXIRRDetailsLoading") ||
        getStateValueByKey(item.pan, "isFolioDetailsLoading");
      const hasCurrentOrInvestedValue =
        item.values.CURRENT_VALUE || item.values.INVESTED_AMOUNT;
      return (
        !item.values.XIRR_VALUE && !isLoadingData && hasCurrentOrInvestedValue
      );
    }
  );

  const isAnyLoading = secondaryFolioDetails.some(
    (item: T_FolioSecondaryDetails) =>
      getStateValueByKey(item.pan, "isXIRRDetailsLoading") ||
      getStateValueByKey(item.pan, "isFolioDetailsLoading")
  );

  const shouldShowRefreshButton =
    !isAnyLoading &&
    (isXIRRValueMissing ||
      (isFolioDetailsFetched &&
        !isFolioDetailsLoading &&
        !isXIRRDetailsFetched));
  if (shouldShowRefreshButton !== mfRefreshBtnVisible)
    setMfRefreshBtnVisible(shouldShowRefreshButton);

  const handleMFaccordionClick = async ({
    folioList_P = folioDetails,
    tryAgainId,
  }: { folioList_P?: T_Folio[]; tryAgainId?: string } = {}) => {
    const primaryFolioList = folioList_P.map((item) => item.Folio_No).join(",");
    let primaryPromise = null;
    let secondaryPromises: Promise<FetchFolioResult>[] = [];

    if (primaryFolioList.length === 0) {
      setIsFolioDetailsFetched(false);
      setFolioDetailsLoading(false);
    } else if (!isFolioDetailsFetched && (!tryAgainId || tryAgainId === "1")) {
      setIsFolioDetailsFetched(false);
      setIsXIRRDetailsFetched(false);
      setFolioDetailsLoading(true);
      setIsXIRRDetailsLoading(true);
      primaryPromise = fetchDataForFolio({
        folioString: primaryFolioList,
        userDetails,
        isPrimary: true,
      });
      primaryPromise
        .then((result) => {
          if (result.isFetched) {
            setIsFolioDetailsFetched(true);
            setFolioDetailsLoading(false);
            if (result.dashboardDetails?.XIRR_VALUE) {
              setIsXIRRDetailsFetched(true);
            }
            setIsXIRRDetailsLoading(false);
          }
        })
        .finally(() => {
          setFolioDetailsLoading(false);
          setIsXIRRDetailsLoading(false);
        });
    }

    if (secondaryFolioDetails.length > 0) {
      secondaryFolioDetails.map((item: T_FolioSecondaryDetails) => {
        if (
          item.values.CURRENT_VALUE &&
          item.values.INVESTED_AMOUNT &&
          item.values.XIRR_VALUE
        ) {
          return;
        }

        // Check if tryAgainId is undefined or matches the current item's tryAgainId
        if (!tryAgainId || tryAgainId === item.pan) {
          const secondaryPromise = fetchDataForFolio({
            folioString: item.folioList,
            isPrimary: false,
          });
          // Update loading state for this secondary folio

          updateLoadingState(item.pan, "isFolioDetailsLoading", true);
          updateLoadingState(item.pan, "isXIRRDetailsLoading", true);
          secondaryPromise
            .then((result) => {
              if (result.isFetched && result.dashboardDetails) {
                dispatch(
                  updateSecondaryFolioValues({
                    pan: item.pan,
                    values: { ...result.dashboardDetails },
                  })
                );
              }
            })
            .finally(() => {
              updateLoadingState(item.pan, "isFolioDetailsLoading", false);
              updateLoadingState(item.pan, "isXIRRDetailsLoading", false);
            });

          secondaryPromises.push(secondaryPromise);
        }
      });
    }

    await Promise.allSettled([primaryPromise, ...secondaryPromises]);
  };

  const fetchFolioList = async ({
    PANNo = "",
    DOB = "",
    isCachingRequired = false,
  }) => {
    let folioDetailsList: T_Folio[] = [];
    if (folioDetails.length === 0 && PANNo) {
      const apiBody: T_ValidatePan_Request = {
        PANNo: PANNo,
        AadharNo: null,
        DOB: DOB,
        ClientIpAddress: null,
        IMEI: null,
        OS: null,
        IsNew: null,
        isMinor: null,
        Channel: null,
        PageURL: null,
        Campaign: null,
        Trans_No: null,
        TransactionType: null,
        ServerIpAddress: null,
        ModuleName: null,
        MethodName: null,
        AuthCode: null,
        GoogleAdID: null,
        DeviceId: null,
        UserName: null,
        isFolioRequired: true,
      };

      folioDetailsList = await fetchValidatePanApi(apiBody, isCachingRequired)
        .then((res) => {
          const foliosDetails = res?.data?.payload.Folios || [];
          dispatch(setFolioDetails(foliosDetails));
          return foliosDetails;
        })
        .catch((err) => {
          console.log(err);
          return [];
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return folioDetailsList;
  };

  const handleMFTryAgain: React.MouseEventHandler<HTMLDivElement> = async (
    event
  ) => {
    let folioList: T_Folio[] = [];
    const { dataset } = event.currentTarget;
    const tryAgainId = dataset.value;
    if (tryAgainId && tryAgainId === "1") {
      if (folioDetails.length === 0) {
        setIsFolioListLoading(true);
        folioList = await fetchFolioList({
          PANNo: userDetails?.pan,
          DOB: userDetails?.dob,
          isCachingRequired: false,
        });
        setIsFolioListLoading(false);
      }
      folioList.length > 0
        ? await handleMFaccordionClick({
            folioList_P: folioList,
            tryAgainId: tryAgainId,
          })
        : await handleMFaccordionClick({ tryAgainId: tryAgainId });
    } else return handleMFaccordionClick({ tryAgainId: tryAgainId });
  };

  const fetchSchemeSummaryDetailsFunction = async (
    apiBody: T_FoliosSummary_Request
  ): Promise<string | null> => {
    try {
      const res = await fetchFolioSummaryDetails(apiBody);
      if (res?.data?.payload?.ProtfolioSummary?.XIRR) {
        return res.data.payload.ProtfolioSummary.XIRR;
      }
    } catch (err) {
      console.log(err);
      // Handle error if needed
    }
    return null;
  };

  const handleMFRefresh = async () => {
    // Refresh primary folio details if XIRR value is not present
    let primaryPromise;
    if (!isXIRRDetailsFetched) {
      const primaryFolioList = folioDetails
        .map((item: T_Folio) => item.Folio_No)
        .join(",");
      if (primaryFolioList.length > 0) {
        setIsXIRRDetailsLoading(true);

        const apiBody = {
          FolioNumbers: primaryFolioList,
          DeviceInfo: "Portal",
          XIRRFlag: "Y",
        };
        primaryPromise = fetchSchemeSummaryDetailsFunction(apiBody)
          .then((xirrValue) => {
            if (xirrValue) {
              dispatch(
                setMfDashboardDetails({
                  XIRR_VALUE: xirrValue,
                })
              );
              setIsXIRRDetailsFetched(true);
              setIsXIRRDetailsLoading(false);
            } else {
              setIsXIRRDetailsLoading(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching primary folio data:", error);
            setIsXIRRDetailsLoading(false);
          })
          .finally(() => {
            setIsXIRRDetailsLoading(false);
          });
      }
    }

    // Refresh secondary folio details if XIRR value is not present
    const secondaryPromises: Promise<void>[] = [];

    secondaryFolioDetails.forEach((item: T_FolioSecondaryDetails) => {
      if (!item.values.XIRR_VALUE) {
        const apiBody = {
          FolioNumbers: item.folioList,
          DeviceInfo: "Portal",
          XIRRFlag: "Y",
        };

        const secondaryPromise = fetchSchemeSummaryDetailsFunction(apiBody)
          .then((xirrValue) => {
            if (xirrValue) {
              dispatch(
                updateSecondaryFolioValues({
                  pan: item.pan,
                  values: { ...item.values, XIRR_VALUE: xirrValue },
                })
              );
            }
          })
          .catch((error) => {
            console.error(
              `Error fetching XIRR value for secondary folio ${item.pan}:`,
              error
            );
          })
          .finally(() => {
            updateLoadingState(item.pan, "isXIRRDetailsLoading", false);
          });
        updateLoadingState(item.pan, "isXIRRDetailsLoading", true);

        secondaryPromises.push(secondaryPromise);
      }
    });

    await Promise.allSettled([primaryPromise, ...secondaryPromises]);
  };

  return (
    <Stack spacing="1rem" marginTop={"1rem"}>
      {!isLoading ? (
        <CustomAccordion
          title={`Mutual funds`}
          customClass="custom-accordion-mutual-fund"
          preIcon={
            <img
              src={cashPlusIcon}
              alt="cash-plus-icon"
              className="accordion-icon"
            />
          }
          handleAccordionClick={() => handleMFaccordionClick()}
        >
          {mfRefreshBtnVisible && (
            <CustomRefresh
              title="Refresh"
              titleSize={".75rem"}
              onClick={handleMFRefresh}
              className="custom-refresh-button"
            />
          )}
          {primaryUserMFLobId && primaryUserMFLobId?.length > 0 && (
            <>
              {secondaryFolioDetails.length > 0 && (
                <Typography variant="h5" marginBottom="1rem">
                  My Holdings
                </Typography>
              )}
              <Stack rowGap="1rem">
                <InvestmentCard
                  data={mfDashboardDetails}
                  viewDetailsClick={() => navigate(`/mutual-fund/details`)}
                  isViewDetailsRequired={folioSchemeDetails?.length > 0}
                  onTryAgain={handleMFTryAgain}
                  tryAgainId={"1"}
                  isFolioDetailsLoading={
                    isFolioDetailsLoading || isFolioListLoading
                  }
                  isFolioDetailsFetched={isFolioDetailsFetched}
                  isXIRRDetailsLoading={
                    isXIRRDetailsLoading || isFolioListLoading
                  }
                />
              </Stack>
            </>
          )}

          {secondaryFolioDetails.length > 0 && (
            <Box marginTop="1.5rem">
              <Typography variant="h5">Other Holdings</Typography>
              {secondaryFolioDetails.map((item: T_FolioSecondaryDetails) => {
                return (
                  <Stack marginTop="1rem" rowGap="1rem" key={item.pan}>
                    <Typography variant="h6" fontWeight={400}>
                      {`${item.name}`}
                    </Typography>
                    <InvestmentCard
                      data={item.values}
                      onTryAgain={handleMFTryAgain}
                      tryAgainId={item.pan}
                      isFolioDetailsLoading={getStateValueByKey(
                        item.pan,
                        "isFolioDetailsLoading"
                      )}
                      isFolioDetailsFetched={
                        !!(
                          item.values.INVESTED_AMOUNT &&
                          item.values.CURRENT_VALUE
                        )
                      }
                      isXIRRDetailsLoading={getStateValueByKey(
                        item.pan,
                        "isXIRRDetailsLoading"
                      )}
                      isViewDetailsRequired={false}
                    />
                  </Stack>
                );
              })}
            </Box>
          )}
        </CustomAccordion>
      ) : (
        <CustomAccordionSkeleton />
      )}
    </Stack>
  );
};

export default MfDashboard;
