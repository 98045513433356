import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  E_ChannelOptions,
  T_CsAppSliceState,
} from "../../globalTypes/GlobalTypes";
// import { loginRtk } from "../../features/Feature_Login/Login_Api";
import _ from "lodash";
import { loginRtk } from "../../features/Feature_Login/Login_Api";

const initialState: T_CsAppSliceState = {
  tokenDetails: undefined,
  userDetails: undefined,
  userAgent: undefined,
  traceId: undefined,
  deviceType: undefined,
  channel: "CSAPP" as E_ChannelOptions.CSAPP,
  platform: 'web',
  csUserId: "",
};

export const CsAppSlice = createSlice({
  name: "CsAppSlice",
  initialState: initialState,
  reducers: {
    updateChannelDetails: (state, action: PayloadAction<E_ChannelOptions>) => {
      return {
        ...state,
        channel: action.payload,
      };
    },
    updateTraceId: (state, action) => {
      return {
        ...state,
        traceId: action.payload,
      };
    },
    setDeviceType: (state, action) => {
      return {
        ...state,
        deviceType: action.payload,
      };
    },
    setTokenDetails: (state, action) => {
      return {
        ...state,
        tokenDetails: action.payload,
      };
    },
    setLongSessionAuthToken: (state: any, action: PayloadAction<string>) => {
      return {
        ...state,
        tokenDetails: {
          ...state.tokenDetails,
          internal: {
            ...state.tokenDetails?.internal,
            authToken: action.payload,
            longSessionAuthToken: action.payload,
          },
        },
      };
    },
    setCustomerAndLobDetails: (state, action) => {
      return {
        ...state,
        userDetails: action.payload,
      };
    },
    clearCustomerAndLobDetailsArr: (state) => {
      return {
        ...state,
        userDetails: undefined,
      };
    },
    updateUserProfileDetails: (state, action) => {
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.payload },
      };
    },
    saveCsUserId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        csUserId: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      loginRtk.endpoints.getCustomerLobDetails.matchFulfilled,
      (state, action) => {
        let tempData = action?.payload?.payload.customerAndLobDetails
          ? {
              ...state.userDetails,
              CS_USER_ID: _.isEmpty(state.userDetails?.CS_USER_ID)
                ? action?.payload?.payload.CS_USER_ID
                : "" + state?.userDetails?.CS_USER_ID,
              IS_FIRST_TIME_USER: !!state.userDetails?.IS_FIRST_TIME_USER,
              IS_USER_REGISTERED: !!state.userDetails?.IS_USER_REGISTERED,
              customerAndLobDetails: [
                ...action?.payload?.payload?.customerAndLobDetails,
              ],
            }
          : undefined;
        return {
          ...state,
          userDetails: tempData,
        };
      }
    );
  },
});

export const CsAppSliceReducer = CsAppSlice.reducer;
export const CsAppSliceKey = CsAppSlice.reducerPath;

export const {
  setDeviceType,
  setTokenDetails,
  setCustomerAndLobDetails,
  updateTraceId,
  updateUserProfileDetails,
  setLongSessionAuthToken,
  updateChannelDetails,
  clearCustomerAndLobDetailsArr,
  saveCsUserId,
} = CsAppSlice.actions;
