import { CSApiService } from "../../redux-store/CSApiService";
import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import {
  T_FetchPolicyAllDetails_Response,
  T_LIDashboardDetails_Response,
  DownloadTaxCertificate_Response,
  DownloadTaxCertificate_ReqPayload,
  DownloadPolicyAccountStatement_ReqPayload,
  DownloadPolicyAccountStatement_Response,
  T_FetchPolicyDetails_Response,
  T_FetchFundDetails_Response,
  T_FetchFundDetails_ReqPayload,
  T_FetchPastPaymentDetails_Response,
  T_FetchPolBankDetails_ReqPayload,
  T_FetchPolBankDetails_Response,
  T_FetchAnnuityDetails,
  T_PolicyBond_Response,
  T_PolicyBond_ReqPayload,
  T_FetchPremiumReceipt_Request,
  T_FetchPremiumReceiptWithDate_Response,
  T_DownloadPremiumReceipt_Response,
  T_SendWhatsApp_Response,
  T_SendEmailWhatsapp_Request,
  T_SendEmail_Response,
  T_submitFeedback_Response,
  T_submitFeedback_Request,
  T_whatsAppOptInOutRequest,
  T_whatsAppOptPayloadResponse,
  T_CreateCaseRequestForWhatsAppInOutRequest,
  T_FetchNSDLPANDetails_Request,
  T_FetchNSDLPANDetails_Response,
  T_CrmCaseCreate_Request,
  T_CrmCaseCreate_Response,
  T_LiUploadDoc_Response,
  T_LiUploadDoc_Request,
  T_LiGetOtp_Response,
  T_LiGetOtp_Request,
  T_LiValidateOtp_Response,
  T_LiValidateOtp_Request,
  T_LiFundDetails_Request,
  T_LiFundDetails_Response,
  T_LiExistingAlert_Response,
  T_LiExistingAlert_Request,
  T_FetchRecentUpdatesLi_Response,
  T_FetchRecentUpdatesLi_Request,
} from "./LifeInsurance_Types";
import {
  Api_DownloadPremium_Receipt,
  Api_DownloadTaxCertificate,
  APi_DownloadPolicyAccountStatement,
  Api_FetchAnnuityDetails,
  Api_FetchBondPolicy,
  Api_FetchDashboardDetails,
  Api_FetchFundDetails,
  Api_FetchPastPaymentDetails,
  Api_FetchPolBankDetails,
  Api_FetchPolicyAllDetails,
  Api_FetchPolicyDetails,
  Api_FetchPremium_Receipt_WithDate,
  Api_SendWhatsApp,
  Api_SendEmail,
  Api_WhatsappOpt,
  Api_FetchNSDLPANDetails,
  Api_CrmCaseCreate,
  Api_LiUploadDoc,
  Api_LiGetOtp,
  Api_LiValidateOtp,
  Api_LiFundDetails,
  Api_LiSetAlertExisting,
  Api_FetchRecentUpdates,
} from "../../utils/ApiEndpoints";

export const LifeInsuranceApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchDashboardDetails: builder.query<
      T_ApiResponse<T_LIDashboardDetails_Response>,
      string
    >({
      providesTags: ["LI_DASHBOARD"],
      query: (clientId) => ({
        url: Api_FetchDashboardDetails,
        method: "POST",
        body: { clientId },
      }),
      // extraOptions: {
      //   maxRetries: 2,
      // },
    }),
    fetchPolicyAllDetails: builder.query<
      T_ApiResponse<T_FetchPolicyAllDetails_Response>,
      string
    >({
      providesTags: (result, err, arg) => {
        return [{ type: "LI_POLICY_ALL_DETAILS", id: arg }];
      },
      query: (policyNumber) => ({
        url: Api_FetchPolicyAllDetails,
        method: "POST",
        body: { policyNumber },
      }),
    }),
    downloadTaxCertificate: builder.query<
      T_ApiResponse<DownloadTaxCertificate_Response>,
      DownloadTaxCertificate_ReqPayload
    >({
      query: ({ request }) => ({
        url: Api_DownloadTaxCertificate,
        method: "POST",
        body: { request },
      }),
    }),
    downloadPolicyAccountStatement: builder.query<
      T_ApiResponse<DownloadPolicyAccountStatement_Response>,
      DownloadPolicyAccountStatement_ReqPayload
    >({
      query: ({
        outputFormat,
        policyId,
        startDate,
        endDate,
        agentCode,
        statementOption,
        statementFormat,
      }) => ({
        url: APi_DownloadPolicyAccountStatement,
        method: "POST",
        body: {
          outputFormat,
          policyId,
          startDate,
          endDate,
          agentCode,
          statementOption,
          statementFormat,
        },
      }),
    }),
    fetchPolicyDetails: builder.query<
      T_ApiResponse<T_FetchPolicyDetails_Response>,
      string
    >({
      providesTags: (result, err, arg) => {
        return [{ type: "LI_POLICY_DETAILS", id: arg }];
      },
      query: (policyNumber) => ({
        url: Api_FetchPolicyDetails,
        method: "POST",
        body: { policyNumber },
      }),
    }),
    fetchFundDetails: builder.query<
      T_ApiResponse<T_FetchFundDetails_Response>,
      T_FetchFundDetails_ReqPayload
    >({
      providesTags: (result, err, arg) => {
        return [
          { type: "LI_FUND_DETAILS", id: arg.policyNumber + arg.effectiveDate },
        ];
      },
      query: ({ policyNumber, effectiveDate }) => ({
        url: Api_FetchFundDetails,
        method: "POST",
        body: { policy_id: policyNumber, effective_date: effectiveDate },
      }),
    }),
    fetchAnnuityDetails: builder.query<
      T_ApiResponse<T_FetchAnnuityDetails>,
      string
    >({
      providesTags: (result, err, arg) => {
        return [{ type: "LI_ANNUITY_DETAILS", id: arg }];
      },
      query: (clientId) => ({
        url: Api_FetchAnnuityDetails,
        method: "POST",
        body: { request: clientId },
      }),
    }),
    fetchPastPaymentDetails: builder.query<
      T_ApiResponse<T_FetchPastPaymentDetails_Response>,
      string
    >({
      providesTags: (result, err, arg) => {
        return [{ type: "LI_PAST_PAYMENT_DETAILS", id: arg }];
      },
      query: (policyNumber) => ({
        url: Api_FetchPastPaymentDetails,
        method: "POST",
        body: { request: policyNumber },
      }),
    }),
    fetchPolBankDetails: builder.query<
      T_ApiResponse<T_FetchPolBankDetails_Response>,
      T_FetchPolBankDetails_ReqPayload
    >({
      providesTags: (result, err, arg) => {
        return [
          { type: "LI_POLICY_DETAILS", id: arg.policyNumber + arg.clientId },
        ];
      },
      query: ({ policyNumber, clientId }) => ({
        url: Api_FetchPolBankDetails,
        method: "POST",
        body: { policyId: policyNumber, clientId: clientId },
      }),
    }),
    sendEmail: builder.query<
      T_ApiResponse<T_SendEmail_Response>,
      T_SendEmailWhatsapp_Request
    >({
      query: (request) => ({
        url: Api_SendEmail,
        method: "POST",
        body: request,
      }),
    }),
    fetchPolicyBond: builder.query<
      T_ApiResponse<T_PolicyBond_Response>,
      T_PolicyBond_ReqPayload
    >({
      query: ({ productCode, channelType, policyNumber, outputFormat }) => ({
        url: Api_FetchBondPolicy,
        method: "POST",
        body: {
          productCode: productCode,
          channelType: channelType,
          policyNumber: policyNumber,
          outputFormat: outputFormat,
        },
      }),
    }),
    downloadPremiumReceipt: builder.query<
      T_ApiResponse<T_DownloadPremiumReceipt_Response>,
      T_FetchPremiumReceipt_Request
    >({
      query: ({ request }) => ({
        url: `${Api_DownloadPremium_Receipt}`,
        method: "POST",
        body: {
          request,
        },
      }),
    }),
    fetchPremiumReceiptWithDate: builder.query<
      T_ApiResponse<T_FetchPremiumReceiptWithDate_Response>,
      T_FetchPremiumReceipt_Request
    >({
      query: (request) => ({
        url: `${Api_FetchPremium_Receipt_WithDate}`,
        method: "POST",
        body: request,
      }),
    }),
    sendWhatsapp: builder.query<
      T_ApiResponse<T_SendWhatsApp_Response>,
      T_SendEmailWhatsapp_Request
    >({
      query: (request) => ({
        url: `${Api_SendWhatsApp}`,
        method: "POST",
        body: request,
      }),
    }),
    submitFeedback: builder.query<
      T_ApiResponse<T_submitFeedback_Response>,
      T_submitFeedback_Request
    >({
      query: (feedback) => ({
        url: `li/save-nps-feedback`,
        method: "POST",
        body: feedback,
      }),
    }),
    whatsAppOptInOut: builder.query<
      T_ApiResponse<T_whatsAppOptPayloadResponse>,
      T_whatsAppOptInOutRequest
    >({
      query: (data) => ({
        url: `${Api_WhatsappOpt}`,
        method: "POST",
        body: data,
      }),
    }),
    createCasewhatsAppOptInOut: builder.query<
      T_ApiResponse<T_whatsAppOptInOutRequest>,
      T_CreateCaseRequestForWhatsAppInOutRequest
    >({
      query: (data) => ({
        url: `${Api_CrmCaseCreate}`,
        method: "POST",
        body: data,
      }),
    }),
    fetchNSDLPANDetails: builder.query<
      T_ApiResponse<T_FetchNSDLPANDetails_Response>,
      T_FetchNSDLPANDetails_Request
    >({
      query: (payload) => ({
        url: Api_FetchNSDLPANDetails,
        method: "POST",
        body: payload,
      }),
    }),
    liCrmCaseCreate: builder.query<
      T_ApiResponse<T_CrmCaseCreate_Response>,
      T_CrmCaseCreate_Request
    >({
      query: (payload) => ({
        url: Api_CrmCaseCreate,
        method: "POST",
        body: payload,
      }),
    }),
    liUploadDoc: builder.query<
      T_ApiResponse<T_LiUploadDoc_Response>,
      T_LiUploadDoc_Request
    >({
      query: (payload) => ({
        url: Api_LiUploadDoc,
        method: "POST",
        body: payload,
      }),
    }),
    liGetOtp: builder.query<
      T_ApiResponse<T_LiGetOtp_Response>,
      T_LiGetOtp_Request
    >({
      query: (payload) => ({
        url: Api_LiGetOtp,
        method: "POST",
        body: payload,
      }),
    }),
    liValidateOtp: builder.query<
      T_ApiResponse<T_LiValidateOtp_Response>,
      T_LiValidateOtp_Request
    >({
      query: (payload) => ({
        url: Api_LiValidateOtp,
        method: "POST",
        body: payload,
      }),
    }),
    liFundDetails: builder.query<
      T_ApiResponse<T_LiFundDetails_Response>,
      T_LiFundDetails_Request
    >({
      query: (payload) => ({
        url: Api_LiFundDetails,
        method: "POST",
        body: payload,
      }),
    }),
    liExistingAlerts: builder.query<
      T_ApiResponse<T_LiExistingAlert_Response>,
      T_LiExistingAlert_Request
    >({
      query: (payload) => ({
        url: Api_LiSetAlertExisting,
        method: "POST",
        body: payload,
      }),
    }),
    fetchRecentUpdatesLi: builder.query<
      T_ApiResponse<T_FetchRecentUpdatesLi_Response>,
      T_FetchRecentUpdatesLi_Request
    >({
      query: (payload) => ({
        url: Api_FetchRecentUpdates,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useLazyFetchDashboardDetailsQuery,
  useLazyDownloadTaxCertificateQuery,
  useLazyDownloadPolicyAccountStatementQuery,
  useLazyFetchAnnuityDetailsQuery,
  useLazyFetchFundDetailsQuery,
  useLazyFetchPastPaymentDetailsQuery,
  useLazyFetchPolBankDetailsQuery,
  useLazyFetchPolicyAllDetailsQuery,
  useLazyFetchPolicyDetailsQuery,
  useLazyFetchPolicyBondQuery,
  useLazyDownloadPremiumReceiptQuery,
  useLazyFetchPremiumReceiptWithDateQuery,
  useLazySendWhatsappQuery,
  useSubmitFeedbackQuery,
  useFetchPremiumReceiptWithDateQuery,
  useSendEmailQuery,
  useLazySendEmailQuery,
  useLazyFetchNSDLPANDetailsQuery,
  useLazyLiCrmCaseCreateQuery,
  useLazyLiUploadDocQuery,
  useLazyLiGetOtpQuery,
  useLazyLiValidateOtpQuery,
  useLazyLiFundDetailsQuery,
  useLazyLiExistingAlertsQuery,
  useLazyWhatsAppOptInOutQuery,
  useLazyCreateCasewhatsAppOptInOutQuery,
  useLazyFetchRecentUpdatesLiQuery,
} = LifeInsuranceApi;
