import AssignmentIcon from "@mui/icons-material/Assignment";
import StarRateIcon from "@mui/icons-material/StarRate";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AutoRenewIcon from "@mui/icons-material/Autorenew";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import BentoIcon from "@mui/icons-material/Bento";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import DownloadIcon from "@mui/icons-material/Download";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Face6OutlinedIcon from "@mui/icons-material/Face6Outlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ConstructionIcon from "@mui/icons-material/Construction";
import SettingsIcon from "@mui/icons-material/Settings";
import FaceIcon from "@mui/icons-material/Face";
import paymentIcon from "../assets/icons/payment.svg";
import personIcon from "../assets/icons/person.svg";
import folderIcon from "../assets/icons/folder.svg";
import editIcon from "../assets/icons/edit.svg";
import downloadStatementIcon from "../assets/icons/download-statement.svg";
import downloadIcon from "../assets/icons/download.svg";
import mailIcon from "../assets/icons/email red.svg";
import worstIcon from "../assets/icons/Worst.svg";
import badIcon from "../assets/icons/Bad.svg";
import fineIcon from "../assets/icons/Fine.svg";
import goodIcon from "../assets/icons/Good.svg";
import bestIcon from "../assets/icons/Best.svg";
import worstClickIcon from "../assets/icons/Worst-enable.svg";
import badClickIcon from "../assets/icons/Bad-enable.svg";
import fineClickIcon from "../assets/icons/Fine-enable.svg";
import goodClickIcon from "../assets/icons/Good-enable.svg";
import bestClickIcon from "../assets/icons/Best-enable.svg";
import trackRequestIcon from "../assets/icons/track-request.svg";
import payPremiumIcon from "../assets/icons/pay-premium.svg";
import reinstatePolicyIcon from "../assets/icons/reinstate-policy.svg";
import changePaymentFeqIcon from "../assets/icons/change-payment-feq.svg";
import setupAutoPayIcon from "../assets/icons/setup-auto-pay.svg";
import repayLoanIcon from "../assets/icons/repay-loan.svg";
import emailIcon from "../assets/icons/email red.svg";
import mobileIcon from "../assets/icons/mobile.svg";
import locationIcon from "../assets/icons/location.svg";
import updatePanIcon from "../assets/icons/update-pan.svg";
import whatsappIcon from "../assets/icons/whatsapp.svg";
import eInsuranceIcon from "../assets/icons/e-insurance.svg";
import applyForLoanIcon from "../assets/icons/apply-for-loan.svg";
import bankAccountIcon from "../assets/icons/bank-account.svg";
import setAlertsIcon from "../assets/icons/set-alerts.svg";
import fileClaimIcon from "../assets/icons/file-claim.svg";
import survivalCertificateIcon from "../assets/icons/survival-certificate.svg";
import taxCertificateIcon from "../assets/icons/tax-certificate.svg";
import policyAccStatementIcon from "../assets/icons/policy-acc-statement.svg";
import policyBondIcon from "../assets/icons/policy-bond.svg";
import serviceReqIcon from "../assets/icons/service-req.svg";
import applicationTrackerIcon from "../assets/icons/application-tracker.svg";
import HandHeartIcon from "../assets/icons/hand-heart-icon.svg";
import CardAccountDetailsIcon from "../assets/icons/card-account-details-icon.svg";
import HandHeartOutlineIcon from "../assets/icons/hand-heart-outline-icon.svg";
import ElectricityBillIcon from "../assets/icons/electricity-bill-icon.svg";
import IdCardIcon from "../assets/icons/e-card-icon.svg";
import VectorIcon from "../assets/icons/Vector-icon.svg";
import CertificateIcon from "../assets/icons/certificate-icon.svg";
import CalendarIcon from "../assets/icons/calendar-today-icon.svg";
import HospitalBoxIcon from "../assets/icons/hospital-box-icon.svg";
import PencilIcon from "../assets/icons/pencil.svg";
import abcdLogoRed from "../assets/images/abcd-logo-tm-red.svg";
import adityaBirlaInsuranceLogo from "../assets/images/abcd-insurance-logo.svg";
import TransactIcon from "../assets/icons/transact-icon.svg";
import StatementIcon from "../assets/icons/statement-icon.svg";
import LinkFolioIcon from "../assets/icons/linkFolio-icon.svg";
import WebIcon from "../assets/icons/web-icon.svg";
import LaunchIcon from "@mui/icons-material/Launch";
import AccountStatementIcon from "../assets/icons/accountStatementIcon.svg";
import CapitalGainIcon from "../assets/icons/capital-gain-icon.svg";
import RedeemIcon from "../assets/icons/redeem-icon.svg";
import SwitchIcon from "../assets/icons/switch-icon.svg";
import SwpIcon from "../assets/icons/swp-icon.svg";
import StpIcon from "../assets/icons/stp-icon.svg";
import ViewBankIcon from "../assets/icons/viewbank-icon.svg";
import RegisterOTMIcon from "../assets/icons/register-otm-icon.svg";
import myClaimsIcon from "../assets/icons/myclaims-icon.svg";
import myRequestIcon from "../assets/icons/myrequests-icon.svg";
import utilitiesIcon from "../assets/icons/utilities-icon.svg";
import calladoctorIcon from "../assets/icons/calldoctor-icon.svg";
import serviceRequestIcon from "../assets/icons/shape-plus.svg";
import handCoinIcon from "../assets/icons/hand-coin.svg";
import textBoxIcon from "../assets/icons/text-box.svg";
import welcomeKitIcon from "../assets/icons/welcome-kit.svg";
import playListIcon from "../assets/icons/playlist-check.svg";
import textBoxCheckIcon from "../assets/icons/text-box-check.svg";
import homeIcon from "../assets/icons/home.svg";
import downloadHL from "../assets/icons/download-icon.svg";
import Email from "@mui/icons-material/Email";
import renewalsIcon from "../assets/icons/renewals-icon.svg";
import setupENACHIcon from "../assets/icons/setupENACH.svg";
import setupESIIcon from "../assets/icons/setupESI.svg";
import pauseCancelIcon from "../assets/icons/pause-cancel-icon.svg";
import addModifyNomineeIcon from "../assets/icons/add-modify-nominee-icon.svg";
import changeContactIcon from "../assets/icons/change-contact-icon.svg";
import whatsappOptInIcon from "../assets/icons/whatsapp-optin-icon.svg";
import createABHAIdIcon from "../assets/icons/create-abhaId-icon.svg";
import bmiCalculatorIcon from "../assets/icons/bmi-calculator-icon.svg";
import activeAgeCalculatorIcon from "../assets/icons/activeAge-calculator-icon.svg";
import changeEmailIcon from "../assets/icons/email red.svg";
import changeAddressIcon from "../assets/icons/home-icon.svg";
import changeMobileIcon from "../assets/icons/change-mobil-icon.svg";
import changeEmiIcon from "../assets/icons/change-emi-date-icon.svg";
import changeEmiTenureIcon from "../assets/icons/calendar-end-icon.svg";
import excessEmiRefundIcon from "../assets/icons/calculator-red-icon.svg";
import tdsRefundIcon from "../assets/icons/cash-refund-icon.svg";
import changeEnachIcon from "../assets/icons/enach-icon.svg";
import { T_CmsRateContentVariables } from "../features/Features_Cms/CmsRateContentVariables.Types";

import {
  APi_DownloadPolicyAccountStatement,
  Api_DownloadTaxCertificate,
  Api_FetchDashboardDetails,
  Api_FetchPolicyDetails,
  Api_FetchPolicyDetailsByMobileNumber,
  Api_FetchProfile_Details,
  Api_GetCustomerLobDetails,
  Api_SendEmail,
  Api_SendWhatsApp,
} from "./ApiEndpoints";
import { T_CreateCaseRequest } from "../features/Feature_LifeInsurance/LifeInsurance_Types";

export const ABCD_SUPERAPP_SOURCE_NAME = "ABCD SUPER APP";
export const ABCD_SUPERAPP_URL = process.env.REACT_APP_ABCD_SUPERAPP_URL;
export const tabViewPoint = 768;
export const REACT_APP_URL = process.env.REACT_APP_URL;
export const ABCD_PLAYSTORE_URL = process.env.REACT_APP_ABCD_PLAYSTORE_URL;
export const ABCD_APPSTORE_URL = process.env.REACT_APP_ABCD_APPSTORE_URL;
export const MF_API_BASE_URL = process.env.REACT_APP_MF_API_BASE_URL;
export const TRADE_US_URL =
  "https://apps.apple.com/in/app/abcd-aditya-birla-capital/id6477909524?mt=8";

export const USE_DEV_TEST_DATA: boolean = false;
export const ABCD_SUPER_APP_PACKAGE_NAME =
  "com.adityabirlacapitaldigital.OneApp";
export const ABCD_SUPER_APP_BUNDLE_ID = "123456789"; //need to replace with original value

export const UI_SESSION_TIMEOUT = 5 * 60 * 1000;
export const UI_SESSION_TIMEOUT_PROMT = 0.5 * 60 * 1000;

export const LiLobCode = "ABSLI";
export const HiLobCode = "ABHI";
export const HlLobCode = "ABHFL";
export const MfLobCode = "ABSLAMC";
export const StockLobCode = "ABML";
export const OneLoginLobCode = "11";
export const OneLoginRedirectUrl = "https://uatonelogin.abcscuat.com/Interface";
export const HiDestinationLobId = "06";
export const LiDestinationLobId = "02";
export const MfDestinationLobId = "01";
export const SsDestinationLobId = "24";
export const LoansDestinationLobId = "03";
export const HomeLoansDestinationLobId = "04";

export const folioApiReqBodyUserId = "MF_Tan";
export const folioApiReqBodyPSW = "VUFUX1Rhbg==";

export enum DEVICE_TYPE {
  MOBILE = "ABFSSL_Mobile_CS",
  WEB = "ABFSSL_Web_CS",
  PWA_WEB = "ABFSSL_Pwa_Web_CS",
  PWA_MOBILE = "ABFSSL_Pwa_Mobile_CS",
}

export enum UI_IDLE_STATUS {
  ACTIVE = "ACTIVE",
  IDLE = "IDLE",
  PROMPTED = "PROMPTED",
}

export const accordionList = [
  { title: "Plan Details", icon: AssignmentIcon },
  { title: "Rider Details", icon: StarRateIcon },
  { title: "Premium Details", icon: WorkspacePremiumIcon },
  { title: "Personal Details", icon: PersonIcon },
  { title: "Nominee Details", icon: PeopleAltIcon },
  { title: "Renewal Payment Details", icon: AutoRenewIcon },
  { title: "Bank Account Details", icon: AccountBalanceIcon },
  { title: "Agent Details", icon: HeadsetMicIcon },
  { title: "Fund Details", icon: BentoIcon },
];

export const planDetails = [
  { label: "Policy Owner Name", value: "MS Akansha Sharma" },
  { label: "Client ID", value: "2098335109" },
  { label: "Purpose of Insurance", value: "Wealth Creation" },
  { label: "e-Insurance Number", value: "4100012177398" },
  { label: "Premium Payment Term", value: "5 Years" },
  { label: "Policy Term", value: "10 Years" },
];

export const serviceList = [
  {
    title: "Payment",
    preIcon: paymentIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "Update Profile",
    preIcon: personIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "Policy Services",
    preIcon: folderIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "Download Statements",
    preIcon: downloadStatementIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "Track Request",
    preIcon: trackRequestIcon,
    postIcon: KeyboardArrowRightIcon,
  },
];

export const quickActionList = [
  {
    title: "My documents",
    preIcon: folderIcon,
    pathName: "my-documents",
  },
  {
    title: "My profile details",
    preIcon: personIcon,
    pathName: "my-profile",
  },
];

export const paymentList = [
  {
    title: "Pay premium",
    preIcon: payPremiumIcon,
    postIcon: ArrowOutwardIcon,
  },
  {
    title: "Reinstate Policy",
    preIcon: reinstatePolicyIcon,
    postIcon: ArrowOutwardIcon,
  },
  {
    title: "Change payment frequency",
    preIcon: changePaymentFeqIcon,
    postIcon: ArrowOutwardIcon,
  },
  // {
  //   title: "Setup Auto-Pay",
  //   preIcon: setupAutoPayIcon,
  //   postIcon: ArrowOutwardIcon,
  // },
  {
    title: "Setup ENACH",
    preIcon: setupENACHIcon,
    postIcon: ArrowOutwardIcon,
  },
  {
    title: "Setup ESI",
    preIcon: setupESIIcon,
    postIcon: ArrowOutwardIcon,
  },

  {
    title: "Repay Loan",
    preIcon: repayLoanIcon,
    postIcon: ArrowOutwardIcon,
  },
];

export const updateProfileList = [
  {
    title: "Update Email Id",
    preIcon: emailIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "Update Mobile Number",
    preIcon: mobileIcon,
    postIcon: KeyboardArrowRightIcon,
    errorMessage: "",
  },
  {
    title: "Update Address",
    preIcon: locationIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "Update PAN",
    preIcon: updatePanIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "update-PAN",
    errorMessage: "",
  },
  {
    title: "WhatsApp Opt in/out",
    preIcon: whatsappIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "Open e-Insurance Account",
    preIcon: eInsuranceIcon,
    postIcon: ArrowOutwardIcon,
  },
];

export const policyServicesList = [
  {
    title: "Apply for Loan",
    preIcon: applyForLoanIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  // {
  //   title: "Add/Update Nominee",
  //   preIcon: payPremiumIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   title: "Add/Update Bank Account",
  //   preIcon: bankAccountIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   title: "Set Alerts",
  //   preIcon: setAlertsIcon,
  //   postIcon: KeyboardArrowRightIcon,
  //   pathname: "set-alerts",
  // },
  {
    title: "Fund Switch/Premium Redirection",
    preIcon: changePaymentFeqIcon,
    postIcon: ArrowOutwardIcon,
  },
  {
    title: "File Claim",
    preIcon: fileClaimIcon,
    postIcon: ArrowOutwardIcon,
  },
  {
    title: "Survival Certificate",
    preIcon: survivalCertificateIcon,
    postIcon: ArrowOutwardIcon,
  },
];

export const downloadStatementList = [
  {
    title: "Tax Certificate",
    preIcon: taxCertificateIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "tax-certificate",
  },
  {
    title: "Premium Receipts",
    preIcon: applyForLoanIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "premium-receipts",
  },
  {
    title: "Policy Account Statement",
    preIcon: policyAccStatementIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "policy-account-statement",
  },
  {
    title: "Policy Bond",
    preIcon: policyBondIcon,
    postIcon: DownloadIcon,
    pathname: "policy-bond",
  },
];

export const trackRequestList = [
  {
    title: "Service Request",
    preIcon: serviceReqIcon,
    postIcon: ArrowOutwardIcon,
  },
  {
    title: "Application Tracker",
    preIcon: applicationTrackerIcon,
    postIcon: ArrowOutwardIcon,
  },
  {
    title: "Claim Tracker",
    preIcon: policyAccStatementIcon,
    postIcon: ArrowOutwardIcon,
  },
];

export const QuickOptionListMf = [
  {
    title: "Transact",
    preIcon: TransactIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "My Statements",
    preIcon: StatementIcon,
    postIcon: KeyboardArrowRightIcon,
    headerIcon: folderIcon,
  },
  // {
  //   title: "Link/Delink folios",
  //   preIcon: LinkFolioIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   title: "Go to ABSLMF Portal",
  //   preIcon: WebIcon,
  //   postIcon: LaunchIcon,
  // },
];

export const mfStatementList = [
  {
    title: "Account statement",
    preIcon: AccountStatementIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "account-statement",
  },
  {
    title: "Capital gain statement",
    preIcon: CapitalGainIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "capital-gains",
  },
  {
    title: "Exit load",
    preIcon: CapitalGainIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "exit-load",
  },
  {
    title: "Transaction statement",
    preIcon: TransactIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "transaction-statement",
  },
];
export const mfTransactList = [
  {
    title: "Redeem",
    preIcon: RedeemIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "redeem",
  },
  {
    title: "SWP",
    preIcon: SwpIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "swp",
  },
  {
    title: "Switch",
    preIcon: SwitchIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "switch",
  },
  {
    title: "STP",
    preIcon: StpIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "stp",
  },
  {
    title: "Pause/ cancel SIP",
    preIcon: pauseCancelIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "pause-cancel",
  },
  {
    title: "View/Add Bank",
    preIcon: ViewBankIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "view-add-bank",
  },
  {
    title: "Register OTM",
    preIcon: RegisterOTMIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "register-otm",
  },
  {
    title: "Add/Modify Nominee",
    preIcon: addModifyNomineeIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "add-modify-nominee",
  },
  {
    title: "Change Contact",
    preIcon: changeContactIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "change-contact",
  },
  {
    title: "WhatsApp Opt in",
    preIcon: whatsappIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "whatsapp-opt-in",
  },
];

export const hamburgerMenuList = [
  {
    title: "My Profile",
    des: "Manage and edit your profile",
    preIcon: FaceIcon,
    pathname: "my-profile",
  },
  {
    title: "Utilities",
    des: "Discover hospitals, locate branches & more",
    preIcon: ConstructionIcon,
    pathname: "utilities",
  },
  {
    title: "Help & Support",
    des: "Contact us & FAQs",
    preIcon: HelpOutlineIcon,
    pathname: "help-&-support",
  },
  // {
  //   title: "App Settings",
  //   des: "App Setup: mPIN, permissions, biometrics",
  //   preIcon: SettingsIcon,
  //   pathname: "app-settings",
  // },
  {
    title: "Logout",
    des: "",
    preIcon: ExitToAppIcon,
    pathname: "logout",
  },
];

export const utilitiesMenuList = [
  {
    title: "Network hospital",
    preIcon: ApartmentIcon,
    url: "https://adityabirlanh.findmystore.io/locator/stores",
  },
  {
    title: "Branch locator",
    preIcon: LocationOnIcon,
    url: "https://adityabirla.findmystore.io/locator/stores",
  },
];

export const helpAndSupportMenuList = [
  {
    title: "Call us",
    preIcon: CallOutlinedIcon,
    pathname: "call-us",
  },
  {
    title: "Chat with us",
    preIcon: ChatBubbleOutlineIcon,
    pathname: "chat-with-us",
  },
  {
    title: "Email",
    preIcon: EmailOutlinedIcon,
    pathname: "email",
  },
  // {
  //   title: "FAQs",
  //   preIcon: QuizOutlinedIcon,
  //   pathname: "FAQs",
  // },
  {
    title: "Report a bug",
    preIcon: BugReportOutlinedIcon,
    pathname: "report-bug",
  },
];

export const appSettingsMenuList = [
  {
    title: "Change mPIN",
    preIcon: LockOutlinedIcon,
    pathName: "change-mPIN",
  },
  // {
  //   title: "Permission settings",
  //   preIcon: SettingsOutlinedIcon,
  //   pathName: "",
  // },
  // {
  //   title: "Device authentication settings",
  //   preIcon: Face6OutlinedIcon,
  //   pathName: "device-authentication-settings",
  // },
];

export const loansMenuList = [
  { title: "Business Loan" },
  { title: "Personal Loan" },
  { title: "Home Loan" },
  { title: "Insurance" },
];

export const FAQsMenuList = [
  { title: "General App FAQ", open: false },
  { title: "Loans", subList: loansMenuList, open: false },
  { title: "Invest", open: false },
  { title: "Insurance", open: false },
];

export const businessLoanMenuList = [
  { title: "Auto debit" },
  { title: "General" },
  { title: "Repayment" },
  { title: "KYC" },
];

export const homeLoanMenuList = [
  { title: "Repayment" },
  { title: "Auto Debit" },
  { title: "Miscellaneous" },
  { title: "General" },
  { title: "Bank statement" },
  { title: "Account Aggregator" },
];

export const autoDebitMenuList = [
  {
    title: "Can I cancel my life insurance policy? Are there any penalties?",
    des: [
      "Yes, you can cancel your life insurance policy if you wish to do so. However, it's important to understand that canceling a policy may have certain implications.",
      "Depending on the terms and conditions of your policy, there may be penalties or charges associated with canceling your policy. These penalties could vary based on the duration of the policy and any applicable surrender charges.",
    ],
  },
  { title: "What happens if I stop paying premiums or my policy lapses?" },
  { title: "How do I surrender or terminate my life insurance policy?" },
  {
    title:
      "What are the options for policy beneficiaries if the insured person passes away?",
  },
  {
    title:
      "Can I change the beneficiaries on my life insurance policy, and if so, how do I go about it?",
  },
];

export const FooterDetailsList = {
  privacyPolicy: {
    title: "PRIVACY POLICY",
    description: `Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home or any other place without the need of going to a designated place like an ATM.
  Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes.
  Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home.
  Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home or any other place without the need of going to a designated place like an ATM.
  Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes.
  Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home.`,
  },
  aboutUs: {
    title: "ABOUT US",
    description: `Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home or any other place without the need of going to a designated place like an ATM.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home or any other place without the need of going to a designated place like an ATM.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home.`,
  },
  termsAndConditions: {
    title: "TERMS & CONDITIONS",
    description: `Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home or any other place without the need of going to a designated place like an ATM.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home or any other place without the need of going to a designated place like an ATM.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes.
    Although you can make direct transfers to banks using ATM cards, it is less convenient than UPI transfers. You need to visit an ATM for money transfers and sometimes, the nearby ATMs may not be able to read the card’s bar code or have low servers. If payments through UPI VPA fail, you can try later at the comfort of your home.`,
  },
};

export const radioListData = [
  { label: "Download Now", key: "download", preIcon: downloadStatementIcon },
  { label: "Get it on Email Id", key: "email", preIcon: emailIcon },
  { label: "Get it on Whatsapp", key: "whatsapp", preIcon: whatsappIcon },
];

export const radioFamilyMembersData = [
  { radioLabel: "Mother", radioKey: "mother" },
  { radioLabel: "Father", radioKey: "father" },
  { radioLabel: "Spouse", radioKey: "spouse" },
  { radioLabel: "Daughter", radioKey: "daughter" },
  { radioLabel: "Son", radioKey: "son" },
  { radioLabel: "Other", radioKey: "other" },
];

export const radioFinancialYearData = [
  { radioLabel: "2020-2021", radioKey: "2021" },
  { radioLabel: "2021-2022", radioKey: "2022" },
  { radioLabel: "2022-2023", radioKey: "2023" },
  { radioLabel: "2023-2024", radioKey: "2024" },
  { radioLabel: "2024-2025", radioKey: "2025" },
];

export const NotificationData = [
  {
    preIcon: HandHeartIcon,
    title: "EMI date approaching!",
    description: "Your next EMI for personal loan is due in 5 days.",
    date: new Date("2024-02-06"),
    isNotificationRead: false,
  },
  {
    preIcon: CardAccountDetailsIcon,
    title: "Add Nominee for Mutual Funds",
    description:
      "We noticed you do not have any nominee added for your MutualFunds. Click here to add now.",
    date: new Date("2024-01-06"),
    isNotificationRead: true,
  },
  {
    preIcon: HandHeartOutlineIcon,
    title: "Recommended for you",
    description:
      "Based on your existing journey, we have a pre-approved loan just for you!",
    date: new Date("2024-06-17"),
    isNotificationRead: false,
  },
  {
    preIcon: ElectricityBillIcon,
    title: "Pay your electricity bill, 20 days left!",
    description:
      "Get ₹50 cash back by successfully adding your electricity bill",
    date: new Date("2024-06-17"),
    isNotificationRead: true,
  },
  {
    preIcon: HandHeartIcon,
    title: "Complete your life insurance",
    description:
      "You left your life insurance application midway. Click here to complete it.",
    date: new Date("2024-06-14"),
    isNotificationRead: true,
  },
];

export const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
export const phoneRegex = /^[6-9]\d{9}$/;
export const dobRegex =
  /^(0[1-9]|[12][0-9]|3[01])\s(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/;
export const termPlanDetails = {
  topSection: [
    { label: "Policy No.", value: "008368298" },
    { label: "Plan Name", value: "ABSLI Life Shield 2017 Lev T40 P40" },
    { label: "Start Date", value: "30-03-2017" },
  ],
  bottomSection: [
    { label: "Sum Assured", value: "₹5 Crores" },
    { label: "Policy Term", value: "40 years" },
    { label: "Payment Term", value: "40 years" },
    { label: "Premium", value: "₹3,081.28" },
    { label: "Mode", value: "Half-Yearly" },
    { label: "Due Date", value: "23 Mar 2023" },
  ],
};

export const radioListRelationshipData = [
  { label: "Mother", key: "Mother" },
  { label: "Father", key: "Father" },
  { label: "Spouse", key: "Spouse" },
  { label: "Daughter", key: "Daughter" },
  { label: "Son", key: "Son" },
  { label: "other", key: "other" },
];

export const appSwitchHeaderList = [
  {
    preIcon: adityaBirlaInsuranceLogo,
    title: "Your ABC holdings",
    subTitle: "Track, Manage, Service & More",
    radioKey: "csApp",
  },
  {
    preIcon: abcdLogoRed,
    title: "Explore Financial Products",
    subTitle: "Pay, Invest, Borrow, Insure & More",
    radioKey: "abcdApp",
  },
];
export const termsAndConditionsTitle = `Terms and Conditions`;
export const privacyPolicyTitle = `Privacy Policy`;
export const aboutUsTitle = `About Us`;
export const monthsList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const policyStatusListForTaxAndPremiumReceiptPolicyBond = [
  "WAIVER",
  "PAID-UP ANNUITY/EXTENDED TERM",
  "RPU ULIP",
  "PREMIUM PAYING",
  "LAPSED",
  "DISCONTINUED-PAYOUT PENDING",
];
export const policyStatusListForPolicyAccountStatement = [
  "WAIVER",
  "PAID-UP ANNUITY/EXTENDED TERM",
  "SURRENDER PAYOUT PENDING",
  "PREMIUM PAYING",
  "PAID-UP LIFE",
];

export const policyStatusListForUpdatePAN = [
  "PREMIUM WAIVER OR PAYOR DEATH",
  "PAID-UP ANNUITY/ EXTENDED TERM-ULIP",
  "RPU ULIP",
  "PREMIUM PAYING",
  "LAPSED",
  "DISCONTINUED",
  "VESTED ANNUITY",
];
export const documentTypeForTaxCertificate = "Tax Certificate";
export const documentTypeForPremiumReceipt = "Premium Receipt";
export const documentTypeForPolicyAccountStatement = "Policy Account Statement";
export const agentCodeValue = `a2p9i9`;
export const dbDetailValue = `RWS`;

export const EVENT_HANDLER = {
  LOGOUT_EVENT: "Logout",
  EXTEND_SESSION_EVENT: "ExtendSession",
  GO_TO_SUPER_APP_EVENT: "GoToSuperApp",
};

export const EVENT_SESSION_TIMEOUT = {
  MSG: "LOG OUT DUE TO SESSION TIMEOUT",
};

export const staticReqPayloadApis = [
  Api_FetchPolicyDetails,
  Api_GetCustomerLobDetails,
  Api_FetchDashboardDetails,
  Api_DownloadTaxCertificate,
  APi_DownloadPolicyAccountStatement,
  Api_FetchProfile_Details,
  Api_SendEmail,
  Api_SendWhatsApp,
  Api_FetchPolicyDetailsByMobileNumber,
];

export const hiQuickMenuList = [
  {
    title: "Policy Documents",
    preIcon: folderIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "Renewals",
    preIcon: renewalsIcon,
    postIcon: LaunchIcon,
  },
  {
    title: "My Claims",
    preIcon: myClaimsIcon,
    postIcon: LaunchIcon,
  },
  {
    title: "Edit Policy",
    preIcon: VectorIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  {
    title: "My Requests",
    preIcon: myRequestIcon,
    postIcon: LaunchIcon,
  },
  {
    title: "Create ABHA ID",
    preIcon: createABHAIdIcon,
    postIcon: LaunchIcon,
  },
  {
    title: "Call a doctor",
    preIcon: calladoctorIcon,
    postIcon: LaunchIcon,
  },
  {
    title: "Utilities",
    preIcon: utilitiesIcon,
    postIcon: KeyboardArrowRightIcon,
  },
  // {
  //   title: "Utilities",
  //   preIcon: utilitiesIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
];

export const hiPolicyDocumentList = [
  {
    title: "E- Card",
    preIcon: IdCardIcon,
    postIcon: DownloadIcon,
    pathname: "e-card",
  },
  {
    title: "Policy wording",
    preIcon: VectorIcon,
    postIcon: DownloadIcon,
    pathname: "policyWordings",
  },
  {
    title: "Premium certificate",
    preIcon: CertificateIcon,
    postIcon: DownloadIcon,
    pathname: "premiumCertificate",
  },
  {
    title: "Policy schedule",
    preIcon: CalendarIcon,
    postIcon: DownloadIcon,
    pathname: "policySchedule",
  },
  {
    title: "Medical reports",
    preIcon: HospitalBoxIcon,
    postIcon: KeyboardArrowRightIcon,
    pathname: "medical-reports",
  },
];

export const hiEditPolicyTabItems = [
  "Proposer Details",
  "Contact details",
  "Nominee details",
];

export const hiEditPolicyProposalFrom = [
  {
    key: "PolicyOwnerName",
    title: "Proposer Name",
    icon: PencilIcon,
    type: "text",
    editLabel: "Name",
  },
  {
    key: "DateOfBirth",
    title: "DOB",
    icon: CalendarIcon,
    type: "dob",
    editLabel: "DOB",
  },
  {
    key: "pan",
    title: "PAN",
    icon: PencilIcon,
    type: "text",
    editLabel: "PAN",
  },
  {
    key: "aadhar",
    title: "Aadhar",
    icon: null,
  },
  {
    key: "Gender1",
    title: "Gender",
    icon: PencilIcon,
    editLabel: "PAN",
  },
];

export const hiEditPolicyContactForm = [
  {
    key: "Mobile",
    title: "Mobile Number",
    icon: PencilIcon,
    type: "text",
  },
  {
    key: "NomineeContactNumber",
    title: "Alternate Mobile Number",
    icon: PencilIcon,
    type: "text",
  },
  {
    key: "Email",
    title: "Email ID",
    icon: PencilIcon,
    type: "text",
  },
  {
    key: "Address",
    title: "Address",
    icon: PencilIcon,
    type: "text",
  },
];

export enum DashboardTabKeys {
  Insurance = "Insurance",
  Investment = "Investment",
  Loans = "Loans",
}

export const DashboardTabValues = [
  {
    tabKey: DashboardTabKeys.Insurance,
    tabValue: ["Life Insurance", "Health Insurance"],
  },
  {
    tabKey: DashboardTabKeys.Investment,
    tabValue: ["Mutual Funds", "Stocks & Securities"],
  },
  {
    tabKey: DashboardTabKeys.Loans,
    tabValue: ["Loans"],
  },
];

export const FolioNumberList = [
  { label: "123475950", value: "123475950" },
  { label: "123475950", value: "123475950" },
  { label: "1016978328", value: "1016978328" },
];

export const FolioNumberAccountList = [
  { label: "123475950", value: "123475950" },
  { label: "123475950", value: "123475950" },
  { label: "123475950", value: "123475950" },
];

export const SchemeListValue = [
  { label: "ABC Scheme One", value: "ABC Scheme One" },
  { label: "ABC scheme Two", value: "ABC Scheme Two" },
];

export const ReportType = [
  { label: "Summary", value: "2" },
  { label: "Transactional", value: "3" },
  {
    label: "Both Summary and Transactional",
    value: "1",
  },
];

export const AdvisorDetails = [
  { label: "No", value: "N" },
  {
    label: "Yes (Broker Details to be displayed)",
    value: "Y",
  },
];

export const documentFormat = [
  { label: "Excel format", key: "excel" },
  { label: "Pdf format", key: "pdf" },
];

export const hiUtilitiesList = [
  {
    title: "BMI Calculator",
    preIcon: bmiCalculatorIcon,
    postIcon: LaunchIcon,
    pathname: "bmiCalculator",
  },
  {
    title: "Active age calculator",
    preIcon: activeAgeCalculatorIcon,
    postIcon: LaunchIcon,
    pathname: "activeAgeCalculator",
  },
];

export const HLQuickList = [
  {
    title: "Statement & documents",
    preIcon: folderIcon,
    postIcon: KeyboardArrowRightIcon,
    isNavIcon: true,
  },
  {
    title: "Service requests",
    preIcon: serviceRequestIcon,
    postIcon: KeyboardArrowRightIcon,
    isNavIcon: true,
  },
  {
    title: "Disbursement request",
    preIcon: handCoinIcon,
    postIcon: KeyboardArrowRightIcon,
    isNavIcon: true,
  },
];

export const HLStatementsAndDocuments: {
  key: string;
  title: string;
  preIcon: any;
  postIcon: React.ElementType;
  pathname?: string;
  secondIcon?: string;
  isNavIcon?: boolean;
}[] = [
  {
    key: "statements-documents",
    title: "Statement of Account",
    preIcon: textBoxIcon,
    postIcon: KeyboardArrowRightIcon,
    isNavIcon: true,
  },
  {
    key: "interest-certificate",
    title: "Interest Certification",
    preIcon: CertificateIcon,
    postIcon: KeyboardArrowRightIcon,
    isNavIcon: true,
  },
  {
    key: "welcome-kit",
    title: "Welcome Kit",
    preIcon: welcomeKitIcon,
    postIcon: Email,
    secondIcon: downloadHL,
    isNavIcon: false,
  },
  {
    key: "repayment-schedule",
    title: "Repayment Schedule",
    preIcon: CalendarIcon,
    postIcon: Email,
    secondIcon: downloadHL,
    isNavIcon: false,
  },
  // {
  //   key: "balance-confirmation-letter",
  //   title: "Balance Confirmation Letter",
  //   preIcon: textBoxCheckIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   key: "gst-invoice",
  //   title: "GST Invoice",
  //   preIcon: textBoxIcon,
  //   postIcon: Email,
  // },
  // {
  //   key: "list-of-documents",
  //   title: "List of documents",
  //   preIcon: playListIcon,
  //   postIcon: Email,
  // },
  // {
  //   key: "insurance-copy",
  //   title: "Insurance copy",
  //   preIcon: folderIcon,
  //   postIcon: Email,
  // },
];

export const FeedbackOptions = [
  {
    label: "Worst",
    key: "worst",
    preIcon: worstIcon,
    postIcon: worstClickIcon,
  },
  { label: "Bad", key: "bad", preIcon: badIcon, postIcon: badClickIcon },
  { label: "Fine", key: "fine", preIcon: fineIcon, postIcon: fineClickIcon },
  { label: "Good", key: "good", preIcon: goodIcon, postIcon: goodClickIcon },
  { label: "Best", key: "best", preIcon: bestIcon, postIcon: bestClickIcon },
];
export const HLServiceRequest = [
  // {
  //   key: "change-email",
  //   title: "Change email",
  //   preIcon: changeEmailIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   key: "change-mobile",
  //   title: "Change mobile",
  //   preIcon: changeMobileIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   key: "change-address",
  //   title: "Change address",
  //   preIcon: changeAddressIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   key: "change-emi-date",
  //   title: "Change EMI date",
  //   preIcon: changeEmiIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   key: "change-emi-tenure",
  //   title: "Change EMI/Tenure",
  //   preIcon: changeEmiTenureIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   key: "excess-emi-refund",
  //   title: "Excess EMI refund",
  //   preIcon: excessEmiRefundIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  // {
  //   key: "tds-refund",
  //   title: "TDS refund",
  //   preIcon: tdsRefundIcon,
  //   postIcon: KeyboardArrowRightIcon,
  // },
  {
    key: "register-change-e-nach",
    title: "Register/Change E-Nach",
    preIcon: changeEnachIcon,
    postIcon: KeyboardArrowRightIcon,
    isNavIcon: true,
  },
];
export const HiNomineeRelationship = [
  {
    value: "Mother",
    label: "Mother",
  },
  {
    value: "Father",
    label: "Father",
  },
  {
    value: "Spouse",
    label: "Spouse",
  },
  {
    value: "Daughter",
    label: "Daughter",
  },
  {
    value: "Son",
    label: "Son",
  },
  {
    value: "Mother-in-law",
    label: "Mother-in-law",
  },
  {
    value: "Father-in-law",
    label: "Father-in-law",
  },
];

export const StatementType = [
  {
    value: "FinancialYear",
    label: "Select a financial Year",
  },
  {
    value: "DateRange",
    label: "Select date range",
  },
];

export const downloadPopOptions = [
  {
    title: "Download",
    icon: downloadIcon,
  },
  {
    title: "Email",
    icon: mailIcon,
  },
];

export const HiEditPolicyNomineeForm = [
  {
    key: "Nominee details",
    title: "Nominee Details",
    icon: PencilIcon,
    type: "text",
  },
];
export const hiDestinationLobId = "06";

export const hlTermsandConditions = [
  'Aditya Birla Home Finance Limited ("ABHFL") offers you the option of paying the Overdue amounts on your loan availed from ABHFL, by using the online payment facilities. The payment processed is through "Bill Desk" (Third Party). Please read these conditions of the Disclaimer carefully. By using the online payment facility, you agree to be bound by these terms contained herein.',
  "For making online payment, you will access a third party site. ABHFL does not in any way warrant the accuracy or completeness of the information, materials, services or the reliability of any service, advice, opinion, statement or other information displayed or distributed through such third party site. Customer may access this site solely for purposes of repayment of your Loan and you understand and acknowledge that availing of any services offered on the site or your reliance on any opinion, advice, statement, memorandum, or information available on the site shall be at your sole risk and discretion.",
  " Aditya Birla Home Finance Ltd. and its affiliates, subsidiaries, employees, officers, directors and agents, expressly disclaim any liability for any deficiency in the services of the Payment Gateway Provider. Neither Aditya Birla Home Finance Ltd. nor any of its affiliates nor their directors, officers and employees will be liable to or have any responsibility of any kind for any loss that you incur arising out of any deficiency in the services of the Payment Gateway Provider to whom the site belongs, failure or disruption of the site of Payment Gateway Provider, or resulting from the act or omission of any other party involved in making this site or the data contained therein available to you, or from any other cause relating to your access to, inability to access, or use of the site or these materials.",
  "ABHFL may change the terms of this disclaimer, at any time and without notice. It is recommended that you review the terms of this disclaimer, periodically for changes. ABHFL shall not be held responsible for all or any actions that may subsequently result in any loss, damage and or liability on account of such change in the information on this website.",
  "I hereby confirm that I am the borrower and declare that the overdue amount paid is being paid from the income earned from legitimate funds and from a bank account/credit card which belongs to me. I understand and agree that this is a regulatory requirement.",
  "I hereby confirm that I have read and agree to abide by the conditions of the Disclaimer for using the online payment facility.",
];
export const liDestinationLobId = "02";

export const PaymentStatusList = ["SUCCESS", "FAILURE", "PENDING"];

export const LiUpdatePanCaseCreateSampleReqBody: T_CreateCaseRequest = {
  ONBEHALFOF: "Customer",
  ADVISORCODE: "",
  CODE: "PNFT009",
  CUSTOMERID: "",
  POLICYNO: "",
  EMAILID: "",
  FIRSTNAME: "",
  LASTNAME: "",
  MOBILENO: "",
  SMSSHORTCODE: "",
  REMARKS: "",
  CATEGORY: "",
  FLSCASE: "",
  CASETYPE: "",
  CASESUBTYPE: "",
  CASESUBSUBTYPE: "",
  SOURCE: "ABC_Servicing_App",
  FTR: "",
  UDP1: "",
  UDP2: "",
  UDP3: "",
  UDP4: "",
  UDP5: "",
  ClientIPAddress: "",
  VoucherNo: "",
  VoucherDate: "",
  DocumentUrl: "",
  lstNomineeUpdate: [],
  POIOption1: "",
  POIOption2: "",
  POIOption3: "",
  Aadhar_number: "",
  Aadhar_Valid_Status: "",
  PAN_number: "",
  Pan_Valid_status: "",
  Address1: "",
  Address2: "",
  Address3: "",
  Area: "",
  City: "",
  Pincode: "",
  State: "",
  Country: "",
  Address_Valid_status: "",
  Address_STP_Flag: "",
  Account_number: "",
  Account_type: "",
  IPFSC_code: "",
  MICRCode: "",
  Bank_name: "",
  Branch_name: "",
  Bank_valid_status: "",
  NameAsNsdl: "",
  PolicyOwnerName: "",
  NameMatchPercent: "",
  NsdlAPIStatus: "SUCCESS",
  NameMatchAPIStatus: "",
  NameMatched: "",
  TransactionId: "",
};

export const SET_ALERT_MESSAGE =
  "Alert will be sent to your registered Email ID";
export let LiUpdateMobileNumberCreateSampleReqBody = {
  ONBEHALFOF: "Customer",
  ADVISORCODE: "",
  CODE: "PNFT008",
  CUSTOMERID: "",
  POLICYNO: "",
  EMAILID: "",
  FIRSTNAME: "",
  LASTNAME: "",
  MOBILENO: "",
  SMSSHORTCODE: "",
  REMARKS: "",
  CATEGORY: "",
  FLSCASE: "",
  CASETYPE: "",
  CASESUBTYPE: "",
  CASESUBSUBTYPE: "",
  SOURCE: "ABC_Servicing_App",
  FTR: "",
  UDP1: "",
  UDP2: "",
  UDP3: "",
  UDP4: "",
  UDP5: "",
  ClientIPAddress: "",
  VoucherNo: "",
  VoucherDate: "",
  DocumentUrl: "",
  lstNomineeUpdate: [],
  POIOption1: "",
  POIOption2: "",
  POIOption3: "",
  Aadhar_number: "",
  Aadhar_Valid_Status: "",
  PAN_number: "",
  Pan_Valid_status: "",
  Address1: "",
  Address2: "",
  Address3: "",
  Area: "",
  City: "",
  Pincode: "",
  State: "",
  Country: "",
  Address_Valid_status: "",
  Account_number: "",
  Account_type: "",
  IPFSC_code: "",
  MICRCode: "",
  Bank_name: "",
  Branch_name: "",
  Bank_valid_status: "",
  TransactionId: "",
};

export let LiUpdateEmailCreateSampleReqBody = {
  ONBEHALFOF: "Customer",
  ADVISORCODE: "",
  CODE: "PNFT007",
  CUSTOMERID: "",
  POLICYNO: "",
  EMAILID: "",
  FIRSTNAME: "",
  LASTNAME: "",
  MOBILENO: "",
  SMSSHORTCODE: "",
  REMARKS: "",
  CATEGORY: "",
  FLSCASE: "",
  CASETYPE: "",
  CASESUBTYPE: "",
  CASESUBSUBTYPE: "",
  SOURCE: "ABC_Servicing_App",
  FTR: "",
  UDP1: "",
  UDP2: "",
  UDP3: "",
  UDP4: "",
  UDP5: "",
  ClientIPAddress: "",
  VoucherNo: "",
  VoucherDate: "",
  DocumentUrl: "",
  lstNomineeUpdate: [],
  POIOption1: "",
  POIOption2: "",
  POIOption3: "",
  Aadhar_number: "",
  Aadhar_Valid_Status: "",
  PAN_number: "",
  Pan_Valid_status: "",
  Address1: "",
  Address2: "",
  Address3: "",
  Area: "",
  City: "",
  Pincode: "",
  State: "",
  Country: "",
  Address_Valid_status: "",
  Account_number: "",
  Account_type: "",
  IPFSC_code: "",
  MICRCode: "",
  Bank_name: "",
  Branch_name: "",
  Bank_valid_status: "",
  TransactionId: "",
};

export const FAQDisclaimerTitle = "Disclaimer";

export const FAQDisclaimerContent =
  "The answers provided here are for informative purposes and may be subject to change, and ABHFL does not warrant their accuracy, adequacy, appropriacy, completeness or reliability. The User understands that nothing contained here is professional legal, commercial or investment advice, and shall not be binding on ABHFL. The User is advised to make investment decisions after conducting their own diligence and research.";
export const rateUsCmsContentData = <T_CmsRateContentVariables>{
  attributes: {
    title: "FINDING A FRIEND IN OUR APP?",
    subTitle: "Tell us how you're feeling in our company?",
    checkBox:
      "ABCD App team or it's research partners can reach me for suggestions on improving ABCD App experience",
    lowRatingDescription: "OH-OH! TELL US WHAT WENT WRONG",
    highRatingDescription: "Tell us about your experience",
    successTitle: "GOTCHA!",
    successMessage:
      "Thank you for sharing your input. We'll come back better than ever before",
  },
  id: 1,
};
export const policyRenewalEncryptionSecretKey = "5891233561234567 ";
