import { Box, Button, Typography } from "@mui/material";
import GetItOnPlayStore from "../../../assets/images/google-play-store.svg";
import GetItOnAppStore from "../../../assets/images/app-store-.svg";
import "./PolicyAndConditionsPage.scss";
import CellPhoneTower from "../../../assets/icons/cellphonetower.svg";
import { Colors } from "../../../styles/constant";
import {
  aboutUsTitle,
  privacyPolicyTitle,
  termsAndConditionsTitle,
} from "../../../utils/constants";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { T_FetchConfigData_ResponseDetails } from "../GeneralAppElements_Types";
import { FC } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  title?: string;
}

const PolicyAndConditionsPage: FC<Props> = ({
  title = termsAndConditionsTitle,
}) => {
  const { state } = useLocation();
  const fetchConfigDataDetails = useAppSelector(
    (st) => st.GeneralAppElementsSlice?.masterData?.supportDataList || []
  );
  let finalPolicyAndTermsDescription: string = "";
  let finalPolicyAndTermsTitle: string =
    state && Object.keys(state).length ? state.title : title;

  const cmsData = useAppSelector(
    (st) => st.CmsSlice.CmsPageVariableData || []
  );

  const handlePolicyTermsPopup = (titleData: string) => {
    fetchConfigDataDetails.forEach(
      (configData: T_FetchConfigData_ResponseDetails) => {
        let finalOptionType;
        if (finalPolicyAndTermsTitle === termsAndConditionsTitle) {
          finalOptionType = termsAndConditionsTitle;
        } else if (titleData === privacyPolicyTitle) {
          finalOptionType = privacyPolicyTitle;
        } else if (titleData === aboutUsTitle) {
          finalOptionType = aboutUsTitle;
        } else {
          finalOptionType = termsAndConditionsTitle;
        }
        if (configData.optionType === finalOptionType) {
          finalPolicyAndTermsDescription =
            configData.content || configData.description;
        }
      }
    );
    if (cmsData) {
      let des =
        cmsData.find((i) => i.attributes.cmsType === titleData)?.attributes.text ??
        null;
      if (des) {
        finalPolicyAndTermsDescription = des;
      }
    }
  };
  handlePolicyTermsPopup(finalPolicyAndTermsTitle);
  const descriptionByParagraph = finalPolicyAndTermsDescription?.split("\n");

  return (
    <>
      <Box className="terms-conditions-page">
        <Box className="terms-conditions-header">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" fontWeight={500} color={"white"}>
              Do more with One ABC
            </Typography>
            <Button
              variant="contained"
              sx={{
                bgcolor: "white",
                borderRadius: "6.25rem",
                textTransform: "none",
                width: "8.5625rem",
                height: "1.75rem",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  variant="h6"
                  marginRight={".4063rem"}
                  color={Colors.red}
                  lineHeight=".975rem"
                >
                  Download The App
                </Typography>
                <img src={CellPhoneTower} alt="CellPhoneTower" />
              </Box>
            </Button>
          </Box>
        </Box>
        <Box className="terms-conditions-body">
          <Box>
            <Typography variant="h6" className="body-title">
              {finalPolicyAndTermsTitle}
            </Typography>
          </Box>
          <Box>
            {descriptionByParagraph &&
              descriptionByParagraph.map((paragraph) => (
                <Typography
                  key={paragraph}
                  className="body-description"
                  variant="body1"
                >
                  {paragraph}
                </Typography>
              ))}
          </Box>
        </Box>
        <Box className="terms-conditions-footer">
          <img src={GetItOnPlayStore} alt="GetItOnPlayStore" />
          <img src={GetItOnAppStore} alt="GetItOnAppStore" />
        </Box>
      </Box>
    </>
  );
};

export default PolicyAndConditionsPage;
