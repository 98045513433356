import { FC, useCallback, useState } from "react";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CallUsPopUp from "../CallUsPopUp/CallUsPopUp";
import EmailUsPopUp from "../EmailUsPopUp/EmailUsPopUp";
import CallUsIcon from "../../../assets/icons/PhoneIcon.svg";
import EmailIcon from "../../../assets/icons/EmailIcon.svg";
import CustomChatWithUs from "../../../components/CustomChatWithUs/CustomChatWithUs";

interface Props {
  open: boolean;
  handleOpen: Function;
  functionalityCode?: string;
  LobId?: string;
  showChatWithUsBtn?:boolean
}

const CallUs: FC<Props> = ({ 
  open, 
  handleOpen,
  functionalityCode = "",
  LobId="",
  showChatWithUsBtn = false
  }) => {
  const [isCallUsOpen, setIsCallUsOpen] = useState(false);
  const [isEmailUsOpen, setIsEmailUsOpen] = useState(false);
  const [showChatWithUs, setShowChatWithUs] = useState(false);
  const handleClosePopup = useCallback(() => {
    handleOpen(false);
  }, [handleOpen]);


  const handleCloseChat = useCallback(() => {
    setShowChatWithUs(false);
  }, []);

  const handleCallUsClick = useCallback(() => {
    setIsCallUsOpen(true);
  }, []);

  // useCallback for handleEmailUsClick
  const handleEmailUsClick = useCallback(() => {
    setIsEmailUsOpen(true);
  }, []);

   // useCallback for handleChatWithUsClick
   const handleChatWithUsClick = useCallback(() => {
    setShowChatWithUs(true);
  }, []);

  const renderSteps = () => {
    return (
      <Box className="need-help">
        <Typography
          fontSize="1.125rem"
          fontWeight={"600"}
          lineHeight={"1.35rem"}
          textAlign="center"
          color={"#333638"}
          marginTop={"1rem"}
        >
          Need any help?
        </Typography>

        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={"1.4375rem"}
          marginBottom={"1.875rem"}
        >
          <Box
            textAlign="center"
            onClick={handleEmailUsClick}
            style={{ cursor: "pointer" }}
          >
            <img
              src={EmailIcon}
              alt="Email Us Icon"
              style={{
                width: "2.5rem",
                height: "2.5rem",
                marginBottom: ".9375rem",
              }}
            />
            <Typography sx={{ fontWeight: "600" }}>Email Us</Typography>
          </Box>
          <Box
            textAlign={"center"}
            marginLeft={"2.375rem"}
            onClick={handleCallUsClick}
            style={{ cursor: "pointer" }}
          >
            <img
              src={CallUsIcon}
              alt="Call Us Icon"
              style={{
                width: "2.5rem",
                height: "2.5rem",
                marginBottom: ".9375rem",
              }}
            />
            <Typography sx={{ fontWeight: "600" }}>Call Us</Typography>
          </Box>
        </Box>
        {showChatWithUsBtn && <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"center"}
          marginTop={"1.4375rem"}
          marginBottom={"1.875rem"}
        >
          <Box
            textAlign={"center"}
            onClick={handleChatWithUsClick}
            style={{ cursor: "pointer" }}
          >
            <img
              src={CallUsIcon}
              alt="Call Us Icon"
              style={{
                width: "2.5rem",
                height: "2.5rem",
                marginBottom: ".9375rem",
              }}
            />
            <Typography sx={{ fontWeight: "600" }}>Chat With Us</Typography>
          </Box>
        </Box>}
        {isCallUsOpen && (
          <CallUsPopUp open={isCallUsOpen} handleOpen={setIsCallUsOpen} />
        )}
        {isEmailUsOpen && (
          <EmailUsPopUp open={isEmailUsOpen} handleOpen={setIsEmailUsOpen} />
        )}
         {showChatWithUs &&
          <CustomChatWithUs
           showIframe={showChatWithUs}
           onClose={handleCloseChat}
           functionalityCode={functionalityCode}
           LobId={LobId}/>}
      </Box>
    );
  };

  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      btnLabel={"Close"}
      className="add-callus-popup"
      btnStartIcon={<CloseIcon fontSize="small" />}
    >
      <Box className="add-callus-popup-content">{renderSteps()}</Box>
    </CustomPopup>
  );
};
export default CallUs;
