import {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  InternalAxiosRequestConfig,
} from "axios";
import {
  T_GenerateTokenRes,
  T_GetCustomerLobDetailsRes,
} from "../features/Feature_Login/Login_Types";
import { R_LiRoutes } from "../features/Feature_LifeInsurance/LifeInsurance_Routes";
import { R_HomeRoutes } from "../pages/Home/Home_Routes";
import { R_MfRoutes } from "../features/Feature_MutualFund/MutualFund_Routes";
import { R_SSRoutes } from "../features/Feature_StocksAndSecurities/Stocks&Securities_Routes";
import { R_HiRoutes } from "../features/Feature_HealthInsurance/HealthInsurance_Routes";
import { T_ProductCategoryOptions } from "../features/Feature_GeneralAppElements/GeneralAppElements_Types";
import { R_HLRoutes } from "../features/Feature_Homeloan/Homeloan_Routes";

export type AxiosFetchQuery = {
  url: string;
  method?: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
  body?: AxiosRequestConfig["data"];
  params?: AxiosRequestConfig["params"];
  headers?: AxiosRequestConfig["headers"];
};
export type T_ApiResponse<T> = {
  code: number;
  message: string;
  payload: T;
  uiDisplayMessage: string;
  uiDisplayTitle: string;
  rootCause?: string;
  errorCode?: string;
  config?: InternalAxiosRequestConfig;
  headers?: AxiosRequestHeaders;
};

export type T_UserProfile = {
  name?: string;
  pan?: string;
  dob?: string;
  mobileNumber?: string;
  emailId?: string;
};

export enum E_ChannelOptions {
  CSAPP = "CSAPP",
  SUPER_APP = "SUPER APP",
  ONE_ABC = "ABCAPP",
  SECONDARY_CHANNEL = "SECONDARY CHANNEL",
}

export type UserPayload = {
  uuid: string | undefined,
  linkedlobs: string,
  userName: string,
  iat: number,
  exp: number,
  functionalitycode: string,
  LOB : string
}

export type T_CsAppSliceState = {
  userDetails: (T_UserProfile & T_CustomerLobDetails) | undefined;
  tokenDetails: T_AuthToken | undefined;
  userAgent: T_UserAgent | undefined;
  traceId: string | undefined;
  deviceType: string | undefined;
  channel: E_ChannelOptions;
  platform: string;
  csUserId: string;
};

export type T_CsErrorSliceState = {
  error: T_ApiResponse<any> | Error | undefined;
  message: string | undefined;
  customTitle?: string;
  customSubTitle?: string;
  customButtonLabel?: string;
};

export type T_AuthToken = T_GenerateTokenRes;
export type T_LogoutInfoToBePassed = {
  token: string;
  supportingData: {
    mobile: string;
    destinationLobId: string;
    sourceLobId: string;
    functionalityCode: string;
    isOnBoarding: string;
    isExtensionRequired: string;
    pan: string;
    dob: string;
    email: string;
  };
};
export type T_UserAgent = {
  deviceId: string;
  platform: string;
  deviceType: string;
};

export type T_UserType = {
  id: number;
  email: string;
  password: string;
  name: string;
  role: string;
  avatar: string;
};

export type T_CustomerLobDetails = T_GetCustomerLobDetailsRes;

export type T_AnimationData = {
  v: string;
  fr: number;
  ip: number;
  op: number;
  w: number;
  h: number;
  nm: string;
  ddd: number;
  assets: any[];
  layers: any[];
};

export type T_Option = {
  label: string;
  value: string;
};
export const J_FunctionalityCode = [
  {
    funcCode: "01GAS",
    name: "ACCOUNT_STATEMENT",
    route: `${R_MfRoutes.mutualFund_AccountStatement}`,
    isDynamicPath: false,
    dynamicKeys: ["panNumber"],
    lobCode: ["01"],
  },
  {
    funcCode: "01CGS",
    name: "CAPITAL_GAIN_STATEMENT",
    route: `${R_MfRoutes.mutualFund_CapitalGains}`,
    isDynamicPath: false,
    dynamicKeys: ["panNumber"],
    lobCode: ["01"],
  },
  {
    funcCode: "01ELS",
    name: "EXIT_LOAD_STATEMENT",
    route: `${R_MfRoutes.mutualFund_ExitLoad}`,
    isDynamicPath: false,
    dynamicKeys: ["panNumber"],
    lobCode: ["01"],
  },
  {
    funcCode: "RDM",
    name: "REDEEM_STATEMENTS",
    route: `${R_MfRoutes.mutualFund_Details}`,
    isDynamicPath: false,
    dynamicKeys: [],
    lobCode: ["01"],
  },
  {
    funcCode: "STP",
    name: "STP",
    route: `${R_MfRoutes.mutualFund_Details}`,
    isDynamicPath: false,
    dynamicKeys: [],
    lobCode: ["01"],
  },
  {
    funcCode: "SWP",
    name: "SWP",
    route: `${R_MfRoutes.mutualFund_Details}`,
    isDynamicPath: false,
    dynamicKeys: [],
    lobCode: ["01"],
  },
  {
    funcCode: "SF1",
    name: "SF",
    route: `${R_MfRoutes.mutualFund_Details}`,
    isDynamicPath: false,
    dynamicKeys: [],
    lobCode: ["01"],
  },
  {
    funcCode: "BNK",
    name: "BNK",
    route: `${R_MfRoutes.mutualFund_Details}`,
    isDynamicPath: false,
    dynamicKeys: [],
    lobCode: ["01"],
  },
  {
    funcCode: "OTM",
    name: "OTM",
    route: `${R_MfRoutes.mutualFund_Details}`,
    isDynamicPath: false,
    dynamicKeys: [],
    lobCode: ["01"],
  },
  {
    funcCode: "TS",
    name: "TS",
    route: `${R_MfRoutes.mutualFund_Details}`,
    isDynamicPath: false,
    dynamicKeys: [],
    lobCode: ["01"],
  },
  {
    funcCode: "24MPD",
    name: "MY_PORTFOLIO",
    route: `${R_SSRoutes.details_Portfolio}`,
    isDynamicPath: false,
    dynamicKeys: ["mobileNo"],
    lobCode: ["24"],
  },
  {
    funcCode: "24MD",
    name: "SS_MY_DOCUMENT",
    route: `${R_SSRoutes.details_Portfolio_MyDocuments}`,
    isDynamicPath: false,
    dynamicKeys: ["mobileNo"],
    lobCode: ["24"],
  },
  {
    funcCode: "24SA",
    name: "SS_STATEMENT_OF_ACCOUNTS",
    route: `${R_SSRoutes.account_statement}`,
    isDynamicPath: false,
    dynamicKeys: ["mobileNo"],
    lobCode: ["24"],
  },
  {
    funcCode: "24ST",
    name: "SS_STATEMENT_OF_TRANSACTION",
    route: `${R_SSRoutes.details_Portfolio_MyDocuments}`,
    isDynamicPath: false,
    dynamicKeys: ["mobileNo"],
    lobCode: ["24"],
  },
  {
    funcCode: "24CG",
    name: "SS_CAPITAL_GAIN",
    route: `${R_SSRoutes.details_Portfolio_MyDocuments}`,
    isDynamicPath: false,
    dynamicKeys: ["mobileNo"],
    lobCode: ["24"],
  },
  {
    funcCode: "24CN",
    name: "SS_CONTRACT_NOTE",
    route: `${R_SSRoutes.contact_notes}`,
    isDynamicPath: false,
    dynamicKeys: ["mobileNo"],
    lobCode: ["24"],
  },
  {
    funcCode: "02MS",
    name: "LI_DOWNLOAD_STATEMENTS",
    route: `${R_LiRoutes.details_PolicyNumber}`,
    isDynamicPath: true,
    dynamicKeys: ["policyNumber"],
    lobCode: ["02"],
  },
  {
    funcCode: "06DMR",
    name: "HI_MY_MEDICAL_REPORTS",
    route: `${R_HiRoutes.details_PolicyNumber_MedicalReports}`,
    isDynamicPath: true,
    dynamicKeys: ["policyNumber"],
    lobCode: ["06"],
  },
  {
    funcCode: "01HO",
    name: "MF_LANDING_PAGE_HOLDINGS",
    route: `${R_HomeRoutes.home}`,
    isDynamicPath: true,
    dynamicKeys: ["panNumber"],
    lobCode: ["01"],
  },
  {
    funcCode: "TC",
    name: "INAPP_TAX_CERTI",
    route: `${R_LiRoutes.details_PolicyNumber_TaxCertificate}`,
    isDynamicPath: true,
    dynamicKeys: ["policyNumber"],
    isEncrypted: true,
    lobCode: ["02"],
  },
  {
    funcCode: "PAC",
    name: "INAPP_POLICY_ACCOUNT_STATEMENT",
    route: `${R_LiRoutes.details_PolicyNumber_PolicyAccountStatement}`,
    isDynamicPath: true,
    dynamicKeys: ["policyNumber"],
    isEncrypted: true,
    lobCode: ["02"],
  },
  {
    funcCode: "PR",
    name: "INAPP_PREMIUM_RECEIPTS",
    route: `${R_LiRoutes.details_PolicyNumber_PremiumReceipts}`,
    isDynamicPath: true,
    dynamicKeys: ["policyNumber"],
    isEncrypted: true,
    lobCode: ["02"],
  },
  {
    funcCode: "04DR",
    name: "IN_APP_DISBURSEMENT_REQUEST",
    route: `${R_HLRoutes.disbursement_request}`,
    isDynamicPath: true,
    dynamicKeys: ["accountNumber"],
    lobCode: ["04"],
  },
  {
    funcCode: "04EP",
    name: "IN_APP_EMI_PAYMENT",
    route: `${R_HLRoutes.emi_payment}`,
    isDynamicPath: true,
    dynamicKeys: ["accountNumber"],
    lobCode: ["04"],
  },
  {
    funcCode: "04CEN",
    name: "IN_APP_E_NACH",
    route: `${R_HLRoutes.change_enach}`,
    isDynamicPath: true,
    dynamicKeys: ["accountNumber"],
    lobCode: ["04"],
  },
  {
    funcCode: "04DIC",
    name: "IN_APP_DOWNLOAD_INTEREST_CERTIFICATE",
    route: `${R_HLRoutes.interest_certificate}`,
    isDynamicPath: true,
    dynamicKeys: ["accountNumber"],
    lobCode: ["04"],
  },
  {
    funcCode: "04LDQSD",
    name: "IN_APP_LOAN_DETAILS_QUICK_STATEMENT_DOC",
    route: `${R_HLRoutes.home_loan_details}`,
    isDynamicPath: true,
    dynamicKeys: ["accountNumber"],
    lobCode: ["04"],
  },
  {
    funcCode: "04LD",
    name: "IN_APP_LOAN_DETAILS",
    route: `${R_HLRoutes.home_loan_details}`,
    isDynamicPath: true,
    dynamicKeys: ["accountNumber"],
    lobCode: ["04"],
  },
  {
    funcCode: "04DSA",
    name: "IN_APP_DOWNLOAD_STATEMENT_ACCOUNT",
    route: `${R_HLRoutes.account_statement}`,
    isDynamicPath: true,
    dynamicKeys: ["accountNumber"],
    lobCode: ["04"],
  },
  {
    funcCode: "04SR",
    name: "IN_APP_HOME_LOAN_SERVICE_REQUEST",
    route: `${R_HLRoutes.home_loan_service_request}`,
    isDynamicPath: true,
    dynamicKeys: ["accountNumber"],
    lobCode: ["04"],
  },
  {
    funcCode: "D",
    name: "Dashboard",
    route: R_HomeRoutes.home,
    isDynamicPath: false,
    dynamicKeys: [],
    lobCode: ["01", "02", "03", "04", "06", "24"],
  },
  //   {
  //   PayPremium: "PP",
  //   Setup: "E-NACH/ESI	ENACH / SIRE",
  //   ReinstatePolicy: "RI",
  //   ChangePaymentFrequency: "MC",
  //   ApplyForloan: "LA",
  //   Repayloan: "LR",
  //   Fundswitch_Premium_Redirection: "FS",
  //   AppTracker: "RST",
  //   ServiceTracker: "ST",
  //   FileClaim: "CI",
  //   TrackClaim: "CT",
  //   OpenEInsuranceAccount: "EI",
  //   SurvivalCertificate: "SC",
  //   Dashboard: "D",
  //   AccountStatement: "A",
  //   InstaRevival: "I",
  //   AdditionofRider_SumAssured: "R",
  //   LinkProduct: "PL",
  //   INAPP_TAX_CERTI: "TC",
  // }
];
export enum E_CampaignIdentifierKeys {
  utm_source = "utm_source",
  utm_medium = "utm_medium",
  utm_campaign = "utm_campaign",
  utm_term = "utm_term",
  utm_content = "utm_content",
  utm_id = "utm_id",
  utm_adid = "utm_adid",
  utm_adgroup = "utm_adgroup",
}
export type T_CampaignHeaders = {
  key: string;
  value: string;
};

export type T_LiRouteState = {
  policyNumber: string;
};
export type T_ErrorEventHandlerState = {
  onClick: undefined | Function;
};
export type T_HiRouteState = {
  policyNumber: string;
};

export type PolicyOrAccountSelectionData = {
  isOpen: boolean;
  options: string[];
  redirectionUrl: string;
  lobDetails: T_ProductCategoryOptions | null;
  functionalityCode?: string;
};
