import MutualFundGuard from "./MutualFundGuard";
import { lazy, Suspense } from "react";
import LoadingInstant from "../../pages/Utilities/LoadingInstant";

export enum R_MfRoutes {
  mutualFund_BaseUrl = "/mutual-fund",
  mutualFund_Details = "/mutual-fund/details",
  mutualFund_CapitalGains = "/mutual-fund/capital-gains",
  mutualFund_AccountStatement = "/mutual-fund/account-statement",
  mutualFund_ExitLoad = "/mutual-fund/exit-load-statement",
}
const FolioDetails = lazy(() => import("./FolioDetails/FolioDetails"));
const CapitalGainStatement = lazy(
  () => import("./CapitalGainStatement/CapitalGainStatement")
);
const AccountStatement = lazy(
  () => import("./AccountStatement/AccountStatement")
);
const ExitLoadStatement = lazy(
  () => import("./ExitLoadStatement/ExitLoadStatement")
);
const MutualFundRoutesFn = () => {
  return [
    {
      path: R_MfRoutes.mutualFund_BaseUrl,
      element: <MutualFundGuard />,
      children: [
        {
          path: R_MfRoutes.mutualFund_Details,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <FolioDetails />
            </Suspense>
          ),
        },
        {
          path: R_MfRoutes.mutualFund_CapitalGains,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <CapitalGainStatement />
            </Suspense>
          ),
        },
        {
          path: R_MfRoutes.mutualFund_AccountStatement,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <AccountStatement />
            </Suspense>
          ),
        },
        {
          path: R_MfRoutes.mutualFund_ExitLoad,
          element: (
            <Suspense fallback={<LoadingInstant />}>
              <ExitLoadStatement />
            </Suspense>
          ),
        },
      ],
    },
  ];
};
export { MutualFundRoutesFn };
