import { Outlet, useNavigate } from "react-router-dom";
import {
  useLazyGetCustomerLobDetailsQuery,
  useLazyGetInternalTokenQuery,
} from "../../features/Feature_Login/Login_Api";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import CsLocalStorageUtils from "../../utils/CsLocalStorageUtils";
import { setError } from "../../redux-store/reducers/CsErrorSlice";
import {
  saveCsUserId,
  setCustomerAndLobDetails,
  setLongSessionAuthToken,
} from "../../redux-store/reducers/CsAppSlice";
import { R_LoginRoutes } from "../../features/Feature_Login/Login_Routes";
import { updateLoadingState } from "../../features/Feature_Loading/Loading_Slice";
import { R_HomeRoutes } from "../../pages/Home/Home_Routes";
import { updateScreenTag } from "../../features/Feature_Login/Login_Slice";
import {
  E_ChannelOptions,
  J_FunctionalityCode,
  T_ApiResponse,
  T_CustomerLobDetails,
} from "../../globalTypes/GlobalTypes";
import { R_OnBoardingRoutes } from "../../features/Feature_OnBoarding/OnBoarding_Routes";
import CsSessionStorageUtils from "../../utils/CsSessionStorageUtils";
import { LoginScreenValues } from "../../features/Feature_Login/Feature_Login";
import { API_STATUS_CODE } from "../../utils/ApiEndpoints";
import { isEmpty } from "lodash";
import { useLazyLogoutApiQuery } from "../../features/Feature_GeneralAppElements/GeneralAppElements_Api";
import { T_GetCustomerLobDetailsRes } from "../../features/Feature_Login/Login_Types";
import CustomMultipleSessionPopUp from "../../components/CustomMultipleSessionPopUp/CustomMultipleSessionPopUp";
const AuthGuard = () => {
  const csAppSlice = useAppSelector((state) => state.CsAppSlice);
  const tokenDetails = csAppSlice.tokenDetails;
  const userDetails = csAppSlice.userDetails;
  const loginSlice = useAppSelector((state) => state.LoginSlice);
  const SsoSlice = useAppSelector((state) => state.SsoSlice.ssoData);
  const SecondaryChannelSlice = useAppSelector(
    (state) => state.SecondaryChannelSlice
  );
  const navigate = useNavigate();
  const [decidingNextRoute, setDecidingNextRoute] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupError, setPopupError] = useState<any>(null);
  const appDispatch = useAppDispatch();
  const [getCustomerLobDetails, { data: customerAndLobDetailsResponse }] =
    useLazyGetCustomerLobDetailsQuery();
  const [getInternalToken, { data: dataToken }] =
    useLazyGetInternalTokenQuery();
  const internalAuthToken = useAppSelector(
    (state) => state.CsAppSlice.tokenDetails?.internal
  );
  const [csLobDetails, setCsLobDetails] =
    useState<T_ApiResponse<T_GetCustomerLobDetailsRes>>();
  const [mobileNumber, setMobileNumber] = useState();
  const [getLogOutApi] = useLazyLogoutApiQuery();

  useEffect(() => {
    if (!internalAuthToken) {
      setDecidingNextRoute(true);
      return;
    }
    if (!userDetails?.customerAndLobDetails && loginSlice.isUserLoggedIn) {
      CsSessionStorageUtils.clear("ACTIVE_DASHBOARD_TAB");
      let mobileNoFromStorage = CsSessionStorageUtils.get("MOBILE");
      const mobile =
        csAppSlice.channel === E_ChannelOptions.SUPER_APP ||
        csAppSlice.channel === E_ChannelOptions.ONE_ABC
          ? SsoSlice.mobileNo
          : loginSlice.mobilenumber || mobileNoFromStorage;
      if (!mobile) {
        gotoLogin();
        return;
      }
      setMobileNumber(mobile);
      appDispatch(updateLoadingState(true));
      getCustomerLobDetails({ mobile: mobile })
        .then((response) => {
          setCsLobDetails(response.data);
          setDecidingNextRoute(true);
          if (
            response.error ||
            !response.data?.payload?.customerAndLobDetails
          ) {
            showError(new Error("error"));
            gotoLogin();
            return;
          }
          if (response.data?.code === API_STATUS_CODE.SUCCESS) {
            appDispatch(saveCsUserId(response.data.payload.CS_USER_ID));
            if (csAppSlice.tokenDetails?.internal.longSessionAuthToken) {
              navigateToUrl(response.data?.payload);
              return;
            }
            getInternalTokenApi(response.data);
          } else {
            showError(new Error(JSON.stringify(response.data)));
          }
        })
        .catch((err) => {
          showError(err);
        });
    } else if (!loginSlice.isUserLoggedIn) {
      gotoLogin();
    }
  }, [userDetails?.customerAndLobDetails, internalAuthToken]);

  const showError = (err: Error) => {
    appDispatch(setError({ error: err, message: err.message }));
  };

  const gotoLogin = () => {
    navigate(R_LoginRoutes.login, { replace: true });
  };

  const saveCsUserIdToLocalStorage = (csUserId: string) => {
    CsLocalStorageUtils.set("CS_USER_ID", csUserId);
  };

  const saveMobileToLocalStorage = (mobile: string) => {
    CsLocalStorageUtils.set("MOBILE", mobile);
  };

  const gotoNoHoldingsPage = () => {
    navigate(R_HomeRoutes.noHoldings, { replace: true });
    appDispatch(updateLoadingState(false));
  };

  const gotoOnBoardingPage = () => {
    appDispatch(updateScreenTag(LoginScreenValues.enterUserDetailScreen));
    navigate(R_OnBoardingRoutes.onboarding, { replace: true });
  };

  const navigateToUrl = (
    userDetailsValue: T_CustomerLobDetails | undefined
  ) => {
    setDecidingNextRoute(false);
    if (!isEmpty(userDetailsValue?.customerAndLobDetails)) {
      if (!userDetailsValue?.IS_USER_REGISTERED) {
        const anyLobsExists = userDetailsValue?.customerAndLobDetails.some(
          (r) => {
            return r.LOBID;
          }
        );
        if (anyLobsExists) {
          gotoOnBoardingPage();
        } else if (!anyLobsExists) {
          gotoNoHoldingsPage();
        }
      } else if (csAppSlice.channel === E_ChannelOptions.SECONDARY_CHANNEL) {
        navigate(SecondaryChannelSlice.constructedAppUrl, {
          state: SecondaryChannelSlice.dynamicState,
          replace: true,
        });
      } else if (
        window.location.pathname.includes(R_OnBoardingRoutes.onboarding)
      ) {
        navigate(R_HomeRoutes.home, { replace: true });
      }
    } else {
      gotoNoHoldingsPage();
    }
  };

  const logoutPreviousSession = async () => {
    setShowPopup(false);
    setPopupError(false);
    appDispatch(updateLoadingState(true));
    await getLogOutApi({
      csUserId: "" + userDetails?.CS_USER_ID,
    });
    if (csLobDetails) {
      getInternalTokenApi(csLobDetails);
    }
    // appDispatch(updateLoadingState(false));
  };

  const getInternalTokenApi = (
    csLOBDe: T_ApiResponse<T_GetCustomerLobDetailsRes>
  ) => {
    appDispatch(updateLoadingState(true));
    if (csLOBDe?.payload.CS_USER_ID) {
      getInternalToken(csLOBDe?.payload.CS_USER_ID).then((tokenResponse) => {
        if (tokenResponse.data?.code === API_STATUS_CODE.SUCCESS) {
          if (!csLOBDe?.payload) {
            gotoNoHoldingsPage();
            return;
          }
          if (
            !isEmpty(SsoSlice?.functionality) &&
            csAppSlice.channel === E_ChannelOptions.ONE_ABC
          ) {
            const funcObj = J_FunctionalityCode.find(
              (j) => j.funcCode === (SsoSlice?.functionality || "")
            );
            let filteredCustomerLobArr = { ...csLOBDe.payload };
            if (funcObj && !isEmpty(csLOBDe.payload.customerAndLobDetails)) {
              filteredCustomerLobArr.customerAndLobDetails =
                csLOBDe.payload.customerAndLobDetails.filter((c) =>
                  funcObj.lobCode.includes(c.LOB_CODE)
                );
            }
            appDispatch(setCustomerAndLobDetails(filteredCustomerLobArr));
          } else {
            appDispatch(setCustomerAndLobDetails(csLOBDe?.payload));
          }
          saveCsUserIdToLocalStorage(csLOBDe?.payload.CS_USER_ID);
          // saveMobileToLocalStorage(mobileNumber);
          appDispatch(
            setLongSessionAuthToken(tokenResponse.data.payload.authToken)
          );
          navigateToUrl(csLOBDe.payload);
        } else if (
          tokenResponse.data?.code === API_STATUS_CODE.BUSINESS_ERROR
        ) {
          appDispatch(updateLoadingState(false));
          setPopupError(true);
          setShowPopup(true);
        } else if (tokenResponse.error) {
          showError(tokenResponse.error as Error);
          gotoLogin();
          return;
        }
      });
    }
    // appDispatch(updateLoadingState(false));
  };

  return (
    <>
      {popupError && (
        <CustomMultipleSessionPopUp
          gotoLogin={() => gotoLogin()}
          logoutPreviousSession={() => logoutPreviousSession()}
          open={showPopup}
        />
      )}
      {!userDetails?.customerAndLobDetails ||
      !tokenDetails?.internal.longSessionAuthToken ||
      decidingNextRoute ? (
        <></>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default AuthGuard;
