import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import _ from "lodash";
import "./LogOutPopUp.scss";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import {
  T_AnimationData,
  T_CustomerLobDetails,
  T_LogoutInfoToBePassed,
  T_UserProfile,
} from "../../../globalTypes/GlobalTypes";
import { useLazyLogoutApiQuery } from "../GeneralAppElements_Api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import { useLocation } from "react-router-dom";
import ExclamationMarkGif from "../../../assets/gif/Exclamation_Mark.json";
import { getLogoutInfo } from "../../../utils/helper";
import { T_GenerateTokenRes } from "../../Feature_Login/Login_Types";

interface Props {
  open: boolean;
  handleOpen: Function;
}

const LogOutPopUp: FC<Props> = ({ open, handleOpen }) => {
  const tokenDetails = useAppSelector((state) => state.CsAppSlice.tokenDetails);
  const location = useLocation();
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const dispatch = useAppDispatch();

  const [getLogOutApi] = useLazyLogoutApiQuery();

  const handleClosePopup = () => {
    handleOpen(false);
  };

  const logoutEvent = (event: any) => {
    event.preventDefault();
    getLogOutApi({
      csUserId: "" + userDetails?.CS_USER_ID,
    }).then(async (res) => {
      const logoutExtraInfo = getLogoutInfo(
        tokenDetails as T_GenerateTokenRes,
        userDetails as T_UserProfile & T_CustomerLobDetails
      );
      dispatch({
        type: "USER_LOGOUT",
        payload: logoutExtraInfo,
      });
    });
  };

  const renderSteps = () => {
    return (
      <Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingTop={"1rem"}
        >
          <CommonGifAnimation
            width="20%"
            animationData={ExclamationMarkGif as T_AnimationData}
          />
        </Box>
        <Box marginTop="1.375rem">
          <Typography textAlign="center" variant="h5">
            ARE YOU SURE YOU WANT TO LOGOUT?
          </Typography>
          <Typography textAlign="center" marginTop="1.5rem">
            Tap "Logout" to continue or "Not now" to go back
          </Typography>

          <Button
            fullWidth
            variant="contained"
            sx={{ marginTop: "1.5rem" }}
            className="continue-btn"
            onClick={logoutEvent}
          >
            Logout
          </Button>
          <Typography
            textAlign="center"
            color="#c7222a"
            variant="h5"
            marginTop="1.125rem"
            onClick={handleClosePopup}
            sx={{
              "&:hover": {
                cursor: `pointer`,
              },
            }}
          >
            Not now
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      className="logout-popup"
    >
      <Box>{renderSteps()}</Box>
    </CustomPopup>
  );
};

export default LogOutPopUp;
