import { CSApiService } from "../../redux-store/CSApiService";
import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import {
  T_StockDashboardDetails_Response,
  T_StatementOfTransaction_ReqPayload,
  T_CapitalGain_ReqPayload,
  T_StatementOfAccount_ReqPayload,
  T_StockDashboardDetails_Request
} from "./Stockc&Securities_Types";
import {
  API_FetchPortfolio,
  API_DownloadStatementOfTransaction,
  API_CapitalGainStatementDownload,
  API_DownloadStatementOfAccount,
  API_DownloadContractNote
} from "../../utils/ApiEndpoints";

export const StocksAndSecuritiesApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchPortfolioData: builder.query<
      T_ApiResponse<T_StockDashboardDetails_Response[]>,
      T_StockDashboardDetails_Request
    >({
      query: (requestBody) => ({
        url: `${API_FetchPortfolio}`,
        method: "POST",
        body:requestBody
      }),
    }),
  downalodStatementOfTransaction: builder.query<
    T_ApiResponse<string>,
    T_StatementOfTransaction_ReqPayload
  >({
    query: (statementBody) => ({
      url: API_DownloadStatementOfTransaction,
      method: "POST",
      body: statementBody
    }),
  }),
  downloadGainCapitalStatement: builder.query<
  T_ApiResponse<string>,
  T_CapitalGain_ReqPayload
>({
  query: (statementBody) => ({
    url: API_CapitalGainStatementDownload,
    method: "POST",
    body: statementBody
  }),
  }),
  downloadStatementOfAccounts: builder.query<
    T_ApiResponse<string>,
    T_StatementOfAccount_ReqPayload
  >({
    query: (statementBody) => ({
      url: API_DownloadStatementOfAccount,
      method: "POST",
      body: statementBody
    }),
  }),
  downloadContractNote: builder.query<
  T_ApiResponse<string>,
  T_StatementOfAccount_ReqPayload
>({
  query: (statementBody) => ({
    url: API_DownloadContractNote,
    method: "POST",
    body: statementBody
  }),
}),
})
});

export const {
  useLazyFetchPortfolioDataQuery,
  useLazyDownalodStatementOfTransactionQuery,
  useLazyDownloadGainCapitalStatementQuery,
  useLazyDownloadStatementOfAccountsQuery,
  useLazyDownloadContractNoteQuery
} = StocksAndSecuritiesApi;
