import React, { FC } from "react";
import { Box, Typography, Grid, Button, Stack } from "@mui/material";
import activeIcon from "../../assets/icons/active-icon.png";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./AddManuallySuccessBody.scss";
import CommonGifAnimation from "../../components/CustomGifAnimation/CommonGifAnimation";
import successGif from "../../assets/gif/Success.json";
import { Colors } from "../../styles/constant";
import CustomLink from "../../components/CustomLink/CustomLink";
import { defaultEmptyFun } from "../../utils/helper";
import { T_PolicyInsured } from "../../features/Feature_HealthInsurance/HealthInsurance_Types";
import {
  currencyConvertor,
  getHiMembersInsured,
  getHiPolicyStatus,
} from "../../utils/conversionUtils";
import dayjs from "dayjs";

interface Props {
  title: string;
  goHomePageHandler: any;
  addAnotherHoldingHandler: Function;
  data?: T_PolicyInsured;
}

const AddManuallySuccessBody: FC<Props> = ({
  title = "",
  goHomePageHandler = defaultEmptyFun,
  addAnotherHoldingHandler = defaultEmptyFun,
  data,
}) => {
  return (
    <Box className="add-manually-success-body">
      <Box height="13.5625rem">
        <CommonGifAnimation animationData={successGif} width="16.875rem" />
      </Box>
      <Box className="health-success-message">
        <Typography variant="h3" color={Colors.red}>
          {title}
        </Typography>
        <Typography variant="h3" marginTop={"0.5rem"}>
          {title === "Health Insurance"
            ? "added successfully!"
            : "Policy added successfully!"}
        </Typography>
        {data && (
          <Typography className="lob-label" variant="h5" padding=".625rem">
            {title}
          </Typography>
        )}
      </Box>
      {data && (
        <Box className="policy-detail">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              margin: "0rem",
              backgroundColor: "#F7F7F7",
              border: ".125rem ",
              borderTopRightRadius: "1.5625rem",
              borderTopLeftRadius: "1.5625rem",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              margin={".9375rem .625rem"}
              sx={{
                backgroundColor: "#F7F7F7",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={400}
                sx={{
                  color: "#4C4C4C",
                }}
              >
                Policy no.
              </Typography>
              <Typography variant="h6" color={Colors.textGray}>
                {data?.PolicyDetail.PolicyNumber}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              marginRight="0.9375rem"
              gap=".25rem"
            >
              <span
                className="circle-icon"
                style={{
                  backgroundColor:
                    data?.PolicyDetail.PolicyStatus === "IF"
                      ? title === "Health Insurance"
                        ? Colors.red
                        : Colors.green
                      : Colors.textGray,
                }}
              ></span>
              <Typography
                color={
                  data?.PolicyDetail.PolicyStatus === "IF"
                    ? title === "Health Insurance"
                      ? Colors.red
                      : Colors.green
                    : Colors.textGray
                }
                variant="h6"
              >
                {getHiPolicyStatus(data?.PolicyDetail.PolicyStatus)}
              </Typography>
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={6}>
              <Box sx={{ margin: ".625rem" }}>
                <Typography
                  variant="h6"
                  fontWeight={400}
                  sx={{
                    color: Colors.descriptionGray,
                  }}
                >
                  Sum assured
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    lineHeight: "1.35rem",
                    color: "#2E2E2E",
                  }}
                >
                  {data?.PolicyDetail.SumInsured}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ margin: ".625rem" }}>
                <Typography
                  sx={{
                    fontSize: ".75rem",
                    fontWeight: "400",
                    lineHeight: "1.05rem",
                    color: "#797979",
                    textAlign: "right",
                  }}
                >
                  {"Members insured"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    lineHeight: "1.35rem",
                    color: "#2E2E2E",
                    textAlign: "right",
                  }}
                >
                  {getHiMembersInsured(data?.InsuredDetail || [])}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box sx={{ margin: ".625rem" }}>
                <Typography
                  sx={{
                    fontSize: ".75rem",
                    fontWeight: "400",
                    lineHeight: "1.05rem",
                    color: "#797979",
                  }}
                >
                  {"Premium due date"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    lineHeight: "1.35rem",
                    color: "#2E2E2E",
                  }}
                >
                  {data?.PolicyDetail.ValidTo
                    ? dayjs(data?.PolicyDetail.ValidTo).format("DD/MM/YYYY")
                    : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={7}>
              <Box sx={{ margin: ".625rem" }}>
                <Typography
                  sx={{
                    fontSize: ".75rem",
                    fontWeight: "400",
                    lineHeight: "1.05rem",
                    color: "#797979",
                    textAlign: "right",
                  }}
                >
                  {"Premium due amount"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    lineHeight: "1.35rem",
                    color: title === "Health Insurance" ? "#D34528" : "#2E2E2E",
                    textAlign: "right",
                  }}
                >
                  {currencyConvertor(data?.PolicyDetail.AnnualPremium)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box
        marginTop="1.25rem"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Button
          variant="contained"
          fullWidth
          sx={{
            marginBottom: "1rem",
          }}
          onClick={goHomePageHandler}
        >
          Go to HomePage
        </Button>
        <CustomLink
          title="Add another holding"
          onClick={addAnotherHoldingHandler}
        />
      </Box>
    </Box>
  );
};

export default AddManuallySuccessBody;
