import {
  combineReducers,
  configureStore,
  PayloadAction,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  CSApiService,
  CSApiServiceReducer,
  CSApiServiceStoreKey,
} from "./CSApiService";
import {
  LifeInsuranceReducer,
  LifeInsuranceSliceKey,
} from "../features/Feature_LifeInsurance/LifeInsurance_Slice";
import {
  StocksSliceReducer,
  StocksSliceKey,
} from "../features/Feature_StocksAndSecurities/Stocks&Securities_Slice";
import { CsAppSliceKey, CsAppSliceReducer } from "./reducers/CsAppSlice";
import { CsErrorSliceKey, CsErrorSliceReducer } from "./reducers/CsErrorSlice";
import {
  GeneralAppElementsSliceKey,
  GeneralAppElementsReducer,
} from "../features/Feature_GeneralAppElements/GeneralAppElements_Slice";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import persistStore from "redux-persist/es/persistStore";
import {
  LoginSliceKey,
  LoginSliceReducer,
} from "../features/Feature_Login/Login_Slice";
import {
  LoadingSliceKey,
  LoadingSliceReducer,
} from "../features/Feature_Loading/Loading_Slice";
import {
  SsoSliceKey,
  SsoSliceReducer,
} from "../features/Feature_SSO/Sso_Slice";
import {
  MutualFundReducer,
  MutualFundSliceKey,
} from "../features/Feature_MutualFund/MutualFund_Slice";
import {
  HealthInsuranceReducer,
  HealthInsuranceSliceKey,
} from "../features/Feature_HealthInsurance/HealthInsurance_Slice";
import {
  SecondaryChannelSliceKey,
  SecondaryChannelSliceReducer,
} from "../features/Feature_SecondaryChannels/SecondaryChannel_Slice";
import {
  HomeLoanSliceSliceKey,
  HomeLoanReducer,
} from "../features/Feature_Homeloan/Homeloan_Slice";
import { propagateEvent } from "../utils/helper";
import { EVENT_HANDLER } from "../utils/constants";
import { CmsReducer, CmsKey } from "../features/Features_Cms/Feature_Cms_Slice";
import { T_LogoutInfoToBePassed } from "../globalTypes/GlobalTypes";

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  [CsAppSliceKey]: CsAppSliceReducer,
  [CsErrorSliceKey]: CsErrorSliceReducer,
  [CSApiServiceStoreKey]: CSApiServiceReducer,
  [LoadingSliceKey]: LoadingSliceReducer,
  [SsoSliceKey]: SsoSliceReducer,
  [SecondaryChannelSliceKey]: SecondaryChannelSliceReducer,
  [LoginSliceKey]: LoginSliceReducer,
  [GeneralAppElementsSliceKey]: GeneralAppElementsReducer,
  [LifeInsuranceSliceKey]: LifeInsuranceReducer,
  [MutualFundSliceKey]: MutualFundReducer,
  [HealthInsuranceSliceKey]: HealthInsuranceReducer,
  [StocksSliceKey]: StocksSliceReducer,
  [HomeLoanSliceSliceKey]: HomeLoanReducer,
  [CmsKey]: CmsReducer,
});

const appReducer = (
  state: any,
  action: PayloadAction<T_LogoutInfoToBePassed>
) => {
  if (action.type === "USER_LOGOUT") {
    // for all keys defined in your persistConfig(s)
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    if (action?.payload?.supportingData) {
      propagateEvent(EVENT_HANDLER.LOGOUT_EVENT, action.payload.supportingData);
    }
    window.open(window.location.origin, "_self");
    return rootReducer(undefined, action);
  } else if (action.type === EVENT_HANDLER.GO_TO_SUPER_APP_EVENT) {
    if (action?.payload?.supportingData) {
      propagateEvent(action.type, action.payload.supportingData);
    }
  }
  return rootReducer(state, action);
};

const rootPersistConfig = {
  key: "root",
  storage: storageSession,
};
const persistedReducer = persistReducer(rootPersistConfig, appReducer);

export const setupStore = (preloadedState?: RootState) => {
 return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(CSApiService.middleware),
  });
};

export const store = setupStore();

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof persistedReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
