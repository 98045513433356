import { FC, useEffect } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { tabViewPoint } from "../../utils/constants";
import AdaptiveHome from "./AdaptiveHome";
import Home from "./Home";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import { updateUserProfileDetails } from "../../redux-store/reducers/CsAppSlice";
import { useLazyFetchProfileDetailsQuery } from "../../features/Feature_GeneralAppElements/GeneralAppElements_Api";
import CsLocalStorageUtils from "../../utils/CsLocalStorageUtils";
import { updateLoadingState } from "../../features/Feature_Loading/Loading_Slice";

const Dashboard: FC = () => {
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const { width } = useWindowSize();
  const dispatch = useAppDispatch();
  const [getProfileDetailApi] = useLazyFetchProfileDetailsQuery();
  const tokenDetails = useAppSelector((state) => state.CsAppSlice.tokenDetails);

  useEffect(() => {
    if (tokenDetails) {
      CsLocalStorageUtils.set("SHOW_INTRO", true);
      dispatch(updateLoadingState(true));
      getProfileDetailApi(
        {
          csUserId: userDetails?.CS_USER_ID || "",
          mobile: "",
        },
        true
      ).then((res) => {
        dispatch(updateLoadingState(false));
        if (res.isSuccess) {
          dispatch(updateUserProfileDetails({ ...res.data?.payload?.[0] }));
          if (res.data?.payload?.[0]) {
            CsLocalStorageUtils.set(
              "MOBILE",
              res.data.payload?.[0].mobileNumber
            );
          }
        }
      });
    }
  }, [tokenDetails]);

  return width >= tabViewPoint ? <AdaptiveHome /> : <Home />;
};

export default Dashboard;