import { Box, Grid } from "@mui/material";
import EnterUserDetail from "./UserDetailEntry/EnterUserDetail";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { CustomCarousel } from "../../components/CustomCarousel/CustomCarousel";
import Navbar from "../../components/CustomNavbar/Navbar";
import { useAppDispatch } from "../../redux-store/CustomReduxHooks";
import { updateLoadingState } from "../Feature_Loading/Loading_Slice";
import { useEffect } from "react";
import { LoginScreenValues } from "../Feature_Login/Feature_Login";
import "./Feature_OnBoarding.scss";

const FeatureOnBoarding: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(updateLoadingState(false));
  }, []);
  function callComponentByStage(stage: LoginScreenValues) {
    if (stage === "enterUserDetailScreen") {
      return <EnterUserDetail />;
    } else {
      console.error(`Function ${stage} not found.`);
    }
  }
  return (
    <Box className="login-page">
      <Navbar />
      <Box className="carousel-box">
        <CustomCarousel />
      </Box>
      <Box className="cardlayout" marginTop="auto">
        <Box display="flex" justifyContent="center">
          <HorizontalRuleIcon className="header-logo" />
        </Box>
        <Grid container justifyContent={"center"} columnSpacing={{ md: 2 }}>
          <Grid item md={8}>
            {callComponentByStage(LoginScreenValues.enterUserDetailScreen)}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default FeatureOnBoarding;
