import { Box, Grid, Typography } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import "./InvestmentCard.scss";
import CustomShimmer from "../../../components/CustomShimmer/CustomShimmer";
import { currencyConvertor } from "../../../utils/conversionUtils";
import CustomLink from "../../../components/CustomLink/CustomLink";
import ArrowDownRedCircleIcon from "../../../assets/icons/arrow-down-red-circle.svg";
import ArrowUpGreenCircleIcon from "../../../assets/icons/arrow-up-green-circle.svg";
import { T_MFDashboardDetails } from "../MutualFund_Types";
import { isPositiveNumber, isZeroString } from "../../../utils/helper";
import CommonGifAnimation from "../../../components/CustomGifAnimation/CommonGifAnimation";
import NoMFSchemeSummaryGif from "../../../assets/gif/No_MF_Scheme_Summary.json";
import { T_AnimationData } from "../../../globalTypes/GlobalTypes";
import useWindowSize from "../../../hooks/useWindowSize";
import { tabViewPoint } from "../../../utils/constants";

interface Props {
  viewDetailsClick?: Function;
  onTryAgain?: React.MouseEventHandler<HTMLDivElement>;
  data?: T_MFDashboardDetails;
  isViewDetailsRequired?: boolean;
  isFolioDetailsLoading?: boolean;
  isFolioDetailsFetched?: boolean;
  isXIRRDetailsLoading?: boolean;
  tryAgainId?: string;
}

interface KeyPairValueProps {
  title: string;
  value?: string | null;
  percentageValue?: string | number;
  isLoading?: boolean;
  spacing?: number;
  isIconRequired?: boolean;
  isPositive?: boolean;
}

export const KeyPairValue: FC<KeyPairValueProps> = ({
  title,
  value,
  percentageValue,
  isLoading,
  spacing = 3.5,
  isIconRequired = false,
  isPositive = true,
}) => {
  const { width } = useWindowSize();

  return (
    <Grid item xs={spacing} display="flex" flexDirection="column">
      <Typography marginBottom="0.5rem" variant="h5" lineHeight="0.9875rem">
        {title}
      </Typography>
      {!isLoading ? (
        <Box display="flex" gap="0.25rem" alignItems={"center"}>
          <Typography variant="subtitle2">
            {value === null || value === "0.00" ? "0.00" : value || "----"}
            {percentageValue && (
              <span
                className={
                  (!isZeroString(percentageValue.toString()) &&
                    (isPositiveNumber(percentageValue)
                      ? "positive-value"
                      : "negative-value")) ||
                  ""
                }
              >
                {width >= tabViewPoint ? "" : <br />}({percentageValue}%)
              </span>
            )}
          </Typography>

          {isIconRequired && value && (
            <img
              src={isPositive ? ArrowUpGreenCircleIcon : ArrowDownRedCircleIcon}
              alt="arrow-circle-icon"
              className="arrow-circle-icon"
            />
          )}
        </Box>
      ) : (
        <CustomShimmer width="3rem" />
      )}
    </Grid>
  );
};

// export const KeyPairValue: FC<KeyPairValueProps> = ({
//   title,
//   value,
//   percentageValue,
//   isLoading,
//   spacing = 4,
//   isIconRequired = false,
//   isPositive = true,
// }) => {
//   return (
//     <Grid
//       item
//       xs={spacing}
//       display="flex"
//       flexDirection="column"
//       justifyContent="flex-start"
//       alignItems="flex-start"
//       className="key-pair-value"
//     >
//       <Box display="flex" flexDirection="column" alignItems="center" width="100%">
//         <Typography marginBottom="0.5rem" variant="h5" lineHeight="0.9875rem" className="key-pair-title">
//           {title}
//         </Typography>
//         <Box flexGrow={1} />
//         {!isLoading ? (
//           <Box display="flex" gap="0.25rem" alignItems="center" className="key-pair-value-text">
//             <Typography variant="subtitle2">
//               {value ? value : "----"}
//               {percentageValue && <span>({percentageValue}%)</span>}
//             </Typography>
//             {isIconRequired && value && (
//               <img
//                 src={
//                   isPositive
//                     ? ArrowUpGreenCircleIcon
//                     : ArrowDownRedCircleIcon
//                 }
//                 alt="arrow-circle-icon"
//                 className="arrow-circle-icon"
//               />
//             )}
//           </Box>
//         ) : (
//           <CustomShimmer width="3rem" />
//         )}
//       </Box>
//     </Grid>
//   );
// };

const InvestmentCard: FC<Props> = ({
  viewDetailsClick,
  onTryAgain,
  data,
  isViewDetailsRequired = true,
  isFolioDetailsFetched = true,
  isFolioDetailsLoading = false,
  isXIRRDetailsLoading = false,
  tryAgainId,
}) => {
  const absolute_return = useRef("");
  const pnl_value = useRef("");

  if (data?.CURRENT_VALUE && data?.INVESTED_AMOUNT) {
    const INT_CURRENT_VALUE = parseFloat(data.CURRENT_VALUE);
    const INT_INVESTED_AMOUNT = parseFloat(data.INVESTED_AMOUNT);
    if (!isNaN(INT_CURRENT_VALUE) && INT_CURRENT_VALUE != 0) {
      const net_profit = INT_CURRENT_VALUE - INT_INVESTED_AMOUNT;
      pnl_value.current = String(net_profit);

      absolute_return.current = String(
        ((net_profit * 100) / INT_INVESTED_AMOUNT).toFixed(2)
      );
    } else {
      if (INT_CURRENT_VALUE === 0 && INT_INVESTED_AMOUNT === 0) {
        pnl_value.current = "0.00";
        absolute_return.current = "0.00";
      }
    }
  }
  return (
    <Box className="investment-card">
      {(isFolioDetailsFetched || isFolioDetailsLoading) && (
        <Box
          display={"flex"}
          textAlign="start"
          gap="1rem"
          flexDirection="column"
        >
          <Grid
            container
            display={"flex"}
            textAlign={"start"}
            justifyContent="space-between"
          >
            <KeyPairValue
              title="Invested Amount"
              value={currencyConvertor(data?.INVESTED_AMOUNT)}
              isLoading={isFolioDetailsLoading}
            />
            <KeyPairValue
              title="Current Value"
              value={currencyConvertor(data?.CURRENT_VALUE)}
              isLoading={isFolioDetailsLoading}
            />

            <KeyPairValue
              title="Profit/Loss"
              value={
                pnl_value.current
                  ? `${currencyConvertor(pnl_value.current)}`
                  : null
              }
              isLoading={isFolioDetailsLoading}
            />
          </Grid>
          <Grid container display="flex" textAlign="start">
            <KeyPairValue
              title="XIRR"
              value={
                data?.XIRR_VALUE === null ||
                data?.XIRR_VALUE === "" ||
                data?.XIRR_VALUE === "0.00"
                  ? "0.00%"
                  : data?.XIRR_VALUE
                  ? `${data.XIRR_VALUE}%`
                  : null
              }
              spacing={4.25}
              isIconRequired={
                data?.XIRR_VALUE === "0.00" || !data?.XIRR_VALUE?.length
                  ? false
                  : true
              }
              isPositive={isPositiveNumber(data?.XIRR_VALUE || "")}
              isLoading={isXIRRDetailsLoading}
            />

            <KeyPairValue
              title="Absolute Return"
              value={
                absolute_return?.current ? `${absolute_return.current}%` : null
              }
              spacing={5}
              isIconRequired={
                absolute_return?.current === "0.00" ? false : true
              }
              isPositive={isPositiveNumber(absolute_return.current || "")}
              isLoading={isFolioDetailsLoading}
            />
          </Grid>
          {isViewDetailsRequired && !isFolioDetailsLoading && (
            <Box display="flex" justifyContent="flex-start">
              <CustomLink
                title="View details"
                titleSize={".75rem"}
                onClick={viewDetailsClick}
              />
            </Box>
          )}
        </Box>
      )}
      {!isFolioDetailsFetched && !isFolioDetailsLoading && (
        <Box className="details-not-fetched-error">
          <CommonGifAnimation
            width={"6.25rem"}
            animationData={NoMFSchemeSummaryGif as T_AnimationData}
          />
          <Typography className="details-not-fetched-error-text">
            Unable to load your data
          </Typography>
          <Box
            className="details-not-fetched-error-link"
            data-value={tryAgainId}
            onClick={onTryAgain}
          >
            Try Again
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InvestmentCard;
