import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import _ from "lodash";
import CustomPopup from "../../components/CustomPopup/CustomPopup";
import { Box, Typography, Button } from "@mui/material";
import AlertGif from "../../assets/gif/Exclamation_Mark.json";
import CommonGifAnimation from "../../components/CustomGifAnimation/CommonGifAnimation";
import {
  T_AnimationData,
  T_CustomerLobDetails,
  T_LogoutInfoToBePassed,
  T_UserProfile,
} from "../../globalTypes/GlobalTypes";
import {
  UI_IDLE_STATUS,
  UI_SESSION_TIMEOUT,
  UI_SESSION_TIMEOUT_PROMT,
  DEVICE_TYPE,
  EVENT_HANDLER,
} from "../../utils/constants";
import useDeviceDetection from "../../hooks/useDeviceDetection";

import "./IdleTimOut.scss";
import { getLogoutInfo, propagateEvent } from "../../utils/helper";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import { T_GenerateTokenRes } from "../../features/Feature_Login/Login_Types";

const IdleTimeOut = () => {
  const timeout = UI_SESSION_TIMEOUT;
  const promptBeforeIdle = UI_SESSION_TIMEOUT_PROMT;
  const [remaining, setRemaining] = useState<number>(timeout);
  const [state, setState] = useState<string>(UI_IDLE_STATUS.IDLE);
  const [isOpen, setOpen] = useState<boolean>(false);
  const deviceType = useDeviceDetection();
  const tokenDetails = useAppSelector((st) => st.CsAppSlice.tokenDetails);
  const userDetails = useAppSelector((st) => st.CsAppSlice.userDetails);
  const csUserId = useAppSelector(
    (st) => st.CsAppSlice.userDetails?.CS_USER_ID
  );
  const dispatch = useAppDispatch();

  const onIdle = () => {
    setState(UI_IDLE_STATUS.IDLE);
  };

  const onActive = () => {
    setState(UI_IDLE_STATUS.ACTIVE);
  };

  const onPrompt = () => {
    if (!isOpen) {
      setState(UI_IDLE_STATUS.PROMPTED);
      setOpen(true);
    }
  };

  const { getRemainingTime, reset, start, pause } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    startManually: true,
  });

  useEffect(() => {
    if (csUserId) {
      start();
    } else {
      pause();
    }
  }, [csUserId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
      if (remaining === 0) {
        doLogoutOperation();
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    let dataToBePassed = {
      mobile: !_.isEmpty(userDetails?.mobileNumber)
        ? userDetails?.mobileNumber
        : "",
      isExtensionRequired: "Yes",
    };
    propagateEvent(EVENT_HANDLER.EXTEND_SESSION_EVENT, dataToBePassed);
    setOpen(false);
    reset();
    start();
  };

  const doLogoutOperation = () => {
    reset();
    setOpen(false);
    const logoutExtraInfo = getLogoutInfo(
      tokenDetails as T_GenerateTokenRes,
      userDetails as T_UserProfile & T_CustomerLobDetails
    );
    dispatch({
      type: "USER_LOGOUT",
      payload: logoutExtraInfo,
    });
  };

  return !csUserId ? null : (
    <CustomPopup
      open={isOpen}
      handleOpen={setOpen}
      className={`custom-session-expire-popup ${
        deviceType === DEVICE_TYPE.WEB ? "web-view" : "mobile-view"
      }`}
    >
      <Box className="session-expire-wrapper">
        <CommonGifAnimation animationData={AlertGif as T_AnimationData} />
        <Typography
          variant="h3"
          textAlign={"center"}
          marginTop={"2rem"}
          id="testid"
        >
          This page is going to expire
        </Typography>
        {state === UI_IDLE_STATUS.PROMPTED && (
          <Typography variant="h5" textAlign={"center"} marginY={"1rem"}>
            Your session will expire in 30 seconds. Click continue if you want
            to extend this session.
          </Typography>
        )}
        {/* {(state === UI_IDLE_STATUS.IDLE || state === UI_IDLE_STATUS.ACTIVE) && (
          <Typography variant="h5" textAlign={"center"} marginY={"1rem"}>
            Please click on logout button to continue
          </Typography>
        )} */}
        {(state === UI_IDLE_STATUS.IDLE ||
          state === UI_IDLE_STATUS.PROMPTED) && (
          <Typography
            variant="h5"
            fontWeight={500}
            textAlign={"center"}
            marginBottom={"2rem"}
          >
            {" "}
            Session expires{" "}
            {remaining < 10
              ? `in 00:0${remaining}s`
              : `in 00:${remaining}s`}{" "}
          </Typography>
        )}
        {state === UI_IDLE_STATUS.ACTIVE && (
          <Typography
            variant="h5"
            fontWeight={500}
            textAlign={"center"}
            marginBottom={"2rem"}
          >
            {" "}
            Session expires in 00:{"00"}s{" "}
          </Typography>
        )}
        {state === UI_IDLE_STATUS.PROMPTED && (
          <Button fullWidth variant="contained" onClick={handleStillHere}>
            Extend Session
          </Button>
        )}
        {/* {(state === UI_IDLE_STATUS.IDLE || state === UI_IDLE_STATUS.ACTIVE) && (
          <Button fullWidth variant="contained" onClick={doLogoutOperation}>
            Logout
          </Button>
        )} */}
      </Box>
    </CustomPopup>
  );
};

export default IdleTimeOut;
