import { FC, useCallback, useEffect } from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Colors } from "../../styles/constant";
import CustomLink from "../CustomLink/CustomLink";
import "./CustomTermPlanCard.scss";
import {
  T_DashboardFundDetails,
  T_LIDashboardDetails,
  T_PolicyAllDetails,
} from "../../features/Feature_LifeInsurance/LifeInsurance_Types";
import {
  currencyConvertor,
  CurrencyFormat,
  getPolicyStatus,
  getPolicyType,
  getStatusColor,
} from "../../utils/conversionUtils";
import dayjs from "dayjs";
import {
  useLazyFetchAnnuityDetailsQuery,
  useLazyFetchPolicyDetailsQuery,
} from "../../features/Feature_LifeInsurance/LifeInsurance_Api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import CustomShimmer from "../CustomShimmer/CustomShimmer";
import emptyPolicy_FolderIcon from "../../assets/icons/empty_holding_foldericon.svg";
import {
  retryDashboardDetails,
  retrySecondaryDashboardDetails,
} from "../../features/Feature_LifeInsurance/LifeInsurance_Slice";
import { encryptString } from "../../utils/helper";

interface KeyPairValueProps {
  title: string;
  value?: string;
  isLoading?: boolean;
}

const KeyPairValue: FC<KeyPairValueProps> = ({ title, value, isLoading }) => {
  if (isLoading || value) {
    return (
      <Grid item xs={4}>
        <Typography marginBottom=".3125rem" variant="h5">
          {title}
        </Typography>
        {value ? (
          <Typography variant="subtitle2">{value}</Typography>
        ) : (
          <CustomShimmer width="3rem" />
        )}
      </Grid>
    );
  }
  return null;
};
interface Props {
  viewDetailsClick?: Function;
  data?: T_LIDashboardDetails;
  policyAllDetails?: T_PolicyAllDetails;
  isPolicyAllDetailsFetched?: boolean;
  isOtherHolding?: boolean;
  showNoHoldingImg?: boolean;
}

const TermPlanCard: FC<Props> = ({
  data,
  viewDetailsClick,
  policyAllDetails,
  isPolicyAllDetailsFetched,
  isOtherHolding = false,
  showNoHoldingImg = false,
}) => {
  const [
    fetchAnnuityDetails,
    { data: annuityDetails, isLoading: isAnnuityDetailsLoading },
  ] = useLazyFetchAnnuityDetailsQuery();
  const [
    fetchPolicyDetails,
    { data: policyDetails, isLoading: isPolicyDetailsLoading },
  ] = useLazyFetchPolicyDetailsQuery();
  const dashboardFundDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice.dashboardFundDetails
  );
  const dispatch = useAppDispatch();
  const selectedDashboardFundDetails = dashboardFundDetails.find(
    (item: T_DashboardFundDetails) => item.POLICY_NUMBER === data?.POLICY_NUMBER
  );

  const configuration = useAppSelector(
    (st) => st.CsAppSlice.tokenDetails?.configuration
  );
  const IV = configuration?.iv || "";
  const secretKey = configuration?.key || "";

  const retryForDashboardDetails = useCallback(() => {
    if (isOtherHolding) {
      dispatch(retrySecondaryDashboardDetails());
    } else {
      dispatch(retryDashboardDetails());
    }
  }, [retryDashboardDetails, retrySecondaryDashboardDetails]);

  useEffect(() => {
    if (data?.POLICY_TYPE === "ANNUITY") {
      const encryptedOwnerId = encryptString(
        JSON.stringify({ ClientId: data?.OWNER_ID }),
        IV,
        secretKey
      );
      fetchAnnuityDetails(encryptedOwnerId);
      fetchPolicyDetails(data?.POLICY_NUMBER, true);
    }
  }, []);

  const selectedAnnuityDetails =
    annuityDetails &&
    annuityDetails.payload.getSurvivalCertificate_Annuity?.find(
      (item) => item.Policy_Number === data?.POLICY_NUMBER
    );

  let policyTerm =
    parseInt(data?.POLICY_TERM || "") !== 0 ? data?.POLICY_TERM : "";
  let paymentTerm =
    parseInt(
      policyAllDetails?.Policy_Level_Details?.PREMIUM_PAYING_TERM || ""
    ) !== 0
      ? policyAllDetails?.Policy_Level_Details?.PREMIUM_PAYING_TERM
      : "";

  return (
    <Box className="term-plan-card-content">
      {showNoHoldingImg ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img width={"100px"} height={"100px"} src={emptyPolicy_FolderIcon} />
          <Typography
            variant="subtitle2"
            lineHeight={"18.2px"}
            textAlign={"center"}
            marginTop={"0.5rem"}
            marginBottom={"0.5rem"}
          >
            Unable to load your data
          </Typography>
          <Typography
            variant="h5"
            textAlign={"center"}
            onClick={retryForDashboardDetails}
            style={{ cursor: "pointer", color: "#C7222A" }}
          >
            Try Again
          </Typography>
        </div>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <ThumbUpIcon
                sx={{
                  color: Colors.textGray,
                  fontSize: "1.125rem",
                  marginRight: ".625rem",
                }}
              />
              <Typography color={Colors.textGray} variant="h4">
                {getPolicyType(data?.POLICY_TYPE || "")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap=".25rem">
              <span
                className="circle-icon"
                style={{
                  backgroundColor: getStatusColor(data?.POLICY_STATUS || ""),
                }}
              ></span>
              <Typography
                sx={{
                  color: getStatusColor(data?.POLICY_STATUS || ""),
                }}
                variant="h6"
              >
                {getPolicyStatus(data?.POLICY_STATUS || "")}
              </Typography>
            </Box>
          </Box>
          <Stack
            display="flex"
            marginTop=".625rem"
            justifyContent="space-between"
            rowGap={".625rem"}
          >
            <Box display="flex" alignItems="center">
              <Typography
                variant="h5"
                marginRight="0.3125rem"
                minWidth={"4.6875rem"}
              >
                Policy No.
              </Typography>
              <Typography variant="subtitle2">{data?.POLICY_NUMBER}</Typography>
            </Box>
            <Box display="flex" alignItems="flex-start">
              <Typography
                variant="h5"
                marginRight="0.3125rem"
                minWidth={"4.6875rem"}
              >
                Plan Name
              </Typography>
              <Typography variant="subtitle2">{data?.PLAN_NAME}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h5"
                marginRight="0.3125rem"
                minWidth={"4.6875rem"}
              >
                Start Date
              </Typography>
              <Typography variant="subtitle2">
                {data?.POLICY_ISSUANCE_DATE
                  ? dayjs(data?.POLICY_ISSUANCE_DATE).format("DD-MM-YYYY")
                  : "---"}
              </Typography>
            </Box>
          </Stack>
          <Divider sx={{ marginY: ".625rem" }} />
          <Grid container display="flex" rowGap=".625rem" textAlign="start">
            <KeyPairValue
              title="Sum Assured"
              value={currencyConvertor(data?.TOTAL_SUM_ASSURED)}
            />

            <KeyPairValue title="Policy Term" value={policyTerm} />

            <KeyPairValue
              title="Payment Term"
              isLoading={isPolicyAllDetailsFetched || !!paymentTerm}
              value={paymentTerm}
            />

            <KeyPairValue
              title="Premium"
              value={CurrencyFormat(data?.POLICYPREMIUM)}
            />

            <KeyPairValue
              title="Mode"
              isLoading={
                isPolicyAllDetailsFetched ||
                !!policyAllDetails?.Policy_Level_Details?.BILLING_MODE
              }
              value={policyAllDetails?.Policy_Level_Details?.BILLING_MODE}
            />

            <KeyPairValue
              title="Due Date"
              value={data?.NDD && dayjs(data?.NDD).format("DD MMM YYYY")}
            />

            {["ULIP", "ANNUITY"].includes(data?.POLICY_TYPE || "") && (
              <KeyPairValue
                title="Fund Value"
                isLoading={
                  !!CurrencyFormat(
                    selectedDashboardFundDetails?.FUNDVALUE || "0"
                  )
                }
                value={CurrencyFormat(
                  selectedDashboardFundDetails?.FUNDVALUE || "0"
                )}
              />
            )}
            {data?.POLICY_TYPE === "ANNUITY" && (
              <KeyPairValue
                title="Annuity Invested"
                isLoading={
                  isPolicyDetailsLoading ||
                  !!CurrencyFormat(
                    policyDetails?.payload.PolicyDetails.ANNUITY_INVESTED_VALUE
                  )
                }
                value={CurrencyFormat(
                  policyDetails?.payload.PolicyDetails.ANNUITY_INVESTED_VALUE
                )}
              />
            )}
            {data?.POLICY_TYPE === "ANNUITY" && (
              <KeyPairValue
                title="Annuity Amount"
                isLoading={
                  isAnnuityDetailsLoading ||
                  !!CurrencyFormat(selectedAnnuityDetails?.Annuity_Amount)
                }
                value={CurrencyFormat(selectedAnnuityDetails?.Annuity_Amount)}
              />
            )}
            {data?.POLICY_TYPE === "ANNUITY" && (
              <KeyPairValue
                title="Annuity Frequency"
                isLoading={!!data?.ANNUITY_FREQUENCY}
                value={data?.ANNUITY_FREQUENCY}
              />
            )}
            {["PAR", "NON PAR", "NON-PAR"].includes(data?.POLICY_TYPE || "") &&
              data?.BONUS_AMT && (
                <KeyPairValue
                  title="Accrued Benefits"
                  isLoading={!!CurrencyFormat(data?.BONUS_AMT)}
                  value={CurrencyFormat(data?.BONUS_AMT)}
                />
              )}
          </Grid>
          {!isOtherHolding && (
            <Box
              display="flex"
              marginTop=".9375rem"
              justifyContent="flex-start"
            >
              <CustomLink
                title="View details"
                titleSize={".875rem"}
                onClick={viewDetailsClick}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TermPlanCard;
