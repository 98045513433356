import { FC, Fragment, useEffect, useState } from "react";
import { T_CustomerLobDetailObj } from "../../Feature_Login/Login_Types";
import {
  T_LIDashboardDetails,
  T_PolicyAllDetails,
} from "../LifeInsurance_Types";
import { isArray, isEmpty, uniqBy } from "lodash";
import {
  useLazyFetchDashboardDetailsQuery,
  useLazyFetchPolicyAllDetailsQuery,
} from "../LifeInsurance_Api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import CsSessionStorageUtils from "../../../utils/CsSessionStorageUtils";
import { LiLobCode } from "../../../utils/constants";
import { getPolicyStatus } from "../../../utils/conversionUtils";
import {
  setLiDashboardDetails,
  setLiDashboardFundDetails,
  setLiPolicyAllDetails,
  setLiSecondaryDashboardFundDetails,
  setSecondaryLiDashboardDetails,
} from "../LifeInsurance_Slice";
import { CustomAccordionSkeleton } from "../../../components/CustomAccordianSkeletonShimmer/CustomAccordianSkeletonShimmer";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion";
import { Box, Stack, Typography } from "@mui/material";
import TermPlanCard from "../../../components/CustomTermPlanCard/TermPlanCard";
import { encryptString } from "../../../utils/helper";
import { API_STATUS_CODE } from "../../../utils/ApiEndpoints";
import { generatePath, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import umbrellaIcon from "../../../assets/icons/umbrella-icon.svg";
import { R_LiRoutes } from "../LifeInsurance_Routes";
import { T_LiRouteState } from "../../../globalTypes/GlobalTypes";
import { setSelectedModule } from "../../Feature_GeneralAppElements/GeneralAppElements_Slice";

interface Props {
  moduleName?: string;
}

export const LiDashboard: FC<Props> = ({ moduleName = "" }) => {
  const [apiCallRunning, setApiCallRunning] = useState<boolean>(false);
  const [isPolicyAllDetailsFetching, setIsPolicyAllDetailsFetching] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fetchDashboardDetailsApi] = useLazyFetchDashboardDetailsQuery();
  const [fetchPolicyAllDetails] = useLazyFetchPolicyAllDetailsQuery();

  const retryForDashboardDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice.retryDashboardDetails
  );
  const retryForSecondaryDetailsApi = useAppSelector(
    (state) => state.LifeInsuranceSlice.retrySecondaryDashboardDetails
  );
  const configuration = useAppSelector(
    (state) => state.CsAppSlice.tokenDetails?.configuration
  );
  const dashboardDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice.dashboardDetails
  );
  const secondaryDashboardDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice.secondaryDashboardDetails
  );
  const policyAllDetails = useAppSelector(
    (state) => state.LifeInsuranceSlice.policyAllDetails
  );

  const IV = configuration?.iv || "";
  const secretKey = configuration?.key || "";
  const userDetails = useAppSelector((state) => state.CsAppSlice.userDetails);
  const appDispatch = useAppDispatch();
  const customerAndLobDetails = userDetails?.customerAndLobDetails;
  const primaryUserLiLobId = customerAndLobDetails?.filter(
    (item: T_CustomerLobDetailObj) =>
      item.LOB === LiLobCode && item.PRIMARY_SECONDARY_USER === "primary"
  );
  const secondaryUserLiLobId = customerAndLobDetails?.filter(
    (item: T_CustomerLobDetailObj) =>
      item.LOB === LiLobCode && item.PRIMARY_SECONDARY_USER === "secondary"
  );
  const dashboardDetailApiFailed = CsSessionStorageUtils.get<boolean>(
    "DASHBOARD_DETAIL_API_FAILED"
  );
  const dashboardSecondaryApiFailed = CsSessionStorageUtils.get<boolean>(
    "DASHBOARD_SECONDARY_API_FAILED"
  );

  useEffect(() => {
    if ((retryForDashboardDetails || 0) > 0) {
      ApiCalls();
    }
  }, [retryForDashboardDetails]);

  useEffect(() => {
    ApiCalls();
  }, [customerAndLobDetails]);
// Function to extract FUNDDETAILS
const extractFundDetails = (results: Array<any>) => {
  return results.map((item) => {
    return isArray(item?.data?.payload.FUNDDETAILS)
      ? item?.data?.payload.FUNDDETAILS
      : [];
  });
};

  const ApiCalls = async () => {
    CsSessionStorageUtils.clear("DASHBOARD_DETAIL_API_FAILED");
    CsSessionStorageUtils.clear("DASHBOARD_SECONDARY_API_FAILED");
    setApiCallRunning(true);
    await Promise.all([fetchAllLiDashboardDetails()]);
    setApiCallRunning(false);
  };

  const fetchAllLiDashboardDetails = async () => {
    if (primaryUserLiLobId && primaryUserLiLobId?.length > 0) {
      let primaryUserPromises = uniqBy(primaryUserLiLobId, "LOBID")?.map(
        (item: T_CustomerLobDetailObj) => {
          return fetchDashboardDetailsApi(
            item.LOBID,
            (retryForDashboardDetails || 0) > 0 ? false : true
          );
        }
      );
      let primaryUserResults = await Promise.all(primaryUserPromises);
      if (primaryUserResults.some((r) => r?.error || r?.data?.code != 1)) {
        CsSessionStorageUtils.set("DASHBOARD_DETAIL_API_FAILED", "true");
        return;
      }
      let transformedPrimaryUserResults = primaryUserResults.map((result) => {
        return (isEmpty(result.data?.payload.DASHBOARDdDETAILS)
          ? []
          : result?.data?.payload?.DASHBOARDdDETAILS.filter(
            (item: T_LIDashboardDetails) =>
              getPolicyStatus(item.POLICY_STATUS)
          ));
      });
      dispatch(
        setLiDashboardDetails(transformedPrimaryUserResults.flat(Infinity))
      );
      let transFormedPrimaryFundDetails = extractFundDetails(primaryUserResults);
      dispatch(
        setLiDashboardFundDetails(transFormedPrimaryFundDetails.flat(Infinity))
      );
    }
    if (secondaryUserLiLobId && secondaryUserLiLobId?.length > 0) {
      let secondaryUserPromises =
        uniqBy(secondaryUserLiLobId, "LOBID")?.map(
          (item: T_CustomerLobDetailObj) => {
            return fetchDashboardDetailsApi(
              item.LOBID,
              (retryForSecondaryDetailsApi || 0) > 0 ? false : true
            );
          }
        ) || [];
      let results = await Promise.all(secondaryUserPromises);
      if (results.some((r) => r?.error || r?.data?.code != 1)) {
        CsSessionStorageUtils.set("DASHBOARD_SECONDARY_API_FAILED", "true");
        return;
      }
      let policyNumberArray = secondaryUserLiLobId?.map(
        (el) => el.HOLDING_IDENTIFIER_ONE
      );
      let transformedSecondaryDashboardResult = results.map(
        (item: any, index) => {
          return (isEmpty(item.data?.payload.DASHBOARDdDETAILS)
            ? []
            : item?.data?.payload?.DASHBOARDdDETAILS.filter(
                (i: T_LIDashboardDetails) => {
                  return (
                    policyNumberArray.includes(i.POLICY_NUMBER) &&
                    getPolicyStatus(i.POLICY_STATUS)
                  );
                }
              ));
        }
      );
      dispatch(
        setSecondaryLiDashboardDetails(
          transformedSecondaryDashboardResult.flat(Infinity)
        )
      );
      let transFormedDashboardFundDetails =  extractFundDetails(results);
      dispatch(
        setLiSecondaryDashboardFundDetails(
          transFormedDashboardFundDetails.flat(Infinity)
        )
      );
    }
  };

  const accordionClick = async () => {
    let promises = [...dashboardDetails, ...secondaryDashboardDetails].map(
      (item: T_LIDashboardDetails) => {
        const encryptedPolicyNumber = encryptString(
          item.POLICY_NUMBER,
          IV,
          secretKey
        );
        return fetchPolicyAllDetails(encryptedPolicyNumber, true);
      }
    );
    setIsPolicyAllDetailsFetching(true);
    let results = await Promise.all(promises);
    let transformedResult = results
      .filter((f) => f.data?.code === API_STATUS_CODE.SUCCESS)
      .map((item: any) => {
        return item.data?.payload.Response;
      });
    dispatch(setLiPolicyAllDetails(transformedResult));
    setIsPolicyAllDetailsFetching(false);
  };

  let totalLiPolicyCount =
    dashboardDetails?.length + secondaryDashboardDetails?.length;

  let isLiAccordionVisible =
    totalLiPolicyCount > 0 ||
    policyAllDetails.length > 0 ||
    dashboardSecondaryApiFailed ||
    dashboardDetailApiFailed;

  return (
    <Fragment>
      {!apiCallRunning ? (
        isLiAccordionVisible && (
          <CustomAccordion
            title={`Life Insurance ${
              totalLiPolicyCount > 0 ? "(" + totalLiPolicyCount + ")" : ""
            }`}
            preIcon={
              <img
                src={umbrellaIcon}
                alt="umbrella"
                className="accordion-icon"
              />
            }
            expandedByDefault={
              dashboardDetailApiFailed || dashboardSecondaryApiFailed || false
            }
            handleAccordionClick={() => {
              if (dashboardDetails.length > 0) {
                accordionClick();
                appDispatch(setSelectedModule(moduleName));
              }
            }}
          >
            <>
              {dashboardDetails.length > 0 && (
                <Typography variant="h5" marginBottom="1rem">
                  My Holding ({dashboardDetails.length})
                </Typography>
              )}
              <Stack rowGap="1rem">
                {dashboardDetails && dashboardDetails.length > 0 ? (
                  dashboardDetails.map(
                    (item: T_LIDashboardDetails, index: number) => {
                      let policyAllDetail = policyAllDetails?.find(
                        (detail: T_PolicyAllDetails) =>
                          detail?.Policy_Level_Details?.POLICY_NUMBER ===
                          item.POLICY_NUMBER
                      );
                      return (
                        <TermPlanCard
                          key={item.POLICY_NUMBER}
                          data={item}
                          policyAllDetails={policyAllDetail}
                          viewDetailsClick={() => {
                            const stateData: T_LiRouteState = {
                              policyNumber: item.POLICY_NUMBER,
                            };
                            navigate(R_LiRoutes.details_PolicyNumber, {
                              state: stateData,
                            });
                          }}
                          isPolicyAllDetailsFetched={isPolicyAllDetailsFetching}
                        />
                      );
                    }
                  )
                ) : dashboardDetailApiFailed ? (
                  <TermPlanCard
                    key={"no policy found"}
                    data={undefined}
                    policyAllDetails={undefined}
                    viewDetailsClick={undefined}
                    isPolicyAllDetailsFetched={isPolicyAllDetailsFetching}
                    showNoHoldingImg={true}
                  />
                ) : (
                  <></>
                )}
              </Stack>
            </>
            {secondaryDashboardDetails.length > 0 ||
            dashboardSecondaryApiFailed ? (
              <Box
                marginTop={dashboardDetails.length > 0 ? "1.5rem" : "0.5rem"}
              >
                <>
                  {secondaryDashboardDetails?.length > 0 && (
                    <Typography variant="h5" marginBottom="0.5rem">
                      Other Holding ({secondaryDashboardDetails.length})
                    </Typography>
                  )}
                  <Stack rowGap="1rem">
                    {secondaryDashboardDetails.length > 0 ? (
                      secondaryDashboardDetails.map(
                        (item: T_LIDashboardDetails) => {
                          let policyAllDetail = policyAllDetails?.find(
                            (detail: T_PolicyAllDetails) =>
                              detail?.Policy_Level_Details?.POLICY_NUMBER ===
                              item.POLICY_NUMBER
                          );
                          return (
                            <Fragment key={item.POLICY_NUMBER}>
                              <Typography variant="h6" fontWeight={400}>
                                {`${item.LIFE_INSURED_NAME} (${1})`}
                              </Typography>
                              <TermPlanCard
                                isOtherHolding={true}
                                key={item.POLICY_NUMBER}
                                data={item}
                                policyAllDetails={policyAllDetail}
                                isPolicyAllDetailsFetched={
                                  isPolicyAllDetailsFetching
                                }
                              />
                            </Fragment>
                          );
                        }
                      )
                    ) : dashboardSecondaryApiFailed ? (
                      <TermPlanCard
                        key={"no policy found"}
                        isOtherHolding={true}
                        data={undefined}
                        policyAllDetails={undefined}
                        viewDetailsClick={undefined}
                        isPolicyAllDetailsFetched={isPolicyAllDetailsFetching}
                        showNoHoldingImg={true}
                      />
                    ) : (
                      <></>
                    )}
                  </Stack>
                </>
              </Box>
            ) : null}
          </CustomAccordion>
        )
      ) : (
        <CustomAccordionSkeleton />
      )}
    </Fragment>
  );
};
