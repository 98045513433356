import { useEffect } from "react";
import {
  useLazyFetchDashboardDetailsQuery,
  useLazyFetchPolicyAllDetailsQuery,
} from "./LifeInsurance_Api";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import { encryptString } from "../../utils/helper";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLiDashboardDetails,
  setLiPolicyAllDetails,
} from "./LifeInsurance_Slice";
import { updateLoadingState } from "../Feature_Loading/Loading_Slice";
import NotFound from "../../pages/NotFound/NotFound";
import { API_STATUS_CODE } from "../../utils/ApiEndpoints";
import {
  E_ChannelOptions,
  T_LiRouteState,
} from "../../globalTypes/GlobalTypes";
import UnableToNavigate from "../../pages/UnableToNavigate/UnableToNavigate";
import { isEmpty } from "lodash";

const LifeInsuranceGuard = () => {
  const dispatch = useDispatch();
  const csAppSlice = useAppSelector((st) => st.CsAppSlice);
  const ssoSlice = useAppSelector((st) => st.SsoSlice.ssoData);
  const configuration = useAppSelector(
    (st) => st.CsAppSlice.tokenDetails?.configuration
  );
  const IV = configuration?.iv || "";
  const secretKey = configuration?.key || "";
  const { state } = useLocation();
  const policyNumber = (state as T_LiRouteState)?.policyNumber || "";
  const [
    fetchPolicyAllDetails,
    { data: policyData, error, isError, isSuccess },
  ] = useLazyFetchPolicyAllDetailsQuery();
  const [
    fetchDashboardDetailsApi,
    { error: dashboardError, isSuccess: dashboardSuccess },
  ] = useLazyFetchDashboardDetailsQuery();

  const policyAllDetails = useAppSelector(
    (st) => st.LifeInsuranceSlice.policyAllDetails
  );

  useEffect(() => {
    if (policyData?.code === 1) {
      fetchDashboardDetailsApi("" + policyAllDetails[0].Owner.CLIENT_ID).then(
        (res) => {
          dispatch(setLiDashboardDetails(res.data?.payload.DASHBOARDdDETAILS));
        }
      );
    }
  }, [policyData]);

  useEffect(() => {
    if (isEmpty(policyAllDetails)) {
      dispatch(updateLoadingState(true));
      const encryptedPolicyNumber = encryptString(policyNumber, IV, secretKey);
      fetchPolicyAllDetails(encryptedPolicyNumber, true).then((res) => {
        if (res.data?.code === API_STATUS_CODE.SUCCESS) {
          dispatch(setLiPolicyAllDetails([res.data?.payload.Response]));
        }
      });
    }
  }, [policyAllDetails]);

  useEffect(() => {
    if (isSuccess && dashboardSuccess) {
      dispatch(updateLoadingState(false));
    }
  }, [isSuccess, dashboardSuccess]);

  return isError || error || dashboardError ? (
    ssoSlice.functionality &&
    ["CI", "SC", "RST", "CT", "ST", "EI"].includes(ssoSlice.functionality) ? (
      <UnableToNavigate />
    ) : (
      <NotFound hideButton={csAppSlice.channel != E_ChannelOptions.CSAPP} />
    )
  ) : isEmpty(policyAllDetails) ? (
    <></>
  ) : (
    <Outlet />
  );
};

export default LifeInsuranceGuard;
