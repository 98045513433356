import { Container, Box } from "@mui/material";
import React, { FC } from "react";
import "./CustomSwitchButton.scss";

interface SwitchButtonProps {
  options: string[];
  selected: string | null; // Allow null for no selection
  onClickFilter: (key: string) => void;
}

const SwitchButton: FC<SwitchButtonProps> = ({
  options,
  selected,
  onClickFilter,
}) => {
  return (
    <Box
      sx={{
        marginBottom: "1.5rem !important",
        backgroundColor: "white",
        borderRadius: "0.75rem",
        padding: "0.1875rem",
      }}
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      borderRadius="12px"
      className="switch-button"
    >
      {options.map((option) => (
        <Container
          key={option}
          onClick={() => onClickFilter(option)}
          sx={{
            backgroundColor: selected === option ? "#C7222A" : "#F1F3F6",
            color: selected === option ? "#FFFFFF" : "#767D93",
            height: "2.0625rem",
            borderRadius: "0.75rem",
            padding: "0.125rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            // transition: "background-color 0.3s, color 0.3s, border-radius 0.3s",
            // '&:hover': {
            //   backgroundColor: selected === option ? "#C7222A" : "#F1F3F6",
            // },
            // '& .MuiTouchRipple-root': {
            //   color: '#FFE7E5', // Ripple effect color
            // },
          }}
        >
          {option}
        </Container>
      ))}
    </Box>
  );
};

export default SwitchButton;
