import { createSlice } from "@reduxjs/toolkit";
import { T_HLSlice } from "./Homeloan_Types";

const initialState: T_HLSlice = {
  summaryData: [],
  loanInfo: {
    totalRows: "0",
    moreRows: "false",
    rows: [],
  },
  paymentInfo: {
    payment_data: {
      pl_order_id: "",
      merc_order_id: "",
      merc_order_date: "",
      merc_id: "",
      amount: "",
      return_url: "",
      product_type: "",
      product_id: "",
      customer_id: "",
      customer_name: "",
      customer_phone: "",
      order_desc: "",
      udf1: "",
      udf2: "",
      account_no: "",
      account_ifsc: "",
    },
    order_data: {
      pl_order_id: "",
      created_date: "",
      status: "",
      status_code: "",
      links: {
        payment_link_web: "",
      },
    },
  },
};

export const HomeLoanSlice = createSlice({
  name: "HomeLoanSlice",
  initialState,
  reducers: {
    setSummaryData: (state, action) => {
      return {
        ...state,
        summaryData: action.payload,
      };
    },
    setHomeLoanInfo: (state, action) => {
      return {
        ...state,
        loanInfo: action.payload,
      };
    },
    setEmiPaymentOrderInfo: (state, action) => {
      return {
        ...state,
        paymentInfo: action.payload,
      };
    },
  },
});

export const HomeLoanReducer = HomeLoanSlice.reducer;
export const HomeLoanSliceSliceKey = HomeLoanSlice.reducerPath;
export const { setSummaryData, setHomeLoanInfo, setEmiPaymentOrderInfo } =
  HomeLoanSlice.actions;
