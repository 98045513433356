import { Box, Button, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import NavigationNotFound from "../../assets/images/navigation_not_found_1x.png";
import { Colors } from "../../styles/constant";
import { useNavigate } from "react-router-dom";
import { R_HomeRoutes } from "../Home/Home_Routes";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import ArrowRightIcon from "../../assets/icons/arrow-right.svg";
import { triggerGenerateSSOOld } from "../../features/Feature_SSO/Sso_Slice";
import {
  T_ValidateSSOOldRes,
  TriggerGenerateSSO_OLD,
} from "../../features/Feature_SSO/Sso_Types";
import {
  OneLoginLobCode,
  OneLoginRedirectUrl,
  REACT_APP_URL,
} from "../../utils/constants";
import { useDispatch } from "react-redux";
import { E_ChannelOptions } from "../../globalTypes/GlobalTypes";
import { updateLoadingState } from "../../features/Feature_Loading/Loading_Slice";

const UnableToNavigate: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [emailInfo, setEmailInfo] = useState<string>("");
  const csAppSlice = useAppSelector((state) => state.CsAppSlice);
  const ssoSlice = useAppSelector(
    (state) => state.SsoSlice.ssoData as T_ValidateSSOOldRes
  );
  const doReverseSSO = useCallback(() => {
    dispatch(updateLoadingState(true));
    dispatch(
      triggerGenerateSSOOld({
        triggerGenerateSSO_Old:
          (ssoSlice as TriggerGenerateSSO_OLD).triggerGenerateSSO_Old || true,
        ciiNo: ssoSlice.ciiNo,
        functionality: "D",
        mobileNo: ssoSlice.mobileNo || "",
        lobClientId: ssoSlice.lobClientId,
        channel: E_ChannelOptions.CSAPP,
        sourceLobId: "11",
        destinationLobId: OneLoginLobCode,
        callbackUrl: `${REACT_APP_URL}/login`,
        redirectionUrl: OneLoginRedirectUrl,
        appVersion: "1",
        panNumber: ssoSlice.panNumber || "",
        customerFullName: ssoSlice.customerFullName || "",
        customerDob: ssoSlice.customerDob || "",
        emailId: ssoSlice.emailId || "",
        osVersion: "Windows",
        imei: "1231312",
        deviceId: "131312",
        userAgent: navigator.userAgent,
        clientIpAddress: "",
        udp: "",
        udp2: "",
        udp3: "",
        udp4: "",
        udp5: "",
        udp6: "",
        udp7: "",
        udp8: "",
        udp9: "",
        udp10: "",
      } as TriggerGenerateSSO_OLD)
    );
  }, [ssoSlice]);

  const gotoHomepage = useCallback(() => {
    dispatch(updateLoadingState(true));
    if (csAppSlice.channel === E_ChannelOptions.ONE_ABC) {
      doReverseSSO();
    } else {
      navigate(R_HomeRoutes.home, { replace: true });
    }
  }, []);

  const fetchConfigDataDetails = useAppSelector(
    (state) => state.GeneralAppElementsSlice?.masterData?.supportDataList || []
  );
  useEffect(() => {
    const emailUsItem = fetchConfigDataDetails.find(
      (item) => item.optionType === "Email Us"
    );
    if (emailUsItem) {
      setEmailInfo(emailUsItem.contactInfo);
    }
  }, [fetchConfigDataDetails]);
  const handleClick = () => {
    window.location.href = `mailto:${emailInfo}?subject=Inquiry`;
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      marginLeft={"32px"}
      marginRight={"32px"}
      height="100vh"
    >
      <img src={NavigationNotFound} height={"196px"} width={"196px"} />
      <div
        style={{ marginTop: "2rem", marginBlock: "2rem", textAlign: "center" }}
      >
        <Typography variant="h3" color={Colors.textblack}>
          Unable to navigate
        </Typography>
        <Typography
          variant="h5"
          color={Colors.textGray}
          sx={{ marginTop: "1rem" }}
        >
          We are facing some technical issue. Please try again after some time 
        </Typography>
        <Typography
          variant="h5"
          color={"#252525"}
          sx={{ marginTop: "1.25rem" }}
        >
          OR
        </Typography>
        <Typography
          variant="h5"
          color={Colors.textGray}
          sx={{ marginTop: "1.25rem" }}
        >
          In case of any concern, you can write to us on{" "}
          <span style={{ color: "red" }}>care.digital@adityabirla.com</span> via
          your registered email ID
        </Typography>
      </div>
      <Button
        variant="contained"
        sx={{
          backgroundColor: Colors.red,
          borderRadius: "1.25rem",
          padding: "1rem 1rem",
          color: Colors.white,
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "19.2px",
          // marginTop: "1rem",
          width: "100%",
        }}
        onClick={handleClick}
      >
        Email Now
      </Button>
      <Button
        endIcon={
          <img
            src={ArrowRightIcon}
            style={{
              filter:
                "invert(19%) sepia(85%) saturate(5500%) hue-rotate(357deg) brightness(100%) contrast(100%)",
            }}
            alt="Arrow Right"
          />
        }
        sx={{
          borderRadius: "1.25rem",
          color: Colors.red,
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "19.2px",
        }}
        onClick={gotoHomepage}
      >
        Go back to homepage
      </Button>
    </Box>
  );
};
export default UnableToNavigate;
