import { useCallback } from "react";
import "./CustomFooter.scss";
import { Box, Typography } from "@mui/material";
import ABCLogo from "../../assets/images/abc-logo.jpg";
import GooglePlaystore from "../../assets/images/GooglePlayStore.png";
import Stack from "@mui/material/Stack";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import appStore from "../../assets/images/appStore.png";
import { useNavigate } from "react-router-dom";
import {
  aboutUsTitle,
  privacyPolicyTitle,
  termsAndConditionsTitle,
} from "../../utils/constants";
import { R_LoginRoutes } from "../../features/Feature_Login/Login_Routes";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import CustomNetworkImage from "../CustomNetworkImage/CustomNetworkImage";

export const Footer = () => {
  const navigate = useNavigate();
  const cmsData = useAppSelector((state) => state.CmsSlice.CmsPageVariableData);
  const cmsLogo = cmsData.find(i=>i.attributes.cmsType === 'ABC Logo')?.attributes.image.data?.attributes.url ?? '';
  const handleAboutUsClick = useCallback(() => {
    navigate(R_LoginRoutes.policyAndConditions, {
      state: { title: aboutUsTitle },
    });
  }, [aboutUsTitle]);

  const handleTermsAndConditionsClick = useCallback(() => {
    navigate(R_LoginRoutes.policyAndConditions, {
      state: { title: termsAndConditionsTitle },
    });
  }, [termsAndConditionsTitle]);
  const handlePrivacyPolicyClick = useCallback(() => {
    navigate(R_LoginRoutes.policyAndConditions, {
      state: { title: privacyPolicyTitle },
    });
  }, [privacyPolicyTitle]);
  return (
    <Box className="footer">
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: ".75rem",
          lineHeight: ".825rem",
          color: "white",
        }}
      >
        {"DOWNLOAD THE APP NOW"}
      </Typography>
      <Box className="play-store-box">
        <img src={GooglePlaystore} alt="google-playstore-icon" />
        <img
          src={appStore}
          alt="apple-store-icon"
          className="image-appleplaystore"
        />
      </Box>

      <Stack className="navigation-box" spacing={2}>
        <Stack direction={"row"} spacing={1}>
          <Typography className="navigation-text">{"About Us"} </Typography>
          <ArrowRightAltIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleAboutUsClick}
          />
        </Stack>

        <Stack direction={"row"} spacing={1}>
          <Typography className="navigation-text">
            {"Terms & Conditions"}{" "}
          </Typography>
          <ArrowRightAltIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handleTermsAndConditionsClick}
          />
        </Stack>

        <Stack direction={"row"} spacing={1}>
          <Typography className="navigation-text">
            {"Privacy Policy"}{" "}
          </Typography>
          <ArrowRightAltIcon
            sx={{ color: "white", cursor: "pointer" }}
            onClick={handlePrivacyPolicyClick}
          />
        </Stack>
      </Stack>

      <Box className="bottom-img-box">
        <Box display="flex" justifyContent="center">
          <CustomNetworkImage fallBackImage= { <img src={ABCLogo} alt=""/>} src={cmsLogo} alt="abc-logo-img" />
        </Box>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: ".625rem",
            lineHeight: ".825rem",
            color: "white",
          }}
        >
          {"© Aditya Birla Capital Limited 2024"}
        </Typography>
      </Box>
    </Box>
  );
};
