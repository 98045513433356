import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type T_LoadingSliceState = {
  showHideLoading?: boolean;
};

const initialState: T_LoadingSliceState = {
  showHideLoading: false,
};

export const LoadingSlice = createSlice({
  name: "LoadingSlice",
  initialState: initialState,
  reducers: {
    updateLoadingState: (
      state: T_LoadingSliceState,
      action: PayloadAction<boolean>
    ) => {
      return { ...state, showHideLoading: action.payload };
    },
  },
});

export const LoadingSliceReducer = LoadingSlice.reducer;
export const LoadingSliceKey = LoadingSlice.reducerPath;
export const { updateLoadingState } = LoadingSlice.actions;
