import { FC } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import CustomLink from "../../../components/CustomLink/CustomLink";
import { Colors } from "../../../styles/constant";
import { LoanData, LoanDetails, T_SummaryData } from "../Homeloan_Types";
import LoanDetailsSection from "./HomeloanDetailSection";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { R_HLRoutes } from "../Homeloan_Routes";

interface Props {
  data: LoanDetails;
  status: string | undefined;
  title: string;
  index: number;
}

const { format } = new Intl.NumberFormat("hi-In", {
  style: "currency",
  currency: "INR",
});

export const getRupeeFormat = (val: string): string => {
  if (val === "") {
    return `₹0`;
  }
  const numVal = parseFloat(val);
  if (isNaN(numVal)) {
    throw new Error("Invalid number format");
  }
  // Format the number value to currency
  const formattedValue = format(numVal);

  // Split formatted value to remove decimal part and return
  return formattedValue.split(".")[0];
};

const HiInformationCard: FC<Props> = ({ data, status, title }) => {
  const navigate = useNavigate();
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const summaryData = allSummaryData.find(
    (item) =>
      item.loan_info.rows[0].LOAN_ACCOUNT_NUMBER === data.LOAN_ACCOUNT_NUMBER
  );

  const loanData: LoanData = {
    SANCTIONED_AMOUNT: data.SANCTIONED_AMOUNT,
    TOTAL_AMOUNT_DISBURSED: data.TOTAL_AMOUNT_DISBURSED,
    EMI_END_DATE: data.NEXT_DUE_DATE ?? "",
    INTEREST_RATE: data.INTEREST_RATE,
    EMI_AMOUNT: data.EMI_AMOUNT,
    DATE_TITLE:
      status?.toLowerCase() === "active" ? "Next EMI date" : "Closed date",
  };

  return (
    <Box className="hl-dashboard-accordion-card">
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Typography color={Colors.textGray} variant="h5">
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          marginRight="0.9375rem"
          gap=".25rem"
        >
          <span
            className="circle-icon"
            style={{
              backgroundColor:
                status && status.toLowerCase() === "active"
                  ? Colors.green
                  : Colors.bgGray,
            }}
          ></span>
          <Typography
            color={
              status && status.toLowerCase() === "active"
                ? Colors.green
                : Colors.textGray
            }
            variant="h6"
          >
            {status}
          </Typography>
        </Box>
      </Stack>
      <Stack
        direction="row"
        display="flex"
        justifyContent="flex-start"
        marginTop={"1rem"}
        columnGap={2}
      >
        <Typography fontWeight={600} variant="h5" marginRight={".8rem"}>
          Acc no.
        </Typography>
        <Typography
          variant="subtitle2"
          flex={1}
          textAlign={{ xs: "left", sm: "left" }}
          sx={{
            paddingRight: {
              sm: "3rem",
            },
          }}
        >
          {data.LOAN_ACCOUNT_NUMBER}
        </Typography>
      </Stack>
      <Divider sx={{ marginY: ".625rem" }} />
      <LoanDetailsSection
        data={{
          ...loanData,
          EMI_AMOUNT: summaryData
            ? summaryData.account_detail.rows[0].NEXT_DUE_AMOUNT
            : loanData.EMI_AMOUNT,
        }}
      />
      <Box display="flex" marginTop=".9375rem" justifyContent="flex-start">
        <CustomLink
          title="View details"
          titleSize={".75rem"}
          onClick={() =>
            data.LOAN_ACCOUNT_NUMBER !== ""
              ? navigate(R_HLRoutes.home_loan_details, {
                  state: { accountNumber: data.LOAN_ACCOUNT_NUMBER },
                })
              : {}
          }
        />
      </Box>
    </Box>
  );
};

export default HiInformationCard;
