import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import AuthGuard from "./Guards/AuthGuard";
import LoginRoutes from "../features/Feature_Login/Login_Routes";
import GeneralAppElementsRoutes from "../features/Feature_GeneralAppElements/GeneralAppElements_Routes";
import { LifeInsuranceRoutesFn } from "../features/Feature_LifeInsurance/LifeInsurance_Routes";
import { StocksSecuritiesRoutesFn } from "../features/Feature_StocksAndSecurities/Stocks&Securities_Routes";
import { HomeloanRoutesFn } from "../features/Feature_Homeloan/Homeloan_Routes";
import HomeRoutes, { R_HomeRoutes } from "../pages/Home/Home_Routes";
import SsoRoutes from "../features/Feature_SSO/Sso_Routes";
import OnBoardingRoutes from "../features/Feature_OnBoarding/OnBoarding_Routes";
import { HealthInsuranceRoutesFn } from "../features/Feature_HealthInsurance/HealthInsurance_Routes";
import Decryption from "../pages/Decryption/Decryption";
import { MutualFundRoutesFn } from "../features/Feature_MutualFund/MutualFund_Routes";
import SecondaryChannelRoutes from "../features/Feature_SecondaryChannels/SecondaryChannel_Routes";
import UnableToNavigate from "../pages/UnableToNavigate/UnableToNavigate";

const AppRoutes = () => {
  const liRoutes = LifeInsuranceRoutesFn();
  const mfRoutes = MutualFundRoutesFn();
  const hiRoutes = HealthInsuranceRoutesFn();
  const SSRoutes = StocksSecuritiesRoutesFn();
  const HLRoutes = HomeloanRoutesFn();
  const arr: RouteObject[] = [
    {
      path: "decryption",
      element: <Decryption />,
    },
    ...SsoRoutes,
    {
      path: "/",
      element: <AuthGuard />,
      children: [
        ...OnBoardingRoutes,
        ...GeneralAppElementsRoutes,
        ...liRoutes,
        ...mfRoutes,
        ...hiRoutes,
        ...SSRoutes,
        ...HLRoutes,
        ...HomeRoutes,
        { path: "", element: <Navigate to={R_HomeRoutes.home} /> },
      ],
    },
    ...LoginRoutes,
    ...SecondaryChannelRoutes,
    {
      path: "*",
      element: <Navigate to={"/"} />,
    },
  ];
  return useRoutes(arr);

 
};

export default AppRoutes;
