import { T_UserProfile } from "../../globalTypes/GlobalTypes";
import { T_GetCustomerLobDetailsRes } from "../Feature_Login/Login_Types";

export type T_MFDashboardDetails = {
  INVESTED_AMOUNT?: string | null;
  CURRENT_VALUE?: string | null;
  XIRR_VALUE?: string | null;
};
export type T_MutualFundSliceState = {
  mfDashboardDetails: T_MFDashboardDetails;
  folioDetails: T_Folio[];
  secondaryFolioDetails: T_FolioSecondaryDetails[];
  folioSchemeDetails: T_FolioSchemeSummary[];
};

export type T_FolioSecondaryDetails = {
  folioList: string;
  name: string;
  pan: string;
  values: T_MFDashboardDetails;
};

export type T_FetchExitLoad_ReqPayload = {
  request: string;
};

export type T_FetchExitLoad_Response = {
  exitLoadResponseData: {
    ReturnCode: string;
    ReturnMsg: string;
    Response: {
      Exp_Date: string;
      StmtDate: string;
      InvestorName: string;
      Folio_No: string;
      Scheme: {
        SchemeCode: string;
        SchemeName: string;
        EnteredTrxnUnits: string;
        EnteredTrxnAmount: string;
        HoldFreeRedemptionUnits: string;
        LastAvailableNAVDate: string;
        LastAvailableNAV: string;
        ExitLoadPerc: string;
        ApproxLoadAmt: string;
        ExitLoadValidityDate: string;
      };
      Purchase_Details: {
        Transactions: {
          Transaction_Amount: string;
          Transaction_Units: string;
          Transaction_Date: string;
          Purchase_Offer_Price: string;
          AVL_Balance_Units_for_Redemption: string;
          Exit_Load_Perc: string;
          Exit_Load_Validity_Date: string;
          Approx_Load_Amount: string;
          Transaction_Description: string;
        }[];
      };
    };
  };
  byteCodeResponse: string;
};

export type T_Folio = {
  Folio_No: string;
  InvestorName: string;
  MobileNumber: string;
  EmailID: string;
  DefaultFlag: string;
  LiquidFlag: string;
  MAXDATE: string;
  ActivatedAmount: string;
  TaxStatusCode: string;
  PANNo: string;
  Holding: string;
  Created_Date: string;
  BirthDate: string;
  IsMinor: string;
  JH1_HOLDER_NAME: string;
  JH2_HOLDER_NAME: string;
  JH3_HOLDER_NAME: string;
  GuardianName: string;
};

export type T_ValidatePan_Request = {
  PANNo: string;
  AadharNo: string | null;
  DOB: string;
  ClientIpAddress: string | null;
  IMEI: string | null;
  OS: string | null;
  IsNew: string | null;
  isMinor: string | null;
  Channel: string | null;
  PageURL: string | null;
  Campaign: string | null;
  Trans_No: string | null;
  TransactionType: string | null;
  ServerIpAddress: string | null;
  ModuleName: string | null;
  MethodName: string | null;
  AuthCode: string | null;
  GoogleAdID: string | null;
  DeviceId: string | null;
  UserName: string | null;
  isFolioRequired: boolean;
};

export type T_ValidatePan_Response = {
  ReturnCode: string;
  ReturnMsg: string;
  IsExistingInvestor: string;
  IsEKYCVerified: string;
  EIPVStatus: string;
  Folios: T_Folio[];
  PANNumber: string;
  InvestorType: string;
  NameAsPerPAN: string;
  KYC_StatusMessage: string;
  IsKYCUnderprocess: string;
  ISKRAServiceDown: string;
  IsKycModifiedAllowed: string;
  IsOnHold: string;
  KRAProvider: string;
  KRAResponse: string;
  IsKycModifiedLinkMandatory: string;
};

export type T_FoliosSummary_Request = {
  FolioNumbers: string;
  DeviceInfo: string;
  XIRRFlag: string;
};

export type protFolioSummaryWithXIRRY = {
  XIRR: string;
};

export type protFolioSummaryWithXIRRN = {
  PORTFOLIO_DATE: string;
  ONE_DAY_CHANGES: string;
  CURRENT_VALUE: string;
  INVESTED_AMT: string;
};

export type T_FoliosSummary_Response = {
  ReturnCode: string;
  ReturnMsg: string;
  ProtfolioSummary: protFolioSummaryWithXIRRY;
};

export type T_FolioSchemeSummary_Request = {
  P_FOLIO_NO: string;
  P_PAN_NO: string;
  P_IS_MINOR: string;
};

export type T_FolioSchemeSummary = {
  FOLIO_NO: string;
  MINOR_TAGGED_FOLIO_NO: string;
  PAN_NO: string;
  TAX_STATUS_CODE: string;
  GUARDIAN_PANNO: string;
  INVESTOR_NAME: string;
  BIRTH_DATE: string;
  FOLIO_CREATE_DATE: string;
  IS_MINOR_NOW: string;
  IS_MINOR_THEN: string;
  BROKER_CODE: string;
  EUIN: string;
  SCH_CODE: string;
  SHORT_NAME: string;
  SCHNAME: string;
  SCHEME_TYPE: string;
  ASSET_CLASS: string;
  NEW_SEBI_CATEGORY: string;
  NEW_SEBI_SUB_CATEGORY: string;
  UNITS: string;
  INVESTED_AMOUNT: string;
  DIVIDEND_AMOUNT: string;
  MARKET_VALUE: string;
  GAINLOSS: string;
  SCHEME_OPTION: string;
  FROM_TURBO_STP: string;
  TO_TURBO_STP: string;
  MIN_TURBO_AMOUNT: string;
  SCHEME_COUNT: string;
  FOLIO_COUNT: string;
  XIRR: string;
  PEKRN_NO: string;
  GUARDIAN_PEKRN_NO: string;
  EMAIL: string;
  MOBILE_NO: string;
  IS_SKINED_FOLIO: string;
  NAV: string;
  NAV_DATE: string;
  FUND_NAME: string;
  AVAILABLE_UNITS: string;
  AVAILABLE_AMOUNT: string;
  SRC_TBL: string;
  PLAN_TYPE: string;
  SIP_ALLOW: string;
  STP_ALLOW: string;
  SWP_ALLOW: string;
  DIV_GROW_PLAN: string;
  ASSET_CATEGORY: string;
  ASSET_SUB_CATEGORY: string;
  STARTDATE: string;
  ENDDATE: string;
  ACTIVE: string;
  NFO: string;
  SWITCHFROM: string;
  TRIGGERFROM: string;
  SIPFLAG: string;
  STPFROM: string;
  SWPFLAG: string;
  ISDIRECT: string;
  CHANGEDIVIDENDFLAG: string;
  DIVSWEEP_FROM: string;
  ISCSIPAVAILABLE: string;
  CancelSIP: string;
  CancelSTP: string;
  CancelSWP: string;
};

export type T_FolioSchemeSummary_Response = {
  ReturnCode: string;
  ReturnMsg: string;
  REF_CUR: T_FolioSchemeSummary[];
};

export type DownloadCapitalGainStatement_ReqPayload = {
  request: string;
};

export type DownloadCapitalGainStatement_Response = {
  ReturnCode: string;
  ReturnMsg: string;
  Statement: string;
};

export type T_FetchAccountStatement_ReqPayload = {
  request: string;
};

export type T_FetchAccountStatement_Response = {
  AccountStatementFormat: string;
  AccountStmtData: string;
  Address: string;
  Email: string;
  FileFormat: string;
  FileName: string;
  Flag: string | null;
  Mobile: string;
  ReturnCode: string;
  ReturnMsg: string;
  ShortUrl: string | null;
  UDP: string | null;
};

export const validatePanBody: T_ValidatePan_Request = {
  PANNo: "",
  AadharNo: null,
  DOB: "",
  ClientIpAddress: null,
  IMEI: null,
  OS: null,
  IsNew: null,
  isMinor: null,
  Channel: null,
  PageURL: null,
  Campaign: null,
  Trans_No: null,
  TransactionType: null,
  ServerIpAddress: null,
  ModuleName: null,
  MethodName: null,
  AuthCode: null,
  GoogleAdID: null,
  DeviceId: null,
  UserName: null,
  isFolioRequired: true,
};

export type T_FetchFolioFunctionProps = {
  folioString?: string;
  userDetails?: T_UserProfile & T_GetCustomerLobDetailsRes;
  isPrimary?: boolean;
};

export type T_LastTransactionBroker_Request = {
  request: string;
};

export type TransactionBroker = {
  BrokerCode: string;
  BrokerName: string;
  IsLastTransacted: string;
};

export type T_LastTransactionBroker_Response = {
  ReturnCode: string;
  ReturnMsg: string;
  ShortUrl: string;
  UDP: string;
  ActiveFolioNo: string;
  MandateBroker: string;
  MandateRIA: string;
  PrimeFolioNo: string;
  RIACode: string;
  listTransactionBroker: TransactionBroker[];
};

export type T_LstAvailabletoLink = {
  ApplicantName: string;
  FolioNo: string;
  GPan: string;
  GuardianName: string;
  HoldingMode: string;
  JH1Name: string;
  JH1Pan: string;
  JH2Name: string;
  JH2Pan: string;
  PanNo: string;
};

export type T_LstLinkFolio = T_LstAvailabletoLink;

export type T_DelinkFolioList_Response = {
  ReturnCode: string;
  ReturnMsg: string;
  ShortUrl: null | string;
  UDP: null | string;
  RequestType: string;
  lstAvailabletoLink: T_LstAvailabletoLink[];
  lstLinkFolio: T_LstLinkFolio[];
};

export type T_DelinkFolioList_Request = {
  PanNumber: string;
  CIINumber: string;
  UserId: string;
  Password: string;
  Source: null | string;
};

export type T_FolioLinkingDeLinking_Response = {
  ReturnCode: string;
  ReturnMsg: string;
  UDP: null | string;
};

export type T_FolioLinkingDeLinking_Request = {
  PrimaryFolio: string;
  PrimaryPan: string;
  CIINumber: string;
  LinkFolio: string;
  LinkPan: string;
  IsLink: string;
  UserId: string;
  Password: string;
};
