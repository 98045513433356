import React, { useCallback, useEffect, useState } from "react";
import {
  Link,
  Typography,
  Box,
  Container,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  useLazyCreatePaymentOrderQuery,
  useLazyFetchOrderStatusQuery,
} from "../Homeloan_Api";
import { useDispatch } from "react-redux";
import {
  T_CreateOrderRequest,
  T_CreateOrderResponse,
  T_OrderStatusRequest,
  T_SummaryData,
} from "../Homeloan_Types";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux-store/CustomReduxHooks";
import dayjs from "dayjs";
import { hlIndexFinder } from "../HomeloanDetails/HomeloanDetails";
import { getRupeeFormat } from "../hlDashboardCard/HlInformationCard";
import "./EMI_payment.scss";
import { R_HLRoutes } from "../Homeloan_Routes";
import PaymentResult from "./PaymentResult";
import { updateLoadingState } from "../../Feature_Loading/Loading_Slice";
import { setEmiPaymentOrderInfo } from "../Homeloan_Slice";
import { PaymentStatusList } from "../../../utils/constants";
import { R_HomeRoutes } from "../../../pages/Home/Home_Routes";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";

type workflowInfo = {
  [key: string]: any;
};
const EMI_payment: React.FC = () => {
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const dispatch = useDispatch();
  const [showPayment, setIsShowPayment] = useState<boolean>(false);
  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );
  const paymentInfo: T_CreateOrderResponse = useAppSelector(
    (state) => state.HomeLoanSlice.paymentInfo
  );
  const [agreed, setAgreed] = useState(false);
  const [createOrderApi] = useLazyCreatePaymentOrderQuery();
  const [fetchOrderStatusApi] = useLazyFetchOrderStatusQuery();
  const [status, setStatus] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [plOrderId, setPlOrderId] = useState<string>("");
  const [reduxValues, setReduxValues] = useState<workflowInfo>({});
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const { state } = useLocation();
  const accountNumber = state?.accountNumber || "";
  // need to comments this test data
  // const summaryData: T_SummaryData = mockSummaryData.Summary_Data[0];

  useEffect(() => {
    if (allSummaryData.length > 0 && accountNumber !== null) {
      const idx = hlIndexFinder(allSummaryData, accountNumber);
      const summaryData = allSummaryData[Number(idx)];
      setReduxValues({
        borrowerDetails: summaryData?.borrower_detail?.rows[0] || [],
        loanInfo: summaryData?.loan_info?.rows[0] || [],
        accountDetail: summaryData?.account_detail?.rows[0] || [],
      });
    }
  }, [allSummaryData, accountNumber]);

  useEffect(() => {
    if (
      searchParams.get("txn_status") !== null &&
      searchParams.get("pl_order_id") !== null &&
      searchParams.get("pl_order_id") !== ""
    ) {
      if (PaymentStatusList.includes(searchParams.get("txn_status") || "")) {
        setIsShowPayment(true);
        setStatus(searchParams.get("txn_status") || "");
        if (searchParams.get("txn_status") !== "SUCCESS") {
          setPlOrderId(searchParams.get("pl_order_id") || "");
        } else {
          setPlOrderId("");
        }
      } else {
        navigate(R_HomeRoutes.home);
      }
    } else {
      appDispatch(updateLoadingState(false));
      setIsShowPayment(false);
    }
  }, [searchParams, appDispatch]);

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const createPaymentOrder = useCallback(() => {
    setIsBtnLoading(true);
    const reqPayload: T_CreateOrderRequest = {
      merc_order_date: dayjs().format("YYYYMMDDHHMMss"),
      merc_id: "MRC0001",
      amount: reduxValues.loanInfo?.AMOUNT_OVERDUE || "0",
      return_url:
        process.env.REACT_APP_ABCD_SUPERAPP_URL + R_HLRoutes.emi_payment,
      product_type: "Overdue Payment",
      product_id: reduxValues.loanInfo?.LOAN_ACCOUNT_NUMBER,
      customer_id: reduxValues.accountDetail?.CUSTOMER_NO,
      customer_name: reduxValues.borrowerDetails?.MOBILE,
      customer_email: reduxValues.borrowerDetails?.E_MAIL,
      customer_phone: "",
      udf1: "",
      udf2: "",
      order_desc: "",
      account_no: "",
      account_ifsc: "",
    };
    createOrderApi(reqPayload).then((res) => {
      if (res.data?.code === 1) {
        setIsShowPayment(true);
        dispatch(
          setEmiPaymentOrderInfo({
            payment_data: res.data.payload.payment_data,
            order_data: res.data.payload.order_data,
          })
        );
        setIsBtnLoading(false);
        window.location.href =
          res.data.payload.order_data.links.payment_link_web;
      }
      if (res.data?.code !== 1) {
        setIsBtnLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "Create order API failed",
          })
        );
      }
    });
  }, [accountNumber, reduxValues]);

  const fetchOrderStatus = useCallback(() => {
    appDispatch(updateLoadingState(true));
    setStatus("");
    const reqPayload: T_OrderStatusRequest = {
      merc_order_id: plOrderId,
      merc_id: "MRC0001",
    };
    fetchOrderStatusApi(reqPayload).then((res) => {
      appDispatch(updateLoadingState(false));
      if (res?.data?.code === 1) {
        setStatus(res.data.payload.order_data.status);
      } else {
        setStatus("FAILURE");
      }
    });
  }, [plOrderId, appDispatch, fetchOrderStatusApi]);

  const handleTryAgain = useCallback(() => {
    setStatus("");
    window.location.href = paymentInfo.order_data.links.payment_link_web;
  }, [paymentInfo]);

  const handleCallback = useCallback(
    (status: string) => {
      switch (status) {
        case "PENDING":
          fetchOrderStatus();
          break;
        case "FAILURE":
          handleTryAgain();
          break;
      }
    },
    [fetchOrderStatus, handleTryAgain]
  );

  const handleTermsAndConditionsClick = useCallback(() => {
    navigate(R_HLRoutes.terms_and_conditions);
  }, [navigate]);

  return (
    <Box className="emi-payment-page">
      {showPayment && (
        <PaymentResult handleClick={handleCallback} status={status} />
      )}
      {Object.keys(reduxValues).length > 0 && (
        <Container className="emi-payment-page-container">
          <Box sx={{ width: "100%" }}>
            <Box className="hl-custom-breadcrumb">
              <CustomBreadcrumbs
                data={["Home loan", "EMI Payment"]}
                className="hl-page-breadcrumb"
              />
              <CustomHelpComponent />
            </Box>
            <Box>
              <Typography variant="h3">EMI & Overdue Payment</Typography>
              {/* <Box className="emi-payment-breakup">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle2" className="emi-payment-label">
                  EMI
                </Typography>

                <Typography variant="h5">
                  {getRupeeFormat(reduxValues.accountDetail?.NEXT_DUE_AMOUNT)}
                </Typography>
              </Box>
              <Box
                mt={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="subtitle2" className="emi-payment-label">
                  Amount overdue + charges
                </Typography>

                <Typography variant="h5">
                  {getRupeeFormat(reduxValues.loanInfo??.AMOUNT_OVERDUE)}
                </Typography>
              </Box>
            </Box> */}
              <Box className="emi-payment-total">
                <Typography variant="subtitle2" className="emi-payment-label">
                  Total outstanding amount
                </Typography>
                <Typography
                  variant="h2"
                  sx={{ fontSize: "2rem !important", marginTop: "0.438rem" }}
                >
                  {getRupeeFormat(reduxValues.loanInfo?.AMOUNT_OVERDUE)}
                </Typography>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox checked={agreed} onChange={handleAgreeChange} />
                }
                label={
                  <Typography variant="subtitle2">
                    I agree with the{" "}
                    <Link
                      // href={R_HLRoutes.terms_and_conditions}
                      color="error"
                      underline="none"
                      onClick={handleTermsAndConditionsClick}
                    >
                      terms & conditions
                    </Link>
                  </Typography>
                }
                className="terms-condition"
              />
            </Box>
          </Box>

          <Box className="continue-btn-box">
            <Box sx={{ padding: "0rem 1rem" }}>
              {isBtnLoading ? (
                <CustomThreeDotsLoader />
              ) : (
                <Button
                  className="continue-btn"
                  disabled={!agreed}
                  onClick={createPaymentOrder}
                >
                  Continue
                </Button>
              )}
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
};
export default EMI_payment;
