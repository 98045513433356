import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  T_AppMobileNumberObj,
  T_HealthInsuranceSlice,
  T_PolicyInsured,
} from "./HealthInsurance_Types";

const initialState: T_HealthInsuranceSlice = {
  allPolicyDetails: [],
  secondaryAllPolicyDetails: [],
  allPolicyInsuredDetails: [],
  medicalReportsInlineBackFunClicked: false,
  medicalReportDownloaded: false,
};

export const HealthInsuranceSlice = createSlice({
  name: "HealthInsuranceSlice",
  initialState,
  reducers: {
    setHiAllPolicyDetails: (
      state,
      action: PayloadAction<T_AppMobileNumberObj[]>
    ) => {
      return {
        ...state,
        allPolicyDetails: action.payload,
      };
    },
    setHiSecondaryAllPolicyDetails: (
      state,
      action: PayloadAction<T_AppMobileNumberObj[]>
    ) => {
      return {
        ...state,
        secondaryAllPolicyDetails: action.payload,
      };
    },
    setHiAllPolicyInsuredDetails: (
      state,
      action: PayloadAction<T_PolicyInsured[]>
    ) => {
      return {
        ...state,
        allPolicyInsuredDetails: action.payload,
      };
    },
    updateMedicalReportInlineFunClicked: (
      state,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        medicalReportsInlineBackFunClicked: action.payload,
      };
    },
    updateMedicalReportDownloaded: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        medicalReportDownloaded: action.payload,
      };
    },
  },
});

export const HealthInsuranceReducer = HealthInsuranceSlice.reducer;
export const HealthInsuranceSliceKey = HealthInsuranceSlice.reducerPath;
export const {
  setHiAllPolicyDetails,
  setHiSecondaryAllPolicyDetails,
  setHiAllPolicyInsuredDetails,
  updateMedicalReportInlineFunClicked,
  updateMedicalReportDownloaded,
} = HealthInsuranceSlice.actions;
