import { Box, Grid, Radio, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { Colors } from "../../../../styles/constant";

interface RadioProps {
  title: string;
  value: string;
  handleRadioChange: Function;
  selectedValue: string;
}
const CustomRadio: FC<RadioProps> = ({
  title,
  value,
  handleRadioChange,
  selectedValue,
}) => {
  const handleChange = useCallback(() => {
    handleRadioChange(value);
  }, [value]);

  return (
    <Box
      className={`sl-custom-radio ${
        selectedValue === value ? "sl-custom-radio-active" : ""
      } `}
      onClick={handleChange}
    >
      <Radio
        checked={selectedValue === value}
        sx={{
          padding: 0,
          color: Colors.black,
          "&.Mui-checked": {
            color: Colors.red,
          },
          "& .MuiSvgIcon-root": {
            fontSize: 20,
          },
        }}
      />
      <Typography variant="h5" sx={{ marginLeft: "0.625rem" }}>
        {title}
      </Typography>
    </Box>
  );
};
export default CustomRadio;
