import { FC } from "react";
import { Box, Button, Typography } from "@mui/material";
import AlertIcon from "../../../../assets/icons/alert.svg";
import "../../ProposerDetails/ProposerDetails.scss";

interface Props {
  handleClick: Function;
}
const PremiumChangeWarning: FC<Props> = ({ handleClick }) => {
  return (
    <Box className="premium-change-warning">
      <img src={AlertIcon} style={{ marginBottom: ".5rem" }} />
      <Typography variant="h5" sx={{ marginBottom: "1.5rem !important" }}>
        PREMIUM CHANGE WARNING
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ marginBottom: "1.5rem !important" }}
      >
        Changing this detail might impact your premium
      </Typography>
      <Button
        fullWidth
        variant="contained"
        className="continue-btn"
        onClick={() => handleClick()}
      >
        I understand
      </Button>
    </Box>
  );
};

export default PremiumChangeWarning;
