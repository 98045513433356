import axios from "axios";
import {
  Api_AccountStatement,
  APi_CMS_ErrorDetails,
  APi_CMS_GetHamburgerMenu,
  APi_CMS_IntegrationVariables,
  APi_CMS_PageVariables,
  Api_FetchPolicyAllDetails,
  Api_FetchPolicyDetails,
  Api_FetchPolicyDetailsByMobileNumber,
  Api_FetchProfile_Details,
  APi_FolioSchemeSummary,
  Api_GenerateSso_New,
  Api_GenerateSso_Old,
  Api_ValidatePan,
  Api_ValidateSso_New,
  Api_ValidateSso_Old,
} from "../utils/ApiEndpoints";
import { decryptString, getStaticReqPayload } from "../utils/helper";
import { USE_DEV_TEST_DATA, staticReqPayloadApis } from "../utils/constants";
import {
  E_CampaignIdentifierKeys,
  E_ChannelOptions,
  T_CampaignHeaders,
} from "../globalTypes/GlobalTypes";
import CsSessionStorageUtils from "../utils/CsSessionStorageUtils";

let store: any = undefined;

const encryptedResponseAPIList = [
  Api_FetchPolicyAllDetails,
  Api_FetchPolicyDetails,
  Api_FetchProfile_Details,
  Api_ValidatePan,
  APi_FolioSchemeSummary,
  Api_AccountStatement,
  Api_FetchPolicyDetailsByMobileNumber,
  APi_CMS_GetHamburgerMenu,
  APi_CMS_IntegrationVariables,
  APi_CMS_PageVariables,
  //APi_CMS_ErrorDetails
];

export const injectStore = (_store: any) => {
  store = _store;
};
const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    // Add any other headers or configurations you need
  },
});

const testDataUrls = ["/abfssl/fetch-customer-and-lob-details"];

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can modify the request config here, e.g., add authentication headers
    if (window.location.hostname.startsWith("localhost")) {
      if (
        USE_DEV_TEST_DATA &&
        staticReqPayloadApis.includes(config?.url || "")
      ) {
        config.data = getStaticReqPayload(config);
      }
    }
    let externalToken = undefined;
    let internalToken = undefined;
    let source = undefined;
    let csUserId = undefined;
    try {
      const csAppSlice = store.getState()?.CsAppSlice;
      externalToken = csAppSlice.tokenDetails?.external.access_token;
      internalToken = csAppSlice.tokenDetails?.internal.authToken;
      source = csAppSlice?.deviceType;
      csUserId = csAppSlice.csUserId || csAppSlice.userDetails.CS_USER_ID;
    } catch (err) {}
    if (
      [Api_GenerateSso_New, Api_GenerateSso_Old].includes(config?.url || "")
    ) {
      config.headers["cognito-token "] = `Bearer ${externalToken}`;
    } else if (
      [Api_ValidateSso_New, Api_ValidateSso_Old].includes(config?.url || "")
    ) {
      config.headers["cognito-token "] = `Bearer ${externalToken}`;
    } else if (config.url !== "/auth/generate-token") {
      config.headers["authToken"] = externalToken;
      config.headers["Authorization"] = `Bearer ${internalToken}`;
    }
    if (
      store.getState()?.CsAppSlice.channel ===
      E_ChannelOptions.SECONDARY_CHANNEL
    ) {
      /**
       * to add utm related headers in case of secondary channels
       */
      const campaignIdentifiers: T_CampaignHeaders[] = JSON.parse(
        CsSessionStorageUtils.get("CAMPAIGN_IDENTIFIERS")
      );
      campaignIdentifiers?.forEach((obj: T_CampaignHeaders) => {
        config.headers[obj.key] = obj.value;
      });
    }
    config.headers["csUserId"] = csUserId;
    config.headers["source"] = source;
    config.headers["traceId"] = store.getState()?.CsAppSlice.traceId;
    config.headers["pageName"] = window.location.pathname;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // You can modify the response data here, e.g., handling pagination
    if (encryptedResponseAPIList.includes(response.config.url || "")) {
      const configuration =
        store.getState()?.CsAppSlice.tokenDetails?.configuration;
      const IV = configuration?.iv || "";
      const secretKey = configuration?.key || "";
      const decryptedResponse = decryptString(
        response.data.payload,
        IV,
        secretKey
      );
      let transformedResponse = decryptedResponse;
      if (decryptedResponse) {
        transformedResponse = JSON.parse(decryptedResponse);
      }
      response.data.payload = transformedResponse;
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
