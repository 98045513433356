import { Box, Typography } from "@mui/material";
import { FC, useCallback } from "react";

interface TypeProps {
  values: any[];
  type: string;
  handleFundRedirection: Function;
  selectedValue: string;
}

const CustomLinkOptions: FC<TypeProps> = ({
  values = [],
  type = "",
  handleFundRedirection,
  selectedValue,
}) => {
  console.log(values, "values", selectedValue);
  const handleChange = useCallback(
    (val: string) => {
      handleFundRedirection(val);
    },
    [handleFundRedirection]
  );
  return (
    <Box className={`li-sa-fv-link-box`}>
      {values.map((data) => {
        return (
          <CustomNavigationLink
            handleFundRedirection={handleChange}
            data={data}
            type={type}
            selectedValue={selectedValue}
          />
        );
      })}
    </Box>
  );
};
export default CustomLinkOptions;

interface LinkProps {
  data: { title: string; url: string };
  type: string;
  handleFundRedirection: Function;
  selectedValue: string;
}

const CustomNavigationLink: FC<LinkProps> = ({
  data,
  type = "",
  selectedValue,
  handleFundRedirection,
}) => {
  console.log(selectedValue, data?.title, "seafdkllk");
  const handleRedirection = useCallback(() => {
    if (data.title === "Know Funds") {
      handleFundRedirection(data.title);
    } else {
      window.location.href = data.url;
    }
  }, []);
  return (
    <Box>
      <Typography
        onClick={handleRedirection}
        variant="h5"
        className="li-sa-fv-link-item"
        sx={{
          fontWeight:
            selectedValue === data.title ? "700 !important" : "500 !important",
        }}
      >
        {data.title}
      </Typography>
    </Box>
  );
};
