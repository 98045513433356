import { FC, useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightIcon from "../../../assets/icons/red-arrow-right.svg";
import CopyIcon from "../../../assets/icons/content-copy.svg";
import DownloadIcon from "../../../assets/icons/download.svg";
import EmailIcon from "../../../assets/icons/email red.svg";
import CertificateIcon from "../../../assets/icons/certificate.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import "./InterestCertificate.scss";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import {
  hlIndexFinder,
  HomeLoanPoweredBy,
} from "../HomeloanDetails/HomeloanDetails";
import { useLocation } from "react-router-dom";
import {
  CustomerRequest,
  T_ReportingService_RequestData,
  T_SummaryData,
} from "../Homeloan_Types";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import { Colors } from "../../../styles/constant";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CommonSuccessPopupBody";
import CustomErrorPopupBody from "../../../components/CustomErrorPopupBody/CustomErrorPopupBody";
import { useNavigate } from "react-router-dom";
import {
  useLazyCreateCaseQuery,
  useLazyReportingServiceQuery,
} from "../Homeloan_Api";
import { useDispatch } from "react-redux";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { downloadPdfFromBase64 } from "../../../utils/helper";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import useWindowSize from "../../../hooks/useWindowSize";
import { tabViewPoint } from "../../../utils/constants";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";

const getCustomerRequest = (
  year: string,
  summaryData: T_SummaryData,
  option: string
) => {
  const currentYear = new Date().getFullYear().toString();
  const isCurrentFinancialYear = year.includes(currentYear);
  const financialYear = year.split("-");
  const customerRequest: CustomerRequest = {
    customerId: summaryData.account_detail.rows[0].CUSTOMER_NO,
    accountNumber: summaryData.Benificiary_Details.rows[0].LOAN_ACCOUNT_NO,
    firstName: summaryData.account_detail.rows[0].FNAME,
    lastName: summaryData.account_detail.rows[0].LNAME,
    mobile: summaryData.borrower_detail.rows[0].MOBILE,
    emailId: summaryData.borrower_detail.rows[0].E_MAIL,
    subject: isCurrentFinancialYear
      ? "Provisional Interest Certificate"
      : "Interest certificate",
    source: "ABC_Servicing_App",
    subtype: isCurrentFinancialYear ? "hfl078" : "hfl037",
    comments: "",
    isClosed: true,
    url: "",
    category: "Request",
    lob: "HFC",
    priority: "Low",
    segment: "Finance",
    callbackDate: "",
    callbackTimeSlot: "",
    stpsRequestType: option,
    asOnDate: "",
    salaryCreditDate: "",
    dueDate: "",
    changeInEmiAmount: "",
    payableCharge: "",
    partPaymentAmount: "",
    impact: "",
    modeOfRepayment: "",
    soaFromDate: "",
    soaToDate: "",
    icFromYear: financialYear[0].trim(),
    icToYear: financialYear[1].trim(),
    attachments: null,
  };
  return customerRequest;
};

const getReportingServiceRequest = (
  year: string,
  summaryData: T_SummaryData,
  caseId: string
) => {
  const currentYear = new Date().getFullYear().toString();
  const isCurrentFinancialYear = year.includes(currentYear);
  return {
    userCredentials: {
      userId: "CPORTAL",
      userPassword:
        "465c194afb65670f38322df087f0a9bb225cc257e43eb4ac5a0c98ef5b3173ac",
    },
    caseId: caseId,
    source: "CRM",
    requestType: "View",
    lan: summaryData.Benificiary_Details.rows[0].LOAN_ACCOUNT_NO,
    reportName: "InterestCertificate",
    documentType: "BinaryData",
    asOnDate: "",
    fromDate: "",
    toDate: "",
    financialYear: isCurrentFinancialYear ? "" : year,
  };
};

const getDateFromString = (date: string) => date?.split("/")[2].trim();
const generateFinancialYearsFromDate = (dateStr: string) => {
  const financialYears = [];

  // Extract the year from the provided date string
  const startYear = Number(getDateFromString(dateStr));
  const currentYear = new Date().getFullYear(); // Get the current year
  const endYear = currentYear; // End year is the current year

  // Generate financial years
  for (let year = startYear; year <= endYear; year++) {
    const nextYear = year + 1;
    const financialYear = `${year}-${nextYear.toString().slice(-2)}`;
    const financialYearValue = `${year}-${nextYear.toString()}`;
    financialYears.push({
      label: financialYear,
      value: financialYearValue,
    });
  }

  return financialYears;
};

const options = [
  {
    text: "Download",
    icon: DownloadIcon,
  },
  {
    text: "Email",
    icon: EmailIcon,
  },
];

const InterestCertificate: FC<any> = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [open, setOpen] = useState<boolean>(false);
  const [financialYear, setFinancialYear] = useState<string>("");
  const [docType, setDocumentType] = useState("");
  const [isDownloadOptionOpen, setDownloadOptionOpen] =
    useState<boolean>(false);
  const [isApiSuccess, setIsApiSuccess] = useState<boolean>(false);
  const [isApiPopupOpen, setApiPopup] = useState<boolean>(false);
  const [generatedSrNumber, setSrNumber] = useState<string | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [copiedText, showCopiedText] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [createCase] = useLazyCreateCaseQuery();
  const [reportingService] = useLazyReportingServiceQuery();

  const allSummaryData: T_SummaryData[] = useAppSelector(
    (state) => state.HomeLoanSlice.summaryData
  );

  const { state } = useLocation();
  const accountNumber = state?.accountNumber || "";

  const idx = hlIndexFinder(allSummaryData, accountNumber);
  const summaryData = allSummaryData[Number(idx)];

  console.log("summaryData", summaryData);

  const financialYears = generateFinancialYearsFromDate(
    summaryData?.Disbursement_History?.rows[0]?.DISBRUSMENT_DATE
  );

  const fetchCreateCaseData = async (
    request: CustomerRequest,
    type: string
  ) => {
    await createCase(request)
      .then(async (res) => {
        if (res.isError || res.data?.code !== 1) {
          dispatch(
            setError({
              error: new Error("Error"),
              message: "Exit Load API failed",
            })
          );
        }
        if (res.data) {
          try {
            const response = res.data.payload;
            const srNumber = res.data?.payload?.caseId
              ? res.data?.payload?.caseId
              : res.data?.payload?.existingCaseId;

            // return srNumber;
            if (srNumber === undefined || srNumber === null) {
              setIsApiSuccess(false);
            } else {
              setSrNumber(srNumber);
              if (type === "Download") {
                const reportingServiceRequest = getReportingServiceRequest(
                  financialYear,
                  summaryData,
                  srNumber
                );
                await fetchReportingServiceData(reportingServiceRequest);
              } else {
                setIsApiSuccess(true);
              }
            }
            setApiPopup(true);
          } catch {
            throw new Error("Unable to extract data");
          }
        }
      })
      .catch((error) => {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "Exit Load API failed",
          })
        );
        return null;
      });
  };

  const fetchReportingServiceData = async (
    request: T_ReportingService_RequestData
  ) => {
    await reportingService(request)
      .then((res) => {
        if (res.isError || res.data?.code !== 1) {
          dispatch(
            setError({
              error: new Error("Error"),
              message: "Exit Load API failed",
            })
          );
        }
        if (res.data) {
          try {
            const response = res.data.payload;
            const binaryDataList = response.binaryDataList;
            if (binaryDataList === undefined || binaryDataList === null) {
              setIsApiSuccess(false);
            } else {
              downloadPdfFromBase64(
                binaryDataList[0],
                `${summaryData.account_detail.rows[0].FNAME}_${summaryData.account_detail.rows[0].LNAME}.pdf`
              );
              setIsApiSuccess(true);
            }
          } catch {
            throw new Error("Unable to extract data");
          }
        }
      })
      .catch((error) => {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "Exit Load API failed",
          })
        );
        return null;
      });
  };

  const getDocument = async (type: string) => {
    setDocumentType(type);
    // api call
    const request = getCustomerRequest(financialYear, summaryData, type);
    setIsBtnLoading(true);
    await fetchCreateCaseData(request, type);
    setIsBtnLoading(false);
  };

  const copyText = useCallback(() => {
    let timeout: any;
    navigator.clipboard
      .writeText(`${generatedSrNumber}`)
      .then(() => {
        showCopiedText(true);
        timeout = setTimeout(() => {
          showCopiedText(false);
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });

    return () => clearTimeout(timeout);
  }, [generatedSrNumber]);

  return (
    <Box className="hl-ic-body">
      <Container sx={{ marginBottom: "2rem" }}>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 10,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <CustomBreadcrumbs
            data={["Home loan", accountNumber || "...", "Interest certificate"]}
            className="hl-page-breadcrumb"
          />
          <CustomHelpComponent />
        </Box>
        <Box className="content-wrapper">
          <Typography variant="h3">Interest certificate</Typography>
          <Stack rowGap={"-0.5rem"} marginTop={"0.8rem"}>
            <Stack direction="row" columnGap={1}>
              <Typography
                variant="subtitle2"
                color={Colors.textGray}
                fontSize={"0.875rem"}
              >
                Disbursement year :
              </Typography>
              <Typography
                variant="h5"
                color={Colors.textGray}
                fontSize={"0.875rem"}
              >
                {getDateFromString(
                  summaryData?.Disbursement_History?.rows[0]?.DISBRUSMENT_DATE
                )}
              </Typography>
            </Stack>
            <Box display="flex" gap="1.875rem" marginTop="0.4rem">
              <Box flex={1}>
                <CustomSelect
                  customPopupClass="hl-select-popup"
                  label=""
                  value={financialYear}
                  onChange={setFinancialYear}
                  options={financialYears}
                  placeholder="Select financial year"
                />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Container>
      <Box margin="auto 1rem 0">
        <Button
          fullWidth
          variant="contained"
          className="continue-btn"
          onClick={() => setDownloadOptionOpen(true)}
          disabled={financialYear.trim() === ""}
        >
          Continue
        </Button>
        <Stack
          display="flex"
          justifyContent="center"
          alignItems="center"
          rowGap={2}
          marginTop={"1rem"}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography
              variant="subtitle2"
              color={Colors.textGray}
              fontSize={"0.875rem"}
              textAlign="center"
              sx={{
                width: {
                  xs: "12rem",
                  sm: "auto",
                },
              }}
            >
              Document will be emailed to your registered email ID
            </Typography>
            {/* <Typography
                variant="subtitle2"
                color={Colors.textGray}
                fontSize={"0.875rem"}
              >
                registered email ID
              </Typography> */}
          </Box>
          <Box marginTop={"-1rem"}>
            <HomeLoanPoweredBy />
          </Box>
        </Stack>
      </Box>

      {isDownloadOptionOpen && (
        <Box className="quick-actions-menu-hl">
          <CustomPopup
            open={isDownloadOptionOpen}
            handleOpen={() => {
              setDownloadOptionOpen(!isDownloadOptionOpen);
            }}
            className={`service-menu-popup ${
              width < tabViewPoint ? "service-menu-popup-hl-ic" : ""
            }`}
            btnLabel="Close"
            btnStartIcon={
              <CloseIcon
                sx={{ height: "1rem", width: "1rem", marginTop: ".125rem" }}
              />
            }
            customeGetHelpHandler={() => {}}
            anchor="top"
          >
            <Stack rowGap={2}>
              <Grid container marginBottom={"1rem"}>
                <Grid
                  item
                  xs={3}
                  sm={1}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <KeyboardArrowLeftIcon
                    sx={{ color: Colors.red, cursor: "pointer" }}
                    onClick={() => {
                      setDownloadOptionOpen(!isDownloadOptionOpen);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={11}
                  display="flex"
                  sx={{
                    marginLeft: {
                      xs: undefined,
                      sm: "-4rem",
                    },
                  }}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    columnGap={1}
                    display="flex"
                    alignItems="center"
                    sx={{
                      paddingLeft: {
                        xs: "1rem",
                        sm: "4rem",
                      },
                    }}
                  >
                    <img
                      src={CertificateIcon}
                      alt="help-icon"
                      style={{ width: "1rem", height: "1rem" }}
                      loading="lazy"
                    />
                    <Typography
                      variant="subtitle2"
                      color={Colors.black}
                      sx={{ marginBottom: "0.2rem" }}
                    >
                      Interest Certificate
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              {isBtnLoading ? (
                <CustomThreeDotsLoader />
              ) : (
                options.map((option) => {
                  return (
                    <Stack
                      direction="row"
                      display="flex"
                      justifyContent="space-between"
                      className="download-options"
                      sx={{
                        padding: "0.65rem",
                        borderRadius: "0.31rem",
                        cursor: "pointer",
                      }}
                      onClick={() => getDocument(option.text)}
                    >
                      <Typography
                        variant="subtitle2"
                        color={Colors.black}
                        marginTop={"0.1rem"}
                        fontSize={"1rem"}
                      >
                        {option.text}
                      </Typography>
                      <img
                        src={option.icon}
                        alt="help-icon"
                        style={{ width: "1.5rem", height: "1.5rem" }}
                        loading="lazy"
                      />
                    </Stack>
                  );
                })
              )}
            </Stack>
          </CustomPopup>
        </Box>
      )}
      {isApiPopupOpen && (
        <CustomPopup
          open={isApiPopupOpen}
          handleOpen={() => setApiPopup(false)}
          btnLabel={!isApiSuccess ? "Go to Interest certificate page" : ""}
          className="download-statement-popup"
          btnEndIcon={
            !isApiSuccess ? (
              <img
                src={ArrowRightIcon}
                alt="right-arrow"
                style={{ color: Colors.red }}
                loading="lazy"
              />
            ) : null
          }
        >
          {isApiSuccess ? (
            <CommonSuccessPopupBody
              title=""
              des={
                docType === "Email"
                  ? "Interest certificate will be emailed to your registered email ID shortly"
                  : "Interest certificate has been downloaded successfully"
              }
              // withInfoText={`The password to the document is PAN of loan's main applicant`}
              subDescription="The password to the document is PAN of loan's main applicant"
              btnLabel="Go back to homepage"
              btnOnClick={() => navigate("/home")}
              btnDisabled={false}
              extraInfo={
                <Stack rowGap={1}>
                  <Stack
                    className="hl-generated-sr"
                    rowGap={1}
                    marginTop={"-1.7rem"}
                  >
                    <Typography
                      variant="subtitle2"
                      color={Colors.textGray}
                      fontSize={"1.125rem"}
                    >
                      Service request number
                    </Typography>
                    <Stack direction="row" columnGap={2}>
                      <Typography
                        variant="h5"
                        color={Colors.black}
                        fontSize={"1.125rem"}
                      >
                        {generatedSrNumber}
                      </Typography>
                      <img
                        src={CopyIcon}
                        alt="copy"
                        loading="lazy"
                        onClick={() =>
                          navigator.clipboard.writeText(`${generatedSrNumber}`)
                        }
                      />
                    </Stack>
                  </Stack>
                  {copiedText && (
                    <Typography variant="h6" color={Colors.black}>
                      Copied!
                    </Typography>
                  )}
                </Stack>
              }
              isChangesForHomeLoan={true}
            />
          ) : (
            <CustomErrorPopupBody
              title="Document is unavailable"
              btnLabel="Connect to customer support"
            />
          )}
        </CustomPopup>
      )}
    </Box>
  );
};

export default InterestCertificate;
