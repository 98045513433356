import React, { useState } from "react";
import { Box, Button, Typography, TextField, Checkbox } from "@mui/material";
import "./MobileNumberEntry.scss";
import { Colors } from "../../../styles/constant";
import InputAdornment from "@mui/material/InputAdornment";
import AlertRhombus from "../../../assets/icons/alert-rhombus.svg";
import PolicyAndConditionsComponent from "../../../components/CustomPolicyAndConditionsComponent/PolicyAndConditionsComponent";
import {
  phoneRegex,
  privacyPolicyTitle,
  termsAndConditionsTitle,
} from "../../../utils/constants";
import { useLazyStoreUserConsentQuery } from "../../Feature_GeneralAppElements/GeneralAppElements_Api";
import {
  useLazyCheckIfUserConsentRequiredQuery,
  useLazyGenerateOtpQuery,
} from "../Login_Api";
import { useAppDispatch } from "../../../redux-store/CustomReduxHooks";
import { updateLoginData, updateScreenTag } from "../Login_Slice";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { LoginScreenValues } from "../Feature_Login";
import { saveCsUserId } from "../../../redux-store/reducers/CsAppSlice";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import {
  T_ApiResponse,
  T_CsErrorSliceState,
} from "../../../globalTypes/GlobalTypes";

interface ChildComponentProps {
  headerText: string;
  subheaderText: string;
}

const MobileNumberEntry: React.FC<ChildComponentProps> = ({
  headerText,
  subheaderText,
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [helperText, setHelperText] = useState<string>("");
  const [isCustomPopupOpen, setIsCustomPopupOpen] = useState<boolean>(false);
  const [finalPolicyAndTermsTitle, setFinalPolicyAndTermsTitle] =
    useState<string>("");
  const [isGenerateOtpRunning, setIsGenerateOtpRunning] =
    useState<boolean>(false);

  const [askForConsent, setAskForConsent] = useState(false);
  const [isFetchConsentRunning, setIsFetchConsentRunning] = useState(false);
  const [fetchUserConsent] = useLazyCheckIfUserConsentRequiredQuery();
  const [generateOtpApi] = useLazyGenerateOtpQuery();

  const appDispatch = useAppDispatch();
  const [storeUserConsentApi] = useLazyStoreUserConsentQuery();

  const handleMobileNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    setHelperText("");
    if (/^\d{0,10}$/.test(inputValue)) {
      setMobileNumber(inputValue);
    }
    if (inputValue.length === 10 && phoneRegex.test(inputValue)) {
      setIsFetchConsentRunning(true);
      fetchUserConsent(inputValue).then((r) => {
        if (r.data?.code === 1 && r.data?.payload?.CS_USER_ID) {
          appDispatch(saveCsUserId(r.data?.payload.CS_USER_ID));
        }
        if (r.data?.code === 1 && !r.data?.payload?.TNC_CONSENT) {
          setAskForConsent(true);
        } else {
          setAskForConsent(false);
        }
        setIsFetchConsentRunning(false);
      });
    }
  };

  const handleBlurMobileNumber = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    validateInput(event.target.value);
  };

  const validateInput = (mobileInput: string) => {
    // Regular expression for phone number validation
    const phoneRegexValue = /^[6-9]\d{9}$/;
    if (!phoneRegexValue.test(mobileInput)) {
      setHelperText("Invalid mobile number");
    } else {
      setHelperText("");
    }
  };

  const hitApiAndHandleResponse = async () => {
    setIsGenerateOtpRunning(true);
    let isGenerateOtpApiCall = false;
    if (askForConsent) {
      await storeUserConsentApi({
        mobile: mobileNumber,
        termsAndConditionConsent: "true",
        privacyConsent: "true",
      }).then((response) => {
        if (response.data?.code === 0 || response.data?.code == 2) {
          appDispatch(
            setError({
              error: response.data,
              message: "API failed",
            })
          );
          setIsGenerateOtpRunning(false);
          isGenerateOtpApiCall = false;
          return;
        } else if (
          response.data?.code === 1 &&
          response.data?.payload?.csUserId
        ) {
          appDispatch(saveCsUserId(response.data?.payload.csUserId));
        }
        isGenerateOtpApiCall = true;
      });
    } else {
      isGenerateOtpApiCall = true;
    }
    if (isGenerateOtpApiCall) {
      await generateOtpApi({
        mobileNo: "" + mobileNumber,
        otp: "",
      }).then((res) => {
        if (res.data?.code === 1) {
          appDispatch(
            updateLoginData({
              key: "mobilenumber",
              value: mobileNumber,
            })
          );
          appDispatch(updateScreenTag(LoginScreenValues.enterOtpScreen));
        } else if ((res.error as any)?.data?.code === 2) {
          setHelperText((res.error as any)?.data?.uiDisplayMessage);
        } else {
          appDispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "API failed",
            })
          );
        }
        setIsGenerateOtpRunning(false);
      });
    }
  };

  const handlePolicyTermsPopup = (title: string) => {
    setIsCustomPopupOpen(true);
    title === termsAndConditionsTitle
      ? setFinalPolicyAndTermsTitle(termsAndConditionsTitle)
      : setFinalPolicyAndTermsTitle(privacyPolicyTitle);
  };

  let isButtonDisabled =
    !mobileNumber ||
    !phoneRegex.test(mobileNumber) ||
    (askForConsent && !checked);

  return (
    <Box
      className="enter-mobile-number-wrapper"
      minWidth={"12.5rem"}
      width={"100%"}
      maxWidth={"23.9375rem"}
    >
      <Typography
        fontSize={24}
        marginTop="1.5rem"
        fontWeight={700}
        lineHeight={"1.8rem"}
      >
        {headerText}
      </Typography>
      <Typography
        marginTop="0.5rem"
        fontWeight={400}
        fontSize={16}
        lineHeight="1.2rem"
        color="#797979"
      >
        {subheaderText}
      </Typography>

      <TextField
        fullWidth
        label="Mobile Number"
        variant="outlined"
        value={mobileNumber}
        onBlur={handleBlurMobileNumber}
        onChange={handleMobileNumberChange}
        error={helperText.length > 0}
        disabled={isFetchConsentRunning || isGenerateOtpRunning}
        inputProps={{
          maxLength: 10,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        helperText={
          <span style={{ display: "flex", alignItems: "center" }}>
            <InputAdornment component="span" position="start">
              {helperText.length > 0 && <img src={AlertRhombus} />}
            </InputAdornment>
            {helperText}
          </span>
        }
        sx={{ marginTop: "1.5rem" }}
      />
      {headerText === "Welcome to ABC" && askForConsent && (
        <Box marginTop={"1rem"} display="flex" alignItems="center">
          <Checkbox
            checked={checked}
            inputProps={{ "aria-label": "custom checkbox" }}
            onChange={(event: any) => setChecked(event.target.checked)}
            sx={{
              padding: 0,
              color: Colors.red,
              "&.Mui-checked": {
                color: Colors.red,
              },
            }}
          />

          <Typography fontSize={12} fontWeight={500} marginLeft="1rem">
            I agree with
            <span
              style={{ color: Colors.red, cursor: "pointer" }}
              onClick={() => handlePolicyTermsPopup(termsAndConditionsTitle)}
            >
              {" "}
              Terms & Conditions{" "}
            </span>
            and
            <span
              style={{ color: Colors.red, cursor: "pointer" }}
              onClick={() => handlePolicyTermsPopup(privacyPolicyTitle)}
            >
              {" "}
              Privacy Policy{" "}
            </span>
          </Typography>
        </Box>
      )}

      <Box
        display="flex"
        justifyContent="center"
        marginTop="1.5rem"
        marginBottom="1.5rem"
      >
        {isGenerateOtpRunning || isFetchConsentRunning ? (
          <CustomThreeDotsLoader withText={true} />
        ) : (
          <Button
            disabled={isButtonDisabled}
            fullWidth
            variant="contained"
            sx={{
              borderRadius: "1.25rem",
              padding: ".875rem 0",
            }}
            onClick={hitApiAndHandleResponse}
          >
            Continue
          </Button>
        )}
      </Box>
      {isCustomPopupOpen && (
        <PolicyAndConditionsComponent
          open={isCustomPopupOpen}
          handleOpen={setIsCustomPopupOpen}
          title={finalPolicyAndTermsTitle}
        />
      )}
    </Box>
  );
};

export default MobileNumberEntry;
