import React, { FC, useState } from "react";
import CommonMenuListPage from "../../../components/CustomMenuListPage/CommonMenuListPage";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RaiseABug from "../RaiseABug/RaiseABug";
import CallUsPopUp from "../CallUsPopUp/CallUsPopUp";
import EmailUsPopUp from "../EmailUsPopUp/EmailUsPopUp";
import { T_ProfileHamburgerMenu } from "../../Features_Cms/ProfileHamburgerMenu.Types";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import CustomChatWithUs from "../../../components/CustomChatWithUs/CustomChatWithUs";

interface Props {
  handleBackBtnClick: Function;
  pathname?: string;
  setVisibleComponent: Function;
}

const HelpAndSupport: FC<Props> = ({
  handleBackBtnClick,
  pathname,
  setVisibleComponent,
}) => {
  const [showRaiseABug, setShowRaiseABug] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [emailPopupOpen, setEmailPopupOpen] = useState<boolean>(false);
  const [showChatWithUs, setShowChatWithUs] = useState(false);
  const profileHamburgerMenuData: T_ProfileHamburgerMenu[] = useAppSelector(
    (state) => state.CmsSlice.ProfileHamburgerMenuData
  );
  const settingSubMenu = profileHamburgerMenuData
    .find((i) => i.attributes.pathname === "help-&-support")
    ?.attributes.subMenu?.filter((f) => f.isEnable);
  const handleCloseChat = () => {
      setShowChatWithUs(false);
  };
  const handleClick = (pathnameData: string) => {
    if (pathnameData === "report-bug") {
      setShowRaiseABug(true);
    } else if (pathnameData === "email") {
      // const recipientEmail = "care.digital@adityabirlacapital.com";
      // const subject = "Subject of the email";
      // const body = "Body of the email";
      // const mailtoUrl = `mailto:${recipientEmail}?subject=${encodeURIComponent(
      //   subject
      // )}&body=${encodeURIComponent(body)}`;
      // window.open(mailtoUrl, "_blank");
      setEmailPopupOpen(true);
    } else if (pathnameData === "call-us") {
      setOpen(true);
    } else if (pathnameData === "chat-with-us") {
      setShowChatWithUs(true);
    }else if (pathnameData === "FAQs") {
      setVisibleComponent(pathnameData);
    }
  };

  return (
    <>
      <CommonMenuListPage
        title="Help & Support"
        handleBackBtnClick={handleBackBtnClick}
        pathname={pathname}
      >
        <Box>
          {settingSubMenu ? (
            settingSubMenu.filter(item => item.isEnable).map((item) => (
              <React.Fragment key={item.menuTitle}>
                <ListItem
                  key={item.menuTitle}
                  onClick={() => handleClick(item.pathname)}
                  secondaryAction={
                    <IconButton
                      sx={{ marginRight: 0 }}
                      edge="end"
                      aria-label="delete"
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                  sx={{
                    marginY: ".625rem",
                    paddingLeft: "1.5rem",
                    cursor: "pointer",
                  }}
                >
                  <ListItemAvatar>
                    <Avatar src={item.menuIcon.data.attributes.url} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h5" marginBottom={".3125rem"}>
                        {item.menuTitle}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Box>
        <RaiseABug
          showRaiseABug={showRaiseABug}
          handleComponent={setShowRaiseABug}
        />
        <CallUsPopUp open={open} handleOpen={setOpen} />
        <EmailUsPopUp open={emailPopupOpen} handleOpen={setEmailPopupOpen} />
        {showChatWithUs && 
        <CustomChatWithUs 
        showIframe={showChatWithUs} 
        onClose={handleCloseChat}
        functionalityCode="D"
        LobId="25"/>}
      </CommonMenuListPage>
    </>
  );
};

export default HelpAndSupport;
