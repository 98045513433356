import { RouteObject } from "react-router-dom";
import HomeloanDetails from "./HomeloanDetails/HomeloanDetails";
import InterestCertificate from "./HomeloanInterestCertificate/InterestCertificate";
import DisbursementRequest from "./DisbursementRequest/DisbursementRequest";
import EMI_payment from "./EMI_payments/EMI_payment";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import "./HomeLoan.scss";
import HomeLoanStatement from "./HomeLoanStatement/HomeLoanStatement";
import HomeloanChangeEnach from "./HomeloanChangeEnach/HomeloanChangeEnach";
import Homeloanuploaddocument from "./Homeloanuploaddocument/Homeloanuploaddocument";
import HomeloanServiceDetails from "./HomeloanServiceDetails/HomeloanServiceDetails";
import HomeLoanGuard from "./HomeLoanGuard";

export enum R_HLRoutes {
  home_loan_baseUrl = "/details/hl",
  home_loan_details = "/details/hl",
  interest_certificate = "/details/hl/interest-certificate",
  account_statement = "/details/hl/account-statement",
  change_enach = "/details/hl/register-change-e-nach",
  document_upload = "/details/hl/document-upload",
  emi_payment = "/details/hl/emi-payment",
  disbursement_request = "/details/hl/disbursement-request",
  terms_and_conditions = "/details/hl/termsandconditions",
  home_loan_service_request = "/details/hl/service-request",
}
const HomeloanRoutesFn = () => {
  const HomeloanRoutes: RouteObject[] = [
    {
      path: R_HLRoutes.home_loan_baseUrl,
      element: <HomeLoanGuard />,
      children: [
        {
          path: R_HLRoutes.home_loan_details,
          element: <HomeloanDetails />,
        },
        {
          path: R_HLRoutes.interest_certificate,
          element: <InterestCertificate />,
        },
        {
          path: R_HLRoutes.account_statement,
          element: <HomeLoanStatement />,
        },
        {
          path: R_HLRoutes.disbursement_request,
          element: <DisbursementRequest />,
        },
        {
          path: R_HLRoutes.emi_payment,
          element: <EMI_payment />,
        },
        {
          path: R_HLRoutes.terms_and_conditions,
          element: <TermsAndConditions />,
        },
        {
          path: R_HLRoutes.change_enach,
          element: <HomeloanChangeEnach />,
        },
        {
          path: R_HLRoutes.document_upload,
          element: <Homeloanuploaddocument />,
        },
        {
          path: R_HLRoutes.home_loan_service_request,
          element: <HomeloanServiceDetails />,
        },
      ],
    },
  ];
  return HomeloanRoutes;
};
export { HomeloanRoutesFn };
