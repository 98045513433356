import React, { FC, useCallback, useState } from "react";
import {
  Box,
  Drawer,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { T_FetchConfigData_ResponseDetails } from "../GeneralAppElements_Types";
import { DEVICE_TYPE } from "../../../utils/constants";
import useDeviceDetection from "../../../hooks/useDeviceDetection";
import "./CallUsPopUp.scss";

interface Props {
  open: boolean;
  handleOpen: Function;
}

const CallUsPopUp: FC<Props> = ({ open, handleOpen }) => {
  const deviceType = useDeviceDetection();
  let CallUscontactInfo: string | undefined;
  const fetchConfigDataDetails = useAppSelector(
    (state) => state.GeneralAppElementsSlice?.masterData?.supportDataList || []
  );

  const callUsItem = fetchConfigDataDetails.find(
    (item) => item.optionType === "Call Us"
  );
  if (callUsItem) {
    CallUscontactInfo = callUsItem.contactInfo;
  }
  const handleClosePopup = useCallback(() => {
    handleOpen(false);
  }, [handleOpen]);

  const handleCallClick = () => {
    window.location.href = `tel:${CallUscontactInfo}`;
  };

  const renderSteps = () => {
    if (deviceType === DEVICE_TYPE.WEB) {
      return (
        <Box sx={{ textAlign: "center", padding: ".625rem" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
              marginBottom: "1.5rem",
            }}
          >
            {" "}
            CALL US{" "}
          </Typography>
          <Typography marginBottom={".625rem"}>
            For help and support, please reach us at this number:
          </Typography>
          <Typography sx={{ marginBottom: ".3125rem", color: "#0000FF" }}>
            {CallUscontactInfo}{" "}
          </Typography>
        </Box>
      );
    } else {
      return (
        <Box sx={{ textAlign: "center", padding: ".625rem" }}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1rem",
              marginBottom: "1.5rem",
            }}
          >
            {" "}
            CALL US{" "}
          </Typography>
          <Typography marginBottom={"1rem"}>
            For help and support, please reach us at this number:
          </Typography>
          <Typography>
            <a href={`tel:${CallUscontactInfo}`} onClick={handleCallClick}>
              {CallUscontactInfo}
            </a>
          </Typography>
        </Box>
      );
    }
  };
  return (
    <CustomPopup
      open={open}
      handleOpen={handleClosePopup}
      btnLabel={"Close"}
      className="add-callus-popup"
      btnStartIcon={<CloseIcon fontSize="small" />}
    >
      <Box className="add-callus-popup-content">{renderSteps()}</Box>
    </CustomPopup>
  );
};
export default CallUsPopUp;
