import { FC, useCallback, useState } from "react";
import { Colors } from "../../styles/constant";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomLink from "../CustomLink/CustomLink";
import { defaultEmptyFun } from "../../utils/helper";

interface CheckList {
  label: string;
  isChecked: boolean;
}

const feedbackNumberList = [
  { number: 0, color: "#D34528" },
  { number: 1, color: "#F16548" },
  { number: 2, color: Colors.iconYellow },
  { number: 3, color: Colors.iconYellow },
  { number: 4, color: "#F6A726" },
  { number: 5, color: "#FCCA67" },
  { number: 6, color: "#FCCA67" },
  { number: 7, color: "#FCCA67" },
  { number: 8, color: Colors.green },
  { number: 9, color: Colors.green },
  { number: 10, color: "#0A6A34" },
];

interface Props {
  linkOnClick?: any;
  btnOnClick?: any;
}

const CommonFeedbackPopupBody: FC<Props> = ({
  linkOnClick = defaultEmptyFun,
  btnOnClick = defaultEmptyFun,
}) => {
  const [feedbackRate, setFeedbackRate] = useState<number | null>(null);
  const [feedbackTextValue, setFeedbackTextValue] = useState<string>("");
  const [checkList, setCheckList] = useState<CheckList[]>([
    { label: "Ease of Login", isChecked: false },
    { label: "Ease of Transaction", isChecked: false },
    { label: "Time Taken to Respond", isChecked: false },
    { label: "Accuracy and Completeness of Information", isChecked: false },
  ]);

  const handleClosePopup = useCallback(() => {
    setFeedbackRate(null);
    setFeedbackTextValue("");
    linkOnClick();
  }, []);

  const handleCheckBoxClick = useCallback((label: string) => {
    setCheckList((prevState: CheckList[]) => {
      let tempState = [...prevState];
      tempState.forEach((item) => {
        if (item.label === label) {
          item.isChecked = !item.isChecked;
        }
      });
      return tempState;
    });
  }, []);

  const renderFeedbackNumberBox = (item: { number: number; color: string }) => (
    <Box
      key={item?.number}
      className={`feedback-number-box ${feedbackRate === item?.number ? "Selected" : ""}`}
      sx={{ backgroundColor: item?.color }}
      onClick={() => setFeedbackRate(item?.number)}
    >
      {item?.number}
      {feedbackRate === item?.number && <CheckCircleIcon className="right-icon" />}
    </Box>
  );
  

  let isBtnDisabled =
    !feedbackRate ||
    checkList.filter((item) => item.isChecked).length === 0 ||
    !feedbackTextValue;

  return (
    <Box className="feedback-wrapper">
      <Typography textAlign="center" letterSpacing=".0938rem" variant="h5">
        FEEDBACK PLEASE
      </Typography>
      <Typography
        marginTop="1rem"
        textAlign="center"
        fontSize={14}
        fontWeight={500}
        color="#979797"
        lineHeight=".9625rem"
      >
        Please rate your experience based on your recent interaction.
      </Typography>
      <Stack
        marginTop="1.875rem"
        flexDirection="row"
        justifyContent="center"
        gap=".4375rem"
      >
        {feedbackNumberList.slice(0, 5).map(renderFeedbackNumberBox)}
      </Stack>
      <Stack
        marginTop=".9375rem"
        flexDirection="row"
        justifyContent="center"
        gap=".4375rem"
      >
        {feedbackNumberList.slice(5, 11).map(renderFeedbackNumberBox)}
      </Stack>
      <Typography
        marginTop="1.875rem"
        textAlign="center"
        fontSize={14}
        fontWeight={500}
        color="#979797"
        lineHeight=".9625rem"
      >
        Tell us more about your concerns, so that we can improve our services
      </Typography>
      <Box marginTop=".625rem">
        {checkList.map((item: CheckList) => (
          <Box
            display="flex"
            alignItems="center"
            key={item.label}
            marginTop={".3125rem"}
          >
            <Checkbox
              size="small"
              checked={item.isChecked}
              onChange={() => handleCheckBoxClick(item.label)}
              sx={{
                padding: 0,
                "&.Mui-checked": {
                  color: Colors.red,
                },
              }}
            />
            <Typography marginLeft=".5rem" fontSize={14} fontWeight={400}>
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
      <TextField
        multiline
        fullWidth
        minRows={3}
        value={feedbackTextValue}
        onChange={(e) => setFeedbackTextValue(e.target.value)}
        className="feedback-text-area"
        placeholder="We appreciate your feedback. Please share any additional details that could help us improve."
      />
      <Button
        fullWidth
        variant="contained"
        disabled={isBtnDisabled}
        className="popup-btn"
        onClick={btnOnClick}
      >
        Submit
      </Button>
      <Box display="flex" justifyContent="center" marginTop=".9375rem">
        <CustomLink
          title="Skip"
          titleSize={".75rem"}
          onClick={handleClosePopup}
        />
      </Box>
    </Box>
  );
};

export default CommonFeedbackPopupBody;
