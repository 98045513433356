import { FC } from "react";
import CommonMenuListPage from "../../../components/CustomMenuListPage/CommonMenuListPage";
import { Box, Stack, Typography } from "@mui/material";
import { Colors } from "../../../styles/constant";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";

interface Props {
  handleBackBtnClick: Function;
  pathname?: string;
}

const DeviceAuthenticationSettings: FC<Props> = ({
  handleBackBtnClick,
  pathname,
}) => {
  return (
    <CommonMenuListPage
      title="Device Authentication Settings"
      handleBackBtnClick={handleBackBtnClick}
      pathname={pathname}
    >
      <Stack spacing={"1rem"} paddingLeft={"1rem"} paddingRight={"1rem"}>
        <Box sx={{ "& > :not(:first-child)": { marginTop: ".25rem" } }}>
          <Typography variant="h4" color={Colors.arsenic}>
            Choose the permissions you wish to share
          </Typography>
          <Typography
            variant="h6"
            fontWeight={400}
            color={"#000000"}
            lineHeight={".975rem"}
          >
            Adjust your app settings to suit your preferences
          </Typography>
        </Box>
        <Box sx={{ "& > :not(:first-child)": { marginTop: ".25rem" } }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h5">Device Authentication</Typography>
            <CustomSwitch />
          </Box>
          <Typography
            variant="h6"
            fontWeight={400}
            lineHeight={".975rem"}
            color={Colors.arsenic}
          >
            Enable this if you wish to login to ABCD App <br /> using your
            device authentication.
          </Typography>
        </Box>
      </Stack>
    </CommonMenuListPage>
  );
};

export default DeviceAuthenticationSettings;
