import React, { FC, useCallback, useState } from "react";
import "./CustomAccordion.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { Colors } from "../../styles/constant";
import { defaultEmptyFun } from "../../utils/helper";

interface Props {
  title: string;
  note?: string;
  preIcon?: React.ReactNode | null;
  children?: React.ReactNode | null;
  subTitle?: string;
  handleAccordionClick?: Function;
  customClass?: string;
  expandedByDefault?: boolean;
  nodeTitle?: React.ReactNode | undefined;
}

const CustomAccordion: FC<Props> = ({
  title = "",
  note = "",
  preIcon,
  children,
  subTitle = "",
  handleAccordionClick = defaultEmptyFun,
  customClass = "",
  expandedByDefault = false,
  nodeTitle = undefined,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(expandedByDefault);

  const handleChange = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
    if (!expanded) {
      handleAccordionClick();
    }
  }, [handleAccordionClick]);

  return (
    <Box className={`custom-accordion-wrapper ${customClass}`}>
      {note && (
        <Box className="accordion-note">
          <Typography color={Colors.darkYellow} variant="h6">
            {note}
          </Typography>
        </Box>
      )}
      <Accordion
        className="custom-accordion"
        disableGutters={true}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<KeyboardArrowDownIcon sx={{ color: Colors.red }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion-summary"
        >
          <Box display="flex" alignItems="center">
            {preIcon}
            <Box marginLeft={preIcon ? "0.5rem" : 0}>
              {nodeTitle ? (
                <Box>{nodeTitle}</Box>
              ) : (
                <Typography variant="h3">{title}</Typography>
              )}
              {subTitle && (
                <Typography fontWeight={500} variant="h5">
                  {subTitle}
                </Typography>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        {children && (
          <AccordionDetails className="accordion-details" key={title}>
            {children}
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

export default CustomAccordion;
